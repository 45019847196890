/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState, useContext } from 'react'
import { useAlert } from 'react-alert'
import {
  Box,
  Title,
  Field,
  Input,
  Control,
  Label,
  Button,
  Icon
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11'
import { FormError, HelpError } from '../../error'
import { logic } from '../../../logic'
import { TabsClientsCategories } from '../tabsClientsCategories'
import { useConfirmActionMessage } from '../../../logic/hooks'
import { Pagination } from '../../pagination'

export const AddEditClientsCategoryTenant = () => {
  const alert = useAlert()
  // Error handling
  const confirmAction = useConfirmActionMessage()

  const { t } = useTranslation()
  const history = useHistory()
  const { id } = useParams()
  const { register, handleSubmit, errors, watch, control } = useForm()
  const [selectedIdentities, setSelectedIdentities] = useState([[]]) // AMN - Each time a client's identity is selected or removed must be added/removed here
  const regexPattern = /[^A-Za-z0-9]/g // only alphabetic chars and numbers

  // State refering to the pagination attributes comming from the pagination component
  const defaultPaginationStartPosition = 0
  const defaultPaginationEndPosition = 10
  const [startPosition, setStartPosition] = useState(
    defaultPaginationStartPosition
  )
  const [endPosition, setEndPosition] = useState(defaultPaginationEndPosition)
  let tenantClientsIdentities = []
  const [reloadData, setReloadData] = useState()
  const [originalCode, setOriginalCode] = useState('')
  const [allCategories, setAllCategories] = useState([])
  const [clientsCategory, setClientsCategory] = useState()

  useEffect(() => {
    logic.getTenantClientsCategories().then(categories => {
      const data = categories && categories.data
      setAllCategories(data)
    })
    if (id) {
      logic
        .getOneTenantClientsCategory(id)
        .then(tenantClientsCategory => {
          setClientsCategory(tenantClientsCategory && tenantClientsCategory.data)
          if (tenantClientsCategory && tenantClientsCategory.data) {
            setOriginalCode(tenantClientsCategory.data[0].categorycode)
          }
        })
        .catch(err => {
          alert.show(err.message, {
            type: 'error'
          })
        })
    }
  }, [reloadData])

  const handleGoBackClick = () => {
    history.push('/clients/categories')
  }

  // handles the pagination requests
  const handleRequestPagination = requestPaginationObject => {
  let newStartPosition = defaultPaginationStartPosition
  let newEndPosition = defaultPaginationEndPosition
  if (requestPaginationObject) {
    const { currentPage, itemsPerPage } = requestPaginationObject
    newStartPosition = currentPage * itemsPerPage - itemsPerPage
    newEndPosition = newStartPosition + itemsPerPage
  }
  setStartPosition(newStartPosition)
  setEndPosition(newEndPosition)
  }

  const onSubmitData = data => {
    const { name } = data
    const code = name.replace(regexPattern, "").toUpperCase()
    // AMN - we check the name doesn't exist to avoid duplicated clients' group names for a tenant
    const existsName = allCategories.filter(category => { if (category.categorycode === code) return category })
    if (existsName && existsName.length > 0 && code !== originalCode) {
      alert.show(t(`The encoded clients' category name already exists`), {
        type: 'error'
      })
      return
    }
    confirmAction.closeMessage()
    
    if (id) {
      logic
        .updateClientsCategoryTenant({
          clientCategoryId: id.toString(),
          categoryName: name,
          categoryCode: code,
        },  {tenantId: logic.tenantId.toString()})
        .then(clientsCategory => {
          if (clientsCategory.status !== 200) {
            throw Error(`${clientsCategory.status}`)
          }
          setReloadData(!reloadData)
          alert.show(t('updateSuccesful'), {
            type: 'success'
          })
        })
        .catch(err => {
          if (err.message === '409') { // AMN - Check this 409 on the api
            alert.show('Input already exists', {
              type: 'error'
            })
          } else {
            alert.show('Unexpected Error', {
              type: 'error'
            })
          }
        })
    } else {
      logic
        .insertClientsCategoryTenant({
          categoryName: name,
          categoryCode: code,
        }, {tenantId: logic.tenantId.toString()})
        .then(clientsCategory => {
          if (clientsCategory.status !== 200) {
            throw Error(clientsCategory.error)
          }
          alert.show(t('clientsCategoryTenantSuccessfullyAdded'), {
            type: 'success'
          })
          history.push({
            pathname: '/clients/categories',
            state: '200'
          })
        })
        .catch(err => {
            alert.show(t(err.message), {
              type: 'error'
            })
        })
    }
  }

  return (
    <Box>
      <TabsClientsCategories highlight='categories' />
      <Button onClick={e => handleGoBackClick()} color='light' size='medium'>
        <Icon>
          <FontAwesomeIcon size='lg' icon={['fas', 'chevron-circle-left']} />
        </Icon>
      </Button>
      <Title>
        {id
          ? `${t('edit')} ${clientsCategory && clientsCategory[0].categoryname}`
          : `${t('add')} ${t('clients_categories')}`}
      </Title>
      <form onSubmit={handleSubmit(onSubmitData)}>
        
          <Field>
            <Label>{t('name')}</Label>
            <Control iconLeft>
              <Input
                defaultValue={clientsCategory && clientsCategory[0].categoryname}
                type='text'
                color='light'
                name='name'
                ref={register({
                  required: true,
                  maxLength: 300
                })}
              />
              <Icon size='small' align='left'>
                <FontAwesomeIcon icon={['fas', 'file-signature']} />
              </Icon>
            </Control>
            {errors.name &&
              errors.name.type === 'required' && (
                <HelpError message={t('required')} />
              )}
            {errors.name &&
              errors.name.type === 'maxLength' && (
                <HelpError message={t('tooManyCharacters')} />
              )}
          </Field>

      <Pagination
        totalItems={selectedIdentities && selectedIdentities.length}
        requestPagination={handleRequestPagination}
      />

        <Field kind='group'>
          <Control>
            {id ? (
              <Button color='link'>{t('update')}</Button>
            ) : (
                <Button color='link'>{t('add')}</Button>
              )}
          </Control>
          <Control>
            <Button as={Link} to='/clients/categories' type='button' text>
              {t('cancel')}
            </Button>
          </Control>
          {confirmAction.showMessage && <FormError {...confirmAction} />}
        </Field>
      </form>
    </Box>
  )
}
