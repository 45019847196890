import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tab, Icon } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

export const TabsClientsCategories = ({ highlight }) => {
  const { t } = useTranslation()
  
  return (
    <Tab.Group align='centered' kind='boxed'>
      <Tab as={Link} to='/clients/categories' active={highlight === 'categories'}>
          <Icon size='small'>
          <FontAwesomeIcon icon={['fas', 'layer-group']} />
          </Icon>
          <span>{t('clients_categories')}</span>
      </Tab>
    </Tab.Group>
  )
}
