import React, { useEffect, useState, useContext } from 'react'
import { useAlert } from 'react-alert'
import { Box, Title, Field, Input, Control, Label, Button, Icon } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11'
import { FormError, HelpError } from '../../../error'
import { logic } from '../../../../logic'
import { TabsObjectives} from '../../tabsObjectives'
import { useConfirmActionMessage } from '../../../../logic/hooks'
import { AdsContext } from "../../../../contexts/adsContext";

export const AddEditObjTypes = () => {
  const alert = useAlert()
   const [agreementType, setAgreementType] = useState()
  // Error handling
  const confirmAction = useConfirmActionMessage()

  const { t } = useTranslation()
  const history = useHistory()
  const { id } = useParams()
  const { register, handleSubmit, errors } = useForm()

  const [reloadData, setReloadData] = useState()
  const { allTenantAgreementsTypes, 
              fetchAllTenantAgreementsTypes,
              updateTenantAgreementType,
              insertTenantAgreementType } = useContext(AdsContext)
  const [originalName, setOriginalName] = useState('')

  useEffect(() => {
    (async function () {
        try {
            await fetchAllTenantAgreementsTypes()
            if (id) {
                const selectedAgreementType = allTenantAgreementsTypes.filter(agreementType => {
                    if (agreementType.agreementtypeid === parseInt(id)) return agreementType
                })
                if (!selectedAgreementType.length) throw Error(`Agreement type not found. Please, contact with AmbitOS through the phone: +34 935 478 899 or by email: servicedesk@ambit-bst.com.`)
                setAgreementType(selectedAgreementType)
                setOriginalName(selectedAgreementType.agreementtypename)
            }
        } catch (error) {
            alert.show(error.message, {
                type: 'error'
            })
            history.push({
                pathname: '/agreements/types',
                state: '200'
              })
        }
    })()
  }, [reloadData])

  const handleGoBackClick = () => {
    history.push('/agreements/types')
  }
  
  const onSubmitData = data => {
    const { name } = data
    // AMN - we check the name doesn't exist to avoid duplicated clients' group names for a tenant
    const existsName = allTenantAgreementsTypes.filter(agreementType => { if (agreementType.agreementtypename === name) return agreementType })
    if (existsName && existsName.length > 0 && name !== originalName) {
      alert.show(t(`The type of agreement name already exists`), {
        type: 'error'
      })
      return
    }
    confirmAction.closeMessage()
    
    if (id) {
        updateTenantAgreementType({ agreementTypeId: id.toString(), agreementTypeName: name, tenantId: logic.tenantId.toString() }) 
        setReloadData(!reloadData)
    } else {
        insertTenantAgreementType({ agreementTypeName: name, tenantId: logic.tenantId.toString() })          
    }
  }

  return (
    <Box>
      <TabsObjectives highlight='types' isInactive="is-inactive" />
      <Button onClick={e => handleGoBackClick()} color='light' size='medium'>
        <Icon>
          <FontAwesomeIcon size='lg' icon={['fas', 'chevron-circle-left']} />
        </Icon>
      </Button>
      <Title>
        {id
          ? `${t('edit')} ${agreementType && agreementType[0].agreementtypename}`
          : `${t('add')} ${t('agreement_type')}`}
      </Title>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <Field>
        <Label>{t('name')}</Label>
        <Control iconLeft>
            <Input
            defaultValue={agreementType && agreementType[0].agreementtypename}
            type='text'
            color='light'
            name='name'
            ref={register({
                required: true,
                maxLength: 30
            })}
            />
            <Icon size='small' align='left'>
            <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
        </Control>
        {errors.name &&
            errors.name.type === 'required' && (
            <HelpError message={t('required')} />
            )}
        {errors.name &&
            errors.name.type === 'maxLength' && (
            <HelpError message={t('tooManyCharacters')} />
            )}
        </Field>
        <Field kind='group'>
          <Control>
            {id ? (
              <Button color='link'>{t('update')}</Button>
            ) : (
                <Button color='link'>{t('add')}</Button>
              )}
          </Control>
          <Control>
            <Button as={Link} to='/agreements/types' type='button' text>
              {t('cancel')}
            </Button>
          </Control>
          {confirmAction.showMessage && <FormError {...confirmAction} />}
        </Field>
      </form>
    </Box>
  )
}