/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import {
  Select,
  Box,
  Title,
  Field,
  Input,
  Control,
  Label,
  Button,
  Icon,
  Level
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11'
import { FormError, HelpError } from '../../../error'
import { logic } from '../../../../logic'
import { TabsProduct } from '../../tabsProduct'
import { useConfirmActionMessage } from '../../../../logic/hooks'
import { CURRENCIES } from '../../../../config/config'

export const AddEditProductValue = () => {
  const alert = useAlert()
  // productTenants
  const [product, setProduct] = useState()
  const [productPriceYears, setProductPriceYears] = useState([])
  const [allTenantProducts, setAllTenantProducts] = useState()
  const [allTenantProductPrices, setAllTenantProductPrices] = useState([])
  const [dateStart, setDateStart] = useState(new Date())
  const [dateEnd, setDateEnd] = useState(new Date())
  // Error handling
  const confirmAction = useConfirmActionMessage()

  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const isNewPrice = location.pathname.split('product/value/')[1].split('/')[0] === 'add' ? true : false
  const { productPriceId, productTenantId } = useParams()
  const { register, handleSubmit, errors, watch } = useForm()

  useEffect(() => {
    let productPriceStartDate
    let productPriceEndDate
    if (productTenantId && productTenantId !== '0') { 
      logic
        .getOneProductYearPrices({ productTenantId: productTenantId, productPriceId: 0 })
        .then(productPrices => {
          let selectedProductPrice
          if (productPrices && productPrices.data.length > 1) {
            selectedProductPrice = productPrices && productPrices.data && productPrices.data.filter(productPrice => productPrice.productpriceid.toString() === productPriceId.toString()) 
          } else {
            selectedProductPrice = productPrices && productPrices.data && productPrices.data
          }
          setProduct(selectedProductPrice[0])
          setProductPriceYears(productPrices && productPrices.data && productPrices.data.map(productPrice => ({ productPriceYear: productPrice.productpriceyear, productPriceId: productPrice.productpriceid })))
          if (isNewPrice) {
              productPriceStartDate = new Date()
              setDateStart(`${productPriceStartDate.getFullYear()}-01-01`)
              productPriceEndDate = new Date()
              setDateEnd(`${productPriceEndDate.getFullYear()}-12-31`)
          } else {
              productPriceStartDate = new Date(selectedProductPrice[0].productpricefromdate)
              setDateStart(`${productPriceStartDate.getFullYear()}-${(productPriceStartDate.getMonth()+1).toString().padStart(2, '0')}-${productPriceStartDate.getDate().toString().padStart(2, '0')}`)
              productPriceEndDate = new Date(selectedProductPrice[0].productpricetodate)
              setDateEnd(`${productPriceEndDate.getFullYear()}-${(productPriceEndDate.getMonth()+1).toString().padStart(2, '0')}-${productPriceEndDate.getDate().toString().padStart(2, '0')}`)
          }
        })
        .catch(err => {
          alert.show(err.message, {
            type: 'error'
          })
        })
    } else {
      logic.getProductPrices().then(products => {
        // Create an object to store unique ages and their associated names
        let sortedUniqueIdsProducts = {}
        
        // Use forEach to iterate over the array and update the object
        products && products.data.forEach(product => {
            // Check if the age is already present in the object
            if (!sortedUniqueIdsProducts.hasOwnProperty(product.producttenantid)) {
                // If not present, add the age as a key and associate the name with it
                sortedUniqueIdsProducts[product.producttenantid] = { producttenantcode: product.producttenantcode, productname: product.productname }
            }
        })
        // Convert the object values to an array
        let uniqueProductIdsArray = Object.entries(sortedUniqueIdsProducts).map(([producttenantid, ...details]) => ({ ...details, producttenantid: parseInt(producttenantid) }));
        
        setAllTenantProductPrices(products && products.data)
        const sortedUniqueProductIdsArray =
          uniqueProductIdsArray &&
          uniqueProductIdsArray.sort((a, b) => {
            if (a[0].productname < b[0].productname) {
              return -1
            }
            if (a[0].productname > b[0].productname) {
              return 1
            }
          })
        setAllTenantProducts(sortedUniqueProductIdsArray)
        productPriceStartDate = new Date()
        setDateStart(`${productPriceStartDate.getFullYear()}-01-01`)
        productPriceEndDate = new Date()
        setDateEnd(`${productPriceEndDate.getFullYear()}-12-31`)
      })
    }
  }, [])

  const handleGoBackClick = () => {
    history.push('/product/value')
  }

  const isThereAnyPriceOnTheYear = (dateStart) => {
    console.log("Within isThereAnyPriceOnTheYear:")
    console.log(dateStart)
    console.log(productPriceYears)
    if (!productPriceYears || productPriceYears.length === 0) return false
    // The product can't have any price for another year distinct from the year selected when ediing a product price date
    const isTheYearFound = productPriceYears.find(year => year.productPriceYear === new Date(dateStart).getFullYear() && 
                                                          year.productPriceId.toString() !== productPriceId.toString()) ? true : false
    return isTheYearFound
  }

  const onSubmitData = data => {
    const { codename, value, currency, dateStart, dateEnd } = data
    confirmAction.closeMessage()
    if (dateStart.split('-')[0] !== dateEnd.split('-')[0]) {
      alert.show(t('The year on both dates MUST be the same'), { type: 'error' })
      return
    }
    if (isThereAnyPriceOnTheYear(dateStart)) {
      alert.show(t('There is another price for the year already'), { type: 'error' })
      return
    }
    if (productPriceId && productPriceId !== '0') {
      logic
        .updateProductValue({
          productPriceId: productPriceId,
          productPrice: value,
          productPriceFromDate: dateStart,
          productPriceToDate: dateEnd,
          productPriceCurrency: currency
        })
        .then(product => {
          if (product.status !== 200) {
            throw Error(`${product.status}`)
          }
          alert.show(t('updateSudccesful'), {
            type: 'success'
          })
        })
        .catch(err => {
          if (err.message === '409') {
            alert.show('Input already exists', {
              type: 'error'
            })
          } else {
            alert.show('Unexpected Error', {
              type: 'error'
            })
          }
        })
    } else {
      logic
        .insertProductValue({
          productTenantId: productTenantId === '0' ? codename : productTenantId,
          productPrice: value,
          productPriceFromDate: dateStart,
          productPriceToDate: dateEnd,
          productPriceCurrency: currency
        })
        .then(product => {
          if (product.status !== 200) {
            console.log(product)
            throw Error(product.error)
          }
          alert.show(t('productValueSuccessfullyAdded'), {
            type: 'success'
          })
          history.push({
            pathname: '/product/value',
            state: '200'
          })
        })
        .catch(err => {
            alert.show(t(err.message), {
              type: 'error'
            })
        })
    }
  }

  const handleChangeProduct = (event) => {
    const { name, value } = event.target
    setProductPriceYears(allTenantProductPrices.filter(tenantProductPrice => tenantProductPrice.producttenantid.toString() === value.toString())
                                               .map(productPrice => ({ productPriceYear: productPrice.productpriceyear, productPriceId: productPrice.productpriceid })))
  }

  const handleChangeDateStart = (event) => {
    const { name, value } = event.target
    if (value.split('-')[1] !== '01' || value.split('-')[2] !== '01') {
      alert.show(t('Start date MUST be Jan 1st'), { type: 'info' })
    }
    const newValue = value === '' ? dateStart : `${value.split('-')[0]}-01-01`
    setDateStart(newValue)
    setDateEnd(`${newValue.split('-')[0]}-12-31`)
  }

  const handleChangeDateEnd = (event) => {
    const { name, value } = event.target
    if (value.split('-')[1] !== '12' || value.split('-')[2] !== '31') {
      alert.show(t('End date MUST be Dec 31st'), { type: 'info' })
    }
    const newValue = value === '' ? dateEnd : `${value.split('-')[0]}-12-31`
    setDateEnd(newValue)
    setDateStart(`${newValue.split('-')[0]}-01-01`)
  }

  return (
    <Box>
      <TabsProduct highlight='values' />
      <Button onClick={e => handleGoBackClick()} color='light' size='medium'>
        <Icon>
          <FontAwesomeIcon size='lg' icon={['fas', 'chevron-circle-left']} />
        </Icon>
      </Button>
      <Title>
        {!isNewPrice
          ? `${t('edit')} ${t('price')} ${product && product.productname}`
          : `${t('add')} ${t('price')} ${productTenantId && productTenantId === '0' ? t('product') : product && product.productname}`}
      </Title>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <Field>
          <Label>{t('codename')}</Label>
          {productTenantId && productTenantId === '0' ?
            <Control expanded>
              <Select.Container color='light' fullwidth>
                <Select
                  name='codename'
                  ref={register({
                    required: true
                  })}
                  onChange={(event) => handleChangeProduct(event)}
                  value={watch('codename') || undefined}
                >
                  <Select.Option value=''>{t('select')}</Select.Option>

                  {allTenantProducts &&
                    allTenantProducts.map((product, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={product && product.producttenantid}
                        >
                          {product && product[0] && product[0].producttenantcode + ' - ' + product[0].productname}
                        </Select.Option>
                      )
                    }
                    )}

                </Select>

              </Select.Container>
              {errors.codename &&
                errors.codename.type === 'required' && (
                  <HelpError message={t('required')} />
                )}
            </Control>
          :
            <Control iconLeft>
              <Input
                defaultValue={product && product.producttenantcode + ' - ' + product.productname}
                type='text'
                color='light'
                name='codename'
                ref={register({
                  required: true
                })}
                disabled={!isNewPrice || (isNewPrice && productTenantId !== '0')}
              />
              <Icon size='small' align='left'>
                <FontAwesomeIcon icon={['fas', 'archive']} />
              </Icon>
            </Control>
          }
          
        </Field>
        
        <Level>
          <Level.Item align="left">
            <Field>
              <Label>{t('value')}</Label>
        
              <Control>
                <Input
                  defaultValue={product && product.productprice}
                  type='number'
                  min="0"
                  step="0.01" // AMN - necessary to allow entering cents (only two decimals), don't remove it
                  color='light'
                  name='value'
                  ref={register({
                    required: true,
                    maxLength: 10
                  })}
                />
              </Control>
              {errors.value && errors.value.type === 'maxLength' && (
                <HelpError message={t('tooManyCharacters')} />
              )}
              {errors.value &&
                errors.value.type === 'required' && (
                  <HelpError message={t('required')} />
                )}
            </Field>
          </Level.Item>

          <Level.Item>
            <Field>
              <Label>{t('select_currency')}:</Label>
              <Control expanded>
                <Select.Container color='light' fullwidth>
                  <Select
                    name='currency'
                    ref={register({
                      required: true
                    })}
                    value={watch('currency_types')}
                  >
                    <Select.Option value=''>{t('select')}</Select.Option>

                    {CURRENCIES.map((currency, index) => {
                        const currencySelected = product && product.productpricecurrency
                        return (
                          <Select.Option
                            key={index}
                            value={currency}
                            selected={ currency  === currencySelected ? 'selected' : 'EUR'}
                            //defaultValue={CURRENCIES.map(currency => currency.productpricecurrency === 'EUR')}
                          >
                            {t(currency)}
                          </Select.Option>
                        )
                      }
                      )}
                  </Select>
                </Select.Container>
              </Control>
              {errors.currency &&
                errors.currency.type === 'required' && (
                  <HelpError message={t('required')} />
                )}
            </Field>
          </Level.Item>
          <Level.Item textAlign="centered">  
            <Level.Item>
              <Field>
                <Label>{t('Desde')}</Label>
                    
                <Control  >
                  <Input
                    value={(product && dateStart) || (allTenantProducts && dateStart)}
                    onChange={(event) => handleChangeDateStart(event)}
                    type='date'
                    color='light'
                    name='dateStart'
                    ref={register({
                      required: true
                    })}
                  />
                </Control>
                {errors.dateStart &&
                  errors.dateStart.type === 'required' && (
                    <HelpError message={t('required')} />
                  )}
              </Field>
            </Level.Item>

            <Level.Item>
              <Field>
                <Label>{t('Hasta')}</Label>
                <Control  >
                  <Input
                    value={(product && dateEnd) || (allTenantProducts && dateEnd)}
                    onChange={(event) => handleChangeDateEnd(event)}
                    type='date'
                    color='light'
                    name='dateEnd'
                    ref={register({
                      required: true
                    })}
                  />
                </Control>
                {errors.dateEnd &&
                  errors.dateEnd.type === 'required' && (
                    <HelpError message={t('required')} />
                  )}
              </Field>
            </Level.Item>
          </Level.Item>
        </Level>
            

        <Field kind='group'>
          <Control>
            <Button color='link'>{!isNewPrice && productPriceId !== '0' ? t('update') : t('add')}</Button> 
          </Control>
          <Control>
            <Button as={Link} to='/product/value' type='button' text>
              {t('cancel')}
            </Button>
          </Control>
          {confirmAction.showMessage && <FormError {...confirmAction} />}
        </Field>
      </form>
    </Box>
  )
}
