import React from 'react'
import { Card, Typography } from 'antd'
import './card.scss'

const { Title } = Typography

export const DashboardCard = ({title, content}) => (
    <>
<Card size='small' title={title} bordered={false} style={{ width: '40%'}}>
<Title>{content}</Title>
    </Card>
    </>
)