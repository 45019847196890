import React, { useState, useEffect, useRef, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { logic } from '../../../logic'
import { TabsObjectives } from '../tabsObjectives'
import {
  Title,
  Level,
  Field,
  Icon,
  Button,
  Table,
  Box,
  Select
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Pagination } from '../../pagination'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useToggle } from '../../../logic/hooks'
import { Search } from '../../filter/Search'
import { Modal } from '../../modal'
import { useAlert } from 'react-alert'
import { AdsContext } from "../../../contexts/adsContext"

export const ObjectivesObjectives = () => {
  const alert = useAlert()

  // Hooks for toggling on and off the modal
  const [open, toggle] = useToggle(false)

  const [openInactive, toggleInactive] = useToggle(false)

  const [deleteItem, setDeleteItem] = useState()

  // state inside the modal
  const [related, setRelated] = useState([])

  // i18n hook
  const { t } = useTranslation()

  // Routing Variables
  const { url } = useRouteMatch()
  const history = useHistory()

  // State refering to the pagination attributes comming from the pagination component
  const defaultPaginationStartPosition = 0
  const defaultPaginationEndPosition = 10
  const [startPosition, setStartPosition] = useState(
    defaultPaginationStartPosition
  )
  const [endPosition, setEndPosition] = useState(defaultPaginationEndPosition)

  // React active or inactive
  const [dropdownState, setDropdownState] = useState('active')
  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const paginationRef = useRef()
  const [searchTenantObjectives, setSearchTenantObjectives] = useState([])
  const { allTenantObjectives,  
              allGroups,
              allTenantAgreementsTypes,
              setSharedObjective,
              setInfZonesCheckedBoxes,
              setProductsCheckedBoxes,
              setBoldDistributors,
              setBoldProducts,
              setBoldPostalCodes,
              setBoldCountries,
              setBoldRegions,
              setBoldProvinces,
              fetchAllTenantObjectives, 
              fetchAllTenantClientsGroup,
              fetchAllTenantAgreementsTypes,
              activateTenantObjective,
              inactivateTenantObjective } = useContext(AdsContext)

  // Fetch objectives when component mounts
  useEffect(() => {
    (async function () {
      const tenantObjectives = await fetchAllTenantObjectives()
      const sortedActiveObjectives =
        tenantObjectives &&
        tenantObjectives
        .filter(objective => { if(objective.active) return objective})
        .sort((a, b) => {
          if (a.objectivecode.toLowerCase() < b.objectivecode.toLowerCase()) {
            return -1
          }
          if (a.objectivecode.toLowerCase() > b.objectivecode.toLowerCase()) {
            return 1
          }
          return 0
        })
      setSearchTenantObjectives(sortedActiveObjectives)
      await fetchAllTenantClientsGroup()
      await fetchAllTenantAgreementsTypes()
    })()
  }, [])

  // handles the pagination requests
  const handleRequestPagination = requestPaginationObject => {
    let newStartPosition = defaultPaginationStartPosition
    let newEndPosition = defaultPaginationEndPosition
    if (requestPaginationObject) {
      const { currentPage, itemsPerPage } = requestPaginationObject
      newStartPosition = currentPage * itemsPerPage - itemsPerPage
      newEndPosition = newStartPosition + itemsPerPage
    }
    setStartPosition(newStartPosition)
    setEndPosition(newEndPosition)
  }

  // handles routing to add/edit
  const handleAddEditClick = (event, element) => {
    setSharedObjective()
    setBoldDistributors([])
    setBoldProducts([])
    setBoldPostalCodes([])
    setBoldCountries([])
    setBoldRegions([])
    setBoldProvinces([])
    setInfZonesCheckedBoxes({
      country: true,
      region: false,
      province: false,
      postalcode: false
    })
    setProductsCheckedBoxes({
      product: false,
      businessunit: true,
      brand: false,
      group: false,
      set: false,
    })
    if (element) {
      history.push({
        pathname: `${url}/edit/${element && element.objectiveid}`,
        state: element
      })
    } else {
      history.push({
        pathname: `${url}/add`
      })
    }
  }

  //handles activation of tenantclientgroup
  const handleActivateItem = async (event, element) => {
    const id = `${element.objectiveid}`
    try {
      const updatedObjective = await activateTenantObjective(id)
      const active = false
      newObjectivesSorted(updatedObjective, active)
      toggleInactive()
    }
    catch (error) {
      throw Error(error)
    }
  }

  const handleDeleteClick = async (event, element) => {
    const id = `${element.objectiveid}`
    try {
      const updatedObjective = await inactivateTenantObjective(id)
      const active = true
      newObjectivesSorted(updatedObjective, active)
      toggle()
    } catch (error) {
      throw Error(`Error while inactivating the objective id: ${id} for the tenant id: ${logic.tenantId.toString()}`)
    }
  }

  const newObjectivesSorted = (updatedObjective, active) => {
    const newObjectives = allTenantObjectives.filter(
      objective => {
        return objective.objectiveid !== updatedObjective.objectiveid && objective.active === active
      }
    )
    const sortedNewObjectives =
    newObjectives &&
    newObjectives.sort((a, b) => {
      if (a.objectivecode.toLowerCase() < b.objectivecode.toLowerCase()) {
        return -1
      }
      if (a.objectivecode.toLowerCase() > b.objectivecode.toLowerCase()) {
        return 1
      }
      return 0
    })
    setSearchTenantObjectives(sortedNewObjectives)
  }

  const showRelatedItems = (event, item) => {
    setDeleteItem({ ...item })

    // TODO set correct logic for related
    setRelated([])
    toggle()
  }

  const showRelatedItemsInactive = (event, item) => {
    setDeleteItem({ ...item })
    if (allGroups.filter(clientGroup => clientGroup.clientgroupid === item.clientgroupid && clientGroup.active === false).length || 
         allTenantAgreementsTypes.filter(agreementType => agreementType.agreementtypeid === item.agreementtypeid && agreementType.active === false).length) {
        setRelated(allGroups.filter(clientGroup => { return clientGroup.clientgroupid === item.clientgroupid && clientGroup.active === false })
          .concat(allTenantAgreementsTypes.filter(agreementType => { return agreementType.agreementtypeid === item.agreementtypeid && agreementType.active === false })))
    } else {
        setRelated([])
    }    
    toggleInactive()
  }

  const handleDoSearch = value => {
    let active
    if (dropdownState === 'active') {
      active = true
    } else {
      active = false
    }
    const result = allTenantObjectives.filter(objective => {

      const newObjective = {
        objectivecode: objective.objectivecode,
        objectivename: objective.objectivename,
        objectiveclientname: objective.clientname,
        objectiveagreementtypename: objective.agreementtypename,
        objectivevaluetype: objective.valuetype 
      }
      const values = Object.values(newObjective)

      const isIncluded = values
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(value.toLowerCase().replace(/\s+/g, ''))
      // pagination Reset vallues
      paginationRef.current.resetValues()

      return isIncluded && objective.active === active
    })
    const sortedResult = result.sort((a, b) => {
      if (a.objectivecode.toLowerCase() < b.objectivecode.toLowerCase()) {
        return -1
      }
      if (a.objectivecode.toLowerCase() > b.objectivecode.toLowerCase()) {
        return 1
      }
      return 0
    })
    setSearchTenantObjectives(sortedResult)
    return sortedResult
  }

  const handleDropdownStateChange = (event) => {
    setDropdownState(event.target.value)
    let active
    if(event.target.value === 'active') {
      active = true
    } else {
      active = false
    }
    const sortedActiveObjectives = allTenantObjectives
      .filter(objective => { if(objective.active == active) return objective})
      .sort((a, b) => {
        if (a.objectivecode.toLowerCase() < b.objectivecode.toLowerCase()) {
          return -1
        }
        if (a.objectivecode.toLowerCase() > b.objectivecode.toLowerCase()) {
          return 1
        }
        return 0
      })
    setSearchTenantObjectives(sortedActiveObjectives)
    paginationRef.current.resetValues()
  }

  return (
    <Box>
      {open && (
        <Modal toggle={toggle} open={open}>
          <Title>{t('confirmDelete')}</Title>
            <>
              <Title subtitle>{t('deleteSure')}</Title>
              <Button
                onClick={e => handleDeleteClick(e, deleteItem)}
                color='danger'
              >
                {t('delete')}
              </Button>
            </>
        </Modal>
      )}

      {openInactive && (
        <Modal toggle={toggleInactive} open={openInactive}>
          <Title>{t('confirmActivate')}</Title>
          {related && related.length !== 0 && (
            <Title subtitle size={6}>
              {t('cannotActivateGroupType')}
            </Title>
          )}
          {related &&
            related.length !== 0 &&
            related.map((item, index) => {
              return (
                <>
                  <Field key={index}>{item.clientname && `${item.clientname} => ${t('clients_groups')}` || item.agreementtypename && `${item.agreementtypename} => ${t('agreement_type')}`}</Field>
                </>
              )
            })}
          {related && related.length === 0 && (
            <>
              <Title subtitle>{t('activateSure')}</Title>
              <Button
                onClick={e => handleActivateItem(e, deleteItem)}
                color='success'
              >
                {t('Activate')}
              </Button>
            </>
          )}
        </Modal>
      )}

      <TabsObjectives highlight='objectives' isInactive="is-inactive" />
      <Level>
        <Select.Container>
          <Select value={dropdownState} onChange={handleDropdownStateChange} >
            <Select.Option value='active'>
              {t('Actives')}
            </Select.Option>
            <Select.Option value='inactive'>
              {t('Inactives')}
            </Select.Option>
          </Select>
        </Select.Container>
        <Level.Item>
          <Search doSearch={handleDoSearch} />
        </Level.Item>
        <Level.Item align='right'>
          <Button onClick={e => handleAddEditClick()} color='info'>
            <Icon>
              <FontAwesomeIcon size='lg' icon={['fas', 'plus']} />
            </Icon>
          </Button>
        </Level.Item>
      </Level>
      <Table hoverable bordered striped narrow fullwidth>
        <Table.Head>
          <Table.Row>
            <Table.Heading>{t('agreement_code')}</Table.Heading>
            <Table.Heading>{t('name')}</Table.Heading>
            <Table.Heading>{t('client')}</Table.Heading>
            <Table.Heading>{t('agreement_name')}</Table.Heading>
            <Table.Heading>{t('startdate')}</Table.Heading>
            <Table.Heading>{t('enddate')}</Table.Heading>
            <Table.Heading>{t('value')}</Table.Heading>
            <Table.Heading>{t('action')}</Table.Heading>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {searchTenantObjectives &&
            searchTenantObjectives
            .slice(startPosition, endPosition)
            .sort((a, b) => {
              if (a.objectivecode.toLowerCase() < b.objectivecode.toLowerCase()) {
                return -1
              }
              if (a.objectivecode.toLowerCase() > b.objectivecode.toLowerCase()) {
                return 1
              }
              return 0
            })
            .map(item => {          
              return (
                <Table.Row key={item.objectiveid}>
                  <Table.Cell>{item.objectivecode}</Table.Cell>
                  <Table.Cell>{item.objectivename}</Table.Cell>
                  <Table.Cell>{item.clientname}</Table.Cell>
                  <Table.Cell>{item.agreementtypename}</Table.Cell>
                  <Table.Cell>{`${new Date(item.starttime).getDate().toString().padStart(2, '0')}/${(new Date(item.starttime).getMonth()+1).toString().padStart(2, '0')}/${new Date(item.starttime).getFullYear()}`}</Table.Cell>
                  <Table.Cell>{`${new Date(item.endtime).getDate().toString().padStart(2, '0')}/${(new Date(item.endtime).getMonth()+1).toString().padStart(2, '0')}/${new Date(item.endtime).getFullYear()}`}</Table.Cell>

                  
                  <Table.Cell align='right'>{`${item.totalvalueobjective.toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")} ${t(item.valuetype)}`}</Table.Cell>
                  <Table.Cell>
                    <button
                      onClick={e => handleAddEditClick(e, item)}
                      className='button is-primary is-outlined is-small'
                    >
                      <Icon>
                        <FontAwesomeIcon size='lg' icon={['fas', 'edit']} />
                      </Icon>
                    </button>
                    {dropdownState === 'active' && <button
                      onClick={e => showRelatedItems(e, item)}
                      className='button is-danger is-outlined is-small'
                    >
                      <Icon>
                        <FontAwesomeIcon size='lg' icon={['fas', 'trash']} />
                      </Icon>
                    </button>}
                    {dropdownState === 'inactive' && <button
                      onClick={e => showRelatedItemsInactive(e, item)}
                      className='button is-success is-outlined is-small'
                    >
                      <Icon>
                        <FontAwesomeIcon size='lg' icon={['fas', 'check-circle']} />
                      </Icon>
                    </button>}
                  </Table.Cell>
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table>
      <Pagination
        totalItems={searchTenantObjectives && searchTenantObjectives.length}
        ref={paginationRef}
        requestPagination={handleRequestPagination}
      />
    </Box>
  )
}
