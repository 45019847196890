const TEMPLATE_NAMES = { sales: 'Modelo Reporte Ventas', stock: 'Modelo Reporte Stock' }

export const getToken = () => sessionStorage.getItem('userToken')

export const getIsTenant = () => sessionStorage.getItem('tenantId')

export const getIsDistributor = () => sessionStorage.getItem('distributorId')

export const getIsAdmin = () => sessionStorage.getItem('adminId')

// AMN - new function within logic to validate the existence of a given date
var validateDate = require("validate-date")

// AMN - important note: the const logic.url is the real production app url. In PRE-production and development environments MUST be modified by
// using a .env file and changing the url through it. See App.js file to understand it and see how the url logic.url is changed when .env file exists.
const logic = {
  _userId: () => sessionStorage.getItem('userId') || null,
  _token: () => sessionStorage.getItem('token') || null,
  _user: () => sessionStorage.getItem('userData') || null,
  _distributorId: () => sessionStorage.getItem('distributorId').toString() || null,
  _tenantId: () => sessionStorage.getItem('tenantId').toString() || null,
  _notCrossSales: () => sessionStorage.getItem('notCrossSales') || null,
  _adminId: () => sessionStorage.getItem('adminId').toString() || null,
  _roleName: () => sessionStorage.getItem('roleName').toString() || null,

  url: 'https://www.animaldatasource.com/api',  


  serviceDeskUrl: 'NO-URL',

  serviceDeskKey: 'NO-KEY',

  get loggedIn() {
    const hasToken = this._token()
    if (hasToken === 'null' || !hasToken) {
      return false
    }
    return true
  },

  get sessionId() {
    return this._userId()
  },

  get user() {
    return this._user()
  },

  get isTenant() {
    const isTenant = this._tenantId()
    if (isTenant === 'null' || !isTenant) {
      return false
    }
    return true
  },

  get isDistributor() {
    const isDistributor = this._distributorId()
    if (isDistributor === 'null' || !isDistributor) {
      return false
    }
    return true
  },

  get isAdmin() {
    const isAdmin = this._roleName()
    if (isAdmin !== 'AmbitAdm') {
      return false
    }
    return true
  },

  get roleName() {
    return this._roleName()
  },

  get distributorId() {
    return this._distributorId()
  },

  get tenantId() {
    return this._tenantId()
  },

  get notCrossSales() {
    if (this._notCrossSales() === 'true') return true
    if (this._notCrossSales() === 'false') return false
    return this._notCrossSales()
  },

  get userEmail() {
    return JSON.parse(this._user()).useremail
  },

  // AMN - Checking that each date on the rows is an existing date
  isExistingDate(rowDate) {
    const responseType = "boolean"
    const dateFormat = "dd/mm/yyyy"
    return validateDate(rowDate, responseType, dateFormat)
  },

  // AMN - Checking the date is DD/MM/YYYY (day with two digits)
  evalDate(value) { 
    if (value.length != 10) return false
    const regexDate = new RegExp('[0-9]{2}\/{1}[0-9]{2}\/{1}[0-9]{4}')
    return regexDate.test(value)
  },

  async sendServiceDeskIssue({ email, companyId, companyName, subject, description }) {
    if (typeof email !== 'string') throw TypeError(`email:${email} is not a string`)
    if (!email.trim()) throw Error('email is empty or blank')
    if (typeof companyId !== 'string') throw TypeError(`companyId:${companyId} is not a string`)
    if (!companyId.trim()) throw Error('companyId is empty or blank')
    if (typeof companyName !== 'string') throw TypeError(`companyName:${companyName} is not a string`)
    if (!companyName.trim()) throw Error('companyName is empty or blank')
    if (typeof subject !== 'string') throw TypeError(`subject:${subject} is not a string`)
    if (!subject.trim()) throw Error('subject is empty or blank')
    if (typeof description !== 'string') throw TypeError(`description:${description} is not a string`)
    if (!description.trim()) throw Error('description is empty or blank')

    const method = 'POST'

    const data = {
      email,
      companyId,
      companyName,
      subject,
      description,
      userId: this._userId()
    }

    return this._call('serviceDesk/issue', data, method)
  },
  /**
   *
   * @param {String} path Path given to the api
   * @param {object} payload
   * @param {String} method METHOD given
   *
   * @returns {Promise} Information about the path and method specified
   */
  _call(path, payload, method, callback) {
    let status
    const headers = {
      Authorization: `Bearer ${this._token()}`,
      'Content-Type': 'application/json; charset=utf-8'
    }
    return fetch(`${this.url}/${path}`, {
      headers,
      method,
      body: JSON.stringify(payload)
    })
      .then(res => {
        status = res.status
        return res.json()
      })
      .then(res => {
        // if (res.error) throw Error(res.error)
        if (callback) {
          if (status === 200 || status === 201) { callback(res) }
        }

        if (res && res.error === 'jwt expired') {
          return this.logout()
        }
        return { ...res, status }
      })
  },

  /**
   *
   * @param {String} path Path given to the api
   * @param {String} method METHOD given
   *
   * @returns {Promise} Information about the path and method specified
   */

  // This method and above _call should be joined in one. Check whether we can pass a body empty here. Then fusion both methods.
   _callGet(path, method, callback) {
    let status
    const headers = {
      Authorization: `Bearer ${this._token()}`,
      'Content-Type': 'application/json; charset=utf-8'
    }
    return fetch(`${this.url}/${path}`, {
      headers,
      method
    })
      .then(res => {
        status = res.status
        return res.json()
      })
      .then(res => {
        if (callback) {
          if (status === 200 || status === 201) { callback(res) }
        }

        if (res && res.error === 'jwt expired') {
          return this.logout()
        }
        return { ...res, status }
      })
  },

  login({ email, password }) {
    if (typeof email !== 'string') throw TypeError(`${email} is not a string`)
    if (typeof password !== 'string') throw TypeError(`${password} is not a string`)

    if (!email.trim()) throw Error('email is empty or blank')
    if (!password.trim()) throw Error('password is empty or blank')

    const method = 'POST'

    return this._call('auth', { email, password }, method, res => {
      
      const { userid, token, userdata: { adminid, distributorid, tenantid, notcrosssales, rolename } } = res.data

      sessionStorage.setItem('roleName', rolename)
      sessionStorage.setItem('userData', JSON.stringify(res.data.userdata))
      sessionStorage.setItem('userId', userid)
      sessionStorage.setItem('token', token)
      sessionStorage.setItem('distributorId', distributorid)
      sessionStorage.setItem('tenantId', tenantid)
      sessionStorage.setItem('notCrossSales', notcrosssales)
      sessionStorage.setItem('adminId', adminid)
    })
  },

  loadTableOfTables() {
    const method = 'GET'

    return this._callGet(`admin/tableOfTables?userId=${this._userId().toString()}`, method, res => {
      res.data.map(tableOftable => {
        return {
          tableoftablesid: tableOftable.tableoftablesid,
          tableoftablesname: tableOftable.tableoftablesname,
          tableoftablesdata: tableOftable.tableoftablesdata,
          tableoftablesdescription: tableOftable.tableoftablesdescription,
          active: tableOftable.active
        }
      })
    })
  },

  getADSVersion() {
    const method = 'GET'
    console.log("here1")
    return this._callGet('admin/tableOfTables/adsversion', method, res => {
      console.log("res: ", res)
      res.data.map(version => {
        return {
          version
        }
      })
    })
  },

  async logout() {
    try {
      const succesfullMessage = 'log out successfull'
      sessionStorage.removeItem('userId')
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('user')
      sessionStorage.removeItem('distributorId')
      sessionStorage.removeItem('tenantId')
      sessionStorage.removeItem('notCrossSales')
      sessionStorage.removeItem('roleName')
      return succesfullMessage
    } catch (error) {
      throw Error(error.message)
    }
  },

  retrieveUser(userId) {
    if (typeof userId !== 'string') throw TypeError(`userId:${userId} is not a string`)

    if (!userId.trim()) throw Error('userId is empty or blank')

    const method = 'POST'

    const data = {
      userId
    }
    return this._call('users/id', data, method)
  },

  unblockUser (email) {
    if (typeof email !== 'string') throw TypeError(`email:${email} is not a string`)

    if (!email.trim()) throw Error('email is empty or blank')

    const method = 'POST'

    const data = {
      email,
      userId: this._userId(),
      token: this._token()
    }
    return this._call('users/unblock', data, method)
  },

  updateUser ({ userName, userEmail, userPhone, password, newPassword, notify, lang }) {
    if (typeof userName !== 'string') throw TypeError(`userName:${userName} is not a string`)
    if (!userName.trim()) throw Error('userName is empty or blank')
    if (typeof userEmail !== 'string') throw TypeError(`userEmail:${userEmail} is not a string`)
    if (!userEmail.trim()) throw Error('userEmail is empty or blank')
    if (typeof password !== 'string') throw TypeError(`password:${password} is not a string`)
    if (!password.trim()) throw Error('password is empty or blank')
    if (typeof notify !== 'string') throw TypeError(`notify:${notify} is not a string`)
    if (!notify.trim()) throw Error('notify is empty or blank')
    if (typeof lang !== 'string') throw TypeError(`lang:${lang} is not a string`)
    if (!lang.trim()) throw Error('lang is empty or blank')


    const method = 'PATCH'

    const data = {
      userId: this.sessionId.toString(),
      username: userName,
      phone: userPhone,
      email: userEmail,
      newPassword,
      password,
      notify,
      lang
    }

    return this._call('users/update', data, method)
  },

  getUserTenant () {
    const method = 'POST'

    const data = {
      userId: this._userId().toString()
    }
    return this._call('users/tenant', data, method)
  },

  getUserDistributorTenants () {
    const method = 'POST'

    const data = {
      userId: this._userId().toString(),
      userEmail: JSON.parse(this._user()).useremail
    }
    return this._call('users/distributor/tenants', data, method)
  },


  registerUserDistributor({ userName, phone, newPassword }) {
    if (typeof newPassword !== 'string') throw TypeError(`newPassword:${newPassword} is not a string`)
    if (!newPassword.trim()) throw Error('newPassword is empty or blank')
    if (typeof userName !== 'string') throw TypeError(`userName:${userName} is not a string`)
    if (!userName.trim()) throw Error('userName is empty or blank')
    if (typeof phone !== 'string') throw TypeError(`phone:${phone} is not a string`)
    if (!phone.trim()) throw Error('phone is empty or blank')

    const method = 'PATCH'
    const token = window.location.href.split('/').slice(-1)[0]
    const data = {
      userName,
      phone,
      newPassword
    }
    return this._call(`users/auth/registerUserDistributor/${token}`, data, method)
  },

  // ------- Product Tenants -------- //

  getProductTenants(userId) {
    if (!userId) {
      userId = this._userId().toString()
    }
    if (typeof userId !== 'string') throw TypeError(`userId:${userId} is not a string`)

    if (!userId.trim()) throw Error('userId is empty or blank')

    const method = 'POST'

    const data = {
      userId,
      tenantId: this._tenantId().toString()
    }
    return this._call('productsTenant', data, method)
  },

  getProductsTenantIds(userId) {
    if (!userId) {
      userId = this._userId().toString()
    }
    if (typeof userId !== 'string') throw TypeError(`userId:${userId} is not a string`)

    if (!userId.trim()) throw Error('userId is empty or blank')

    const method = 'GET'

    return this._callGet(`productsTenantIds?userId=${userId}&tenantId=${this._tenantId().toString()}`, method)
  },

  getOneProductTenant({ productTenantId }) {
    if (typeof productTenantId !== 'string') throw TypeError(`productTenantId:${productTenantId} is not a string`)

    if (!productTenantId.trim()) throw Error('productTenantId is empty or blank')

    const method = 'POST'

    const data = {
      productTenantId,
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString()
    }

    return this._call('productsTenant/id', data, method)
  },

  getOneTenantClientsGroup({ clientGroupId }) {
    if (typeof clientGroupId !== 'string') throw TypeError(`clientGroupId:${clientGroupId} is not a string`)

    if (!clientGroupId.trim()) throw Error('clientGroupId is empty or blank')

    const method = 'GET'

    return this._callGet(`group/clientsGroupOne?userId=${this._userId().toString()}&clientGroupId=${clientGroupId}`, method)
  },

  getTenantClientsWithSales({ tenantId }) {
    if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)

    if (!tenantId.trim()) throw Error('tenantId is empty or blank')

    const method = 'GET'

    return this._callGet(`group/clientsWithSales?userId=${this._userId().toString()}&tenantId=${tenantId}`, method)
  },

  insertProductTenant({ productTenantCode, productName, productBrandId, productBusinessUnitId, productGroupId, productDescription, productSetId }) {
    if (typeof productTenantCode !== 'string') throw TypeError(`productTenantCode:${productTenantCode} is not a string`)
    if (!productTenantCode.trim()) throw Error('productTenantCode is empty or blank')

    if (typeof productName !== 'string') throw TypeError(`productName:${productName} is not a string`)
    if (typeof productBrandId !== 'string') throw TypeError(`productBrandId:${productBrandId} is not a string`)
    if (typeof productBusinessUnitId !== 'string') throw TypeError(`productBusinessUnitId:${productBusinessUnitId} is not a string`)
    if (typeof productSetId !== 'string') throw TypeError(`productSetId:${productSetId} is not a string`)
    if (typeof productGroupId !== 'string') throw TypeError(`productGroupId:${productGroupId} is not a string`)
    if (typeof productDescription !== 'string') throw TypeError(`productDescription:${productDescription} is not a string`)

    const method = 'POST'

    const data = {
      productTenantCode,
      productName,
      productBrandId,
      productBusinessUnitId,
      productGroupId,
      productSetId,
      productDescription,
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      distributorId: this._distributorId().toString()
    }
    
    return this._call('productsTenant/add', data, method)
  },

  updateProductTenant({ productTenantId, productTenantCode, productName, productBrandId, productBusinessUnitId, productGroupId, productSetId, productDescription }) {
    if (typeof productTenantId !== 'string') throw TypeError(`productTenantId:${productTenantId} is not a string`)
    if (!productTenantId.trim()) throw Error('productTenantId is empty or blank')

    if (typeof productTenantCode !== 'string') throw TypeError(`productTenantCode:${productTenantCode} is not a string`)
    if (!productTenantCode.trim()) throw Error('productTenantCode is empty or blank')

    if (typeof productName !== 'string') throw TypeError(`productName:${productName} is not a string`)
    if (typeof productBrandId !== 'string') throw TypeError(`productBrandId:${productBrandId} is not a string`)
    if (typeof productBusinessUnitId !== 'string') throw TypeError(`productBusinessUnitId:${productBusinessUnitId} is not a string`)
    if (typeof productGroupId !== 'string') throw TypeError(`productGroupId:${productGroupId} is not a string`)
    if (typeof productSetId !== 'string') throw TypeError(`productSetId:${productSetId} is not a string`)
    if (typeof productDescription !== 'string') throw TypeError(`productDescription:${productDescription} is not a string`)

    const method = 'PATCH'

    const data = {
      productTenantId,
      productTenantCode,
      productName,
      productBrandId,
      productBusinessUnitId,
      productGroupId,
      productSetId,
      productDescription,
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      distributorId: this._distributorId().toString()
    }

    return this._call('productsTenant/update', data, method)
  },

  deleteProductTenant({ productTenantId }) {
    if (typeof productTenantId !== 'string') throw TypeError(`productTenantId:${productTenantId} is not a string`)
    if (!productTenantId.trim()) throw Error('productTenantId is empty or blank')

    const method = 'DELETE'

    const data = {
      userId: this._userId(),
      tenantId: this._tenantId(),
      productTenantId,
      distributorId: this._distributorId()
    }
    return this._call('productsTenant/delete', data, method)
  },

  activateProductTenant({ productTenantId }) {
    if (typeof productTenantId !== 'string') throw TypeError(`productTenantId:${productTenantId} is not a string`)
    if (!productTenantId.trim()) throw Error('productTenantId is empty or blank')

    const method = 'POST'

    const data = {
      userId: this._userId(),
      tenantId: this._tenantId(),
      productTenantId,
      distributorId: this._distributorId()
    }
    return this._call('productsTenant/activate', data, method)
  },

  getProductTenantCorrespondance({ productTenantId }) {
    if (typeof productTenantId !== 'string') throw TypeError(`productTenantId:${productTenantId} is not a string`)
    if (!productTenantId.trim()) throw Error('productTenantId is empty or blank')

    const method = 'POST'

    const data = {
      userId: this._userId(),
      tenantId: this._tenantId(),
      distributorId: this._distributorId(),
      productTenantId
    }

    return this._call('productsTenant/correspondance', data, method)
  },

  // ------- End Product Tenants ------- //

  // ------- Product Brands ------- //
  getProductBrands(userId) {
    if (typeof userId !== 'string') throw TypeError(`userId:${userId} is not a string`)

    if (!userId.trim()) throw Error('userId is empty or blank')

    const method = 'POST'

    const data = {
      userId,
      tenantId: this._tenantId().toString()
    }

    return this._call('productBrand', data, method)
  },

  getOneProductBrand({ userId, productBrandId }) {
    if (typeof userId !== 'string') throw TypeError(`userId:${userId} is not a string`)
    if (typeof productBrandId !== 'string') throw TypeError(`productBrandId:${productBrandId} is not a string`)

    if (!userId.trim()) throw Error('userId is empty or blank')
    if (!productBrandId.trim()) throw Error('productBrandId is empty or blank')

    const method = 'POST'

    const data = {
      userId,
      tenantId: this._tenantId().toString(),
      productBrandId
    }

    return this._call('productBrand/id', data, method)
  },

  insertProductBrand(productBrandName) {
    if (typeof productBrandName !== 'string') throw TypeError(`productBrandName:${productBrandName} is not a string`)

    if (!productBrandName.trim()) throw Error('productBrandName is empty or blank')

    const method = 'POST'

    const data = {
      userId: this.sessionId,
      tenantId: this._tenantId().toString(),
      productBrandName
    }

    return this._call('productBrand/add', data, method)
  },

  updateProductBrand({ productBrandId, name }) {
    if (typeof name !== 'string') throw TypeError(`name:${name} is not a string`)
    if (typeof productBrandId !== 'string') throw TypeError(`productBrandId:${productBrandId} is not a string`)
    if (!name.trim()) throw Error('name is empty or blank')
    if (!productBrandId.trim()) throw Error('productBrandId is empty or blank')

    const method = 'PATCH'

    const data = {
      userId: this.sessionId,
      tenantId: this._tenantId().toString(),
      newProductBrandName: name,
      productBrandId
    }

    return this._call('productBrand/update', data, method)
  },

  deleteProductBrand({ productBrandId }) {
    if (typeof productBrandId !== 'string') throw TypeError(`productBrandId:${productBrandId} is not a string`)
    if (!productBrandId.trim()) throw Error('productBrandId is empty or blank')

    const method = 'DELETE'

    const data = {
      userId: this.sessionId,
      tenantId: this._tenantId().toString(),
      productBrandId
    }

    return this._call('productBrand/delete', data, method)
  },

  activateProductBrand({ productBrandId }) {
    if (typeof productBrandId !== 'string') throw TypeError(`productBrandId:${productBrandId} is not a string`)
    if (!productBrandId.trim()) throw Error('productBrandId is empty or blank')

    const method = 'POST'

    const data = {
      userId: this.sessionId,
      tenantId: this._tenantId().toString(),
      productBrandId
    }

    return this._call('productBrand/activate', data, method)
  },

  getRelatedProductBrands({ userId, productBrandId }) {
    if (typeof userId !== 'string') throw TypeError(`userId:${userId} is not a string`)
    if (typeof productBrandId !== 'string') throw TypeError(`productBrandId:${productBrandId} is not a string`)

    if (!userId.trim()) throw Error('userId is empty or blank')
    if (!productBrandId.trim()) throw Error('productBrandId is empty or blank')

    const method = 'POST'

    const data = {
      userId,
      tenantId: this._tenantId().toString(),
      productBrandId
    }

    return this._call('productBrand/related', data, method)
  },

  // ------- End Product Brands ------- //

  // ------- Product Prices ------- //
  getProductPrices() {
   
    const method = 'POST'

    const data = {
      userId: this.sessionId,
      tenantId: this._tenantId().toString()
    }

    return this._call('productPrices', data, method)
  },

  getOneProductYearPrices({ productTenantId, productPriceId }) {
    const method = 'POST'

    const data = {
      userId: this.sessionId,
      tenantId: this._tenantId().toString(), 
      productTenantId: productTenantId.toString(),
      productPriceId: productPriceId.toString()
    }

    return this._call('productPrices/oneProductPrices', data, method)
  },

  insertProductValue({ productTenantId, productPrice, productPriceFromDate, productPriceToDate, productPriceCurrency }) {
    if (typeof productTenantId !== 'string') throw TypeError(`productTenantId:${productTenantId} is not a string`)

    if (!productTenantId.trim()) throw Error('productPriceId is empty or blank')

    const method = 'POST'

    return this._call('productPrices/add', { productTenantId, productPrice, productPriceFromDate, productPriceToDate, productPriceCurrency, userId: this.sessionId, tenantId: this._tenantId().toString() }, method)
  },

  updateProductValue({ productPriceId, productPrice, productPriceFromDate, productPriceToDate, productPriceCurrency }) {
    if (typeof productPriceId !== 'string') throw TypeError(`productPriceId:${productPriceId} is not a string`)

    if (!productPriceId.trim()) throw Error('productPriceId is empty or blank')

    const method = 'POST'

    return this._call('productPrices/update', { productPriceId, productPrice, productPriceFromDate, productPriceToDate, productPriceCurrency, userId: this.sessionId, tenantId: this._tenantId().toString() }, method)
  },

  // ------- End Product Prices ------- //

  // ------- Product Bussiness Units ------- //
  getProductBusinessUnits(userId) {
    if (typeof userId !== 'string') throw TypeError(`userId:${userId} is not a string`)

    if (!userId.trim()) throw Error('userId is empty or blank')

    const method = 'POST'
    return this._call('productBusinessUnit', { userId, tenantId: this._tenantId().toString() }, method)
  },

  getOneProductBusinessUnit({ userId, productBusinessUnitId }) {
    if (typeof userId !== 'string') throw TypeError(`userId:${userId} is not a string`)
    if (typeof productBusinessUnitId !== 'string') throw TypeError(`productBusinessUnitId:${productBusinessUnitId} is not a string`)

    if (!userId.trim()) throw Error('userId is empty or blank')
    if (!productBusinessUnitId.trim()) throw Error('productBusinessUnitId is empty or blank')

    const method = 'POST'

    return this._call('productBusinessUnit/id', { userId, tenantId: this._tenantId().toString(), productBusinessUnitId }, method)
  },

  insertProductBusinessUnit(productBusinessUnitName) {
    if (typeof productBusinessUnitName !== 'string') throw TypeError(`productBusinessUnitName:${productBusinessUnitName} is not a string`)

    if (!productBusinessUnitName.trim()) throw Error('productBusinessUnitName is empty or blank')

    const method = 'POST'

    return this._call('productBusinessUnit/add', { userId: this.sessionId, tenantId: this._tenantId().toString(), productBusinessUnitName }, method)
  },

  updateProductBusinessUnit({ productBusinessUnitId, name }) {
    if (typeof name !== 'string') throw TypeError(`name:${name} is not a string`)
    if (typeof productBusinessUnitId !== 'string') throw TypeError(`productBusinessUnitId:${productBusinessUnitId} is not a string`)

    if (!name.trim()) throw Error('name is empty or blank')
    if (!productBusinessUnitId.trim()) throw Error('productBusinessUnitId is empty or blank')

    const method = 'PATCH'

    return this._call('productBusinessUnit/update', { userId: this.sessionId, tenantId: this._tenantId().toString(), newProductBusinessUnitName: name, productBusinessUnitId }, method)
  },

  deleteProductBusinessUnit({ productBusinessUnitId }) {
    if (typeof productBusinessUnitId !== 'string') throw TypeError(`productBusinessUnitId:${productBusinessUnitId} is not a string`)
    if (!productBusinessUnitId.trim()) throw Error('productBusinessUnitId is empty or blank')

    const method = 'DELETE'

    return this._call('productBusinessUnit/delete', { userId: this.sessionId, tenantId: this._tenantId().toString(), productBusinessUnitId }, method)
  },

  activateProductBusinessUnit({ productBusinessUnitId }) {
    if (typeof productBusinessUnitId !== 'string') throw TypeError(`productBusinessUnitId:${productBusinessUnitId} is not a string`)
    if (!productBusinessUnitId.trim()) throw Error('productBusinessUnitId is empty or blank')

    const method = 'POST'

    return this._call('productBusinessUnit/activate', { userId: this.sessionId, tenantId: this._tenantId().toString(), productBusinessUnitId }, method)
  },

  getRelatedProductBusinessUnits({ userId, productBusinessUnitId }) {
    if (typeof userId !== 'string') throw TypeError(`userId:${userId} is not a string`)
    if (typeof productBusinessUnitId !== 'string') throw TypeError(`productBusinessUnitId:${productBusinessUnitId} is not a string`)

    if (!userId.trim()) throw Error('userId is empty or blank')
    if (!productBusinessUnitId.trim()) throw Error('productBusinessUnitId is empty or blank')

    const method = 'POST'

    return this._call('productBusinessUnit/related', { userId, tenantId: this._tenantId().toString(), productBusinessUnitId }, method)
  },

  // ------- End Product Business Units ------- //

  // ------- Product Groups ------- //
  getProductGroups(userId) {
    if (typeof userId !== 'string') throw TypeError(`userId:${userId} is not a string`)

    if (!userId.trim()) throw Error('userId is empty or blank')

    const method = 'POST'
    return this._call('productGroup', { userId, tenantId: this._tenantId().toString() }, method)
  },

  getOneProductGroup({ userId, productGroupId }) {
    if (typeof userId !== 'string') throw TypeError(`userId:${userId} is not a string`)
    if (typeof productGroupId !== 'string') throw TypeError(`productGroupId:${productGroupId} is not a string`)

    if (!userId.trim()) throw Error('userId is empty or blank')
    if (!productGroupId.trim()) throw Error('productGroupId is empty or blank')

    const method = 'POST'

    return this._call('productGroup/id', { userId, tenantId: this._tenantId().toString(), productGroupId }, method)
  },

  insertProductGroup(productGroupName) {
    if (typeof productGroupName !== 'string') throw TypeError(`productGroupName:${productGroupName} is not a string`)

    if (!productGroupName.trim()) throw Error('productGroupName is empty or blank')

    const method = 'POST'

    return this._call('productGroup/add', { userId: this.sessionId, tenantId: this._tenantId().toString(), productGroupName }, method)
  },

  updateProductGroup({ productGroupId, name }) {
    if (typeof name !== 'string') throw TypeError(`name:${name} is not a string`)
    if (typeof productGroupId !== 'string') throw TypeError(`productGroupId:${productGroupId} is not a string`)

    if (!name.trim()) throw Error('name is empty or blank')
    if (!productGroupId.trim()) throw Error('productGroupId is empty or blank')

    const method = 'PATCH'

    return this._call('productGroup/update', { userId: this.sessionId, tenantId: this._tenantId().toString(), newProductGroupName: name, productGroupId }, method)
  },

  deleteProductGroup({ productGroupId }) {
    if (typeof productGroupId !== 'string') throw TypeError(`productGroupId:${productGroupId} is not a string`)
    if (!productGroupId.trim()) throw Error('productGroupId is empty or blank')

    const method = 'DELETE'

    return this._call('productGroup/delete', { userId: this.sessionId, tenantId: this._tenantId().toString(), productGroupId }, method)
  },

  activateProductGroup({ productGroupId }) {
    if (typeof productGroupId !== 'string') throw TypeError(`productGroupId:${productGroupId} is not a string`)
    if (!productGroupId.trim()) throw Error('productGroupId is empty or blank')

    const method = 'POST'

    return this._call('productGroup/activate', { userId: this.sessionId, tenantId: this._tenantId().toString(), productGroupId }, method)
  },

  getRelatedProductGroups({ userId, productGroupId }) {
    if (typeof userId !== 'string') throw TypeError(`userId:${userId} is not a string`)
    if (typeof productGroupId !== 'string') throw TypeError(`productGroupId:${productGroupId} is not a string`)

    if (!userId.trim()) throw Error('userId is empty or blank')
    if (!productGroupId.trim()) throw Error('productGroupId is empty or blank')

    const method = 'POST'

    return this._call('productGroup/related', { userId, tenantId: this._tenantId().toString(), productGroupId }, method)
  },

  // ------- End Product Groups ------- //

  // ------- Product Sets ------- //
  getProductSets() {
    const method = 'POST'

    const data = {
      userId: this._userId(),
      tenantId: this._tenantId()
    }
    return this._call('productSet', data, method)
  },

  getOneProductSet({ userId, productSetId }) {
    if (typeof userId !== 'string') throw TypeError(`userId:${userId} is not a string`)
    if (typeof productSetId !== 'string') throw TypeError(`productSetId:${productSetId} is not a string`)

    if (!userId.trim()) throw Error('userId is empty or blank')
    if (!productSetId.trim()) throw Error('productSetId is empty or blank')

    const method = 'POST'

    return this._call('productSet/id', { userId, tenantId: this._tenantId().toString(), productSetId }, method)
  },

  insertProductSet(productSetName) {
    if (typeof productSetName !== 'string') throw TypeError(`productSetName:${productSetName} is not a string`)

    if (!productSetName.trim()) throw Error('productSetName is empty or blank')

    const method = 'POST'

    return this._call('productSet/add', { userId: this.sessionId, tenantId: this._tenantId().toString(), productSetName }, method)
  },

  updateProductSet({ productSetId, name }) {
    if (typeof name !== 'string') throw TypeError(`name:${name} is not a string`)
    if (typeof productSetId !== 'string') throw TypeError(`productSetId:${productSetId} is not a string`)

    if (!name.trim()) throw Error('name is empty or blank')
    if (!productSetId.trim()) throw Error('productSetId is empty or blank')

    const method = 'PATCH'

    return this._call('productSet/update', { userId: this.sessionId, tenantId: this._tenantId().toString(), newProductSetName: name, productSetId }, method)
  },

  deleteProductSet({ productSetId }) {
    if (typeof productSetId !== 'string') throw TypeError(`productSetId:${productSetId} is not a string`)
    if (!productSetId.trim()) throw Error('productSetId is empty or blank')

    const method = 'DELETE'

    return this._call('productSet/delete', { userId: this.sessionId, tenantId: this._tenantId().toString(), productSetId }, method)
  },

  activateProductSet({ productSetId }) {
    if (typeof productSetId !== 'string') throw TypeError(`productSetId:${productSetId} is not a string`)
    if (!productSetId.trim()) throw Error('productSetId is empty or blank')

    const method = 'POST'

    return this._call('productSet/activate', { userId: this.sessionId, tenantId: this._tenantId().toString(), productSetId }, method)
  },

  getRelatedProductSets({ userId, productSetId }) {
    if (typeof userId !== 'string') throw TypeError(`userId:${userId} is not a string`)
    if (typeof productSetId !== 'string') throw TypeError(`productSetId:${productSetId} is not a string`)

    if (!userId.trim()) throw Error('userId is empty or blank')
    if (!productSetId.trim()) throw Error('productSetId is empty or blank')

    const method = 'POST'

    return this._call('productSet/related', { userId, tenantId: this._tenantId().toString(), productSetId }, method)
  },

  // ------- End Product Sets ------- //

  // -------- Client Groups ------ //

  getTenantClientsGroup(tenantId, active=true) {
    if (!tenantId) {
      tenantId = this._tenantId().toString()
    }
    if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)

    if (!tenantId.trim()) throw Error('tenantId is empty or blank')

    const method = 'GET'
    
    return this._callGet(`group/clientsGroups?userId=${this._userId().toString()}&tenantId=${tenantId}&active=${active}`, method)
  },

  insertClientsGroupTenant({ clientName, clientDescription, clientsIDsToAdd }, { tenantId }) {
    if (!tenantId) {
      tenantId = this._tenantId().toString()
    }
    if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)
    if (!tenantId.trim()) throw Error('tenantId is empty or blank')

    if (typeof clientName !== 'string') throw TypeError(`clientName:${clientName} is not a string`)
    if (!clientName.trim()) throw Error('clientName is empty or blank')

    const method = 'POST'

    return this._call('group/clientsGroups/add', 
                              { clientGroupName: clientName, clientGroupDescription: clientDescription, 
                                 tenantId, userId: this._userId().toString(), clientIds: clientsIDsToAdd }, method)
  },

  updateClientsGroupTenant({ clientGroupId, clientName, clientDescription, clientsIDsToAdd, clientsIDsToDelete }, { tenantId }) {
    
    if (typeof clientGroupId !== 'string') throw TypeError(`clientGroupId:${clientGroupId} is not a string`)
    if (!clientGroupId.trim()) throw Error('clientGroupId is empty or blank')

    if (!tenantId) {
      tenantId = this._tenantId().toString()
    }
    if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)
    if (!tenantId.trim()) throw Error('tenantId is empty or blank')

    if (typeof clientName !== 'string') throw TypeError(`clientName:${clientName} is not a string`)
    if (!clientName.trim()) throw Error('clientName is empty or blank')

    const method = 'POST'

    return this._call('group/clientsGroups/update', 
                              { clientGroupId, clientGroupName: clientName, clientGroupDescription: clientDescription, 
                                tenantId, userId: this._userId().toString(), deleteClientIds: clientsIDsToDelete, 
                                insertClientIds: clientsIDsToAdd }, method)
  },

  getClientsGroupObjectives(clientGroupId, userId) {
    if (!userId) {
      userId = this._userId().toString()
    }
    if (typeof userId !== 'string') throw TypeError(`userId:${userId} is not a string`)
    if (!userId.trim()) throw Error('userId is empty or blank')

    const method = 'GET'
    
    return this._callGet(`group/objectives?userId=${userId}&clientGroupId=${clientGroupId["clientGroupId"]}`, method)
  },

  activateTenantClientsGroup({ clientGroupId }) {
    if (typeof clientGroupId !== 'string') throw TypeError(`clientgroupid:${clientGroupId} is not a string`)
    if (!clientGroupId.trim()) throw Error('clientgroupid is empty or blank')

    const method = 'POST'

    const data = {
      clientGroupId,
      userId: this._userId().toString(),
    }
    return this._call('group/clientsGroups/activate', data, method)
  },

  deleteClientsGroupTenant({ clientGroupId }) {
    if (typeof clientGroupId !== 'string') throw TypeError(`clientGroupId:${clientGroupId} is not a string`)
    if (!clientGroupId.trim()) throw Error('clientGroupId is empty or blank')
    const method = 'POST'

    const data = {
      clientGroupId,
      userId: this._userId().toString()
    }
    return this._call('group/clientsGroups/inactivate', data, method)
  },

 // -------- End Client Groups ------ //

 // -------- Clients' Categories ------ //

getTenantClientsCategories(tenantId) { // active = null means all categories, = true means all categories false, and = false means all categories true
  if (!tenantId) {
    tenantId = this._tenantId().toString()
  }
  if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)

  if (!tenantId.trim()) throw Error('tenantId is empty or blank')

  const method = 'GET'
  
  return this._callGet(`group/clientsCategories?userId=${this._userId().toString()}&tenantId=${tenantId}`, method)
},

insertClientsCategoryTenant({ categoryName, categoryCode }, { tenantId }) {
  if (!tenantId) {
    tenantId = this._tenantId().toString()
  }
  if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)
  if (!tenantId.trim()) throw Error('tenantId is empty or blank')

  if (typeof categoryName !== 'string') throw TypeError(`categoryName:${categoryName} is not a string`)
  if (!categoryName.trim()) throw Error('categoryName is empty or blank')

  if (typeof categoryCode !== 'string') throw TypeError(`categoryCode:${categoryCode} is not a string`)
  if (!categoryCode.trim()) throw Error('categoryCode is empty or blank')

  const method = 'POST'

  return this._call('group/clientsCategory/add', { categoryName, categoryCode, tenantId, userId: this._userId().toString() }, method)
},

updateClientsCategoryTenant({ clientCategoryId, categoryName, categoryCode }, { tenantId }) {
    
  if (typeof clientCategoryId !== 'string') throw TypeError(`clientCategoryId:${clientCategoryId} is not a string`)
  if (!clientCategoryId.trim()) throw Error('clientCategoryId is empty or blank')

  if (!tenantId) {
    tenantId = this._tenantId().toString()
  }
  if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)
  if (!tenantId.trim()) throw Error('tenantId is empty or blank')

  if (typeof categoryName !== 'string') throw TypeError(`categoryName:${categoryName} is not a string`)
  if (!categoryName.trim()) throw Error('categoryName is empty or blank')

  if (typeof categoryCode !== 'string') throw TypeError(`categoryCode:${categoryCode} is not a string`)
  if (!categoryCode.trim()) throw Error('categoryCode is empty or blank')

  const method = 'POST'

  return this._call('group/clientsCategory/update', 
                            { clientCategoryId, categoryName, categoryCode, 
                              tenantId, userId: this._userId().toString() }, method)
},

getOneTenantClientsCategory(clientCategoryId) { 

  if (typeof clientCategoryId !== 'string') throw TypeError(`clientCategoryId:${clientCategoryId} is not a string`)

  const method = 'GET'

  return this._callGet(`group/clientsCategoryOne?userId=${this._userId().toString()}&tenantId=${this._tenantId().toString()}&clientCategoryId=${clientCategoryId}`, method)
},


activateClientCategory({ clientCategoryId }) {
  if (typeof clientCategoryId !== 'string') throw TypeError(`clientcategoryid:${clientCategoryId} is not a string`)
  if (!clientCategoryId.trim()) throw Error('clientCategoryId is empty or blank')

  const method = 'POST'

  const data = {
    clientCategoryId,
    userId: this._userId().toString(),
  }
  return this._call('group/clientsCategories/activate', data, method)
},

deleteClientCategory({ clientCategoryId }) {
  if (typeof clientCategoryId !== 'string') throw TypeError(`clientcategoryid:${clientCategoryId} is not a string`)
  if (!clientCategoryId.trim()) throw Error('clientCategoryId is empty or blank')
  const method = 'POST'

  const data = {
    clientCategoryId,
    userId: this._userId().toString()
  }
  return this._call('group/clientsCategories/inactivate', data, method)
},


// -------- End Clients' Categories ------ //

 // -------- Tenant Client Groups Objectives ------ //

  getTenantObjectives(tenantId) {
    if (!tenantId) {
      tenantId = this._tenantId().toString()
    }
    if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)

    if (!tenantId.trim()) throw Error('tenantId is empty or blank')

    const method = 'GET'
    
    return this._callGet(`objective?userId=${this._userId().toString()}&tenantId=${tenantId}`, method)
  },

  activateTenantObjective(objectiveId, tenantId) {
    if (typeof objectiveId !== 'string') throw TypeError(`objectiveid:${objectiveId} is not a string`)
    if (!objectiveId.trim()) throw Error('objectiveid is empty or blank')

    const method = 'POST'

    const data = {
      objectiveId,
      tenantId,
      userId: this._userId().toString(),
    }
    return this._call('objective/activate', data, method)
  },

  inactivateTenantObjective(objectiveId, tenantId) {
    if (typeof objectiveId !== 'string') throw TypeError(`objectiveid:${objectiveId} is not a string`)
    if (!objectiveId.trim()) throw Error('objectiveid is empty or blank')

    const method = 'POST'

    const data = {
      objectiveId,
      tenantId,
      userId: this._userId().toString(),
    }
    return this._call('objective/delete', data, method)
  },

  updateTenantObjective(objectiveId, objectiveCode, objectiveName, objectiveDescription, clientGroupId, startTime, endTime, previousEndTime, totalValueObjective, agreementTypeId, valueType,
                                        insertProductTenantIds= "", insertProductBrandIds ="", insertProductBusinessUnitIds="", 
                                        insertProductGroupIds="", insertProductSetIds="", insertDistributorIds="", 
                                        insertPostalCodeIds="", insertCountryNames="", insertRegionNames="", insertProvinceNames="", 
                                        deleteProductTenantIds="", deleteProductBrandIds="", deleteProductBusinessUnitIds="", 
                                        deleteProductGroupIds="", deleteProductSetIds="", deleteDistributorIds="", deletePostalCodeIds="", 
                                        deleteCountryNames="", deleteRegionNames="",  deleteProvinceNames="") {
    if (typeof objectiveId !== 'string') throw TypeError(`objectiveid:${objectiveId} is not a string`)
    if (!objectiveId.trim()) throw Error('objectiveid is empty or blank')

    const method = 'POST'

    const data = {
      objectiveId, objectiveCode, objectiveName, objectiveDescription, clientGroupId, startTime, endTime, previousEndTime, totalValueObjective, agreementTypeId, valueType,
      insertProductTenantIds, insertProductBrandIds, insertProductBusinessUnitIds, insertProductGroupIds, insertProductSetIds, 
      insertDistributorIds, insertPostalCodeIds, insertCountryNames, insertRegionNames, insertProvinceNames, 
      deleteProductTenantIds, deleteProductBrandIds, deleteProductBusinessUnitIds, deleteProductGroupIds, deleteProductSetIds, 
      deleteDistributorIds, deletePostalCodeIds, deleteCountryNames, deleteRegionNames, deleteProvinceNames, 
      userId: this._userId().toString()
    }
    return this._call('objective/update', data, method)
  },

  insertTenantObjective(objectiveCode, objectiveName, objectiveDescription, clientGroupId, startTime, endTime, totalValueObjective, agreementTypeId, valueType, tenantId,
                                      productTenantIds= "", productBrandIds ="", productBusinessUnitIds="", 
                                      productGroupIds="", productSetIds="", distributorIds="", 
                                      postalCodeIds="", countryNames="", regionNames="", provinceNames="") {
    if (typeof tenantId !== 'string') throw TypeError(`tenantid:${tenantId} is not a string`)
    if (!tenantId.trim()) throw Error('tenantid is empty or blank')

    const method = 'POST'

    const data = {
      objectiveCode, objectiveName, objectiveDescription, clientGroupId, startTime, endTime, totalValueObjective, agreementTypeId, valueType, tenantId,
      productTenantIds, productBrandIds, productBusinessUnitIds, productGroupIds, productSetIds, 
      distributorIds, postalCodeIds, countryNames, regionNames, provinceNames, userId: this._userId().toString()
    }
    return this._call('objective/create', data, method)
  },

  getRelatedClientsGroupObjectives({ clientGroupId, userId }) {
    if (typeof userId !== 'string') throw TypeError(`userId:${userId} is not a string`)
    if (typeof clientGroupId !== 'string') throw TypeError(`clientGroupId:${clientGroupId} is not a string`)

    if (!userId.trim()) throw Error('userId is empty or blank')
    if (!clientGroupId.trim()) throw Error('clientGroupId is empty or blank')

    const method = 'GET'

    return this._callGet(`group/objectives?userId=${userId}&clientGroupId=${clientGroupId} `, method)
  },

  getPostalCodes() {
    const method = 'GET'

    return this._callGet(`postalCodes?userId=${this._userId().toString()}`, method)
  },

  getOnePostalCode(postalCode) {
    if (typeof postalCode !== 'string') throw TypeError(`Postal code:${postalCode} is not a string`)

    const method = 'GET'

    return this._callGet(`postalCodes/one?userId=${this._userId().toString()}&postalCode=${postalCode}`, method)
  },

  getOnePostalCodeById(postalCodeId) {
    if (typeof postalCodeId !== 'number') throw TypeError(`Postal code id:${postalCodeId} is not a number`)

    const method = 'GET'

    return this._callGet(`postalCodes/onebyid?userId=${this._userId().toString()}&postalCodeId=${postalCodeId}`, method)
  },

  getCountriesFromPostalCodes() {
    const method = 'GET'

    return this._callGet(`postalCodes/countries?userId=${this._userId().toString()}`, method)
  },

  getRegionsFromPostalCodes() {
    const method = 'GET'

    return this._callGet(`postalCodes/regions?userId=${this._userId().toString()}`, method)
  },

  getProvincesFromPostalCodes() {
    const method = 'GET'

    return this._callGet(`postalCodes/provinces?userId=${this._userId().toString()}`, method)
  },

  getTenantAgreementsTypes(tenantId) {
    if (!tenantId) {
      tenantId = this._tenantId().toString()
    }
    if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)

    if (!tenantId.trim()) throw Error('tenantId is empty or blank')

    const method = 'GET'
    
    return this._callGet(`objective/agreementstypes?userId=${this._userId().toString()}&tenantId=${tenantId}`, method)
  },

  activateTenantAgreementType(agreementTypeId, tenantId) {
    if (typeof agreementTypeId !== 'string') throw TypeError(`agreementypeid:${agreementTypeId} is not a string`)
    if (!agreementTypeId.trim()) throw Error('agreementtypeid is empty or blank')

    const method = 'POST'

    const data = {
      agreementTypeId,
      tenantId,
      userId: this._userId().toString(),
    }
    return this._call('objective/agreementtype/activate', data, method)
  },

  inactivateTenantAgreementType(agreementTypeId, tenantId) {
    if (typeof agreementTypeId !== 'string') throw TypeError(`agreementypeid:${agreementTypeId} is not a string`)
    if (!agreementTypeId.trim()) throw Error('agreementtypeid is empty or blank')

    const method = 'POST'

    const data = {
      agreementTypeId,
      tenantId,
      userId: this._userId().toString(),
    }
    return this._call('objective/agreementtype/delete', data, method)
  },

  updateTenantAgreementType(agreementTypeId, agreementTypeName, tenantId) {
      if (typeof agreementTypeId !== 'string') throw TypeError(`agreementtypeid:${agreementTypeId} is not a string`)
      if (!agreementTypeId.trim()) throw Error('agreementtypeid is empty or blank')

      const method = 'POST'

      const data = { agreementTypeId, agreementTypeName, tenantId, userId: this._userId().toString() }
      return this._call('objective/agreementtype/update', data, method)
  },

  insertTenantAgreementType(agreementTypeName, tenantId) {
      if (typeof tenantId !== 'string') throw TypeError(`tenantid:${tenantId} is not a string`)
      if (!tenantId.trim()) throw Error('tenantid is empty or blank')

      const method = 'POST'

      const data = { agreementTypeName, tenantId, userId: this._userId().toString() }
      return this._call('objective/agreementtype/add', data, method)
  },

  // -------- End Tenant Client Groups Objectives ------ //

  // ------- Distributors ------- //

  getDistributors() {
    const method = 'POST'

    const data = {
      userId: this._userId(),
      tenantId: this._tenantId()
    }

    return this._call('distributor', data, method)
  },
  
  getInactiveDistributors() {
    const method = 'POST'

    const data = {
      userId: this._userId(),
      tenantId: this._tenantId()
    }

    return this._call('distributor/inactive', data, method)
  },

  getOneDistributorTenant({ distributorTenantId }) {
    if (typeof distributorTenantId !== 'string') throw TypeError(`distributorTenantId:${distributorTenantId} is not a string`)

    if (!distributorTenantId.trim()) throw Error('distributorTenantId is empty or blank')

    const method = 'POST'

    const data = {
      userId: this._userId(),
      tenantId: this._tenantId().toString(),
      distributorTenantId
    }

    return this._call('distributorTenant/id', data, method)
  },

  getOneDistributor({ distributorId, distributorIdentity }) {
    if (distributorId) {
      if (typeof distributorId !== 'string') throw TypeError(`distributorId:${distributorId} is not a string`)
      if (!distributorId.trim()) throw Error('distributorId is empty or blank')
    }
    if (distributorIdentity) {
      if (typeof distributorIdentity !== 'string') throw TypeError(`distributorIdentity:${distributorIdentity} is not a string`)
      if (!distributorIdentity.trim()) throw Error('distributorIdentity is empty or blank')
    }
    const method = 'POST'

    const data = {
      userId: this._userId(),
      distributorId,
      distributorIdentity
    }

    return this._call('distributor/id', data, method)
  },
  getAllDistributors() {
   
    const method = 'POST'

    const data = {
      userId: this._userId()
    }

    return this._call('distributor/all', data, method)
  },


  insertOneDistributorTenant({
    distributorCode,
    distributorIdentity,
    distributorName,
    postalCode,
    phone,
    alternatePhone,
    province,
    city,
    address,
    primaryContactId,
    isUploadBlocked,
    toMonthBlocked,
    salesStock: notifySalesStock,
    description
  }
  ) {
    if (typeof distributorCode !== 'string') throw TypeError(`distributorCode:${distributorCode} is not a string`)
    if (!distributorCode.trim()) throw Error('distributorCode is empty or blank')
    if (typeof distributorIdentity !== 'string') throw TypeError(`distributorIdentity:${distributorIdentity} is not a string`)
    if (!distributorIdentity.trim()) throw Error('distributorIdentity is empty or blank')
    if (typeof distributorName !== 'string') throw TypeError(`distributorName:${distributorName} is not a string`)
    if (!distributorName.trim()) throw Error('distributorName is empty or blank')

    const method = 'POST'

    const data = {
      userId: this._userId(),
      tenantId: this._tenantId(),
      distributorCode,
      distributorIdentity,
      distributorName,
      postalCode,
      phone,
      alternativePhone: alternatePhone,
      province,
      city,
      address,
      primaryContactId,
      isUploadBlocked,
      toMonthBlocked,
      salesStock: notifySalesStock,
      description
    }

    return this._call('distributorTenant/add', data, method)
  },

  updateDistributorTenant({
    distributorTenantId,
    distributorCode,
    distributorIdentity,
    distributorName,
    postalCode,
    phone,
    alternatePhone,
    province,
    city,
    address,
    primaryContactId,
    isUploadBlocked,
    toMonthBlocked,
    salesStock: notifySalesStock,
    description
  }
  ) {
    if (typeof distributorTenantId !== 'string') throw TypeError(`distributorTenantId:${distributorTenantId} is not a string`)
    if (!distributorTenantId.trim()) throw Error('distributorTenantId is empty or blank')
    if (typeof distributorCode !== 'string') throw TypeError(`distributorCode:${distributorCode} is not a string`)
    if (!distributorCode.trim()) throw Error('distributorCode is empty or blank')
    if (typeof distributorIdentity !== 'string') throw TypeError(`distributorIdentity:${distributorIdentity} is not a string`)
    if (!distributorIdentity.trim()) throw Error('distributorIdentity is empty or blank')
    if (typeof distributorName !== 'string') throw TypeError(`distributorName:${distributorName} is not a string`)
    if (!distributorName.trim()) throw Error('distributorName is empty or blank')

    const method = 'PATCH'

    const data = {
      userId: this._userId(),
      tenantId: this._tenantId(),
      distributorTenantId,
      distributorCode,
      distributorIdentity,
      distributorName,
      postalCode,
      phone,
      alternativePhone: alternatePhone,
      province,
      city,
      address,
      primaryContactId,
      isUploadBlocked,
      toMonthBlocked,  
      salesStock: notifySalesStock,
      description
    }

    return this._call('distributorTenant/update', data, method)
  },

  deleteDistributorTenant({ distributorTenantId }) {
    if (typeof distributorTenantId !== 'string') throw TypeError(`distributorTenantId:${distributorTenantId} is not a string`)
    if (!distributorTenantId.trim()) throw Error('distributorTenantId is empty or blank')

    const method = 'DELETE'

    const data = {
      distributorTenantId,
      tenantId: this._tenantId(),
      userId: this._userId()
    }

    return this._call('distributorTenant/delete', data, method)
  },

  activateDistributorTenant({ distributorTenantId }) {
    if (typeof distributorTenantId !== 'string') throw TypeError(`distributorTenantId:${distributorTenantId} is not a string`)
    if (!distributorTenantId.trim()) throw Error('distributorTenantId is empty or blank')

    const method = 'DELETE'

    const data = {
      distributorTenantId,
      tenantId: this._tenantId(),
      userId: this._userId()
    }

    return this._call('distributorTenant/activate', data, method)
  },

  getDistributorObjectives(distributorId) {
    if (!distributorId) {
      distributorId = this._distributorId().toString()
    }
    if (typeof distributorId !== 'string') throw TypeError(`distributorId:${distributorId} is not a string`)
  
    if (!distributorId.trim()) throw Error('distributorId is empty or blank')
  
    const method = 'GET'
    
    return this._callGet(`objective/distributor?userId=${this._userId().toString()}&distributorId=${distributorId}`, method)
  },

  downloadAllSales(salesData) {
    const method = 'POST'

    const data = {
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      salesData
    }
    return this._call('reports/downloadAllDistObjSales', data, method)
  },

  getDistributorTenantObjectives(tenantId) {
    if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)
    if (!tenantId.trim()) throw Error('tenantId is empty or blank')
    
    const method = 'GET'
    return this._callGet(`objective/distributortenant?userId=${this._userId().toString()}&tenantId=${tenantId}`, method)
  },

  getDistributorsObjective(objectiveId) {
    if (typeof objectiveId !== 'string') throw TypeError(`objectiveId:${objectiveId} is not a string`)

    if (!objectiveId.trim()) throw Error('objectiveId is empty or blank')

    const method = 'GET'
    
    return this._callGet(`objective/objectivedistributor?userId=${this._userId().toString()}&objectiveId=${objectiveId}`, method)
  },

  updateDistTenantObj(objectiveDistributorId, objectiveRealValue, objectiveOldToReplaceValue) {
    if (typeof objectiveDistributorId !== 'string') throw TypeError(`objectiveDistributorId:${objectiveDistributorId} is not a string`)
    if (!objectiveDistributorId.trim()) throw Error('objectiveDistributorId is empty or blank')

    const method = 'POST'

    const data = {
      objectiveDistributorId, objectiveRealValue, objectiveOldToReplaceValue, userId: this._userId().toString()
    }
    return this._call('objective/distributor/update', data, method)
  },
  
  updateObjDistributor(objectiveId, distributorId, distributorObjCode) {
    if (typeof objectiveId !== 'string') throw TypeError(`objectiveId:${objectiveId} is not a string`)
    if (!objectiveId.trim()) throw Error('objectiveId is empty or blank')
    if (typeof distributorId !== 'string') throw TypeError(`distributorId:${distributorId} is not a string`)
    if (!distributorId.trim()) throw Error('distributorId is empty or blank')

    const method = 'POST'

    const data = {
      objectiveId, distributorId, distributorObjCode, userId: this._userId().toString()
    }
    return this._call('objective/objectivedistributor/update', data, method)
  },

  insertDistTenantObj(objectiveId, distributorId, realValue, registryTime ) {
    if (typeof distributorId !== 'string') throw TypeError(`distributorId:${distributorId} is not a string`)
    if (!distributorId.trim()) throw Error('distributorId is empty or blank')

    const method = 'POST'

    const data = { objectiveId, distributorId, realValue, registryTime, userId: this._userId().toString()
    }
    return this._call('objective/distributor/create', data, method)
  },
  
  // ------ End Distributors ------ //

  // ------- Product Correspondance ------- //

  getAllProductCorrespondance() {
    const method = 'POST'

    const data = {
      userId: this._userId(),
      tenantId: this._tenantId()
    }

    return this._call('productCorrespondance/all', data, method)
  },

  getProductCorrespondance({ distributorId }) {
    if (typeof distributorId !== 'string') throw TypeError(`distributorId:${distributorId} is not a string`)
    if (!distributorId.trim()) throw Error('distributorId is empty or blank')

    const method = 'POST'

    const data = {
      userId: this._userId(),
      tenantId: this._tenantId(),
      distributorId
    }

    return this._call('productCorrespondance', data, method)
  },
  getNonProductCorrespondance() {
    const method = 'POST'

    const data = {
      userId: this._userId(),
      tenantId: this._tenantId()
    }

    return this._call('productCorrespondance/non', data, method)
  },

  getOneProductCorrespondance({ productTenantId, productDistributorId }) {
    if (typeof productTenantId !== 'string') throw TypeError(`productTenantId:${productTenantId} is not a string`)
    if (!productTenantId.trim()) throw Error('productTenantId is empty or blank')
    if (typeof productDistributorId !== 'string') throw TypeError(`productDistributorId:${productDistributorId} is not a string`)
    if (!productDistributorId.trim()) throw Error('productDistributorId is empty or blank')

    const method = 'POST'

    const data = {
      userId: this._userId(),
      tenantId: this._tenantId(),
      productTenantId,
      productDistributorId
    }
    return this._call('productCorrespondance/id', data, method)
  },

  updateProductCorrespondance({ productTenantId, productDistributorId, description, newProductTenantId, correspondanceFactor }) {
    if (typeof productTenantId !== 'string') throw TypeError(`productTenantId:${productTenantId} is not a string`)
    if (!productTenantId.trim()) throw Error('productTenantId is empty or blank')
    if (typeof productDistributorId !== 'string') throw TypeError(`productDistributorId:${productDistributorId} is not a string`)
    if (!productDistributorId.trim()) throw Error('productDistributorId is empty or blank')
    if (typeof description !== 'string') throw TypeError(`description:${description} is not a string`)
    if (typeof newProductTenantId !== 'string') throw TypeError(`newProductTenantId:${newProductTenantId} is not a string`)
    if (typeof correspondanceFactor !== 'string') throw TypeError(`correspondanceFactor:${correspondanceFactor} is not a string`)
    if (!correspondanceFactor.trim()) throw Error('correspondanceFactor is empty or blank')

    const method = 'PATCH'

    const data = {
      userId: this._userId(),
      tenantId: this._tenantId(),
      productTenantId,
      productDistributorId,
      description,
      newProductTenantId,
      correspondanceFactor

    }
    return this._call('productCorrespondance/update', data, method)
  },

  insertProductCorrespondance({ productTenantId, description, correspondanceFactor, productDistributorId, distributorId }) {
    if (typeof productTenantId !== 'string') throw TypeError(`productTenantId:${productTenantId} is not a string`)
    if (!productTenantId.trim()) throw Error('productTenantId is empty or blank')
    if (typeof productDistributorId !== 'string') throw TypeError(`productDistributorId:${productDistributorId} is not a string`)
    if (!productDistributorId.trim()) throw Error('productDistributorId is empty or blank')
    if (typeof correspondanceFactor !== 'string') throw TypeError(`correspondanceFactor:${correspondanceFactor} is not a string`)
    if (!correspondanceFactor.trim()) throw Error('correspondanceFactor is empty or blank')
    if (typeof distributorId !== 'string') throw TypeError(`distributorId:${distributorId} is not a string`)
    if (!distributorId.trim()) throw Error('distributorId is empty or blank')
    if (description && typeof description !== 'string') throw TypeError(`description: ${description} is not a string`)

    const method = 'POST'

    const data = {
      userId: this._userId(),
      tenantId: this._tenantId(),
      productTenantId,
      description,
      correspondanceFactor,
      productDistributorId,
      distributorId
    }

    return this._call('productCorrespondance/add', data, method)
  },

  deleteProductCorrespondance({ productTenantId, productDistributorId }) {
    if (typeof productTenantId !== 'string') throw TypeError(`productTenantId:${productTenantId} is not a string`)
    if (!productTenantId.trim()) throw Error('productTenantId is empty or blank')
    if (typeof productDistributorId !== 'string') throw TypeError(`productDistributorId:${productDistributorId} is not a string`)
    if (!productDistributorId.trim()) throw Error('productDistributorId is empty or blank')

    const method = 'DELETE'

    const data = {
      userId: this._userId(),
      tenantId: this._tenantId(),
      distributorId: this._distributorId(),
      productTenantId,
      productDistributorId
    }
    return this._call('productCorrespondance/delete', data, method)
  },
  // --------- product Distributor --------- //

  getProductsDistributor({ tenantId }) {
    if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)
    if (!tenantId.trim()) throw Error('tenantId is empty or blank')

    const method = 'POST'

    const data = {
      tenantId,
      userId: this._userId(),
      distributorId: this._distributorId()
    }

    return this._call('productDistributor', data, method)
  },

  getOneProductDistributor({ productDistributorId, productDistributorCode, tenantId }) {
    if (productDistributorId) {
      if (typeof productDistributorId !== 'string') throw TypeError(`productDistributorId:${productDistributorId} is not a string`)
    }
    if (productDistributorCode) {
      if (typeof productDistributorCode !== 'string') throw TypeError(`productDistributorCode:${productDistributorCode} is not a string`)
    }
    if (!productDistributorId && !productDistributorCode) {
      throw Error(`productDistributorId: ${productDistributorId} or productDistributorCode: ${productDistributorCode} must be defined`)
    }
    if (tenantId) {
      if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)
    }

    const method = 'POST'

    let tenantQuery = tenantId
    if (this._tenantId() !== 'null') {
      tenantQuery = this._tenantId()
    }
    const data = {
      productDistributorId,
      productDistributorCode,
      tenantId: tenantQuery,
      userId: this._userId(),
      distributorId: this._distributorId()
    }

    return this._call('productDistributor/id', data, method)
  },

  getOneProductDistributorAsTenant({ productDistributorId, productDistributorCode, tenantId }) {
    if (productDistributorId) {
      if (typeof productDistributorId !== 'string') throw TypeError(`productDistributorId:${productDistributorId} is not a string`)
    }
    if (productDistributorCode) {
      if (typeof productDistributorCode !== 'string') throw TypeError(`productDistributorCode:${productDistributorCode} is not a string`)
    }
    if (!productDistributorId && !productDistributorCode) {
      throw Error(`productDistributorId: ${productDistributorId} or productDistributorCode: ${productDistributorCode} must be defined`)
    }
    if (tenantId) {
      if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)
    }

    const method = 'POST'

    let tenantQuery = tenantId
    if (this._tenantId() !== 'null') {
      tenantQuery = this._tenantId()
    }
    const data = {
      productDistributorId,
      productDistributorCode,
      tenantId: tenantQuery,
      userId: this._userId(),
      distributorId: this._distributorId()
    }

    return this._call('productDistributor/tenant/id', data, method)
  },
  insertProductDistributor({ productDistributorCode, distributorProductName, tenantId }) {
    if (typeof productDistributorCode !== 'string') throw TypeError(`productDistributorCode:${productDistributorCode} is not a string`)
    if (!productDistributorCode.trim()) throw Error('productDistributorCode is empty or blank')
    if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)
    if (!tenantId.trim()) throw Error('tenantId is empty or blank')

    if (typeof distributorProductName !== 'string') throw TypeError(`distributorProductName:${distributorProductName} is not a string`)

    const method = 'POST'

    const data = {
      productDistributorCode,
      distributorProductName,
      userId: this._userId().toString(),
      tenantId,
      distributorId: this._distributorId()
    }

    return this._call('productsDistributor/add', data, method)
  },

  updateProductDistributor({ tenantId, productDistributorId, productDistributorCode, productName, productBrandId, productBusinessUnitId, productGroupId, productDescription }) {
    if (typeof productDistributorId !== 'string') throw TypeError(`productDistributorId:${productDistributorId} is not a string`)
    if (!productDistributorId.trim()) throw Error('productDistributorId is empty or blank')

    if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)
    if (!tenantId.trim()) throw Error('tenantId is empty or blank')

    if (typeof productDistributorCode !== 'string') throw TypeError(`productDistributorCode:${productDistributorCode} is not a string`)
    if (!productDistributorCode.trim()) throw Error('productDistributorCode is empty or blank')

    if (typeof productName !== 'string') throw TypeError(`productName:${productName} is not a string`)

    const method = 'PATCH'

    const data = {
      productDistributorId,
      productDistributorCode,
      productName,
      userId: this._userId().toString(),
      distributorId: this._distributorId(),
      tenantId
    }

    return this._call('productsDistributor/update', data, method)
  },
  deleteProductDistributor({ productDistributorId, tenantId }) {
    if (typeof productDistributorId !== 'string') throw TypeError(`productDistributorId:${productDistributorId} is not a string`)
    if (!productDistributorId.trim()) throw Error('productDistributorId is empty or blank')
    if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)
    if (!tenantId.trim()) throw Error('tenantId is empty or blank')

    const method = 'DELETE'

    const data = {
      userId: this._userId(),
      distributorId: this._distributorId(),
      productDistributorId,
      tenantId
    }
    return this._call('productsDistributor/delete', data, method)
  },

  activateProductDistributor({ productDistributorId, tenantId }) {
    if (typeof productDistributorId !== 'string') throw TypeError(`productDistributorId:${productDistributorId} is not a string`)
    if (!productDistributorId.trim()) throw Error('productDistributorId is empty or blank')
    if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)
    if (!tenantId.trim()) throw Error('tenantId is empty or blank')

    const method = 'POST'

    const data = {
      userId: this._userId(),
      distributorId: this._distributorId(),
      productDistributorId,
      tenantId
    }
    return this._call('productsDistributor/activate', data, method)
  },


  // ------ End product distributor ------ //

  // ------ Tenants distributor ------ //

  getTenantsFromDistributor() {
    const method = 'POST'
    const data = {
      userId: this._userId(),
      distributorId: this._distributorId()
    }

    return this._call('tenantDistributor', data, method)
  },

  getTenantsFromDistributorAdmin(distributorId) {
    const method = 'POST'
    const data = {
      userId: this._userId(),
      distributorId: distributorId
    }

    return this. _call('tenantDistributor/adm', data, method)
  },

  // ----- End Tenants distributor ----- //

  // ----- UserDistributor ----- //
  getUserDistributors({ distributorId }) {
    if (typeof distributorId !== 'string') throw TypeError(`distributorId:${distributorId} is not a string`)
    if (!distributorId.trim()) throw Error('distributorId is empty or blank')
    const method = 'POST'

    const data = {
      distributorId,
      tenantId: this._tenantId(),
      userId: this._userId()
    }

    return this._call('userDistributor', data, method)
  },

  getUsersFromDistributor ({ distributorId }) {
    if (typeof distributorId !== 'string') throw TypeError(`distributorId:${distributorId} is not a string`)
    if (!distributorId.trim()) throw Error('distributorId is empty or blank')

    const method = 'POST'

    const data = {
      distributorId,
      userId: this._userId()
    }

    return this._call('userDistributor/staff', data, method)
  },

  getOneUserDistributor({ distributorId, distributorUserId }) {
    if (typeof distributorId !== 'string') throw TypeError(`distributorId:${distributorId} is not a string`)
    if (!distributorId.trim()) throw Error('distributorId is empty or blank')
    if (typeof distributorUserId !== 'string') throw TypeError(`distributorUserId:${distributorUserId} is not a string`)
    if (!distributorUserId.trim()) throw Error('distributorUserId is empty or blank')

    const method = 'POST'

    const data = {
      tenantId: this._tenantId(),
      userId: this._userId(),
      distributorUserId,
      distributorId
    }

    return this._call('userDistributor/id', data, method)
  },

  addUserDistributor({ userEmail, primaryContact, distributorId, notify }) {
    const isPrimaryContact = primaryContact || false

    if (typeof userEmail !== 'string') throw TypeError(`userEmail:${userEmail} is not a string`)
    if (!userEmail.trim()) throw Error('userEmail is empty or blank')
    if (typeof distributorId !== 'string') throw TypeError(`distributorId:${distributorId} is not a string`)
    if (!distributorId.trim()) throw Error('distributorId is empty or blank')
    if (typeof notify !== 'boolean') throw TypeError(`notify:${notify} is not a boolean`)

    const method = 'POST'
    const data = {
      tenantId: this._tenantId(),
      userId: this._userId(),
      distributorId,
      userEmail,
      isPrimaryContact: primaryContact,
      notify
    }

    return this._call('userDistributor/add', data, method)
  },

  deleteUserDistributor({ distributorId, userDistributorId }) {
    if (typeof distributorId !== 'string') throw TypeError(`distributorId:${distributorId} is not a string`)
    if (!distributorId.trim()) throw Error('distributorId is empty or blank')
    if (typeof userDistributorId !== 'string') throw TypeError(`userDistributorId:${userDistributorId} is not a string`)
    if (!userDistributorId.trim()) throw Error('userDistributorId is empty or blank')

    const method = 'DELETE'

    const data = {
      tenantId: this._tenantId(),
      userId: this._userId(),
      userDistributorId,
      distributorId
    }

    return this._call('userDistributor/delete', data, method)
  },

  updateUserDistributor({ userDistributorId, primaryContact, distributorId, notify }) {
    const isPrimaryContact = primaryContact || false

    if (typeof userDistributorId !== 'string') throw TypeError(`userDistributorId:${userDistributorId} is not a string`)
    if (!userDistributorId.trim()) throw Error('userDistributorId is empty or blank')
    if (typeof distributorId !== 'string') throw TypeError(`distributorId:${distributorId} is not a string`)
    if (!distributorId.trim()) throw Error('distributorId is empty or blank')
    if (typeof notify !== 'boolean') throw TypeError(`notify:${notify} is not a string`)
    const method = 'PATCH'
    const data = {
      tenantId: this._tenantId(),
      userId: this._userId(),
      distributorUserId: userDistributorId,
      isPrimaryContact,
      distributorId,
      notify
    }

    return this._call('userDistributor/update', data, method)
  },

  // ----- End UserDistributor ------//

  // ----- Excel Page ----- //

  getRegistriesFromSelectedDateAndTenant({ selectedDate, selectedTenant }) {
    if (!(selectedDate instanceof Date)) throw TypeError(`selectedDate :${selectedDate} is not a Date`)
    if (typeof selectedTenant !== 'string') throw TypeError(`selectedTenant:${selectedTenant} is not a string`)
    if (!selectedTenant.trim()) throw Error('selectedTenant is empty or blank')

    const method = 'POST'

    const data = {
      selectedDate,
      selectedTenant,
      userId: this._userId(),
      distributorId: this._distributorId()
    }

    return this._call('registry/id', data, method)
  },

  getRegistriesFromSelectedDateAndTenantAdmin({ selectedDate, selectedTenant, distributorId }) {
    if (!(selectedDate instanceof Date)) throw TypeError(`selectedDate :${selectedDate} is not a Date`)
    if (typeof selectedTenant !== 'string') throw TypeError(`selectedTenant:${selectedTenant} is not a string`)
    if (!selectedTenant.trim()) throw Error('selectedTenant is empty or blank')

    const method = 'POST'

    const data = {
      selectedDate,
      selectedTenant,
      userId: this._userId(),
      distributorId: distributorId
    }

    return this._call('registry/id', data, method)
  },

  insertPriceExcelPage(page) {
    const { price, tenantId } = page
    if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)

    const method = 'POST'

    const data = {
      ...page,
      tenantId,
      distributorId: this._distributorId(),
      userId: this._userId(),
      year: page.year,
      price, 
    }
    return this._call('registry-price', data, method)
  },
  
  insertExcelPage(page, distributorId = this._distributorId(), isItATenant = false) {
    const { sales, stock, date, tenantId } = page
    if (!(date instanceof Date)) throw TypeError(`date :${date} is not a date`)
    if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)
    if (!tenantId.trim()) throw Error('tenantId is empty or blank')
    if (sales.name !== TEMPLATE_NAMES.sales) throw Error(`sales: ${sales} does not equal ${TEMPLATE_NAMES.sales}`)
    if (stock.name !== TEMPLATE_NAMES.stock) throw Error(`stock: ${stock} does not equal ${TEMPLATE_NAMES.stock}`)

    const method = 'POST'

    const data = {
      ...page,
      date,
      tenantId,
      distributorId,
      userId: this._userId(),
      roleName: this._roleName(),
      isItATenant
    }

    return this._call('registry', data, method)
  },

  validateProductCodes(page, distributorId = this._distributorId()) {
    const { sales, stock, date, tenantId } = page
    if (!(date instanceof Date)) throw TypeError(`date :${date} is not a date`)
    if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)
    if (!tenantId.trim()) throw Error('tenantId is empty or blank')
    if (sales.name !== TEMPLATE_NAMES.sales) throw Error(`sales: ${sales} does not equal ${TEMPLATE_NAMES.sales}`)
    if (stock.name !== TEMPLATE_NAMES.stock) throw Error(`stock: ${stock} does not equal ${TEMPLATE_NAMES.stock}`)

    const method = 'POST'

    const data = {
      ...page,
      date,
      tenantId,
      distributorId,
      userId: this._userId()
    }

    return this._call('registry/validate', data, method)
  },

  updateDistProdNames(tenantId, newProdNames, distributorId = this._distributorId(), isItATenant = false) {
    if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)
    if (!tenantId.trim()) throw Error('tenantId is empty or blank')

    const method = 'POST'
    const data = {
      tenantId,
      distributorId,
      newProdNames,
      userId: this._userId(),
      isTenant: this.isTenant || isItATenant
    }

    return this._call('registry/updateprodnames', data, method)
  },

  deleteExcelPage({ date, tenantId, fileToDelete, distributorId = this._distributorId() }) {
    if (!(date instanceof Date)) throw TypeError(`date :${date} is not a date`)
    if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)
    if (!tenantId.trim()) throw Error('tenantId is empty or blank')
    if (typeof fileToDelete !== 'string') throw TypeError(`fileToDelete:${fileToDelete} is not a string`)
    if (!fileToDelete.trim()) throw Error('fileToDelete is empty or blank')

    const method = 'DELETE'

    const data = {
      date,
      tenantId,
      fileToDelete,
      distributorId,
      userId: this._userId()
    }

    return this._call('registry/delete', data, method)
  },

  verifyPasswordToken({ token }) {
    if (typeof token !== 'string') throw TypeError(`password:${token} is not a string`)
    if (!token.trim()) throw Error('tenantId is empty or blank')
    const method = 'POST'

    const data = {
      token
    }
    return this._call('users/auth/verifyToken', data, method)
  },

  resetPasswordMail({ email }) {
    if (typeof email !== 'string') throw TypeError(`password:${email} is not a string`)
    if (!email.trim()) throw Error('tenantId is empty or blank')

    const method = 'POST'

    const data = {
      email
    }

    return this._call('users/forgotPassword', data, method)
  },
  resetPassword({ newPassword }) {
    if (typeof newPassword !== 'string') throw TypeError(`password:${newPassword} is not a string`)
    if (!newPassword.trim()) throw Error('tenantId is empty or blank')
    const token = window.location.href.split('/').slice(-1)[0]
    const method = 'PATCH'
    const data = {
      newPassword
    }

    return this._call(`users/auth/resetPassword/${token}`, data, method)
  },

  resetPasswordAdmin({ newPassword, userChangePasswordId }) {
    if (typeof newPassword !== 'string') throw TypeError(`password:${newPassword} is not a string`)
    if (!newPassword.trim()) throw Error('tenantId is empty or blank')
    const token = this._token()
    const method = 'PATCH'
    const data = {
      newPassword,
      token,
      userChangePasswordId,
      userId: this._userId()
    }

    return this._call(`users/auth/resetPasswordAdmin`, data, method)
  },
  deleteTenantAdmin({ tenantId }) {
    if (typeof tenantId !== 'string') throw TypeError(`password:${tenantId} is not a string`)
    if (!tenantId.trim()) throw Error('tenantId is empty or blank')
    const method = 'POST'
    const userId = this._userId()
    const data = {
      tenantId,
      userId
    }
    return this._call('admin/tenants/deleteTenant', data, method)
  },
  deleteUserAdmin({ userId, distributorId, tenantId }) {
    if (typeof userId !== 'string') throw TypeError(`userIdToDelete:${userId} is not a string`)
    if (!userId.trim()) throw Error('userId to delete is empty or blank')
    if (typeof distributorId !== 'string') throw TypeError(`distributorId of userIdToDelete:${distributorId} is not a string`)
    if (!distributorId.trim()) throw Error('distributorId of userIdToDelete is empty or blank')
    if (typeof tenantId !== 'string') throw TypeError(`tenantId of userIdToDelete :${tenantId} is not a string`)
    if (!tenantId.trim()) throw Error('tenantId of userIdToDelete is empty or blank')
    const method = 'POST'
    const userIdToDelete = userId
    userId = this._userId()
    const data = {
      userId,
      userIdToDelete,
      distributorId,
      tenantId
    }
    return this._call('admin/users/deleteUser', data, method)
  },
  getTenantsAdmin() {
    const method = 'POST'
    const userId = this._userId()
    const data = {
      userId
    }
    return this._call('admin/retrieveTenants', data, method)
  },
  getUsersAdmin() {
    const method = 'POST'
    const userId = this._userId()
    const data = {
      userId
    }
    return this._call('admin/retrieveUsers', data, method)
  },
  getCountTenantsAdmin() {
    const method = 'POST'
    const userId = this._userId()
    const data = {
      userId
    }
    return this._call('admin/retrieveCountTenants', data, method)
  },
  getUserAdmin(userIdToEdit) {
    const method = 'POST'
    const userId = this._userId()
    const data = {
      userId,
      userIdToEdit
    }
    return this._call('admin/users/id', data, method)
  },

  retrieveUserTenant(userIdToEdit) {
    const method = 'POST'
    const userId = this._userId()
    const data = {
      userId,
      userIdToEdit
    }
    return this._call('tenant/users/id', data, method)
  },

  getDistributorsAdmin() {
    const method = 'POST'
    const userId = this._userId()
    const data = {
      userId
    }
    return this._call('admin/retrieveDistributors', data, method)
  },
  getDistributorsSpecificTenantAdmin(tenantId) {
    const method = 'POST'
    
    const data = {
      userId: this._userId(),
      tenantId: tenantId
    }
    return this._call('distributor', data, method)
  },
  updateTenantAdmin({ tenantId, distributorIdentity, distributorName, distributorCode, postalCode, province, city, address, phone, alternatePhone, description }) {
    if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)
    if (!tenantId.trim()) throw Error('tenantId is empty or blank')
    if (typeof distributorIdentity !== 'string') throw TypeError(`tenantId:${distributorIdentity} is not a string`)
    if (!distributorIdentity.trim()) throw Error('distributorId is empty or blank')
    if (typeof distributorName !== 'string') throw TypeError(`distributorName:${distributorName} is not a string`)
    if (!distributorName.trim()) throw Error('distributorId is empty or blank')
    if (typeof distributorCode !== 'string') throw TypeError(`distributorCode:${distributorCode} is not a string`)
    if (!distributorCode.trim()) throw Error('distributorId is empty or blank')
    if (typeof postalCode !== 'string') throw TypeError(`postalCode:${postalCode} is not a string`)
    if (!postalCode.trim()) throw Error('distributorId is empty or blank')
    if (typeof distributorIdentity !== 'string') throw TypeError(`distributorIdentity:${distributorIdentity} is not a string`)
    if (!distributorIdentity.trim()) throw Error('distributorId is empty or blank')
    if (typeof province !== 'string') throw TypeError(`province:${province} is not a string`)
    if (!province.trim()) throw Error('distributorId is empty or blank')
    if (typeof city !== 'string') throw TypeError(`city:${city} is not a string`)
    if (!city.trim()) throw Error('distributorId is empty or blank')
    if (typeof address !== 'string') throw TypeError(`adress:${address} is not a string`)
    if (!address.trim()) throw Error('distributorId is empty or blank')
    if (typeof phone !== 'string') throw TypeError(`phone:${phone} is not a string`)
    if (!phone.trim()) throw Error('distributorId is empty or blank')

    const method = 'POST'
    const data = {
      userId: this._userId(),
      tenantId,
      distributorIdentity: distributorIdentity,
      distributorName: distributorName,
      distributorCode: distributorCode,
      province: province,
      city: city,
      postalCode: postalCode,
      address: address,
      phone: phone,
      alternativePhone: alternatePhone,
      description: description
    }

    return this._call('admin/tenants/update', data, method)
  },
  updateUserTenantAdmin({ userId, downgradeToDistributor, newDistributorId, newTenantId, tenantId }) {
    if (typeof userId !== 'string') throw TypeError(`userName:${userId} is not a string`)
    if (!userId.trim()) throw Error('userName is empty or blank')
    if (typeof downgradeToDistributor !== 'boolean') throw TypeError(`downgradeToDistributor:${downgradeToDistributor} is not a boolean `)
    if (newDistributorId) {
      if (typeof newDistributorId !== 'string') throw TypeError(`newDistributorId:${newDistributorId} is not a string`)
      if (!newDistributorId.trim()) throw Error('password is empty or blank')
    }
    if (newTenantId) {
      if (typeof newTenantId !== 'string') throw TypeError(`newTenantId:${newTenantId} is not a string`)
      if (!newTenantId.trim()) throw Error('password is empty or blank')
    }
    if (tenantId) {
      if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)
      if (!tenantId.trim()) throw Error('password is empty or blank')
    }
    const method = 'PATCH'

    const data = {
      userIdToUpdate: userId,
      userId: this.sessionId.toString(),
      downgradeToDistributor: downgradeToDistributor,
      newDistributorId: newDistributorId,
      newTenantId: newTenantId,
      tenantId: tenantId
    }
    return this._call('admin/users/update/userTenant', data, method)
  },
  updateUserDistributorAdmin({ userId, upgradeToTenant, newDistributorId, newTenantId, tenantId }) {
    if (typeof userId !== 'string') throw TypeError(`userName:${userId} is not a string`)
    if (!userId.trim()) throw Error('userName is empty or blank')
    if (typeof upgradeToTenant !== 'boolean') throw TypeError(`upgradeToTenant:${upgradeToTenant} is not a boolean`)
    if (newDistributorId) {
      if (typeof newDistributorId !== 'string') throw TypeError(`newDistributorId:${newDistributorId} is not a string`)
      if (!newDistributorId.trim()) throw Error('password is empty or blank')
    }
    if (newTenantId) {
      if (typeof newTenantId !== 'string') throw TypeError(`newTenantId:${newTenantId} is not a string`)
      if (!newTenantId.trim()) throw Error('password is empty or blank')
    }
    if (tenantId) {
      if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)
      if (!tenantId.trim()) throw Error('password is empty or blank')
    }
    const method = 'PATCH'

    const data = {
      userIdToUpdate: userId,
      userId: this.sessionId.toString(),
      upgradeToTenant: upgradeToTenant,
      newDistributorId: newDistributorId,
      newTenantId: newTenantId,
      tenantId: tenantId
    }
    return this._call('admin/users/update/userDistributor', data, method)
  },
  addUserAdmin({ userEmail, distributorId, tenantId, tenantName, roleName }) {
    if (typeof userEmail !== 'string') throw TypeError(`userEmail:${userEmail} is not a string`)
    if (!userEmail.trim()) throw Error('userEmail is empty or blank')
    if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)
    if (!tenantId.trim()) throw Error('tenantId is empty or blank')
    if (typeof tenantName !== 'string') throw TypeError(`tenantName:${tenantName} is not a string`)
    if (!tenantName.trim()) throw Error('tenantName is empty or blank')
    if (typeof roleName !== 'string') throw TypeError(`tenantId:${roleName} is not a string`)
    if (!roleName.trim()) throw Error('distributorId is empty or blank')

    const method = 'POST'

    const data = {
      userId: this._userId(),
      tenantId: tenantId,
      tenantName: tenantName,
      distributorId: distributorId,
      userEmail: userEmail,
      roleName: roleName
    }

    return this._call('admin/user/add', data, method)
  },
  updateInactiveUserAdmin({ userIdToUpdate, distributorId, tenantId, roleName }) {
    if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)
    if (!tenantId.trim()) throw Error('distributorId is empty or blank')
    if (typeof roleName !== 'string') throw TypeError(`tenantId:${roleName} is not a string`)
    if (!roleName.trim()) throw Error('distributorId is empty or blank')

    const method = 'POST'

    const data = {
      userId: this._userId(),
      tenantId: tenantId,
      userIdToUpdate: userIdToUpdate,
      distributorId: distributorId,
      roleName: roleName
    }
    return this._call('admin/user/updateInactiveUser', data, method)
  },
  // get distributorId from tenant using tenantIdentity
  getOneDistributorTenantAdmin({ tenantIdentity, tenantId }) {
    if (typeof tenantIdentity !== 'string') throw TypeError(`distributorTenantId:${tenantIdentity} is not a string`)

    if (!tenantIdentity.trim()) throw Error('distributorTenantId is empty or blank')

    const method = 'POST'

    const data = {
      userId: this._userId(),
      tenantId: tenantId,
      tenantIdentity
    }
    return this._call('admin/distributorTenant/id', data, method)
  },
  addTenantAdmin({ distributorIdentity, distributorName, distributorCode, postalCode, province, city, address, phone, alternatePhone, description }) {
    if (typeof distributorIdentity !== 'string') throw TypeError(`tenantId:${distributorIdentity} is not a string`)
    if (!distributorIdentity.trim()) throw Error('distributorId is empty or blank')
    if (typeof distributorName !== 'string') throw TypeError(`distributorName:${distributorName} is not a string`)
    if (!distributorName.trim()) throw Error('distributorId is empty or blank')
    if (typeof distributorCode !== 'string') throw TypeError(`distributorCode:${distributorCode} is not a string`)
    if (!distributorCode.trim()) throw Error('distributorId is empty or blank')
    if (typeof postalCode !== 'string') throw TypeError(`postalCode:${postalCode} is not a string`)
    if (!postalCode.trim()) throw Error('distributorId is empty or blank')
    if (typeof distributorIdentity !== 'string') throw TypeError(`distributorIdentity:${distributorIdentity} is not a string`)
    if (!distributorIdentity.trim()) throw Error('distributorId is empty or blank')
    if (typeof province !== 'string') throw TypeError(`province:${province} is not a string`)
    if (!province.trim()) throw Error('distributorId is empty or blank')
    if (typeof city !== 'string') throw TypeError(`city:${city} is not a string`)
    if (!city.trim()) throw Error('distributorId is empty or blank')
    if (typeof address !== 'string') throw TypeError(`adress:${address} is not a string`)
    if (!address.trim()) throw Error('distributorId is empty or blank')
    if (typeof phone !== 'string') throw TypeError(`phone:${phone} is not a string`)
    if (!phone.trim()) throw Error('distributorId is empty or blank')

    const method = 'POST'
    const data = {
      userId: this._userId(),
      distributorIdentity: distributorIdentity,
      distributorName: distributorName,
      distributorCode: distributorCode,
      province: province,
      city: city,
      postalCode: postalCode,
      address: address,
      phone: phone,
      alternativePhone: alternatePhone,
      description: description
    }

    return this._call('admin/tenant/add', data, method)
  },

  // ----- End Excel Page ----- //

  // ----- Dashboards ----- //

  getDashboardsAdmin() {
    const method = 'GET'
    return this._callGet(`admin/retrieveDashboards?userId=${this._userId().toString()}`, method)
  },

  getOneDashboardAdmin({ dashboardId }) {
    const method = 'GET'
    return this._callGet(`admin/dashboard?userId=${this._userId().toString()}&dashboardId=${dashboardId}`, method)
  },

  getDashboardsDistributor({ distributorId }) {
    const method = 'GET'
    return this._callGet(`distributor/retrieveDashboards?userId=${this._userId().toString()}&distributorId=${distributorId}`, method)
  },

  addDashboardAdmin({ distributorId, dashboardName, dashboardUrl, dashboardDescription }) {
    if (typeof distributorId !== 'string') throw TypeError(`distributorId:${distributorId} is not a string`)
    if (!distributorId.trim()) throw Error('distributorId is empty or blank')
    if (typeof dashboardName !== 'string') throw TypeError(`dashboardName:${dashboardName} is not a string`)
    if (typeof dashboardUrl !== 'string') throw TypeError(`dashboardUrl:${dashboardUrl} is not a string`)
    if (typeof dashboardDescription !== 'string') throw TypeError(`dashboardDescription:${dashboardDescription} is not a string`)

    const method = 'POST'
    const data = {
      userId: this._userId(),
      distributorId: distributorId,
      dashboardName: dashboardName,
      dashboardUrl: dashboardUrl,
      dashboardDescription: dashboardDescription
    }

    return this._call('admin/dashboard/add', data, method)
  },

  updateDashboardAdmin({ dashboardId, distributorId, dashboardName, dashboardUrl, dashboardDescription }) {
    if (typeof dashboardId !== 'string') throw TypeError(`dashboardId:${dashboardId} is not a string`)
    if (!dashboardId.trim()) throw Error('dashboardId is empty or blank')
    if (typeof distributorId !== 'string') throw TypeError(`distributorId:${distributorId} is not a string`)
    if (!distributorId.trim()) throw Error('distributorId is empty or blank')
    if (typeof dashboardName !== 'string') throw TypeError(`dashboardName:${dashboardName} is not a string`)
    if (typeof dashboardUrl !== 'string') throw TypeError(`dashboardUrl:${dashboardUrl} is not a string`)
    if (typeof dashboardDescription !== 'string') throw TypeError(`dashboardDescription:${dashboardDescription} is not a string`)

    const method = 'POST'
    const data = {
      userId: this._userId(),
      dashboardId: dashboardId,
      distributorId: distributorId,
      dashboardName: dashboardName,
      dashboardUrl: dashboardUrl,
      dashboardDescription: dashboardDescription
    }

    return this._call('admin/dashboard/update', data, method)
  },

  activateAdminDashboard(dashboardId) {
    if (typeof dashboardId !== 'string') throw TypeError(`dashboardId:${dashboardId} is not a string`)
    if (!dashboardId.trim()) throw Error('dashboardId is empty or blank')

    const method = 'POST'

    const data = {
      dashboardId,
      userId: this._userId().toString(),
    }
    return this._call('admin/dashboard/activate', data, method)
  },

  inactivateAdminDashboard(dashboardId) {
    if (typeof dashboardId !== 'string') throw TypeError(`dashboardId:${dashboardId} is not a string`)
    if (!dashboardId.trim()) throw Error('dashboardId is empty or blank')

    const method = 'POST'

    const data = {
      dashboardId,
      userId: this._userId().toString(),
    }
    return this._call('admin/dashboard/delete', data, method)
  },

  addTableOfTablesAdmin({ tableOfTablesName, tableOfTablesData, tableOfTablesDescription }) {
    if (typeof tableOfTablesName !== 'string') throw TypeError(`tableOfTablesName:${tableOfTablesName} is not a string`)
    if (typeof tableOfTablesData !== 'string') throw TypeError(`tableOfTablesData:${tableOfTablesData} is not a string`)
    if (typeof tableOfTablesDescription !== 'string') throw TypeError(`tableOfTablesDescription:${tableOfTablesDescription} is not a string`)

    const method = 'POST'
    const data = {
      userId: this._userId(),
      tableOfTablesName: tableOfTablesName,
      tableOfTablesData: tableOfTablesData,
      tableOfTablesDescription: tableOfTablesDescription
    }

    return this._call('admin/tableOfTables/add', data, method)
  },

  updateTableOfTablesAdmin({ tableOfTablesId, tableOfTablesData, tableOfTablesDescription }) {
    if (typeof tableOfTablesId !== 'string') throw TypeError(`tableOfTablesId:${tableOfTablesId} is not a string`)
    if (!tableOfTablesId.trim()) throw Error('tableOfTablesId is empty or blank')
    if (typeof tableOfTablesData !== 'string') throw TypeError(`tableOfTablesData:${tableOfTablesData} is not a string`)
    if (typeof tableOfTablesDescription !== 'string') throw TypeError(`tableOfTablesDescription:${tableOfTablesDescription} is not a string`)
    const method = 'POST'
    const data = {
      userId: this._userId(),
      tableOfTablesId: tableOfTablesId,
      tableOfTablesData: tableOfTablesData,
      tableOfTablesDescription: tableOfTablesDescription
    }

    return this._call('admin/tableOfTables/update', data, method)
  },

  activateAdminTable(tableOfTablesId) {
    if (typeof tableOfTablesId !== 'string') throw TypeError(`tableOfTablesId:${tableOfTablesId} is not a string`)
    if (!tableOfTablesId.trim()) throw Error('tableOfTablesId is empty or blank')

    const method = 'POST'

    const data = {
      tableOfTablesId,
      userId: this._userId().toString(),
    }
    return this._call('admin/tableOfTables/activate', data, method)
  },

  inactivateAdminTable(tableOfTablesId) {
    if (typeof tableOfTablesId !== 'string') throw TypeError(`tableOfTablesId:${tableOfTablesId} is not a string`)
    if (!tableOfTablesId.trim()) throw Error('tableOfTablesId is empty or blank')

    const method = 'POST'

    const data = {
      tableOfTablesId,
      userId: this._userId().toString(),
    }
    return this._call('admin/tableOfTables/delete', data, method)
  },

  // ----- End Dashboards ----- //

  // ----- Tenant ----- //

  getTenant(userId) {
    if (!userId) {
      userId = this._userId().toString()
    }
    if (typeof userId !== 'string') throw TypeError(`userId:${userId} is not a string`)

    if (!userId.trim()) throw Error('userId is empty or blank')

    const method = 'GET'

    return this._callGet(`retrieveTenant?userId=${userId}&tenantId=${this._tenantId().toString()}`, method)
  },

  updateTenant({ notCrossSales }) {
    if (typeof notCrossSales !== 'boolean') throw TypeError(`notCrossSales:${notCrossSales} is not a boolean`)

    const method = 'POST'
    const data = {
      userId: this._userId(),
      tenantId: this._tenantId(),
      notCrossSales: notCrossSales
    }

    return this._call('tenant/crosssales', data, method)
  },


  getUsersFromTenant() {
    const method = 'POST'

    const data = {
      userId: this._userId(),
      tenantId: this._tenantId()
    }

    return this._call('tenant/users', data, method)
  },

  addUserTenant({ userEmail, distributorId, tenantId }) {
    if (typeof userEmail !== 'string') throw TypeError(`userEmail:${userEmail} is not a string`)
    if (!userEmail.trim()) throw Error('userEmail is empty or blank')
    if (typeof distributorId !== 'string') throw TypeError(`distributorId:${distributorId} is not a string`)
    if (!distributorId.trim()) throw Error('distributorId is empty or blank')
    if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)
    if (!tenantId.trim()) throw Error('tenantId is empty or blank')

    const method = 'POST'

    const data = {
      userEmail,
      tenantId,
      userId: this._userId(),
      distributorId
    }

    return this._call('tenant/users/add', data, method)
  },
  retrieveSalesReport({ selectedDistributors, dateStart, dateEnd, yearPrice, timeout }) {
    const method = 'POST'

    const data = {
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      selectedDistributors,
      dateStart,
      dateEnd,
      yearPrice,
      notCrossSales: this.notCrossSales,
      timeout
    }

    return this._call('reports/salesReport', data, method)
  },

  countSalesReportDistributors({ selectedDistributors, dateStart, dateEnd }) {
    const method = 'GET'
    console.log("in count passing: ", selectedDistributors)
    return this._callGet(`reports/countSalesReport?userId=${this._userId().toString()}&tenantId=${this._tenantId().toString()}&selectedDistributors=`+ JSON.stringify(selectedDistributors) + `&dateStart=${dateStart}&dateEnd=${dateEnd}`, method)
  },

  retrieveSalesReportAllDistributors({ dateStart, dateEnd, yearPrice, timeout }) {
    const method = 'POST'

    const data = {
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      dateStart,
      dateEnd,
      yearPrice,
      notCrossSales: this.notCrossSales,
      timeout
    }

    return this._call('reports/salesReportAllDistributors', data, method)
  },

  countSalesReportAllDistributors({ dateStart, dateEnd }) {
    const method = 'GET'

    return this._callGet(`reports/countSalesReportAllDistributors?userId=${this._userId().toString()}&tenantId=${this._tenantId().toString()}&dateStart=${dateStart}&dateEnd=${dateEnd}`, method)
  },

  retrieveCategoryClientsSalesReportAllDistributors({ dateStart, dateEnd, yearPrice, petsBusinessUnits, timeout }) {
    const method = 'POST'

    const data = {
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      dateStart,
      dateEnd,
      yearPrice,
      petsBusinessUnits,
      notCrossSales: this.notCrossSales,
      timeout
    }

    return this._call('reports/categoryClientsSalesReportAllDistributors', data, method)
  },

  downloadSalesReport({ selectedDistributors, dateStart, dateEnd, yearPrice, isPVLContracted, timeout }) {
    const method = 'POST'

    const data = {
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      selectedDistributors,
      dateStart,
      dateEnd,
      yearPrice,
      isPVLContracted, 
      notCrossSales: this.notCrossSales,
      timeout
    }

    return this._call('reports/downloadSalesReport', data, method)
  },

  downloadReportAllDistributors({ dateStart, dateEnd, yearPrice, isPVLContracted, timeout }) {
    const method = 'POST'

    const data = {
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      dateStart,
      dateEnd,
      yearPrice,
      isPVLContracted, 
      notCrossSales: this.notCrossSales,
      timeout
    }

    return this._call('reports/downloadSalesReportAllDistributors', data, method)
  },

  downloadCategoryClientsReportAllDistributors({ dateStart, dateEnd, yearPrice, petsBusinessUnits, timeout }) {
    const method = 'POST'

    const data = {
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      dateStart,
      dateEnd,
      yearPrice,
      notCrossSales: this.notCrossSales,
      petsBusinessUnits,
      timeout
    }

    return this._call('reports/downloadCategoryClientsSalesReportAllDistributors', data, method)
  },

  retrieveStockReport({ selectedDistributors, dateStart, dateEnd, yearPrice, timeout }) {
    const method = 'POST'

    const data = {
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      selectedDistributors,
      dateStart,
      dateEnd,
      yearPrice,
      timeout
    }

    return this._call('reports/stockReport', data, method)
  },

  countStockReportDistributors({ selectedDistributors, dateStart, dateEnd }) {
    const method = 'GET'
    return this._callGet(`reports/countStockReport?userId=${this._userId().toString()}&tenantId=${this._tenantId().toString()}&selectedDistributors=`+ JSON.stringify(selectedDistributors) + `&dateStart=${dateStart}&dateEnd=${dateEnd}`, method)
  },

  retrieveStockReportAllDistributors({ dateStart, dateEnd, timeout, yearPrice }) {
    const method = 'POST'

    const data = {
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      dateStart,
      dateEnd,
      yearPrice,
      timeout
    }

    return this._call('reports/stockReportAllDistributors', data, method)
  },

  countStockReportAllDistributors({ dateStart, dateEnd }) {
    const method = 'GET'

    return this._callGet(`reports/countStockReportAllDistributors?userId=${this._userId().toString()}&tenantId=${this._tenantId().toString()}&dateStart=${dateStart}&dateEnd=${dateEnd}`, method)
  },

  downloadStockReport({ selectedDistributors, dateStart, dateEnd, yearPrice, isPVLContracted, timeout }) {
    const method = 'POST'

    const data = {
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      selectedDistributors,
      dateStart,
      dateEnd,
      yearPrice,
      isPVLContracted,
      timeout
    }

    return this._call('reports/downloadStockReport', data, method)
  },
  downloadStockReportAllDistributors({ dateStart, dateEnd, yearPrice, isPVLContracted, timeout }) {
    const method = 'POST'

    const data = {
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      dateStart,
      dateEnd,
      yearPrice,
      isPVLContracted,
      timeout
    }

    return this._call('reports/downloadStockReportAllDistributors', data, method)
  },
  retrieveClientsReport ({ selectedRegions, dateStart, dateEnd, yearPrice, timeout }) {
    const method = 'POST'

    const data = {
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      dateStart,
      dateEnd,
      yearPrice,
      notCrossSales: this.notCrossSales,
      selectedRegions,
      timeout
    }

    return this._call('reports/clientsReport', data, method)
  },

  countClientsReportRegions({ selectedRegions, dateStart, dateEnd }) {
    const method = 'GET'
    return this._callGet(`reports/countClientsReport?userId=${this._userId().toString()}&tenantId=${this._tenantId().toString()}&selectedRegions=`+ JSON.stringify(selectedRegions) + `&dateStart=${dateStart}&dateEnd=${dateEnd}`, method)
  },

  retrieveAllRegionsClientsReport({ dateStart, dateEnd, yearPrice, timeout }) {
    const method = 'POST'

    const data = {
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      dateStart,
      dateEnd,
      yearPrice,
      notCrossSales: this.notCrossSales,
      timeout
    }

    return this._call('reports/clientsReportAllRegions', data, method)
  },

  countClientsReportAllRegions({ dateStart, dateEnd }) {
    const method = 'GET'

    return this._callGet(`reports/countClientsReportAllRegions?userId=${this._userId().toString()}&tenantId=${this._tenantId().toString()}&dateStart=${dateStart}&dateEnd=${dateEnd}`, method)
  },

  downloadClientsReport({ selectedRegions, dateStart, dateEnd, yearPrice, isPVLContracted, timeout }) {
    const method = 'POST'

    const data = {
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      dateStart,
      dateEnd,
      yearPrice,
      isPVLContracted,
      notCrossSales: this.notCrossSales,
      selectedRegions,
      timeout
    }

    return this._call('reports/downloadClientsReport', data, method)
  },
  downloadAllRegionsClientsReport({ dateStart, dateEnd, yearPrice, isPVLContracted, timeout }) {
    const method = 'POST'

    const data = {
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      dateStart,
      dateEnd,
      yearPrice,
      isPVLContracted,
      notCrossSales: this.notCrossSales,
      timeout
    }

    return this._call('reports/downloadClientsReportAllRegions', data, method)
  },
  downloadFilteredSalesReport(sheet){
    const method = 'POST'
   
    const data = {
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      sheet
    }
    return this._call('reports/downloadFilteredSalesReport', data, method)
  },

  downloadFilteredCategoryClientsSalesReport(sheet){
    const method = 'POST'
   
    const data = {
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      sheet
    }
    console.log("sheet: ", sheet)
    return this._call('reports/downloadFilteredCategoryClientsSalesReport', data, method)
  },


  downloadFilteredStockReport(sheet){
    const method = 'POST'
   
    const data = {
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      sheet
    }
    return this._call('reports/downloadFilteredStockReport', data, method)
  },

  downloadFilteredClientsReport(sheet){
    const method = 'POST'
   
    const data = {
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      sheet
    }
    return this._call('reports/downloadFilteredClientsReport', data, method)
  },

  getDistributorStatus(selectedYear) {
    const method = 'POST'
    const data = {
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      selectedYear
    }

    return this._call('reports/distributorReportStatus', data, method)
  },
  retrieveMonthlySummary({ date }) {
    const method = 'POST'

    const data = {
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      notCrossSales: this.notCrossSales,
      date
    }
    return this._call('reports/MonthlySummary', data, method)
  },

  getMonthlyNewClients(selectedYear) {
    const method = 'POST'
    const data = {
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      selectedYear,
      notCrossSales: this.notCrossSales
    }

    return this._call('reports/monthlyNewClients', data, method)
  },

  getNewClientsPerDistributor(selectedYear) {
    const method = 'POST'
    const data = {
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      selectedYear,
      notCrossSales: this.notCrossSales
    }

    return this._call('reports/newClientsPerDistributor', data, method)
  },

  getTotalClientsPerDistributor(selectedYear) {
    const method = 'POST'
    const data = {
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      selectedYear,
      notCrossSales: this.notCrossSales
    }

    return this._call('reports/totalClientsPerDistributor', data, method)
  },

  getMonthlyNewClientsEvolution(selectedYear) {
    const method = 'POST'
    const data = {
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString(),
      selectedYear,
      notCrossSales: this.notCrossSales
    }

    return this._call('reports/monthlyNewClientsEvolution', data, method)
  },
  getRegions() {
    const method = 'POST'

    const data = {
      userId: this._userId().toString(),
      tenantId: this._tenantId().toString()
    }

    return this._call('reports/clientsRegions', data, method)
  },


  deleteUserTenant({ tenantId, userToDeleteId }) {
    if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)
    if (!tenantId.trim()) throw Error('tenantId is empty or blank')
    if (typeof userToDeleteId !== 'string') throw TypeError(`userToDeleteId:${userToDeleteId} is not a string`)
    if (!userToDeleteId.trim()) throw Error('userToDeleteId is empty or blank')

    const method = 'DELETE'

    const data = {
      userId: this._userId(),
      tenantId: tenantId,
      distributorId: this._distributorId(),
      userToDeleteId
    }

    return this._call('tenant/users/delete', data, method)
  },

  // ----- End Tenant ----- //

  // ---- Start dashboard ----- //

  getMonthlySales ({ tenantId }) {
    if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)
    if (!tenantId.trim()) throw Error('tenantId is empty or blank')

    const method = 'POST'

    const data = {
      userId: this._userId(),
      tenantId: tenantId,
      notCrossSales: this.notCrossSales
    }

    return this._call('monthlySales', data, method)
  },

  getMonthlyStocks ({ tenantId }) {
    if (typeof tenantId !== 'string') throw TypeError(`tenantId:${tenantId} is not a string`)
    if (!tenantId.trim()) throw Error('tenantId is empty or blank')

    const method = 'POST'

    const data = {
      userId: this._userId(),
      tenantId: tenantId
    }

    return this._call('monthlyStocks', data, method)
  },

  getMonthlyDistributorSales({distributorId}){
    if (typeof distributorId !== 'string') throw TypeError(`distributorId:${distributorId} is not a string`)
    if (!distributorId.trim()) throw Error('distributorId is empty or blank')

    const method = 'POST'

    const data = {
      userId: this._userId(),
      distributorId
    }

    return this._call('dashboards/monthlyDistributorSales', data, method)
  },

  getMonthlyDistributorStocks({distributorId}){
    if (typeof distributorId !== 'string') throw TypeError(`distributorId:${distributorId} is not a string`)
    if (!distributorId.trim()) throw Error('distributorId is empty or blank')

    const method = 'POST'

    const data = {
      userId: this._userId(),
      distributorId
    }

    return this._call('dashboards/monthlyDistributorStocks', data, method)
  },

  getDistributorUploadPercent () {
    const method = 'POST'

    const data = {
      userId: this._userId(),
      tenantId: this._tenantId()
    }

    return this._call('dashboards/distributorPercentUploads', data, method)
  },

  
  getTotalClients () {
    const method = 'POST'

    const data = {
      userId: this._userId(),
      tenantId: this._tenantId(),
      notCrossSales: this.notCrossSales
    }

    return this._call('reports/totalClients', data, method)
  },

  getTotalClientsByYear (selectedYear) {
    const method = 'POST'

    const data = {
      userId: this._userId(),
      tenantId: this._tenantId(),
      selectedYear,
      notCrossSales: this.notCrossSales
    }

    return this._call('reports/totalClientsByYear', data, method)
  },


  getAverageClients (selectedYear) {
    const method = 'POST'

    const data = {
      userId: this._userId(),
      tenantId: this._tenantId(),
      selectedYear,
      notCrossSales: this.notCrossSales
    }

    return this._call('reports/averageClients', data, method)
  },

  getLastMonthSales () {
    const method = 'POST'

    const data = {
      userId: this._userId(),
      tenantId: this._tenantId(),
      notCrossSales: this.notCrossSales
    }

    return this._call('dashboards/lastMonthSales', data, method)
  },

  getLastMonthStocks () {
    const method = 'POST'

    const data = {
      userId: this._userId(),
      tenantId: this._tenantId()
    }

    return this._call('dashboards/lastMonthStocks', data, method)
  },

  getLastMonthDistributorSales () {
    const method = 'POST'

    const data = {
      userId: this._userId(),
      distributorId: this._distributorId()
    }

    return this._call('dashboards/lastMonthDistributorSales', data, method)
  },

  getLastMonthDistributorStocks () {
    const method = 'POST'

    const data = {
      userId: this._userId(),
      distributorId: this._distributorId()
    }

    return this._call('dashboards/lastMonthDistributorStocks', data, method)
  },

  getTotalProductsAllTenants () {
    const method = 'POST'

    const data = {
      userId: this._userId(),
      distributorId: this._distributorId()
    }

    return this._call('dashboards/totalproductsalltenants', data, method)
  },

  getTotalInactiveProductsAllTenants () {
    const method = 'POST'

    const data = {
      userId: this._userId(),
      distributorId: this._distributorId()
    }

    return this._call('dashboards/totalinactiveproductsalltenants', data, method)
  },

  getAbsoluteNonCorrespondance () {
    const method = 'POST'

    const data = {
      userId: this._userId(),
      tenantId: this._tenantId()
    }

    return this._call('dashboards/absoluteNonCorrespondance', data, method)
  },
  // ------ End dashboard ------ //

  // ------ Start language ----- //
  getUserLanguage () {

    const method = 'POST'

    const data = {
      userId: this.userId
    }

    return this._call('getUserLanguage', data, method)
  }
}

export { logic }
