import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, Link } from 'react-router-dom'
import { Breadcrumb, Icon } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './BreadCrumb.scss'

export const BreadCrumb = () => {
  const { t } = useTranslation()
  const [url, setUrl] = useState([])

  const location = useLocation()
  // ComponentDidMount
  useEffect(() => {
    // This is meant to use the location react router object to extract the path and extracts it to an array to later use for the breadcrumbs.
    // slice(1) is used to eliminate the first element as it is blank after doing split {example url: /services/example   after split ["",services,example]}
    const path = location && location.pathname.split('/')
    const pathNameUnfiltered = path && path.slice(1)
    const pathName = pathNameUnfiltered.filter(item => {
      return isNaN(item)
    })
    setUrl(pathName)
  }, [location])

  return (
    <Breadcrumb id='breadcrumb' separator='succeeds'>
      <Breadcrumb.Item as={Link} to='/dashboard'>
        <Icon size='small'>
          <FontAwesomeIcon icon={['fas', 'home']} />
        </Icon>
        {t('Home')}
      </Breadcrumb.Item>
      {url && url.map((path, index) => {
        let isActive = false
        if (index + 1 === url.length) {
          isActive = true
        }
        if(path === 'tenant'){
          return
        }
        return (
          <Breadcrumb.Item key={index} active={isActive}>
            {t(path)}
          </Breadcrumb.Item>
        )
      })}

    </Breadcrumb>
  )
}
