import React from 'react'
import {Form, Input,InputNumber, Badge, Icon, Tooltip} from 'antd'

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

export const EditableFormRow = Form.create()(EditableRow);

export class EditableCell extends React.Component {
  state = {
    editing: false,
  };

  toggleEdit = () => {
    console.log("EDITABLE - toggleEdit before changing editing: ", this.state.editing)
    const editing = !this.state.editing;
    console.log("EDITABLE - toggleEdit after changing editing: ", editing)
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  onChange = (column, e) => {
    if (column == 'grossPrice') 
      e.currentTarget.value = e.currentTarget.value.replace(/[^\d\,-]+/g, '');
  }

  renderCell = form => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props
    let errorTitle = title
    const { hasProductError,hasDuplicateError, hasCurrencyError, hasProductNameError, hasPriceError, hasPriceWarning, hasYearStartDateError, hasYearEndDateError, hasRangeError,hasStartDateFormated,hasEndDateFormated,hasEndMinorStartDate } = this.props && this.props.record
    let hasError = false;
    let hasWarning = false;
    let promptError
    let align = 'left';
    if(dataIndex === 'product'){
      if(hasProductError){
        hasError = true
        promptError = 'El codigo del producto no existe';
      }
    }
    if(dataIndex === 'product'){
      if(hasDuplicateError){
        hasError = true
        promptError = 'El codigo del producto esta duplicado';
      }
    }
    if(dataIndex === 'currency'){
      if(hasCurrencyError){
        hasError = true
        promptError = 'La divisa debe ser informada y debe ser EUR';
      }
    }

    if(dataIndex === 'productName'){
      if(hasProductNameError){
        hasError = true
        promptError = 'El nombre del producto no concuerda con el registro';
      }
    }
   
    if(dataIndex === 'grossPrice'){
      align = 'right';
      if(hasPriceWarning){
        hasWarning = true;
        promptError = "Detectado precio inferior o considerablemente superior al año anterior, precio del año: " + this.props.record.year + " precio: " + this.props.record.priceYear;
      }
    }
    if(dataIndex === 'grossPrice'){
      align = 'right';
      if(hasPriceError){
        hasError = true
        promptError = 'El precio debe ser mayor o igual que 0';
      }
    }
    if(dataIndex === 'startDate'){
      if(hasYearStartDateError){
        hasError = true;
        promptError = 'El Año de la fecha de inicio no concuerda con el año de la carga';
      }
    }

    if(dataIndex === 'startDate' || dataIndex == 'endDate'){
      if(hasRangeError){
        hasError = true;
        promptError = "Para este periodo de fecha de inicio y fecha fin hay cargado un precio";
      }
    }

    if(dataIndex === 'endDate'){
      if(hasYearEndDateError){
        hasError = true;
        promptError = 'El Año de la fecha de fin no concuerda con el año de la carga';
      }
    }

    if(dataIndex === 'startDate' || dataIndex == 'endDate'){
      if(hasEndMinorStartDate){
        hasError = true;
        promptError = "La fecha de inicio no puede ser mayor a la fecha de fin";
      }
    }

    if(dataIndex === 'startDate'){
      if(hasStartDateFormated){
        hasError = true;
        promptError = "Formato incorrecto para la fecha de inicio"
      }
    }

    if(dataIndex === 'enDate'){
      if(hasEndDateFormated){
        hasError = true;
        promptError = "Formato incorrecto para la fecha de fin"
      }
    }

    let color = '#f5222d';
    if (!hasError && hasPriceWarning) {
      color = '#ffcc00';
    }

    const { editing } = this.state;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: dataIndex !== 'clientName' && dataIndex !== 'clientCategory' ? true : false,
              message: `${errorTitle} es requerido.`,
            },
          ],
          initialValue: record[dataIndex],
        })(<Input readOnly={dataIndex == 'product'}  onChange={(e) => this.onChange(dataIndex,e)} ref={node => (this.input = node)}  style={{textAlign: align}} onPressEnter={this.save} onBlur={this.save} />)}
      </Form.Item>
    ) : (
      <div
        className='editable-cell-value-wrap'
        style={{ paddingRight: 24, minHeight: 32, textAlign: align }}
        onClick={this.toggleEdit}
      >
        {hasError || hasWarning ? (
          <Tooltip title={promptError}>
            <Badge count={<Icon type="exclamation-circle" theme="filled" style={{ color: color, margin: '-5px' }} />}>{children}</Badge>
          </Tooltip>
        ) : 
        children      
        }
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}