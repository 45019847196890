/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useAlert } from 'react-alert'
import { logic } from '../../logic'
import {
  Title,
  Level,
  Field,
  Icon,
  Button,
  Table,
  Box,
  Notification,
  Delete,
  Select
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Pagination } from '../pagination'
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom'
import { useToggle } from '../../logic/hooks'
import { Search } from '../filter/Search'
import { Modal } from '../modal'
import { TabsDistributor } from './tabsDistributor/'
import { NOTIFICATIONS } from '../../notifications'

export const Distributor = () => {
  // Hooks for toggling on and off the modal
  const [open, toggle] = useToggle(false)

  const [openInactive, toggleInactive] = useToggle(false)

  const [deleteItem, setDeleteItem] = useState()

  // state inside the modal
  const [related, setRelated] = useState([])

  // main state
  const [distributors, setDistributors] = useState()

  // distributors that are searched for
  const [searchDistributors, setSearchDistributors] = useState([])

  const [dropdownState, setDropdownState] = useState('active')
  const alert = useAlert()

  // i18n hook
  const { t } = useTranslation()

  // Routing Variables
  const { url } = useRouteMatch()
  const history = useHistory()
  const location = useLocation()

  // State refering to the pagination attributes comming from the pagination component
  const defaultPaginationStartPosition = 0
  const defaultPaginationEndPosition = 10
  const [startPosition, setStartPosition] = useState(
    defaultPaginationStartPosition
  )
  const [endPosition, setEndPosition] = useState(defaultPaginationEndPosition)

  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const paginationRef = useRef()

  // state to verify if a userTenant has been recently created
  const [hasCreatedDistributorTenant, setHasCreatedDistributorTenant] = useState(false)

  // Fetch products when component mounts
  useEffect(() => {
    logic.getDistributors().then(distributors => {
        const distributorTenantId = logic.distributorId
        let data = distributors && distributors.data
        data = data.filter(item => {
          return item.distributortenantid !== parseInt(distributorTenantId)
        }) 

      const sortedDistributors =
          data.sort((a, b) => {
          if (a.distributorname < b.distributorname) {
            return -1
          }
          if (a.distributorname > b.distributorname) {
            return 1
          }
        })
      setDistributors(sortedDistributors)
      setSearchDistributors(sortedDistributors)
    })
  }, [])

  // ComponentDidMount Check if password has been changed correctly
  useEffect(() => {
    function checkIfPasswordHasBeenReset () {
      const hasLocation = location
      if (hasLocation && hasLocation.state && hasLocation.state.message === t(NOTIFICATIONS.productTenantCreate)) {
        setHasCreatedDistributorTenant(true)
      }
    }
    checkIfPasswordHasBeenReset()
  }, [])

  // handles the pagination requests
  const handleRequestPagination = requestPaginationObject => {
    let newStartPosition = defaultPaginationStartPosition
    let newEndPosition = defaultPaginationEndPosition
    if (requestPaginationObject) {
      const { currentPage, itemsPerPage } = requestPaginationObject
      newStartPosition = currentPage * itemsPerPage - itemsPerPage
      newEndPosition = newStartPosition + itemsPerPage
    }
    setStartPosition(newStartPosition)
    setEndPosition(newEndPosition)
  }

  // handles routing to add/edit
  const handleAddEditClick = (event, element) => {
    if (element) {
      history.push({
        pathname: `${url}/edit/${element && element.distributortenantid}`,
        state: element
      })
    } else {
      history.push({
        pathname: `${url}/add`
      })
    }
  }

  // deletes the item
  const handleDeleteClick = (event, element) => {
    
    const id = `${element.distributortenantid}`
    logic
      .deleteDistributorTenant({ distributorTenantId: id })
      .then(item => {
        if (item.status !== 200) {
          throw Error(item.error)
        }

        const newDistributors = distributors.filter(
          distributor => {
            return distributor.distributortenantid !== item.data[0].distributortenantid
          }
        )

        const sortedNewDistributors =
        newDistributors &&
        newDistributors.sort((a, b) => {
          if (a.distributorname < b.distributorname) {
            return -1
          }
          if (a.distributorname > b.distributorname) {
            return 1
          }
        })
        setDistributors(newDistributors)
        setSearchDistributors(sortedNewDistributors)
        toggle()
        alert.show(t('updateSuccesful'), {
          type: 'success'
        })
      })
      .catch(err => {
        console.error(err.message)
        toggle()
        alert.show(`Error while inactivating the distributor id: ${id}. Error: ${err.message}`, {
          type: 'error'
        })
      })
  }

  // deletes the item
  const handleActivateClick = (event, element) => {
    
    const id = `${element.distributortenantid}`
    logic
      .activateDistributorTenant({ distributorTenantId: id })
      .then(item => {
        if (item.status !== 200) {
          throw Error(item.error)
        }

        const newDistributors = distributors.filter(
          distributor => {
            return distributor.distributortenantid !== item.data[0].distributortenantid
          }
        )

        const sortedNewDistributors =
        newDistributors &&
        newDistributors.sort((a, b) => {
          if (a.distributorname < b.distributorname) {
            return -1
          }
          if (a.distributorname > b.distributorname) {
            return 1
          }
        })
        setDistributors(newDistributors)
        setSearchDistributors(sortedNewDistributors)
        toggleInactive()
        alert.show(t('updateSuccesful'), {
          type: 'success'
        })
      })
      .catch(err => {
        console.error(err.message)
        toggleInactive()
        alert.show(`Error while activating the distributor id: ${id}. Error: ${err.message}`, {
          type: 'error'
        })
      })
  }

  const showRelatedItems = (event, item) => {
    setDeleteItem({ ...item })

    // TODO set correct logic for related
    setRelated([])
    toggle()
  }

  const showRelatedItemsInactive = (event, item) => {
    setDeleteItem({ ...item })

    // TODO set correct logic for related
    setRelated([])
    toggleInactive()
  }

  const handleDoSearch = value => {
    const result = distributors.filter(distributor => {
      const values = Object.values(distributor)

      const isIncluded = values
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(value.toLowerCase().replace(/\s+/g, ''))
      // pagination Reset values
      paginationRef.current.resetValues()

      return isIncluded
    })
    const sortedResult = result.sort((a, b) => {
      if (a.distributorname < b.distributorname) {
        return -1
      }
      if (a.distributorname > b.distributorname) {
        return 1
      }
    })
    setSearchDistributors(sortedResult)
    return sortedResult
  }


  const handleDropdownStateChange = (event) => {
    setDropdownState(event.target.value)
    if (event.target.value === 'active') {
      logic.getDistributors().then(distributors => {
        const distributorTenantId = logic.distributorId
        let data = distributors && distributors.data
        data = data.filter(item => {
          return item.distributortenantid !== parseInt(distributorTenantId)
        })
      const sortedDistributors =
          data.sort((a, b) => {
          if (a.distributorname < b.distributorname) {
            return -1
          }
          if (a.distributorname > b.distributorname) {
            return 1
          }
        })
      setDistributors(sortedDistributors)
      setSearchDistributors(sortedDistributors)
    })
    }

    if(event.target.value === 'inactive'){
      logic.getInactiveDistributors().then(distributors => {
        const distributorTenantId = logic.distributorId
        let data = distributors && distributors.data
        data = data.filter(item => {
          return item.distributortenantid !== parseInt(distributorTenantId)
        })
      const sortedDistributors =
          data.sort((a, b) => {
          if (a.distributorname < b.distributorname) {
            return -1
          }
          if (a.distributorname > b.distributorname) {
            return 1
          }
        })
      setDistributors(sortedDistributors)
      setSearchDistributors(sortedDistributors)
    })
    }
  }

  return (
    <Box>
      {open && (
        <Modal toggle={toggle} open={open}>
          <Title>{t('confirmDelete')}</Title>
          {related && related.length !== 0 && (
            <Title subtitle size={6}>
              {t('cannotDeleteRelated')}
            </Title>
          )}
          {related &&
            related.length !== 0 &&
            related.length > 7 &&
            related.slice(0, 7).map((item, index) => {
              return (
                <>
                  <Field key={index}>{item.distributorname}</Field>
                </>
              )
            })}
          {related &&
            related.length !== 0 &&
            related.length <= 7 &&
            related.map((item, index) => {
              return (
                <>
                  <Field key={index}>{item.distributorname}</Field>
                </>
              )
            })}
          {related && related.length !== 0 && related.length > 7 && (
            <Field>
              ...{t('and')} {related.length - 7} {t('others')}
            </Field>
          )}
          {related && related.length === 0 && (
            <>
              <Title subtitle>{t('deleteSure')}</Title>
              <Button
                onClick={e => handleDeleteClick(e, deleteItem)}
                color='danger'
              >
                {t('delete')}
              </Button>
            </>
          )}
        </Modal>
      )}
      {openInactive && (
          <Modal toggle={toggleInactive} open={openInactive}>
          <Title>{t('confirmActivate')}</Title>
          {related && related.length !== 0 && (
            <Title subtitle size={6}>
              {t('cannotDeleteRelated')}
            </Title>
          )}
          {related &&
            related.length !== 0 &&
            related.length > 7 &&
            related.slice(0, 7).map((item, index) => {
              return (
                <>
                  <Field key={index}>{item.distributorname}</Field>
                </>
              )
            })}
          {related &&
            related.length !== 0 &&
            related.length <= 7 &&
            related.map((item, index) => {
              return (
                <>
                  <Field key={index}>{item.distributorname}</Field>
                </>
              )
            })}
          {related && related.length !== 0 && related.length > 7 && (
            <Field>
              ...{t('and')} {related.length - 7} {t('others')}
            </Field>
          )}
          {related && related.length === 0 && (
            <>
              <Title subtitle>{t('activateSure')}</Title>
              <Button
                onClick={e => handleActivateClick(e, deleteItem)}
                color='success'
              >
                {t('Activate')}
              </Button>
            </>
          )}
        </Modal>
      )}
      {hasCreatedDistributorTenant &&
      <Level>
        <Level.Item>
          <Notification color='success'>
            <Delete as='button' onClick={() => setHasCreatedDistributorTenant(false)} />
            {t('distributorCreated')}
          </Notification>
        </Level.Item>
      </Level>}
      <TabsDistributor highlight='distributor' />
      <Level>
        <Select.Container>
          <Select value={dropdownState} onChange={handleDropdownStateChange} >
            <Select.Option value='active'>
              {t('Actives')}
            </Select.Option>
            <Select.Option value='inactive'>
              {t('Inactives')}
            </Select.Option>
          </Select>
        </Select.Container>
        <Level.Item>
          <Search doSearch={handleDoSearch} />
        </Level.Item>
        <Level.Item align='right'>
          <Button onClick={e => handleAddEditClick()} color='info'>
            <Icon>
              <FontAwesomeIcon size='lg' icon={['fas', 'plus']} />
            </Icon>
          </Button>
        </Level.Item>
      </Level>
      <Table fullwidth narrow hoverable bordered striped>
        <Table.Head>
          <Table.Row>
            <Table.Heading>{t('code')}</Table.Heading>
            <Table.Heading>{t('name')}</Table.Heading>
            <Table.Heading>{t('phone')}</Table.Heading>
            <Table.Heading>{t('alternatePhone')}</Table.Heading>
            <Table.Heading>{t('uploadDataBlocked')}</Table.Heading>
            <Table.Heading>{t('blockedto')}</Table.Heading>
            <Table.Heading>{t('action')}</Table.Heading>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {searchDistributors &&
            searchDistributors.slice(startPosition, endPosition).map((item, index) => {
              return (
                <Table.Row key={index}>
                  <Table.Heading>{item.distributorcode}</Table.Heading>
                  <Table.Cell>{item.distributorname}</Table.Cell>
                  <Table.Cell>{item.phone}</Table.Cell>
                  <Table.Cell>{item.alternativephone}</Table.Cell>
                  <Table.Cell>{item.isuploadblocked ? t('yes') : t('no')}</Table.Cell>
                  <Table.Cell>{item.tomonthblocked === null ? '-' : `${(new Date(item.tomonthblocked).getMonth()+1).toString().padStart(2, '0')}/${new Date(item.tomonthblocked).getFullYear()}`}</Table.Cell>
                  <Table.Cell>
                    <button
                      onClick={e => handleAddEditClick(e, item)}
                      className='button is-primary is-outlined is-small'
                    >
                      <Icon>
                        <FontAwesomeIcon size='lg' icon={['fas', 'edit']} />
                      </Icon>
                    </button>
                    {dropdownState === 'active' && <button
                      onClick={e => showRelatedItems(e, item)}
                      className='button is-danger is-outlined is-small'
                    >
                      <Icon>
                        <FontAwesomeIcon size='lg' icon={['fas', 'trash']} />
                      </Icon>
                    </button>}

                    {dropdownState === 'inactive' && <button
                      onClick={e => showRelatedItemsInactive(e, item)}
                      className='button is-success is-outlined is-small'
                    >
                      <Icon>
                        <FontAwesomeIcon size='lg' icon={['fas', 'check-circle']} />
                      </Icon>
                    </button>}
                  </Table.Cell>
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table>
      <Pagination
        totalItems={searchDistributors && searchDistributors.length}
        ref={paginationRef}
        requestPagination={handleRequestPagination}
      />
    </Box>
  )
}
