import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Tab, Icon, Generic } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

export const TabsSummary = ({ highlight }) => {
  const { t } = useTranslation()

  return (
    <Tab.Group align='centered' kind='boxed'>
      <Tab as={Link} to='/reports/Status' active={highlight === 'distributors status'}>
        <Icon size='small'>
          <FontAwesomeIcon icon={['fas', 'tasks']} />
        </Icon>
        <span>{t('Distributor status')}</span>
      </Tab>
      <Tab as={Link} to='/reports/Summary' active={highlight === 'monthly summary'}>
        <Icon size='small'>
          <FontAwesomeIcon icon={['fas', 'poll']} />
        </Icon>
        <span>{t('Monthly Summary')}</span>
      </Tab>
    </Tab.Group>
  )
}
