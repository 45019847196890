const arrayFilters = {
  arrayFilters(array) {
    let list = [...new Set(array)]
    list = list.filter(function (el) {
      return el != null
    })
    arrayFilter = list.map(function (el, i) {
      return { index: i, value: el.toLowerCase() };
    })
    arrayFilter.sort(function (a, b) {
      if (a.value > b.value) {
        return 1;
      }
      if (a.value < b.value) {
        return -1;
      }
      return 0;
    });
    var arrayFilter = arrayFilter.map(function (el) {
      return list[el.index];
    });
    return arrayFilter
  }
}
export { arrayFilters }