import React, { useState, useEffect ,useContext } from 'react'
import { logic } from '../../logic'
import { Box, Image } from 'rbx'
import { useTranslation } from 'react-i18next'
import { AdsContext } from "../../contexts/adsContext"

export const AdminDashboard = () => {

  const { t } = useTranslation()
  const [dashboardUrl, setDashboardUrl] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    (async function () {
        try {
          let adminDashboard = []
          await logic
                .loadTableOfTables()
                .then(tableOfTables => {
                  adminDashboard = tableOfTables.data.filter(tableOfTables => tableOfTables.tableoftablesname === 'ADMDASH' && 
                                                              tableOfTables.tableoftablesdata.split('###')[0] === 'DATA')
                })
          setDashboardUrl(adminDashboard[0].tableoftablesdata.split('###')[2])
        } catch(err) {
            window.alert(err.message)
        }
    })()
  }, [])

  const hideSpinner = () => {
    setIsLoading(false)
  }

  return (
    <Box id="dashboard">
      <Image.Container size='3by2'>
          <iframe src={dashboardUrl} onLoad={hideSpinner} /> 
      </Image.Container>
    </Box>
  )
}
