import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tab, Icon } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

export const TabsUsersAdmin = ({ highlight }) => {
  const { t } = useTranslation()

  return (
    <Tab.Group align='centered' kind='boxed'>
      <Tab as={Link} to='/users' active={highlight === 'users'}>
        <Icon size='small'>
          <FontAwesomeIcon icon={['fas', 'shopping-basket']} />
        </Icon>
        <span>{t('Distribuidor/Tenant')}</span>
      </Tab>
      <Tab as={Link} to='/users/unblock' active={highlight === 'unblock'}>
        <Icon size='small'>
          <FontAwesomeIcon icon={['fas', 'copyright']} />
        </Icon>
        <span>{t('Unblock')}</span>
      </Tab>
      <Tab as={Link} to='/users/changePassword' active={highlight === 'changePassword'}>
        <Icon size='small'>
          <FontAwesomeIcon icon={['fas', 'briefcase']} />
        </Icon>
        <span>{t('Cambiar contraseña')}</span>
      </Tab>
    </Tab.Group>
  )
}
