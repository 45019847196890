import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { logic } from '../../logic'
import { TabsCorrespondance } from './tabsCorrespondance'
import {
  Title,
  Level,
  Field,
  Icon,
  Button,
  Table,
  Box
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Pagination } from '../pagination'
import { useHistory } from 'react-router-dom'
import { useToggle } from '../../logic/hooks'
import { Search } from '../filter/Search'
import { Modal } from '../modal'

export const NonCorrespondance = () => {
  // Hooks for toggling on and off the modal
  const [open, toggle] = useToggle(false)

  const [deleteItem, setDeleteItem] = useState()

  // state inside the modal
  const [related, setRelated] = useState([])

  // main state
  const [products, setProducts] = useState([])

  // products that are searched for
  const [searchProducts, setSearchProducts] = useState([])

  const [nonCorrespondances, setNonCorrespondances] = useState(false)

  // i18n hook
  const { t } = useTranslation()

  // Routing Variables
  const history = useHistory()

  // State refering to the pagination attributes comming from the pagination component
  const defaultPaginationStartPosition = 0
  const defaultPaginationEndPosition = 10
  const [startPosition, setStartPosition] = useState(
    defaultPaginationStartPosition
  )
  const [endPosition, setEndPosition] = useState(defaultPaginationEndPosition)

  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const paginationRef = useRef()

  // Fetch products when component mounts
  useEffect(() => {
    logic
      .getNonProductCorrespondance()
      .then(nonCorrespondances => {
        if (nonCorrespondances.status === 404) {
          setProducts([])
        } else if (nonCorrespondances.status === 200) {
          const distTenantId = logic.distributorId
          const nonCorrespondanceData = nonCorrespondances && nonCorrespondances.data
          const filteredProducts = nonCorrespondanceData.filter(product => {
            return product && product.distributortenantid !== parseInt(distTenantId)
          })
          const sortedNewProducts =
          filteredProducts &&
          filteredProducts.sort((a, b) => {
            if (a.productname < b.productname) {
              return -1
            }
            if (a.productname > b.productname) {
              return 1
            }
            return 0
          })
          setProducts(sortedNewProducts)
          setSearchProducts(sortedNewProducts)
          setNonCorrespondances(true)
        } else {
          throw Error('unexpected Error')
        }
      })
      .catch(err => {
        window.alert(err.message)
      })
  }, [])

  // handles the pagination requests
  const handleRequestPagination = requestPaginationObject => {
    let newStartPosition = defaultPaginationStartPosition
    let newEndPosition = defaultPaginationEndPosition
    if (requestPaginationObject) {
      const { currentPage, itemsPerPage } = requestPaginationObject
      newStartPosition = currentPage * itemsPerPage - itemsPerPage
      newEndPosition = newStartPosition + itemsPerPage
    }
    setStartPosition(newStartPosition)
    setEndPosition(newEndPosition)
  }

  // handles routing to add/edit
  const handleAddEditClick = (event, element) => {
    if (element) {
      history.push({
        pathname: `/correspondance/add/${element && element.productdistributorid}`,
        state: element
      })
    }
  }

  const handleDoSearch = value => {
    const result = products.filter(product => {
      product.searchProductName = product.productname.toString()
      const values = Object.values(product)

      const isIncluded = values
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
      // pagination Reset vallues
      paginationRef.current.resetValues()

      return isIncluded
    })
    const sortedResult = result.sort((a, b) => {
      if (a.productname < b.productname) {
        return -1
      }
      if (a.productname > b.productname) {
        return 1
      }
      return 0
    })
    setSearchProducts(sortedResult)
    return sortedResult
  }

  return (
    <Box>
      <TabsCorrespondance isPending={nonCorrespondances} highlight='correspondanceNot' />
      <Level>
        <Level.Item>
          <Search doSearch={handleDoSearch} />
        </Level.Item>
        <Level.Item align='right'>
          <Button onClick={e => handleAddEditClick()} color='info'>
            <Icon>
              <FontAwesomeIcon size='lg' icon={['fas', 'plus']} />
            </Icon>
          </Button>
        </Level.Item>
      </Level>
      <Table hoverable bordered striped narrow fullwidth>
        <Table.Head>
          <Table.Row>
            <Table.Heading>{t('distributorProductCode')}</Table.Heading>
            <Table.Heading>{t('productName')}</Table.Heading>
            <Table.Heading>{t('distributorCode')}</Table.Heading>
            <Table.Heading>{t('distributorName')}</Table.Heading>
            <Table.Heading>{t('action')}</Table.Heading>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {searchProducts &&
            searchProducts
              .slice(startPosition, endPosition)
              .map((item, index) => {
                return (
                  <Table.Row key={`${item.productdistributorcode}-${index}`}>
                    <Table.Heading>{item.productdistributorcode}</Table.Heading>
                    <Table.Cell>{item.productname}</Table.Cell>
                    <Table.Heading>{item.distributorcode}</Table.Heading>
                    <Table.Cell>{item.distributorname}</Table.Cell>
                    <Table.Cell>
                      <button onClick={e => handleAddEditClick(e, item)} className='button is-primary is-outlined is-small'>
                        <Icon>
                          <FontAwesomeIcon size='lg' icon={['fas', 'edit']} />
                        </Icon>
                      </button>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
          {!searchProducts && (
            <Table.Row>
              <Table.Heading colSpan='4'>{t('noDataAvailable')}</Table.Heading>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <Pagination
        totalItems={searchProducts && searchProducts.length}
        ref={paginationRef}
        requestPagination={handleRequestPagination}
      />
    </Box>
  )
}
