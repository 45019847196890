/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import { useAlert } from 'react-alert'
import { logic } from '../../../logic'
import { Select, Level, Box } from 'rbx'
import { TabsSummary } from '../TabsSummary'
import './StatusDistributor.scss'
import { Table, Tag } from 'antd'

const { Column } = Table

export const StatusDistributor = () => {
  const alert = useAlert()

  // i18n hook
  const { t } = useTranslation()

  // main state
  const [selectedYear, setSelectedYear] = useState(new Date().getMonth() === 0 ? new Date().getFullYear() - 1 : new Date().getFullYear())

  // main state
  const [dataSource, setDataSource] = useState()

  // distributors that are searched for
  const [distributorStatus, setDistributorStatus] = useState([])

  const currentYear = new Date().getFullYear()
  const fromYear = 2020
  const toYear = new Date(currentYear, 11).getFullYear()
  const years = []
  for (let i = toYear; i >= fromYear; i -= 1) {
    years.push(i)
  }

  // Fetch products when component mounts
  useEffect(() => {
    logic
      .getDistributorStatus(selectedYear)
      .then(distributorsStatus => {
        const data = distributorsStatus && distributorsStatus.data
        setDataSource(data)
        setDistributorStatus(data)
      })
  }, [selectedYear])

  // components for the editable rows

  const handleSelectYear = event => {
    const year = event.target.value
    setSelectedYear(year)
    
  }

  return (
    <>
      <Box>
        <TabsSummary highlight='distributors status' />
          <Select.Container>
              <Select value={selectedYear} onChange={handleSelectYear}>
                {years.map((year, index) => {
                  return (
                    <Select.Option key={year} value={year}>
                      {year}
                    </Select.Option>
                  )
                })}
              </Select>
          </Select.Container>
        <div id='distributorStatus'>

         <Box className='glosario'>
            <Level>
              <Tag color='#02C39A'>
                {t('x')}
              </Tag>
              {t('Data uploaded')}

              <Tag color='#EA7317'>
                {t('x')}
              </Tag>
              {t('Sales Data uploaded')}
              <Tag color='#EFD28D'>
                {t('x')}
              </Tag>
              {t('Stock Data uploaded')}
              <Tag color='#F05365'>
                {t('x')}
              </Tag>
              {t('Data not uploaded')}
            </Level>
          </Box>
          <Box>
          {!distributorStatus.length ? 'No existen datos' : <Table dataSource={dataSource} pagination={false}>
              <Column title={t('Distribuidor')} dataIndex='distributorname' key='distributorname' width='50' className='columnaDistribuidor' />
              {false && <Column title={t('Año')} dataIndex='year' key='year' />}
              <Column
                title={t('Enero')} dataIndex='January' key='January' render={tag => (
                  <>
                    {tag === null && <Tag color='#F05365' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 1 && <Tag color='#EA7317' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 2 && <Tag color='#EFD28D' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 3 && <Tag color='#02C39A' key={tag}>
                      {t('x')}
                                  </Tag>}
                  </>
                )}
              />
              <Column
                title={t('Febrero')} dataIndex='February' key='February' render={tag => (
                  <>
                    {tag === null && <Tag color='#F05365' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 1 && <Tag color='#EA7317' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 2 && <Tag color='#EFD28D' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 3 && <Tag color='#02C39A' key={tag}>
                      {t('x')}
                                  </Tag>}
                  </>
                )}
              />
              <Column
                title={t('Marzo')} dataIndex='March' key='March' render={tag => (
                  <>
                    {tag === null && <Tag color='#F05365' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 1 && <Tag color='#EA7317' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 2 && <Tag color='#EFD28D' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 3 && <Tag color='#02C39A' key={tag}>
                      {t('x')}
                                  </Tag>}
                  </>
                )}
              />
              <Column
                title={t('Abril')} dataIndex='April' key='April' render={tag => (
                  <>
                    {tag === null && <Tag color='#F05365' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 1 && <Tag color='#EA7317' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 2 && <Tag color='#EFD28D' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 3 && <Tag color='#02C39A' key={tag}>
                      {t('x')}
                                  </Tag>}
                  </>
                )}
              />
              <Column
                title={t('Mayo')} dataIndex='May' key='May' render={tag => (
                  <>
                    {tag === null && <Tag color='#F05365' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 1 && <Tag color='#EA7317' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 2 && <Tag color='#EFD28D' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 3 && <Tag color='#02C39A' key={tag}>
                      {t('x')}
                                  </Tag>}
                  </>
                )}
              />
              <Column
                title={t('Junio')} dataIndex='June' key='June' render={tag => (
                  <>
                    {tag === null && <Tag color='#F05365' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 1 && <Tag color='#EA7317' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 2 && <Tag color='#EFD28D' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 3 && <Tag color='#02C39A' key={tag}>
                      {t('x')}
                                  </Tag>}
                  </>
                )}
              />
              <Column
                title={t('Julio')} dataIndex='July' key='July' render={tag => (
                  <>
                    {tag === null && <Tag color='#F05365' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 1 && <Tag color='#EA7317' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 2 && <Tag color='#EFD28D' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 3 && <Tag color='#02C39A' key={tag}>
                      {t('x')}
                                  </Tag>}
                  </>
                )}
              />
              <Column
                title={t('Agosto')} dataIndex='August' key='August' render={tag => (
                  <>
                    {tag === null && <Tag color='#F05365' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 1 && <Tag color='#EA7317' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 2 && <Tag color='#EFD28D' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 3 && <Tag color='#02C39A' key={tag}>
                      {t('x')}
                                  </Tag>}
                  </>
                )}
              />
              <Column
                title={t('Septiembre')} dataIndex='September' key='September' render={tag => (
                  <>
                    {tag === null && <Tag color='#F05365' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 1 && <Tag color='#EA7317' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 2 && <Tag color='#EFD28D' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 3 && <Tag color='#02C39A' key={tag}>
                      {t('x')}
                                  </Tag>}
                  </>
                )}
              />
              <Column
                title={t('Octubre')} dataIndex='October' key='October' render={tag => (
                  <>
                    {tag === null && <Tag color='#F05365' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 1 && <Tag color='#EA7317' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 2 && <Tag color='#EFD28D' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 3 && <Tag color='#02C39A' key={tag}>
                      {t('x')}
                                  </Tag>}
                  </>
                )}
              />
              <Column
                title={t('Noviembre')} dataIndex='November' key='November' render={tag => (
                  <>
                    {tag === null && <Tag color='#F05365' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 1 && <Tag color='#EA7317' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 2 && <Tag color='#EFD28D' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 3 && <Tag color='#02C39A' key={tag}>
                      {t('x')}
                                  </Tag>}
                  </>
                )}
              />
              <Column
                title={t('Diciembre')} dataIndex='December' key='December' render={tag => (
                  <>
                    {tag === null && <Tag color='#F05365' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 1 && <Tag color='#EA7317' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 2 && <Tag color='#EFD28D' key={tag}>
                      {t('x')}
                                  </Tag>}
                    {tag === 3 && <Tag color='#02C39A' key={tag}>
                      {t('x')}
                                  </Tag>}
                  </>
                )}
              />
            </Table>}
          </Box>
        </div>
      </Box>
    </>
  )
}