import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import { useTranslation } from 'react-i18next'
import { logic } from '../../logic'
import { Box, Field, Label, Input, Control, Icon, Button, Checkbox } from 'rbx'
import { SelectContainer, Select } from 'rbx/elements/form/select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from 'react-hook-form'
import { FormError, HelpError } from '../error'
import { useConfirmActionMessage } from '../../logic/hooks'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
const schema = yup.object().shape({
  newPassword: yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&._€+#-])[A-Za-z\d@$!%*?&._€+#-]{8,}$/, { excludeEmptyString: true }),
  confirmNewPassword: yup.string().oneOf([yup.ref('newPassword'), null]),
  currentPassword: yup.string().required(),
  lang: yup.string().required()
})

const LANGUAGES = ['EN', 'ES']

export const Profile = () => {
  const alert = useAlert()
  const { t, i18n } = useTranslation()

  const [user, setUser] = useState()

  const confirmAction = useConfirmActionMessage()

  useEffect(() => {
    const sessionId = logic.sessionId.toString()
    logic.retrieveUser(sessionId)
      .then(user => {
        setUser(user && user.data && user.data[0])
      })
  }, [])
  const { register, handleSubmit, errors, setError } = useForm({ validationSchema: schema })
  const onSubmit = data => {
    const {
      newPassword,
      confirmNewPassword,
      email,
      userName,
      phone,
      currentPassword,
      lang
    } = data
    let { notify } = data

    if (notify === true) {
      notify = '1'
    }
    if (notify === false) {
      notify = '0'
    }
    if (newPassword !== confirmNewPassword) {
      return setError('confirmNewPassword', 'passwordNotMatch')
    }
    logic.updateUser({
      userName,
      userEmail: email,
      userPhone: phone || '-',
      password: currentPassword,
      newPassword,
      notify,
      lang
    })
      .then(user => {
        if (user.status !== 200) {
          throw Error(`${user.status}`)
        }
        setUser(user && user.data && user.data[0])
        const language = user && user.data && user.data[0] && user.data[0].lang
        i18n.changeLanguage(language && language.toLowerCase())
        alert.show(t('updateSudccesful'), {
          type: 'success'
        })
      })
      .catch(err => {
        if (err.message === '401') {
          alert.show(t('incorrectPassword'), {
            type: 'error'
          })
        } else {
          alert.show(t('unexpectedError'), {
            type: 'error'
          })
        }
      })
  }

  return (
    <div>
      {/* <Tabs /> */}
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Field>
            <Label>{t('username')}</Label>
            <Control iconLeft>
              <Input
                defaultValue={user && user.username}
                type='text'
                color='light'
                name='userName'
                ref={register({
                  required: true,
                  maxLength: 80
                })}
              />
              <Icon size='small' align='left'>
                <FontAwesomeIcon icon={['fas', 'user']} />
              </Icon>
            </Control>
            {errors.userName && errors.userName.type === 'required' && (
              <HelpError message={t('required')} />
            )}
            {errors.userName && errors.userName.type === 'maxLength' && (
              <HelpError message={t('tooManyCharacters')} />
            )}
          </Field>

          <Field>
            <Label>Email</Label>
            <Control iconLeft iconRight>
              <Input
                defaultValue={user && user.useremail}
                type='email'
                color='light'
                name='email'
                ref={register({
                  required: true,
                  maxLength: 120
                })}
              />
              <Icon size='small' align='left'>
                <FontAwesomeIcon icon={['fas', 'envelope']} />
              </Icon>
            </Control>
            {errors.email && errors.email.type === 'required' && (
              <HelpError message={t('required')} />
            )}
            {errors.email && errors.email.type === 'maxLength' && (
              <HelpError message={t('tooManyCharacters')} />
            )}
          </Field>

          <Field>
            <Label>{t('phoneNumber')}</Label>
            <Control iconLeft iconRight>
              <Input
                defaultValue={user && user.userphone}
                type='text'
                color='light'
                name='phone'
                ref={register({
                  maxLength: 20
                })}
              />
              <Icon size='small' align='left'>
                <FontAwesomeIcon icon={['fas', 'envelope']} />
              </Icon>
            </Control>
            {errors.phone && errors.phone.type === 'required' && (
              <HelpError message={t('required')} />
            )}
            {errors.phone && errors.phone.type === 'maxLength' && (
              <HelpError message={t('tooManyCharacters')} />
            )}
          </Field>

          <Field>
            <Label>{t('Habilitar notificationes')}</Label>
            <Checkbox
              type='checkbox'
              color='light'
              name='notify'
              ref={register}
              defaultChecked={
                (user && user.notify)
              }
            />
          </Field>
          <Field>
            <Label>{t('language')}</Label>
            <SelectContainer>
            <Select
              name='lang'
              ref={register({
                required: true
              })}
              //defaultValue={defaultLanguage}
              //onChange={handleLanguageSelect}
              //value={selectedLanguage || user && user.lang}
              >
        <Select.Option value=''>{t('select')} {t('language')}</Select.Option>
        {LANGUAGES && LANGUAGES.map((language, index) => {
          return (
            <Select.Option
              key={index}
              value={language}
              selected={user && user.lang === language ? 'selected' : 'Español'}
            >{language === 'ES' ? 'Español' : 'English'}
            </Select.Option>
          )
        })}
      </Select>
    </SelectContainer>
            {errors.lang && (
              <HelpError message={t('Debe seleccionar un idioma')} />
            )}
          </Field>

          <Field>
            <Label>{t('newPassword')}</Label>
            <Control iconLeft>
              <Input
                type='password'
                color='light'
                name='newPassword'
                ref={register({
                  maxLength: 120,
                  minLength: 4
                })}
              />
              <Icon size='small' align='left'>
                <FontAwesomeIcon icon={['fas', 'lock']} />
              </Icon>
            </Control>
            {errors.newPassword && (
              <HelpError message={t('Recuerda, la contraseña tiene que tener un mínimo de ocho caracteres, al menos una mayúscula, una minúscula, un número y un carácter especial.')} />
            )}
          </Field>

          <Field>
            <Label>{t('confirmNewPassword')}</Label>
            <Control iconLeft>
              <Input
                type='password'
                color='light'
                name='confirmNewPassword'
                ref={register({
                  maxLength: 120,
                  minLength: 4
                })}
              />
              <Icon size='small' align='left'>
                <FontAwesomeIcon icon={['fas', 'lock']} />
              </Icon>
            </Control>
            {errors.confirmNewPassword && (
              <HelpError message={t('passwordNotMatch')} />
            )}
          </Field>

          <Field style={{marginTop: '100px'}}>
            <Label>{t('confirmYourCurrentPassword')}</Label>
            <Control iconLeft>
              <Input
                type='password'
                color='light'
                name='currentPassword'
                ref={register({
                  required: true
                })}
              />
              <Icon size='small' align='left'>
                <FontAwesomeIcon icon={['fas', 'lock']} />
              </Icon>
            </Control>
            {errors.currentPassword && errors.currentPassword.type === 'required' && (
              <HelpError message={t('required')} />
            )}
          </Field>
          <Field kind='group'>
            <Control>
              <Button color='link'>{t('update')}</Button>
            </Control>
            <Control>
              <Button as={Link} to='/' type='button' text>{t('cancel')}</Button>
            </Control>
            {confirmAction.showMessage && <FormError {...confirmAction} />}
          </Field>
        </form>
      </Box>
    </div>
  )
}
