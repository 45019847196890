/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState, useContext } from 'react'
import { useAlert } from 'react-alert'
import {
  Select,
  Textarea,
  Box,
  Title,
  Field,
  Input,
  Control,
  Label,
  Button,
  Icon,
  Column,
  Table
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11'
import { Controller } from 'react-hook-form'
import { FormError, HelpError } from '../../../error'
import { logic } from '../../../../logic'
import { TabsGroups } from '../../tabsGroups'
import { useConfirmActionMessage } from '../../../../logic/hooks'
import ReactSelect, { createFilter } from 'react-select'
import { Pagination } from '../../../pagination'
import { AdsContext } from "../../../../contexts/adsContext"


// import { FormError, HelpError } from '../error'

export const AddEditClientsGroupTenant = () => {
  const alert = useAlert()
  // clientTenants
  const [clientsGroup, setClientsGroup] = useState()
  // Error handling
  const confirmAction = useConfirmActionMessage()

  const { t } = useTranslation()
  const history = useHistory()
  const { id } = useParams()
  const { register, handleSubmit, errors, watch, control } = useForm()
  const [options, setOptions] = useState([])
  const [selectedIdentities, setSelectedIdentities] = useState([[]]) // AMN - Each time a client's identity is selected or removed must be added/removed here
  const [boldIdentities, setBoldIdentities] = useState([])

   // State refering to the pagination attributes comming from the pagination component
   const defaultPaginationStartPosition = 0
   const defaultPaginationEndPosition = 10
   const [startPosition, setStartPosition] = useState(
     defaultPaginationStartPosition
   )
   const [endPosition, setEndPosition] = useState(defaultPaginationEndPosition)
   let tenantClientsIdentities = []
   const [allClientsWithSales, setAllClientsWithSales] = useState([])
   const [apiIdentities, setApiIdentities] = useState([])
   const [reloadData, setReloadData] = useState()
   const { allGroups, fetchAllTenantClientsGroup } = useContext(AdsContext)
   const [originalName, setOriginalName] = useState('')

  // componentDidMount ---> get clientsGroup with clients' identities and prepare selected ones
  useEffect(() => {
    fetchAllTenantClientsGroup()
    if (id) {
      logic
        .getOneTenantClientsGroup({ clientGroupId: id })
        .then(tenantClientsGroup => {
          setClientsGroup(tenantClientsGroup && tenantClientsGroup.data)
          if (tenantClientsGroup && tenantClientsGroup.data) {
            setOriginalName(tenantClientsGroup.data[0].clientname)
          }
          if (tenantClientsGroup && tenantClientsGroup.data && tenantClientsGroup.data[0].clientidentity !== null) {
            const sortedTenantClientsGroup =
              tenantClientsGroup.data.sort((a, b) => {
              if (a.clientidentity.toLowerCase() < b.clientidentity.toLowerCase()) {
                return -1
              }
              if (a.clientidentity.toLowerCase() > b.clientidentity.toLowerCase()) {
                return 1
              }
              return 0
              })
            setSelectedIdentities(toThreeClientsRows(sortedTenantClientsGroup.map(clientGroup => clientGroup.clientidentity)))
            setApiIdentities([...tenantClientsGroup.data])
          } else {
            setSelectedIdentities([[]])
          }
          tenantClientsIdentities = tenantClientsGroup.data.map( tenantClientGroup => {
            return tenantClientGroup.clientidentity
          })
        })
        .catch(err => {
          alert.show(err.message, {
            type: 'error'
          })
        })
    }
    logic
    .getTenantClientsWithSales({ tenantId: logic.tenantId.toString() })
    .then(clientsWithSales => {
     setAllClientsWithSales(clientsWithSales.data.map( clientWithSales => { return clientWithSales }))
      const notSelectedClientsWithSales = clientsWithSales.data.filter( identity => {
       let isIncluded = tenantClientsIdentities.includes(identity.clientidentity)
       if (!isIncluded) return identity 
       })
      
      const sortedNotSelectedClientsWithSales =
       notSelectedClientsWithSales.sort((a, b) => {
       if (a.clientidentity.toLowerCase() < b.clientidentity.toLowerCase()) {
         return -1
       }
       if (a.clientidentity.toLowerCase() > b.clientidentity.toLowerCase()) {
        return 1
       }
       return 0
       })
      setOptions(
        sortedNotSelectedClientsWithSales.map(identity => {
          return {
            label: `${identity.clientidentity}`,
            value: identity.clientidentity
          }
        })
      )
    })
    .catch(err => {
      alert.show(err.message, {
        type: 'error'
      })
    })
  }, [reloadData])

  // From a list of clients' identities to a three-on-a-row list of clients' identities
  const toThreeClientsRows =  (clientsIdentities) => {
    let threeClientsIdentitiesOnARow = []
    let threeClientsIdentities = []
    let countIdentities = 0;
    for (let index=0; index < clientsIdentities.length; index++) {
      if (index % 3 === 0) {
        threeClientsIdentities.push(clientsIdentities[index])
      } else {
        countIdentities++
        threeClientsIdentities.push(clientsIdentities[index])
        if (countIdentities === 2) {
          threeClientsIdentitiesOnARow.push(threeClientsIdentities)
          countIdentities = 0
          threeClientsIdentities = []
        } 
      }
    }
    if (threeClientsIdentities.length !== 0) threeClientsIdentitiesOnARow.push(threeClientsIdentities)

    return threeClientsIdentitiesOnARow
  }

  const handleClientIdentitySelected = (clientSelected) => {
    setSelectedIdentities(toThreeClientsRows([clientSelected.value].concat(...selectedIdentities)))
    setBoldIdentities([clientSelected.value].concat(...boldIdentities))
    setOptions(options
      .filter((identity, index) => { 
        if(index !== options.indexOf(clientSelected)) return identity
      })
    )
  }

  const handleClientIdentityDeselected = (e, clientIdentity) => {
    let newSelectedIdentities = deselectClientIdentity(selectedIdentities, clientIdentity)

    setSelectedIdentities(toThreeClientsRows([].concat(...newSelectedIdentities)))

    let newSortedOptions = [...options]
    newSortedOptions.push({
      label: clientIdentity,
      value: clientIdentity
    })
    newSortedOptions.sort((a, b) => {
      if (a.value.toLowerCase() < b.value.toLowerCase()) {
        return -1
      }
      if (a.value.toLowerCase() > b.value.toLowerCase()) {
        return 1
      }
      return 0
    })

    setOptions(newSortedOptions)
  }

  const deselectClientIdentity = (twoDimensionArray, elementToFind) => {
    for (let firstIndex = 0; twoDimensionArray.length > firstIndex; firstIndex++) {
      for (let secondIndex = 0; twoDimensionArray[firstIndex].length > secondIndex; secondIndex++) {
        if (twoDimensionArray[firstIndex][secondIndex] === elementToFind) {
          twoDimensionArray[firstIndex].splice(secondIndex, 1)
          return twoDimensionArray
        }
      }
    }
    return [-1, -1]
  }

  const handleGoBackClick = () => {
    history.push('/clients/groups')
  }

  // handles the pagination requests
  const handleRequestPagination = requestPaginationObject => {
  let newStartPosition = defaultPaginationStartPosition
  let newEndPosition = defaultPaginationEndPosition
  if (requestPaginationObject) {
    const { currentPage, itemsPerPage } = requestPaginationObject
    newStartPosition = currentPage * itemsPerPage - itemsPerPage
    newEndPosition = newStartPosition + itemsPerPage
  }
  setStartPosition(newStartPosition)
  setEndPosition(newEndPosition)
  }

  const identitiesToSend = (identitiesToSend) => {
    let identitiesToAdd, identitiesToRemove = []

    identitiesToAdd = identitiesToSend.filter(identityToSend => {
      const found = apiIdentities.find(identity => identity.clientidentity === identityToSend )
      if(!found) return identityToSend       
    })
    let clientId
    for (let index = 0; index < identitiesToAdd.length; index++) {
     clientId = allClientsWithSales.find(clientIdentity => clientIdentity.clientidentity === identitiesToAdd[index])
     identitiesToAdd[index] = clientId.clientid
    }

    identitiesToRemove = apiIdentities.filter(identityToSend => {
      if(!identitiesToSend.includes(identityToSend.clientidentity)) return identityToSend
    })

    for (let index = 0; index < identitiesToRemove.length; index++) {
     clientId = allClientsWithSales.find(clientIdentity => clientIdentity.clientidentity === identitiesToRemove[index].clientidentity)
     identitiesToRemove[index] = clientId.clientid
    }

    return [ identitiesToAdd, identitiesToRemove ]
  }
  
  const onSubmitData = data => {
    const { name, description } = data
    // AMN - we check the name doesn't exist to avoid duplicated clients' group names for a tenant
    const existsName = allGroups.filter(group => { if (group.clientname === name) return group })
    if (existsName && existsName.length > 0 && name !== originalName) {
      alert.show(t(`The clients' group name already exists`), {
        type: 'error'
      })
      return
    }
    confirmAction.closeMessage()
    const clientsIDs = identitiesToSend([].concat(...selectedIdentities))
    
    if (id) {
      logic
        .updateClientsGroupTenant({
          clientGroupId: id.toString(),
          clientName: name,
          clientDescription: description,
          clientsIDsToAdd: clientsIDs[0].join(";"), 
          clientsIDsToDelete: clientsIDs[1].join(";"), 
        },  {tenantId: logic.tenantId.toString()})
        .then(clientsGroup => {
          if (clientsGroup.status !== 200) {
            throw Error(`${clientsGroup.status}`)
          }
          setReloadData(!reloadData)
          setBoldIdentities([])
          alert.show(t('updateSuccesful'), {
            type: 'success'
          })
        })
        .catch(err => {
          if (err.message === '409') { // AMN - Check this 409 on the api
            alert.show('Input already exists', {
              type: 'error'
            })
          } else {
            alert.show('Unexpected Error', {
              type: 'error'
            })
          }
        })
    } else {
      logic
        .insertClientsGroupTenant({
          clientName: name,
          clientDescription: description,
          clientsIDsToAdd: clientsIDs[0].join(";") // AMN - Here we will have to see how the cliendIDs array is kept in the form, take it and send inserts, deletes as "nn;nn"
        }, {tenantId: logic.tenantId.toString()})
        .then(clientsGroup => {
          if (clientsGroup.status !== 200) {
            throw Error(clientsGroup.error)
          }
          alert.show(t('clientsGroupTenantSuccessfullyAdded'), {
            type: 'success'
          })
          history.push({
            pathname: '/clients/groups',
            state: '200'
          })
        })
        .catch(err => {
            alert.show(t(err.message), {
              type: 'error'
            })
        })
    }
  }

  return (
    <Box>
      <TabsGroups highlight='groups' />
      <Button onClick={e => handleGoBackClick()} color='light' size='medium'>
        <Icon>
          <FontAwesomeIcon size='lg' icon={['fas', 'chevron-circle-left']} />
        </Icon>
      </Button>
      <Title>
        {id
          ? `${t('edit')} ${clientsGroup && clientsGroup[0].clientname}`
          : `${t('add')} ${t('clients_groups')}`}
      </Title>
      <form onSubmit={handleSubmit(onSubmitData)}>
        
          <Field>
            <Label>{t('name')}</Label>
            <Control iconLeft>
              <Input
                defaultValue={clientsGroup && clientsGroup[0].clientname}
                type='text'
                color='light'
                name='name'
                ref={register({
                  required: true,
                  maxLength: 200
                })}
              />
              <Icon size='small' align='left'>
                <FontAwesomeIcon icon={['fas', 'file-signature']} />
              </Icon>
            </Control>
            {errors.name &&
              errors.name.type === 'required' && (
                <HelpError message={t('required')} />
              )}
            {errors.name &&
              errors.name.type === 'maxLength' && (
                <HelpError message={t('tooManyCharacters')} />
              )}
          </Field>

          <Field>
            <Label>{t('description')}</Label>

            <Control>
              <Textarea
                defaultValue={clientsGroup && clientsGroup[0].clientdescription}
                color='light'
                name='description'
                ref={register({
                  maxLength: 1000
                })}
                placeholder={t('description')}
              />
            </Control>
            {errors.description && errors.description.type === 'maxLength' && (
              <HelpError message={t('tooManyCharacters')} />
            )}
            {errors.description &&
              errors.description.type === 'required' && (
                <HelpError message={t('required')} />
              )}
          </Field>
        
        <Column size={8}>
            <Column.Group>
              <Column narrow>
                <Label>{t('selectClients')}: </Label>
              </Column>
              <Column>
                <Field>
                  <Controller
                    as={
                      <ReactSelect isClearable isSearchable options={options} />
                    }
                    control={control}
                    onChange={([selected]) => {
                      // React Select return object instead of value for selection
                      handleClientIdentitySelected(selected)
                    }}
                    name='clientsTenant'
                  />
                </Field>
              </Column>
            </Column.Group>
        </Column>

        <Table hoverable bordered striped narrow fullwidth >
          <Table.Head>
            <Table.Row>
              <Table.Heading>{t('selectedClient')}</Table.Heading>
              <Table.Heading>{t('action')}</Table.Heading>
              <Table.Heading>{t('selectedClient')}</Table.Heading>
              <Table.Heading>{t('action')}</Table.Heading>
              <Table.Heading>{t('selectedClient')}</Table.Heading>
              <Table.Heading>{t('action')}</Table.Heading>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {selectedIdentities &&
              selectedIdentities.slice(startPosition, endPosition).map((client, index) => {
                return (
                  <Table.Row key={index}>
                    <Table.Cell className={client.length > 0 && client[0]  && boldIdentities.includes(client[0]) ? "new-identity" : ""} key={client.length > 0 && client[0] ? client[0] : 1}>{client[0]}</Table.Cell>
                    <Table.Cell key={client.length > 0 && client[0] ? `${client[0]}+'A'` : 10}>
                        {client.length > 0 && client[0] !== null && (
                          <button
                              type="button"
                              onClick={e => handleClientIdentityDeselected(e, client[0])}
                              className='button is-danger is-outlined is-small'
                            >
                            <Icon>
                              <FontAwesomeIcon size='lg' icon={['fas', 'trash']} />
                            </Icon>
                          </button>)
                        }
                    </Table.Cell>
                    <Table.Cell className={client.length > 1 && boldIdentities.includes(client[1]) ? "new-identity" : ""} key={client.length > 1 ? client[1] : 2}>{client[1]}</Table.Cell>
                    <Table.Cell key={client.length > 1 ? `${client[1]}+'B'` : 11}>
                        {client.length > 1 && (
                          <button
                            type="button"
                            onClick={e => handleClientIdentityDeselected(e, client[1])}
                            className='button is-danger is-outlined is-small'
                            >
                            <Icon>
                              <FontAwesomeIcon size='lg' icon={['fas', 'trash']} />
                            </Icon>
                          </button>)
                        }
                    </Table.Cell>
                    <Table.Cell className={client.length > 2 && boldIdentities.includes(client[2]) ? "new-identity" : ""} key={client.length > 2 ? client[2] : 3}>{client[2]}</Table.Cell>
                    <Table.Cell key={client.length > 2 ? `${client[2]}+'C'` : 12}>
                        {client.length > 2 && (
                          <button
                            type="button"
                            onClick={e => handleClientIdentityDeselected(e, client[2])}
                            className='button is-danger is-outlined is-small'
                            >
                            <Icon>
                              <FontAwesomeIcon size='lg' icon={['fas', 'trash']} />
                            </Icon>
                          </button>) 
                        }
                    </Table.Cell>
                  </Table.Row>
                )
              })}
          </Table.Body>
      </Table>
      <Pagination
        totalItems={selectedIdentities && selectedIdentities.length}
        //ref={paginationRef}
        requestPagination={handleRequestPagination}
      />

        <Field kind='group'>
          <Control>
            {id ? (
              <Button color='link'>{t('update')}</Button>
            ) : (
                <Button color='link'>{t('add')}</Button>
              )}
          </Control>
          <Control>
            <Button as={Link} to='/clients/groups' type='button' text>
              {t('cancel')}
            </Button>
          </Control>
          {confirmAction.showMessage && <FormError {...confirmAction} />}
        </Field>
      </form>
    </Box>
  )
}
