import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tab, Icon } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

export const TabsDistributor = ({ highlight }) => {
  const { t } = useTranslation()

  return (
    <Tab.Group align='centered' kind='boxed'>
      <Tab as={Link} to='/distributor' active={highlight === 'distributor'}>
        <Icon size='small'>
          <FontAwesomeIcon icon={['fas', 'warehouse']} />
        </Icon>
        <span>{t('distributors')}</span>
      </Tab>
      <Tab as={Link} to='/distributor/users' active={highlight === 'users'}>
        <Icon size='small'>
          <FontAwesomeIcon icon={['fas', 'users']} />
        </Icon>
        <span>{t('users')}</span>
      </Tab>
    </Tab.Group>
  )
}
