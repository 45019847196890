import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { logic } from '../../../logic'
import { SelectContainer, Select } from 'rbx/elements/form/select'

export const SelectDistributors = (props) => {
  const { t } = useTranslation()

  const [distributors, setDistributors] = useState()

  const [selectedDistributor, setSelectedDistributor] = useState(props.originalDistributorId)
  let counter = 0
  useEffect(() => {
    
    logic
      .getDistributorsSpecificTenantAdmin((counter === 0 && props && props.originalTenantId && props.originalTenantId.toString()) || props.tenantId)
      .then(async (distributors) => {
        const data = distributors && distributors.data
        setDistributors(data)
        counter = 1
      })
      .catch(err => {
        window.alert(err.message)
      })
  }, [])
  useEffect(() => {
    
    counter = 1
    logic
    .getDistributorsSpecificTenantAdmin((counter === 0 && props && props.originalTenantId && props.originalTenantId.toString()) || props.tenantId)
    .then((distributors) => {
      const data = distributors && distributors.data
      setDistributors(data)
    })
    .catch(err => {
      window.alert(err.message)
    }) 
    if(counter !== 0){
      logic
      .getDistributorsSpecificTenantAdmin(props && props.tenantId)
      .then(distributors => {
        const data = distributors && distributors.data
        setDistributors(data)
      })
      .catch(err => {
        window.alert(err.message)
      })
    }
    }, [props.tenantId])

  const handleDistributorSelect = event => {
    const { value } = event.target

    setSelectedDistributor(value)

    props.doSelectDistributor(value)
  }

  return (
    <SelectContainer>
      <Select
        name='distributors'
        onChange={handleDistributorSelect}
        value={selectedDistributor || props.originalDistributorId || ''}
      >
        <Select.Option value=''>{t('select')} {t('distributor')}</Select.Option>
        {distributors && distributors.map(distributor => {
          return (
            <Select.Option
              key={distributor && distributor.distributortenantid}
              value={distributor && distributor.distributortenantid}
            >{distributor && distributor.distributorcode} {distributor && distributor.distributorname}
            </Select.Option>
          )
        })}
      </Select>
    </SelectContainer>
  )
}
