const arraySplitter = {
  // splits an array in subarrays of size n
    arraySplitter (array, n) {
      let subArrays = []
      for (var i = 0, len = array.length; i < len; i += n) {
        subArrays.push(array.slice(i, i + n));
      }
      return subArrays 
     }
  }
  export { arraySplitter }

