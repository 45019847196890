import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Tab, Icon } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEuroSign } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { logic } from '../../../logic'
import { TableOfTables } from '../../../logic/tableOfTables'
import { AdsContext } from "../../../contexts/adsContext"

export const TabsProduct = ({ highlight }) => {
  const { t } = useTranslation()
  const { allTableOfTables } = useContext(AdsContext)

  const isPVLContracted = TableOfTables('PVL', logic.tenantId.toString(), allTableOfTables, true)


  return (
    <Tab.Group align='centered' kind='boxed'>
      <Tab as={Link} to='/product/tenant' active={highlight === 'products'}>
        <Icon size='small'>
          <FontAwesomeIcon icon={['fas', 'shopping-basket']} />
        </Icon>
        <span>{t('products')}</span>
      </Tab>
      <Tab as={Link} to='/product/brand' active={highlight === 'brands'}>
        <Icon size='small'>
          <FontAwesomeIcon icon={['fas', 'copyright']} />
        </Icon>
        <span>{t('brands')}</span>
      </Tab>
      <Tab as={Link} to='/product/businessUnit' active={highlight === 'businessUnits'}>
        <Icon size='small'>
          <FontAwesomeIcon icon={['fas', 'briefcase']} />
        </Icon>
        <span>{t('businessUnits')}</span>
      </Tab>
      <Tab as={Link} to='/product/group' active={highlight === 'groups'}>
        <Icon size='small'>
          <FontAwesomeIcon icon={['fas', 'layer-group']} />
        </Icon>
        <span>{t('groups')}</span>
      </Tab>
      <Tab as={Link} to='/product/set' active={highlight === 'sets'}>
        <Icon size='small'>
          <FontAwesomeIcon icon={['fas', 'object-group']} />
        </Icon>
        <span>{t('Conjuntos')}</span>
      </Tab>
      {isPVLContracted ? 
        <Tab as={Link} to='/product/value' active={highlight === 'values'}>
          <Icon size='small'>
            <FontAwesomeIcon icon={faEuroSign} />
          </Icon>
          <span>{t('values')}</span>
        </Tab>
        : 
        null
      }
    </Tab.Group>
  )
}
