/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, useRef } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import { useAlert } from 'react-alert'
import { logic } from '../../../logic'
import { Select, Label, Card, Content, Level, Icon, Box } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom'
import { useToggle } from '../../../logic/hooks'
import { Search } from '../../filter/Search'
import { TabsClients } from '../TabsClients'
import { NOTIFICATIONS } from '../../../notifications'
import './NewClients.scss'
import { Table, Tag, Space, Switch } from 'antd'
import { Bar } from 'react-chartjs-2'
import { Column } from 'rbx'
import * as dayjs from 'dayjs'
import { DashboardCard } from '../../dashboard/card'

const { Column: AntColumn, ColumnGroup } = Table



export const NewClientsPerDistributor = () => {
  //let dataSource = []
  const alert = useAlert()

  // i18n hook
  const { t } = useTranslation()
  // main state
  const [tableLoading, setTableLoading] = useState(false)

  // main state
  const [selectedYear, setSelectedYear] = useState(new Date().getMonth() === 0 ? new Date().getFullYear() - 1 : new Date().getFullYear())

  // main state
  const [startYear, setStartYear] = useState(new Date(2020))

  // main state
  const [endYear, setEndYear] = useState((new Date()))

  // main state
  const [newClientsMonth, setNewClientsMonth] = useState(new Array(12).fill('0'))

  // main state
  const [newClientsEvolution, setNewClientsEvolution] = useState(new Array(12).fill({key: Math.random()}))
  // main state
  const [totalClients, setTotalClients] = useState(0)

  // main state
  const [annualClients, setAnnualClients] = useState(0)
  // main state
  const [averageClients, setAverageClients] = useState(new Array(12).fill('0'))

  // dataSource
  const [dataSource, setDataSource] = useState([])
  
  // distributors that are searched for
  const [distributorStatus, setDistributorStatus] = useState([])

  // Routing Variables
  const { url } = useRouteMatch()
  const history = useHistory()
  const location = useLocation()

  const columns = [
    {
      title: t('Distribuidor'),
      dataIndex: "distributorname",
      key: "distributorname"
    },
    {
      title: t('Enero'),
      dataIndex: 'January',
      key: 'January'
    },{
      title: t('Febrero'),
      dataIndex: 'February',
      key: 'February'
    },{
      title: t('Marzo'),
      dataIndex: 'March',
      key: 'March'
    },{
      title: t('Abril'),
      dataIndex: 'April',
      key: 'April'
    }, {
      title: t('Mayo'),
      dataIndex: 'May',
      key: 'May'
    }, {
      title: t('Junio'),
      dataIndex: 'June',
      key: 'June'
    }, {
      title: t('Julio'),
      dataIndex: 'July',
      key: 'July'
    }, {
      title: t('Agosto'),
      dataIndex: 'August',
      key: 'August'
    },{
      title: t('Septiembre'),
      dataIndex: 'September',
      key: 'September'
    },{
      title: t('Octubre'),
      dataIndex: 'October',
      key: 'October'
    },{
      title: t('Noviembre'),
      dataIndex: 'November',
      key: 'November'
    },{
      title: t('Diciembre'),
      dataIndex: 'December',
      key: 'December'
    }
  ]
  const data = {
    labels: [t('Enero'), t('Febrero'), t('Marzo'), t('Abril'), t('Mayo'), t('Junio'), t('Julio'), t('Agosto'), t('Setpiembre'), t('Octubre'), t('Noviembre'), t('Diciembre')],
    datasets: [
      {
        type: 'bar',
        label: '# Nuevos clientes',
        data: newClientsMonth,
        backgroundColor: 'rgb(255, 99, 132)',
      },
      {
        type: 'bar',
        label: '# Clientes',
        data: newClientsEvolution,
        backgroundColor: 'rgb(54, 162, 235)',
      },
      { 
        type:'line',
        label: 'Media Año',
        data: averageClients,
        fill: false
      }
    ],
  }
  
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  }
  
  const currentYear = new Date().getFullYear()
  const fromYear = 2020
  const toYear = new Date(currentYear, 11).getFullYear()
  const years = []
  for (let i = toYear; i >= fromYear; i -= 1) {
    years.push(i)
  }

  // Fetch products when component mounts
  useEffect(() => {
    logic
    .getNewClientsPerDistributor(selectedYear)
    .then(distributorStatus => {
      const dataSource = []
      const parametrized = distributorStatus && distributorStatus.data && distributorStatus.data.map((value, index) => {
        for(const property in value){
          if(!value[property]){
            value[property] = 0
          }
        }
        return {
          key: index,
          ...value
        }
      })
     
      setDataSource(parametrized)
    })

  }, [selectedYear])

  // components for the editable rows

  const handleSelectYear = event => {
    const year = event.target.value
    setSelectedYear(year)
    
  }

  return (
    <>
      <Box id="newClientsPerDistributor">

        <TabsClients highlight='newPerDistributor' />
        {logic.notCrossSales && <Switch checkedChildren={t('Sin vtas cruzadas')} disabled checked />} 
        {!logic.notCrossSales && <Switch unCheckedChildren={t('Con vtas cruzadas')} disabled />} 
        <br/>
        <Select.Container>
              <Select value={selectedYear} onChange={handleSelectYear}>
                {years.map((year, index) => {
                  return (
                    <Select.Option key={year} value={year}>
                      {year}
                    </Select.Option>
                  )
                })}
              </Select>
          </Select.Container>
          
          <Column.Group>
          <Column>
          <Table pagination={false} dataSource={dataSource} columns={columns}></Table>
          </Column>
        </Column.Group>
      </Box>
    </>
  )
}
