import React from 'react'
import {Form, Input, Badge, Icon, Tooltip} from 'antd'

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

export const EditableFormRow = Form.create()(EditableRow);

export class EditableCell extends React.Component {
  state = {
    editing: false,
  }

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = form => {
    this.form = form;
    const { children, dataIndex, record, title, language } = this.props
    let errorTitle = title
    const { hasDateError, hasCifError, hasPostalCodeError, hasUnitsError, hasDistributorProductNameError, hasProductCodeError, correctProductName, tenantName, hasNoStockCodeError, hasCategoryNameError, isCategoryNameInactive } = this.props && this.props.record
    let hasError = false
    let promptError
    if(!record[dataIndex] && record[dataIndex] !== 0 && dataIndex !== 'lotCode' && dataIndex !== 'clientCategory' && dataIndex !== 'clientName'){
      hasError = true
      promptError = language === 'es' ? 'celda vacía' : 'empty cell'
    }
    if(dataIndex === 'date'){
      if(hasDateError){
        hasError = true
        promptError = language === 'es' ? 'El mes y el año debe coincidir con la fecha seleccionada y ser válida (DD/MM/AAAA)' : 'Month and year must fit in the selected date and be valid (DD/MM/YYYY)'
      }
    }
    if(dataIndex === 'cifClient'){
      errorTitle = language === 'es' ? 'CIF del cliente (por defecto A00000000)' : 'Client CIF (by default A00000000)'
      if(hasCifError){
        hasError = true
      }
      promptError = language === 'es' ? 'Debe ser un CIF/NIF válido' : 'Must be a valid CIF/NIF'
    }
    if(dataIndex === 'postalCode'){
      if(hasPostalCodeError){
        hasError = true
        promptError = language === 'es' ? 'Código postal inválido' : 'Not valid postal code'
      }
    }
    if(dataIndex === 'units'){
      if(hasUnitsError){
        hasError = true
        promptError = language === 'es' ? 'Debe ser un número entero' : 'Must be an integer number'
      }
    }
    if(dataIndex === 'distributorProductName'){
      if(hasDistributorProductNameError){
        hasError = true
        promptError = language === 'es' ? 'Hay 2 o mas códigos de producto iguales con diferente nombre' : 'There are 2+ equal product codes with different name'
      }
    }

    if(dataIndex === 'productDistributorCode'){
      if(hasProductCodeError){
        hasError = true
        correctProductName !== undefined ? promptError = language === 'es' ? `El nombre de producto completo para este código es: ${correctProductName}` : `The complete product name for this code is: ${correctProductName}`
                                         : promptError = language === 'es' ? `El código de este producto es del fabricante: ${tenantName}. No lo puede utilizar fuera de dicho fabricante` : `The code of this product is for tenant: ${tenantName}. You can't use it for other tenant`
      }
    }
    if(dataIndex === 'productDistributorCode'){
      if(hasNoStockCodeError){
        hasError = true
        promptError = promptError = language === 'es' ? `Éste código de producto no existe en Stock` : `This product code does not exists in Stock`
      }
    }
    if(dataIndex === 'clientCategory'){
      errorTitle = promptError = language === 'es' ? 'Categoría del cliente errónea' : 'Wrong client category'
      if(hasCategoryNameError){
        hasError = true
      }
      if (language === 'es') {
        promptError = !isCategoryNameInactive ? 'Debe ser una categoría de cliente válida' : 'La categoría está inactiva. Consulte a su fabricante'
      } else {
        promptError = !isCategoryNameInactive ? 'Must be a valid client category' : 'The category is inactive. Contact your tenant'
      }
    }

    if(record[dataIndex] && !hasDateError && !hasCifError && !hasPostalCodeError && !hasUnitsError && !hasDistributorProductNameError && !hasProductCodeError && !hasNoStockCodeError && !hasCategoryNameError){
      hasError = false
    }

    const { editing } = this.state;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: dataIndex !== 'clientName' && dataIndex !== 'clientCategory' ? true : false,
              message: language === 'es' ? `${errorTitle} es requerido.` : `${errorTitle} is required.`,
            },
          ],
          initialValue: record[dataIndex],
        })(<Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
      </Form.Item>
    ) : (
      <div
        className='editable-cell-value-wrap'
        style={{ paddingRight: 24, minHeight: 32 }}
        onClick={this.toggleEdit}
      >
        {hasError ? (
          <Tooltip title={promptError}>
            <Badge count={<Icon type="exclamation-circle" theme="filled" style={{ color: '#f5222d', margin: '-5px' }} />}>{children}</Badge>
          </Tooltip>
        ): 
        children      
        }
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}