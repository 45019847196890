/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useContext } from 'react'
import { AvisoLegal } from '../../../config/legal/AvisoLegal'
import { PoliticaCookies } from '../../../config/legal/PoliticaCookies'
import { PoliticaPrivacidad } from '../../../config/legal/PoliticaPrivacidad'
import { TerminosCondiciones } from '../../../config/legal/TerminosCondiciones'
import { Header } from './header/Header'
import { Sidebar } from './sidebar/Sidebar'
import { Column, Footer, Content } from 'rbx'
import './appContainer.scss'
import { BreadCrumb } from '../../breadcrumb/BreadCrumb'
import { useModal } from '../../../logic/hooks/'
import { Link, useLocation } from 'react-router-dom'
import { Modal } from 'antd'
import { AdsContext } from "../../../contexts/adsContext"

export const AppContainer = props => {
  const { allTableOfTables } = useContext(AdsContext)
  const version = 'Build ' + allTableOfTables.filter(table => table.tableoftablesname === 'ADSVERSION' && 
                                                     table.tableoftablesdata.split('###')[0] === 'DATA')
                                             .map(catcliTable => catcliTable.tableoftablesdata.split('###')[1])
  
  const avisoLegalModal = useModal()
  const politicaCookiesModal = useModal()
  const politcaPrivacidadModal = useModal()
  const terminosCondicionesModal = useModal()


  const location = useLocation()
  const path = location && location.pathname

  return (
    <>
         <div className='appContainer'>
          <Header />
          <Column.Group className='appContainer__content'>
            <Column narrow>
              <Sidebar />
            </Column>
            <Column style={{marginRight: 20}}>
            <BreadCrumb />
              {props.children}
            </Column>
          </Column.Group>
          <Footer>
            <Content textAlign='centered'>
              <small>
                <strong>(c) Animal Data Source&#8482;</strong> IS AN AMBIT BST PRODUCT – ALL RIGHTS RESERVED
              </small> <br />
              <Link onClick={() => avisoLegalModal.showModal()} to={path}>Aviso Legal</Link>
              &nbsp;·&nbsp;<Link onClick={() => politcaPrivacidadModal.showModal()} to={path}>Política de privacidad</Link>
              &nbsp;·&nbsp;<Link onClick={() => politicaCookiesModal.showModal()} to={path}>Política de cookies</Link>
              &nbsp;·&nbsp;<Link onClick={() => terminosCondicionesModal.showModal()} to={path}>Términos y condiciones</Link>
            </Content>
            <Content textAlign='centered'>
              {version}
            </Content>
          </Footer>
          <Modal
            title='Aviso legal'
            visible={avisoLegalModal.isVisible}
            onCancel={() => avisoLegalModal.closeModal()}
            onOk={() => avisoLegalModal.closeModal()}
            footer={null}
          >
            <AvisoLegal />
          </Modal>
          <Modal
            title='Política de cookies'
            visible={politicaCookiesModal.isVisible}
            onCancel={() => politicaCookiesModal.closeModal()}
            onOk={() => politicaCookiesModal.closeModal()}
            footer={null}
          >
            <PoliticaCookies />
          </Modal>
          <Modal
            title='Política de Privacidad'
            visible={politcaPrivacidadModal.isVisible}
            onCancel={() => politcaPrivacidadModal.closeModal()}
            onOk={() => politcaPrivacidadModal.closeModal()}
            footer={null}
          >
            <PoliticaPrivacidad />
          </Modal>
          <Modal
            title='Términos y condiciones'
            visible={terminosCondicionesModal.isVisible}
            onCancel={() => terminosCondicionesModal.closeModal()}
            onOk={() => terminosCondicionesModal.closeModal()}
            footer={null}
          >
            <TerminosCondiciones />
          </Modal>
         </div>
    </>
  )
}
