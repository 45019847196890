/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const DIVISIONS = [10, 25, 50, 100]
export const Pagination = forwardRef(({ totalItems, requestPagination }, ref) => {
  // Pagination hook
  const { t } = useTranslation()
  // State that controls the number of page buttons to be displayed
  const [pages, setPages] = useState(1)
  // Current page being displayed
  const [currentPage, setCurrentPage] = useState(1)
  // State that handles the items per page
  const [numberOfDivisions, setNumberOfDivisions] = useState([10, 25, 50, 100])
  // State that handles the index of the current division that is being used. Also controls which button is active on numbre of divisions.
  const [currentDivision, setCurrentDivision] = useState(0)

  const itemsPerPage = numberOfDivisions[currentDivision]
  const lastPage = pages

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({

    getAlert () {
      window.alert('getAlert from Child')
    },

    resetValues () {
      setCurrentPage(1)
      requestPagination({
        itemsPerPage,
        currentPage: 1
      })
    }

  }))

  /*
    Configure useEffects

    */

  useEffect(() => {
    requestPagination({
      itemsPerPage: numberOfDivisions[currentDivision],
      currentPage
    })
  }, [currentPage])

  useEffect(() => {
    const calculatedPages = Math.ceil(totalItems / numberOfDivisions[currentDivision])
    calculatedPages ? setPages(calculatedPages) : setPages(0)
  }, [totalItems, numberOfDivisions, currentDivision])

  useEffect(() => {
    if (totalItems > DIVISIONS[3]) {
      setNumberOfDivisions([DIVISIONS[0],DIVISIONS[1], DIVISIONS[2], DIVISIONS[3]])
    }
    if (totalItems < DIVISIONS[3]) {
      setNumberOfDivisions([DIVISIONS[0],DIVISIONS[1], DIVISIONS[2], DIVISIONS[3]])
    }
    if (totalItems < DIVISIONS[2]) {
      setNumberOfDivisions([DIVISIONS[0], DIVISIONS[1], DIVISIONS[2]])
    }
    if (totalItems < DIVISIONS[1]) {
      setNumberOfDivisions([DIVISIONS[0], DIVISIONS[1]])
    }
    if (totalItems < DIVISIONS[0]) {
      setNumberOfDivisions([DIVISIONS[0]])
    }
  }, [totalItems])

  /*
    Sets perPage
    */

  // Sets the itemsPerPage to the selected amount and then does a pagination request to the parent component
  // When new itemsPerPage are given, we start again on page 1.
  const handleSelectCurrentDivision = (event, currentDivisionIndex) => {
    event.preventDefault()
    setCurrentDivision(
      currentDivisionIndex
    )
    setCurrentPage(1)
    requestPagination({
      itemsPerPage: numberOfDivisions[currentDivisionIndex],
      currentPage: 1
    })
  }

  // When clicked, it will set the current page to the clicked one
  const handlePageClick = (event, page) => {
    event.preventDefault()
    setCurrentPage(page)
    requestPagination({
      currentPage: page,
      itemsPerPage
    })
  }

  const handleNextPage = event => {
    event.preventDefault()

    if (currentPage === lastPage) {
      return
    }

    setCurrentPage(currentPage + 1)
  }

  const handlePreviousPage = event => {
    event.preventDefault()

    if (currentPage === 1) {
      return
    }

    setCurrentPage(currentPage - 1)
  }

  // Paginate algorithm, defines the way they are distributed
  const paginate = (currentPage, lastPage, clickEvent) => {
    // delta defines the range to add  { ... }
    const delta = 1
    // defines the numbers to be printed
    const range = []

    for (let i = Math.max(2, (currentPage - delta)); i <= Math.min((lastPage - 1), (currentPage + delta)); i += 1) {
      range.push(i)
    }

    if ((currentPage - delta) > 2) {
      range.unshift('...')
    }

    if ((currentPage + delta) < (lastPage - 1)) {
      range.push('...')
    }

    range.unshift(1)

    if (lastPage !== 0 && lastPage !== 1) range.push(lastPage)

    return range.map((i, index) => {
      if (i === '...') {
        return (
          <span key={index} className='pagination-ellipsis'>&hellip;</span>
        )
      }

      return (
        !isNaN(i)
          ? <span
            value={i}
            key={index}
            onClick={event => clickEvent(event, i)}
            className={currentPage === i ? 'pagination-link is-current' : 'pagination-link'}
            >{i}
            </span>
          : <span key={index}>{i}</span>
      )
    })
  }

  return (
    <div className='level'>
      <div className='level-left'>
        <nav className='level-item pagination is-centered' role='navigation' aria-label='pagination'>
          <button onClick={handlePreviousPage} className='button pagination-previous has-background-link has-text-white'>{t('Previous')}</button>
          <button onClick={handleNextPage} className='button pagination-next has-background-link has-text-white'>{t('Next page')}</button>
          <ul className='pagination-list'>
            <li>
              {paginate(currentPage, pages, handlePageClick)}
            </li>
          </ul>
        </nav>
      </div>

      <div className='level-right'>
        <div className='level-item'>
          <div className='buttons has-addons'>
            {numberOfDivisions.map((element, index) => {
              return (
                <button onClick={event => handleSelectCurrentDivision(event, index)} className={`button ${index === currentDivision ? 'is-primary is-selected' : ''}`} key={index}>{element}</button>
              )
            })}
          </div>
        </div>
      </div>
    </div>

  )
})
