import React, { useState, useEffect } from 'react'
import { logic } from '../../../../logic'
import { SelectContainer, Select } from 'rbx/elements/form/select'
import { useLocation } from 'react-router-dom'

export const SelectTenant = (props) => {
  const [distributors, setDistributors] = useState()

  const [defaultDistributor, setDefaultDistributor] = useState()

  const [selectedDistributor, setSelectedDistributor] = useState()

  const location = useLocation()
  const [calledBackForTenantId, setCalledBackFromTenantId] = useState(location.state && location.state.tenantId || '0')

  useEffect(() => {
    logic
      .getTenantsFromDistributor()
      .then(tenants => {
        const data = tenants && tenants.data
        if (data && data.length === 1) {
          setDistributors(data)
          setSelectedDistributor(data && data[0] && data[0].tenantid.toString())
          props.doSelectTenant(data && data[0] && data[0].tenantid && data[0].tenantid.toString())  
        } else {
          if (calledBackForTenantId === '0') {
            setDistributors([{ distributorid: 0, tenantid: 0, tenantname: 'Seleccione fabricante' }].concat(data))
            setSelectedDistributor('0')  
            props.doSelectTenant('0')
          } else {
            const filteredData = data && data[0] && data.filter(tenant => tenant.tenantid.toString() === calledBackForTenantId) || []
            setDistributors([{ distributorid: 0, tenantid: 0, tenantname: 'Seleccione fabricante' }].concat(data))
            setSelectedDistributor(filteredData[0].tenantid.toString())
            props.doSelectTenant(filteredData[0].tenantid.toString())
          }
        }
      })
      .catch(err => {
        window.alert(err.message)
      })
  }, [])

  const handleDistributorSelect = event => {
    const { value } = event.target

    setSelectedDistributor(value)

    props.doSelectTenant(value)
  }

  return (
    <SelectContainer>
      <Select
        name='tenant'
        onChange={handleDistributorSelect}
        value={selectedDistributor || ''}
      >
        {selectedDistributor && distributors && distributors.map(distributor => {
          console.log("selectedDistributor: ", selectedDistributor)
          console.log("distributor: ", distributor)
          if (selectedDistributor !== distributor && distributor.tenantid.toString()) {
            return (
              <Select.Option
                key={distributor && distributor.tenantid}
                value={distributor && distributor.tenantid}
              > {distributor && distributor.tenantname}
              </Select.Option>
            )
          } else {
            return (
              <Select.Option
                key={distributor && distributor.tenantid}
                value={distributor && distributor.tenantid}
                selected
              > {distributor && distributor.tenantname}
              </Select.Option>
            )
          }
        })}
      </Select>
    </SelectContainer>
  )
}
