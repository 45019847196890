/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState, useContext } from 'react'
import { useAlert } from 'react-alert'
import {
  Table,
  Textarea,
  Box,
  Title,
  Field,
  Input,
  Control,
  Label,
  Button,
  Icon
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FormError, HelpError } from '../../error'
import { logic } from '../../../logic'
import { useConfirmActionMessage } from '../../../logic/hooks'
import { AdsContext } from "../../../contexts/adsContext"

export const BUTTON_STATES = { hovered: 'hovered', focused: 'focused', active: 'active', loading: 'loading' }
export const AddEditTablesAdmin = () => {
  // productTenant
  const alert = useAlert()
  const [options, setOptions] = useState()
  const [tableOfTablesAdmin, setTableOfTablesAdmin] = useState()
  const [tableOfTablesHead, setTableOfTablesHead] = useState([])
  const [completeTableOfTables, setCompleteTableOfTables] = useState()
  // Error handling
  const confirmAction = useConfirmActionMessage()
  // Button request loading
  const [buttonState, setButtonState] = useState(BUTTON_STATES.active)

  const { t } = useTranslation()
  const history = useHistory()
  const { tableOfTablesId } = useParams()
  const { register, handleSubmit, errors, watch, getValues } = useForm()
  const [originalRow, setOriginalRow] = useState()
  const { allTableOfTables } = useContext(AdsContext)

  // componentDidMount
  useEffect(() => {
    (async function () {
      if (tableOfTablesId) {
        const tableOfTables = allTableOfTables.filter(table => { return parseInt(table.tableoftablesid) === parseInt(tableOfTablesId) })
        const tableOfTablesHead = allTableOfTables.filter(table => { return table.tableoftablesname === tableOfTables[0].tableoftablesname &&
                                                                            table.tableoftablesdata.slice(0,6) === 'HEADER' })
        const allTables = allTableOfTables.filter(table => { return table.tableoftablesname === tableOfTables[0].tableoftablesname })
        setTableOfTablesAdmin(tableOfTables && tableOfTables[0])
        setTableOfTablesHead(tableOfTablesHead && tableOfTablesHead[0])
        setCompleteTableOfTables(allTables)
        setOriginalRow(tableOfTables && tableOfTables[0] && tableOfTables[0].tableoftablesname + tableOfTables[0].tableoftablesdata)
      } 
    })()
  }, [allTableOfTables])

  const handleGoBackClick = () => {
    history.push('/settings/tables')
  }

  const onSubmitData = data => {
    setButtonState(BUTTON_STATES.loading)

    const { tableOfTablesName, tableOfTablesData, tableOfTablesDescription } = data
    const existsRow = allTableOfTables.filter(table => { if (table.tableoftablesname === tableOfTablesName && 
                                                              table.tableoftablesdata === tableOfTablesData) 
                                                              return table })
    const existsName = allTableOfTables.filter(table => { if (table.tableoftablesname === tableOfTablesName) 
                                                              return table })
    const existsHeader = allTableOfTables.filter(table => { if (table.tableoftablesname === tableOfTablesName && 
                                                                table.tableoftablesdata.slice(0,6) === 'HEADER') 
                                                                return table })
    if (existsRow && existsRow.length > 0 && tableOfTablesName + tableOfTablesData !== originalRow) {
      alert.show(t(`The table of tables' row exists already`), {
        type: 'error'
      })
      return
    }
    if (tableOfTablesData.slice(0,6) === 'HEADER' && existsName && existsName.length > 0) {
      alert.show(t('The HEADER for this table of tables exists already'), {
        type: 'error'
      })
      return
    }
    if (tableOfTablesData.slice(0,4) === 'DATA' && existsHeader && existsHeader.length > 0 && existsHeader[0].active !== true) {
      alert.show(t('The HEADER for this table of tables is inactive'), {
        type: 'error'
      })
      return
    }

    confirmAction.closeMessage()
    if (tableOfTablesId) {
      logic
        .updateTableOfTablesAdmin({
          tableOfTablesId,
          tableOfTablesData,
          tableOfTablesDescription
        })
        .then(table => {
          if (table.status !== 200) {
            alert.show(table.error, {
              type: 'error'
            })
          } else {
            alert.show(t('table_update'), {
              type: 'success'
            })
            history.push({
              pathname: '/settings/tables',
              state: { fromChild: true } // Passing state indicating it was called from child
            })
          } 
        })
        .catch(err => {
          if (err.message === '409') {
            alert.show(t('Input already exists'), {
              type: 'error'
            })
          } else {
            alert.show(t('Unexpected Error'), {
              type: 'error'
            })
          }
        })
    } else {
      logic
        .addTableOfTablesAdmin({
          tableOfTablesName,
          tableOfTablesData,
          tableOfTablesDescription
        })
        .then(table => {
          if (table.status !== 201) {
            alert.show(table.error, {
              type: 'error'
            })
          } else {
            alert.show(t('dashboard_added'), {
              type: 'success'
            })
            history.push({
              pathname: '/settings/tables',
              state: { fromChild: true } // Passing state indicating it was called from child
            })  
          }
        })
        .catch(err => {
          console.log("err: ", err)
          if (err.message === '409') {
            alert.show(t('Input already exists'), {
              type: 'error'
            })
          } else {
            alert.show(t('Unexpected error'), {
              type: 'error'
            })
          }
        })
    }
    setButtonState(BUTTON_STATES.active)
  }

  function validateRow(value) {
    const formValues = getValues()

    if (!tableOfTablesId && value.split('###')[0] === 'HEADER') {
      return true
    }
    if (!tableOfTablesId && value.split('###')[0] === 'DATA') {
      const headerOfThisData = allTableOfTables.filter(table => { return table.tableoftablesname === formValues.tableOfTablesName &&
                                                                         table.tableoftablesdata.slice(0,6) === 'HEADER' })
      if (!headerOfThisData.length) {
        return false
      } else {
        if (value.split('###').length !== headerOfThisData[0].tableoftablesdata.split('###').length) {
          return false
        } else {
          return true
        }    
      }
    }
    if (value.split('###').length !== tableOfTablesHead.tableoftablesdata.split('###').length) {
      return false
    } else {
      return true
    }
  }

  return (
    <Box>
      <Button onClick={e => handleGoBackClick()} color='light' size='medium'>
        <Icon>
          <FontAwesomeIcon size='lg' icon={['fas', 'chevron-circle-left']} />
        </Icon>
      </Button>
      <Title>
        {tableOfTablesId
          ? `${t('edit')} ${tableOfTablesAdmin && tableOfTablesAdmin.tableoftablesname}`
          : `${t('add')} ${t('Table')}`}
      </Title>
      <form onSubmit={handleSubmit(onSubmitData)}>

        <Field>
          <Label>{t('table_name')}</Label>
          <Control iconLeft>
            {tableOfTablesId ?
              (<Input
                defaultValue={tableOfTablesAdmin && tableOfTablesAdmin.tableoftablesname}
                type='text'
                color='light'
                name='tableOfTablesName'
                ref={register({
                  maxLength: 300,
                  required: true
                })}
                disabled
              />)
            :
              (<Input
                defaultValue={tableOfTablesAdmin && tableOfTablesAdmin.tableoftablesname}
                type='text'
                color='light'
                name='tableOfTablesName'
                ref={register({
                  maxLength: 300,
                  required: true
                })}
              />)
            }
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {errors.tableOfTablesName &&
            errors.tableOfTablesName.type === 'required' && (
              <HelpError message={t('required')} />
            )}
          {errors.tableOfTablesName &&
            errors.tableOfTablesName.type === 'maxLength' && (
              <HelpError message={t('tooManyCharacters')} />
            )}
        </Field>

        {tableOfTablesAdmin && tableOfTablesAdmin.tableoftablesdata.slice(0,6) === 'HEADER' ?
          (<>
            <Field>
              <Label>{t('table_data')}</Label>
              <Control iconLeft>
                <Input
                  defaultValue={tableOfTablesAdmin && tableOfTablesAdmin.tableoftablesdata}
                  type='text'
                  color='light'
                  name='tableOfTablesData'
                  ref={register({
                    maxLength: 3000,
                    required: true,
                    /* pattern: tableOfTablesId ? /^(HEADER)###/ : /^(HEADER|DATA)###/,
                    validate: ((value) => { return false }) */
                  })}
                  disabled
                />
                <Icon size='small' align='left'>
                  <FontAwesomeIcon icon={['fas', 'file-signature']} />
                </Icon>
              </Control>
              {errors.tableOfTablesData &&
                errors.tableOfTablesData.type === 'required' && (
                  <HelpError message={t('required')} />
                )}
              {errors.tableOfTablesData &&
                errors.tableOfTablesData.type === 'maxLength' && (
                  <HelpError message={t('tooManyCharacters')} />
                )}
              {errors.tableOfTablesData &&
                errors.tableOfTablesData.type === 'pattern' && (
                <HelpError message={t('muststarteitherbydataorheader')} />
                )}
              {errors.tableOfTablesData &&
              errors.tableOfTablesData.type === 'validate' && (
                <HelpError message={t('notvaliddata')} />
              )}
            </Field>

            <Field>
              <Label>{t('table_description')}</Label>
              <Control iconLeft>
                <Textarea
                  defaultValue={tableOfTablesAdmin && tableOfTablesAdmin.tableoftablesdescription}
                  type='text'
                  color='light'
                  name='tableOfTablesDescription'
                  ref={register({
                    maxLength: 1000
                  })}
                  disabled
                />
              </Control>
              {errors.tableOfTablesDescription &&
                errors.tableOfTablesDescription.type === 'maxLength' && (
                  <HelpError message={t('tooManyCharacters')} />
                )}
            </Field>
          </>)
        :
          (<>
            <Field>
              <Label>{t('table_data')}</Label>
              <Control iconLeft>
                <Input
                  defaultValue={tableOfTablesAdmin && tableOfTablesAdmin.tableoftablesdata}
                  type='text'
                  color='light'
                  name='tableOfTablesData'
                  ref={register({
                    maxLength: 3000,
                    required: true,
                    //pattern: tableOfTablesId ? /^(DATA)###/ : /^(HEADER|DATA)###/,
                    pattern: tableOfTablesId ? /^(DATA)###(?! *$).+/ : /^(HEADER|DATA)###(?! *$).+/,
                    validate: (value) => { return validateRow(value) }
                  })}
                />
                <Icon size='small' align='left'>
                  <FontAwesomeIcon icon={['fas', 'file-signature']} />
                </Icon>
              </Control>
              {errors.tableOfTablesData &&
                errors.tableOfTablesData.type === 'required' && (
                  <HelpError message={t('required')} />
                )}
              {errors.tableOfTablesData &&
                errors.tableOfTablesData.type === 'maxLength' && (
                  <HelpError message={t('tooManyCharacters')} />
                )}
              {errors.tableOfTablesData &&
                errors.tableOfTablesData.type === 'pattern' && (
                  <HelpError message={t('muststarteitherbydataorheader')} />
                )}
              {errors.tableOfTablesData &&
                errors.tableOfTablesData.type === 'validate' && (
                  <HelpError message={t('notvaliddata')} />
                )}
            </Field>

            <Field>
              <Label>{t('table_description')}</Label>
              <Control iconLeft>
                <Textarea
                  defaultValue={tableOfTablesAdmin && tableOfTablesAdmin.tableoftablesdescription}
                  type='text'
                  color='light'
                  name='tableOfTablesDescription'
                  ref={register({
                    maxLength: 1000
                  })}
                />
              </Control>
              {errors.tableOfTablesDescription &&
                errors.tableOfTablesDescription.type === 'maxLength' && (
                  <HelpError message={t('tooManyCharacters')} />
                )}
            </Field>
          </>)
        }
        <Table hoverable>
          <Table.Head>
            <Table.Row>
              <Table.Heading>{t('Table')}</Table.Heading>
              <Table.Heading>{t('Data')}</Table.Heading>
              <Table.Heading>{t('status')}</Table.Heading>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {completeTableOfTables && completeTableOfTables.map(table => (
              <Table.Row key={table.tableoftablesid}>
                <Table.Cell>{table.tableoftablesname}</Table.Cell>
                <Table.Cell>{table.tableoftablesdata}</Table.Cell>
                <Table.Cell>{table.active ? 'active' : 'inactive'}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {tableOfTablesAdmin && tableOfTablesAdmin.tableoftablesdata.slice(0,6) === 'HEADER' ?
          null 
          :
            <Field kind='group'>
              <Control>
                {tableOfTablesId ? (<Button color='link'>{t('update')}</Button>) 
                                : (<Button color='link'>{t('add')}</Button>)}
              </Control>
              <Control>
                <Button as={Link} to='/settings/tables' type='button' text>
                  {t('cancel')}
                </Button>
              </Control>
              {confirmAction.showMessage && <FormError {...confirmAction} />}
            </Field>
        }
      </form>
    </Box>
  )
}
