/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState, useContext } from 'react'
import { Box, Title, Field, Label, Icon, Column,Table } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11'
import { Controller } from 'react-hook-form'
import { logic } from '../../../../logic'
import { TabsObjectives} from '../../tabsObjectives'
import ReactSelect from 'react-select'
import { Pagination } from '../../../pagination'
import { AdsContext } from "../../../../contexts/adsContext";

export const AddEditObjDistributors = () => {

  const { t } = useTranslation()
  const { id } = useParams()
  const { control } = useForm()
  const [options, setOptions] = useState([])
  // State refering to the pagination attributes comming from the pagination component
  const defaultPaginationStartPosition = 0
  const defaultPaginationEndPosition = 10
  const [startPosition, setStartPosition] = useState(
    defaultPaginationStartPosition
  )
  const [endPosition, setEndPosition] = useState(defaultPaginationEndPosition)
  const [selectedDistributors, setSelectedDistributors] = useState([]) // Array with distributors selected on screen
  const { fetchAllTenantDistributors,
              sharedObjective, setSharedObjective, sharedObjDisabled,
              boldDistributors, setBoldDistributors } = useContext(AdsContext)

  useEffect(() => {
    (async function () {

      // 1st) We load all active distributors for a given tenant but without the tenant as distributor itself.
      let allDistributors = []
      allDistributors = await fetchAllTenantDistributors()
      const allTenantDistributors = allDistributors.filter(distributor => {
       return distributor.distributortenantid !== parseInt(logic.distributorId) // without the tenant as distributor itself
      })
      // 2nd) We prepare all the distributors selected on the screen
      let onScreenObjDistributors = sharedObjective[0].distributor.map(objDistributor => {
       const distributorFound = allTenantDistributors.find(distributor =>{
         if (distributor.distributortenantid === objDistributor) return distributor
       })
       return distributorFound && {
        label: `${distributorFound.distributorname}`,
        value: distributorFound.distributortenantid
        } || 
        { 
          label: '', 
          value: null
        }
      })
      if (boldDistributors.length === 0) {
        let onScreenObjDistributorsSorted = onScreenObjDistributors.sort((a, b) => {
          if (a.label.toLowerCase() < b.label.toLowerCase()) {
            return -1
          }
          if (a.label.toLowerCase() > b.label.toLowerCase()) {
           return 1
          }
          return 0
        })
        setSelectedDistributors([...onScreenObjDistributorsSorted])
      } else {
        setSelectedDistributors([...onScreenObjDistributors])
      }
     
      // 3rd) We prepare all the distributors not selected on the screen       
      const notSelectedTenantDistributors = allTenantDistributors && allTenantDistributors.filter( distributor => {
        let isIncluded = onScreenObjDistributors && onScreenObjDistributors.some(tmpDistributor => distributor.distributortenantid === tmpDistributor.value)
        if (!isIncluded) return distributor 
      })
      const sortedNotSelectedTenantDistributors =
       notSelectedTenantDistributors.sort((a, b) => {
       if (a.distributorname.toLowerCase() < b.distributorname.toLowerCase()) {
         return -1
       }
       if (a.distributorname.toLowerCase() > b.distributorname.toLowerCase()) {
        return 1
       }
       return 0
       })

      setOptions(
       sortedNotSelectedTenantDistributors.map(distributor => {
         return {
           label: `${distributor.distributorname}`,
           value: distributor.distributortenantid
         }
       })
      )
   })();
  }, [])

  const handleDistributorNameSelected = (distributorSelected) => {
    const newSelectedDistributors = [distributorSelected].concat(selectedDistributors)
    setSelectedDistributors([...newSelectedDistributors])
    setBoldDistributors([distributorSelected.value].concat(boldDistributors))
    setOptions(options
      .filter((distributor, index) => { 
        if(distributorSelected.value !== distributor.value) return distributor
      })
    )
    let newShrObj = [...sharedObjective]
    newShrObj[0].distributor = newSelectedDistributors.map(distributorSelected => {
      return distributorSelected.value
    })
    setSharedObjective([...newShrObj])
  }

  const handleDistributorNameDeselected = (e, distributorDeselected) => {
    let newSelectedDistributors = selectedDistributors.filter( distributor => { 
      if ( distributor.value !== distributorDeselected.value) return distributor
     })
    setSelectedDistributors([].concat(newSelectedDistributors))

    let newSortedOptions = [...options]
    newSortedOptions.push({
      label: distributorDeselected.label,
      value: distributorDeselected.value
    })
    newSortedOptions.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1
      }
      return 0
    })
    setOptions([...newSortedOptions])

    let newShrObj = [...sharedObjective]
    newShrObj[0].distributor = newSelectedDistributors.map(distributorSelected => {
      return distributorSelected.value
    })
    setSharedObjective([...newShrObj])
  }

    // handles the pagination requests
  const handleRequestPagination = requestPaginationObject => {
    let newStartPosition = defaultPaginationStartPosition
    let newEndPosition = defaultPaginationEndPosition
    if (requestPaginationObject) {
      const { currentPage, itemsPerPage } = requestPaginationObject
      newStartPosition = currentPage * itemsPerPage - itemsPerPage
      newEndPosition = newStartPosition + itemsPerPage
    }
    setStartPosition(newStartPosition)
    setEndPosition(newEndPosition)
  }

  return (
    <Box>
      <TabsObjectives highlight='distributors' isInactive='is-active' id={id && parseInt(id) > -1 ? id : -1} />
      <Title>
        {id
          ? `${t('edit')} ${sharedObjective && sharedObjective[0].objectivename}`
          : `${t('add')} ${t('objective_distributors')}`}
      </Title>
      
        <Column size={8}>
            <Column.Group>
              <Column narrow>
                <Label>{t('selectDistributors')}: </Label>
              </Column>
              <Column>
                <Field>
                  <Controller
                    as={
                      <ReactSelect isClearable isSearchable options={options} isDisabled={sharedObjDisabled} />
                    }
                    control={control}
                    onChange={([selected]) => {
                      // React Select return object instead of value for selection
                      handleDistributorNameSelected(selected)
                    }}
                    name='objectivesTenant'
                  />
                </Field>
              </Column>
            </Column.Group>
        </Column>

        <Table hoverable bordered striped narrow fullwidth >
          <Table.Head>
            <Table.Row>
              <Table.Heading>{t('selectedDistributor')}</Table.Heading>
              <Table.Heading>{t('action')}</Table.Heading>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {selectedDistributors &&
              selectedDistributors.slice(startPosition, endPosition).map((distributor, index) => {
                return (
                  <Table.Row key={index}>
                    <Table.Cell className={boldDistributors.includes(distributor.value) ? "new-identity" : ""} key={index+1*10000}>{distributor.label}</Table.Cell>
                    <Table.Cell key={index+1*20000}>
                        
                            <button
                                type="button"
                                onClick={e => handleDistributorNameDeselected(e, distributor)}
                                className='button is-danger is-outlined is-small'
                                disabled={sharedObjDisabled}
                              >
                              <Icon>
                                <FontAwesomeIcon size='lg' icon={['fas', 'trash']} />
                              </Icon>
                            </button>

                    </Table.Cell>
                  </Table.Row>
                )
              })}
          </Table.Body>
      </Table>
      <Pagination
        totalItems={selectedDistributors && selectedDistributors.length}
        requestPagination={handleRequestPagination}
      />
    </Box>
  )
}