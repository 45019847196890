/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Table, Button, Dropdown, Menu, Row, Col, Icon, Upload, Tabs, Select, Modal, Badge, Input, Tooltip } from 'antd'
import { ExcelRenderer, jsonToExcelDownload } from '../../logic/excelRenderer'
import { EditableFormRow, EditableCell } from '../excelPage/editable'
import { useTranslation } from 'react-i18next'
import { MonthPicker } from './monthPicker'
import * as dayjs from 'dayjs'
import { logic } from '../../logic'
import { faDiagnoses } from '@fortawesome/free-solid-svg-icons'
import { useConfirmActionMessage } from '../../logic/hooks'
import { FormError } from '../error'
import { validateSpanishId } from 'spain-id'
import postalCodeRegex from '../../config/postalCodes/postalCodeData'
import { useAlert } from 'react-alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Highlighter from 'react-highlight-words'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { DistributorSelector } from './DistributorSelector'
import { MAXMONTHTOBLOCK } from '../../config/config'
import { AdsContext } from "../../contexts/adsContext"
import { TableOfTables } from '../../logic/tableOfTables'

dayjs.extend(customParseFormat)

const { TabPane } = Tabs

const DATE_FORMAT = 'DD/MM/YYYY'
const DEFAULT_CIF = 'A00000000'
const COUNTRIES = ['AD', 'ES', 'GB', 'PT', 'FR', 'NL']
const BOOLEAN = { true: true, false: false }
const DEFAULT_STOCK_UNITS = 0
const DEFAULT_POSTAL_CODE = '00000'
const BI_ID_PRO_PRE = 1 // BI id on DDBB for PRO and PRE environments (it's the same code)
const BI_POSTAL_CODE_LIMIT_DATE = 202203 // All BI distributors spanish postal codes from Mar 2022 onwards must be above 00999
let clientsCategoriesNames
let clientsCategories
let isPVLContracted

function divideArrayByFactor (array, factor, fixedFactor) {
  const result =
    array &&
    array.map((item) => {
      // The two fixed method is to limit the amout of decimals that are given
      return (item / factor).toFixed(fixedFactor)
    })

  // Returns a set of unique values
  const uniqueResult = new Set(result)

  return [...uniqueResult]
}

export const ExcelPageAdmin = (props) => {
  // Error handling
  const alert = useAlert()
  const confirmAction = useConfirmActionMessage()
  const confirmAction2 = useConfirmActionMessage()
  const confirmAction3 = useConfirmActionMessage()
  const confirmAction4 = useConfirmActionMessage()
  const confirmAction5 = useConfirmActionMessage()
  const confirmAction6 = useConfirmActionMessage()
  const confirmAction7 = useConfirmActionMessage()
  const confirmAction8 = useConfirmActionMessage()
  // Routing Variables
  const { url } = useRouteMatch()
  const history = useHistory()

  const { t, i18n } = useTranslation()

  // Quick and dirty flag to hide contents based on whether a provider and a date has been selected
  const [isHidden, setIsHidden] = useState(true)
  const [hasSelectionChanged, sethasSelectionChanged] = useState(false)

  // flag to indicate whether there has been a satisfactory validation check
  const [hasValidDataToUpload, setHasValidDataToUpload] = useState(false)

  // flag to indicate that the chech button is validating (loading)
  const [isLoadingValidation, setIsLoadingValidation] = useState(false)
  const [fixDescriptions, setFixDescriptions] = useState(false)
  const [newProdNames, setNewProdNames] = useState([])

  // flag to indicate that the delete button is validating
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)

  // flag to indicate that the delete button is validating
  const [isLoadingDownload, setIsLoadingDownload] = useState(false)

  // flag to indicate that the delete button is validating
  const [isLoadingPage, setIsLoadingPage] = useState(false)

  // flag to indicate that the delete button is validating
  const [isLoadingUpload, setIsLoadingUpload] = useState(false)

  // flag to indicate whether there are already registries for the selected date and tenant
  const [hasRegistries, setHasRegistries] = useState(false)

  const [selectedDate, setSelectedDate] = useState()

  const [selectedTenant, setSelectedTenant] = useState()
  const [selectedTenantName, setSelectedTenantName] = useState()
  const [selectedTenantIdentity, setSelectedTenantIdentity] = useState()
  const [isDataBlocked, setIsDataBlocked] = useState(false)

  // state that controls the files that are being uploaded
  const [fileList, setFileList] = useState([])

  // state that controles whether the table is loading
  const [tableLoading, setTableLoading] = useState(false)

  // state that controls whether the tabs show there is an error or not
  const [hasSalesError, setHasSalesError] = useState(false)
  const [hasStockError, setHasStockError] = useState(false)
  let readSalesRows = 0
  let readStockRows = 0

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [searchInput, setSearchInput] = useState()

  const [selectedDistributor, setSelectedDistributor] = useState({})
  const [selectedDistributorIdentity, setSelectedDistributorIdentity] = useState()

  const [visiblePage, setVisiblePage] = useState(false)
  const [theProgress, setTheProgress] = useState()
  const [progressSeconds, setProgressSeconds] = useState()
  const [defaultTab, setDefaultTab] = useState('1')
  const [originalFirstPage, setOriginalFirstPage] = useState(0)
  const [originalSecondPage, setOriginalSecondPage] = useState(0)
  const [showSalesDescriptionsMessage, setShowSalesDescriptionsMessage] = useState(false)
  const [showStocksDescriptionsMessage, setShowStocksDescriptionsMessage] = useState(false)
  const [infoState, setInfoState] = useState(false)
  const { allTableOfTables } = useContext(AdsContext)

  useEffect(() => {               
    if (progressSeconds !== 0) {
      const progress = setTimeout(() => { setTheProgress(theProgress + 1) }, 1000)
      return () => clearTimeout(progress)
    } else {
      setTheProgress(0)
    }
  }, [theProgress])

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            setSearchInput(node)
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon='search'
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type='search' style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.select())
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  })

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }

  // Modal delete
  const showConfirm = (selectedFile) => {
    let isOkClicked = false
    Modal.confirm({
      title: selectedFile === 'both' ? t('Do you Want to delete these items?') : selectedFile === 'sales' ? t('Do you Want to delete SALES items?') : t('Do you Want to delete STOCKS items?'),
      icon: <Icon type='exclamation-circle' />,
      content: t('You will not be able to recover these items'),
      onOk () {
        if (!isOkClicked) {
          isOkClicked = true
          confirmDeleteExcelPage(selectedFile)
        }
      },
      onCancel () {
      }
    })
  }

  const showConfirmSubmit = () => {
    let isOkClicked = false
    Modal.confirm({
      title: t('Confirm submit'),
      icon: <Icon type='exclamation-circle' />,
      content: t(
        'You acknowlegde that the information uploaded for the tenant {{selectedTenantName}} is correct', {selectedTenantName: selectedTenantName}
      ),
      onOk () {
        if (!isOkClicked) {
          isOkClicked = true
          handleSubmit()
        }
      },
      onCancel () {
        setHasValidDataToUpload(false)
      }
    })
  }

  const showConfirmChangeDescriptions = () => {
    let isOkClicked = false
    Modal.confirm({
      title: t('Confirm change descriptions'),
      icon: <Icon type='exclamation-circle' />,
      content: t('Content change descriptions'),
      onOk () {
        if (!isOkClicked) {
          isOkClicked = true
          updateDescriptions()
        }
      },
      onCancel () {
      }
    })
  }

  const showModal = () => {
    setInfoState(true)
  }

  const handleOk = e => {
    console.log(e)
    setInfoState(false)
  }

  const handleCancel = e => {
    console.log(e)
    setInfoState(false)
  }

  const confirmDeleteExcelPage = (selectedFile) => {
    setIsLoadingDelete(true)
    logic
      .deleteExcelPage({ date: selectedDate, tenantId: selectedTenant, fileToDelete: selectedFile, distributorId: selectedDistributor })
      .then((result) => {
        if (result && result.data && result.data.sales >= 0 && result.data.stocks >=0) {
          alert.show(t('Registros Borrados'), {
            type: 'success'
          })
          if (selectedFile === 'both' || (selectedFile === 'sales' &&  originalSecondPage === 0) || (selectedFile === 'stocks' && originalFirstPage === 0)) {
              setHasRegistries(false)
              setOriginalFirstPage(0)
              setOriginalSecondPage(0)
              setShowSalesDescriptionsMessage(false)
              setShowStocksDescriptionsMessage(false)
              setFirstPage((prevState) => {
                return { ...prevState, rows: [] }
              })
              setSecondPage((prevState) => {
                return { ...prevState, rows: [] }
              })
              setDefaultTab('1')
          } 
          if (selectedFile === 'sales' && originalSecondPage > 0) {
              setOriginalFirstPage(0)
              setShowSalesDescriptionsMessage(false)
              setFirstPage((prevState) => {
                return { ...prevState, rows: [] }
              })
              setDefaultTab('2')
          }
          if (selectedFile === 'stocks' && originalFirstPage > 0) {
              setOriginalSecondPage(0)
              setShowStocksDescriptionsMessage(false)
              setSecondPage((prevState) => {
                return { ...prevState, rows: [] }
              })
              setDefaultTab('1')
          }
          setIsLoadingDelete(false)
        } else {
          setIsLoadingDelete(false)
          alert.show(t('Error borrando registros'), {
           type: 'error'
          })
        }
      })
      .catch((err) => {
        setIsLoadingDelete(false)
        console.error(err.message)
        alert.show(t('Error borrando registros'), {
          type: 'error'
        })
      })
  }

  useEffect(() => {
    if (!isHidden || hasSelectionChanged) {
      loadData()
    }
    if (isHidden) {
      setHasRegistries(false)
    }
  }, [isHidden, hasSelectionChanged])

  const loadData = () => {
    isPVLContracted = TableOfTables('PVL', selectedTenant, allTableOfTables, true)
    setIsLoadingPage(true)
    setIsLoadingDelete(true)
    setIsLoadingDownload(true)
    logic
      .getRegistriesFromSelectedDateAndTenantAdmin({
        selectedDate,
        selectedTenant, 
        distributorId: selectedDistributor
      })
      .then((registries) => {
        const {
          data: { stocks, sales }
        } = registries
        const newRows1 = []
        const newRows2 = []
        sales && sales.length ? setOriginalFirstPage(sales.length) : setOriginalFirstPage(0)
        stocks && stocks.length ? setOriginalSecondPage(stocks.length) : setOriginalSecondPage(0)
        if ((stocks && stocks.length) || (sales && sales.length)) {
          setFirstPage((prevState) => {
            return { ...prevState, name: 'Modelo Reporte Ventas' }
          })
          setSecondPage((prevState) => {
            return { ...prevState, name: 'Modelo Reporte Stock' }
          })
          stocks.map((row, index) => {
            if (row && row !== 'undefined') {
              const {
                registrytime: registryTime,
                productdistributorcode: productDistributorCode,
                productname: productName,
                units,
                registrystockid: registryStockId
              } = row
              const formatedRegistryTime = dayjs(registryTime).format(
                DATE_FORMAT
              )
              return newRows2.push({
                key: `stock-${registryStockId}`,
                date: formatedRegistryTime,
                productDistributorCode: productDistributorCode,
                distributorProductName: productName,
                units: units,
                country: '',
                hasDateError: false,
                hasUnitsError: false,
                hasDistributorProductNameError: false,
                hasProductCodeError: false
              })
            }
          })
          sales.map((row, index) => {
            if (row && row !== 'undefined') {
              const {
                registrytime: registryTime,
                productdistributorcode: productDistributorCode,
                productname: productName,
                units,
                registrysalesid: registrySalesId,
                postalcode: postalCode,
                clientidentity: clientIdentity,
                categoryname: categoryName,
                clientcategoryname: clientCategoryName 
              } = row
              const formatedRegistryTime = dayjs(registryTime).format(
                DATE_FORMAT
              )
              return newRows1.push({
                key: `sale-${registrySalesId}`,
                date: formatedRegistryTime,
                cifClient: clientIdentity,
                productDistributorCode: productDistributorCode,
                distributorProductName: productName,
                postalCode: postalCode,
                units: units,
                country: '',
                clientCategory: categoryName,
                clientName: clientCategoryName,
                hasDateError: false,
                hasCifError: false,
                hasPostalCodeError: false,
                hasUnitsError: false,
                hasDistributorProductNameError: false,
                hasProductCodeError: false,
                hasCategoryNameError: false,
                isCategoryNameInactive: false
              })
            }
          })

          if (isPVLContracted) {
            setFirstPage((prevState) => {
              return {
                ...prevState,
                columns: columnsWithClients.columns,
                rows: newRows1,
                errorMessage: null
              }
            })
          } else {
            setFirstPage((prevState) => {
              return {
                ...prevState,
                columns: columnsWithoutClients.columns,
                rows: newRows1,
                errorMessage: null
              }
            })
          }

          setSecondPage((prevState) => {
            return {
              ...prevState,
              rows: newRows2,
              errorMessage: null
            }
          })
          setHasRegistries(true)
        }
        if (stocks && !stocks.length && sales && !sales.length) {
          setHasRegistries(false)

          if (isPVLContracted) {
            setFirstPage((prevState) => {
              return {
                ...prevState,
                columns: columnsWithClients.columns,
                rows: newRows1,
                errorMessage: null
              }
            })
          } else {
            setFirstPage((prevState) => {
              return {
                ...prevState,
                columns: columnsWithoutClients.columns,
                rows: newRows1,
                errorMessage: null
              }
            })
          }

          setSecondPage((prevState) => {
            return {
              ...prevState,
              rows: newRows2,
              errorMessage: null
            }
          })
        }
        if (!sales.length && stocks.length) {
          setDefaultTab('2')
        } else {
          setDefaultTab('1')
        }
        setIsLoadingPage(false)
        setIsLoadingDelete(false)
        setIsLoadingDownload(false)
      })
  }

  const renderErrorCell = (text, record) => {
    let hasError = false
    // it will check if the row has an hasError property and if it is the case it will render one or another.
    const { hasCifError, hasDateError, hasDistributorProductNameError, hasPostalCodeError, hasProductCodeError, hasUnitsError, hasCategoryNameError } = record

    if (hasCifError || hasDateError || hasDistributorProductNameError || hasPostalCodeError || hasProductCodeError || hasUnitsError || hasCategoryNameError) {
      hasError = true
    }

    return (
      hasError &&
      <Icon style={{ color: '#f5222d' }}>
          <FontAwesomeIcon size='lg' icon={['fas', 'exclamation-triangle']} />
      </Icon>
    )
  }

  const renderSelectPostalCode = (text, record) => {
    // it will check if the record has an key of newSale or newStock, it it does, the select will be available.
    const { key, postalCode } = record
    const regexCheckRecord = new RegExp('^[n][e][w]')
    const isNewData = regexCheckRecord.test(key)
        // make sure postal codes are correct
    let defaultCountry = COUNTRIES.find(country => {
      const countryToCompare = postalCodeRegex.postCodeRegex.find(regex => {
        return regex.territoryId === country
      })

      if (countryToCompare && countryToCompare.text) {
        const regex = new RegExp(countryToCompare.text)
        return regex.test(postalCode)
      }
    })
    defaultCountry = defaultCountry || 'ES'

    return (
      <Select
        defaultValue={isNewData ? defaultCountry : 'N/A'}
        style={{ width: 120 }}
        onChange={(value) => handleChange(value, record, 'postalCode')}
        disabled={isNewData ? BOOLEAN.false : BOOLEAN.true}
      >
        {COUNTRIES.map((country) => {
          return (
            <Select.Option key={country} value={country}>
              {country}
            </Select.Option>
          )
        })}
      </Select>
    )
  }

  const renderSelectClientCategory = (text, record) => {
    // it will check if the record has an key of newSale or newStock, it it does, the select will be available.
    const { key, clientCategory } = record
    const regexCheckRecord = new RegExp('^[n][e][w]')
    const isNewData = regexCheckRecord.test(key)

    return (
      <Select
        defaultValue={clientCategory}
        style={{ width: 220 }}
        onChange={(value) => handleChange(value, record, 'clientCategory')}
        disabled={isNewData ? BOOLEAN.false : BOOLEAN.true}
      >
        {clientsCategoriesNames.map((category) => {
          return (
            <Select.Option key={category} value={category}>
              {category}
            </Select.Option>
          )
        })}
      </Select>
    )
  }

  const [firstPage, setFirstPage] = useState({
    name: '',
    cols: [],
    rows: [],
    errorMessage: null
  })

  const columnsWithoutClients = {
    name: '',
    cols: [],
    rows: [],
    errorMessage: null,
    columns: [
      {
        title: t('date'),
        dataIndex: 'date',
        editable: true,
        sorter: (a, b) => {
          if (!a.date || !b.date) {
            return -1
          }
          a = typeof date === 'string' ? a.date.split('/').reverse().join('/') : a.date
          b = typeof date === 'string' ? b.date.split('/').reverse().join('/') : b.date
          if (a < b) {
            return -1
          } else if (a > b) {
            return 1
          } else {
            return 0
          }
        },
        ...getColumnSearchProps('date')
      },
      {
        title: t('cifClient'),
        dataIndex: 'cifClient',
        editable: true,
        sorter: (a, b) => {
          if (!a.cifClient || !b.cifClient) {
            return -1
          }
          return ('' + a.cifClient).localeCompare(b.cifClient)
        },
        ...getColumnSearchProps('cifClient')
      },
      {
        title: t('productDistributorCode'),
        dataIndex: 'productDistributorCode',
        editable: true,
        sorter: (a, b) => {
          if (!a.productDistributorCode || !b.productDistributorCode) {
            return -1
          }
          return ('' + a.productDistributorCode).localeCompare(
            b.productDistributorCode
          )
        },
        ...getColumnSearchProps('productDistributorCode')
      },
      {
        title: t('distributorProductName'),
        dataIndex: 'distributorProductName',
        editable: true,
        sorter: (a, b) => {
          if (!a.distributorProductName || !b.distributorProductName) {
            return -1
          }
          return ('' + a.distributorProductName).localeCompare(
            b.distributorProductName
          )
        },
        ...getColumnSearchProps('distributorProductName')
      },
      {
        title: t('postalCode'),
        dataIndex: 'postalCode',
        editable: true,
        sorter: (a, b) => {
          if (!a.postalCode || b.postalCode) {
            return -1
          }
          return a.postalCode - b.postalCode
        },
        ...getColumnSearchProps('postalCode')
      },
      {
        title: t('units'),
        dataIndex: 'units',
        editable: true,
        sorter: (a, b) => {
          if (!a.units || !b.units) {
            return -1
          }
          return a.units - b.units
        },
        ...getColumnSearchProps('units')
      },
      {
        title: t('country'),
        dataIndex: 'country',
        editable: false,
        render: renderSelectPostalCode
      },
      {
        title: t('Errores'),
        dataIndex: 'errors',
        editable: false,
        sorter: (a, b) => {
          // if a has error and b doesn't
          if ((a.hasCifError || a.hasDateError || a.hasDistributorProductNameError || a.hasPostalCodeError || a.hasProductCodeError || a.hasUnitsError || a.hasCategoryNameError) && (!b.hasCifError || !b.hasDateError || !b.hasDistributorProductNameError || !b.hasPostalCodeError || !b.hasProductCodeError || !b.hasUnitsError || !b.hasCategoryNameError)) {
            return -1
          }
          // if a doesn't have error and b does
          if ((!a.hasCifError || !a.hasDateError || !a.hasDistributorProductNameError || !a.hasPostalCodeError || !a.hasProductCodeError || !a.hasUnitsError || !a.hasCategoryNameError) && (b.hasCifError || b.hasDateError || b.hasDistributorProductNameError || b.hasPostalCodeError || b.hasProductCodeError || b.hasUnitsError || b.hasCategoryNameError)) {
            return 1
          }

          // if they both have an error
          if ((a.hasCifError || a.hasDateError || a.hasDistributorProductNameError || a.hasPostalCodeError || a.hasProductCodeError || a.hasUnitsError || a.hasCategoryNameError) && (b.hasCifError || b.hasDateError || b.hasDistributorProductNameError || b.hasPostalCodeError || b.hasProductCodeError || b.hasUnitsError || b.hasCategoryNameError)) {
            return 0
          }
          return 0
        },
        render: renderErrorCell
      }
    ]
  }

  const columnsWithClients = {
    name: '',
    cols: [],
    rows: [],
    errorMessage: null,
    columns: [
      {
        title: t('date'),
        dataIndex: 'date',
        editable: true,
        sorter: (a, b) => {
          if (!a.date || !b.date) {
            return -1
          }
          a = typeof date === 'string' ? a.date.split('/').reverse().join('/') : a.date
          b = typeof date === 'string' ? b.date.split('/').reverse().join('/') : b.date
          if (a < b) {
            return -1
          } else if (a > b) {
            return 1
          } else {
            return 0
          }
        },
        ...getColumnSearchProps('date')
      },
      {
        title: t('cifClient'),
        dataIndex: 'cifClient',
        editable: true,
        sorter: (a, b) => {
          if (!a.cifClient || !b.cifClient) {
            return -1
          }
          return ('' + a.cifClient).localeCompare(b.cifClient)
        },
        ...getColumnSearchProps('cifClient')
      },
      {
        title: t('productDistributorCode'),
        dataIndex: 'productDistributorCode',
        editable: true,
        sorter: (a, b) => {
          if (!a.productDistributorCode || !b.productDistributorCode) {
            return -1
          }
          return ('' + a.productDistributorCode).localeCompare(
            b.productDistributorCode
          )
        },
        ...getColumnSearchProps('productDistributorCode')
      },
      {
        title: t('distributorProductName'),
        dataIndex: 'distributorProductName',
        editable: true,
        sorter: (a, b) => {
          if (!a.distributorProductName || !b.distributorProductName) {
            return -1
          }
          return ('' + a.distributorProductName).localeCompare(
            b.distributorProductName
          )
        },
        ...getColumnSearchProps('distributorProductName')
      },
      {
        title: t('postalCode'),
        dataIndex: 'postalCode',
        editable: true,
        sorter: (a, b) => {
          if (!a.postalCode || b.postalCode) {
            return -1
          }
          return a.postalCode - b.postalCode
        },
        ...getColumnSearchProps('postalCode')
      },
      {
        title: t('units'),
        dataIndex: 'units',
        editable: true,
        sorter: (a, b) => {
          if (!a.units || !b.units) {
            return -1
          }
          return a.units - b.units
        },
        ...getColumnSearchProps('units')
      },
      {
        title: t('country'),
        dataIndex: 'country',
        editable: false,
        render: renderSelectPostalCode
      },
      {
        title: t('clientCategory'),
        dataIndex: 'clientCategory',
        editable: true,
        render: renderSelectClientCategory,
        /* sorter: (a, b) => {
          if (!a.clientCategory || !b.clientCategory) {
            return -1
          }
          return ('' + a.clientCategory).localeCompare(
            b.clientCategory
          )
        },
        ...getColumnSearchProps('clientCategory') */
      }, 
      {
        title: t('clientName'),
        dataIndex: 'clientName',
        editable: true,
       /*  sorter: (a, b) => {
          if (!a.clientName || !b.clientName) {
            return -1
          }
          return ('' + a.clientName).localeCompare(b.clientName)
        },
        ...getColumnSearchProps('clientName') */
      },
      {
        title: t('Errores'),
        dataIndex: 'errors',
        editable: false,
        sorter: (a, b) => {
          // if a has error and b doesn't
          if ((a.hasCifError || a.hasDateError || a.hasDistributorProductNameError || a.hasPostalCodeError || a.hasProductCodeError || a.hasUnitsError || a.hasCategoryNameError) && (!b.hasCifError || !b.hasDateError || !b.hasDistributorProductNameError || !b.hasPostalCodeError || !b.hasProductCodeError || !b.hasUnitsError || !b.hasCategoryNameError)) {
            return -1
          }
          // if a doesn't have error and b does
          if ((!a.hasCifError || !a.hasDateError || !a.hasDistributorProductNameError || !a.hasPostalCodeError || !a.hasProductCodeError || !a.hasUnitsError || !a.hasCategoryNameError) && (b.hasCifError || b.hasDateError || b.hasDistributorProductNameError || b.hasPostalCodeError || b.hasProductCodeError || b.hasUnitsError || b.hasCategoryNameError)) {
            return 1
          }

          // if they both have an error
          if ((a.hasCifError || a.hasDateError || a.hasDistributorProductNameError || a.hasPostalCodeError || a.hasProductCodeError || a.hasUnitsError || a.hasCategoryNameError) && (b.hasCifError || b.hasDateError || b.hasDistributorProductNameError || b.hasPostalCodeError || b.hasProductCodeError || b.hasUnitsError || b.hasCategoryNameError)) {
            return 0
          }
          return 0
        },
        render: renderErrorCell
      }
    ]
  }

  const [secondPage, setSecondPage] = useState({
    name: '',
    cols: [],
    rows: [],
    errorMessage: null,
    columns: [
      {
        title: t('date'),
        dataIndex: 'date',
        editable: true,
        sorter: (a, b) => {
          if (!a.date || !b.date) {
            return -1
          }
          a = typeof date === 'string' ? a.date.split('/').reverse().join('/') : a.date
          b = typeof date === 'string' ? b.date.split('/').reverse().join('/') : b.date
          if (a < b) {
            return -1
          } else if (a > b) {
            return 1
          } else {
            return 0
          }
        },
        ...getColumnSearchProps('date')
      },
      {
        title: t('productDistributorCode'),
        dataIndex: 'productDistributorCode',
        editable: true,
        sorter: (a, b) => {
          if (!a.productDistributorCode || !b.productDistributorCode) {
            return -1
          }
          return ('' + a.productDistributorCode).localeCompare(
            b.productDistributorCode
          )
        },
        ...getColumnSearchProps('productDistributorCode')
      },
      {
        title: t('distributorProductName'),
        dataIndex: 'distributorProductName',
        editable: true,
        sorter: (a, b) => {
          if (!a.distributorProductName || !b.distributorProductName) {
            return -1
          }
          return ('' + a.distributorProductName).localeCompare(
            b.distributorProductName
          )
        },
        ...getColumnSearchProps('distributorProductName')
      },
      {
        title: t('units'),
        dataIndex: 'units',
        editable: true,
        sorter: (a, b) => {
          if (!a.units || !b.units) {
            return -1
          }
          return a.units - b.units
        },
        ...getColumnSearchProps('units')
      },
      {
        title: t('Errores'),
        dataIndex: 'errors',
        editable: false,
        sorter: (a, b) => {
           // if a has error and b doesn't
           if ((a.hasCifError || a.hasDateError || a.hasDistributorProductNameError || a.hasPostalCodeError || a.hasProductCodeError || a.hasUnitsError || a.hasCategoryNameError) && (!b.hasCifError || !b.hasDateError || !b.hasDistributorProductNameError || !b.hasPostalCodeError || !b.hasProductCodeError || !b.hasUnitsError || !b.hasCategoryNameError)) {
            return -1
          }
          // if a doesn't have error and b does
          if ((!a.hasCifError || !a.hasDateError || !a.hasDistributorProductNameError || !a.hasPostalCodeError || !a.hasProductCodeError || !a.hasUnitsError || !a.hasCategoryNameError) && (b.hasCifError || b.hasDateError || b.hasDistributorProductNameError || b.hasPostalCodeError || b.hasProductCodeError || b.hasUnitsError || b.hasCategoryNameError)) {
            return 1
          }

          // if they both have an error
          if ((a.hasCifError || a.hasDateError || a.hasDistributorProductNameError || a.hasPostalCodeError || a.hasProductCodeError || a.hasUnitsError || a.hasCategoryNameError) && (b.hasCifError || b.hasDateError || b.hasDistributorProductNameError || b.hasPostalCodeError || b.hasProductCodeError || b.hasUnitsError || b.hasCategoryNameError)) {
            return 0
          }
          return 0
        },
        render: renderErrorCell
      }
    ]
  })

  function handleChange (value, record, column) {
    switch(column) {
      case 'postalCode':
        record.country = value
      case 'clientCategory':
        record.clientCategory = value
    }

    setFirstPage((prevState) => {
      return { ...prevState }
    })
  }

  const handleSave = (row) => {
    const newData = [...firstPage.rows]
    const newData2 = [...secondPage.rows]
    const index = newData.findIndex((item) => row.key === item.key)
    const index2 = newData2.findIndex((item) => row.key === item.key)
    const item = newData[index]
    const item2 = newData2[index2]
    // if findIndex fails, don't splice
    if (index !== -1) {
      newData.splice(index, 1, {
        ...item,
        ...row
      })
    }
    // if findIndex fails, don't splice
    if (index2 !== -1) {
      newData2.splice(index2, 1, {
        ...item2,
        ...row
      })
    }

    setFirstPage((prevState) => {
      return { ...prevState, rows: newData }
    })

    setSecondPage((prevState) => {
      return { ...prevState, rows: newData2 }
    })
  }

  const handleFileListChange = (info) => {
    let fileList = [...info.fileList]
    // Remove first and second page state when uploading
    if (originalFirstPage === 0) {
      setFirstPage((prevState) => {
        return { ...prevState, rows: [] }
      })
    }
    if (originalSecondPage === 0) {
      setSecondPage((prevState) => {
        return { ...prevState, rows: [] }
      })
    }
    // 1. Limit the number of uploaded files to 1
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(-1)

    setFileList([...fileList])
  }

  const fileHandler = (fileList) => {
    setIsLoadingUpload(true)
    // 1. Limit the number of uploaded files to 1
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    if (fileList.length) {
      fileList = fileList.slice(-1)
    }
    const fileObj = fileList
    if (!fileObj) {
      alert.show(t('Ningun archivo cargado'), {
        type: 'error'
      })
      setFirstPage((prevState) => {
        return { ...prevState, errorMessage: 'No file uploaded!' }
      })
      return false
    }
    if (!(fileObj.type === 'application/vnd.ms-excel' ||
        fileObj.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
          alert.show(t('Formato incorrecto, solo se acepta format Excel'), {
              type: 'error'
          })
          setFirstPage((prevState) => {
              return {
              ...prevState,
              errorMessage: 'Unknown file format. Only Excel files are uploaded!'
              }
          })
          return false
    }

    ExcelRenderer(fileObj, (err, resp) => {
        if (err) {
            if (err === 'incorrectTemplate') {
              alert.show(t('Por favor utiliza la plantilla correcta'), {
                  type: 'error'
              })
            }
        } else {
          const newRows1 = []
          const newRows2 = []
          if (resp[0].rows.length > 0) {
            setFirstPage((prevState) => {
                return { ...prevState, name: resp[0].wsname }
            })
          }
          if (resp[1].rows.length > 0) {
            setSecondPage((prevState) => {
                return { ...prevState, name: resp[1].wsname }
            })
          }
          const defaultDate = dayjs(selectedDate).format(DATE_FORMAT)
          resp.map((sheet, sheetIndex) => {
              sheet.rows.slice(1).map((row, index) => {
                  if (row && row !== 'undefined') {
                        // Trim all the sensitive data to make sure we have correct information
                      row[1] = row[1] && row[1].toString().trim()
                      row[2] = row[2] && row[2].toString().trim()
                      row[3] = row[3] && row[3].toString().trim()
                      row[4] = row[4] && row[4].toString().trim()
                      if (sheetIndex === 0) {
                          // If row[1](the Cif) starts with a DE, default to the default CIF
                          // check if the ID given is a VAT (VAT = ES + CIF ),  if it is , it will remove the ES
                          const regexCheckingBeggining = new RegExp('^[De][Ee]')

                          if (regexCheckingBeggining.test(row[1])) {
                              row[1] = DEFAULT_CIF
                          }
                          // make sure postal codes are correct
                          // If the number has 4 digits, it will add the extra 0
                          if (row[4] && row[4].toString() && row[4].toString().length === 4) {
                              row[4] = '0' + row[4]
                          }
                          if (row[4] && row[4].toString() && row[4].toString().length === 3) {
                              row[4] = '00' + row[4]
                          }
                          const postCode = row[4] || DEFAULT_POSTAL_CODE
                          const defaultCountry = COUNTRIES.find(country => {
                              const countryToCompare = postalCodeRegex.postCodeRegex.find(regex => {
                                  return regex.territoryId === country
                              })
                              if (countryToCompare && countryToCompare.text) {
                                  const regex = new RegExp(countryToCompare.text)
                                  const test = regex.test(postCode) // here we only check the postal code country not its validity
                                  return test
                              }
                          })
                          // Make sure the number given is a number or default to N/A
                          if (row[5] === 0 || row[5] === '0') {
                              row[5] = 0
                          } else {
                              row[5] = (row[5] && Number(row[5])) || 'N/A'
                          }

                          return newRows1.push({
                              key: `newSale-${index}`,
                              date: row[0] || defaultDate,
                              cifClient: row[1] || DEFAULT_CIF,
                              productDistributorCode: row[2],
                              distributorProductName: row[3],
                              postalCode: row[4] || DEFAULT_POSTAL_CODE,
                              units: row[5],
                              country: defaultCountry || 'ES',
                              clientCategory: isPVLContracted ? row[6] : '',
                              clientName: isPVLContracted ? row[7] : '',          
                              hasDateError: false,
                              hasCifError: false,
                              hasPostalCodeError: false,
                              hasUnitsError: false,
                              hasProductCodeError: false,
                              hasCategoryNameError: false,
                              isCategoryNameInactive: false
                          })
                      }
                      if (sheetIndex === 1) {
                          // Make sure the number given is a number or default to N/A
                          if (row[3] === 0 || row[3] === '0') {
                          row[3] = 0
                          } else {
                          row[3] = (row[3] && Number(row[3])) || 'N/A'
                          }
                          return newRows2.push({
                          key: `newStock-${index}`,
                          date: row[0] || defaultDate,
                          productDistributorCode: row[1],
                          distributorProductName: row[2],
                          units: row[3] || DEFAULT_STOCK_UNITS,
                          country: COUNTRIES[2],
                          hasDateError: false,
                          hasUnitsError: false,
                          hasProductCodeError: false
                          })
                      }
                  }
                  return null
              })
              if (newRows1.length === 0) {
                if (resp[0].rows.length > 0) {
                    setFirstPage((prevState) => {
                    return { ...prevState, errorMessage: 'No data found in file!' }
                    })
                }
                if (newRows2.length === 0) return false
              } 
              if (newRows2.length === 0) {
                if (resp[1].rows.length > 0) {
                    setSecondPage((prevState) => {
                        return { ...prevState, errorMessage: 'No data found in file!' }
                    })
                }
                  return false
              } 
        })
        if ((originalFirstPage !== 0 && newRows1.length > 0) ||
            (originalSecondPage !== 0 && newRows2.length > 0)) {
              alert.show(originalFirstPage !== 0 ? t('sales_data_exists') : t('stocks_data_exists'), {
                type: 'error',
                timeout: 0
              })  
              setIsLoadingUpload(false)
              return
        }
        if (newRows1.length !== 0) {
          setHasRegistries((prevState) => false)
          setFirstPage((prevState) => {
              return {
                  ...prevState,
                  cols: resp.cols,
                  rows: newRows1,
                  errorMessage: null
              }
          })
          setDefaultTab('1')
        }
        if (newRows2.length !== 0) {
          setHasRegistries((prevState) => false)
          setSecondPage((prevState) => {
              return {
                  ...prevState,
                  cols: resp.cols,
                  rows: newRows2,
                  errorMessage: null
              }
          })
          if (newRows1.length === 0) setDefaultTab('2')
        }
      }
      setIsLoadingUpload(false)
    })
    setTableLoading(false)
    return false
  }

  // Important: for the progress bar when uploading data we divide the total rows (sales and stocks) by 8.500 because
  // we've calculated that each 7.500 registers inserted in postgresql DDBB takes aprox 1 minute.
  // By doing it we know the number of minutes it will take to upload the data.
  // As 1 minute are 60 secs we transform minutes to seconds by multiply them by 60.
  // We want to show the progress in steps of 1% so we divide the total seconds by 100 and finally...
  // we multiply per 1.000 because setTimeout calculates the timeout in miliseconds.
  const handleSubmit = async () => {
    setTheProgress(0)
    setProgressSeconds(1000)
    const data = {
      sales: {
        name: firstPage.name,
        data: originalFirstPage === 0 ? firstPage.rows : []
      },
      stock: {
        name: secondPage.name,
        data: originalSecondPage === 0 ? secondPage.rows : []
      },
      date: selectedDate,
      tenantId: selectedTenant.toString(),
      timeout: Math.round((((originalFirstPage  === 0 ? firstPage.rows.length  : 0  + 
                             originalSecondPage === 0 ? secondPage.rows.length : 0) / 8500) * 60) * 1000) + 60000,
      readSalesRows: originalFirstPage  === 0 ? readSalesRows : 0,
      readStockRows: originalSecondPage === 0 ? readStockRows : 0
    }
    confirmAction.closeMessage()
    confirmAction2.closeMessage()
    confirmAction3.closeMessage()
    confirmAction4.closeMessage()
    confirmAction5.closeMessage()
    confirmAction6.closeMessage()
    confirmAction7.closeMessage()
    setIsLoadingValidation(true)
    const isItATenant = selectedDistributorIdentity === selectedTenantIdentity ? true : false
    logic
      .insertExcelPage(data, selectedDistributor, isItATenant)
      .then((data) => {
        if (data.status !== 200) {
          throw Error(data.error)
        }
        if (data.data && data.data.sale[0] === 'TIMEOUT') {
          alert.show(t('Timeout'), {
            type: 'info',
            timeout: 0
          })  
        } else if (data.data && data.data.sale[0] === 'BLOCKED') {
          alert.show(t('Blocked'), {
            type: 'info',
            timeout: 5000
          })  
          history.push("/")
        } else if (data.data && data.data.sale[0] === 'UPLOADED' || data.data && data.data.stock[0] === 'UPLOADED') {
          alert.show(t('Uploaded'), {
            type: 'info',
            timeout: 5000
          })  
          setIsHidden(false)
        } else {
          alert.show(t('Subida de datos satisfactoria'), {
            type: 'success'
          })  
        }
        setIsLoadingValidation(false)
        setHasValidDataToUpload(false)
        setHasRegistries(true)
        setFixDescriptions(false)
        setNewProdNames([])
        if (firstPage.rows.length) setOriginalFirstPage(firstPage.rows.length)
        if (secondPage.rows.length) setOriginalSecondPage(secondPage.rows.length)
        setFileList([])
        setProgressSeconds()
      })
      .catch((err) => {
        const { message } = err
        console.error(message)
        setIsLoadingValidation(false)
        setHasValidDataToUpload(false)
        setProgressSeconds()
        alert.show((message), {
          type: 'error',
          timeout: 0
        })
        setHasSalesError(false)
        setHasStockError(false)
        setOriginalFirstPage(0)
        setOriginalSecondPage(0)
        setFixDescriptions(false)
        setNewProdNames([])
        setFirstPage((prevState) => {
          return { ...prevState, rows: [] }
        })
        setSecondPage((prevState) => {
          return { ...prevState, rows: [] }
        })
      })
  }

  // components for the editable rows
  const components1 = {
    body: {
      row: EditableFormRow,
      cell: EditableCell
    }
  }

  const components2 = {
    body: {
      row: EditableFormRow,
      cell: EditableCell
    }
  }
  const newColumns1 =
    firstPage &&
    firstPage.columns &&
    firstPage.columns.map((col) => {
      if (!col.editable || originalFirstPage > 0) { // if originally the distributor had data on the DDBB we don't allow changing it
        return col
      }
      return {
        ...col,
        onCell: (record) => {
          return {
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            language: i18n.language,
            handleSave: handleSave
          }
        }
      }
    })

  const newColumns2 =
    secondPage &&
    secondPage.columns &&
    secondPage.columns.map((col) => {
      if (!col.editable || originalSecondPage > 0) { // if originally the distributor had data on the DDBB we don't allow changing it
        return col
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          language: i18n.language,
          handleSave: handleSave
        })
      }
    })

  function callback (key) {
    setDefaultTab(key)
  }

  const handleDoYearMonthPick = (value) => {
    setHasStockError(false)
    setHasSalesError(false)
    setFixDescriptions(false)
    setNewProdNames([])
    setShowSalesDescriptionsMessage(false)
    setShowStocksDescriptionsMessage(false)
    const { tenantId, date, tenantName, tenantIdentity, isUploadBlocked, toMonthBlocked } = value
    setSelectedDate(date)
    setSelectedTenant(tenantId)
    isPVLContracted = TableOfTables('PVL', tenantId, allTableOfTables, true)
    setSelectedTenantName(tenantName)
    setSelectedTenantIdentity(tenantIdentity)
    let blockTillMonth
    const selectedDate = `${new Date(date).getFullYear()}-${(new Date(date).getMonth()+1).toString().padStart(2, '0')}`
    if (isUploadBlocked) {
      if (toMonthBlocked === null) {
        blockTillMonth = MAXMONTHTOBLOCK[new Date().getMonth()]
      } else {
        blockTillMonth = `${new Date(toMonthBlocked).getFullYear()}-${(new Date(toMonthBlocked).getMonth()+1).toString().padStart(2, '0')}`
      }
      if (selectedDate <= blockTillMonth) {
        setIsDataBlocked(true)  
      } else {
        setIsDataBlocked(false)
      }
    } else {
      setIsDataBlocked(false)
    }
    setFileList([])
    if (tenantId && date) {
      logic
        .getTenantClientsCategories(tenantId.toString())
        .then((registries) => {
          if (registries.status !== 200) {
            throw Error(`${registries.status}`)
          }
          clientsCategoriesNames = registries.data.filter(category => category.active === true)
                                                  .map(category => { return category.categoryname })
                                                  
          clientsCategories = registries.data
        })
        .catch(err => {
          alert.show(t(err.message), {
            type: 'error'
          })
      })
      sethasSelectionChanged(!hasSelectionChanged)
      setIsHidden(false) // only is changed once first time a user select the distributor
    }
  }

  const handleValidateDataToUpload = async () => {
    setHasSalesError(false)
    setHasStockError(false)
    setIsLoadingValidation(true)
    setHasValidDataToUpload(false)
    readSalesRows = firstPage.rows.length
    readStockRows = secondPage.rows.length
    try {
        const data = {
          sales: {
            name: firstPage.name,
            data: originalFirstPage === 0 ? firstPage.rows : []
          },
          stock: {
            name: secondPage.name,
            data: originalSecondPage === 0 ? secondPage.rows : []
          },
          date: selectedDate,
          tenantId: selectedTenant.toString()
        }

        confirmAction.closeMessage()
        confirmAction2.closeMessage()
        confirmAction3.closeMessage()
        confirmAction4.closeMessage()
        confirmAction5.closeMessage()
        confirmAction6.closeMessage()
        confirmAction7.closeMessage()
        let firstPageState = { ...firstPage }
        let secondPageState = { ...secondPage }
        setFirstPage((prevState) => {
          firstPageState = prevState
          return { ...prevState }
        })
        // verify the dates
        const selectedDateYear = dayjs(selectedDate).year()
        const selectedDateMonth = dayjs(selectedDate).month()

        const checkProductCodeConsistency = await logic.validateProductCodes(data, selectedDistributor)
        const saleProductCodeErrorsIndex = []
        const stockProductCodeErrorsIndex = []

        if (checkProductCodeConsistency && checkProductCodeConsistency.data) {
          const saleProductCodeErrors =
            checkProductCodeConsistency &&
            checkProductCodeConsistency.data &&
            checkProductCodeConsistency.data.sale
          const stockProductCodeErrors =
            checkProductCodeConsistency &&
            checkProductCodeConsistency.data &&
            checkProductCodeConsistency.data.stock

          // find the rows that are to be updated, and then replace with the new one
          // push all of the indexes where there are errors
          const newSalesProductCodeRows =
            firstPageState &&
            firstPageState.rows &&
            firstPageState.rows.map((row, index) => {
              // find row if it exists
              const newRow = saleProductCodeErrors.find((item) => {
                if ((item && item.key && item.key.toString() === row.key.toString()) || (!row.productDistributorCode)) {
                  saleProductCodeErrorsIndex.push(index)
                }
                return item && item.key && item.key.toString() === row.key.toString()
              })
              if (newRow || !row.productDistributorCode) {
                return {
                  ...row,
                  hasProductCodeError: true,
                  hasError: true,
                  correctProductName: newRow && newRow.correctProductName,
                  tenantName: newRow && newRow.tenantName
                }
              } else {
                return { ...row, hasProductCodeError: false, hasError: false }
              }
            })
          const incorrectSalesProductNames = originalFirstPage === 0 ? newSalesProductCodeRows.map(element => Object.keys(element).includes("correctProductName")) 
                                                                     : []
          const newSalesCorrectProductNames = originalFirstPage === 0 ? newSalesProductCodeRows.filter(element => element.correctProductName)
                                                                                               .map(element => { return { code: element.productDistributorCode, newProdName: element.distributorProductName }})
                                                                      : []
    
          firstPageState = {
            ...firstPageState,
            rows: [...newSalesProductCodeRows]
          }

          // find the rows that are to be updated, and then replace with the new one
          // push all of the indexes where there are errors
          const newStocksProductCodeRows =
            secondPageState &&
            secondPageState.rows &&
            secondPageState.rows.map((row, index) => {
              // find row if it exists
              const newRow = stockProductCodeErrors.find((item) => {
                if ((item && item.key && item.key.toString() === row.key.toString()) || (!row.productDistributorCode)) {
                  stockProductCodeErrorsIndex.push(index)
                }
                return item && item.key && item.key.toString() === row.key.toString()
              })
              if (newRow || !row.productDistributorCode) {
                return {
                  ...row,
                  hasProductCodeError: true,
                  hasError: true,
                  correctProductName: newRow && newRow.correctProductName,
                  tenantName: newRow && newRow.tenantName
                }
              } else {
                return { ...row, hasProductCodeError: false, hasError: false }
              }
            })
          const incorrectStocksProductNames = originalSecondPage === 0 ? newStocksProductCodeRows.map(element => Object.keys(element).includes("correctProductName")) 
                                                                       : []
          const newStocksCorrectProductNames = originalSecondPage === 0 ? newStocksProductCodeRows.filter(element => element.correctProductName)
                                                                                                  .map(element => { return { code: element.productDistributorCode, newProdName: element.distributorProductName }})
                                                                        : []
          setFixDescriptions(incorrectSalesProductNames.includes(true) || incorrectStocksProductNames.includes(true))

          const stringCorrectProdNames = newSalesCorrectProductNames.concat(newStocksCorrectProductNames).map(obj => JSON.stringify(obj))
          const uniqueStringSet = new Set(stringCorrectProdNames)
          const uniqueCorrectProdNames = Array.from(uniqueStringSet).map(str => JSON.parse(str))
          setNewProdNames(uniqueCorrectProdNames)
          
          secondPageState = {
            ...secondPageState,
            rows: [...newStocksProductCodeRows]
          }
        }

        // We make sure that all of the product codes in sales are also in stocks
        const allSalesCodesAreInStockRows =
          firstPageState &&
          firstPageState.rows &&
          firstPageState.rows.map((row, index) => {
            // find row if it exists
            if (row && !row.productDistributorCode) {
              return {
                ...row,
                hasNoStockCodeError: false,
                hasError: false
              }
            }
            const newRow = secondPageState && secondPageState.rows && secondPageState.rows.find((item) => {
              return item && item.productDistributorCode && item.productDistributorCode.toString() === row.productDistributorCode.toString()
            })

            if (!newRow) {
              // saleProductCodeErrorsIndex.push(index)
              return {
                ...row,
                hasNoStockCodeError: false,
                hasError: false
              }
            } else {
              return { ...row, hasNoStockCodeError: false }
            }
          })
        // check  the sale dates
        const wrongSaleDates = data.sales.data.filter((sale) => {
          let isValidDate = false
          if (sale && sale.date && typeof sale.date !== 'string') { // in case of a number or other type coming from the Excel the date is NOT valid
            isValidDate = false 
          } else {
            if (sale && sale.date.split('/').length === 3) {
              isValidDate = dayjs(sale && sale.date, 'DD/MM/YYYY').isValid() && logic.evalDate(sale && sale.date)
            } // AMN dayjs gives as valid DD-MM-YYYY and node module validate-date provokes an error
          }
          // Given the date in DD/MM/YYYY we can get the substrings
          let getSaleMonthChars
          let getSaleYearChars
          if (isValidDate) {
            getSaleMonthChars = sale && sale.date && sale.date.substr(3, 2)
            getSaleYearChars = sale && sale.date && sale.date.substr(6, 4)
          }
          const saleMonth = parseInt(getSaleMonthChars)
          const saleYear = parseInt(getSaleYearChars)
          // subtract one because selectedDateMonth starts at 0
          // this is a filter function that returns under the condition that the sale month is correct, and the selected year is also correct
          // We check that the input is an actual valid date
          if (!isValidDate || !logic.isExistingDate(sale && sale.date)) {
            return true
          }
          return ((saleMonth - 1 !== selectedDateMonth || saleYear !== selectedDateYear))
        })
        // update the state to show a notification on the cell
        // find the rows that are to be updated, and then replace with the new one
        const saleDateErrorsIndex = []
        const newSaleDateRows =
          firstPageState &&
          firstPageState.rows.map((row) => {
            // find row if it exists
            const newRow = wrongSaleDates.find((item, index) => {
              if (item && item.key === row.key) {
                saleDateErrorsIndex.push(index)
              }
              return item && item.key === row.key
            })
            if (newRow) {
              return { ...row, hasDateError: true, hasError: true }
            } else {
              return { ...row, hasDateError: false }
            }
          })
        firstPageState = { ...firstPageState, rows: [...newSaleDateRows] }
        // check the stock dates
        const wrongStockDates = data.stock.data.filter((item) => {
          let isValidDate = false
          if (item && item.date && typeof item.date !== 'string') { // in case of a number or other type coming from the Excel the date is NOT valid
            isValidDate = false 
          } else {
            if (item && item.date.split('/').length === 3) {
              isValidDate = dayjs(item && item.date, 'DD/MM/YYYY').isValid() && logic.evalDate(item && item.date)
            } // AMN dayjs gives as valid DD-MM-YYYY and node module validate-date provokes an error
          }
          // Given the date in DD/MM/YYYY we can get the substrings
          let getStockMonthChars
          let getStockYearChars
          if (isValidDate) {
            getStockMonthChars = item && item.date && item.date.substr(3, 2)
            getStockYearChars = item && item.date && item.date.substr(6, 4)
          }
          const stockMonth = parseInt(getStockMonthChars)
          const stockYear = parseInt(getStockYearChars)

          if (!isValidDate || !logic.isExistingDate(item && item.date)) {
            return true
          }

          // subtract one because selectedDateMonth starts at 0
          return (stockMonth - 1 !== selectedDateMonth || stockYear !== selectedDateYear)
        })

        // update the state to show a notification on the cell
        // find the rows that are to be updated, and then replace with the new one
        // keep track of the index of found errors so we know where the errors are
        const stockDateErrorsIndex = []
        const newStockDateRows =
          secondPageState &&
          secondPageState.rows.map((row, index) => {
            // find row if it exists
            const newRow = wrongStockDates.find((item) => {
              if (item && item.key === row.key) {
                stockDateErrorsIndex.push(index)
              }
              return item && item.key === row.key
            })
            if (newRow) {
              return { ...row, hasDateError: true, hasError: true }
            } else {
              return { ...row, hasDateError: false }
            }
          })
        secondPageState = { ...secondPageState, rows: [...newStockDateRows] }

        // make sure postal codes are correct
        const checkPostalCodes = data.sales.data.filter((sale) => {
          const country = sale.country
          const zipCode = sale.postalCode
          const regexObjectToCompare = postalCodeRegex.postCodeRegex.find(
            (regex) => {
              return regex.territoryId === country
            }
          )

          const regex = new RegExp(regexObjectToCompare.text)
          let test = !regex.test(zipCode) // we only want postal codes with error, so when error (regex = false) we say is true to filter them 
          const selectedYYYYMM = `${dayjs(selectedDate).format(DATE_FORMAT).split('/')[2]}${dayjs(selectedDate).format(DATE_FORMAT).split('/')[1]}`
          // The spanish postal codes new limit (>00999) only applies for reportings from 03/2022 onwards.
          if (selectedYYYYMM >= BI_POSTAL_CODE_LIMIT_DATE) {
              if (regexObjectToCompare.territoryId === "ES" && BI_ID_PRO_PRE === parseInt(selectedTenant) && !test) {
                  const regex_bi = new RegExp(regexObjectToCompare.BI_novalid)
                  test = regex_bi.test(zipCode)
              }
          }
          return test
        })

        // update the state to show a notification on the cell
        // find the rows that are to be updated, and then replace with the new one
        const postalCodeErrorsIndex = []
        const newPostalCodeRows =
          firstPageState &&
          firstPageState.rows.map((row, index) => {
            // find row if it exists
            const newRow = checkPostalCodes.find((item) => {
              if (item.key === row.key) {
                postalCodeErrorsIndex.push(index)
              }
              return item.key === row.key
            })
            if (newRow) {
              return { ...row, hasPostalCodeError: true, hasError: true }
            } else {
              return { ...row, hasPostalCodeError: false }
            }
          })
        firstPageState = { ...firstPageState, rows: [...newPostalCodeRows] }
        // check the CIF is ok, will ignore the cif if it is from a country other than spain
        const checkCIF = data.sales.data.filter((sale) => {
          let identification = sale.cifClient.toString()

          // check if the county is ES, if it is not, it will ignore the CIF
          if (sale.country !== 'ES') {
            return false
          }
          // check if the ID given is a VAT (VAT = ES + CIF ),  if it is , it will remove the ES
          const regexCheckingBeggining = new RegExp('^[Ee][Ss]')

          if (regexCheckingBeggining.test(identification)) {
            identification = identification.slice(2)
          }
          const isValidId = validateSpanishId(identification)

          return !isValidId
        })

        // update the state to show a notification on the cell
        // find the rows that are to be updated, and then replace with the new one
        const cifErrorsIndex = []
        const newCifRows =
          firstPageState &&
          firstPageState.rows.map((row, index) => {
            // find row if it exists
            const newRow = checkCIF.find((item) => {
              if (item.key === row.key) {
                cifErrorsIndex.push(index)
              }
              return item.key === row.key
            })
            if (newRow) {
              return { ...row, hasCifError: true, hasError: true }
            } else {
              return { ...row, hasCifError: false }
            }
          })
        firstPageState = { ...firstPageState, rows: [...newCifRows] }

        // check whehter the client's category is ok
        const regexPattern = /[^A-Za-z0-9]/g // only alphabetic chars and numbers
        const checkCategories = data.sales.data.filter((sale) => {
          let clientCategory = false
          if (sale.clientCategory) {
            clientCategory = clientsCategories.find(clientCategory => clientCategory.categorycode === sale.clientCategory.toString().replace(regexPattern, "").toUpperCase() && clientCategory.active)
          }
          return clientCategory !== undefined ? false : true
        })

        // find the rows that are to be updated, and then replace with the new one
        const clientsCategoriesErrorsIndex = []
        const newClientsCategoriesRows =
          firstPageState &&
          firstPageState.rows.map((row, index) => {
            // find row if it exists
            const newRow = checkCategories.find((item) => {
              if (item.key === row.key) {
                clientsCategoriesErrorsIndex.push(index)
              }
              return item.key === row.key
            })
            if (newRow) {
              const isCategoryNameInactive = clientsCategories.find(clientCategory => clientCategory.categorycode === newRow.clientCategory.toString().replace(regexPattern, "").toUpperCase())
              return { ...row, hasCategoryNameError: true, isCategoryNameInactive: isCategoryNameInactive === undefined ? false : true, hasError: true }
            } else {
              return { ...row, hasCategoryNameError: false, isCategoryNameInactive: false }
            }
          })
        firstPageState = { ...firstPageState, rows: [...newClientsCategoriesRows] }
    

        // check if the units are ok, they have to be integers
        const checkFirstPageUnits = data.sales.data.filter((sale) => {
          const units = Number(sale.units)
          return !Number.isInteger(units)
        })

        const checkSecondPageUnits = data.stock.data.filter((stock) => {
          const units = Number(stock.units)
          return !Number.isInteger(units)
        })
        // find the rows that are to be updated, and then replace with the new one
        // push all of the indexes where there are errors
        const saleUnitErrorsIndex = []
        const newSalesUnitsRows =
          firstPageState &&
          firstPageState.rows.map((row, index) => {
            // find row if it exists
            const newRow = checkFirstPageUnits.find((item) => {
              if (item.key === row.key) {
                saleUnitErrorsIndex.push(index)
              }
              return item.key === row.key
            })
            if (newRow) {
              return { ...row, hasUnitsError: true, hasError: true }
            } else {
              return { ...row, hasUnitsError: false }
            }
          })
        firstPageState = { ...firstPageState, rows: [...newSalesUnitsRows] }

        // find the rows that are to be updated, and then replace with the new one
        const stockUnitErrorsIndex = []
        const newStocksUnitsRows =
          secondPageState &&
          secondPageState.rows.map((row, index) => {
            // find row if it exists
            const newRow = checkSecondPageUnits.find((item) => {
              if (item.key === row.key) {
                stockUnitErrorsIndex.push(index)
              }
              return item.key === row.key
            })
            if (newRow) {
              return { ...row, hasUnitsError: true, hasError: true }
            } else {
              return { ...row, hasUnitsError: false }
            }
          })
        secondPageState = { ...secondPageState, rows: [...newStocksUnitsRows] }
        // Check in the first page and the second page whether there is an inconsistency between multiple product codes and their product name
        const findDuplicates = (arr) => {
          const sortedArray = arr.slice().sort((a, b) => {
            if (!a.productDistributorCode || !b.productDistributorCode) {
              return -1
            }
            if (a.productDistributorCode.toString() < b.productDistributorCode.toString()) {
              return -1
            }
            if (a.productDistributorCode.toString() > b.productDistributorCode.toString()) {
              return 1
            }
            return 0
          }) // You can define the comparing function here.
          // JS by default uses a crappy string compare.
          // (we use slice to clone the array so the
          // original array won't be modified)
          const results = []
          for (let i = 0; i < sortedArray.length; i++) {
            // If the product distributor code is the same, it will check the product names if they are different then it will be added to results
            // we will check if it is in the results page, if it is not, then we will add it
            // If it is the last number, compare with the one below
            const sortedArrayToCompareWith =
              i === sortedArray.length - 1 ? sortedArray[i - 1] : sortedArray[i + 1]
              if (!sortedArray[i].distributorProductName) {
                results.push(sortedArray[i])
              }
            if (sortedArray[i] && sortedArray[i + 1]) {
              if (sortedArray[i + 1].productDistributorCode && sortedArray[i].productDistributorCode) {
                if (sortedArrayToCompareWith.productDistributorCode.toString() === sortedArray[i].productDistributorCode.toString()) {
                  if (sortedArrayToCompareWith.distributorProductName !== sortedArray[i].distributorProductName) {
                    const duplicateResult = results.find((element) =>
                        element.key.toString() === sortedArray[i].key.toString()
                    )
                    if (!duplicateResult) {
                      results.push(sortedArray[i])
                    }
                  }
                }
              }
            }
          }
          return results
        }

        const duplicateSaleDistributorCodes = findDuplicates(
          firstPageState.rows
        )
        const duplicateStockDistributorCodes = findDuplicates(
          secondPageState.rows
        )

        const saleDistributorProductNameErrorsIndex = []

        // find the rows that are to be updated, and then replace with the new one
        // push all of the indexes where there are errors
        const newSalesDistributorProductNameRows =
          firstPageState &&
          firstPageState.rows &&
          firstPageState.rows.map((row, index) => {
            // find row if it exists
            const newRow = duplicateSaleDistributorCodes.find((item) => {
              if (item && item.productDistributorCode && row.productDistributorCode) {
                if (item.productDistributorCode.toString() === row.productDistributorCode.toString() || !item.productDistributorCode) {
                  saleDistributorProductNameErrorsIndex.push(index)
                }
                return item.productDistributorCode === row.productDistributorCode
              }
            })

            if (newRow) {
              return {
                ...row,
                hasDistributorProductNameError: true,
                hasError: true
              }
            }
            return { ...row, hasDistributorProductNameError: false }
          })

        firstPageState = {
          ...firstPageState,
          rows: [...newSalesDistributorProductNameRows]
        }

        const stockDistributorProductNameErrorsIndex = []

        // find the rows that are to be updated, and then replace with the new one
        // push all of the indexes where there are errors
        const newStocksDistributorProductNameRows =
          secondPageState &&
          secondPageState.rows &&
          secondPageState.rows.map((row, index) => {
            // find row if it exists
            const newRow = duplicateStockDistributorCodes.find((item) => {
              if (item && item.productDistributorCode && row.productDistributorCode) {
                if (item.productDistributorCode.toString() === row.productDistributorCode.toString() || !item.productDistributorCode) {
                  saleDistributorProductNameErrorsIndex.push(index)
                }
                return item.productDistributorCode === row.productDistributorCode
              }
            })

            if (newRow) {
              return {
                ...row,
                hasDistributorProductNameError: true,
                hasError: true
              }
            }
              return { ...row, hasDistributorProductNameError: false }
          })

        secondPageState = {
          ...secondPageState,
          rows: [...newStocksDistributorProductNameRows]
        }

        // End the Check in the first page and the second page whether there is an inconsistency between multiple product codes and their product name

        setFirstPage(firstPageState)
        setSecondPage(secondPageState)

        const dividedSaledateErrorsIndex = divideArrayByFactor(saleDateErrorsIndex, 10, 0)
        const dividedStockDateErrorsIndex = divideArrayByFactor(stockDateErrorsIndex, 10, 0)
        const dividedPostalCodeErrorsIndex = divideArrayByFactor(postalCodeErrorsIndex, 10, 0)
        const dividedCifErrorsIndex = divideArrayByFactor(cifErrorsIndex, 10, 0)
        const dividedClientsCategoriesErrorsIndex = divideArrayByFactor(clientsCategoriesErrorsIndex, 10, 0)
        const dividedSaleUnitErrorsIndex = divideArrayByFactor(saleUnitErrorsIndex, 10, 0)
        const dividedStockUnitErrorsIndex = divideArrayByFactor(stockUnitErrorsIndex, 10, 0)

        const dividedSaleDistributorProductNameErrorsIndex = divideArrayByFactor(saleDistributorProductNameErrorsIndex, 10, 0)

        const dividedStockDistributorProductNameErrorsIndex = divideArrayByFactor(stockDistributorProductNameErrorsIndex, 10, 0)

        const dividedSaleProductCodeErrorsIndex = divideArrayByFactor(saleProductCodeErrorsIndex, 10, 0)

        const dividedStockProductCodeErrorsIndex = divideArrayByFactor(stockProductCodeErrorsIndex, 10, 0)

        const uniqueArrayPages = Array.from(
          new Set([
            ...dividedSaledateErrorsIndex,
            ...dividedStockDateErrorsIndex,
            ...dividedPostalCodeErrorsIndex,
            ...dividedCifErrorsIndex,
            ...dividedClientsCategoriesErrorsIndex,
            ...dividedSaleUnitErrorsIndex,
            ...dividedStockUnitErrorsIndex,
            ...dividedSaleDistributorProductNameErrorsIndex,
            ...dividedStockDistributorProductNameErrorsIndex,
            ...dividedSaleProductCodeErrorsIndex,
            ...dividedStockProductCodeErrorsIndex
          ])
        )

        // add one to get the pagination arrays
        const uniquePaginagionArrays = uniqueArrayPages.map((item) => {
          return Number(item) + 1
        })

        // this large ternary checks if there are any errors in the fields and then will show the error, this has been scraped for now.
        // The final confirm message is important as it will show the pages that there is an error
        if ((wrongStockDates && wrongStockDates.length) ||
            (wrongSaleDates && wrongSaleDates.length) ||
            (checkPostalCodes && checkPostalCodes.length) ||
            (checkCIF && checkCIF.length) ||
            (checkCategories && checkCategories.length) ||
            (checkFirstPageUnits && checkFirstPageUnits.length) ||
            (checkSecondPageUnits && checkSecondPageUnits.length) ||
            (duplicateSaleDistributorCodes && duplicateSaleDistributorCodes.length) ||
            (duplicateStockDistributorCodes && duplicateStockDistributorCodes.length) ||
            (saleProductCodeErrorsIndex && saleProductCodeErrorsIndex.length) ||
            (stockProductCodeErrorsIndex && stockProductCodeErrorsIndex.length)
           ){
              if (wrongStockDates && wrongStockDates.length) {
                setHasStockError(true)
              }
              if (wrongSaleDates && wrongSaleDates.length) {
                setHasSalesError(true)
              }
              if (checkPostalCodes && checkPostalCodes.length) {
                setHasSalesError(true)
              }
              if (checkCIF && checkCIF.length) {
                setHasSalesError(true)
              }
              if (checkCategories && checkCategories.length) {
                setHasSalesError(true)
              }
              if (checkFirstPageUnits && checkFirstPageUnits.length) {
                setHasSalesError(true)
              }
              if (checkSecondPageUnits && checkSecondPageUnits.length) {
                setHasStockError(true)
              }
              if (duplicateSaleDistributorCodes && duplicateSaleDistributorCodes.length) {
                setHasSalesError(true)
              }
              if (duplicateStockDistributorCodes && duplicateStockDistributorCodes.length) {
                setHasStockError(true)
              }
              if ((saleProductCodeErrorsIndex && saleProductCodeErrorsIndex.length)) {
                setHasSalesError(true)
              }
              if ((stockProductCodeErrorsIndex && stockProductCodeErrorsIndex.length)) {
                setHasStockError(true)
              }
              alert.show(t('Existen errores en ventas o inventario'), {
                type: 'error'
              })
              setIsLoadingValidation(false)
              setHasValidDataToUpload(false)
              return
            }
        setHasStockError(false)
        setHasSalesError(false)
        showConfirmSubmit()
        setIsLoadingValidation(false)
        setHasValidDataToUpload(true)
    } catch (err) {
        setIsLoadingValidation(false)
        setHasValidDataToUpload(false)
        alert.show(t(err.message), {
          type: 'error'
        })
    }
  }

  const handleDownloadExcelClick = () => {
    setIsLoadingDownload(true)
    let firstSheet = []
    let secondSheet = []
    if (!firstPage.rows.length) {
      firstSheet.push({
        Fecha: null,
        CIFCliente: null,
        CodigoDistribuidor: null,
        NombreProducto: null,
        CodigoPostal: null,
        Unidades: null,
        CategoriaCliente: null,
        NombreCliente: null
      }) 
    } else {
      firstSheet = firstPage.rows.map((item) => {
        const {
          date,
          cifClient,
          productDistributorCode,
          distributorProductName,
          postalCode,
          units,
          clientCategory,
          clientName
        } = item

        return {
          Fecha: date,
          CIFCliente: cifClient,
          CodigoDistribuidor: productDistributorCode,
          NombreProducto: distributorProductName,
          CodigoPostal: postalCode,
          Unidades: units,
          CategoriaCliente: clientCategory,
          NombreCliente: clientName
        }
      })
    }

    if (!secondPage.rows.length) {
      secondSheet.push({
        Fecha: null,
        CodigoDistribuidor: null,
        NombreProducto: null,
        Unidades: null,
        NumeroLote: null
      }) 
    } else {
      secondSheet = secondPage.rows.map((item) => {
        const {
          date,
          productDistributorCode,
          distributorProductName,
          units
        } = item

        return {
          Fecha: date,
          CodigoDistribuidor: productDistributorCode,
          NombreProducto: distributorProductName,
          Unidades: units
        }
      })
    }
    jsonToExcelDownload(firstSheet, secondSheet, selectedDate)
    setIsLoadingDownload(false)
  }
  
  const handleDistributorSelect = (value) => {
    setHasStockError(false)
    setHasSalesError(false)
    setFixDescriptions(false)
    setNewProdNames([])
    setShowSalesDescriptionsMessage(false)
    setShowStocksDescriptionsMessage(false)
    setFileList([])
    if (value) {
        setVisiblePage(true)
        setSelectedDistributor(value && `${value.value}`)
        setSelectedDistributorIdentity(value && value.label.split(' ·')[0])
        if (selectedTenant && selectedDate) {
          setSelectedDate(selectedDate)
          setSelectedTenant(selectedTenant)
          setSelectedTenantName(selectedTenantName)
          setSelectedTenantIdentity(selectedTenantIdentity)
          if (!isHidden) sethasSelectionChanged(!hasSelectionChanged)
        }
    } else {
        setVisiblePage(false)
        setSelectedDistributor(null)
        setSelectedDistributorIdentity('')
    }
  }

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Button type='danger' 
                onClick={e => showConfirm("both")} 
                loading={isLoadingDelete} 
                disabled={isDataBlocked || originalFirstPage === 0 || originalSecondPage === 0}>
          {!isLoadingDelete ? <Icon type='delete' /> : null}
          {t('Click to delete Excel File')}
        </Button>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1">
        <Button type='danger' 
                onClick={e => showConfirm("sales")} 
                loading={isLoadingDelete} 
                disabled={isDataBlocked || originalFirstPage === 0}>
          {!isLoadingDelete ? <Icon type='delete' /> : null}
          {t('Click to delete SALES File')}
        </Button>
      </Menu.Item>
      <Menu.Item key="3">
        <Button type='danger' 
                onClick={e => showConfirm("stocks")} 
                loading={isLoadingDelete} 
                disabled={isDataBlocked || originalSecondPage === 0}>
          {!isLoadingDelete ? <Icon type='delete' /> : null}
          {t('Click to delete STOCKS File')}
        </Button>
      </Menu.Item>
    </Menu>
  )

  const updateDescriptions = async () => {
    console.log("newProdNames: ", newProdNames)
    try {
      const isItATenant = selectedDistributorIdentity === selectedTenantIdentity ? true : false
      const areDistProdNamesUpdated = await logic.updateDistProdNames(selectedTenant.toString(), newProdNames, selectedDistributor.toString(), isItATenant)
      if (originalFirstPage === 0 && originalSecondPage !== 0) setShowStocksDescriptionsMessage(true) 
      if (originalSecondPage === 0 && originalFirstPage !== 0) setShowSalesDescriptionsMessage(true)  
      setFixDescriptions(false)
      setNewProdNames([])
      await handleValidateDataToUpload()
      console.log("areDistProdNamesUpdated: ", areDistProdNamesUpdated)
    } catch (err) {
      alert.show(t('errorsupdatingprods'), {
        type: 'error'
      })
    }
  }

  return (
    <>
      <DistributorSelector onDistributorSelect={(value) => handleDistributorSelect(value)}></DistributorSelector>
      {visiblePage && <MonthPicker distributorId={selectedDistributor} doYearMonthPick={handleDoYearMonthPick} />}
      <div style={isHidden ? { display: 'none' } : { display: 'block' }}>
        <br></br>
        <Row gutter={16}>
          <Col span={8}>
            <a
              href={`${logic.url}/template/excel/${TableOfTables('PVL', selectedTenant, allTableOfTables, true)}`}
              target='_blank'
              rel='noopener noreferrer'
              download
            >
              <Icon type='download' /> {t('Sample excel sheet')}
            </a>
          </Col>
          <Col
            span={4}
            align='right'
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            {firstPage.rows.length > 0 && (
              <>
              </>
            )}
          </Col>
          <Col span={4}>
            <Button onClick={showConfirmChangeDescriptions} color='light' size='medium' disabled={!fixDescriptions} type='danger'>
              <Icon>
                <FontAwesomeIcon icon={faDiagnoses} />
              </Icon>
              {t('changeProdDesc')}
            </Button>
          </Col>
        </Row>
        <br></br>
        {confirmAction.showMessage && <FormError {...confirmAction} />}
        {confirmAction2.showMessage && <FormError {...confirmAction2} />}
        {confirmAction3.showMessage && <FormError {...confirmAction3} />}
        {confirmAction4.showMessage && <FormError {...confirmAction4} />}
        {confirmAction5.showMessage && <FormError {...confirmAction5} />}
        {confirmAction6.showMessage && <FormError {...confirmAction6} />}
        {confirmAction8.showMessage && <FormError {...confirmAction8} />}
        {confirmAction7.showMessage && <FormError {...confirmAction7} />}
        <div>
          <Row>
            <Col span={8}>
              {originalFirstPage === 0 && originalSecondPage === 0 ? (
                <Upload
                  name='file'
                  onChange={handleFileListChange}
                  fileList={fileList}
                  beforeUpload={fileHandler}
                  onRemove={() => {
                    setHasSalesError(false)
                    setHasStockError(false)
                    setFirstPage((prevState) => {
                      return { ...prevState, rows: [] }
                    })
                    setSecondPage((prevState) => {
                      return { ...prevState, rows: [] }
                    })
                  }}
                  multiple={false}
                  loading={isLoadingPage || isLoadingUpload}
                >
                  <Tooltip title={isDataBlocked ? t('blockByTenant') : null}>
                    <Button loading={isLoadingPage || isLoadingUpload} disabled={isLoadingValidation || isDataBlocked}>
                      {!isLoadingPage && !isLoadingUpload ? <Icon type='upload' /> : null}
                      {t('Click to Upload Excel File')}
                    </Button>
                  </Tooltip>
                </Upload>
              ) : null }
              {originalSecondPage === 0 && originalFirstPage !== 0 ? (
                <Upload
                  name='file'
                  onChange={handleFileListChange}
                  fileList={fileList}
                  beforeUpload={fileHandler}
                  onRemove={() => {
                    setHasStockError(false)
                    setSecondPage((prevState) => {
                      return { ...prevState, rows: [] }
                    })
                    setHasRegistries(true)
                  }}
                  multiple={false}
                  loading={isLoadingPage || isLoadingUpload}
                >
                  <Tooltip title={isDataBlocked ? t('blockByTenant') : null}>
                    <Button loading={isLoadingPage || isLoadingUpload} disabled={isLoadingValidation || isDataBlocked}>
                      {!isLoadingPage && !isLoadingUpload ? <Icon type='upload' /> : null}
                      {t('Click to Upload Excel File (pending stocks)')}
                    </Button>
                  </Tooltip>
                </Upload>
              ) : null }
              {originalFirstPage === 0 && originalSecondPage !== 0 ? (
                 <Upload
                  name='file'
                  onChange={handleFileListChange}
                  fileList={fileList}
                  beforeUpload={fileHandler}
                  onRemove={() => {
                    setHasSalesError(false)
                    setFirstPage((prevState) => {
                      return { ...prevState, rows: [] }
                    })
                    setHasRegistries(true)
                  }}
                  multiple={false}
                  loading={isLoadingPage || isLoadingUpload}
                >
                  <Tooltip title={isDataBlocked ? t('blockByTenant') : null}>
                    <Button loading={isLoadingPage || isLoadingUpload} disabled={isLoadingValidation || isDataBlocked}>
                      {!isLoadingPage && !isLoadingUpload ? <Icon type='upload' /> : null}
                      {t('Click to Upload Excel File (pending sales)')}
                    </Button>
                  </Tooltip>
                </Upload>
              ) : null }
              {hasRegistries && (originalFirstPage === 0 || originalSecondPage === 0) ? 
                <br></br> : null }
              {hasRegistries ? (
                <Dropdown overlay={menu} trigger={['click']} loading={isLoadingDelete} disabled={isDataBlocked}>
                  <Tooltip title={isDataBlocked ? t('blockByTenant') : null}>
                    <Button type='danger' loading={isLoadingDelete} disabled={isDataBlocked}>
                      {!isLoadingDelete ? <Icon type='delete' /> : null}
                      {t('Select file(s) to delete')}
                    </Button>
                  </Tooltip>
                </Dropdown>
              ) : null}
            </Col>
            <Col span={4}>
              {(firstPage.rows.length || secondPage.rows.length) &&
              !hasRegistries &&
              !hasValidDataToUpload ? (
                <Button
                  onClick={handleValidateDataToUpload}
                  type='primary'
                  style={{ marginBottom: 16, marginLeft: 10 }}
                  loading={isLoadingValidation}
                >
                  {' '}
              {t('Check Data')}
              </Button>
              ) : null}
              {(firstPage.rows.length  || secondPage.rows.length) &&
              !hasRegistries &&
              hasValidDataToUpload ? (
                <Button
                  onClick={showConfirmSubmit}
                  type='primary'
                  style={{ marginBottom: 16, marginLeft: 10 }}
                  loading={isLoadingValidation}
                >
                  {!isLoadingValidation ? <Icon type='cloud-upload' /> : null}
                  {isLoadingValidation ? `${t('Enviando datos...')} ${Math.trunc(theProgress / 60).toString().padStart(2, '0')}:${Math.trunc(theProgress % 60).toString().padStart(2, '0')} min` : t('Submit Data')}
                </Button>
              ) : null}
              {hasRegistries ? (
                <Button
                  onClick={handleDownloadExcelClick}
                  style={{
                    marginBottom: 16,
                    marginLeft: 10,
                    backgroundColor: 'mediumseagreen',
                    color: 'white'
                  }}
                  loading={isLoadingDownload}
                >
                  {!isLoadingDownload ? <Icon type='download' /> : null} 
                  {t('download')}
                </Button>
              ) : null}
            </Col>
            <Col span={4}>
              {(firstPage.rows.length || secondPage.rows.length) &&
                !hasRegistries &&
                !hasValidDataToUpload ? (
                  <>
                    <Button type='link' onClick={showModal}>
                        <Icon type="info-circle" style={{ fontSize: '32px'}} theme="twoTone" />
                    </Button>
                    <Modal
                      title={t('salesAndStockValidations')}
                      visible={infoState}
                      onOk={handleOk}
                      onCancel={handleCancel}
                      width={1000}
                    >
                      {i18n.language === 'es'?
                        <>
                          <p>-	Fecha: </p>
                          <p>&nbsp;&nbsp;&nbsp;.	Formato: DD/MM/AAAA</p>
                          <p>&nbsp;&nbsp;&nbsp;.	El mes y el año deben <strong>coincidir</strong> con el mes y año de registro.</p>
                          <p>-	CIF del cliente: </p>
                          <p>&nbsp;&nbsp;&nbsp;.	Se <strong>validan</strong> los <strong>CIFs españoles.</strong></p>
                          <p>&nbsp;&nbsp;&nbsp;.	Si se <strong>desconoce</strong> el CIF o no se puede reportar indicar <strong>A00000000.</strong></p>
                          <p>-	Código de producto:</p>
                          <p>&nbsp;&nbsp;&nbsp;.	El código es <strong>único</strong> (no se puede repetir).</p>
                          <p>&nbsp;&nbsp;&nbsp;.	Si el código de producto <strong>no existe se creará.</strong></p>
                          <p>&nbsp;&nbsp;&nbsp;.	Si el código de producto existe <strong>se valida que sea para el fabricante</strong> al que se reporta.</p>
                          <p>-	Nombre del producto:</p>
                          <p>&nbsp;&nbsp;&nbsp;.	El <strong>nombre del producto</strong> debe ser <strong>el que tiene asignado el código.</strong></p>
                          <p>&nbsp;&nbsp;&nbsp;.	Si el nombre del producto para el código indicado <strong>no coincide</strong> con el nombre guardado <strong>no se permiten subir los datos.</strong></p>
                          <p>&nbsp;&nbsp;&nbsp;.	<strong>No</strong> se puede reportar para un mismo código de producto <strong>dos o más nombres diferentes.</strong> No se permitirán subir los datos.</p>
                          <p>&nbsp;&nbsp;&nbsp;.  <strong>Tras verificar datos</strong> puede <strong>ACTUALIZAR TODOS</strong> los nombres incorrectos <strong>pulsando</strong> en "Arreglar descripciones".</p>
                          <p>-	Código postal:</p>
                          <p>&nbsp;&nbsp;&nbsp;.	Se <strong>validan</strong> los códigos postales de Andorra, España, Gran Bretaña, Portugal, Francia y Países Bajos.</p>
                          <p>-	Unidades: debe ser un <strong>número entero</strong> (sin decimales).</p>
                          {isPVLContracted ? 
                            <>
                              <p>-	Categoría del cliente:</p>
                              <p>&nbsp;&nbsp;&nbsp;.	No es obligatoria.</p>
                              <p>&nbsp;&nbsp;&nbsp;.	Si tiene que informarla debe ser <strong>una de las categorías de cliente definidas por su fabricante</strong>.</p>
                              <p>-	Nombre del cliente: no es obligatorio.</p>
                            </>
                            :
                            null
                          }
                          <p>-	Número de lote: no es obligatorio.</p>
                          <p>-	País: </p>
                          <p>&nbsp;&nbsp;&nbsp;.	Por defecto España, pero <strong>se puede cambiar.</strong> El cambio <strong>afecta a la validación del código postal.</strong></p>
                          <br></br>
                          <p>Nota: recuerde además <u>utilizar la plantilla adecuada</u>. Puede descargarla en la pantalla de reporte arriba a la izquierda cuando cierre este modal.</p>
                        </>
                      :
                       <p>
                          <p>-	Date: </p>
                          <p>&nbsp;&nbsp;&nbsp;.	Format: DD/MM/YYYY</p>
                          <p>&nbsp;&nbsp;&nbsp;.	Month and year must <strong>match</strong> the registry month and year.</p>
                          <p>-	Client's CIF: </p>
                          <p>&nbsp;&nbsp;&nbsp;.	<strong>Spanish's CIFs</strong> are <strong>valited.</strong></p>
                          <p>&nbsp;&nbsp;&nbsp;.	Report <stroong>A00000000</stroong> in case that the CIF is <strong>unknown</strong> or can't be reported</p>
                          <p>-	Product code:</p>
                          <p>&nbsp;&nbsp;&nbsp;.	The code is <strong>unique</strong> (can't be repeated).</p>
                          <p>&nbsp;&nbsp;&nbsp;.	When the product's code <strong>doens't exist will be created.</strong></p>
                          <p>&nbsp;&nbsp;&nbsp;.  When the product's code exist <strong>is validated that is for the tenant</strong> to which we are reporting.</p>
                          <p>-	Product name:</p>
                          <p>&nbsp;&nbsp;&nbsp;.	The <strong>product's name</strong> must be <strong>the one assigned to the code.</strong></p>
                          <p>&nbsp;&nbsp;&nbsp;.	When the product's name for the reported code <strong>doesn't exactly match</strong> the kept name <strong>data upload won't be allowed.</strong></p>
                          <p>&nbsp;&nbsp;&nbsp;.	<strong>Can't</strong> be reported for the same product's code <strong>two or more different names.</strong> Data upload won't be allowed.</p>
                          <p>&nbsp;&nbsp;&nbsp;.  <strong>After checking data</strong> you can <strong>UPDATE ALL</strong> wrong names <strong>clicking</strong> on "Fix descriptions".</p>
                          <p>-	Postal code:</p>
                          <p>&nbsp;&nbsp;&nbsp;.	Andorra, Spain, Great Britain, Portugal, France and Netherland's postal codes are <strong>validated.</strong></p>
                          <p>-	Units: must be an <strong>integer number</strong> (without decimals).</p>
                          {isPVLContracted ? 
                            <>
                              <p>-	Client category:</p>
                              <p>&nbsp;&nbsp;&nbsp;.	Not mandatory.</p>
                              <p>&nbsp;&nbsp;&nbsp;.	When you have to report it must be <strong>one of the clients' categories defined by your tenant</strong>.</p>
                              <p>-	Clients' name: not mandatory.</p>
                            </>
                            :
                            null
                          }
                          <p>-	Set number: not mandatory.</p>
                          <p>-	Country: </p>
                          <p>&nbsp;&nbsp;&nbsp;.	By default Spain but <strong>can be changed.</strong> The change <strong>affects the postal code validation.</strong></p>
                          <br></br>
                          <p>Note: remember to <u>use the appropiate template</u> as well. You can download it in the report screen up on the left once you close this modal.</p>
                       </p>
                      }
                      </Modal>
                  </>
              ) : null}
            </Col>
          </Row>
        </div>
        <div style={{ marginTop: 20 }}>
          <Tabs activeKey={defaultTab} onChange={callback} type='card'>
            <TabPane tab={hasSalesError ? <Badge count={<Icon type='exclamation-circle' theme='filled' style={{ color: '#f5222d', margin: '-6px' }} />}>  {t('sales')}</Badge> : showSalesDescriptionsMessage ? <Tooltip placement="topRight" title={t('descriptionsoutdatedwarning')}>{t('sales')}</Tooltip> : t('sales')} key='1'>
              <Table
                components={components1}
                rowClassName={() => 'editable-row'}
                dataSource={firstPage.rows}
                columns={hasRegistries ? firstPage.columns : newColumns1}
                loading={tableLoading}
              />
            </TabPane>
            <TabPane tab={hasStockError ? <Badge count={<Icon type='exclamation-circle' theme='filled' style={{ color: '#f5222d', margin: '-6px' }} />}>  {t('stock')}</Badge> : showStocksDescriptionsMessage ? <Tooltip placement="right" title={t('descriptionsoutdatedwarning')}>{t('stock')}</Tooltip> : t('stock')} key='2'>
              <Table
                components={components2}
                rowClassName={() => 'editable-row'}
                dataSource={secondPage.rows}
                columns={hasRegistries ? secondPage.columns : newColumns2}
                loading={tableLoading}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </>
  )
}