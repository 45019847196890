import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { logic } from '../../../../logic'
import { SelectContainer, Select } from 'rbx/elements/form/select'
import { useAlert } from 'react-alert'

export const SelectDistributors = (props) => {
  const { t } = useTranslation()

  const alert = useAlert()

  const [distributors, setDistributors] = useState()

  const [selectedDistributor, setSelectedDistributor] = useState()

  useEffect(() => {
    logic
      .getDistributors()
      .then(distributors => {
        const distributorTenantId = logic.distributorId
        let data = distributors && distributors.data
        data = data.filter(item => {
          return item.distributortenantid !== parseInt(distributorTenantId)
        })

        data = data && data.sort((a, b) => {
          if (a.distributorname < b.distributorname) {
            return -1
          }
          if (a.distributorname > b.distributorname) {
            return 1
          }
        })

        setDistributors(data)
      })
      .catch(err => {
        alert.show(t('Error buscando distribuidores'), {
          type: 'error'
        })
      })
  }, [])

  const handleDistributorSelect = event => {
    const { value } = event.target

    setSelectedDistributor(value)

    props.doSelectDistributor(value)
  }

  return (
    <SelectContainer>
      <Select
        name='distributors'
        onChange={handleDistributorSelect}
        value={selectedDistributor || ''}
      >
        <Select.Option value=''>{t('select')} {t('distributor')}</Select.Option>
        {distributors && distributors.map(distributor => {
          return (
            <Select.Option
              key={distributor && distributor.distributortenantid}
              value={distributor && distributor.distributortenantid}
            >{distributor && distributor.distributorcode} - {distributor && distributor.distributorname}
            </Select.Option>
          )
        })}
      </Select>
    </SelectContainer>
  )
}
