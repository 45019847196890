import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { logic } from '../../logic'
import { TabsCorrespondance } from './tabsCorrespondance'
import { useAlert } from 'react-alert'
import {
  Title,
  Level,
  Field,
  Icon,
  Select,
  Button,
  Table,
  Box,
  Control
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Pagination } from '../pagination'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useToggle } from '../../logic/hooks'
import { Search } from '../filter/Search'
import { Modal } from '../modal'
import { SelectDistributors } from './selectDistributors/SelectDistributors'
import { ActionButtons } from '../actionButtons'
import './Correspondance.scss'
import { jsonToExcelDownloadCorrespondances } from '../../logic/excelRenderer'

export const Correspondance = () => {
  const alert = useAlert()
  // Hooks for toggling on and off the modal
  const [open, toggle] = useToggle(false)

  const [deleteItem, setDeleteItem] = useState()

  // state inside the modal
  const [related, setRelated] = useState([])

  // main state
  const [products, setProducts] = useState()

  const [tenant, setTenant] = useState()

  const [nonCorrespondances, setNonCorrespondances] = useState(false)

  // products that are searched for
  const [searchProducts, setSearchProducts] = useState([])

  // i18n hook
  const { t } = useTranslation()

  // Routing Variables
  const { url } = useRouteMatch()
  const history = useHistory()

  // State that checks whether the download button is loading
  const [loading, setLoading] = useState('active')

  // State refering to the pagination attributes comming from the pagination component
  const defaultPaginationStartPosition = 0
  const defaultPaginationEndPosition = 10
  const [startPosition, setStartPosition] = useState(
    defaultPaginationStartPosition
  )
  const [endPosition, setEndPosition] = useState(defaultPaginationEndPosition)

  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const paginationRef = useRef()

  // Fetch products when component mounts
  useEffect(() => {
    logic
      .getUserTenant()
      .then(tenant => {
        setTenant(tenant && tenant.data && tenant.data[0])
      })
      .catch(err => {
        window.alert(err.message)
      })
    logic
      .getNonProductCorrespondance()
      .then(nonCorrespondances => {
        if (nonCorrespondances.status === 404) {
          setNonCorrespondances(false)
        } else if (nonCorrespondances.status === 200) {
          setNonCorrespondances(true)
        } else {
          throw Error('unexpected Error')
        }
      })
      .catch(err => {
        window.alert(err.message)
      })
  }, [])

  // handles the pagination requests
  const handleRequestPagination = requestPaginationObject => {
    let newStartPosition = defaultPaginationStartPosition
    let newEndPosition = defaultPaginationEndPosition
    if (requestPaginationObject) {
      const { currentPage, itemsPerPage } = requestPaginationObject
      newStartPosition = currentPage * itemsPerPage - itemsPerPage
      newEndPosition = newStartPosition + itemsPerPage
    }
    setStartPosition(newStartPosition)
    setEndPosition(newEndPosition)
  }

  // handles routing to add/edit
  const handleAddEditClick = (event, element) => {
    if (element) {
      history.push({
        pathname: `${url}/edit/${element && element.productdistributorid}/${element &&
          element.producttenantid}`,
        state: element
      })
    } else {
      history.push({
        pathname: `${url}/add`
      })
    }
  }

  // deletes the item
  const handleDeleteClick = (event, element) => {
    const { producttenantid: productTenantId, productdistributorid: productDistributorId } = element
    logic
      .deleteProductCorrespondance({ productTenantId: productTenantId.toString(), productDistributorId: productDistributorId.toString() })
      .then(item => {
        if (item.status !== 200) {
          throw Error('status error')
        }
        logic
          .getNonProductCorrespondance()
          .then(nonCorrespondances => {
            if (nonCorrespondances.status === 404) {
              setNonCorrespondances(false)
            } else if (nonCorrespondances.status === 200) {
              setNonCorrespondances(true)
            } else {
              throw Error('unexpected Error')
            }
          })

        const newProducts = products.filter(product => {
          return !(product.producttenantid === item.data[0].producttenantid && product.productdistributorid === item.data[0].productdistributorid)
        })

        const sortedNewProducts =
          newProducts &&
          newProducts.sort((a, b) => {
            if (a.tenantproductname < b.tenantproductname) {
              return -1
            }
            if (a.tenantproductname > b.tenantproductname) {
              return 1
            }
          })
        setProducts(sortedNewProducts)
        setSearchProducts(sortedNewProducts)
        toggle()
      })
      .catch(err => {
        console.error(err.message)
      })
      .catch(() => {
        toggle()
      })
  }

  const showRelatedItems = (event, item) => {
    setDeleteItem({ ...item })

    // TODO set correct logic for related
    setRelated([])
    toggle()
  }

  const handleDoSearch = value => {
    const result = products && products.filter(product => {
      const values = Object.values(product)

      const isIncluded = values
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
      // pagination Reset vallues
      paginationRef.current.resetValues()

      return isIncluded
    }) || []
    const sortedResult = result.sort((a, b) => {
      if (a.tenantproductname < b.tenantproductname) {
        return -1
      }
      if (a.tenantproductname > b.tenantproductname) {
        return 1
      }
    })
    setSearchProducts(sortedResult)
    return sortedResult
  }

  const handleSelectDistributor = value => {
    if (value) {
      logic
        .getProductCorrespondance({ distributorId: value })
        .then(correspondances => {
          const sortedCorrespondances =
            correspondances &&
            correspondances.data &&
            correspondances.data.sort((a, b) => {
              if (a.tenantproductname < b.tenantproductname) {
                return -1
              }
              if (a.tenantproductname > b.tenantproductname) {
                return 1
              }
            })
          setProducts(sortedCorrespondances)
          setSearchProducts(sortedCorrespondances)
        })
    }
  }

  const handleDownloadCorrespondances = () => {
    setLoading('loading')
    logic.getAllProductCorrespondance().then(result => {
     const newArray =
      result.data = result.data.map(item => {
        const correctCoeficient = item.coeficiente.toString().replace('.', ',')
        const result = {...item, coeficiente: correctCoeficient}
        return result
      })
        return jsonToExcelDownloadCorrespondances(result && result.data, 'Correspondencias') 
    })
    .then(()=> {
      setLoading('active')
    })
    .catch(()=> {
      setLoading('active')
      alert.show('Error al descargar', {
        type: 'error'
      })
    })
  }

  return (
    <Box id='correspondance'>
      {open && (
        <Modal toggle={toggle} open={open}>
          <Title>{t('confirmDelete')}</Title>
          {related && related.length !== 0 && (
            <Title subtitle size={6}>
              {t('cannotDeleteRelated')}
            </Title>
          )}
          {related &&
            related.length !== 0 &&
            related.length > 7 &&
            related.slice(0, 7).map((item, index) => {
              return (
                <>
                  <Field key={index}>{item.productname}</Field>
                </>
              )
            })}
          {related &&
            related.length !== 0 &&
            related.length <= 7 &&
            related.map((item, index) => {
              return (
                <>
                  <Field key={index}>{item.productname}</Field>
                </>
              )
            })}
          {related && related.length !== 0 && related.length > 7 && (
            <Field>
              ...{t('and')} {related.length - 7} {t('others')}
            </Field>
          )}
          {related && related.length === 0 && (
            <>
              <Title subtitle>{t('deleteSure')}</Title>
              <Button
                onClick={e => handleDeleteClick(e, deleteItem)}
                color='danger'
              >
                {t('delete')}
              </Button>
            </>
          )}
        </Modal>
      )}
      <TabsCorrespondance
        isPending={nonCorrespondances}
        highlight='correspondance'
      />
      <Level>
        <Level.Item align='left'>
      <SelectDistributors doSelectDistributor={handleSelectDistributor} />
          
        </Level.Item>
        <Level.Item>
          <Search doSearch={handleDoSearch} />
        </Level.Item>
        <Level.Item align='right'>
        <Button
                  onClick={handleDownloadCorrespondances}
                  style={{
                    marginLeft: 10,
                    backgroundColor: '#538cc6',
                    color: 'white'
                  }}
                 state={loading}
                >
                  <Icon type='download' /> {t('Descargar todas las correspondencias')}
                </Button>
        </Level.Item>
      </Level>
      <Table hoverable bordered striped narrow fullwidth>
        <Table.Head>
          <Table.Row>
            <Table.Heading className='tenant' colSpan='2'>
              {tenant && tenant.tenantname}
            </Table.Heading>
            <Table.Heading />
            <Table.Heading className='distributor' colSpan='3'>{t('distributor')}</Table.Heading>
            <Table.Heading colSpan='3'>{t('action')}</Table.Heading>
          </Table.Row>
        </Table.Head>
        <Table.Head>
          <Table.Row>
            <Table.Heading className='tenant'>{t('code')}</Table.Heading>
            <Table.Heading className='tenant'>{t('name')}</Table.Heading>
            <Table.Heading />
            <Table.Heading className='distributor'>{t('code')}</Table.Heading>
            <Table.Heading className='distributor'>{t('name')}</Table.Heading>
            <Table.Heading>{t('correspondanceFactor')}</Table.Heading>
            <Table.Heading />
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {searchProducts &&
            searchProducts
              .slice(startPosition, endPosition)
              .map((item, index) => {
                return (
                  <Table.Row key={item.producttenantcode + index}>
                    <Table.Heading className='tenant'>{item.producttenantcode}</Table.Heading>
                    <Table.Cell className='tenant'>{item.tenantproductname}</Table.Cell>
                    <Table.Cell />
                    <Table.Heading className='distributor'>{item.productdistributorcode}</Table.Heading>
                    <Table.Cell className='distributor'>{item.distributorproductname}</Table.Cell>
                    <Table.Cell className='distributor'>{item.correspondancefactor}</Table.Cell>
                    <Table.Cell>
                      <ActionButtons
                        onEditClick={e => handleAddEditClick(e, item)}
                        onDeleteClick={e => showRelatedItems(e, item)}
                      />
                    </Table.Cell>
                  </Table.Row>
                )
              })}
          {!searchProducts && (
            <Table.Row>
              <Table.Heading colSpan='4'>{t('noDataAvailable')}</Table.Heading>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <Pagination
        totalItems={searchProducts && searchProducts.length}
        ref={paginationRef}
        requestPagination={handleRequestPagination}
      />
    </Box>
  )
}
