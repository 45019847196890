import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import { useTranslation } from 'react-i18next'
import { logic } from '../../logic'
import { Box, Field, Label, Input, Control, Icon, Textarea, Button } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from 'react-hook-form'
import { FormError, HelpError } from '../error'
import { useConfirmActionMessage } from '../../logic/hooks'
import { Link } from 'react-router-dom'
import { Role } from '../../config/role'
export const Support = () => {
  const { t } = useTranslation()
  const alert = useAlert()

  const [user, setUser] = useState()

  const [companyName, setCompanyName] = useState()

  const [companyId, setCompanyId] = useState()
  const confirmAction = useConfirmActionMessage()

  useEffect(() => {
    async function getUserTenantAndDistributorDetails () {
      try {
        const roleName = logic._roleName()
        if (roleName === Role.Tenant) {
          const tenantDetails = await logic.getUserTenant()
          if (tenantDetails.status !== 200) throw Error(tenantDetails.status)
          setCompanyName(tenantDetails && tenantDetails.data && tenantDetails.data[0] && tenantDetails.data[0].tenantname)
          setCompanyId(tenantDetails && tenantDetails.data && tenantDetails.data[0] && tenantDetails.data[0].tenantidentity)
        }

        if (roleName === Role.Distributor) {
          const distributorDetails = await logic.getOneDistributor({ distributorId: logic.distributorId })
          if (distributorDetails.status !== 200) throw Error(distributorDetails.status)
          setCompanyName(distributorDetails && distributorDetails.data && distributorDetails.data[0].distributorname)
          setCompanyId(distributorDetails && distributorDetails.data && distributorDetails.data[0].distributoridentity)
        }

        if (roleName === Role.Admin) {
          setCompanyName('Ambit')
          setCompanyId(logic.sessionId)
        }
      } catch (err) {
        alert.show(err.message, {
          type: 'error'
        })
      }
    }
    getUserTenantAndDistributorDetails()
  }, [])

  useEffect(() => {
    const sessionId = logic.sessionId.toString()
    logic.retrieveUser(sessionId)
      .then(user => {
        setUser(user && user.data && user.data[0])
      })
  }, [])
  const { register, handleSubmit, errors } = useForm()

  const onSubmit = data => {
    const {
      subject,
      description
    } = data

    logic.sendServiceDeskIssue({
      email: user.useremail,
      companyId,
      companyName,
      subject,
      description
    })
      .then(user => {
        if (user.status !== 200) {
          throw Error(`${user.status}`)
        }
        setUser(user && user.data && user.data[0])
        alert.show(t('incidenceSent'), {
          type: 'success'
        })
      })
      .catch(err => {
        if (err.message === '409') {
          alert.show(t('incorrectPassword'), {
            type: 'error'
          })
        } else {
          alert.show(t('unexpectedError'), {
            type: 'error'
          })
        }
      })
  }

  return (
    <div>
      {/* <Tabs /> */}
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Field>
            <Label>{t('subject')}</Label>
            <Control iconLeft>
              <Input
                type='text'
                color='light'
                name='subject'
                ref={register({
                  required: true,
                  maxLength: 80
                })}
              />
              <Icon size='small' align='left'>
                <FontAwesomeIcon icon={['fas', 'user']} />
              </Icon>
            </Control>
            {errors.subject && errors.subject.type === 'required' && (
              <HelpError message={t('required')} />
            )}
            {errors.subject && errors.subject.type === 'maxLength' && (
              <HelpError message={t('tooManyCharacters')} />
            )}
          </Field>
          <Field>
            <Label>{t('description')}</Label>
            <Control>
              <Textarea
                color='light'
                name='description'
                ref={register({
                  maxLength: 1000
                })}
                placeholder={t('description')}
              />
            </Control>
            {errors.description && errors.description.type === 'maxLength' && (
              <HelpError message={t('tooManyCharacters')} />
            )}
          </Field>

          <Field kind='group'>
            <Control>
              <Button color='link'>{t('Send')}</Button>
            </Control>
            <Control>
              <Button as={Link} to='/' type='button' text>{t('cancel')}</Button>
            </Control>
            {confirmAction.showMessage && <FormError {...confirmAction} />}
          </Field>
        </form>
      </Box>
    </div>
  )
}
