/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { logic } from '../../../logic'
import { TabsProduct } from '../tabsProduct'
import { Level, Box, Title, Field, Icon, Button, Table, Select } from 'rbx'
import { Modal } from '../../modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Pagination } from '../../pagination'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useToggle } from '../../../logic/hooks'
import { Search } from '../../filter/Search'
import { useAlert } from 'react-alert'

export const ProductSet = () => {
  // Hooks for toggling on and off the modal
  const [open, toggle] = useToggle(false)
  const [openInactive, toggleInactive] = useToggle(false)
  const [activateInactivateItem, setActivateInactivateItem] = useState()

  const [deleteItem, setDeleteItem] = useState()

  // state inside the modal
  const [related, setRelated] = useState([])

  // Main state, what the component will recieve in componentDidMount
  const [sets, setSets] = useState()

  // sets that are searched for. It is the state that will actualy be seen in the table
  const [searchSets, setSearchSets] = useState([])

  const alert = useAlert()

  const { t } = useTranslation()

  // Routing variables
  const history = useHistory()
  const { url } = useRouteMatch()

  // State refering to the pagination attributes comming from the pagination component
  const defaultPaginationStartPosition = 0
  const defaultPaginationEndPosition = 10
  const [startPosition, setStartPosition] = useState(defaultPaginationStartPosition)
  const [endPosition, setEndPosition] = useState(defaultPaginationEndPosition)
  // React active or inactive
  const [dropdownState, setDropdownState] = useState('active')

  // Fetch sets when component mounts
  useEffect(() => {
    async function fetchData() {
      await getProductSets()    
    }
    fetchData()
  }, [])

  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const paginationRef = useRef()

  // handles the pagination requests
  const handleRequestPagination = requestPaginationObject => {
    let newStartPosition = defaultPaginationStartPosition
    let newEndPosition = defaultPaginationEndPosition
    if (requestPaginationObject) {
      const { currentPage, itemsPerPage } = requestPaginationObject
      newStartPosition = (currentPage * itemsPerPage) - itemsPerPage
      newEndPosition = newStartPosition + itemsPerPage
    }
    setStartPosition(
      newStartPosition
    )
    setEndPosition(
      newEndPosition
    )
  }

  // handles routing to add/edit
  const handleAddEditClick = (event, element) => {
    if (element) {
      history.push({
        pathname: `${url}/edit/${element && element.productsetid}`,
        state: element
      })
    } else {
      history.push({
        pathname: `${url}/add`
      })
    }
  }

  // deletes the item
  const handleDeleteClick = (event, element) => {
    const id = `${element.productsetid}`
    logic.deleteProductSet({ productSetId: id })
      .then(setsWoDeletedOne => {
        if (setsWoDeletedOne.status !== 200) {
          throw Error(setsWoDeletedOne.error)
        }
        const newSets = sets.filter(set => set.productsetid !== setsWoDeletedOne.data[0].productsetid)
        alert.show(t('Conjunto eliminado'), { type: 'success' })
        setSets(newSets)
        setSearchSets(newSets)
        toggle()
      })
      .catch(err => {
        alert.show(t('errorinactivating')+`. ${err}`, { type: 'error' })
        toggle()
      })
  }

  const handleActivateItem = (event, element) => {
    const id = `${element.productsetid}`
    logic.activateProductSet({ productSetId: id })
      .then(setsWoActivatedOne => {
        if (setsWoActivatedOne.status !== 200) {
          throw Error(setsWoActivatedOne.error)
        }
        const newSets = sets.filter(set => set.productsetid !== setsWoActivatedOne.data[0].productsetid)
        alert.show(t('Conjunto activado'), { type: 'success' })
        setSets(newSets)
        setSearchSets(newSets)
        toggleInactive()
      })
      .catch(err => {
        alert.show(t('erroractivating')+`. ${err}`, { type: 'error' })
        console.error(err.message)
        toggleInactive()
      })
      
  }

  const showRelatedItems = (event, item) => {
    setActivateInactivateItem({ ...item })
    const productSetId = item.productsetid.toString()
    const userId = logic.sessionId.toString()
    logic.getRelatedProductSets({ productSetId, userId })
    .then(async items => {
      if (items.status !== 200) {
        throw Error(`${items.status}`)
      }
      await setRelated(items.data)
    })
    .then(() => {
      toggle()
    })
    .catch(err => {
      if (err.message === '409') {
              alert.show('Input already exists', { type: 'error' })
              } else {
              alert.show('Unexpected Error', { type: 'error' })
              }
        })
    .catch(() => {
      toggle()
    })
  }

  const showRelatedItemsInactive = (event, item) => {
    setActivateInactivateItem({ ...item })
    
    // TODO set correct logic for related
    setRelated([])
    toggleInactive()
  }

  const handleDoSearch = value => {
    const result = sets.filter(set => {
      const values = Object.values(set)

      const isIncluded = values
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(value.toLowerCase().replace(/\s+/g, ''))
      // pagination Reset vallues
      paginationRef.current.resetValues()

      return isIncluded
    })
    const sortedResult = result.sort((a, b) => {
      if (a.productsetname.toLowerCase() < b.productsetname.toLowerCase()) {
        return -1
      }
      if (a.productsetname.toLowerCase() > b.productsetname.toLowerCase()) {
        return 1
      }
      return 0
    })
    setSearchSets(sortedResult)
    return sortedResult
  }

  const handleDropdownStateChange = async (event) => {
    setDropdownState(event.target.value)
    const activeInactive = event.target.value
    await getProductSets(activeInactive) 
  }

  async function getProductSets(activeInactive = 'active') {
    try {
      const sessionId = logic.sessionId.toString()
      const sets = await logic.getProductSets(sessionId)
      let data = sets && sets.data
      data = data.filter(item => {
        return activeInactive === 'active' ? item.active : !item.active
      }) 
      const sortedSets =
        data.sort((a, b) => {
          if (a.productsetname.toLowerCase() < b.productsetname.toLowerCase()) {
            return -1
          }
          if (a.productsetname.toLowerCase() > b.productsetname.toLowerCase()) {
            return 1
          }
          return 0
        })
      setSets(sortedSets)
      setSearchSets(sortedSets)
    } catch (error) {
        alert.show('getsetserror', {
          type: 'error'
        })
    }
  }

  return (
    <Box>
      {open && 
        (<Modal toggle={toggle} open={open}>
          <Title>
            {t('confirmDelete')}
          </Title>
          {related && related.length !== 0 && (
                  <Title subtitle size={6}>
                      {t('cannotDeleteRelated')}
                  </Title>
                  )}
          {related && related.length !== 0 && related.length > 7 && related.slice(0, 7).map((item, index) => {
            return (<>
            <Field key={index}>
              {item.productName}
            </Field>
                    </>)
            })}
            {related && related.length !== 0 && related.length > 7 && (
              <Field>
                  ...{t('and')} {related.length - 7} {t('others')}
              </Field>
            )}
            {related && related.length !== 0 && related.length <= 7 && related.map((item, index) => {
            return (<>
            <Field key={index}>
              {item.productname}
            </Field>
                    </>)
            })}
            {related && related.length === 0 && (
              <>
              <Title subtitle>
                {t('deleteSure')}
              </Title>
              <Button onClick={e => handleDeleteClick(e, activateInactivateItem)} color='danger'>{t('delete')}</Button>
              </>
            )}
        </Modal>
      )}
       {openInactive && (
        <Modal toggle={toggleInactive} open={openInactive}>
          <Title>{t('confirmActivate')}</Title>
          {related && related.length !== 0 && (
            <Title subtitle size={6}>
              {t('cannotActivateRelated')}
            </Title>
          )}
          {related && related.length !== 0 && related.length > 7 && related.slice(0, 7).map((item, index) => {
            return (<>
              <Field key={index}>
                {item.productname}
              </Field>
                    </>)
          })}
          {related && related.length !== 0 && related.length <= 7 && related.map((item, index) => {
            return (<>
              <Field key={index}>
                {item.productname}
              </Field>
                    </>)
          })}
          {related && related.length !== 0 && related.length > 7 && (
            <Field>
              ...{t('and')} {related.length - 7} {t('others')}
            </Field>
          )}
          {related && related.length === 0 && (
            <>
              <Title subtitle>{t('activateSure')}</Title>
              <Button
                onClick={e => handleActivateItem(e, activateInactivateItem)}
                color='success'
              >
                {t('Activate')}
              </Button>
            </>
          )}
        </Modal>
      )}
      <TabsProduct highlight='sets' />
      <Level>
        <Select.Container>
          <Select value={dropdownState} onChange={handleDropdownStateChange} >
            <Select.Option value='active'>
              {t('Actives')}
            </Select.Option>
            <Select.Option value='inactive'>
              {t('Inactives')}
            </Select.Option>
          </Select>
        </Select.Container>
        <Level.Item>
          <Search doSearch={handleDoSearch} />
        </Level.Item>
        <Level.Item align='right'>
          <Button onClick={e => handleAddEditClick()} color='info'>
            <Icon>
              <FontAwesomeIcon size='lg' icon={['fas', 'plus']} />
            </Icon>
          </Button>
        </Level.Item>
      </Level>
      <Table fullwidth narrow hoverable bordered striped>
        <Table.Head>
          <Table.Row>
            <Table.Heading>
              {t('name')}
            </Table.Heading>
            <Table.Heading>
              {t('action')}
            </Table.Heading>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {searchSets && searchSets
            .slice(startPosition, endPosition).map(item => {
              return (
                <Table.Row onDoubleClick={e => handleAddEditClick(e, item)} key={item.productSetId}>
                  <Table.Cell>{item.productsetname}</Table.Cell>
                  <Table.Cell>
                    {item.productsetname !== 'DESCARTAR' &&
                      <button onClick={e => handleAddEditClick(e, item)} className='button is-primary is-outlined is-small'>
                        <Icon>
                          <FontAwesomeIcon size='lg' icon={['fas', 'edit']} />
                        </Icon>
                      </button>
                    }
                    {dropdownState === 'active' && item.productsetname !== 'DESCARTAR' && 
                      <button
                        onClick={e => showRelatedItems(e, item)}
                        className='button is-danger is-outlined is-small'
                      >
                        <Icon>
                          <FontAwesomeIcon size='lg' icon={['fas', 'trash']} />
                        </Icon>
                      </button>
                    }
                    {dropdownState === 'inactive' && item.productsetname !== 'DESCARTAR' && 
                      <button
                        onClick={e => showRelatedItemsInactive(e, item)}
                        className='button is-success is-outlined is-small'
                      >
                        <Icon>
                          <FontAwesomeIcon size='lg' icon={['fas', 'check-circle']} />
                        </Icon>
                      </button>
                    }
                  </Table.Cell>
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table>
      <Pagination totalItems={searchSets && searchSets.length} ref={paginationRef} requestPagination={handleRequestPagination} />
    </Box>
  )
}
