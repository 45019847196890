/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, useRef,useContext } from 'react'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Multiselect } from 'multiselect-react-dropdown'
import { useTranslation } from 'react-i18next'
import { useAlert } from 'react-alert'
import { logic } from '../../../logic'
import { Title, Level, Icon, Button, Table, Box, Select, Progress } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { Pagination } from '../../pagination'
import { TabsSalesStock } from '../TabsSalesStock'
import { arrayFilters } from '../../../utils/arrayFilters'
import { MONTHS_OFFSET } from '../../../config/config'
import { filtersMgmt } from '../filtersMgmt/filtersMgmt'
import './ClientsReport.scss'
import { Switch } from 'antd'
import { AdsContext } from "../../../contexts/adsContext"
import { TableOfTables } from '../../../logic/tableOfTables'

export const ClientsReport = () => {
  const alert = useAlert()
  const styleSelectorDistributors = {
    chips: { background: '#6699cc', 'word-wrap': 'break-word ' },
    searchBox: { 'border-radius': '2%', marginTop: '1px', marginBottom: '1px', maxHeight: '100px', overflow: 'auto'  },
    multiselectContainer: { width: '60vh', marginRight: '-10px', '-webkit-appearance': 'none', minHeight: '400px', overflow: 'auto' },
    inputField: {
      autocomplete: "off"
    },
    option: { // To change css for dropdown options
      'background-color': 'white',
      color: 'black'
    },
    groupHeading: {
      display: 'none'
    }
  }
  const styleSelectorFilter = {
    chips: { background: '#6699cc', width: 'auto' },
    searchBox: { width: '35vh', marginTop: '1px', marginBottom: '1px', marginRight: '6px', maxHeight: '100px', overflow: 'auto' },
    inputField: {
      autocomplete: "off"
    },
    multiselectContainer: { width: 'auto', 'max-width': '40%', marginRight: '-10px' },
    optionContainer: { // To change css for option container
      border: '2px solid',
      width: '30vh'

    },
    optionListContainer: { // To change css for option container
      border: '2px solid',
      width: '30vh',
      'z-index': '10'
    },
    option: {
      'background-color': 'white',
      color: 'black'

    }
  }

  // i18n hook
  const { t } = useTranslation()

  // main state
  const [regions, setRegions] = useState([])

  // date selector start
  const [dateStart, setDateStart] = useState(new Date())

  // date selector end
  const [dateEnd, setDateEnd] = useState(new Date())

  // distributors that are searched for
  const [searchSales, setSearchSales] = useState(undefined)

  // filter fields
  const [arrayRegion, setArrayRegion] = useState()
  const [arrayPopulation, setArrayPopulation] = useState()
  const [arrayPostalCode, setArrayPostalCode] = useState()
  const [arrayCIF, setArrayCIF] = useState()
  const [arrayBrand, setArrayBrand] = useState()
  const [arrayGroup, setArrayGroup] = useState()
  const [arrayBusinessUnit, setArrayBusinessUnit] = useState()
  const [arraySet, setArraySet] = useState()
  const [data, setData] = useState()

  const [selectedRegions, setSelectedRegions] = useState([t('Todas las regiones')])
  const [selectedPopulation, setSelectedPopulation] = useState([])
  const [selectedPostalCode, setSelectedPostalCode] = useState([])
  const [selectedCIF, setSelectedCIF] = useState([])
  const [selectedBrand, setSelectedBrand] = useState([])
  const [selectedGroup, setSelectedGroup] = useState([])
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState([])
  const [selectedSet, setSelectedSet] = useState([])

  // flag to indicate that the chech button is validating (loading)
  const [isLoadingValidation, setIsLoadingValidation] = useState('active')
  const [isLoadingValidationDownload, setIsLoadingValidationDownload] = useState('active')

  // default selected Values
  //const [selectedValues, setSelectedValues] = useState([t('Todas las regiones')])

  // State refering to the pagination attributes comming from the pagination component
  const defaultPaginationStartPosition = 0
  const defaultPaginationEndPosition = 10
  const [startPosition, setStartPosition] = useState(
    defaultPaginationStartPosition
  )
  const [endPosition, setEndPosition] = useState(defaultPaginationEndPosition)

  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const paginationRef = useRef()

  const populationRef = useRef()
  const postalCodeRef = useRef()
  const CIFRef = useRef()
  const groupRef = useRef()
  const businessUnitRef = useRef()
  const setRef = useRef()
  const brandRef = useRef()

  const [theProgress, setTheProgress] = useState()
  const [progressSeconds, setProgressSeconds] = useState()

  // Add year prices vars
  const [years, setYears] = useState()
  const [yearPrice, setYearPrice] = useState()
  const { allTableOfTables } = useContext(AdsContext)

  const isPVLContracted = TableOfTables('PVL', logic.tenantId.toString(), allTableOfTables, true)

  useEffect(() => {              
    if (progressSeconds !== 0) {
      const progress = setTimeout(() => { setTheProgress(theProgress + 1) }, 1000)
      return () => clearTimeout(progress)
    } else {
      setTheProgress(0)
    }
  }, [theProgress])

  // Fetch products when component mounts
  useEffect(() => {
    logic.getRegions().then(info => {
      const data = info && info.data
      const arrayRegion = data.map(({ region }) => region)
      for (let i = 0; i > arrayRegion.length; i++) {
        if (arrayRegion[i] === null) {
          arrayRegion[i] = 'desconocido'
        }
      }
      arrayRegion.unshift(t('Todas las regiones'))
      setArrayRegion(arrayRegion)
    })
  }, [])
  // set one month back
  useEffect(() => {
    const currentDate = new Date()
    currentDate.setMonth(currentDate.getMonth() - MONTHS_OFFSET)
    setDateStart(currentDate)
    setDateEnd(currentDate)
  }, [])


  // Fetch products when component mounts
  useEffect(() => {
      if (isPVLContracted) {
        logic.getProductPrices().then(prices => {
          const data = prices && prices.data
          const pricesYears = [...new Set(data.map(item => item.productpriceyear))]
          const pricesYearsFiltered = pricesYears.filter(item => {
            if (item !== null) return item
          }).sort()
          // The customer wants only the last prices available so we select the nearest year. 
          // In case in the future they wanted to value the sales with prices from other years you can change the next instruction by 
          // setYears(pricesYearsFiltered)
          // In the back-end the query has been adapted as well to work with any year calculating the values with the prices for the 
          // selected year or previous nearest years in case the product didn't have price for the selected year.
          setYears([pricesYearsFiltered[pricesYearsFiltered.length - 1]])
          if (pricesYearsFiltered) setYearPrice(pricesYearsFiltered[pricesYearsFiltered.length - 1])
      })
    }
  }, [])


  const handleChangeDateStart = date => {
    setDateStart(date)
  }
  const handleChangeDateEnd = date => {
    setDateEnd(date)
  }
  // region
  const handleSelectRegion = (selectedRegions, selectedItem) => {
    setSelectedRegions(selectedRegions)
  }
  const handleRemoveRegion = (selectedRegions, selectedItem) => {
    setSelectedRegions(selectedRegions)
  }

  // handles the pagination requests
  const handleRequestPagination = requestPaginationObject => {
    let newStartPosition = defaultPaginationStartPosition
    let newEndPosition = defaultPaginationEndPosition
    if (requestPaginationObject) {
      const { currentPage, itemsPerPage } = requestPaginationObject
      newStartPosition = currentPage * itemsPerPage - itemsPerPage
      newEndPosition = newStartPosition + itemsPerPage
    }
    setStartPosition(newStartPosition)
    setEndPosition(newEndPosition)
  }

  const calculateDiffTime = () => {
    let diffTime = 0
      if (dateEnd.getFullYear() > dateStart.getFullYear()) {
        if (dateEnd.getFullYear() > dateStart.getFullYear() + 1) {
          diffTime = 9999
        } else {
          diffTime = (dateEnd.getMonth() + 12) - dateStart.getMonth()
        }
      } else {
         diffTime = dateEnd.getMonth() - dateStart.getMonth()
      }
      return diffTime
  }

  const isDateRangeValid = () => {
    const pureDateEnd = `${dateEnd.getFullYear()}`+`${dateEnd.getMonth().toString().padStart(2, '0')}`
    const pureDateStart = `${dateStart.getFullYear()}`+`${dateStart.getMonth().toString().padStart(2, '0')}`
    if (pureDateEnd < pureDateStart) {
      return false
    }
    return true
  }

  const handleRetrieveClientsData = () => {
    if (!yearPrice && isPVLContracted) {
      return alert.show(t('Debe tener precios para generar este reporte'), {
        type:
          'error'
      })
    }
    if(!selectedRegions || (selectedRegions && !selectedRegions.length)){
      return alert.show('Por favor seleccione al menos una region', {
        type:
          'error'
      })
    }
    if (!isDateRangeValid()) {
      setIsLoadingValidation('active')
      return alert.show(t('fecha inicial debe ser anterior a fecha final'), {
        type:
          'error'
      })
    }
    if (calculateDiffTime() > 2) {
      setIsLoadingValidation('active')
      return alert.show(t('Franja de tiempo máxima de 3 meses excedida'), {
        type:
          'error'
      })
    }
    if (selectedRegions && dateEnd && dateStart) {
      setTheProgress(0)
      setProgressSeconds(1000)
      setIsLoadingValidation('loading')
      if (selectedRegions.includes(t('Todas las regiones'))) {
        logic
          .retrieveAllRegionsClientsReport({ dateStart, dateEnd, yearPrice, timeout: 600000 })
          .then(result => {
            let data = result && result.data
            data = data.map(row => {
              return { ...row, test: row.value !== null ? parseFloat(row.value.replace(',', '.')) : 0  }
            })
            let arrayPopulation = data.map(({ population }) => population)
            arrayPopulation = arrayFilters.arrayFilters(arrayPopulation)
            let arrayPostalCode = data.map(({ postalcode }) => postalcode)
            arrayPostalCode = arrayFilters.arrayFilters(arrayPostalCode)
            let arrayCIF = data.map(({ clientidentity }) => clientidentity)
            arrayCIF = arrayFilters.arrayFilters(arrayCIF)
            let arrayBrand = data.map(({ brand }) => brand)
            arrayBrand = arrayFilters.arrayFilters(arrayBrand)
            let arrayGroup = data.map(({ group }) => group)
            arrayGroup = arrayFilters.arrayFilters(arrayGroup)
            let arrayBusinessUnit = data.map(({ businessunit }) => businessunit)
            arrayBusinessUnit = arrayFilters.arrayFilters(arrayBusinessUnit)
            let arraySet = data.map(({ set }) => set)
            arraySet = arrayFilters.arrayFilters(arraySet)

            setArrayPopulation(arrayPopulation)
            setArrayPostalCode(arrayPostalCode)
            setArrayCIF(arrayCIF)
            setArrayBrand(arrayBrand)
            setArrayGroup(arrayGroup)
            setArrayBusinessUnit(arrayBusinessUnit)
            setArraySet(arraySet)
            setSearchSales(data)
            setData(data)
            setProgressSeconds()
            setTheProgress()
            if (data.length === 0 && result && result.message && !result.message.includes('Timeout')) {
              setSearchSales(undefined)
              alert.show(t('Datos no existentes'), { type: 'error' })
              setIsLoadingValidation('active')
            }
            if(result && result.message && result.message.includes('Timeout')) {
              alert.show(t('Timeout'), { type: 'info' })
              setIsLoadingValidation('active')
            }
            return data
          })
          .then(data => {
            if (data.length !== 0) {
              alert.show(t('report succesfully retrieved'), { type: 'success' })
              setIsLoadingValidation('active')
            }
          })
      } else {
          logic
            .retrieveClientsReport({ selectedRegions, dateStart, dateEnd, yearPrice, timeout: 600000 })
            .then(result => {
              const data = result && result.data
              let arrayPopulation = data.map(({ population }) => population)
              arrayPopulation = arrayFilters.arrayFilters(arrayPopulation)
              let arrayPostalCode = data.map(({ postalcode }) => postalcode)
              arrayPostalCode = arrayFilters.arrayFilters(arrayPostalCode)
              let arrayCIF = data.map(({ clientidentity }) => clientidentity)
              arrayCIF = arrayFilters.arrayFilters(arrayCIF)
              let arrayBrand = data.map(({ brand }) => brand)
              arrayBrand = arrayFilters.arrayFilters(arrayBrand)
              let arrayGroup = data.map(({ group }) => group)
              arrayGroup = arrayFilters.arrayFilters(arrayGroup)
              let arrayBusinessUnit = data.map(({ businessunit }) => businessunit)
              arrayBusinessUnit = arrayFilters.arrayFilters(arrayBusinessUnit)
              let arraySet = data.map(({ set }) => set)
              arraySet = arrayFilters.arrayFilters(arraySet)
              setArrayPopulation(arrayPopulation)
              setArrayPostalCode(arrayPostalCode)
              setArrayCIF(arrayCIF)
              setArrayBrand(arrayBrand)
              setArrayGroup(arrayGroup)
              setArrayBusinessUnit(arrayBusinessUnit)
              setArraySet(arraySet)
              setSearchSales(data)
              setData(data)
              setProgressSeconds()
              setTheProgress()
              if (data.length === 0 && result && result.message && !result.message.includes('Timeout')) {
                setSearchSales(undefined)
                alert.show(t('Datos no existentes'), { type: 'error' })
                setIsLoadingValidation('active')
              }
              if(result && result.message && result.message.includes('Timeout')) {
                alert.show(t('Timeout'), { type: 'info' })
                setIsLoadingValidation('active')
              }
              return data
            })
            .then(data => {
              if (data.length !== 0) {
                alert.show(t('report succesfully retrieved'), { type: 'success' })
                setIsLoadingValidation('active')
              }
            })
      }
    }
  }
  const handleDownloadData = () => {    
    if (!yearPrice && isPVLContracted) {
      return alert.show(t('Debe tener precios para generar este reporte'), {
        type:
          'error'
      })
    }
    if(!selectedRegions || (selectedRegions && !selectedRegions.length)){
      return alert.show('Por favor seleccione al menos una region', {
        type:
          'error'
      })
    }
    if (!isDateRangeValid()) {
      setIsLoadingValidationDownload('active')
      return alert.show(t('fecha inicial debe ser anterior a fecha final'), {
        type:
          'error'
      })
    }
    if (calculateDiffTime() > 2) {
      setIsLoadingValidationDownload('active')
      return alert.show(t('Franja de tiempo máxima de 3 meses excedida'), {
        type:
          'error'
      })
    }
    if (selectedRegions && dateEnd && dateStart) {  
        setIsLoadingValidationDownload('loading')
        setTheProgress(0)
        setProgressSeconds(1000)
        if (selectedRegions.includes(t('Todas las regiones'))) {
          logic
            .downloadAllRegionsClientsReport({ dateStart, dateEnd, yearPrice, isPVLContracted, timeout: 600000 })
            .then(result => {
              const data = result && result.data
              let a = document.createElement('a')
              a.href = `${logic.url}/${data.path}`
              // Give filename you wish to download
              a.download = data.name
              a.style.display = 'none'
              document.body.appendChild(a)
              a.click()
              document.body.removeChild(a)
              setProgressSeconds()
              setTheProgress()
              if(result && result.message && result.message.includes('Timeout')) {
                alert.show(t('Timeout'), { type: 'info' })
                setIsLoadingValidation('active')
              }
            })
            .then(() => {
              alert.show(t('report succesfully retrieved'), { type: 'success' })
              setIsLoadingValidationDownload('active')
            })
        } else {
          setIsLoadingValidationDownload('loading')
          logic
            .downloadClientsReport({ selectedRegions, dateStart, dateEnd, yearPrice, isPVLContracted, timeout: 600000 })
            .then(result => {
              const data = result && result.data
              let a = document.createElement('a')
              a.href = `${logic.url}/${data.path}`
              // Give filename you wish to download
              a.download = data.name
              a.style.display = 'none'
              document.body.appendChild(a)
              a.click()
              document.body.removeChild(a)
              setProgressSeconds()
              setTheProgress()
              if(result && result.message && result.message.includes('Timeout')) {
                alert.show(t('Timeout'), { type: 'info' })
                setIsLoadingValidation('active')
              }
            })
            .then(() => {
              alert.show(t('report succesfully retrieved'), { type: 'success' })
              setIsLoadingValidationDownload('active')
            })
      }
    }
  }

  const handleDownloadClientsData = () => {
    setIsLoadingValidationDownload('loading')
    if (searchSales) {
      const sheet1 = searchSales.map((item) => {
        const { clientidentity: clientIdentity, postalcode: postalCode, country, region, province, population, distributorproductname: DistributorProductName, providercode: Providercode, providerproductname: ProviderProductName, brand: Brand, businessunit: BusinessUnit, group: Group, set: Set, coeficient: Coeficient, units, rawdata: rawData, value: Value, date, distributorcode: DistributorCode, distributorcif: DistributorCIF } = item
        if (isPVLContracted) {
          return {
            ClientCIF: clientIdentity, postalCode: "=\""+postalCode + "\"", country: country, region: region, province: province, population: population, DistributorCode, DistributorCIF, DistributorProductName: DistributorProductName, Providercode: Providercode, ProviderProductName: ProviderProductName, Brand: Brand, BusinessUnit: BusinessUnit, Group: Group, Set: Set, Coeficient: Coeficient, Saleunits: units, RawData: rawData, Value, Saledate: date
          }
        } else {
          return {
            ClientCIF: clientIdentity, postalCode: "=\""+postalCode + "\"", country: country, region: region, province: province, population: population, DistributorCode, DistributorCIF, DistributorProductName: DistributorProductName, Providercode: Providercode, ProviderProductName: ProviderProductName, Brand: Brand, BusinessUnit: BusinessUnit, Group: Group, Set: Set, Coeficient: Coeficient, Saleunits: units, RawData: rawData, Saledate: date
          }
        }
      })
      if (calculateDiffTime() > 2) {
        setIsLoadingValidation('active')
        return alert.show(t('Franja de tiempo máxima de 3 meses excedida'), {
          type:
            'error'
        })
      }
      const name = `clientSalesReport`
      logic.downloadFilteredClientsReport(sheet1)
      .then(result => {
        const data = result && result.data
        let a = document.createElement('a')
        a.href = `${logic.url}/${data.path}`
        // Give filename you wish to download
        a.download = data.name
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      })
      .then( () => {
        setIsLoadingValidationDownload('active')
        alert.show(t('report succesfully retrieved'), { type: 'success' })
      })
    }
  }
  // filter selectors
  const filterSelectors = (selected = [], typeOfSelection = 'Empty', selectedItem = 'removed') => {
    let newFiltersOptionsAndSelection = {
      input: {
        typeOfSelection: typeOfSelection,
        selectedItem: selectedItem,
        selected: selected,
        allSelected: ['n/a', 'n/a', selectedPopulation, selectedPostalCode, 
          selectedBrand, selectedGroup, selectedBusinessUnit, selectedSet, selectedCIF, 'n/a'],
        data: data && [...data]  
      },
      output: {
        newSelections: [],
        newSearchSales: data && [...data],
        newArrays: []
      }
    }
    filtersMgmt(newFiltersOptionsAndSelection)
  
    let newSelectedPopulations = [...newFiltersOptionsAndSelection.output.newSelections[2]]
    let newSelectedPostalCodes = [...newFiltersOptionsAndSelection.output.newSelections[3]]
    let newSelectedBrands = [...newFiltersOptionsAndSelection.output.newSelections[4]]
    let newSelectedGroups = [...newFiltersOptionsAndSelection.output.newSelections[5]]
    let newSelectedBusinessUnits = [...newFiltersOptionsAndSelection.output.newSelections[6]]
    let newSelectedSets = [...newFiltersOptionsAndSelection.output.newSelections[7]]
    let newSelectedCIFs = [...newFiltersOptionsAndSelection.output.newSelections[8]]

    let newSearchSales = [...newFiltersOptionsAndSelection.output.newSearchSales]

    let arrayPopulation = [...newFiltersOptionsAndSelection.output.newArrays[1]]
    let arrayPostalCode = [...newFiltersOptionsAndSelection.output.newArrays[2]]
    let arrayBrand = [...newFiltersOptionsAndSelection.output.newArrays[3]]
    let arrayGroup = [...newFiltersOptionsAndSelection.output.newArrays[4]]
    let arrayBusinessUnit = [...newFiltersOptionsAndSelection.output.newArrays[5]]
    let arraySet = [...newFiltersOptionsAndSelection.output.newArrays[6]]
    let arrayCIF = [...newFiltersOptionsAndSelection.output.newArrays[7]]

    if (!newSelectedPopulations.length && !newSelectedPostalCodes.length && 
        !newSelectedBrands.length && !newSelectedGroups.length && 
        !newSelectedBusinessUnits.length && !newSelectedSets.length && 
        !newSelectedCIFs.length && newSearchSales) {
          if (typeOfSelection !== 'Populations') {
            setArrayPopulation(arrayFilters.arrayFilters(newSearchSales.map(({ population }) => population)))
            setSelectedPopulation([])
            populationRef.current.resetSelectedValues()
          }
          if (typeOfSelection !== 'PostalCodes') {
            setArrayPostalCode(arrayFilters.arrayFilters(newSearchSales.map(({ postalcode }) => postalcode)))
            setSelectedPostalCode([])
            postalCodeRef.current.resetSelectedValues()
          } 
          if (typeOfSelection !== 'Brands') {
            setArrayBrand(arrayFilters.arrayFilters(newSearchSales.map(({ brand }) => brand)))
            setSelectedBrand([])
            brandRef.current.resetSelectedValues()
          }
          if (typeOfSelection !== 'Groups') {
            setArrayGroup(arrayFilters.arrayFilters(newSearchSales.map(({ group }) => group)))
            setSelectedGroup([])
            groupRef.current.resetSelectedValues()
          }
          if (typeOfSelection !== 'BusinessUnits') {
            setArrayBusinessUnit(arrayFilters.arrayFilters(newSearchSales.map(({ businessunit }) => businessunit)))
            setSelectedBusinessUnit([])
            businessUnitRef.current.resetSelectedValues()
          }
          if (typeOfSelection !== 'Sets') {
            setArraySet(arrayFilters.arrayFilters(newSearchSales.map(({ set }) => set)))
            setSelectedSet([])
            setRef.current.resetSelectedValues()
          }
          if (typeOfSelection !== 'CIFs') {
            setArrayCIF(arrayFilters.arrayFilters(newSearchSales.map(({ clientidentity }) => clientidentity)))
            setSelectedCIF([])
            CIFRef.current.resetSelectedValues()
          }
    } else { // When there are selected items we do two things: 
            // a) We create the new list of all possible selections
            // b) We update the list of selected items that could have changed depending
            //    on the new items selected
          if (newSearchSales && arrayPopulation && typeOfSelection !== 'Populations') {
            setArrayPopulation(arrayFilters.arrayFilters(arrayPopulation.map(({ population }) => population)))
            setSelectedPopulation(newSelectedPopulations.filter(population => arrayFilters.arrayFilters(arrayPopulation.map(({ population }) => population)).includes(population)))
          }
          if (newSearchSales && arrayPostalCode && typeOfSelection !== 'PostalCodes') {
            setArrayPostalCode(arrayFilters.arrayFilters(arrayPostalCode.map(({ postalcode }) => postalcode)))
            setSelectedPostalCode(newSelectedPostalCodes.filter(postalcode => arrayFilters.arrayFilters(arrayPostalCode.map(({ postalcode }) => postalcode)).includes(postalcode)))
          }
          if (newSearchSales && arrayBrand && typeOfSelection !== 'Brands') {
            setArrayBrand(arrayFilters.arrayFilters(arrayBrand.map(({ brand }) => brand)))
            setSelectedBrand(newSelectedBrands.filter(brand => arrayFilters.arrayFilters(arrayBrand.map(({ brand }) => brand)).includes(brand)))
          }
          if (newSearchSales && arrayGroup && typeOfSelection !== 'Groups') {
            setArrayGroup(arrayFilters.arrayFilters(arrayGroup.map(({ group }) => group)))
            setSelectedGroup(newSelectedGroups.filter(group => arrayFilters.arrayFilters(arrayGroup.map(({ group }) => group)).includes(group)))
          }
          if (newSearchSales && arrayBusinessUnit && typeOfSelection !== 'BusinessUnits') {
            setArrayBusinessUnit(arrayFilters.arrayFilters(arrayBusinessUnit.map(({ businessunit }) => businessunit)))
            setSelectedBusinessUnit(newSelectedBusinessUnits.filter(businessunit => arrayFilters.arrayFilters(arrayBusinessUnit.map(({ businessunit }) => businessunit)).includes(businessunit)))
          }
          if (newSearchSales && arraySet && typeOfSelection !== 'Sets') {
            setArraySet(arrayFilters.arrayFilters(arraySet.map(({ set }) => set)))
            setSelectedSet(newSelectedSets.filter(set => arrayFilters.arrayFilters(arraySet.map(({ set }) => set)).includes(set)))
          }
          if (newSearchSales && arrayCIF && typeOfSelection !== 'CIFs') {
            setArrayCIF(arrayFilters.arrayFilters(arrayCIF.map(({ clientidentity }) => clientidentity)))
            setSelectedCIF(newSelectedCIFs.filter(clientidentity => arrayFilters.arrayFilters(arrayCIF.map(({ clientidentity }) => clientidentity)).includes(clientidentity)))
          }
    }
    setSearchSales(newSearchSales)
    if (newSearchSales && newSearchSales.length === 0) {
      return alert.show(t('wrong filter combination'), { type: 'error' })
    }
  }

  // reset report
  const handleReset = () => {
    setSelectedPopulation([])
    setSelectedPostalCode([])
    setSelectedBrand([])
    setSelectedGroup([])
    setSelectedBusinessUnit([])
    setSelectedSet([])
    setSelectedCIF([])
    setSearchSales(undefined)
  }
   // reset filters
   const handleResetFilters = () => {
    setSelectedPopulation([])
    setSelectedPostalCode([])
    setSelectedBrand([])
    setSelectedGroup([])
    setSelectedBusinessUnit([])
    setSelectedSet([])
    setSelectedCIF([])
    filterSelectors()
  }
  // population
  const handleSelectPopulation = (newSelectedPopulations, selectedItem) => {
    setSelectedPopulation(newSelectedPopulations)
    filterSelectors(newSelectedPopulations, 'Populations', selectedItem)
  }
  const handleRemovePopulation = (newSelectedPopulations, selectedItem) => {
    setSelectedPopulation(newSelectedPopulations)
    if (!newSelectedPopulations.length) {
      setSelectedPopulation([])
    }
    filterSelectors(newSelectedPopulations, 'Populations')
  }
  // postal code
  const handleSelectPostalCode = (newSelectedPostalCodes, selectedItem) => {
    setSelectedPostalCode(newSelectedPostalCodes)
    filterSelectors(newSelectedPostalCodes, 'PostalCodes', selectedItem)
  }
  const handleRemovePostalCode = (newSelectedPostalCodes, selectedItem) => {
    setSelectedPostalCode(newSelectedPostalCodes)
    if (!newSelectedPostalCodes.length) {
      setSelectedPostalCode([])
    }
    filterSelectors(newSelectedPostalCodes, 'PostalCodes')
  }
  // ClientCIF
  const handleSelectCIF = (newSelectedCIFs, selectedItem) => {
    setSelectedCIF(newSelectedCIFs)
    filterSelectors(newSelectedCIFs, 'CIFs', selectedItem)
  }
  const handleRemoveCIF = (newSelectedCIFs, selectedItem) => {
    setSelectedCIF(newSelectedCIFs)
    if (!newSelectedCIFs.length) {
      setSelectedCIF([])
    }
    filterSelectors(newSelectedCIFs, 'CIFs')
  }
  // brand
  const handleSelectBrand = (newSelectedBrands, selectedItem) => {
    setSelectedBrand(newSelectedBrands)
    filterSelectors(newSelectedBrands, 'Brands', selectedItem)
  }
  const handleRemoveBrand = (newSelectedBrands, selectedItem) => {
    setSelectedBrand(newSelectedBrands)
    if (!newSelectedBrands.length) {
      setSelectedBrand([])
    }
    filterSelectors(newSelectedBrands, 'Brands')
  }
  // group
  const handleSelectGroup = (newSelectedGroups, selectedItem) => {
    setSelectedGroup(newSelectedGroups)
    filterSelectors(newSelectedGroups, 'Groups', selectedItem)
  }
  const handleRemoveGroup = (newSelectedGroups, selectedItem) => {
    setSelectedGroup(newSelectedGroups)
    if (!newSelectedGroups.length) {
      setSelectedGroup([])
    }
    filterSelectors(newSelectedGroups, 'Groups')
  }
  // Business Unit
  const handleSelectBusinessUnit = (newSelectedBusinessUnits, selectedItem) => {
    setSelectedBusinessUnit(newSelectedBusinessUnits)
    filterSelectors(newSelectedBusinessUnits, 'BusinessUnits', selectedItem)
  }
  const handleRemoveBusinessUnit = (newSelectedBusinessUnits, selectedItem) => {
    setSelectedBusinessUnit(newSelectedBusinessUnits)
    if (!newSelectedBusinessUnits.length) {
      setSelectedBusinessUnit([])
    }
    filterSelectors(newSelectedBusinessUnits, 'BusinessUnits')
  }
  // set
  const handleSelectSet = (newSelectedSets, selectedItem) => {
    setSelectedSet(newSelectedSets)
    filterSelectors(newSelectedSets, 'Sets', selectedItem)
  }
  const handleRemoveSet = (newSelectedSets, selectedItem) => {
    setSelectedSet(newSelectedSets)
    if (!newSelectedSets.length) {
      setSelectedSet([])
    }
    filterSelectors(newSelectedSets, 'Sets')
  }

    
  const handleYearChange = (e) => {
    const { year } = e.target.form
    if (year.value === '') return
    setYearPrice(year.value)
  }

  function transformString(stringNumber) {
    // Split the string into integer and fractional parts
    let parts = stringNumber.split(',');

    // Separate the integer part into groups of three digits and join with points
    let integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // If there's a fractional part, concatenate it with the decimal point
    let fractionalPart = parts.length > 1 ? ',' + parts[1] : '';

    // Concatenate integer and fractional parts
    return integerPart + fractionalPart;
  }

  return (
    <Box id='salesReports'>
      <TabsSalesStock highlight='clients' />
      {!searchSales && <Level>
        <Select.Container color='dark'>
            <Multiselect selectedValues={selectedRegions} autocomplete="off" placeholder={t('Regiones')} options={arrayRegion} isObject={false} style={styleSelectorDistributors} onSelect={handleSelectRegion} onRemove={handleRemoveRegion} avoidHighlightFirstOption />
        </Select.Container>
        {isPVLContracted &&
          <Level.Item>
            <form>
              <Title size={5}>{t('year prices')}:</Title>
              <Select.Container>
                <Select name='year' onChange={handleYearChange} defaultValue='' value={yearPrice && yearPrice}>
                  <Select.Option value=''>
                    {t('select')} {t('year')}
                  </Select.Option>
                  {years && years.map((year, index) => {
                    return (
                      <Select.Option key={year} value={year}>
                        {year}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Select.Container>
            </form>
          </Level.Item>
        }
        <Level.Item>
          <div>
            <Title size={5}>{t('Desde')}:</Title>
            <DatePicker
              showPopperArrow={false}
              dateFormat='MM/yyyy'
              showMonthYearPicker
              selected={dateStart}
              onChange={handleChangeDateStart}
            />
          </div>
        </Level.Item>
        <Level.Item>
          <div>
            <Title size={5}>{t('Hasta')}:</Title>
            <DatePicker
              dateFormat='MM/yyyy'
              showMonthYearPicker
              selected={dateEnd}
              onChange={handleChangeDateEnd}
            />
          </div>
        </Level.Item>
      </Level>}
      {!searchSales && <Level>
        <Level.Item>
          <Button.Group>
            <Button onClick={handleRetrieveClientsData} color='primary' state={isLoadingValidation}>
              {t('Generar Informe')}
            </Button>
             <Button onClick={handleDownloadData} color='warning' state={isLoadingValidationDownload}>
              {t('Descargar Informe')}
            </Button> 
          </Button.Group>
        </Level.Item>
      </Level>}
      {searchSales && 
        <Level>
          <Level.Item>
            <Title size={5}>{t('Desde')}:</Title>&nbsp;
            <DatePicker
              showPopperArrow={false}
              dateFormat='MM/yyyy'
              selected={dateStart}
              disabled
            />
          </Level.Item>
          <Level.Item>
            <Title size={5}>{t('Hasta')}:</Title>&nbsp;
            <DatePicker
              dateFormat='MM/yyyy'
              selected={dateEnd}
              disabled
            />
            <br></br>
          </Level.Item>
        </Level>
      }
      <Level>
        {searchSales && searchSales.length > 0 && <Level.Item>
          <Button.Group>
            <Button onClick={handleReset} color='primary'>
              {t('Generar Informe Nuevo')}
            </Button>
            <Button onClick={handleDownloadClientsData} state={isLoadingValidationDownload} color='success'>
              <span>
                {t('Descargar Informe')}
              </span>
              <Icon>
                <FontAwesomeIcon icon={['fas', 'cloud-download-alt']} />
              </Icon>
            </Button>
            <Button onClick={handleResetFilters} color='warning'>
              <span>
                {t('Restablecer filtros')}
              </span>
              <Icon>
                <FontAwesomeIcon icon={faFilter} />
              </Icon>
            </Button>
          </Button.Group>
        </Level.Item>}
      </Level>
      {theProgress > 0 &&
        <div>
          <Level>
            <Level.Item>
              <div style={{ width: 325 }}>
               <Progress size="small" color="primary"/>
              </div>
              <p>&nbsp;<strong>{t('In progress')} {`${Math.trunc(theProgress / 60).toString().padStart(2, '0')}:${Math.trunc(theProgress % 60).toString().padStart(2, '0')}`} min</strong></p>
            </Level.Item>  
          </Level>
        </div>
      }
      <form id="Form1" runat="server" autocomplete="off">
        <Level>
          {searchSales && searchSales.length > 0 && <Level.Item>
            <Select.Container color='dark'>
              <Multiselect closeOnSelect={false} placeholder={t('Poblaciones')} ref={populationRef} selectedValues={selectedPopulation} options={arrayPopulation} isObject={false} className='multiselectcontainerFilter' style={styleSelectorFilter} onSelect={handleSelectPopulation} onRemove={handleRemovePopulation} avoidHighlightFirstOption />
            </Select.Container>
            <Select.Container color='dark'>
              <Multiselect closeOnSelect={false} placeholder={t('Codigo Postal')} ref={postalCodeRef} selectedValues={selectedPostalCode} options={arrayPostalCode} isObject={false} className='multiselectcontainerFilter' style={styleSelectorFilter} onSelect={handleSelectPostalCode} onRemove={handleRemovePostalCode} avoidHighlightFirstOption />
            </Select.Container>
            <Select.Container color='dark'>
              <Multiselect closeOnSelect={false} placeholder={t('CIF')} ref={CIFRef} selectedValues={selectedCIF} options={arrayCIF} isObject={false} className='multiselectcontainerFilter' style={styleSelectorFilter} onSelect={handleSelectCIF} onRemove={handleRemoveCIF} avoidHighlightFirstOption />
            </Select.Container>
          </Level.Item>}
        </Level>
        <Level>
          {searchSales && searchSales.length > 0 && <Level.Item>
            <Select.Container color='dark'>
              <Multiselect closeOnSelect={false} placeholder={t('Marcas')} ref={brandRef} selectedValues={selectedBrand} options={arrayBrand} isObject={false} className='multiselectcontainerFilter' style={styleSelectorFilter} onSelect={handleSelectBrand} onRemove={handleRemoveBrand} avoidHighlightFirstOption />
            </Select.Container>
            <Select.Container color='dark'>
              <Multiselect closeOnSelect={false} placeholder={t('Grupos')} ref={groupRef} selectedValues={selectedGroup} options={arrayGroup} isObject={false} className='multiselectcontainerFilter' style={styleSelectorFilter} onSelect={handleSelectGroup} onRemove={handleRemoveGroup} avoidHighlightFirstOption />
            </Select.Container>
            <Select.Container color='dark'>
              <Multiselect closeOnSelect={false} placeholder={t('Business Unit')} ref={businessUnitRef} selectedValues={selectedBusinessUnit} options={arrayBusinessUnit} isObject={false} className='multiselectcontainerFilter' style={styleSelectorFilter} onSelect={handleSelectBusinessUnit} onRemove={handleRemoveBusinessUnit} avoidHighlightFirstOption />
            </Select.Container>
            <Select.Container color='dark'>
              <Multiselect closeOnSelect={false} placeholder={t('Conjuntos')} ref={setRef} selectedValues={selectedSet} options={arraySet} isObject={false} className='multiselectcontainerFilter' style={styleSelectorFilter} onSelect={handleSelectSet} onRemove={handleRemoveSet} avoidHighlightFirstOption />
            </Select.Container>
          </Level.Item>}
        </Level>
      </form>
      <br />
      {searchSales && searchSales.length > 0 && logic.notCrossSales && <Switch checkedChildren={t('Sin vtas cruzadas')} disabled checked />} 
      {searchSales && searchSales.length > 0 && !logic.notCrossSales && <Switch unCheckedChildren={t('Con vtas cruzadas')} disabled />} 
      <br />
      <br />
      {searchSales && searchSales.length > 0 && <Table fullwidth narrow hoverable bordered striped>
        <Table.Head>
          <Table.Row>
            <Table.Heading>{t('Client CIF')}</Table.Heading>
            <Table.Heading>{t('Postal Code')}</Table.Heading>
            <Table.Heading>{t('Province')}</Table.Heading>
            <Table.Heading>{t('Population')}</Table.Heading>
            <Table.Heading>{t('Providercode')}</Table.Heading>
            <Table.Heading>{t('ProviderProductName')}</Table.Heading>
            <Table.Heading>{t('Brand')}</Table.Heading>
            <Table.Heading>{t('Group')}</Table.Heading>
            <Table.Heading>{t('Bussiness Unit')}</Table.Heading>
            <Table.Heading>{t('Set')}</Table.Heading>
            <Table.Heading>{t('Raw Data')}</Table.Heading>
            {isPVLContracted && <Table.Heading>{t('Value')}</Table.Heading>}
            <Table.Heading>{t('Sale date')}</Table.Heading>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {searchSales &&
            searchSales.slice(startPosition, endPosition).map((item, index) => {
              return (
                <Table.Row key={index}>
                  <Table.Heading>{item.clientidentity}</Table.Heading>
                  <Table.Cell>{item.postalcode}</Table.Cell>
                  <Table.Cell>{item.province}</Table.Cell>
                  <Table.Cell>{item.population}</Table.Cell>
                  <Table.Cell>{item.providercode}</Table.Cell>
                  <Table.Cell>{item.providerproductname}</Table.Cell>
                  <Table.Cell>{item.brand}</Table.Cell>
                  <Table.Cell>{item.group}</Table.Cell>
                  <Table.Cell>{item.businessunit}</Table.Cell>
                  <Table.Cell>{item.set}</Table.Cell>
                  <Table.Cell className="right">{item.units}</Table.Cell>
                  {isPVLContracted && <Table.Cell className="right">{item.value !== null ? transformString(item.value) : item.value}</Table.Cell>}
                  <Table.Cell>{item.date}</Table.Cell>
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table>}
      {searchSales && searchSales.length > 0 && <Pagination
        totalItems={searchSales && searchSales.length}
        ref={paginationRef}
        requestPagination={handleRequestPagination}
      />}
    </Box>
  )
}
