import React, { useEffect, useState } from 'react'
import { Hero, Footer, Content } from 'rbx'
import { Modal } from 'antd'
import { AvisoLegal } from '../../../config/legal/AvisoLegal'
import { PoliticaCookies } from '../../../config/legal/PoliticaCookies'
import { PoliticaPrivacidad } from '../../../config/legal/PoliticaPrivacidad'
import { TerminosCondiciones } from '../../../config/legal/TerminosCondiciones'
import { Link } from 'react-router-dom'
import { useModal } from '../../../logic/hooks/'
import './loginContainer.scss'
import { logic } from '../../../logic'

export const LoginContainer = props => {
  const [version, setVersion]  = useState()
  
  const avisoLegalModal = useModal()
  const politicaCookiesModal = useModal()
  const politcaPrivacidadModal = useModal()
  const terminosCondicionesModal = useModal()

  useEffect(() => {
    logic.getADSVersion().then(adsversion => {
      setVersion('Build ' + adsversion.data[0].version || '0.0.0')
    })
  }, [])


  return (
    window.location.pathname !== '/dashboard/analytics/fullscreen' ?
    <>
      <Hero className='loginContainer'>
        <Hero.Body>
          {props.children}
          <Content style={{ paddingTop: '10px' }} textAlign='centered'>
            <p>
              Si no dispone de esta información o para cualquier aclaración por favor contacte con su Proveedor o visite nuestra web <a href='https://www.ambit-bst.com/'>https://www.ambit-bst.com/</a>
            </p>
          </Content>
          <Footer>
            <Content textAlign='centered'>
              <small>
                <strong>(c) Animal Data Source&#8482;</strong> IS AN AMBIT BST PRODUCT – ALL RIGHTS RESERVED
              </small> <br />
              <Link onClick={() => avisoLegalModal.showModal()} to=''>Aviso Legal</Link>
            &nbsp;·&nbsp;<Link onClick={() => politcaPrivacidadModal.showModal()} to=''>Política de privacidad</Link>
            &nbsp;·&nbsp;<Link onClick={() => politicaCookiesModal.showModal()} to=''>Política de cookies</Link>
            &nbsp;·&nbsp;<Link onClick={() => terminosCondicionesModal.showModal()} to=''>Términos y condiciones</Link>
            </Content>
            <Content textAlign='centered'>
             {version && version}
            </Content>
          </Footer>
        </Hero.Body>
        <Modal
          title='Aviso legal'
          visible={avisoLegalModal.isVisible}
          onCancel={() => avisoLegalModal.closeModal()}
          onOk={() => avisoLegalModal.closeModal()}
          footer={null}
        >
          <AvisoLegal />
        </Modal>
        <Modal
          title='Política de cookies'
          visible={politicaCookiesModal.isVisible}
          onCancel={() => politicaCookiesModal.closeModal()}
          onOk={() => politicaCookiesModal.closeModal()}
          footer={null}
        >
          <PoliticaCookies />
        </Modal>
        <Modal
          title='Política de Privacidad'
          visible={politcaPrivacidadModal.isVisible}
          onCancel={() => politcaPrivacidadModal.closeModal()}
          onOk={() => politcaPrivacidadModal.closeModal()}
          footer={null}
        >
          <PoliticaPrivacidad />
        </Modal>
        <Modal
          title='Términos y condiciones'
          visible={terminosCondicionesModal.isVisible}
          onCancel={() => terminosCondicionesModal.closeModal()}
          onOk={() => terminosCondicionesModal.closeModal()}
          footer={null}
        >
          <TerminosCondiciones />
        </Modal>
      </Hero>
    </>
    :
    <>
      <Hero >
          {props.children}
      </Hero>
    </>
  )
}
