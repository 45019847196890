import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { logic } from '../../../logic'
import { TabsGroups } from '../tabsGroups'
import {
  Title,
  Level,
  Field,
  Icon,
  Button,
  Table,
  Box,
  Select
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Pagination } from '../../pagination'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useToggle, useConfirmActionMessage } from '../../../logic/hooks'
import { Search } from '../../filter/Search'
import { Modal } from '../../modal'
import { useAlert } from 'react-alert'

export const ObjectivesGroup = () => {
  const alert = useAlert()

  // Hooks for toggling on and off the modal
  const [open, toggle] = useToggle(false)

  const [openInactive, toggleInactive] = useToggle(false)

  const [deleteItem, setDeleteItem] = useState()

  // state inside the modal
  const [related, setRelated] = useState([])

  // main state
  const [groups, setGroups] = useState()
  const [allGroups, setAllGroups] = useState()

  // products that are searched for
  const [searchGroups, setSearchGroups] = useState([])
  const errorAction = useConfirmActionMessage()

  // i18n hook
  const { t } = useTranslation()

  // Routing Variables
  const { url } = useRouteMatch()
  const history = useHistory()

  // State refering to the pagination attributes comming from the pagination component
  const defaultPaginationStartPosition = 0
  const defaultPaginationEndPosition = 10
  const [startPosition, setStartPosition] = useState(
    defaultPaginationStartPosition
  )
  const [endPosition, setEndPosition] = useState(defaultPaginationEndPosition)

  // React active or inactive
  const [dropdownState, setDropdownState] = useState('active')
  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const paginationRef = useRef()

  // Fetch products when component mounts
  useEffect(() => {
    const tenantId = logic.tenantId.toString()
    let sortedActiveGroups = [] 
    let inactiveGroups = []
    logic.getTenantClientsGroup(tenantId)
    .then(groups => {
      sortedActiveGroups =
        groups && groups.data &&
        groups.data.sort((a, b) => {
          if (a.clientname.toLowerCase() < b.clientname.toLowerCase()) {
            return -1
          }
          if (a.clientname.toLowerCase() > b.clientname.toLowerCase()) {
            return 1
          }
          return 0
        })
        setGroups(sortedActiveGroups)
        setSearchGroups(sortedActiveGroups)
    })
    .then(() => {
      const active = false
      logic.getTenantClientsGroup(tenantId, active).then(groups => {
        inactiveGroups = groups && groups.data
        setAllGroups(sortedActiveGroups.concat(inactiveGroups))
      })
    })
  }, [])

  // handles the pagination requests
  const handleRequestPagination = requestPaginationObject => {
    let newStartPosition = defaultPaginationStartPosition
    let newEndPosition = defaultPaginationEndPosition
    if (requestPaginationObject) {
      const { currentPage, itemsPerPage } = requestPaginationObject
      newStartPosition = currentPage * itemsPerPage - itemsPerPage
      newEndPosition = newStartPosition + itemsPerPage
    }
    setStartPosition(newStartPosition)
    setEndPosition(newEndPosition)
  }

  // handles routing to add/edit
  const handleAddEditClick = (event, element) => {
    if (element) {
      history.push({
        pathname: `${url}/edit/${element && element.clientgroupid}`,
        state: element
      })
    } else {
      history.push({
        pathname: `${url}/add`
      })
    }
  }

  //handles activation of tenantclientgroup
  const handleActivateItem = (event, element) => {
    
    const id = `${element.clientgroupid}`
    logic
      .activateTenantClientsGroup({ clientGroupId: id })
      .then(item => {

        if (item.status !== 200) {
          throw Error('status error')
        } 
        const newGroups = groups.filter(
          group => {
            return group.clientgroupid !== item.data[0].clientgroupid
          }
        )

        const sortedNewGroups =
        newGroups &&
        newGroups.sort((a, b) => {
          if (a.clientname.toLowerCase() < b.clientname.toLowerCase()) {
            return -1
          }
          if (a.clientname.toLowerCase() > b.clientname.toLowerCase()) {
            return 1
          }
          return 0
        })
        alert.show(t('clientsGroupActivated'), { type: 'success' })
        setSearchGroups(sortedNewGroups)
        setGroups(sortedNewGroups)
        toggleInactive()
      })
      .catch(err => {
        console.error(err.message)
      })
      .catch(() => {
        toggleInactive()
      })
  }

  // deletes the item
  const handleDeleteClick = (event, element) => {
    const id = `${element.clientgroupid}`
    logic
      .deleteClientsGroupTenant({ clientGroupId: id })
      .then(item => {
        if (item.status !== 200) {
          throw Error('status error')
        }

        const newGroups = groups.filter(
          group => {
            return group.clientgroupid !== item.data[0].clientgroupid
          }
        )

        const sortedNewGroups =
        newGroups &&
        newGroups.sort((a, b) => {
          if (a.clientname.toLowerCase() < b.clientname.toLowerCase()) {
            return -1
          }
          if (a.clientname.toLowerCase() > b.clientname.toLowerCase()) {
            return 1
          }
          return 0
        })
        alert.show(t('clientsGroupInactivated'), { type: 'success' })
        setSearchGroups(sortedNewGroups)
        setGroups(sortedNewGroups)
        toggle()
      })
      .catch(err => {
        console.error(err.message)
      })
      .catch(() => {
        toggle()
      })
  }

  const showRelatedItems = (event, item) => {
    setDeleteItem({ ...item })

    const clientGroupId = item.clientgroupid.toString()
    const userId = logic.sessionId.toString()
    logic.getRelatedClientsGroupObjectives({ clientGroupId, userId })
      .then(items => {
        if (items.status !== 200) {
          throw Error(`${items.status}`)
        }
        setRelated(items.data.length > 0 ? items.data.filter(objective => {
          if (objective.active) return objective
        }) : [])
      })
      .then(() => {
        toggle()
      })
      .catch(err => {
        if (err.message === '409') {
          alert.show('Input already exists', {
            type: 'error'
          })
        } else {
          alert.show('Unexpected Error', {
            type: 'error'
          })
        }
      })
      .catch(() => {
        toggle()
      })
  }

  const showRelatedItemsInactive = (event, item) => {
    setDeleteItem({ ...item })
    toggleInactive()
  }

  const handleDoSearch = value => {
    const result = groups.filter(group => {

      const newGroup = {
        clientname: group.clientname,
        clientdescription: group.clientdescription
      }
      const values = Object.values(newGroup)

      const isIncluded = values
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(value.toLowerCase().replace(/\s+/g, ''))
      // pagination Reset vallues
      paginationRef.current.resetValues()

      return isIncluded
    })
    const sortedResult = result.sort((a, b) => {
      if (a.clientname.toLowerCase() < b.clientname.toLowerCase()) {
        return -1
      }
      if (a.clientname.toLowerCase() > b.clientname.toLowerCase()) {
        return 1
      }
      return 0
    })
    setSearchGroups(sortedResult)
    return sortedResult
  }

  const handleDropdownStateChange = (event) => {
    setDropdownState(event.target.value)
    let active
    if(event.target.value === 'active') {
      active = true
    } else {
      active = false
    }
    const tenantId = logic.tenantId.toString()
    logic.getTenantClientsGroup(tenantId, active).then(groups => {
      const sortedGroups =
        groups && groups.data &&
        groups.data.sort((a, b) => {
          if (a.clientname.toLowerCase() < b.clientname.toLowerCase()) {
            return -1
          }
          if (a.clientname.toLowerCase() > b.clientname.toLowerCase()) {
            return 1
          }
          return 0
        })
      setGroups(sortedGroups)
      setSearchGroups(sortedGroups)
      paginationRef.current.resetValues()
    })
  }

  return (
    <Box>
      {open && (  
        <Modal toggle={toggle} open={open}>
          <Title>{t('confirmDelete')}</Title>
          {related && related.length !== 0 && (
            <Title subtitle size={6}>
              {t('cannotDeleteGroup')}
            </Title>
          )}
          {related && related.length !== 0 && related.length > 7 && related.slice(0, 7).map((item, index) => {
            return (<>
             <Field key={index}>
               {`${item.objectivecode}: ${item.objectivename}`}
             </Field>
                   </>)
             })}
          {related && related.length !== 0 && related.length <= 7 && related.map((item, index) => {
            return (<>
            <Field key={index}>
              {`${item.objectivecode}: ${item.objectivename}`}
            </Field>
                  </>)
            })}
          {related && related.length !== 0 && related.length > 7 && (
             <Field key={related.length}>
              ...{t('and')} {related.length - 7} {t('others')}
            </Field>
          )}
          {related && related.length === 0 && (
            <>
              <Title subtitle>{t('deleteSure')}</Title>
              <Button
                onClick={e => handleDeleteClick(e, deleteItem)}
                color='danger'
              >
                {t('delete')}
              </Button>
            </>
          )}
        </Modal>
      )}

      {openInactive && (
        <Modal toggle={toggleInactive} open={openInactive}>
          <Title>{t('confirmActivate')}</Title>
            <>
              <Title subtitle>{t('activateSure')}</Title>
              <Button
                onClick={e => handleActivateItem(e, deleteItem)}
                color='success'
              >
                {t('Activate')}
              </Button>
            </>
        </Modal>
      )}

      <TabsGroups highlight='groups' />
      <Level>
        <Select.Container>
          <Select value={dropdownState} onChange={handleDropdownStateChange} >
            <Select.Option value='active'>
              {t('Actives')}
            </Select.Option>
            <Select.Option value='inactive'>
              {t('Inactives')}
            </Select.Option>
          </Select>
        </Select.Container>
        <Level.Item>
          <Search doSearch={handleDoSearch} />
        </Level.Item>
        <Level.Item align='right'>
          <Button onClick={e => handleAddEditClick()} color='info'>
            <Icon>
              <FontAwesomeIcon size='lg' icon={['fas', 'plus']} />
            </Icon>
          </Button>
        </Level.Item>
      </Level>
      <Table hoverable bordered striped narrow fullwidth>
        <Table.Head>
          <Table.Row>
            <Table.Heading>{t('name')}</Table.Heading>
            <Table.Heading>{t('description')}</Table.Heading>
            <Table.Heading>{t('action')}</Table.Heading>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {searchGroups &&
            searchGroups.slice(startPosition, endPosition).map(item => {
              return (
                <Table.Row key={item.clientgroupid}>
                  <Table.Cell>{item.clientname}</Table.Cell>
                  <Table.Cell>{item.clientdescription}</Table.Cell>
                  <Table.Cell>
                    <button
                      onClick={e => handleAddEditClick(e, item)}
                      className='button is-primary is-outlined is-small'
                    >
                      <Icon>
                        <FontAwesomeIcon size='lg' icon={['fas', 'edit']} />
                      </Icon>
                    </button>
                    {dropdownState === 'active' && <button
                      onClick={e => showRelatedItems(e, item)}
                      className='button is-danger is-outlined is-small'
                    >
                      <Icon>
                        <FontAwesomeIcon size='lg' icon={['fas', 'trash']} />
                      </Icon>
                    </button>}
                    {dropdownState === 'inactive' && <button
                      onClick={e => showRelatedItemsInactive(e, item)}
                      className='button is-success is-outlined is-small'
                    >
                      <Icon>
                        <FontAwesomeIcon size='lg' icon={['fas', 'check-circle']} />
                      </Icon>
                    </button>}
                  </Table.Cell>
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table>
      <Pagination
        totalItems={searchGroups && searchGroups.length}
        ref={paginationRef}
        requestPagination={handleRequestPagination}
      />
    </Box>
  )
}
