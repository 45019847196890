import React from 'react'
import { useAlert } from 'react-alert'
import { Container, Column, Box, Image, Field, Input, Control, Button, Icon, Message } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import AmbitLogo from '../../config/images/ambit.png'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FormError, HelpError } from '../error'
import { logic } from '../../logic'
import { useConfirmActionMessage } from '../../logic/hooks'
import { NOTIFICATIONS } from '../../notifications'
import * as yup from 'yup'
const schema = yup.object().shape({
  newPassword: yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&._€+#-])[A-Za-z\d@$!%*?&._€+#-]{8,}$/).required(),
  confirmNewPassword: yup.string().oneOf([yup.ref('newPassword'), null]).required()
})

export const ResetPassword = () => {
  const alert = useAlert()
  const confirmAction = useConfirmActionMessage()
  const history = useHistory()
  const { t } = useTranslation()
  const { register, handleSubmit, errors, setError } = useForm({ validationSchema: schema })
  const onSubmitData = ({ newPassword, confirmNewPassword }) => {
    if (newPassword !== confirmNewPassword) {
      return setError('confirmNewPassword', 'passwordNotMatch')
    }
    logic.resetPassword({ newPassword })
      .then(res => {
        if (res.status !== 200) {
          throw Error(res.status)
        }
        alert.show(t(NOTIFICATIONS.resetPassword), {
          type: 'success'
        })
        history.push({
          pathname: '/login'
        })
      })
      .catch(err => {
        console.error('error', err.message)
        if (err.message === '409') {
          alert.show('Too many requests', {
            type: 'error'
          })
        } else {
          alert.show('Invalid Password', {
            type: 'error'
          })
        }
      })
  }

  return (
    <Container>
      <Column.Group>
        <Column size={4} offset={4}>
          <Box>
            <Image.Container size='2by1'>
              <Image src={AmbitLogo} />
            </Image.Container>
            <Message color='info'>
              <p>Recuerda, la contraseña tiene que tener un mínimo de ocho caracteres, al menos una mayúscula, una minúscula, un número y un carácter especial. </p>
            </Message>
            <form onSubmit={handleSubmit(onSubmitData)}>
              <Field>
                <Control iconLeft>
                  <Input name='newPassword' ref={register} color='light' type='password' placeholder={t('nueva contraseña')} autoFocus='' />
                  <Icon align='left'>
                    <FontAwesomeIcon icon={['fas', 'lock']} />
                  </Icon>
                </Control>
                {errors.newPassword && (
                  <HelpError message={t('musthavecorrectformat')} />
                )}
              </Field>
              <Field>
                <Control iconLeft>
                  <Input name='confirmNewPassword' ref={register} color='light' type='password' placeholder={t('confirmar contraseña')} />
                  {errors.password && errors.password.type === 'required' &&
                    (
                      <FormError showMessage type='danger' message='Required' closeButton={false} />
                    )}
                  <Icon align='left'>
                    <FontAwesomeIcon icon={['fas', 'lock']} />
                  </Icon>
                </Control>
                {errors.confirmNewPassword && (
                  <HelpError message={t('las contraseñas tienen que coincidir')} />
                )}
              </Field>
              {confirmAction.showMessage && <FormError {...confirmAction} />}
              <Button color='info' fullwidth>
                <Container>
                  {t('resetear contraseña')}
                </Container>
                <Icon>
                  <FontAwesomeIcon size='lg' icon={['fas', 'sign-in-alt']} />
                </Icon>
              </Button>
            </form>
          </Box>
          <Container textAlign='centered' textColor='grey' />
        </Column>
      </Column.Group>
    </Container>

  )
}
