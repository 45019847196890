import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tab, Icon } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

export const TabsDashboardsAdmin = ({ highlight }) => {
  const { t } = useTranslation()

  return (
    <Tab.Group align='centered' kind='boxed'>
      <Tab as={Link} to='/settings/dashboards' active={highlight === 'dashboards'}>
        <Icon size='small'>
          <FontAwesomeIcon icon={faChartLine} />
        </Icon>
        <span>{t('Dashboard')}</span>
      </Tab>
    </Tab.Group>
  )
}
