import React, { useState, createContext, useEffect } from 'react';
import { logic } from '../../src/logic/logic'
import { useTranslation } from 'react-i18next'
import { useAlert } from 'react-alert'
import { useHistory } from 'react-router-dom'

export const AdsContext = createContext();

function AdsContextProvider (props) {
    const [allGroups, setAllGroups] = useState()
    const [allTenantObjectives, setAllTenantObjectives] = useState()
    const [allTenantAgreementsTypes, setAllTenantAgreementsTypes] = useState()
    const [allDistributorObjectives, setAllDistributorObjectives] = useState()
    const [allDistributorTenantObjectives, setAllDistributorTenantObjectives] = useState()
    const [sharedObjective, setSharedObjective] = useState() // AMN - The objective selected (edit) or the new one (add) we are working on
    const [boldDistributors, setBoldDistributors] = useState([])
    const [boldProducts, setBoldProducts] = useState([])
    const [boldBusUnits, setBoldBusUnits] = useState([])
    const [boldBrands, setBoldBrands] = useState([])
    const [boldGroups, setBoldGroups] = useState([])
    const [boldSets, setBoldSets] = useState([])
    const [boldPostalCodes, setBoldPostalCodes] = useState([])
    const [boldCountries, setBoldCountries] = useState([])
    const [boldRegions, setBoldRegions] = useState([])
    const [boldProvinces, setBoldProvinces] = useState([])
    const [originalObjCode, setOriginalObjCode] = useState('')
    const [sharedObjDisabled, setSharedObjDisabled] = useState(false)
    const [shrObjToDateDisabled, setShrObjToDateDisabled] = useState(false)
    const [infZonesCheckedBoxes, setInfZonesCheckedBoxes] = useState({
        country: false,
        region: false,
        province: false,
        postalcode: false
    })
    const [productsCheckedBoxes, setProductsCheckedBoxes] = useState({
        product: false,
        businessunit: false,
        brand: false,
        group: false,
        set: false,
    })
    const [allAdminDashboards, setAllAdminDashboards] = useState()
    const [allTableOfTables, setAllTablesOfTables] = useState([])

    const history = useHistory()

    // i18n hook
    const { t } = useTranslation()

    const alert = useAlert()

    useEffect(() => {
        (async function () {
            await fetchAllAdminTables()
        })()
    },[])

    const fetchAllTenantClientsGroup = async () => {
        try {
            const tenantId = logic.tenantId.toString()
            const activeGroups = await logic.getTenantClientsGroup(tenantId)
            if (activeGroups.status !== 200) {
                throw Error(`${activeGroups.status}`)
           }
            const active = false
            const inactiveGroups = await logic.getTenantClientsGroup(tenantId, active)        
            if (inactiveGroups.status !== 200) {
                throw Error(`${inactiveGroups.status}`)
           }
            setAllGroups(activeGroups.data.concat(inactiveGroups.data))
            return activeGroups.data.concat(inactiveGroups.data)
        }
        catch (error) {
            throw Error("Error while getting the clients' group")
        }
    }

    const fetchAllTenantObjectives = async () => {
        try {
            const tenantId = logic.tenantId.toString()
            const tenantObjectives = await logic.getTenantObjectives(tenantId)
            if (tenantObjectives.status !== 200) {
                throw Error(`${tenantObjectives.status}`)
           }
            setAllTenantObjectives([...tenantObjectives.data])
            return tenantObjectives.data
        }
        catch (error) {
            throw Error("Error while getting the tenant's objectives")
        }
    }

    const fetchAllTenantAgreementsTypes = async (distributorTenant = 0) => {
        try {
            let tenantId
            if (distributorTenant !== 0) {
                tenantId = distributorTenant.toString()
            } else {
                tenantId = logic.tenantId.toString()
            }
            const tenantAgreementsTypes = await logic.getTenantAgreementsTypes(tenantId)
            if (tenantAgreementsTypes.status !== 200) {
                throw Error(`${tenantAgreementsTypes.status}`)
           }
            setAllTenantAgreementsTypes([...tenantAgreementsTypes.data])
            return tenantAgreementsTypes.data
        }
        catch (error) {
            throw Error("Error while getting the tenant's types of agreements")
        }
    }

    const fetchAllTenantProductsIds = async () => {
        try {
            const userId = logic.sessionId.toString()
            const tenantProducts = await logic.getProductsTenantIds(userId)
            return tenantProducts.data
        }
        catch (error) {
            throw Error("Error while getting the tenant's products")
        }
    }

    const fetchAllTenantDistributors = async () => {
        try {
            const tenantId = logic.tenantId.toString()
            const tenantDistributors = await logic.getDistributors(tenantId)
            return tenantDistributors.data
        }
        catch (error) {
            throw Error("Error while getting the tenant's distributors")
        }
    }

    const fetchAllPostalCodes = async () => {
        try {
            const postalCodes = await logic.getPostalCodes()
            return postalCodes.data
        }
        catch (error) {
            throw Error("Error while getting the postal codes")
        }
    }

    const fetchOnePostalCode = async (postalCode) => {
        try {
            const postalCodeData = await logic.getOnePostalCode(postalCode)
            return postalCodeData
        }
        catch (error) {
            throw Error("Error while getting the postal code: ", postalCode)
        }
    }

    const fetchOnePostalCodeById = async (postalCodeId) => {
        try {
            const postalCodeData = await logic.getOnePostalCodeById(postalCodeId)
            return postalCodeData.data
        }
        catch (error) {
            throw Error("Error while getting the postal code id: ", postalCodeId)
        }
    }

    const fetchAllCountries = async () => {
        try {
            const countries = await logic.getCountriesFromPostalCodes()
            return countries.data
        }
        catch (error) {
            throw Error("Error while getting the countries from postal codes")
        }
    }

    const fetchAllRegions = async () => {
        try {
            const regions = await logic.getRegionsFromPostalCodes()
            return regions.data
        }
        catch (error) {
            throw Error("Error while getting the regions from postal codes")
        }
    }

    const fetchAllProvinces = async () => {
        try {
            const provinces = await logic.getProvincesFromPostalCodes()
            return provinces.data
        }
        catch (error) {
            throw Error("Error while getting the provinces from postal codes")
        }
    }

    const activateTenantObjective = async (objectiveId) => {
        try {
            const tenantId = logic.tenantId.toString()
            const activedObjective = await logic.activateTenantObjective(objectiveId, tenantId)
            const updatedAllTenantObjectives = allTenantObjectives.map(objective => { 
                if(objective.objectiveid === activedObjective.data[0].objectiveid) {
                  objective.active = !objective.active
                }
                return objective
              })
            setAllTenantObjectives([...updatedAllTenantObjectives])    
            alert.show(t('objectivesTenantActivated'), { type: 'success' })
            return activedObjective.data[0]
        }
        catch (error) {
            throw Error(`Error while activating the objective id: ${objectiveId} for the tenant id: ${logic.tenantId.toString()}`)
        }
    }

    const inactivateTenantObjective = async (objectiveId) => {
        try {
            const tenantId = logic.tenantId.toString()
            const inactivedObjective = await logic.inactivateTenantObjective(objectiveId, tenantId)
            const updatedAllTenantObjectives = allTenantObjectives.map(objective => { 
                if(objective.objectiveid === inactivedObjective.data[0].objectiveid) {
                  objective.active = !objective.active
                }
                return objective
              })
            setAllTenantObjectives([...updatedAllTenantObjectives]) 
            alert.show(t('objectivesTenantInactivated'), { type: 'success' })
            return inactivedObjective.data[0]
        }
        catch (error) {
            throw Error(`Error while inactivating the objective id: ${objectiveId} for the tenant id: ${logic.tenantId.toString()}`)
        }
    }

    const activateTenantAgreementType = async (agreementTypeId) => {
        try {
            const tenantId = logic.tenantId.toString()
            const activedAgreementType = await logic.activateTenantAgreementType(agreementTypeId, tenantId)
            const updatedAllTenantAgreementsTypes = allTenantAgreementsTypes.map(agreementType => { 
                if(agreementType.agreementtypeid === activedAgreementType.data[0].agreementtypeid) {
                  agreementType.active = !agreementType.active
                }
                return agreementType
            })
            setAllTenantAgreementsTypes([...updatedAllTenantAgreementsTypes]) 
            alert.show(t('agreementTypeTenantActivated'), { type: 'success' })
            return activedAgreementType.data[0]
        }
        catch (error) {
            throw Error(`Error while activating the type of agreement with id: ${agreementTypeId} for the tenant id: ${logic.tenantId.toString()}`)
        }
    }

    const inactivateTenantAgreementType = async (agreementTypeId) => {
        try {
            const tenantId = logic.tenantId.toString()
            const inactivedAgreementType = await logic.inactivateTenantAgreementType(agreementTypeId, tenantId)
            const updatedAllTenantAgreementsTypes = allTenantAgreementsTypes.map(agreementType => { 
                if(agreementType.agreementtypeid === inactivedAgreementType.data[0].agreementtypeid) {
                  agreementType.active = !agreementType.active
                }
                return agreementType
            })
            setAllTenantAgreementsTypes([...updatedAllTenantAgreementsTypes]) 
            alert.show(t('agreementTypeTenantInactivated'), { type: 'success' })
            return inactivedAgreementType.data[0]
        }
        catch (error) {
            throw Error(`Error while inactivating the type of agreement with id: ${agreementTypeId} for the tenant id: ${logic.tenantId.toString()}`)
        }
    }

    const updateTenantAgreementType = async ({ agreementTypeId, agreementTypeName, tenantId }) => {
        try {
            const updatedAgreementType = await logic.updateTenantAgreementType(agreementTypeId.toString(), agreementTypeName, tenantId.toString())
            if (updatedAgreementType.status !== 200) {
                throw Error(`${updatedAgreementType.status}`)
            }
            const updatedAllTenantAgreementsTypes = allTenantAgreementsTypes.map(agreementType => { 
                if (agreementType.agreementtypeid === parseInt(agreementTypeId)) {
                    agreementType.agreementtypename = agreementTypeName
                }
                return agreementType
            })
            setAllTenantAgreementsTypes([...updatedAllTenantAgreementsTypes]) 
            alert.show(t('updateSuccesful'), { type: 'success' })
        } catch (error) {
            throw Error(`Error while updating the type of agreement id: ${agreementTypeId} for the tenant id: ${logic.tenantId.toString()}, error: ${error}`)
        }
    }

    const insertTenantAgreementType = async ({ agreementTypeName }) => {
        try {
            const tenantId = logic.tenantId.toString()
            const newAgreementType = await logic.insertTenantAgreementType(agreementTypeName, tenantId)
            if (newAgreementType.status !== 200) {
                throw Error(`${newAgreementType.status}`)
            }
            alert.show(t('agreementTypeTenantInserted'), { type: 'success' })
            history.push({
                pathname: '/agreements/types',
                state: '200'
            })
        } catch (error) {
            throw Error(`Error while adding a new type of agreement for the tenant id: ${logic.tenantId.toString()}, error: ${error}`)
        }
    }

    const updateTenantObjective = async ({objectiveId, objectiveCode, objectiveName, objectiveDescription, clientGroupId, 
                                                                    startTime, endTime, previousEndTime, totalValueObjective, agreementTypeId, valueType,
                                                                    insertProductTenantIds= "", insertProductBrandIds ="", insertProductBusinessUnitIds="", 
                                                                    insertProductGroupIds="", insertProductSetIds="", insertDistributorIds="", 
                                                                    insertPostalCodeIds="", insertCountryNames="", insertRegionNames="", insertProvinceNames="", 
                                                                    deleteProductTenantIds="", deleteProductBrandIds="", deleteProductBusinessUnitIds="", 
                                                                    deleteProductGroupIds="", deleteProductSetIds="", deleteCountryNames="", deleteRegionNames="",
                                                                    deleteProvinceNames="", deleteDistributorIds="", deletePostalCodeIds="",}, identitiesToSend) => {
        try {
            const updatedObjective = await logic.updateTenantObjective(objectiveId.toString(), objectiveCode, objectiveName, objectiveDescription, 
                                                                                                               clientGroupId, startTime, endTime, previousEndTime,
                                                                                                               totalValueObjective, agreementTypeId, valueType, insertProductTenantIds, 
                                                                                                               insertProductBrandIds, insertProductBusinessUnitIds, 
                                                                                                               insertProductGroupIds, insertProductSetIds, 
                                                                                                               insertDistributorIds, insertPostalCodeIds, insertCountryNames, 
                                                                                                               insertRegionNames, insertProvinceNames, deleteProductTenantIds, 
                                                                                                               deleteProductBrandIds, deleteProductBusinessUnitIds, 
                                                                                                               deleteProductGroupIds, deleteProductSetIds, deleteDistributorIds, 
                                                                                                               deletePostalCodeIds, deleteCountryNames, deleteRegionNames,
                                                                                                               deleteProvinceNames)
            if (updatedObjective.status !== 200) {
                 throw Error(`${updatedObjective.status}`)
            }
            const updatedAllTenantObjectives = allTenantObjectives.map(objective => { 
                if(objective.objectiveid === parseInt(objectiveId)) {
                  objective.objectivecode =objectiveCode
                  objective.objectivename =objectiveName
                  objective.objectivedescription = objectiveDescription
                  objective.clientgroupid = clientGroupId
                  objective.starttime = startTime
                  objective.endtime = endTime
                  objective.totalvalueobjective = totalValueObjective
                  objective.agreementtypeid = agreementTypeId
                  objective.valuetype = valueType 
                  objective.product = [...identitiesToSend['product']]
                  objective.brand = [...identitiesToSend['brand']]
                  objective.businessunit = [...identitiesToSend['businessunit']]
                  objective.group = [...identitiesToSend['group']]
                  objective.set = [...identitiesToSend['set']]
                  objective.distributor = [...identitiesToSend['distributor']]   
                  objective.postalcode = [...identitiesToSend['postalcode']]
                  objective.country = [...identitiesToSend['country']]
                  objective.region = [...identitiesToSend['region']]
                  objective.province = [...identitiesToSend['province']] 
                }
                return objective
              })
            setAllTenantObjectives([...updatedAllTenantObjectives]) 
            alert.show(t('objectiveTenantUpdated'), { type: 'success' })
        }
        catch (error) {
            throw Error(`Error while updating the objective id: ${objectiveId} for the tenant id: ${logic.tenantId.toString()}, error: ${error}`)
        }
    }

    const insertTenantObjective = async ({objectiveCode, objectiveName, objectiveDescription, clientGroupId, 
                                                                  startTime, endTime, totalValueObjective, agreementTypeId, valueType,
                                                                  insertProductTenantIds= "", insertProductBrandIds ="", insertProductBusinessUnitIds="", 
                                                                  insertProductGroupIds="", insertProductSetIds="", insertDistributorIds="", 
                                                                  insertPostalCodeIds="", insertCountryNames="", insertRegionNames="", insertProvinceNames=""}) => {
        try {
            const tenantId = logic.tenantId.toString()
            const newObjective = await logic.insertTenantObjective(objectiveCode, objectiveName, objectiveDescription, 
                                                                                                         clientGroupId, startTime, endTime,
                                                                                                         totalValueObjective, agreementTypeId, valueType, tenantId, 
                                                                                                         insertProductTenantIds, insertProductBrandIds, insertProductBusinessUnitIds, 
                                                                                                         insertProductGroupIds, insertProductSetIds, insertDistributorIds, 
                                                                                                         insertPostalCodeIds, insertCountryNames, insertRegionNames, insertProvinceNames)
            if (newObjective.status !== 200) {
                 throw Error(`${newObjective.status}`)
            }
            alert.show(t('objectiveTenantInserted'), { type: 'success' })
            history.push({
                pathname: '/agreements/agreements',
                state: '200'
            })
        }
        catch (error) {
            throw Error(`Error while adding a new objective for the tenant id: ${logic.tenantId.toString()}, error: ${error}`)
        }
    }

    const fetchAllDistributorObj = async (objectiveId) => {
        try {
            const distributorsObj = await logic.getDistributorsObjective(objectiveId)
            if (distributorsObj.status !== 200) {
                throw Error(`${distributorsObj.status}`)
           }
            return distributorsObj.data
        }
        catch (error) {
            throw Error("Error while getting all distributors values for one objective id")
        }
    }

    const updateDistTenantObj = async ({objectiveDistributorId, objectiveRealValue, objectiveOldToReplaceValue}) => {
        try {
            const updatedDistTenantObj = await logic.updateDistTenantObj(objectiveDistributorId.toString(), objectiveRealValue, objectiveOldToReplaceValue)
            if (updatedDistTenantObj.status !== 200) {
                throw Error(`${updatedDistTenantObj.status}`)
            }
            const updatedAllDistributorObj = allDistributorObjectives.map(objective => { 
                if(objective.objectivedistributorid === parseInt(objectiveDistributorId)) {
                    objective.realvalue = objectiveRealValue
                }
                return objective
            })
            setAllDistributorObjectives([...updatedAllDistributorObj]) 
        }
        catch (error) {
            throw Error(`Error while updating the distriburor objective id: ${objectiveDistributorId} for the distributor id: ${logic.distributorId.toString()}, and tenant id: ${logic.tenantId.toString()}. Error: ${error}`)
        }
    }

    const insertDistTenantObj = async ({ objectiveId, distributorId, realValue, registryTime }) => {
        try {
            const newDistObjective = await logic.insertDistTenantObj(objectiveId, distributorId, realValue, registryTime)
            if (newDistObjective.status !== 200) {
                throw Error(`${newDistObjective.status}`)
            }
        }
        catch (error) {
            throw Error(`Error while adding a new objective for the distributor  id: ${distributorId}, and tenant id: ${logic.tenantId.toString()}. Error: ${error}`)
        }
    }

    const updateObjDistributor = async ({ objectiveId, distributorId, distributorObjCode }) => {
        try {
            const updatedObjDistributor = await logic.updateObjDistributor(objectiveId, distributorId, distributorObjCode)
            if (updatedObjDistributor.status !== 200) {
                throw Error(`${updatedObjDistributor.status}`)
            }
            const updatedAllDistributorObj = allDistributorObjectives.map(objective => { 
                if(objective.objectiveid === parseInt(objectiveId) && objective.distributorid === parseInt(distributorId)) {
                    objective.distributorobjcode = distributorObjCode
                }
                return objective
            })
            setAllDistributorObjectives([...updatedAllDistributorObj]) 
        }
        catch (error) {
            throw Error(`Error while updating the distributor objective with objective id: ${objectiveId} for the distributor id: ${distributorId}. Error: ${error}`)
        }
    }

    const fetchAllDistributorObjectives = async () => {
        try {
            const distributorId = logic.distributorId.toString()
            const distributorObjectives = await logic.getDistributorObjectives(distributorId)
            if (distributorObjectives.status !== 200) {
                throw Error(`${distributorObjectives.status}`)
           }
            setAllDistributorObjectives([...distributorObjectives.data])
            return distributorObjectives.data
        }
        catch (error) {
            throw Error("Error while getting the distributor's objectives")
        }
    }

    const fetchAllDistributorTenantObjectives = async () => {
        try {
            const tenantId = logic.tenantId.toString()
            const distributorTenantObjectives = await logic.getDistributorTenantObjectives(tenantId)
            if (distributorTenantObjectives.status !== 200) {
                throw Error(`${distributorTenantObjectives.status}`)
           }
           setAllDistributorTenantObjectives([...distributorTenantObjectives.data])
           return distributorTenantObjectives.data
        }
        catch (error) {
            throw Error("Error while getting the tenant-distributor's objectives")
        }
    }

    const downloadAllDistributorsTenantSales = async (salesData) => {
        try {
            const theFile = await logic.downloadAllSales(salesData)
            return theFile
        }
        catch (error) {
            throw Error("Error while downloading all the distributors' tenant sales report")
        }
    }

    const fetchAllAdminDashboards = async () => {
        try {
            const dashboards = await logic.getDashboardsAdmin()
            if (dashboards.status !== 200) {
                throw Error(`${dashboards.status}`)
            }
            setAllAdminDashboards(dashboards.data)
            return dashboards.data
        }
        catch (error) {
            throw Error("Error while getting the admin's dashboards")
        }
    }

    const activateAdminDashboard = async (dashboardId) => {
        try {
            const activedDashboard = await logic.activateAdminDashboard(dashboardId)
            const updatedAllAdminDashboards = allAdminDashboards.map(dashboard => { 
                if(dashboard.dashboardid === activedDashboard.data[0].dashboardid) {
                  dashboard.active = !dashboard.active
                }
                return dashboard
              })
            setAllAdminDashboards([...updatedAllAdminDashboards])    
            alert.show(t('dashboardsAdminActivated'), { type: 'success' })
            return activedDashboard.data[0]
        }
        catch (error) {
            throw Error(`Error while activating the dashboard id: ${dashboardId}`)
        }
    }

    const inactivateAdminDashboard = async (dashboardId) => {
        try {
            const inactivedDashboard = await logic.inactivateAdminDashboard(dashboardId)
            const updatedAllAdminDashboards = allAdminDashboards.map(dashboard => { 
                if(dashboard.dashboardid === inactivedDashboard.data[0].dashboardid) {
                    dashboard.active = !dashboard.active
                }
                return dashboard
              })
              setAllAdminDashboards([...updatedAllAdminDashboards]) 
            alert.show(t('dashboardsAdminInactivated'), { type: 'success' })
            return inactivedDashboard.data[0]
        }
        catch (error) {
            throw Error(`Error while inactivating the dashboard id: ${dashboardId}`)
        }
    }

    const fetchAllAdminTables = async () => {
        try {
            const tables = await logic.loadTableOfTables()
            if (tables.status !== 200) {
                throw Error(`${tables.status}`)
            }
            setAllTablesOfTables(tables.data)
            return tables.data
        }
        catch (error) {
            throw Error("Error while getting the admin's tables")
        }
    }

    const activateAdminTable = async (tableOfTablesId) => {
        try {
            const activedTable = await logic.activateAdminTable(tableOfTablesId)
            const updatedAllAdminTables = allTableOfTables.map(table => { 
                if(table.tableoftablesid === activedTable.data[0].tableoftablesid) {
                    table.active = !table.active
                }
                return table
              })
            setAllTablesOfTables([...updatedAllAdminTables])    
            alert.show(t('tablesAdminActivated'), { type: 'success' })
            return activedTable.data[0]
        }
        catch (error) {
            throw Error(`Error while activating the table of tables with id: ${tableOfTablesId}`)
        }
    }

    const inactivateAdminTable = async (tableOfTablesId) => {
        try {
            const inactivedTable = await logic.inactivateAdminTable(tableOfTablesId)
            const updatedAllAdminTables = allTableOfTables.map(table => { 
                if(table.tableoftablesid === inactivedTable.data[0].tableoftablesid) {
                    table.active = !table.active
                }
                return table
              })
              setAllTablesOfTables([...updatedAllAdminTables]) 
            alert.show(t('tablesAdminInactivated'), { type: 'success' })
            return inactivedTable.data[0]
        }
        catch (error) {
            throw Error(`Error while inactivating the table of tables with id: ${tableOfTablesId}`)
        }
    }

    return (
        <AdsContext.Provider value={{
            allGroups, setAllGroups,
            allTenantObjectives, setAllTenantObjectives,
            allTenantAgreementsTypes, setAllTenantAgreementsTypes,
            allDistributorTenantObjectives, setAllDistributorTenantObjectives,
            allDistributorObjectives, setAllDistributorObjectives,
            sharedObjective, setSharedObjective,
            sharedObjDisabled, setSharedObjDisabled,
            shrObjToDateDisabled, setShrObjToDateDisabled,
            boldDistributors, setBoldDistributors,
            boldProducts, setBoldProducts,
            boldBusUnits, setBoldBusUnits,
            boldBrands, setBoldBrands,
            boldGroups, setBoldGroups,
            boldSets, setBoldSets,
            boldPostalCodes, setBoldPostalCodes,
            boldCountries, setBoldCountries,
            boldRegions, setBoldRegions,
            boldProvinces, setBoldProvinces,
            originalObjCode, setOriginalObjCode,
            infZonesCheckedBoxes, setInfZonesCheckedBoxes,
            productsCheckedBoxes, setProductsCheckedBoxes,
            allAdminDashboards, setAllAdminDashboards,
            allTableOfTables, setAllTablesOfTables,
            fetchAllTenantClientsGroup,
            fetchAllTenantObjectives,
            fetchAllTenantAgreementsTypes,
            fetchAllTenantDistributors,
            fetchAllTenantProductsIds,
            fetchAllPostalCodes,
            fetchOnePostalCode,
            fetchOnePostalCodeById,
            fetchAllCountries,
            fetchAllRegions,
            fetchAllProvinces,
            activateTenantObjective,
            inactivateTenantObjective,
            inactivateTenantAgreementType,
            activateTenantAgreementType,
            updateTenantObjective,
            updateTenantAgreementType,
            insertTenantObjective,
            insertTenantAgreementType,
            fetchAllDistributorObj,
            updateDistTenantObj,
            insertDistTenantObj,
            updateObjDistributor,
            fetchAllDistributorObjectives,
            fetchAllDistributorTenantObjectives,
            downloadAllDistributorsTenantSales,
            fetchAllAdminDashboards,
            activateAdminDashboard, 
            inactivateAdminDashboard,
            fetchAllAdminTables,
            activateAdminTable, 
            inactivateAdminTable 
 
        }}>
            {props.children}
        </AdsContext.Provider>
    )
}
export default AdsContextProvider;