/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React from 'react'
import { Control, Input, Icon, Field } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const FormError = ({ message, showMessage, closeMessage, type, closeButton = true }) => {
  return (
    showMessage &&
    <Field>
      <Field.Body>
    <Control onClick={closeMessage} textColor={type} iconLeft iconRight>
      <Input style={{ cursor: 'pointer' }} textColor={type} readOnly static value={message} />
      <Icon color={type} align='left'>
        <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
      </Icon>
      {closeButton && <Icon align='right' color='dark'>
              <FontAwesomeIcon size='1x' icon={['fas', 'times-circle']} />
                      </Icon>}
    </Control>
      </Field.Body>
    </Field>
  )
}
