/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState, useContext } from 'react'
import { useAlert } from 'react-alert'
import { Select, Textarea, Box, Title, Field, Input, Control, Label, Button, Icon, Level } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11'
import { FormError, HelpError } from '../../../error'
import { TabsObjectives} from '../../tabsObjectives/tabsObjectives'
import { useConfirmActionMessage } from '../../../../logic/hooks'
import { AdsContext } from "../../../../contexts/adsContext"
import { N_M_OBJ_TABLES, AGREEMENT_VALUE_TYPES } from '../../../../config/config'
import { logic } from '../../../../logic'

export const AddEditObjectivesTenant = () => {
  const alert = useAlert()
  const { allTenantObjectives, 
          sharedObjective, setSharedObjective,
          sharedObjDisabled, setSharedObjDisabled,
          shrObjToDateDisabled, setShrObjToDateDisabled,
          originalObjCode, setOriginalObjCode,
          setBoldDistributors,
          setBoldPostalCodes,
          setBoldCountries,
          setBoldRegions,
          setBoldProvinces,
          setBoldProducts, 
          setBoldBusUnits, 
          setBoldBrands, 
          setBoldGroups, 
          setBoldSets,
          setInfZonesCheckedBoxes,
          setProductsCheckedBoxes,
          fetchAllTenantClientsGroup,
          fetchAllTenantAgreementsTypes,
          fetchAllDistributorObj,
          updateTenantObjective, 
          insertTenantObjective } = useContext(AdsContext)
  
  // Error handling
  const confirmAction = useConfirmActionMessage()

  const { t } = useTranslation()
  const history = useHistory()
  const { id } = useParams()
  const { register, handleSubmit, errors, watch } = useForm()
  const [clientsGroups, setClientsGroups] = useState([])
  const [agreementsTypes, setAgreementsTypes] = useState(['dummy'])
  const [objective, setObjective] = useState()
 
  const [dateStart2, setDateStart2] = useState(new Date())
  const [dateEnd2, setDateEnd2] = useState(new Date())
  const [initialDateEnd, setInitialDateEnd] = useState(new Date())
  const [olderRegistryTime, setOlderRegistryTime] = useState('0000-00-00')

  useEffect(() => {
    (async function () {
      const allTenantClientsGroup = await fetchAllTenantClientsGroup()
      const allTenantAgreementsTypes = await fetchAllTenantAgreementsTypes()
      setAgreementsTypes(
        allTenantAgreementsTypes
        .filter(agreementType => {
          if (agreementType.active) return agreementType
        })
        .sort((a,b) => {
          if (a.agreementtypename < b.agreementtypename) {
            return -1
          }
          if (a.agreementtypename > b.agreementtypename) {
            return 1
          }
          return 0
        })
        .map(agreementType => {
        return {
          label: `${agreementType.agreementtypename}`,
          value: agreementType.agreementtypeid
        }
        })
      )
      setClientsGroups(
        allTenantClientsGroup
        .filter(clientGroup => {
          if (clientGroup.active) return clientGroup
        })
        .map(clientGroup => {
        return {
          label: `${clientGroup.clientname}`,
          value: clientGroup.clientgroupid
        }
        })
      )
      let selectedObjective
      if (id) {
        if (sharedObjective && sharedObjective.length > 0) {
          selectedObjective = JSON.parse(JSON.stringify(sharedObjective))
          setObjective([...selectedObjective])
        } else {
          selectedObjective = JSON.parse(JSON.stringify(allTenantObjectives.filter(objective => {
            if (objective.objectiveid === parseInt(id)) return objective
          })))     
          setObjective([...selectedObjective])
          setSharedObjective([...selectedObjective])
          setInfZonesCheckedBoxes({
           country: selectedObjective[0].country.length > 0 ? true : false,
           region: selectedObjective[0].region.length > 0 ? true : false,
           province: selectedObjective[0].province.length > 0 ? true : false,
           postalcode: selectedObjective[0].postalcode.length > 0 ? true : false
          })
          setProductsCheckedBoxes({
            businessunit: selectedObjective[0].businessunit.length > 0 ? true : false,
            brand: selectedObjective[0].brand.length > 0 ? true : false,
            group: selectedObjective[0].group.length > 0 ? true : false,
            set: selectedObjective[0].set.length > 0 ? true : false,
            product: selectedObjective[0].product.length > 0 ? true : false
           })
          setOriginalObjCode(selectedObjective[0].objectivecode)
        }
        const objectiveStartDate = new Date(selectedObjective[0].starttime)
        setDateStart2(`${objectiveStartDate.getFullYear()}-${(objectiveStartDate.getMonth()+1).toString().padStart(2, '0')}-${objectiveStartDate.getDate().toString().padStart(2, '0')}`)
        const objectiveEndDate = new Date(selectedObjective[0].endtime)
        setDateEnd2(`${objectiveEndDate.getFullYear()}-${(objectiveEndDate.getMonth()+1).toString().padStart(2, '0')}-${objectiveEndDate.getDate().toString().padStart(2, '0')}`)
        setInitialDateEnd(`${objectiveEndDate.getFullYear()}-${(objectiveEndDate.getMonth()+1).toString().padStart(2, '0')}-${objectiveEndDate.getDate().toString().padStart(2, '0')}`)
        
        const distributorsObj = await fetchAllDistributorObj(id)
        if (distributorsObj.length > 0) {
          setSharedObjDisabled(true)
          setShrObjToDateDisabled(checkMinimumToDate(distributorsObj, new Date(selectedObjective[0].endtime)))
        } else {
          setSharedObjDisabled(false)
          setShrObjToDateDisabled(false)
        }
      } else {
        setSharedObjDisabled(false)
        setShrObjToDateDisabled(false)
        if (sharedObjective && sharedObjective.length > 0) {
          selectedObjective = JSON.parse(JSON.stringify(sharedObjective))
          setObjective([...selectedObjective])
          const objectiveStartDate = new Date(selectedObjective[0].starttime)
          setDateStart2(`${objectiveStartDate.getFullYear()}-${(objectiveStartDate.getMonth()+1).toString().padStart(2, '0')}-${objectiveStartDate.getDate().toString().padStart(2, '0')}`)
          const objectiveEndDate = new Date(selectedObjective[0].endtime)
          setDateEnd2(`${objectiveEndDate.getFullYear()}-${(objectiveEndDate.getMonth()+1).toString().padStart(2, '0')}-${objectiveEndDate.getDate().toString().padStart(2, '0')}`)
          setInitialDateEnd(`${objectiveEndDate.getFullYear()}-${(objectiveEndDate.getMonth()+1).toString().padStart(2, '0')}-${objectiveEndDate.getDate().toString().padStart(2, '0')}`)
        } else {
          const currentDate2 = new Date()
          setDateStart2(`${currentDate2.getFullYear()}-${(currentDate2.getMonth()+1).toString().padStart(2, '0')}-01`)
          setDateEnd2(`${currentDate2.getFullYear()}-${(currentDate2.getMonth()+1).toString().padStart(2, '0')}-${new Date(currentDate2.getFullYear(), currentDate2.getMonth()+1, 0).getDate()}`)
          setInitialDateEnd(`${currentDate2.getFullYear()}-${(currentDate2.getMonth()+1).toString().padStart(2, '0')}-${new Date(currentDate2.getFullYear(), currentDate2.getMonth()+1, 0).getDate()}`)
          const newObjective = [{
            active: true,
            brand: [],
            businessunit: [],
            clientgroupid: null,
            distributor: [],
            endtime: new Date(`${currentDate2.getFullYear()}-${currentDate2.getMonth()+1}-${new Date(currentDate2.getFullYear(), currentDate2.getMonth()+1, 0).getDate()}`),
            group: [],
            objectivedescription: "",
            objectiveid: null,
            objectivecode: "",
            objectivename: "",
            postalcode: [],
            product: [],
            set: [],
            country: [],
            region: [],
            province: [],
            starttime: new Date(`${currentDate2.getFullYear()}-${currentDate2.getMonth()+1}-01`),
            tenantid: logic.tenantId.toString(),
            totalvalueobjective: "",
            agreementtypeid: null,
            valuetype: ""
          }]
          setObjective([...newObjective])
          setSharedObjective([...newObjective])
        }
      }
    })();
  }, [])
  
  const checkMinimumToDate = (distributorsObj, endtime) => {
    const pureEndDate = `${endtime.getFullYear()}-${(endtime.getMonth()+1).toString().padStart(2, '0')}-00`
    let mustToDateBeDisable = false
    let newOlderRegistryTime = '0000-00-00'
    for (let index = 0; index < distributorsObj.length; index++) {
      const registryTime = new Date(distributorsObj[index].registrytime)
      const pureRegistryTime = `${registryTime.getFullYear()}-${(registryTime.getMonth()+1).toString().padStart(2, '0')}-${registryTime.getDate().toString().padStart(2, '0')}`
      if (pureRegistryTime > newOlderRegistryTime) newOlderRegistryTime = pureRegistryTime
      if (pureRegistryTime > pureEndDate) mustToDateBeDisable = true
    }
    setOlderRegistryTime(newOlderRegistryTime)
    return mustToDateBeDisable
  }

  const handleGoBackClick = () => {
    history.push('/agreements/agreements')
  }

  const handleChangeDateStart2 = (event) => {
    const { name, value } = event.target
    if (value > dateEnd2) {
      alert.show(t('fecha inicial debe ser anterior a fecha final'), { type: 'error' })
    }
    setDateStart2(value)
    handleChange('dateStart', new Date(value))
  }

  const handleChangeDateEnd2 = (event) => {
    const { name, value } = event.target
    if (dateStart2 > value) {
      alert.show(t('fecha inicial debe ser anterior a fecha final'), { type: 'error' })
    }
    if (olderRegistryTime > value) {
      alert.show(t('Fecha final debe ser posterior a la última fecha con registro de ventas del distribuidor'), { type: 'error' })
    }
    setDateEnd2(value)
    handleChange('dateEnd', new Date(value))
  }

  const handleChangeField = (event) => {
    const { name, value } = event.target
    handleChange(name, value)
  }

  const handleChange = (name, value) => {
    let newShrObj = [...sharedObjective]
    switch (name) {
      case 'code': 
        newShrObj[0].objectivecode = value
        break
      case 'name': 
        newShrObj[0].objectivename = value
        break
      case 'comments': 
        newShrObj[0].objectivedescription = value
        break
      case 'clientgroupid':
        newShrObj[0].clientgroupid = parseInt(value)
        break
      case 'economicValue':
        newShrObj[0].totalvalueobjective = value.replace(/,/g, '.')
        break
      case 'dateStart':
         newShrObj[0].starttime = value 
         break
      case 'dateEnd':
         newShrObj[0].endtime = value    
         break
      case 'agreementtypeid':
         newShrObj[0].agreementtypeid = parseInt(value)    
         break
      case 'valuetype':
         newShrObj[0].valuetype = value    
         break
      default:
        break
    }
    setSharedObjective([...newShrObj])
  }

 // We receive all the identities on the screens (identitiesToSend) and their original situation (apiObjective)  
 // and we return an array of nested arrays with two elemenst (identities to add and to remove)
 const prepareIdsToSend = (identitiesToSend, apiObjective = []) => {
    let ids = {}
    let identitiesToAdd, identitiesToRemove = []
    N_M_OBJ_TABLES.forEach(nToMObjTable => {
      let apiIdentities = []
      if (apiObjective.length > 0) apiIdentities = [...apiObjective[0][nToMObjTable[0]]]
      identitiesToAdd = identitiesToSend[nToMObjTable[0]].filter(identityToSend => {
        const found = apiIdentities.find(identity => identity === identityToSend ) 
        if(!found) return identityToSend       
      })
      identitiesToRemove = apiIdentities.filter(identityToSend => { 
        if(!identitiesToSend[nToMObjTable[0]].some(identityToRemove => identityToRemove === identityToSend)) return identityToSend
      })
      ids[nToMObjTable[0]] = [ identitiesToAdd, identitiesToRemove ]
    })
    return ids
  }
  
  const onSubmitData = data => {
    const { code, name, comments, clientgroupid, economicValue, dateStart2, dateEnd2, agreementtypeid, valuetype } = data
    // We check the name doesn't exist to avoid duplicated objectives names for a tenant
    const existsCode = allTenantObjectives.filter(objective => { if (objective.objectivecode === code) return objective })
    if (existsCode && existsCode.length > 0 && code !== originalObjCode) {
      alert.show(t(`The code already exists`), {
        type: 'error'
      })
      return
    }
    if (dateStart2 > dateEnd2) {
      alert.show(t('fecha inicial debe ser anterior o igual a fecha final'), { type: 'error' })
      return
    }
    if (olderRegistryTime > dateEnd2) {
      alert.show(t('Fecha final debe ser posterior a la última fecha con registro de ventas del distribuidor'), { type: 'error' })
      return
    }
    confirmAction.closeMessage()

    // We prepare all the identities to be send to look next for identities added and/or identities removed.
    let identitiesToSend = {}
    N_M_OBJ_TABLES.forEach(nToMObjTable => {
      identitiesToSend[nToMObjTable[0]] = [].concat(...sharedObjective[0][nToMObjTable[0]])
    })

    // We call a function that will review all the identities on the screens and will check whether the user has added them (to send 
    // to the api for inserting a new identity to the n:m table) or has removed them (to send to the api for deleting an old identity from the n:m table)
    // The function returns an array with nested arrays of two elements: identities to be send and to be removed.
    const distributorsIDs = prepareIdsToSend(identitiesToSend,  allTenantObjectives.filter(objective => {
      if (objective.objectiveid === parseInt(id ? id : -1)) return objective
    })) 
    let tenantObjective = {
      objectiveCode: code,
      objectiveName: name,
      objectiveDescription: comments,
      clientGroupId: clientgroupid.length > 0  ? clientgroupid : null,
      startTime: new Date(dateStart2),
      endTime: new Date(dateEnd2),
      previousEndTime: new Date(initialDateEnd), // Not recorded or updated, only used for audittrail record on update
      totalValueObjective: parseFloat(economicValue.replace(/,/g, '.')),
      agreementTypeId: agreementtypeid,
      valueType: valuetype
    }
    if (id) {
      tenantObjective.objectiveId = id.toString()
      N_M_OBJ_TABLES.forEach(nToMObjTable => {
        tenantObjective[nToMObjTable[1]] = distributorsIDs[nToMObjTable[0]][0].join(";")
        tenantObjective[nToMObjTable[2]] = distributorsIDs[nToMObjTable[0]][1].join(";")
      })
    } else {
      N_M_OBJ_TABLES.forEach(nToMObjTable => {
        tenantObjective[nToMObjTable[1]] = distributorsIDs[nToMObjTable[0]][0].join(";")
      })
    }
    if (id) {
      updateTenantObjective(tenantObjective, identitiesToSend)
    } 
    else {
      insertTenantObjective(tenantObjective)
    }
    setBoldDistributors([])
    setBoldPostalCodes([])
    setBoldCountries([])
    setBoldRegions([])
    setBoldProvinces([])
    setBoldProducts([]) 
    setBoldBusUnits([]) 
    setBoldBrands([]) 
    setBoldGroups([]) 
    setBoldSets([])
  }

  return (
    <Box>
      <TabsObjectives highlight='objectives' isInactive='is-active' id={id && parseInt(id) > -1 ? id : -1} />
      <Button onClick={e => handleGoBackClick()} color='light' size='medium'>
        <Icon>
          <FontAwesomeIcon size='lg' icon={['fas', 'chevron-circle-left']} />
        </Icon>
      </Button>
      <Title>
        {id
          ? `${t('edit')} ${objective && objective[0].objectivecode}`
          : `${t('add')} ${t('objective')}`}
      </Title>
      <form onSubmit={handleSubmit(onSubmitData)}>

      <Field>
          <Label>{t('code')}</Label>
          <Control iconLeft>
            <Input
              defaultValue={objective && objective[0].objectivecode}
              onChange={ (event) => handleChangeField(event)}
              type='text'
              color='light'
              name='code'
              ref={register({
                required: true,
                maxLength: 50
              })}
              disabled={sharedObjDisabled}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'archive']} />
            </Icon>
          </Control>
          {errors.code &&
            errors.code.type === 'required' && (
              <HelpError message={t('required')} />
            )}
          {errors.code &&
            errors.code.type === 'maxLength' && (
              <HelpError message={t('tooManyCharacters')} />
            )}
        </Field>
        
        <Field>
          <Label>{t('name')}</Label>
          <Control iconLeft>
            <Input
              defaultValue={objective && objective[0].objectivename}
              onChange={ (event) => handleChangeField(event)}
              type='text'
              color='light'
              name='name'
              ref={register({
                maxLength: 200
              })}
              disabled={sharedObjDisabled}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {errors.name &&
            errors.name.type === 'required' && (
              <HelpError message={t('required')} />
            )}
          {errors.name &&
            errors.name.type === 'maxLength' && (
              <HelpError message={t('tooManyCharacters')} />
            )}
        </Field>

        <Field>
          <Label>{t('comments')}</Label>

          <Control>
            <Textarea
              defaultValue={objective && objective[0].objectivedescription}
              onChange={ (event) => handleChangeField(event)}
              color='light'
              name='comments'
              ref={register({
                maxLength: 1000
              })}
              placeholder={t('comments')}
            />
          </Control>
          {errors.comments && errors.comments.type === 'maxLength' && (
            <HelpError message={t('tooManyCharacters')} />
          )}
          {errors.comments &&
            errors.comments.type === 'required' && (
              <HelpError message={t('required')} />
            )}
        </Field>

        <Field>
          <Label>{t('select_agreement_name')}:</Label>
          <Control expanded>
            <Select.Container color='light' fullwidth>
              <Select
                name='agreementtypeid'
                ref={register({
                  required: true
                })}
                value={watch('agreement_types')}
                onChange={ (event) => handleChangeField(event)}
                disabled={sharedObjDisabled}
              >
                <Select.Option value=''>{t('select')}</Select.Option>

                {agreementsTypes &&
                  agreementsTypes.map((agreementType, index) => {
                    let parsedAgreementTypeId = 0
                    parsedAgreementTypeId = objective && parseInt(objective[0].agreementtypeid)
                    
                    return (
                      <Select.Option
                        key={index}
                        value={agreementType && `${agreementType.value}`}
                        selected={ agreementType && agreementType.value === parsedAgreementTypeId ? 'selected' : null}
                      >
                        {agreementType && agreementType.label}
                      </Select.Option>
                    )
                  }
                  )}
              </Select>
            </Select.Container>
          </Control>
          {errors.agreementtypeid &&
            errors.agreementtypeid.type === 'required' && (
              <HelpError message={t('required')} />
            )}
        </Field>

        <Field>
          <Label>{t('objective_groups')}:</Label>
          <Control expanded>
            <Select.Container color='light' fullwidth>
              <Select
                name='clientgroupid'
                ref={register({
                  required: false
                })}
                value={watch('clients_groups') || undefined}
                onChange={ (event) => handleChangeField(event)}
                disabled={sharedObjDisabled}
              >
                <Select.Option value=''>{t('select')}</Select.Option>

                {clientsGroups &&
                  clientsGroups.map((clientGroup, index) => {
                    let parsedClientGroupId = 0
                    parsedClientGroupId = objective && parseInt(objective[0].clientgroupid)
                    
                    return (
                      <Select.Option
                        key={index}
                        value={clientGroup && `${clientGroup.value}`}
                        selected={ clientGroup && clientGroup.value === parsedClientGroupId ? 'selected' : null}
                      >
                        {clientGroup && clientGroup.label}
                      </Select.Option>
                    )
                  }
                  )}
              </Select>
            </Select.Container>
          </Control>
          {errors.clientgroupid &&
            errors.clientgroupid.type === 'required' && (
              <HelpError message={t('required')} />
            )}
        </Field>
        <Level>
          <Level.Item align="left">      
              <Level.Item>
                <Field>
                  <Label>{t('value')}</Label>
                      
                  <Control>
                    <Input
                      defaultValue={objective && objective[0].totalvalueobjective}
                      onChange={ (event) => handleChangeField(event)}
                      type='number'
                      min="0"
                      step={ objective && objective[0].valuetype === 'euros' ? "0.01" : "1" } // AMN - necessary to allow entering cents (only two decimals), don't remove it
                      color='light'
                      name='economicValue'
                      ref={register({
                        required: true,
                        maxLength: 10
                      })}
                      disabled={sharedObjDisabled}
                    />
                  </Control>
                  {errors.economicValue && errors.economicValue.type === 'maxLength' && (
                    <HelpError message={t('tooManyCharacters')} />
                  )}
                  {errors.economicValue &&
                    errors.economicValue.type === 'required' && (
                      <HelpError message={t('required')} />
                    )}
                </Field>
              </Level.Item>
          
              <Level.Item>
                <Field>
                  <Label>{t('select_value_type')}:</Label>
                  <Control expanded>
                    <Select.Container color='light' fullwidth>
                      <Select
                        name='valuetype'
                        ref={register({
                          required: true
                        })}
                        value={watch('values_types')}
                        onChange={ (event) => handleChangeField(event)}
                        disabled={sharedObjDisabled}
                      >
                        <Select.Option value=''>{t('select')}</Select.Option>

                        {AGREEMENT_VALUE_TYPES.map((valueType, index) => {
                            const valueTypeSelected = objective && objective[0].valuetype
                            return (
                              <Select.Option
                                key={index}
                                value={valueType}
                                selected={ valueType  === valueTypeSelected ? 'selected' : null}
                              >
                                {t(valueType)}
                              </Select.Option>
                            )
                          }
                          )}
                      </Select>
                    </Select.Container>
                  </Control>
                  {errors.valuetype &&
                    errors.valuetype.type === 'required' && (
                      <HelpError message={t('required')} />
                    )}
                </Field>
              </Level.Item>
            </Level.Item>
            <Level.Item textAlign="centered">  
               <Level.Item>
                  <Field>
                    <Label>{t('Desde')}</Label>
                        
                    <Control  >
                      <Input
                        defaultValue={objective && dateStart2}
                        onChange={(event) => handleChangeDateStart2(event)}
                        type='date'
                        color='light'
                        name='dateStart2'
                        ref={register({
                          required: true
                        })}
                        disabled={sharedObjDisabled}
                      />
                    </Control>
                    {errors.dateStart2 &&
                      errors.dateStart2.type === 'required' && (
                        <HelpError message={t('required')} />
                      )}
                  </Field>
                </Level.Item>

                <Level.Item>
                  <Field>
                    <Label>{t('Hasta')}</Label>
                    <Control  >
                      <Input
                        defaultValue={objective && dateEnd2}
                        onChange={(event) => handleChangeDateEnd2(event)}
                        type='date'
                        color='light'
                        name='dateEnd2'
                        ref={register({
                          required: true
                        })}
                        disabled={shrObjToDateDisabled}
                      />
                    </Control>
                    {errors.dateEnd2 &&
                      errors.dateEnd2.type === 'required' && (
                        <HelpError message={t('required')} />
                      )}
                  </Field>
                </Level.Item>
            </Level.Item>
        </Level>
        <Field kind='group'>
          <Control>
            {id ? (
              <Button disabled={sharedObjDisabled && shrObjToDateDisabled} color='link'>{t('update')}</Button>
            ) : (
                <Button color='link'>{t('add')}</Button>
              )}
          </Control>
          <Control>
            <Button as={Link} to='/agreements/agreements' type='button' text>
              {t('cancel')}
            </Button>
          </Control>
          {confirmAction.showMessage && <FormError {...confirmAction} />}
        </Field>
      </form>
    </Box>
  )
}