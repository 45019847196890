// Adds all the fontawesome icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { faApple } from '@fortawesome/free-brands-svg-icons/faApple'
import {
  faCheckSquare,
  faCoffee,
  faUser,
  faLanguage,
  faQuestionCircle,
  faPowerOff,
  faShoppingBasket,
  faExchangeAlt,
  faWarehouse,
  faStore,
  faMountain,
  faClipboardList,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faSignInAlt,
  faEnvelope,
  faLock,
  faExclamationTriangle,
  faMusic,
  faFilm,
  faImage,
  faFileAlt,
  faEdit,
  faTrash,
  faPlus,
  faChevronCircleLeft,
  faTimesCircle,
  faCopyright,
  faBriefcase,
  faLayerGroup,
  faSearch,
  faNotEqual,
  faSpinner,
  faArchive,
  faFileSignature,
  faDatabase,
  faUpload,
  faHome,
  faPhone,
  faUsersCog,
  faUserFriends,
  faTasks,
  faFileUpload,
  faObjectGroup,
  faUsers,
  faBook,
  faCloudDownloadAlt,
  faCheckDouble,
  faCheck,
  faPoll,
  faUserCheck,
  faClipboardCheck,
  faAddressBook,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons'
import { faIdCard as faIdCardRegular } from '@fortawesome/free-regular-svg-icons'

library.add(
  faApple,
  faCheckSquare,
  faCoffee,
  faUser,
  faLanguage,
  faIdCardRegular,
  faQuestionCircle,
  faPowerOff,
  faShoppingBasket,
  faExchangeAlt,
  faWarehouse,
  faStore,
  faMountain,
  faClipboardList,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faSignInAlt,
  faEnvelope,
  faLock,
  faExclamationTriangle,
  faMusic,
  faFilm,
  faImage,
  faFileAlt,
  faEdit,
  faTrash,
  faPlus,
  faChevronCircleLeft,
  faTimesCircle,
  faCopyright,
  faBriefcase,
  faLayerGroup,
  faSearch,
  faNotEqual,
  faSpinner,
  faArchive,
  faFileSignature,
  faDatabase,
  faUpload,
  faHome,
  faPhone,
  faUsersCog,
  faUserFriends,
  faTasks,
  faFileUpload,
  faObjectGroup,
  faUsers,
  faBook,
  faCloudDownloadAlt,
  faCheckDouble,
  faCheck,
  faPoll,
  faUserCheck,
  faClipboardCheck,
  faAddressBook,
  faCheckCircle
)
