/* eslint-disable react/jsx-closing-tag-location */
import React, { useState }  from 'react'
import { withRouter, Switch, Route, Redirect } from 'react-router-dom'
import { PrivateRoute } from './components/privateRoute'
import { Dashboard } from './components/dashboard'
import { DashboardsAdmin } from './components/dashboardsAdmin'
import { AddEditDashboardsAdmin } from './components/dashboardsAdmin/addEditDashboardsAdmin'
import { TablesAdmin } from './components/tablesAdmin'
import { AddEditTablesAdmin } from './components/tablesAdmin/addEditTablesAdmin'
import { Profile } from './components/profile'
import { Login } from './components/login'
import { AvisoLegal } from '../src/config/legal/AvisoLegal'
import { PoliticaPrivacidad } from '../src/config/legal/PoliticaPrivacidad'
import { PoliticaCookies } from '../src/config/legal/PoliticaCookies'
import { ForgotPassword } from './components/forgotPassword'
import { Register } from './components/register'
import { ResetPassword } from './components/resetPassword'
import { AppContainer } from './components/layout/appContainer'
import { LoginContainer } from './components/layout/loginContainer'
import { logic } from './logic'
import { ProductTenant } from './components/product/productTenant'
import { ObjectivesGroup } from './components/objectives/objectivesGroup'
import { ClientCategories } from './components/group'
import { AddEditClientsGroupTenant } from './components/objectives/objectivesGroup/addEdit'
import { AddEditClientsCategoryTenant } from './components/group/addEdit'
import { AddEditObjTypes } from './components/objectives/objectivesTypes/addEdit'
import { AddEditObjectivesTenant } from './components/objectives/objectivesObjectives/addEdit'
import { AddEditObjProducts } from './components/objectives/objectivesObjectives/addEdit'
import { AddEditObjDistributors } from './components/objectives/objectivesObjectives/addEdit'
import { AddEditObjPostalCodes } from './components/objectives/objectivesObjectives/addEdit'
import { ObjectivesObjectives } from './components/objectives/objectivesObjectives'
import { ObjectivesTypes } from './components/objectives/objectivesTypes'
import { ProductBrand } from './components/product/productBrand'
import { AddEditProductBrand } from './components/product/productBrand/addEdit'
import { AddEditProductBusinessUnit } from './components/product/productBusinessUnit/addEdit'
import { ProductBusinessUnit } from './components/product/productBusinessUnit'
import { ProductGroup } from './components/product/productGroup'
import { ProductSet } from './components/product/productSet'
import { ProductValue } from './components/product/productValue'
import { AddEditProductGroup } from './components/product/productGroup/addEdit/addEditProductGroup'
import { AddEditProductSet } from './components/product/productSet/addEdit/addEditProductSet'
import { AddEditProductTenant } from './components/product/productTenant/addEdit'
import { AddEditProductValue } from './components/product/productValue/addEdit'
import { AddEditDistributor } from './components/distributor/addEdit'
import { ObjectivesSales } from './components/objectives/objectivesSales'
import { Distributor } from './components/distributor'
import { ExcelPage } from './components/excelPage'
import { PriceExcelPage } from './components/priceExcelPage'

import { Correspondance, NonCorrespondance } from './components/correspondance'
import { AddEditCorrespondance } from './components/correspondance/addEditCorrespondance'
import { Role } from './config/role'
import { ProductDistributor } from './components/product/productDistributor'
import { AddEditProductDistributor } from './components/product/productDistributor/addEdit/addEditProductDistributor'
import { UsersDistributor } from './components/distributor/usersDistributor'
import { AddEditUserDistributor } from './components/distributor/usersDistributor/addEdit'
import { Support } from './components/support'
import { SalesDistributorsReport } from './components/reports/SalesDistributorsReport'
import { StatusDistributor } from './components/reports/StatusDistributor'
import { MonthlySummary } from './components/reports/MonthlySummary'
import { StockDistributorsReport } from './components/reports/StockDistributorsReport'
import { ClientsReport } from './components/reports/ClientsReport'
import { ClientsCategoriesReport } from './components/reports/ClientsCategoriesReport'
import { UsersTenant } from './components/tenant/usersTenant'
import { MyTenant } from './components/tenant/myTenant'
import { AddEditUserTenant } from './components/tenant/usersTenant/addEdit/addEditUserTenant'
import { ProductTenantCorrespondance } from './components/product/productTenantCorrespondance'
import { DistributorUsers } from './components/distributorUsers/usersDistributor/index'
import { TenantsAdmin } from './components/tenantsAdmin'
import { UsersAdmin } from './components/usersAdmin'
import { AddEditUserAdmin } from './components/usersAdmin/addEditUserAdmin/addEditUserAdmin'
import { AddEditTenantAdmin } from './components/tenantsAdmin/addEditTenantAdmin/addEditTenantAdmin'
import { DistributorDashboard } from './components/distributorDashboard' 
import { AdminDashboard } from './components/adminDashboard/Dashboard'
import { UsersAdminUnblock } from './components/usersAdmin/UsersAdminUnblock'
import { UsersAdminChangePassword } from './components/usersAdmin/UsersAdminChangePassword'
import { ExcelPageAdmin } from './components/excelPageAdmin/ExcelPageAdmin'
import { NewClients } from './components/reports/NewClients'
import { NewClientsPerDistributor } from './components/reports/NewClientsPerDistributor'
import { TotalClientsPerDistributor } from './components/reports/TotalClientsPerDistributor'
import AdsContextProvider from '../src/contexts/adsContext';

if (process.env.REACT_APP_API_URL) logic.url = process.env.REACT_APP_API_URL

const App = () => {
  return (
    <>
      {
        logic.loggedIn
          ? <AdsContextProvider> 
              <AppContainer>
                <Switch>
                  <PrivateRoute role={Role.Admin} exact path='/tenants' component={TenantsAdmin} />
                  <PrivateRoute role={Role.Admin} exact path='/tenants/add' component={AddEditTenantAdmin} />
                  <PrivateRoute role={Role.Admin} exact path='/tenants/edit/:tenantIdentity/:tenantId' component={AddEditTenantAdmin} />
                  <PrivateRoute role={Role.Admin} exact path='/users' component={UsersAdmin} />
                  <PrivateRoute role={Role.Admin} exact path='/users/unblock' component={UsersAdminUnblock} />
                  <PrivateRoute role={Role.Admin} exact path='/users/changePassword' component={UsersAdminChangePassword} />
                  <PrivateRoute role={Role.Admin} exact path='/users' component={UsersAdmin} />
                  <PrivateRoute role={Role.Admin} exact path='/users/add' component={AddEditUserAdmin} />
                  <PrivateRoute role={Role.Admin} exact path='/users/edit/:userId' component={AddEditUserAdmin} />
                  <PrivateRoute role={Role.Tenant} exact path='/dashboard' component={Dashboard} />
                  <PrivateRoute role={Role.Distributor} exact path='/stats' component={DistributorDashboard} />
                  <PrivateRoute role={Role.Admin} exact path='/home' component={AdminDashboard} />
                  <PrivateRoute role={Role.Distributor} path='/profile' component={Profile} />
                  <PrivateRoute role={Role.Distributor} exact path='/support' component={Support} />
                  <PrivateRoute role={Role.Tenant} exact path='/tenant/users' component={UsersTenant} />
                  <PrivateRoute role={Role.Tenant} exact path='/tenant/users/add/:tenantId' component={AddEditUserTenant} />
                  <PrivateRoute role={Role.Tenant} exact path='/tenant/users/edit/:tenantId/:userId' component={AddEditUserTenant} />
                  <PrivateRoute role={Role.Tenant} exact path='/tenant/mytenant' component={MyTenant} />
                  <PrivateRoute role={Role.Tenant} exact path='/product/tenant' component={ProductTenant} />
                  <PrivateRoute role={Role.Distributor} exact path='/product/distributor' component={ProductDistributor} />
                  <PrivateRoute role={Role.Distributor} exact path='/product/distributor/edit/:id/:tenantId' component={AddEditProductDistributor} />
                  <PrivateRoute role={Role.Distributor} exact path='/product/distributor/add/:tenantId' component={AddEditProductDistributor} />
                  <PrivateRoute role={Role.Distributor} exact path='/staff' component={DistributorUsers} />
                  <PrivateRoute role={Role.Tenant} exact path='/product/tenant/edit/:id' component={AddEditProductTenant} />
                  <PrivateRoute role={Role.Tenant} exact path='/product/tenant/add' component={AddEditProductTenant} />
                  <PrivateRoute role={Role.Tenant} exact path='/product/tenant/correspondance/:id' component={ProductTenantCorrespondance} />
                  <PrivateRoute role={Role.Tenant} exact path='/product/brand' component={ProductBrand} />
                  <PrivateRoute role={Role.Tenant} exact path='/product/brand/edit/:id' component={AddEditProductBrand} />
                  <PrivateRoute role={Role.Tenant} exact path='/product/brand/add' component={AddEditProductBrand} />
                  <PrivateRoute role={Role.Tenant} exact path='/product/businessUnit/add' component={AddEditProductBusinessUnit} />
                  <PrivateRoute role={Role.Tenant} exact path='/product/businessUnit/edit/:id' component={AddEditProductBusinessUnit} />
                  <PrivateRoute role={Role.Tenant} exact path='/product/businessUnit' component={ProductBusinessUnit} />
                  <PrivateRoute role={Role.Tenant} exact path='/product/group/add' component={AddEditProductGroup} />
                  <PrivateRoute role={Role.Tenant} exact path='/product/group/edit/:id' component={AddEditProductGroup} />
                  <PrivateRoute role={Role.Tenant} exact path='/product/group' component={ProductGroup} />
                  <PrivateRoute role={Role.Tenant} exact path='/product/set' component={ProductSet} />
                  <PrivateRoute role={Role.Tenant} exact path='/product/value/edit/:productPriceId/:productTenantId' component={AddEditProductValue} />
                  <PrivateRoute role={Role.Tenant} exact path='/product/value/add/:productPriceId/:productTenantId' component={AddEditProductValue} />
                  <PrivateRoute role={Role.Tenant} exact path='/product/value' component={ProductValue} />
                  <PrivateRoute role={Role.Tenant} exact path='/product/set/add' component={AddEditProductSet} />
                  <PrivateRoute role={Role.Tenant} exact path='/product/set/edit/:id' component={AddEditProductSet} />
                  <PrivateRoute role={Role.Tenant} exact path='/reports/Status' component={StatusDistributor} />
                  <PrivateRoute role={Role.Tenant} exact path='/reports/Summary' component={MonthlySummary} />
                  <PrivateRoute role={Role.Tenant} exact path='/reports/Sales' component={SalesDistributorsReport} />
                  <PrivateRoute role={Role.Tenant} exact path='/reports/Stock' component={StockDistributorsReport} />
                  <PrivateRoute role={Role.Tenant} exact path='/reports/clients' component={ClientsReport} />
                  <PrivateRoute role={Role.Tenant} exact path='/reports/Categories' component={ClientsCategoriesReport} />
                  <PrivateRoute role={Role.Tenant} exact path='/reports/new' component={NewClients} />
                  <PrivateRoute role={Role.Tenant} exact path='/reports/newPerDistributor' component={NewClientsPerDistributor} />
                  <PrivateRoute role={Role.Tenant} exact path='/reports/totalPerDistributor' component={TotalClientsPerDistributor} />
                  <PrivateRoute role={Role.Tenant} exact path='/distributor' component={Distributor} />
                  <PrivateRoute role={Role.Tenant} exact path='/distributor/add' component={AddEditDistributor} />
                  <PrivateRoute role={Role.Tenant} exact path='/distributor/edit/:id' component={AddEditDistributor} />
                  <PrivateRoute role={Role.Tenant} exact path='/distributor/users/' component={UsersDistributor} />
                  <PrivateRoute role={Role.Tenant} exact path='/distributor/users/add/:distributorId' component={AddEditUserDistributor} />
                  <PrivateRoute role={Role.Tenant} exact path='/distributor/users/edit/:distributorId/:userId' component={AddEditUserDistributor} />
                  <PrivateRoute role={Role.Tenant} exact path='/correspondance' component={Correspondance} />
                  <PrivateRoute role={Role.Distributor} exact path='/data' component={ExcelPage} />
                  <PrivateRoute role={Role.Distributor} exact path='/pricedata' component={PriceExcelPage} />
                  <PrivateRoute role={Role.Admin} exact path='/dataAdmin' component={ExcelPageAdmin} />
                  <PrivateRoute role={Role.Tenant} exact path='/correspondance/not' component={NonCorrespondance} />
                  <PrivateRoute role={Role.Tenant} exact path='/correspondance/add/:productDistributorId' component={AddEditCorrespondance} />
                  <PrivateRoute role={Role.Tenant} exact path='/correspondance/edit/:productDistributorId/:productTenantId' component={AddEditCorrespondance} />
                  <PrivateRoute role={Role.Tenant} exact path='/clients/groups' component={ObjectivesGroup} />
                  <PrivateRoute role={Role.Tenant} exact path='/clients/groups/add' component={AddEditClientsGroupTenant} />
                  <PrivateRoute role={Role.Tenant} exact path='/clients/groups/edit/:id' component={AddEditClientsGroupTenant} />
                  <PrivateRoute role={Role.Tenant} exact path='/clients/categories' component={ClientCategories} />
                  <PrivateRoute role={Role.Tenant} exact path='/clients/categories/add' component={AddEditClientsCategoryTenant} />
                  <PrivateRoute role={Role.Tenant} exact path='/clients/categories/edit/:id' component={AddEditClientsCategoryTenant} />
                  <PrivateRoute role={Role.Tenant} exact path='/agreements/agreements' component={ObjectivesObjectives} />
                  <PrivateRoute role={Role.Tenant} exact path='/agreements/types' component={ObjectivesTypes} />
                  <PrivateRoute role={Role.Tenant} exact path='/agreements/types/add' component={AddEditObjTypes} />
                  <PrivateRoute role={Role.Tenant} exact path='/agreements/types/edit/:id' component={AddEditObjTypes} />
                  <PrivateRoute role={Role.Tenant} exact path='/agreements/agreements/add' component={AddEditObjectivesTenant} />
                  <PrivateRoute role={Role.Tenant} exact path='/agreements/agreements/edit/:id' component={AddEditObjectivesTenant} />
                  <PrivateRoute role={Role.Tenant} exact path='/agreements/products/add' component={AddEditObjProducts} />
                  <PrivateRoute role={Role.Tenant} exact path='/agreements/products/edit/:id' component={AddEditObjProducts} />
                  <PrivateRoute role={Role.Tenant} exact path='/agreements/distributors/add' component={AddEditObjDistributors} />
                  <PrivateRoute role={Role.Tenant} exact path='/agreements/distributors/edit/:id' component={AddEditObjDistributors} />
                  <PrivateRoute role={Role.Tenant} exact path='/agreements/postalcodes/add' component={AddEditObjPostalCodes} />
                  <PrivateRoute role={Role.Tenant} exact path='/agreements/postalcodes/edit/:id' component={AddEditObjPostalCodes} />
                  <PrivateRoute role={Role.Distributor} exact path='/agreements/sales' component={ObjectivesSales} />
                  <PrivateRoute role={Role.Admin} exact path='/settings/dashboards' component={DashboardsAdmin} />
                  <PrivateRoute role={Role.Admin} exact path='/settings/dashboards/add' component={AddEditDashboardsAdmin} />
                  <PrivateRoute role={Role.Admin} exact path='/settings/dashboards/edit/:dashboardId' component={AddEditDashboardsAdmin} />
                  <PrivateRoute role={Role.Admin} exact path='/settings/tables' component={TablesAdmin} />
                  <PrivateRoute role={Role.Admin} exact path='/settings/tables/add' component={AddEditTablesAdmin} />
                  <PrivateRoute role={Role.Admin} exact path='/settings/tables/edit/:tableOfTablesId' component={AddEditTablesAdmin} />
                  <Redirect from='*' to='/' />
                </Switch>
              </AppContainer>
            </AdsContextProvider>
          : <LoginContainer>
              <Switch>
                <Route path='/login' component={Login} />
                <Route path='/register/:token' component={Register} />
                <Route path='/forgotPassword' component={ForgotPassword} />
                <Route path='/resetPassword/:token' component={ResetPassword} />
                <Route path='/legal' component={AvisoLegal} />
                <Route path='/privacity' component={PoliticaPrivacidad} />
                <Route path='/cookies' component={PoliticaCookies} />
                <Redirect from='*' to='/login' component={Login} />
              </Switch>
            </LoginContainer>            
      }
    </>

  )
}
export default withRouter(App)