import React from 'react'
import { useAlert } from 'react-alert'
import { Container, Column, Box, Image, Field, Input, Control, Button, Icon } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import AmbitLogo from '../../config/images/ADS_fondoblanco.png'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FormError } from '../error'
import { logic } from '../../logic'
import { useConfirmActionMessage } from '../../logic/hooks'
import './forgotPassword.scss'
export const ForgotPassword = () => {
  const alert = useAlert()
  const history = useHistory()
  const { t } = useTranslation()
  const { register, handleSubmit, errors } = useForm()
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

  const errorAction = useConfirmActionMessage()
  const validateUserName = async (value) => {
    await sleep(0)
    if (value) {
      return true
    }
    return false
  }

  const onSubmitData = ({ email }) => {
    logic.resetPasswordMail({ email })
      .then(res => {
        if (res.status !== 200) {
          throw Error(res.status)
        }
        history.push('/')
      })
      .catch(err => {
        console.error('error', err.message)
        if (err.message === '429') {
          alert.show('tooManyRequests ', {
            type: 'error'
          })
        } else {
          alert.show('warning issue', {
            type: 'error'
          })
        }
      })
  }

  return (
    <Container id='forgotPassword'>
      <Column.Group>
        <Column size={4} offset={4}>
          <Box>
            <Image.Container size='2by1'>
              <Image className='logo' src={AmbitLogo} />
            </Image.Container>
            <form onSubmit={handleSubmit(onSubmitData)}>
              <Field>
                <Control iconLeft>
                  <Input name='email' ref={register({ required: true, validate: validateUserName })} color='light' type='email' placeholder={t('email')} autoFocus='' />
                  {errors.email && errors.email.type === 'required' && (
                    <FormError showMessage type='danger' message='Required' closeButton={false} />
                  )}
                  {errors.email && errors.email.type === 'validate' &&
                    (
                      <FormError message={t('mustUse bill@gmail.com')} />
                    )}
                  <Icon align='left'>
                    <FontAwesomeIcon icon={['fas', 'envelope']} />
                  </Icon>
                </Control>
              </Field>
              {errorAction.showMessage && <FormError {...errorAction} />}
              <Button color='info' fullwidth>
                <Container>
                  {t('Recuperar contraseña')}
                </Container>
                <Icon>
                  <FontAwesomeIcon size='lg' icon={['fas', 'sign-in-alt']} />
                </Icon>
              </Button>
            </form>
          </Box>
          <Container textAlign='centered' textColor='grey' />
        </Column>
      </Column.Group>
    </Container>
  )
}
