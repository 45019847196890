import React from 'react'
import './Legal.scss'
import {
  Image
} from 'rbx'
import AnimalDataSourceLogo from '../../config/images/ADS_fondoblanco.png'

export const AvisoLegal = () => (
  <>
    <div id="history" class="scroll-area">
      <Image.Container>
        <Image className='logo' src={AnimalDataSourceLogo} />
      </Image.Container>
      <br></br>  
      <p>
        <strong>Aviso Legal</strong>
      </p>
      La página www.animaldatasource.com  Animal Data Source ™ App es propiedad de Ambit Building Solutions Together, S.A (en adelante Ambit BST) con el NIF A-63069660 y domicilio en calle Roselló i Porcel, núm. 21, 8 planta, 08016, Barcelona. Sociedad inscrita en el Registro Mercantil de Barcelona, al Tomo 35.577, Folio 194 y Hoja B-263113.
      <br/>
      Para cualquier consulta o propuesta, contáctenos en el e-mail: legal@ambit-bst.com o en el teléfono +34 932 768 990.
      <p>
        <strong>1.	Condiciones Generales De Uso</strong>
        <strong />
      </p>
      <p>
        El acceso a Animal Data Source por parte del USUARIO está condicionado a la previa lectura y aceptación integra, expresa y sin reservas de los Términos y Condiciones de Uso vigentes en el momento del acceso, que rogamos lea detenidamente. 
      </p>
      <p>
        <strong>2.	Propiedad Intelectual</strong>
      </p>
      <p>
      Todos los contenidos, textos, imágenes, marcas y códigos fuente son de nuestra propiedad o de terceros a los que se han adquirido sus derechos de explotación, y están protegidos por los derechos de Propiedad Intelectual e Industrial.
      <br/>
      El usuario únicamente tiene derecho a un uso privado de los mismos, sin ánimo de lucro, y necesita autorización expresa para modificarlos, reproducirlos, explotarlos, distribuirlos o ejercer cualquier derecho perteneciente a su titular.
      </p>
      <p>
        <strong>3.	Condiciones De Acceso</strong>
      </p>
      <p>
      El acceso a Animal Data Source requiere un registro previo con Ambit BST.
      <br/>
      El usuario debe acceder a Animal Data Source conforme a la buena fe, las normas de orden público, los Términos y Condiciones, Política de privacidad y Aviso Legal. El acceso a Animal Data Source se realiza bajo la propia y exclusiva responsabilidad del usuario, que responderá en todo caso de los daños y perjuicios que pueda causar a terceros o a nosotros mismos por mal uso.
      </p>
      <p>
        <strong>4.	Responsabilidades</strong>
      </p>
      <p>
      Al poner a disposición del usuario Animal Data Source queremos ofrecerle un servicio de calidad, utilizando la máxima diligencia en la prestación de este, así como en los medios tecnológicos utilizados. No obstante, no responderemos de la presencia de virus y otros elementos que de algún modo puedan dañar el sistema informático del usuario.
      <br/>
      El USUARIO tiene prohibido cualquier tipo de acción sobre nuestro portal que origine una excesiva sobrecarga de funcionamiento a nuestros sistemas informáticos, así como la introducción de virus, o instalación de robots, o software que altere el normal funcionamiento de nuestra web, o en definitiva pueda causar daños a nuestros sistemas informáticos.
      </p>
      <p>
        <strong>5.	 Modificación de las presentes condiciones y duración</strong>
      </p>
      <p>
      Animal Data Source podrá modificar en cualquier momento las condiciones aquí determinadas, siendo debidamente publicadas como aquí aparecen. Las presentes condiciones estarán vigentes hasta que sean modificadas por otras debidamente publicadas.
      </p>
    </div>
  </>
)
