import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tab, Icon } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faFileContract } from '@fortawesome/free-solid-svg-icons'
import { faList } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

export const TabsObjectives = ({ highlight, isInactive = '', id }) => {
  
  const { t } = useTranslation()

  return (
    <Tab.Group align='centered' kind='boxed'>
      <Tab as={Link} to={ isInactive === 'is-inactive' ? '/agreements/agreements' : id === -1 ? `/agreements/agreements/add` : `/agreements/agreements/edit/${id}`} active={highlight === 'objectives'}>
        <Icon size='small'>
          <FontAwesomeIcon icon={faFileContract} />
        </Icon>
        { isInactive  === 'is-inactive' ? <span>{t('objective_plural')}</span> : <span>{t('objective')}</span>}
      </Tab>
      { isInactive == 'is-inactive' ? (
         <Tab as={Link} to="/agreements/types" active={highlight === 'types'}>
          <Icon size='small'>
            <FontAwesomeIcon icon={faList} />
          </Icon>
          <span  >{t('types')}</span>
        </Tab>) : null }
      { isInactive !== 'is-inactive' ? (
         <Tab as={Link} to={ id === -1 ? `/agreements/products/add` : `/agreements/products/edit/${id}` } active={highlight === 'products'}>
          <Icon size='small'>
            <FontAwesomeIcon icon={['fas', 'shopping-basket']} />
          </Icon>
          <span  >{t('products')}</span>
        </Tab>) : null }
      { isInactive !== 'is-inactive' ? (
        <Tab as={Link} to={ id === -1 ? `/agreements/distributors/add` : `/agreements/distributors/edit/${id}` } active={highlight === 'distributors'}>
          <Icon size='small'>
            <FontAwesomeIcon icon={['fas','warehouse']} />
          </Icon>
          <span >{t('distributors')}</span>
        </Tab>) : null }
      { isInactive !== 'is-inactive' ? (
        <Tab as={Link} to={ id === -1 ? `/agreements/postalcodes/add` : `/agreements/postalcodes/edit/${id}` } active={highlight === 'postalCodes'}>
          <Icon size='small'>`
            <FontAwesomeIcon icon={faMapMarkerAlt} />
          </Icon>
          <span >{t('postalCodes')}</span>
        </Tab>) : null }
    </Tab.Group>
  )
}
