/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { logic } from '../../../logic'
import { TabsDistributor } from '../usersDistributor/tabsDistributor'
import {
  Title,
  Level,
  Field,
  Icon,
  Select,
  Button,
  Table,
  Box,
  Notification,
  Delete
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Pagination } from '../../pagination'
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom'
import { useToggle } from '../../../logic/hooks'
import { Search } from '../../filter/Search'
import { Modal } from '../../modal'
import { SelectDistributors } from './selectDistributors'
import { NOTIFICATIONS } from '../../../notifications'
import './distributorUsers.scss'

export const DistributorUsers = () => {
  // Hooks for toggling on and off the modal
  const [open, toggle] = useToggle(false)

  const [deleteItem, setDeleteItem] = useState()

  // state inside the modal
  const [related, setRelated] = useState([])

  // DistributorId
  const [distributorId, setDistributorId] = useState()

  // main state
  const [products, setProducts] = useState()

  const [tenant, setTenant] = useState()

  // products that are searched for
  const [searchProducts, setSearchProducts] = useState([])

  // i18n hook
  const { t } = useTranslation()

  // Routing Variables
  const { url } = useRouteMatch()
  const history = useHistory()
  const location = useLocation()

  // state to verify if a userTenant has been recently created
  const [hasCreatedUserDistributor, setHasCreatedUserDistributor] = useState(false)

  // State refering to the pagination attributes comming from the pagination component
  const defaultPaginationStartPosition = 0
  const defaultPaginationEndPosition = 10
  const [startPosition, setStartPosition] = useState(
    defaultPaginationStartPosition
  )
  const [endPosition, setEndPosition] = useState(defaultPaginationEndPosition)

  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const paginationRef = useRef()
  // ComponentDidMount Check if userDistributor has been created
  useEffect(() => {
    function checkIfUserDistributorHasBeenCreated () {
      const hasLocation = location
      if (hasLocation && hasLocation.state && hasLocation.state.message === t(NOTIFICATIONS.userDistributorCreate)) {
        setHasCreatedUserDistributor(true)
      }
    }
    checkIfUserDistributorHasBeenCreated()
  }, [])

  // Fetch products when component mounts
    useEffect(() => {
      const a = logic && logic.distributorId
        logic
          .getUsersFromDistributor({ distributorId: a })
          .then(users => {
            const sortedUsers =
              users &&
              users.data &&
              users.data.sort((a, b) => {
                if (a.username < b.username) {
                  return -1
                }
                if (a.username > b.username) {
                  return 1
                }
                return 0
              })
            setProducts(sortedUsers)
            setSearchProducts(sortedUsers)
          })
      
    },[])
 
  // handles the pagination requests
  const handleRequestPagination = requestPaginationObject => {
    let newStartPosition = defaultPaginationStartPosition
    let newEndPosition = defaultPaginationEndPosition
    if (requestPaginationObject) {
      const { currentPage, itemsPerPage } = requestPaginationObject
      newStartPosition = currentPage * itemsPerPage - itemsPerPage
      newEndPosition = newStartPosition + itemsPerPage
    }
    setStartPosition(newStartPosition)
    setEndPosition(newEndPosition)
  }

  const handleDoSearch = value => {
    const result = products.filter(product => {
      const values = Object.values(product)

      const isIncluded = values
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
      // pagination Reset vallues
      paginationRef.current.resetValues()

      return isIncluded
    })
    const sortedResult = result.sort((a, b) => {
      if (a.tenantproductname < b.tenantproductname) {
        return -1
      }
      if (a.tenantproductname > b.tenantproductname) {
        return 1
      }
      return 0
    })
    setSearchProducts(sortedResult)
    return sortedResult
  }
  
  return (
    <Box id='correspondance'>
      {hasCreatedUserDistributor &&
            <Level>
              <Level.Item>
                <Notification color='success'>
                  <Delete as='button' onClick={() => setHasCreatedUserDistributor(false)} />
                  {t('userCreated')}
                </Notification>
              </Level.Item>
            </Level>}
      <TabsDistributor
        highlight='users'
      />
      <Level>
        <Level.Item>
          <Search doSearch={handleDoSearch} />
        </Level.Item>
      </Level>
      <Table hoverable bordered striped narrow fullwidth>
        <Table.Head>
          <Table.Row>
            <Table.Heading>{t('name')}</Table.Heading>
            <Table.Heading>{t('email')}</Table.Heading>
            <Table.Heading>{t('phone')}</Table.Heading>
            <Table.Heading>{t('tenant')}</Table.Heading>
            {/*<Table.Heading>{t('primaryContact')}</Table.Heading>*/}
            {/*<Table.Heading>{t('notify')}</Table.Heading>*/}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {searchProducts &&
            searchProducts
              .slice(startPosition, endPosition)
              .map((item, index) => {
                return (
                  <Table.Row key={item.userid}>
                    <Table.Cell>{item.username}</Table.Cell>
                    <Table.Cell>{item.useremail}</Table.Cell>
                    <Table.Cell>{item.userphone}</Table.Cell>
                    <Table.Cell>{item.tenantnames}</Table.Cell>
                    {/*<Table.Cell>{item.userid === item.primaryContactid ? t('yes') : t('no')}</Table.Cell>*/}
                    {/*<Table.Cell>{item.notify ? t('yes') : t('no')}</Table.Cell>*/}
                  </Table.Row>
                )
              })}
          {!searchProducts && (
            <Table.Row>
              <Table.Heading colSpan='4'>{t('noDataAvailable')}</Table.Heading>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <Pagination
        totalItems={searchProducts && searchProducts.length}
        ref={paginationRef}
        requestPagination={handleRequestPagination}
      />
    </Box>
  )
}
