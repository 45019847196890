// sets the initial configuration for i18n
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import dayjs from 'dayjs'
import LanguageDetector from 'i18next-browser-languagedetector'
import { parsedEnLocales, parsedEsLocales } from './locales/parseTranslations'

export default i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: {
          ...parsedEnLocales,
          capitalize: '{{text, capitalize}}',
          lowercase: '{{text, lowercase}}'
        }
      },
      es: {
        translation: {
          ...parsedEsLocales,
          capitalize: '{{text, capitalize}}',
          lowercase: '{{text, lowercase}}'
        }

      }
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
      format: function (value, format, lng) {
        if (value && format === 'uppercase') {
          return value.toUpperCase()
        }
        if (value && format === 'lowercase') {
          return value.toLowerCase()
        }
        if (value && format === 'capitalize') {
          return value.charAt(0).toUpperCase() + value.slice(1)
        }
        // moment corresponds to a function that moment.js gives us. consult if it is necessary
        if (value && value instanceof Date) {
          return dayjs(value).format(format)
        }
        return value
      }
    }
  }

  )

// triggers when the language is changed.
i18n.on('languageChanged', () => {
  console.log('callback log that triggers when language change')
})
