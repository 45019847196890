import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import './config/ie11Polyfill/customEvent'
import './config/ie11Polyfill/find'
import { Provider as AlertProvider } from 'react-alert'
import AlertTemplate from './config/alertTemplate/react-alert-template-test.js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import 'antd/dist/antd.css'
import './App.scss'
import * as serviceWorker from './serviceWorker'
import './fontAwesomeLibrary'
import './config/i18n/i18n'
import { BrowserRouter as Router } from 'react-router-dom'
// import 'core-js'

// Alert Provider optional cofiguration
const options = {
  position: 'top center',
  timeout: 5000,
  offset: '30px',
  transition: 'fade'
}

ReactDOM.render(
  <AlertProvider template={AlertTemplate} {...options}>
    <Router>
      <App />
    </Router>
  </AlertProvider>
  , document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
