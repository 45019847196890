import React, { useState, useEffect } from 'react'
import './header.scss'
import { Navbar, Icon, Title, Container, Image } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Logo from '../../../../config/images/ADS_fondoazul.png'  
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { logic } from '../../../../logic'

export const Header = () => {
  const [user, setUser] = useState()

  const history = useHistory()
  const { t, i18n } = useTranslation()
  const handleLanguageClick = (language, event) => {
    event.preventDefault()
    i18n.changeLanguage(language)
  }

  useEffect(() => {
    logic.retrieveUser(logic.sessionId.toString())
      .then(user => {
        setUser(user && user.data && user.data[0])
        const language = user && user.data && user.data[0] && user.data[0].lang
        i18n.changeLanguage(language && language.toLowerCase())
      })
  }, [])

  const handleLogoutClick = event => {
    event.preventDefault()
    logic.logout()
      .then(message => {
        history.push('/login')
      })
      .catch(error => {
        console.log(error.message)
      })
  }
  return (
    <Navbar color='info'>
      <Navbar.Brand>
        <Navbar.Item as={Link} to={logic && logic.isTenant ? '/dashboard' : logic && logic.isDistributor ? '/stats' : '/home'} href='#'>
            <Image src={Logo} />
        </Navbar.Item>
        <Navbar.Burger />
      </Navbar.Brand>
      <Navbar.Menu>
        <Navbar.Segment align='end'>
          <Navbar.Item dropdown>
            <Navbar.Link>
              <Title as='span' textColor='white' size={5}>{user && user.username}</Title>
              <Icon>
                <FontAwesomeIcon icon={['fas', 'user']} />
              </Icon>
            </Navbar.Link>
            <Navbar.Dropdown boxed as='div' align='right'>
              <Navbar.Item as={Link} to='/profile'>
                <Icon>
                  <FontAwesomeIcon size='lg' icon={['far', 'id-card']} />
                </Icon>
                <Container>
                  {t('profile')}
                </Container>
              </Navbar.Item>
              <Navbar.Divider />
              <Navbar.Item active>
                <Icon>

                  <FontAwesomeIcon size='lg' icon={['fas', 'language']} />
                </Icon>
                <Container>
                  {t('language')}
                </Container>
              </Navbar.Item>
              <Navbar.Item onClick={event => handleLanguageClick('es', event)}>{t('spanish')}</Navbar.Item>
              <Navbar.Item onClick={event => handleLanguageClick('en', event)}>{t('english')}</Navbar.Item>
              <Navbar.Divider />
              <Navbar.Item as={Link} to='/support'>
                <Icon>
                  <FontAwesomeIcon size='lg' icon={['fas', 'question-circle']} />
                </Icon>
                <Container>
                  {t('help')}/{t('support')}
                </Container>
              </Navbar.Item>
              <Navbar.Divider />
              <div className='navbar-item'>
                <Icon>
                  <FontAwesomeIcon size='lg' icon={['fas', 'book']} />
                </Icon>
                <a
              href={`${logic.url}/template/manual/${user && user.rolename}/${user && user.lang}`}
              target='_blank'
              rel='noopener noreferrer'
              download
            >{t('Descargar Manual')}</a>
              </div>

              <Navbar.Item onClick={event => handleLogoutClick(event)}>
                <Icon>
                  <FontAwesomeIcon size='lg' icon={['fas', 'power-off']} />
                </Icon>
                <Container>
                  {t('logout')}
                </Container>
              </Navbar.Item>
            </Navbar.Dropdown>
          </Navbar.Item>
        </Navbar.Segment>
      </Navbar.Menu>
    </Navbar>
  )
}
