import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { logic } from '../../../logic'
import { SelectContainer, Select } from 'rbx/elements/form/select'
import ReactSelect from 'react-select'
import './selectDistributors.scss'

export const SelectDistributors = (props) => {
  const { t } = useTranslation()

  const [distributors, setDistributors] = useState()

  const [selectedDistributor, setSelectedDistributor] = useState()

  const [options, setOptions] = useState([])
  useEffect(() => {
    const distributorIdToFilter = logic.distributorId
    logic
      .getDistributors()
      .then(distributors => {
        let data = distributors && distributors.data
        data = data.filter(item => {
          return item && item.distributortenantid && item.distributortenantid.toString() !== distributorIdToFilter
        })
        const sortedData = data && data.sort((a, b) => {
          if (a.distributorname < b.distributorname) {
            return -1
          }
          if (a.distributorname > b.distributorname) {
            return 1
          }
          return 0
        })
        setDistributors(sortedData)
        setOptions(
          sortedData.map(product => {
            const { distributorcode, distributorname, distributortenantid } = product
            return {
              label: `${distributorcode} ${distributorname}`,
              value: distributortenantid
            }
          })
        )
      })
      .catch(err => {
        window.alert(err.message)
      })
  }, [])

  const handleDistributorSelect = event => {
    const value = event && event.value

    setSelectedDistributor(`${value}`)

    props.doSelectDistributor(`${value}`)
  }

  return (
        <ReactSelect className='reactSelectDistributors' isClearable isSearchable options={options} onChange={handleDistributorSelect} />
  )
}
