/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { logic } from '../../../logic'
import { TabsDistributor } from '../tabsDistributor'
import {
  Title,
  Level,
  Field,
  Icon,
  Select,
  Button,
  Table,
  Box,
  Notification,
  Delete
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Pagination } from '../../pagination'
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom'
import { useToggle } from '../../../logic/hooks'
import { Search } from '../../filter/Search'
import { Modal } from '../../modal'
import { SelectDistributors } from './selectDistributors'
import { ActionButtons } from '../../actionButtons'
import { NOTIFICATIONS } from '../../../notifications'
import './usersDistributor.scss'
import { useAlert } from 'react-alert'

export const UsersDistributor = () => {
  // Hooks for toggling on and off the modal
  const [open, toggle] = useToggle(false)

  const [deleteItem, setDeleteItem] = useState()

  // state inside the modal
  const [related, setRelated] = useState([])

  // DistributorId
  const [distributorId, setDistributorId] = useState()

  // main state
  const [products, setProducts] = useState()

  const [tenant, setTenant] = useState()

  // products that are searched for
  const [searchProducts, setSearchProducts] = useState([])

  // i18n hook
  const { t } = useTranslation()
  const alert = useAlert()

  // Routing Variables
  const { url } = useRouteMatch()
  const history = useHistory()
  const location = useLocation()

  // state to verify if a userTenant has been recently created
  const [hasCreatedUserDistributor, setHasCreatedUserDistributor] = useState(false)

  // State refering to the pagination attributes comming from the pagination component
  const defaultPaginationStartPosition = 0
  const defaultPaginationEndPosition = 10
  const [startPosition, setStartPosition] = useState(
    defaultPaginationStartPosition
  )
  const [endPosition, setEndPosition] = useState(defaultPaginationEndPosition)

  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const paginationRef = useRef()
  // ComponentDidMount Check if userDistributor has been created
  useEffect(() => {
    function checkIfUserDistributorHasBeenCreated () {
      const hasLocation = location
      if (hasLocation && hasLocation.state && hasLocation.state.message === t(NOTIFICATIONS.userDistributorCreate)) {
        setHasCreatedUserDistributor(true)
      }
    }
    checkIfUserDistributorHasBeenCreated()
  }, [])

  // Fetch products when component mounts
  useEffect(() => {
    logic
      .getUserTenant()
      .then(tenant => {
        setTenant(tenant && tenant.data && tenant.data[0])
      })
      .catch(err => {
        window.alert(err.message)
      })
  }, [])

  // handles the pagination requests
  const handleRequestPagination = requestPaginationObject => {
    let newStartPosition = defaultPaginationStartPosition
    let newEndPosition = defaultPaginationEndPosition
    if (requestPaginationObject) {
      const { currentPage, itemsPerPage } = requestPaginationObject
      newStartPosition = currentPage * itemsPerPage - itemsPerPage
      newEndPosition = newStartPosition + itemsPerPage
    }
    setStartPosition(newStartPosition)
    setEndPosition(newEndPosition)
  }

  // handles routing to add/edit
  const handleAddEditClick = (event, element) => {
    if (element) {
      history.push({
        pathname: `${url}/edit/${element && element.distributorid}/${element &&
          element.userid}`
      })
    } else {
      history.push({
        pathname: `${url}/add/${distributorId}`
      })
    }
  }

  // deletes the item
  const handleDeleteClick = (event, element) => {
    const { distributorid: distributorId, userid: userId } = element
    logic
      .deleteUserDistributor({ distributorId: distributorId.toString(), userDistributorId: userId.toString() })
      .then(item => {
        if (item.status !== 200) {
          alert.show(item.error, { type: 'error' })
          throw Error('status error')
        }
        const newProducts = products.filter(product => {
          return product.userid !== item.data[0].userid
        })
        const sortedNewProducts =
          newProducts &&
          newProducts.sort((a, b) => {
            if (a.useremail < b.useremail) {
              return -1
            }
            if (a.useremail > b.useremail) {
              return 1
            }
            return 0
          })
        toggle()
        alert.show('Usuario eliminado satisfactoriamente', { type: 'success' })
        setSearchProducts(sortedNewProducts)
      })
      .catch(err => {
        toggle()
        console.error(err.message)
      })
  }

  const showRelatedItems = (event, item) => {
    setDeleteItem({ ...item })
    // TODO set correct logic for related
    setRelated([])
    toggle()
  }

  const handleDoSearch = value => {
    const result = products && products.filter(product => {
      const values = Object.values(product)

      const isIncluded = values
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
      // pagination Reset vallues
      paginationRef.current.resetValues()

      return isIncluded
    }) || []
    const sortedResult = result.sort((a, b) => {
      if (a.tenantproductname < b.tenantproductname) {
        return -1
      }
      if (a.tenantproductname > b.tenantproductname) {
        return 1
      }
      return 0
    })
    setSearchProducts(sortedResult)
    return sortedResult
  }

  const handleSelectDistributor = value => {
    setDistributorId(value)
    if (value) {
      logic
        .getUserDistributors({ distributorId: value })
        .then(users => {
          const sortedUsers =
            users &&
            users.data &&
            users.data.sort((a, b) => {
              if (a.username < b.username) {
                return -1
              }
              if (a.username > b.username) {
                return 1
              }
              return 0
            })
          setProducts(sortedUsers)
          setSearchProducts(sortedUsers)
        })
    }
  }

  return (
    <Box id='correspondance'>
      {open && (
        <Modal toggle={toggle} open={open}>
          <Title>{t('confirmDelete')}</Title>
          {related && related.length !== 0 && (
            <Title subtitle size={6}>
              {t('cannotDeleteRelated')}
            </Title>
          )}
          {related &&
            related.length !== 0 &&
            related.length > 7 &&
            related.slice(0, 7).map((item, index) => {
              return (
                <>
                  <Field key={index}>{item.productname}</Field>
                </>
              )
            })}
          {related &&
            related.length !== 0 &&
            related.length <= 7 &&
            related.map((item, index) => {
              return (
                <>
                  <Field key={index}>{item.productname}</Field>
                </>
              )
            })}
          {related && related.length !== 0 && related.length > 7 && (
            <Field>
              ...{t('and')} {related.length - 7} {t('others')}
            </Field>
          )}
          {related && related.length === 0 && (
            <>
              <Title subtitle>{t('deleteSure')}</Title>
              <Button
                onClick={e => handleDeleteClick(e, deleteItem)}
                color='danger'
              >
                {t('delete')}
              </Button>
            </>
          )}
        </Modal>
      )}
      {hasCreatedUserDistributor &&
            <Level>
              <Level.Item>
                <Notification color='success'>
                  <Delete as='button' onClick={() => setHasCreatedUserDistributor(false)} />
                  {t('userCreated')}
                </Notification>
              </Level.Item>
            </Level>}
      <TabsDistributor
        highlight='users'
      />
      <Level>
        <Level.Item align='left'>
          <Select.Container color='dark'>
            <SelectDistributors doSelectDistributor={handleSelectDistributor} />
          </Select.Container>
        </Level.Item>
        <Level.Item>
          <Search doSearch={handleDoSearch} />
        </Level.Item>
        <Level.Item align='right'>
          {distributorId && <Button onClick={e => handleAddEditClick()} color='info'>
            <Icon>
              <FontAwesomeIcon size='lg' icon={['fas', 'plus']} />
            </Icon>
          </Button>}
        </Level.Item>
      </Level>
      <Table hoverable bordered striped narrow fullwidth>
        <Table.Head>
          <Table.Row>
            <Table.Heading>{t('email')}</Table.Heading>
            <Table.Heading>{t('name')}</Table.Heading>
            <Table.Heading>{t('phone')}</Table.Heading>
            <Table.Heading>{t('primaryContact')}</Table.Heading>
            <Table.Heading>{t('notify')}</Table.Heading>
            <Table.Heading>{t('state')}</Table.Heading>
            <Table.Heading>{t('action')}</Table.Heading>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {searchProducts &&
            searchProducts
              .slice(startPosition, endPosition)
              .map((item, index) => {
                return (
                  <Table.Row key={item.userid}>
                    <Table.Heading>{item.useremail}</Table.Heading>
                    <Table.Cell>{item.username}</Table.Cell>
                    <Table.Cell>{item.userphone}</Table.Cell>
                    <Table.Cell>{item.userid === item.primarycontactid ? t('yes') : t('no')}</Table.Cell>
                    <Table.Cell>{item.notify ? t('yes') : t('no')}</Table.Cell>
                    <Table.Cell>{item.active ? t('active') : t('pending_activation')}</Table.Cell>
                    <Table.Cell>
                      <ActionButtons
                        onEditClick={e => handleAddEditClick(e, item)}
                        onDeleteClick={e => showRelatedItems(e, item)}
                      />
                    </Table.Cell>
                  </Table.Row>
                )
              })}
          {!searchProducts && (
            <Table.Row>
              <Table.Heading colSpan='4'>{t('noDataAvailable')}</Table.Heading>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <Pagination
        totalItems={searchProducts && searchProducts.length}
        ref={paginationRef}
        requestPagination={handleRequestPagination}
      />
    </Box>
  )
}
