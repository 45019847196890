const mixTenantInfo = {
    mixTenantInfo (tenants, tenantsInfo) {
      const finalInfo = []
      for (let i = 0; i < tenants.length; i++) {
        for (let j = 0; j < tenantsInfo.length; j++) {
          if (tenants[i].tenantid === tenantsInfo[j].tenantid) {
            const tenantMix = tenants[i]
            tenantMix.TenantUsers = tenantsInfo[j].tenantusers
            tenantMix.Distributors = tenantsInfo[j].distributors
            tenantMix.DistributorUsers = tenantsInfo[j].distributorusers
            finalInfo.push(tenantMix)
            break
          }
          if (tenants[i].tenantid !== tenantsInfo[j].tenantid && j === tenantsInfo.length - 1) {
            finalInfo.push(tenants[i])
          }
        }
      }
      return finalInfo
    }
  }
  export { mixTenantInfo }