/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, useContext } from 'react'
import {
  Control,
  Field,
  Menu,
  Icon,
  Container,
  Button
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileContract, faChartLine, faScrewdriver } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { logic } from '../../../../logic'
import { TableOfTables } from '../../../../logic/tableOfTables'
import { AdsContext } from "../../../../contexts/adsContext"
import { Role } from '../../../../config/role'
import './sidebar.scss'

export const Sidebar = () => {
  const { t } = useTranslation()
  const { allTableOfTables } = useContext(AdsContext)

  const isPVLContracted = TableOfTables('PVL', logic.tenantId.toString(), allTableOfTables, true)
  const isAGRContracted = TableOfTables('AGR', logic.tenantId.toString(), allTableOfTables, true)
  const isCLIGROUPContracted = TableOfTables('CLIGROUP', logic.tenantId.toString(), allTableOfTables, true)
  const [areAGRContractedByTenants, setAreAGRContractedByTenants] = useState(false)
  // State that controls whether the menu is expanded or collapsed
  const [toggle, setToggle] = useState(true)

  const [tenant, setTenant] = useState({})

  const [hasObjectives, setHasObjectives] = useState(false)

  const isTenant = () => logic.roleName === Role.Tenant

  const isDistributor = () => logic.roleName === Role.Distributor

  const isAdmin = () => logic.roleName === Role.Admin

  const handleToggleMenu = () => {
    setToggle(prevState => !prevState)
  }

  useEffect(() => {
    function getTenantData () {
      logic
      .getUserTenant()
      .then(tenant => {
        setTenant(tenant && tenant.data && tenant.data[0])
      })
      .catch(err => {
        window.alert(err.message)
      })
    }

    function getTenantDistributorData () {
      let tenantsWithAGRs = []
      logic
      .loadTableOfTables()
      .then(tableOfTables => {
        tenantsWithAGRs = tableOfTables.data.filter(table => { return table.active && table.tableoftablesname === 'AGR' })
      })
      .then(() => {
        logic
        .getUserDistributorTenants()
        .then(distributorTenants => {
          distributorTenants.data.forEach(distributorTenant => {
            if (TableOfTables('AGR', distributorTenant.tenantid.toString(), tenantsWithAGRs, true)) {
              setAreAGRContractedByTenants(true)
            }})
        })
      })
      .catch(err => {
        window.alert(err.message)
      }) 
    }
    
    if (logic.isTenant) {
      getTenantData()
    } 
    if (logic.isDistributor && !logic.isTenant) {
      getTenantDistributorData() 
    }
    if (logic.isDistributor && !logic.isAdmin && !logic.isTenant) {
      logic
      .getDistributorObjectives(logic.distributorId)
      .then(objectives => {
        if (objectives && objectives.data && objectives.data.length) {
          setHasObjectives(true)
        } else {
          setHasObjectives(false)
        }
      })
    }
  }, [])

  const [url, setUrl] = useState('')

  const location = useLocation()
  // ComponentDidMount
  useEffect(() => {
    // This is meant to use the location react router object to extract the path and extracts it to an array to later use for the breadcrumbs.
    // slice(1) is used to eliminate the first element as it is blank after doing split {example url: /services/example   after split ["",services,example]}
    const path = location && location.pathname
    setUrl(path)
  }, [location])

  return (
    <Menu id='sidebar' className='sidebar'>
      <Menu.List>

        {isTenant() && <Menu.List.Item as={Link} active={url.startsWith('/tenant')} to='/tenant/users'>
          <Field kind='group' horizontal>
            <Control>
              <Icon>
                <FontAwesomeIcon icon={['fas', 'users-cog']} />
              </Icon>
            </Control>

            {toggle ? (
              <Control>
                <Container>{(tenant && tenant.tenantname) || t('tenant')}</Container>
              </Control>
            ) : null}
          </Field>
        </Menu.List.Item>}

        {logic.isAdmin && <Menu.List.Item as={Link} to='/tenants'>
            <Field horizontal kind='group'>
              <Control>
                <Icon>
                  <FontAwesomeIcon icon={['fas', 'store']} />
                </Icon>
              </Control>
              {toggle ? (
                <Control>
                  <Container>{t('tenants')}</Container>
                </Control>
              ) : null}
            </Field>
        </Menu.List.Item>}

        {logic.isAdmin && <Menu.List.Item as={Link} to='/users'>
            <Field horizontal kind='group'>
              <Control>
                <Icon>
                  <FontAwesomeIcon icon={['fas', 'user']} />
                </Icon>
              </Control>
              {toggle ? (
                <Control>
                  <Container>{t('users')}</Container>
                </Control>
              ) : null}
            </Field>
        </Menu.List.Item>}
                        
        {logic.isAdmin && <Menu.List.Item as={Link} to='/dataAdmin'>
          <Field horizontal kind='group'>
            <Control>
              <Icon>
                <FontAwesomeIcon icon={['fas', 'file-upload']} />
              </Icon>
            </Control>
            {toggle ? (
              <Control>
                <Container>{t('Data')}</Container>
              </Control>
            ) : null}
          </Field>
        </Menu.List.Item>}

        {logic.isAdmin && <Menu.List.Item as={Link} active={url.includes('/settings')} to='/settings/dashboards'
        menu={
          toggle && url.includes('/settings') && <Menu.List>
            <Menu.List.Item textSize={7} as={Link} active={url.includes('/settings/dashboards')} to='/settings/dashboards'>{t('Dashboard')}</Menu.List.Item>
            <Menu.List.Item textSize={7} as={Link} active={url.includes('/settings/tables')} to='/settings/tables'>{t('Tables')}</Menu.List.Item>              
          </Menu.List>
          }>
          <Field horizontal kind='group'>
            <Control>
              <Icon>
              <FontAwesomeIcon icon={faScrewdriver} />
              </Icon> 
            </Control>
            {toggle ? (
              <Control>
                <Container> {t('Settings')}</Container>
              </Control>
            ) : null}
          </Field>
        </Menu.List.Item>}
                        
        {isTenant() && <Menu.List.Item as={Link} active={url.includes('/product/')} to='/product/tenant'>
          <Field kind='group' horizontal>
            <Control>
              <Icon>
                <FontAwesomeIcon icon={['fas', 'shopping-basket']} />
              </Icon>
            </Control>

            {toggle ? (
              <Control>
                <Container>{t('product')}</Container>
              </Control>
            ) : null}
          </Field>
        </Menu.List.Item>}

        {isDistributor() && <Menu.List.Item as={Link} active={url.includes('/product/distributor')} to='/product/distributor'>
          <Field kind='group' horizontal>
            <Control>
              <Icon>
                <FontAwesomeIcon icon={['fas', 'shopping-basket']} />
              </Icon>
            </Control>

            {toggle ? (
              <Control>
                <Container>{t('product')}</Container>
              </Control>
            ) : null}
          </Field>
        </Menu.List.Item>}

        {isTenant() && <Menu.List.Item as={Link} active={url.startsWith('/correspondance')} to='/correspondance'>
          <Field horizontal kind='group'>
            <Control>
              <Icon>
                <FontAwesomeIcon icon={['fas', 'exchange-alt']} />
              </Icon>
            </Control>
            {toggle ? (
              <Control>
                <Container> {t('correspondance')}</Container>
              </Control>
            ) : null}
          </Field>
        </Menu.List.Item>}

        {isTenant() && <Menu.List.Item as={Link} active={url.startsWith('/distributor')} to='/distributor'>
          <Field horizontal kind='group'>
            <Control>
              <Icon>
                <FontAwesomeIcon icon={['fas', 'warehouse']} />
              </Icon>
            </Control>
            {toggle ? (
              <Control>
                <Container>{t('distributors')}</Container>
              </Control>
            ) : null}
          </Field>
        </Menu.List.Item>}

        {false && isTenant() && <Menu.List.Item as={Link} active={url.startsWith('/clients')} to='/clients'>
          <Field horizontal kind='group'>
            <Control>
              <Icon>
                <FontAwesomeIcon icon={['fas', 'store']} />
              </Icon>
            </Control>
            {toggle ? (
              <Control>
                <Container>{t('clients')}</Container>
              </Control>
            ) : null}
          </Field>
        </Menu.List.Item>}

        {isTenant() && <Menu.List.Item as={Link} active={url.includes('/reports')} to='/reports/Status'
        menu={
          toggle && url.includes('/reports') && <Menu.List>
            <Menu.List.Item textSize={7} as={Link} active={url.includes('/reports/Status') || url.includes('/reports/Summary')} to='/reports/Status'>{t('summary')}</Menu.List.Item>
            <Menu.List.Item textSize={7} as={Link} active={url.includes('/reports/Sales') || url.includes('/reports/Stock') || url.includes('/reports/clients')} to='/reports/Sales'>{t('Sales&Stock')}</Menu.List.Item>
            <Menu.List.Item textSize={7} as={Link} active={url.includes('/reports/new')} to='/reports/new'>{t('Clients')}</Menu.List.Item>
          </Menu.List>
          }>
          <Field horizontal kind='group'>
            <Control>
              <Icon>
                <FontAwesomeIcon icon={['fas', 'clipboard-list']} />
              </Icon>
            </Control>
            {toggle ? (
              <Control>
                <Container> {t('report_plural')}</Container>
              </Control>
            ) : null}
          </Field>
        </Menu.List.Item>}

        {logic.isDistributor && !logic.isAdmin && !isTenant() && <Menu.List.Item as={Link} active={url.includes('/staff')} to='/staff'>
          <Field horizontal kind='group'>
            <Control>
              <Icon>
                <FontAwesomeIcon icon={['fas', 'user-check']} />
              </Icon>
            </Control>
            {toggle ? (
              <Control>
                <Container> {t('Employees')}</Container>
              </Control>
            ) : null}
          </Field>
        </Menu.List.Item>}

        {logic.isDistributor && !logic.isAdmin && <Menu.List.Item as={Link} active={url.includes('/data')} to='/data'>
          <Field horizontal kind='group'>
            <Control>
              <Icon>
                <FontAwesomeIcon icon={['fas', 'file-upload']} />
              </Icon>
            </Control>
            {toggle ? (
              <Control>
                <Container> {t('sales/stock')}</Container>
              </Control>
            ) : null}
          </Field>
        </Menu.List.Item>}
        {isTenant() && isPVLContracted && 
          <Menu.List.Item as={Link} active={url.includes('/pricedata')} to='/pricedata'>
            <Field horizontal kind='group'>
              <Control>
                <Icon>
                  <FontAwesomeIcon icon={['fas', 'file-upload']} />
                </Icon>
              </Control>
              {toggle ? (
                <Control>
                  <Container> {t('prices')}</Container>
                </Control>
              ) : null}
            </Field>
          </Menu.List.Item>}
        {isTenant() && (isCLIGROUPContracted || isPVLContracted) && <Menu.List.Item as={Link} active={url.includes('/clients/')} to='/clients/groups'
          menu={
            toggle && url.includes('/clients/') && <Menu.List>
              {isCLIGROUPContracted ?
                <Menu.List.Item textSize={7} as={Link} active={url.includes('/clients/groups')} to='/clients/groups'>{t('clients_groups')}</Menu.List.Item>
                :
                null
              }
              {isPVLContracted ? 
                <Menu.List.Item textSize={7} as={Link} active={url.includes('/clients/categories')} to='/clients/categories'>{t('clients_categories')}</Menu.List.Item>
                :
                null
              }
            </Menu.List>
          }>
          <Field horizontal kind='group'>
            <Control>
              <Icon>
                <FontAwesomeIcon icon={['fas', 'users']} />
              </Icon> 
            </Control>
            {toggle ? (
              <Control>
                <Container> {t('clients')}</Container>
              </Control>
            ) : null}
          </Field>
        </Menu.List.Item>}

        {isTenant() && isAGRContracted && <Menu.List.Item as={Link} active={url.includes('/agreements')} to='/agreements/agreements'
        menu={
          toggle && url.includes('/agreements') && <Menu.List>
            <Menu.List.Item textSize={7} as={Link} active={url.includes('/agreements/agreements') || url.includes('/agreements/products') || 
                                                                                             url.includes('/agreements/distributors') || url.includes('/agreements/postalcodes')} 
                                       to='/agreements/agreements'>{t('distributor_objectives')}</Menu.List.Item>
            <Menu.List.Item textSize={7} as={Link} active={url.includes('/agreements/sales')} to='/agreements/sales'>{t('objectives_sells')}</Menu.List.Item>
          </Menu.List>
          }>
          <Field horizontal kind='group'>
            <Control>
              <Icon>
                <FontAwesomeIcon icon={faFileContract} />
              </Icon> 
            </Control>
            {toggle ? (
              <Control>
                <Container> {t('objective_plural')}</Container>
              </Control>
            ) : null}
          </Field>
        </Menu.List.Item>}

        {isDistributor() && areAGRContractedByTenants && hasObjectives && <Menu.List.Item as={Link} active={url.includes('/agreements/sales')} to='/agreements/sales'>
          <Field kind='group' horizontal>
            <Control>
              <Icon>
                <FontAwesomeIcon icon={faFileContract} />
              </Icon>
            </Control>

            {toggle ? (
              <Control>
                <Container>{t('objectives_sales')}</Container>
              </Control>
            ) : null}
          </Field>
        </Menu.List.Item>}

        <Menu.List.Item>
          <Button.Group align='centered'>
            <Button onClick={() => handleToggleMenu()} size='small' rounded>
              <Icon>
                {toggle ? (
                  <FontAwesomeIcon icon={['fas', 'angle-double-left']} />
                ) : (
                  <FontAwesomeIcon icon={['fas', 'angle-double-right']} />
                )}
              </Icon>
            </Button>
          </Button.Group>
        </Menu.List.Item>
      </Menu.List>
    </Menu>
  )
}
