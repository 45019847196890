import React, { useState, useEffect } from 'react'
import { logic } from '../../logic'
import { Box, Image, Column } from 'rbx'
import { Spin, Switch } from 'antd'
import { useTranslation } from 'react-i18next'
import { Line } from 'react-chartjs-2'
import { DashboardCard } from './card'
import { TabsDashboard } from './tabsDashboard'
const MONTHS = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

// Iterated from: https://stackoverflow.com/questions/10599933/convert-long-number-into-abbreviated-string-in-javascript-with-a-special-shortn
function abbreviateNumber(value) {
  
  
  if(!value){
    value = 0
  }

  if(typeof value !== 'number'){
    value = parseFloat(value)
  }
  let newValue = value;
  const suffixes = ["", "K", "M", "B","T"];
  let suffixNum = 0;
  while (newValue >= 1000) {
    newValue /= 1000;
    suffixNum++;
  }

  newValue = newValue.toPrecision(3);

  newValue += suffixes[suffixNum];
  return newValue
}

export const Dashboard = () => {

  const { t } = useTranslation()
  const [sales, setSales] = useState([])
  const [stocks, setStocks] = useState([])
  const [distributorUploadPercent, setDistributorUploadPercent] = useState([])
  const [lastMonthSales, setLastMonthSales] = useState([])
  const [lastMonthStocks, setLastMonthStocks] = useState([])
  const [missingCorrespondances, setMissingCorrespondances] = useState(0)
  const [startMonth, setStartMonth] = useState(0)
  const [months, setMonths] = useState([])
  const [hasAnalytics, setHasAnalytics] = useState(true)
  const [dashboardUrl, setDashboardUrl] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const tenantId = logic.tenantId
    const distributorId = logic.distributorId
    logic.getDashboardsDistributor({ distributorId }).then((response) => {
      if (response && response.data && response.data[0]) {
        setDashboardUrl(response.data[0].dashboardurl)
      } else {
        setHasAnalytics(false)
      }
    })
    logic.getMonthlySales({ tenantId }).then((response) => {
      const firstDate = new Date(response && response.data && response.data[0] && response.data[0].date)
      setStartMonth(firstDate.getMonth() || 0)
      let monthArray = []
      if (response && response.data && response.data[0] && response.data[0].date) {
        // monthArray = organizeMonths(firstDate.getMonth(), response && response.data && response.data.length)
        monthArray = response.data.map(item => {
          const date = new Date(item.date)
          return MONTHS[date.getMonth()]
        })
      }
      setMonths(monthArray)
      response &&
        response.data &&
        response.data.map((item) => {
          return item && item.units
        })
      const newSales =
        response &&
        response.data &&
        response.data.map((item) => {
          return item && item.units
        })
      setSales(newSales)
    })

    logic.getMonthlyStocks({ tenantId }).then((response) => {
      const newStocks =
        response &&
        response.data &&
        response.data.map((item) => {
          return item && item.units
        })
      setStocks(newStocks)
    })

    logic.getDistributorUploadPercent()
      .then(response => {
        const percent =
        response &&
        response.data
        setDistributorUploadPercent(percent || 0)
      })

    logic.getLastMonthStocks()
      .then(response => {
        const units =
        response &&
        response.data &&
        response.data.map(item => {
          return item && item.units
        })
        const number = abbreviateNumber(units && units[0])
        setLastMonthStocks(number)
      })

    logic.getLastMonthSales()
      .then(response => {
        const units =
        response &&
        response.data &&
        response.data.map(item => {
          return item && item.units
        })
        const number = abbreviateNumber(units && units[0])
        setLastMonthSales(number)
      })

    logic.getAbsoluteNonCorrespondance()
      .then(response => {
        const units =
        response &&
        response.data &&
        response.data.map(item => {
          return item && item.count
        })
        const number = abbreviateNumber(units && units[0])
        const integer = Math.floor(number)
        setMissingCorrespondances(integer)
      })
  }, [])

  const data = {
    labels: months,
    datasets: [
      {
        label: 'Ventas',
        data: sales,
        fill: true,
        backgroundColor: 'rgba(167,188,214,0.2)',
        borderColor: 'rgba(0,47,135,1)',
      },
      {
        label: 'Stocks',
        data: stocks,
        fill: false,
        borderColor: '#00b796',
      },
    ],
  }

  const hideSpinner = () => {
    setIsLoading(false)
  }

  return (
    <Box id="dashboard">
        <TabsDashboard highlight='dashboard' />
        {hasAnalytics && isLoading !== false ?
          <Image.Container size='3by2'>
            <Spin size="large" />
          </Image.Container>
          :
          null
        }
        {hasAnalytics ?
          <Image.Container size='3by2'>
              <iframe src={dashboardUrl} onLoad={hideSpinner} /> 
          </Image.Container>
        :
          <Column.Group>
            <Column>
              {logic.notCrossSales && <Switch checkedChildren={t('Sin vtas cruzadas')} disabled checked />} 
              {!logic.notCrossSales && <Switch unCheckedChildren={t('Con vtas cruzadas')} disabled />} 
              <Line size data={data}></Line>
            </Column>
            <Column size='one-quarter' style={{paddingTop: '56px'}}>
              <br/>
              <Column.Group>
                <DashboardCard title={t('Porcentaje de distribuidores subido')} content={`${distributorUploadPercent}%`}></DashboardCard>
              </Column.Group>
              <Column.Group>
                <DashboardCard title={t('Unidades último mes de ventas')} content={lastMonthSales}></DashboardCard>
              </Column.Group>
              <Column.Group>
                <DashboardCard title={t('Unidades último mes de stock')} content={lastMonthStocks}></DashboardCard>
              </Column.Group>
              <Column.Group>
                <DashboardCard title={t('Correspondencias sin asignar')} content={missingCorrespondances}></DashboardCard>
              </Column.Group>
            </Column>
          </Column.Group>
        }
    </Box>
  )
}
