/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { logic } from '../../logic'
import { Modal } from 'antd'
import {
  Title,
  Level,
  Field,
  Icon,
  Button,
  Table,
  Box
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Pagination } from '../pagination'
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom'
import { useToggle, useConfirmActionMessage } from '../../logic/hooks'
import { Search } from '../filter/Search'
import { TabsUsersAdmin } from './tabsUsersAdmin'
import { UnblockUsersModal } from './unblockUsersModal'
import { useModal } from '../../logic/hooks/'
import { useAlert } from 'react-alert'
import './UsersAdmin.scss'

export const UsersAdminUnblock = () => {
  const alert = useAlert()
  const modalLogic = useModal() 
  const [email, setEmail] = useState('')
  const [selectedUser, setSelectedUser] = useState({})
  // Hooks for toggling on and off the modal
  const [open, toggle] = useToggle(false)

  const [deleteItem, setDeleteItem] = useState()

  // state inside the modal
  const [related, setRelated] = useState([])
  // state inside the modal
  const [counter, setCounter] = useState(0)

  // main state
  const [users, setUsers] = useState()

  // users that are searched for
  const [searchUsers, setSearchUsers] = useState([])

  // Error message hook
  const errorAction = useConfirmActionMessage()

  // i18n hook
  const { t } = useTranslation()

  // Routing Variables
  const { url } = useRouteMatch()
  const history = useHistory()
  const location = useLocation()

  // State refering to the pagination attributes comming from the pagination component
  const defaultPaginationStartPosition = 0
  const defaultPaginationEndPosition = 10
  const [startPosition, setStartPosition] = useState(
    defaultPaginationStartPosition
  )
  const [endPosition, setEndPosition] = useState(defaultPaginationEndPosition)

  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const paginationRef = useRef()

  // Fetch users when component mounts
  useEffect(() => {
    logic
      .getUsersAdmin()
      .then(users => {
        const mapFromUsers = new Map(users && users.data.map(user => [user.useremail, user]))
        const uniqueUserEmail = [...mapFromUsers.values()]
        const sortedUsers = users && users.data && uniqueUserEmail.sort((a, b) => {
          if (a.username < b.username) {
            return -1
          }
          if (a.username > b.username) {
            return 1
          }
        })
        setUsers(sortedUsers)
        setSearchUsers(sortedUsers)
      })
      .catch(err => {
        window.alert(err.message)
      })
  }, [counter])

  // handles the pagination requests
  const handleRequestPagination = requestPaginationObject => {
    let newStartPosition = defaultPaginationStartPosition
    let newEndPosition = defaultPaginationEndPosition
    if (requestPaginationObject) {
      const { currentPage, itemsPerPage } = requestPaginationObject
      newStartPosition = currentPage * itemsPerPage - itemsPerPage
      newEndPosition = newStartPosition + itemsPerPage
    }
    setStartPosition(newStartPosition)
    setEndPosition(newEndPosition)
  }

  // handles routing to add/edit
  const handleAddEditClick = (event, element) => {
    modalLogic.showModal()
    setSelectedUser(element)
    setEmail(element && element.useremail)
  }

  // deletes the item
  const handleDeleteClick = (event, element) => {
    const { userid: userId } = element
    logic
      .deleteUserAdmin({ userId: userId.toString() })
      .then(item => {
        if (item.status !== 200) {
          throw Error('status error')
        }
        setCounter(prevState => prevState + 1)
        toggle()
      })
      .catch(err => {
        console.error(err.message)
      })
      .catch(() => {
        toggle()
      })
  }

  const showRelatedItems = (event, item) => {
    setDeleteItem({ ...item })
    // TODO set correct logic for related
    setRelated([])
    toggle()
  }

  const handleDoSearch = value => {
    const result = users.filter(user => {
      const values = Object.values(user)

      const isIncluded = values
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
      // pagination Reset vallues
      paginationRef.current.resetValues()

      return isIncluded
    })
    const sortedResult = result.sort((a, b) => {
      if (a.username < b.username) {
        return -1
      }
      if (a.username > b.username) {
        return 1
      }
    })
    setSearchUsers(sortedResult)
    return sortedResult
  }

  const handleUnblockUser = (user) => {
      
      logic.unblockUser(user && user.useremail)
      .then(response => {
          if(response && response.status === 200){
              alert.show(t('Usuario desbloqueado'), {
                  type: 'success'
              })
          }
          else{
              alert.show(t('Hubo un error, contacte con soporte técnico'),{
                  type:'error'
              })
          }
          modalLogic.closeModal()
          console.log(response)
      })
  }

  return (
    <>
    {/*  <UnblockUsersModal {...modalLogic} email={email}></UnblockUsersModal>*/}
    <Modal 
          title={t('Desbloquear Usuario')}
          visible={modalLogic.isVisible}
          onCancel={() => modalLogic.closeModal()}
          onOk={() => handleUnblockUser(selectedUser)}
          
          >
          {t(`Esta seguro que quiere desbloquear el usuario ${email}?`)}    
          </Modal>
    <Box id='users'>
      {open && (
        <Modal toggle={toggle} open={open}>
          <Title>{t('confirmDelete')}</Title>
          {related && related.length !== 0 && (
            <Title subtitle size={6}>
              {t('cannotDeleteRelated')}
            </Title>
          )}
          {related &&
            related.length !== 0 &&
            related.length > 7 &&
            related.slice(0, 7).map((item, index) => {
              return (
                <>
                  <Field key={index}>{item.productName}</Field>
                </>
              )
            })}
          {related &&
            related.length !== 0 &&
            related.length <= 7 &&
            related.map((item, index) => {
              return (
                <>
                  <Field key={index}>{item.productname}</Field>
                </>
              )
            })}
          {related && related.length !== 0 && related.length > 7 && (
            <Field>
              ...{t('and')} {related.length - 7} {t('others')}
            </Field>
          )}
          {related && related.length === 0 && (
            <>
              <Title subtitle>{t('deleteSure')}</Title>
              <Button
                onClick={e => handleDeleteClick(e, deleteItem)}
                color='danger'
              >
                {t('delete')}
              </Button>
            </>
          )}
        </Modal>
      )}
      <TabsUsersAdmin highlight='unblock'/>
      <Level>
        <Level.Item>
          <Search doSearch={handleDoSearch} />
        </Level.Item>
      </Level>
      <Table hoverable bordered striped narrow fullwidth>
        <Table.Head>
          <Table.Row>
            <Table.Heading colSpan='1'>
              {t('user name')}
            </Table.Heading>
            <Table.Heading colSpan='1'>
              {t('contact Email')}
            </Table.Heading>
            <Table.Heading colSpan='1'>
              {t('roleName')}
            </Table.Heading>
            <Table.Heading colSpan='1'>
              {t('distributor')}
            </Table.Heading>
            <Table.Heading colSpan='1'>
              {t('state')}
            </Table.Heading>
            <Table.Heading colSpan='3'>{t('action')}</Table.Heading>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {searchUsers &&
            searchUsers
              .slice(startPosition, endPosition)
              .map((item, index) => {
                return (
                  <Table.Row key={index}>
                    <Table.Cell>{item.username}</Table.Cell>
                    <Table.Cell>{item.useremail}</Table.Cell>
                    <Table.Cell>{item.rolename}</Table.Cell>
                    <Table.Cell>{item.distributorname}</Table.Cell>
                    <Table.Cell>{item.active ? t('active') : t('inactive')}</Table.Cell>
                    <Table.Cell>
                    <button
                      onClick={e => handleAddEditClick(e, item)}
                      className='button is-primary is-outlined is-small'
                    >
                      <Icon>
                        <FontAwesomeIcon size='lg' icon={['fas', 'edit']} />
                      </Icon>
                    </button>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
          {!searchUsers && (
            <Table.Row>
              <Table.Heading colSpan='4'>{t('noDataAvailable')}</Table.Heading>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <Pagination
        totalItems={searchUsers && searchUsers.length}
        ref={paginationRef}
        requestPagination={handleRequestPagination}
      />
    </Box>
    </>
  )
}
