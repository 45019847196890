import React, { Component } from 'react'
import XLSX from 'xlsx'
import * as dayjs from 'dayjs'
import { arraySplitter } from '../../utils/arraySplitter'
const DATE_FORMAT = 'DD/MM/YYYY'
const TEMPLATE_NAMES = ['Modelo Reporte Ventas', 'Modelo Reporte Stock', 'Correspondances', 'EUR_PRICES']

export class OutTable extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div>
        <table className={this.props.tableClassName}>
          <tbody>
            <tr>
              {this.props.columns.map(c => (
                <th
                  key={c.key}
                  className={c.key === -1 ? this.props.tableHeaderRowClass : ''}
                >
                  {c.key === -1 ? '' : c.name}
                </th>
              ))}
            </tr>
            {this.props.data.map((r, i) => (
              <tr key={i}>
                <td key={i} className={this.props.tableHeaderRowClass}>
                  {i}
                </td>
                {this.props.columns.map(c => (
                  <td key={c.key}>{r[c.key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export function ExcelRenderer(file, callback) {
 
  return new Promise(function (resolve, reject) {
    var reader = new FileReader()
    var rABS = !!reader.readAsBinaryString
    reader.onload = function (e) {
      /* Parse data */
      var bstr = e.target.result
      var wb = XLSX.read(bstr, { cellDates: true, type: rABS ? 'binary' : 'array' })
      /* Get worksheets */
      // Check if the worksheets match the wanted name
      let wsname
      let wsname2
      console.log("load " + wb.SheetNames[0]);
      if (wb.SheetNames[0] === TEMPLATE_NAMES[0] && wb.SheetNames[1] === TEMPLATE_NAMES[1]) {
        wsname = wb.SheetNames[0]
        wsname2 = wb.SheetNames[1]
      } else if (wb.SheetNames[1] === TEMPLATE_NAMES[0] && wb.SheetNames[0] === TEMPLATE_NAMES[1]) {
        wsname = wb.SheetNames[1]
        wsname2 = wb.SheetNames[0]
      } else if (wb.SheetNames[0] == TEMPLATE_NAMES[3]) {
        wsname = wb.SheetNames[0];
      } else {
        //reject()
        return callback('incorrectTemplate', null)
      }
      console.log("continue")
      var ws = wb.Sheets[wsname]
      var ws2 = wb.Sheets[wsname2]

      /* Convert array of arrays */
      /* the following stream converts JS objects to text via JSON.stringify */
      var json = XLSX.utils.sheet_to_json(ws, { header: 1 })
      var cols = ws && ws['!ref'] && makeCols(ws['!ref'])
      var json2 = XLSX.utils.sheet_to_json(ws2, { header: 1 })
      var cols2 = ws2 && ws2['!ref'] && makeCols(ws2['!ref'])
      console.log(cols);
      let stopCount
      /* Row formatting */
      json = json.map((row, index) => {
        /* Returns nothing if row is empty  */
        if (row && row.length === 0) {
          return null
        }

        /* The template has a legend at the bottom, we will check if the row contains the given strings and after that we will return nothing */
        const isStopRow = row[0] === 'Modelo Reporte Ventas' || row[0] === 'Modelo Reporte Stock'
        if (isStopRow) {
          stopCount = index
        }
        if (index >= stopCount) {
          return null
        }
        /* Search in every cell to see if there is a date object and convert it to string */
        return row.map(item => {
          if (item instanceof Date) {
            return dayjs(item).add(2, 'hour').format(DATE_FORMAT)
          }
          return item
        })
      })
      json2 = json2.map((row, index) => {
        /* Returns nothing if row is empty  */
        if (row && row.length === 0) {
          return null
        }

        /* The template has a legend at the bottom, we will check if the row contains the given strings and after that we will return nothing */
        const isStopRow = row[0] === 'Modelo Reporte Ventas' || row[0] === 'Modelo Reporte Stock'
        if (isStopRow) {
          stopCount = index
        }
        if (index >= stopCount) {
          return null
        }
        /* Search in every cell to see if there is a date object and convert it to string */
        return row.map(item => {
          if (item instanceof Date) {
            const result = dayjs(item).add(2, 'hour').format(DATE_FORMAT)
            return result
          }
          return item
        })
      })
      var data = [
        { wsname, rows: json, cols: cols },
        { wsname: wsname2, rows: json2, cols: cols2 }
      ]
      return callback(null, data)
    }
    if (file && rABS) reader.readAsBinaryString(file)
    else reader.readAsArrayBuffer(file)
  })
}

function makeCols(refstr) {
  var o = []
  var C = XLSX.utils.decode_range(refstr).e.c + 1
  for (var i = 0; i < C; ++i) {
    o[i] = { name: XLSX.utils.encode_col(i), key: i }
  }
  return o
}

export function jsonToExcelDownload(sheet1, sheet2, name) {
  // export json to Worksheet of Excel
  // only array possible
  const animalWS = XLSX.utils.json_to_sheet(sheet1)
  const pokemonWS = XLSX.utils.json_to_sheet(sheet2)

  // A workbook is the name given to an Excel file
  const wb = XLSX.utils.book_new() // make Workbook of Excel

  // add Worksheet to Workbook
  // Workbook contains one or more worksheets
  XLSX.utils.book_append_sheet(wb, animalWS, TEMPLATE_NAMES[0]) // sheetAName is name of Worksheet
  XLSX.utils.book_append_sheet(wb, pokemonWS, TEMPLATE_NAMES[1])

  // export Excel file
  XLSX.writeFile(wb, `${name}.xlsx`) // name of the file is 'book.xlsx'
}

export function jsonToExcelDownloadReport2(sheet1, name) {
  // export json to Worksheet of Excel
  // only array possible
  const animalWS = XLSX.utils.json_to_sheet(sheet1)

  // A workbook is the name given to an Excel file
  const wb = XLSX.utils.book_new() // make Workbook of Excel

  // add Worksheet to Workbook
  // Workbook contains one or more worksheets
  XLSX.utils.book_append_sheet(wb, animalWS, TEMPLATE_NAMES[0]) // sheetAName is name of Worksheet
  const bookType = 'xlsx'
  const compression = true
  const options = { compression: compression, bookType: bookType, type: 'binary' }
  // export Excel file
  let file = XLSX.write(wb, options) // name of the file is 'book.xlsx'
}

export async function jsonToExcelDownloadReport(sheet1, name) {
  // export json to Worksheet of Excel
  // only array possible
  let subSheets = arraySplitter.arraySplitter(sheet1, 10000)
  let date = new Date()
  let now = date.toISOString()
  for (let i = 0; i < subSheets.length; i++) {
    let newName = `${name}`
    if (subSheets.length > 1) {
      newName = `${name}${i + 1}`
    }
    const animalWS = XLSX.utils.json_to_sheet(subSheets[i])
    // A workbook is the name given to an Excel file
    const wb = XLSX.utils.book_new() // make Workbook of Excel
    // add Worksheet to Workbook
    // Workbook contains one or more worksheets
    XLSX.utils.book_append_sheet(wb, animalWS, TEMPLATE_NAMES[0]) // sheetAName is name of Worksheet
    const bookType = 'xlsx'
    const compression = true
    const options = { compression: compression, bookType: bookType }
    // export Excel file
    await new Promise((resolve, reject) => {
      // the interface wasn't clearly documented, but this reasonable guess worked...
      XLSX.writeFile(wb, `${now}${newName}.xlsx`, options)
      resolve()
    })
  }
}

export function jsonToExcelDownloadCorrespondances(sheet1, name) {
  // export json to Worksheet of Excel
  // only array possible
  const animalWS = XLSX.utils.json_to_sheet(sheet1)

  // A workbook is the name given to an Excel file
  const wb = XLSX.utils.book_new() // make Workbook of Excel

  // add Worksheet to Workbook
  // Workbook contains one or more worksheets
  XLSX.utils.book_append_sheet(wb, animalWS, TEMPLATE_NAMES[2]) // sheetAName is name of Worksheet

  // export Excel file
  XLSX.writeFile(wb, `${name}.xlsx`) // name of the file is 'book.xlsx'
}
