import React, {useState} from 'react'
import { useAlert } from 'react-alert'
import { Container, Column, Box, Image, Field, Input, Control, Button, Icon, Message, Label, Checkbox } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import AmbitLogo from '../../config/images/ambit.png'
import { useHistory, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FormError, HelpError } from '../error'
import { logic } from '../../logic'
import { useConfirmActionMessage, useModal } from '../../logic/hooks'

import { Modal } from 'antd'

import * as yup from 'yup'
import { TerminosCondiciones } from '../../config/legal/TerminosCondiciones'
const schema = yup.object().shape({
  newPassword: yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&._€+#-,])[A-Za-z\d@$!%*?&._€+#-,]{8,}$/).required(),
  confirmNewPassword: yup.string().oneOf([yup.ref('newPassword'), null]).required(),
  userName: yup.string().required().max(80),
  phone: yup.string().required().max(20),
  termsAndConditions: yup.bool().oneOf([true])
})

export const Register = () => {
  const [AreTermsAndConditionsRead, setAreTermsAndConditionsRead] = useState(false)
  const termsAndConditionsModal = useModal()
  const privacyStatement = useModal()
  const alert = useAlert()
  const confirmAction = useConfirmActionMessage()
  const history = useHistory()
  const token = window.location.href.split('/').slice(-1)[0]
  const { t } = useTranslation()
  const { register, handleSubmit, errors, setError } = useForm({ validationSchema: schema })
  const onSubmitData = ({ userName, phone, newPassword, confirmNewPassword, termsAndConditions }) => {
    if (newPassword !== confirmNewPassword) {
      return setError('confirmNewPassword', 'passwordNotMatch')
    }
    logic.registerUserDistributor({ userName, phone, newPassword })
      .then(res => {
        if (res.status !== 200) {
          throw Error(res.status)
        }
        alert.show('Registrado satisfactoriamente', { type: 'success' })
        history.push({
          pathname: '/login'
        })
      })
      .catch(err => {
        console.error('error', err.message)
        if (err.message === '409') {
          alert.show('Cuenta bloqueada', {
            type: 'error'
          })
        } else if (err.message === '404') {
          alert.show('Error durante el proceso de registro. Por favor, contacte con su proveedor', {
            type: 'error'
          })
        } else {
          alert.show('Cuenta o contraseña inválida', {
            type: 'error'
          })
        }
      })
  }

  const markTermsAndConditionsAsRead = () => {
    setAreTermsAndConditionsRead(true)
    termsAndConditionsModal.showModal()
  }

  return (
    <Container>
      <Column.Group>
        <Column size={4} offset={4}>
          <Box>
            <Image.Container size='2by1'>
              <Image src={AmbitLogo} />
            </Image.Container>
            <Message color='info'>
              <p>Recuerda, la contraseña tiene que tener un mínimo de ocho caracteres, al menos una mayúscula, una minúscula, un número y un carácter especial. </p>
            </Message>
            <form onSubmit={handleSubmit(onSubmitData)}>
              <Field>
                <Control iconLeft>
                  <Input
                    type='text'
                    color='light'
                    name='userName'
                    ref={register({
                      required: true,
                      maxLength: 80
                    })}
                    placeholder={t('Nombre')}
                  />
                  <Icon size='small' align='left'>
                    <FontAwesomeIcon icon={['fas', 'user']} />
                  </Icon>
                </Control>
                {errors.userName && errors.userName.type === 'required' && (
                  <HelpError message={t('required')} />
                )}
                {errors.userName && errors.userName.type === 'max' && (
                  <HelpError message={t('tooManyCharacters')} />
                )}
              </Field>
              <Field>
                <Control iconLeft iconRight>
                  <Input
                    type='text'
                    color='light'
                    name='phone'
                    ref={register({
                      maxLength: 20
                    })}
                    placeholder='Teléfono'
                  />
                  <Icon size='small' align='left'>
                    <FontAwesomeIcon icon={['fas', 'phone']} />
                  </Icon>
                </Control>
                {errors.phone && errors.phone.type === 'required' && (
                  <HelpError message={t('required')} />
                )}
                {errors.phone && errors.phone.type === 'max' && (
                  <HelpError message={t('tooManyCharacters')} />
                )}
              </Field>

              <Field>
                <Control iconLeft>
                  <Input name='newPassword' ref={register} color='light' type='password' placeholder={t('Nueva contraseña')} autoFocus='' />
                  <Icon align='left'>
                    <FontAwesomeIcon icon={['fas', 'lock']} />
                  </Icon>
                </Control>
                {errors.newPassword && (
                  <HelpError message={t('musthavecorrectformat')} />
                )}
              </Field>
              <Field>
                <Control iconLeft>
                  <Input name='confirmNewPassword' ref={register} color='light' type='password' placeholder={t('Confirmar contraseña')} />
                  {errors.password && errors.password.type === 'required' &&
                    (
                      <FormError showMessage type='danger' message='Required' closeButton={false} />
                    )}
                  <Icon align='left'>
                    <FontAwesomeIcon icon={['fas', 'lock']} />
                  </Icon>
                </Control>
                {errors.confirmNewPassword && (
                  <HelpError message={t('las contraseñas tienen que coincidir')} />
                )}
              </Field>
              <Field>
                {AreTermsAndConditionsRead ?
                  <Label>
                    <Checkbox 
                      name='termsAndConditions' 
                      ref={register({
                        required: true
                      })}
                    /> Estoy de acuerdo con <Link onClick={() => markTermsAndConditionsAsRead()} to={`/register/${token}`}>los términos y condiciones de uso</Link>
                  </Label> :
                  <Label>
                    <Checkbox 
                      //tooltip={t('Debe leer y aceptar los términos y condiciones')}
                      tooltip="Debe leer y aceptar los términos y condiciones"
                      tooltipColor="warning"
                      name='termsAndConditions' 
                      ref={register({
                        required: true
                      })}
                      disabled
                    /> Estoy de acuerdo con <Link onClick={() => markTermsAndConditionsAsRead()} to={`/register/${token}`}>los términos y condiciones de uso</Link>
                  </Label> 
                }
                {errors.termsAndConditions && (
                  <HelpError message={t('Debe leer y aceptar los términos y condiciones')} />
                )}
                {errors.userName && errors.userName.type === 'max' && (
                  <HelpError message={t('tooManyCharacters')} />
                )}
              </Field>
              {confirmAction.showMessage && <FormError {...confirmAction} />}
              <Button color='info' fullwidth>
                <Container>
                  {t('Registrar')}
                </Container>
                <Icon>
                  <FontAwesomeIcon size='lg' icon={['fas', 'sign-in-alt']} />
                </Icon>
              </Button>
            </form>
          </Box>
          <Container textAlign='centered' textColor='grey' />
        </Column>
      </Column.Group>
      <Modal
        title='Condiciones de uso'
        visible={termsAndConditionsModal.isVisible}
        onCancel={() => termsAndConditionsModal.closeModal()}
        onOk={() => termsAndConditionsModal.closeModal()}
        footer={null}
      >
        <TerminosCondiciones />
      </Modal>
    </Container>
  )
}
