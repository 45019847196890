/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { jsonToExcelDownloadReport } from '../../../logic/excelRenderer'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import { useAlert } from 'react-alert'
import { logic } from '../../../logic'
import {
  Title,
  Level,
  Icon,
  Button,
  Box,
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TabsSummary } from '../TabsSummary'
import './MonthlySummary.scss'
import { Table, Switch } from 'antd'

const { Column } = Table

const MONTHS_OFFSET = 1

export const MonthlySummary = () => {


  const alert = useAlert()
  // flag to indicate that the chech button is validating (loading)
  const [isLoadingValidation, setIsLoadingValidation] = useState('active')

  // main state

  // main state
  const [data, setData] = useState()

  // main state

  // date selector start
  const [date, setDate] = useState(new Date())

  // distributors that are searched for
  const [searchSales, setSearchSales] = useState(undefined)

  // set date to one month before 
  useEffect(()=> {
    const currentDate = new Date()
    currentDate.setMonth(currentDate.getMonth() - MONTHS_OFFSET)
    setDate(currentDate)
  }, [])
  // i18n hook
  const { t } = useTranslation()

  // Fetch products when component mounts
  const handleChangeDate = date => {
    setDate(date)
  }

  const handleRetrieveSummary = () => {
    if (date) {
      setIsLoadingValidation('loading')
      logic
        .retrieveMonthlySummary({ date })
        .then(result => {
          
          const data = result && result.data
          let dataFormatted = data.length === 0 ? [] : JSON.parse(JSON.stringify(data))
          setSearchSales(data)
          for (let index = 0; index < data.length; index++) {
            dataFormatted[index].salesitems = dataFormatted[index].salesitems.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            dataFormatted[index].stockitems = dataFormatted[index].stockitems.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            dataFormatted[index].salesunits === '0' ? dataFormatted[index].salesunits = '0,00' : dataFormatted[index].salesunits = dataFormatted[index].salesunits.replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            dataFormatted[index].rawsalesunits === '0' ? dataFormatted[index].rawsalesunits = '0,00' : dataFormatted[index].rawsalesunits = dataFormatted[index].rawsalesunits.replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            dataFormatted[index].stockunits === '0' ? dataFormatted[index].stockunits = '0,00' : dataFormatted[index].stockunits = dataFormatted[index].stockunits.replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            dataFormatted[index].rawstockunits === '0' ? dataFormatted[index].rawstockunits = '0,00' : dataFormatted[index].rawstockunits = dataFormatted[index].rawstockunits.replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
          }
          setData(dataFormatted)
          if (data.length === 0) {
            setSearchSales(undefined)
            alert.show(t('Datos no existentes'), { type: 'error' })
            setIsLoadingValidation('active')
          }
          return data
        })
        .then(data => {
          if (data.length !== 0) {
            alert.show(t('summary succesfully retrieved'), { type: 'success' })
            setIsLoadingValidation('active')
          }
        })
    }
  }

  const handleDownloadSalesData = async () => {
    setIsLoadingValidation('loading')
    if (searchSales) {
      const sheet1 = searchSales.map((item) => {
        const {
          distributorname: distributorName, salesitems, salesunits, stockitems, stockunits
        } = item

        const salesItems = +salesitems
        const salesUnits = +salesunits
        const stockItems = +stockitems
        const stockUnits = +stockunits
        return {
          distributorName, salesItems, salesUnits, stockItems, stockUnits
        }
      })

      // second scenario
      const name = `MonthlySummary${date.getMonth() + 1}`
      
      await jsonToExcelDownloadReport(sheet1, name)
      
      setIsLoadingValidation('active')
    }
  }

  // reset report
  const handleReset = () => {
    setSearchSales(undefined)
  }

  return (
    <Box id='monthlySummary'>
      <TabsSummary highlight='monthly summary' />
      {!searchSales && <Level>
        <Level.Item style={{textAlign:'center'}}>
          <Title size={5}>{t('Seleccionar mes de resumen')}:</Title>&nbsp;
          <DatePicker
            showPopperArrow={false}
            dateFormat='MM/yyyy'
            showMonthYearPicker
            selected={date}
            onChange={handleChangeDate}
          />
        </Level.Item>

      </Level>}
      {!searchSales && <Level>
        <Level.Item>
          <Button onClick={handleRetrieveSummary} color='primary' state={isLoadingValidation}>
            {t('Generar Resumen')}
          </Button>
        </Level.Item>
      </Level>}
      {searchSales && 
        <Level>
          <Level.Item style={{textAlign:'center'}}>
            <Title size={5}>{t('Mes de resumen')}:</Title>&nbsp;
            <DatePicker
              showPopperArrow={false}
              dateFormat='MM/yyyy'
              selected={date}
              disabled
            />
          </Level.Item>
        </Level>
      }
      <Level>
        {searchSales && searchSales.length > 0 && <Level.Item>
          <Button.Group>
            <Button onClick={handleReset} color='primary'>
              {t('Generar Resumen Nuevo')}
            </Button>
            <Button onClick={handleDownloadSalesData} color='success' state={isLoadingValidation}>
              <span>
                {t('Descargar Resumen')}
              </span>
              <Icon>
                <FontAwesomeIcon icon={['fas', 'cloud-download-alt']} />
              </Icon>
            </Button>
          </Button.Group>
        </Level.Item>}
      </Level>     
      <br />
      <br />
      {searchSales && searchSales.length > 0 && logic.notCrossSales && <Switch checkedChildren={t('Sin vtas cruzadas')} disabled checked />} 
      {searchSales && searchSales.length > 0 && !logic.notCrossSales && <Switch unCheckedChildren={t('Con vtas cruzadas')} disabled />} 
      <br /> 
      <br />
      {searchSales && searchSales.length > 0 && <Table dataSource={data} pagination={false}>
        <Column title={t('Distribuidor')} dataIndex='distributorname' key='distributorName' width='50' className='columnaDistribuidor' />
        <Column title={t('Sales Items')} dataIndex='salesitems' align='right' key='salesItems' />
        <Column title={t('Sales Units')} dataIndex='salesunits' align='right' key='salesUnits' />
        <Column title={t('Raw Sales Units')} dataIndex='rawsalesunits' align='right' key='rawSalesUnits' />
        <Column title={t('Stock Items')} dataIndex='stockitems' align='right' key='stockItems' />
        <Column title={t('Stock Units')} dataIndex='stockunits' align='right' key='stockUnits' />
        <Column title={t('Raw Stock Units')} dataIndex='rawstockunits' align='right' key='rawStockUnits' />
      </Table>}
    </Box>
  )
}

