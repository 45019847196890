/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import {
  Box,
  Title,
  Field,
  Select,
  Input,
  Control,
  Label,
  Button,
  Icon
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FormError, HelpError } from '../../../error'
import { logic } from '../../../../logic'
import { TabsDistributor } from '../tabsDistributor'
import { useConfirmActionMessage } from '../../../../logic/hooks'

// import { FormError, HelpError } from '../error'

export const AddEditProductDistributor = () => {
  const alert = useAlert()
  // productTenants
  const [product, setProduct] = useState()
  const [tenantName, setTenantName] = useState()

  // Error handling
  const confirmAction = useConfirmActionMessage()

  const { t } = useTranslation()
  const history = useHistory()
  const { id, tenantId } = useParams()
  const { register, handleSubmit, errors } = useForm()

  // componentDidMount ---> get productBrands, the productTenant, productBusinessUnits, productGroups
  useEffect(() => {
    logic
      .getTenantsAdmin()
      .then(tenants => {
          const tenantName = tenants && tenants.data && tenants.data.filter(tenant => tenant.tenantid.toString() === tenantId)
          setTenantName(tenantName[0].tenantname)
      })
      .catch(err => {
        alert.show(err.message, {
          type: 'error'
        })
      })
    if (id) {
      logic
        .getOneProductDistributor({ productDistributorId: id, tenantId })
        .then(product => {
          setProduct(product && product.data && product.data[0])
        })
        .catch(err => {
          alert.show(err.message, {
            type: 'error'
          })
        })
    }
  }, [])

  const handleGoBackClick = () => {
    history.push({
      pathname: '/product/distributor',
      state: { fromChild: true, tenantId: tenantId } // Passing state indicating it was called from child
    })  
  }

  const onSubmitData = data => {
    const { code, productName } = data
    confirmAction.closeMessage()
    if (id) {
      logic
        .updateProductDistributor({
          tenantId,
          productDistributorId: id.toString(),
          productDistributorCode: code,
          productName: productName
         })
        .then(product => {
          if (product.status !== 200) {
            throw Error(`${product.status}`)
          }
          alert.show(t('updateSudccesful'), {
            type: 'success'
          })
        })
        .catch(err => {
          if (err.message === '409') {
            alert.show('Input already exists', {
              type: 'error'
            })
          } else {
            alert.show('Unexpected Error', {
              type: 'error'
            })
          }
        })
    } else {
      logic
        .insertProductDistributor({
          productDistributorCode: code,
          distributorProductName: productName,
          tenantId
        })
        .then(product => {
          if (product.status !== 200) {
            throw Error(product.status)
          }
          history.push({
            pathname: '/product/distributor',
            state: '200'
          })
        })
        .catch(err => {
          if (err.message === '409') {
            alert.show('Input already exists', {
              type: 'error'
            })
          } else {
            alert.show('Unexpected Error', {
              type: 'error'
            })
          }
        })
    }
  }

  return (
    <Box>
      <TabsDistributor highlight='products' />
       <Button onClick={e => handleGoBackClick()} color='light' size='medium'>
        <Icon>
          <FontAwesomeIcon size='lg' icon={['fas', 'chevron-circle-left']} />
        </Icon>
          &nbsp;&nbsp;&nbsp;{tenantName}
       </Button>
      <Title>
        {id
          ? `${t('edit')} ${product && product.distributorproductname}`
          : `${t('add')} ${t('product')}`}
      </Title>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <Field>
          <Label>{t('code')}</Label>
          <Control iconLeft>
            <Input
              defaultValue={product && product.productdistributorcode}
              type='text'
              color='light'
              name='code'
              ref={register({
                required: true,
                maxLength: 50
              })}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'archive']} />
            </Icon>
          </Control>
          {errors.code &&
            errors.code.type === 'required' && (
              <HelpError message={t('required')} />
            )}
          {errors.code &&
            errors.code.type === 'maxLength' && (
              <HelpError message={t('tooManyCharacters')} />
            )}
        </Field>

        <Field>
          <Label>{t('name')}</Label>

          <Control iconLeft iconRight>
            <Input
              defaultValue={product && product.distributorproductname}
              type='type'
              color='light'
              name='productName'
              ref={register({
                maxLength: 1000,
                required: true
              })}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {errors.productName && errors.productName.type === 'maxLength' && (
            <HelpError message={t('tooManyCharacters')} />
          )}
          {errors.productName &&
            errors.productName.type === 'required' && (
              <HelpError message={t('required')} />
            )}
        </Field>

        <Field kind='group'>
          <Control>
            {id ? (
              <Button color='link'>{t('update')}</Button>
            ) : (
              <Button color='link'>{t('add')}</Button>
            )}
          </Control>
          <Control>
            <Button as={Link} to='/product/distributor' type='button' text>
              {t('cancel')}
            </Button>
          </Control>
          {confirmAction.showMessage && <FormError {...confirmAction} />}
        </Field>
      </form>
    </Box>
  )
}
