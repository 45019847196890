/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, useRef, useContext } from 'react'
import { useAlert } from 'react-alert'
import { useTranslation } from 'react-i18next'
import {
  Title,
  Level,
  Icon,
  Button,
  Field,
  Input,
  Control,
  Table,
  Box,
  Select
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Pagination } from '../pagination'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useToggle } from '../../logic/hooks'
import { Search } from '../filter/Search'
import { Modal } from '../modal'
import { AdsContext } from "../../contexts/adsContext"
import { TabsDashboardsAdmin } from './tabsDashboardsAdmin'
import { FormError } from '../error'
import { useForm } from 'react-hook-form'
import { TableOfTables } from '../../logic/tableOfTables'
let isValidPass = false

export const DashboardsAdmin = () => {
  const alert = useAlert()
  // Hooks for toggling on and off the modal
  const [open, toggle] = useToggle(false)
  const [openInactive, toggleInactive] = useToggle(false)
  const { allTableOfTables } = useContext(AdsContext)

  const [deleteItem, setDeleteItem] = useState()

  // state inside the modal
  const [related, setRelated] = useState([])
  
  // tenants that are searched for
  const [searchDashboards, setSearchDashboards] = useState()
  const [dropdownState, setDropdownState] = useState('active')

  // i18n hook
  const { t } = useTranslation()
  const { register, handleSubmit, errors } = useForm()

  // Routing Variables
  const { url } = useRouteMatch()
  const history = useHistory()

  // State refering to the pagination attributes comming from the pagination component
  const defaultPaginationStartPosition = 0
  const defaultPaginationEndPosition = 10
  const [startPosition, setStartPosition] = useState(
    defaultPaginationStartPosition
  )
  const [endPosition, setEndPosition] = useState(defaultPaginationEndPosition)

  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const paginationRef = useRef()
  const { allAdminDashboards, fetchAllAdminDashboards, activateAdminDashboard, inactivateAdminDashboard } = useContext(AdsContext)

  // Fetch tenants when component mounts
  useEffect(() => {
    (async function () {
      const allDashboards = await fetchAllAdminDashboards()
      const activeDashboards = allDashboards.filter(
        dashboard => {
          return dashboard.active === true
        }
      )
      setSearchDashboards(activeDashboards)
    })()
  }, [])
  
  // handles the pagination requests
  const handleRequestPagination = requestPaginationObject => {
    let newStartPosition = defaultPaginationStartPosition
    let newEndPosition = defaultPaginationEndPosition
    if (requestPaginationObject) {
      const { currentPage, itemsPerPage } = requestPaginationObject
      newStartPosition = currentPage * itemsPerPage - itemsPerPage
      newEndPosition = newStartPosition + itemsPerPage
    }
    setStartPosition(newStartPosition)
    setEndPosition(newEndPosition)
  }

  // handles routing to add/edit
  const handleAddEditClick = (event, element) => {
    if (element) {
      history.push({
        pathname: `${url}/edit/${element && element.dashboardid}`
      })
    } else {
      history.push({
        pathname: `${url}/add`
      })
    }
  }
  const handleActivateItem = async (event, element) => {
    const id = `${element.dashboardid}`
    try {
      const updatedDashboard = await activateAdminDashboard(id)
      const active = false
      newDashboardsSorted(updatedDashboard, active)
      toggleInactive()
    }
    catch (error) {
      toggleInactive()
      throw Error(error)
    }
  }

  const handleDeleteClick = async (event, element) => {
    const id = `${element.dashboardid}`
    try {
      const updatedDashboard = await inactivateAdminDashboard(id)
      const active = true
      newDashboardsSorted(updatedDashboard, active)
      toggle()
    } catch (error) {
      toggle()
      throw Error(`Error while inactivating the dashboard id: ${id} for the tenant: ${element.distributorname} with dashboard name: ${element.dashboardname}`)
    }
  }

  const newDashboardsSorted = (updatedDashboard, active) => {
    const newDashboards = allAdminDashboards.filter(
      dashboard => {
        return dashboard.dashboardid !== updatedDashboard.dashboardid && dashboard.active === active
      }
    )
    const sortedNewDashboards =
    newDashboards &&
    newDashboards.sort((a, b) => {
      if (a.distributorname.toLowerCase() < b.distributorname.toLowerCase()) {
        return -1
      }
      if (a.distributorname.toLowerCase() > b.distributorname.toLowerCase()) {
        return 1
      }
      return 0
    })
    setSearchDashboards(sortedNewDashboards)
  }

  const showRelatedItems = (event, item) => {
    setDeleteItem({ ...item })
    // TODO set correct logic for related
    setRelated([])
    toggle()
  }

  const showRelatedItemsInactive = (event, item) => {
    setDeleteItem({ ...item })
    setRelated([])
    toggleInactive()
  }

  const handleDoSearch = value => {
    let active
    if (dropdownState === 'active') {
      active = true
    } else {
      active = false
    }
    const result = allAdminDashboards.filter(dashboard => {
      const values = Object.values(dashboard)

      const isIncluded = values
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
      // pagination Reset vallues
      paginationRef.current.resetValues()

      return isIncluded && dashboard.active === active
    })
    setSearchDashboards(result)
    return result
  }

  const handleDropdownStateChange = (event) => {
    setDropdownState(event.target.value)
    let active
    if(event.target.value === 'active') {
      active = true
    } else {
      active = false
    }

    const selectedDashboards = allAdminDashboards.filter(dashboard => { if(dashboard.active == active) return dashboard})
    setSearchDashboards(selectedDashboards)
    paginationRef.current.resetValues()
  }

  const onSubmitData = ({ password }) => {
    isValidPass = TableOfTables('SUPADMIN', password, allTableOfTables, null)
  }

  return isValidPass ? (
    <Box id='dashboards'>
      {open && (
        <Modal toggle={toggle} open={open}>
          <Title>{t('confirmDelete')}</Title>
            <>
              <Title subtitle>{t('deleteSure')}</Title>
                <Button
                  onClick={e => handleDeleteClick(e, deleteItem)}
                  color='danger'
                >
                  {t('delete')}
                </Button>
            </>
        </Modal>
      )}
      {openInactive && (
        <Modal toggle={toggleInactive} open={openInactive}>
          <Title>{t('confirmActivate')}</Title>
            <>
              <Title subtitle>{t('activateSure')}</Title>
              <Button
                onClick={e => handleActivateItem(e, deleteItem)}
                color='success'
              >
                {t('Activate')}
              </Button>
            </>
        </Modal>
      )}
      <TabsDashboardsAdmin highlight='dashboards'/>
      <Level>
        <Select.Container>
          <Select value={dropdownState} onChange={handleDropdownStateChange} >
            <Select.Option value='active'>
              {t('Actives')}
            </Select.Option>
            <Select.Option value='inactive'>
              {t('Inactives')}
            </Select.Option>
          </Select>
        </Select.Container>
        <Level.Item>
          <Search doSearch={handleDoSearch} />
        </Level.Item>
        <Level.Item align='right'>
          <Button onClick={e => handleAddEditClick()} color='info'>
            <Icon>
              <FontAwesomeIcon size='lg' icon={['fas', 'plus']} />
            </Icon>
          </Button>
        </Level.Item>
      </Level>
      <Table hoverable bordered striped narrow fullwidth>
        <Table.Head>
          <Table.Row>
            <Table.Heading className='dashboard' colSpan='1'>
              {t('distributor name')}
            </Table.Heading>
            <Table.Heading className='dashboard' colSpan='1'>
              {t('dashboard name')}
            </Table.Heading>
            <Table.Heading className='dashboard' colSpan='1'>
              {t('dashboard url')}
            </Table.Heading>
            <Table.Heading className='dashboard' colSpan='1'>
              {t('status')}
            </Table.Heading>
            <Table.Heading colSpan='3'>
              {t('action')}
            </Table.Heading>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {searchDashboards &&
            searchDashboards
              .slice(startPosition, endPosition)
              .map((item, index) => {
                return (
                  <Table.Row key={item.dashboardid}>
                    <Table.Cell>{item.distributorname}</Table.Cell>
                    <Table.Cell>{item.dashboardname}</Table.Cell>
                    <Table.Cell>{item.dashboardurl}</Table.Cell>
                    <Table.Cell>{item.active ? 'active' : 'inactive'}</Table.Cell>
                    <Table.Cell>
                      <button
                        onClick={e => handleAddEditClick(e, item)}
                        className='button is-primary is-outlined is-small'
                      >
                        <Icon>
                          <FontAwesomeIcon size='lg' icon={['fas', 'edit']} />
                        </Icon>
                      </button>
                      {dropdownState === 'active' && <button
                        onClick={e => showRelatedItems(e, item)}
                        className='button is-danger is-outlined is-small'
                      >
                        <Icon>
                          <FontAwesomeIcon size='lg' icon={['fas', 'trash']} />
                        </Icon>
                      </button>}
                      {dropdownState === 'inactive' && <button
                        onClick={e => showRelatedItemsInactive(e, item)}
                        className='button is-success is-outlined is-small'
                      >
                        <Icon>
                          <FontAwesomeIcon size='lg' icon={['fas', 'check-circle']} />
                        </Icon>
                      </button>}
                    </Table.Cell>
                  </Table.Row>
                )
              })}
          {!searchDashboards && (
            <Table.Row>
              <Table.Heading colSpan='4'>{t('noDataAvailable')}</Table.Heading>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <Pagination
        totalItems={searchDashboards && searchDashboards.length}
        ref={paginationRef}
        requestPagination={handleRequestPagination}
      />
    </Box>
  )
  :
  (
    <Box>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <Field>
          <Control iconLeft>
            <Input
              name='password'
              ref={register({ required: true, minLength: 2 })}
              color='light'
              type='password'
              placeholder={t('password')}
            />
            {errors.password && errors.password.type === 'required' && (
              <FormError
                showMessage
                type='danger'
                message='Required'
                closeButton={false}
              />
            )}
            <Icon align='left'>
              <FontAwesomeIcon icon={['fas', 'lock']} />
            </Icon>
          </Control>
        </Field>
      </form>
    </Box>
  )
}
