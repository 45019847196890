import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tab, Icon } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileContract } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

export const TabsSales = ({ highlight }) => {
  
  const { t } = useTranslation()

  return (
    <Tab.Group align='centered' kind='boxed'>
      <Tab as={Link} to="/objectives/sales" active={highlight === 'sales'}>
        <Icon size='small'>
          <FontAwesomeIcon icon={faFileContract} />
        </Icon>
         <span>{t('objective_plural')}</span> 
      </Tab>
    </Tab.Group>
  )
}
