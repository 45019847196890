/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import {
  Box,
  Title,
  Field,
  Input,
  Control,
  Label,
  Button,
  Icon,
  Checkbox
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FormError } from '../../../error'
import { logic } from '../../../../logic'
import { TabsTenant } from '../../tabsTenant'
import { useConfirmActionMessage } from '../../../../logic/hooks'
import { NOTIFICATIONS } from '../../../../notifications'
import { useAlert } from 'react-alert'

export const BUTTON_STATES = { hovered: 'hovered', focused: 'focused', active: 'active', loading: 'loading' }
export const AddEditUserTenant = () => {
  // userTenant
  const [userTenant, setUserTenant] = useState()

  const confirmAction = useConfirmActionMessage()
  const alert = useAlert()
  // Button request loading
  const [buttonState, setButtonState] = useState(BUTTON_STATES.active)

  const { t } = useTranslation()
  const history = useHistory()
  const { userId, tenantId } = useParams()
  const { register, handleSubmit, errors, watch } = useForm()

  // load
  useEffect(() => {
    function loadUserId () {
      
        logic.retrieveUserTenant(userId)
        .then(user => {
          if (user.status !== 200) {
            alert.show('Error de red', {
              type: 'error'
            })
          }
          setUserTenant(user && user.data && user.data[0])
        })
    }
    if (userId) {
      loadUserId()
    }
  }, [])

  const handleGoBackClick = () => {
    history.push('/tenant/users')
  }

  const onSubmitData = data => {
    setButtonState(BUTTON_STATES.loading)

    const { email } = data
    // we destructure as let because we wil be passing the city and province name, not id
    confirmAction.closeMessage()
    if (userId) {
      logic
        .updateUserDistributor({
          userDistributorId: userId.toString(),
          tenandId: tenantId.toString(),
          distributorId: logic.distributorId.toString()
         })
        .then(userTenant => {
          if (userTenant.status !== 200) {
            throw Error(`${userTenant.status}`)
          }
          alert.show(t('updateSuccesful'), { type: 'success' })
        })
        .catch(err => {
          if (err.message === '409') {
            alert.show(t('Input already exists'), { type: 'error' })
          } else {
            alert.show(t('Error inesperado'), { type: 'error' })
          }
        })
    } else {
      logic
        .addUserTenant({
          userEmail: email,
          tenantId: tenantId.toString(),
          distributorId: logic.distributorId.toString()
        })
        .then(userTenant => {
          if (userTenant.status !== 200) {
            throw Error(userTenant.status)
          }
          alert.show(t(NOTIFICATIONS.userDistributorCreate), { type: 'success' })
          history.push({
            pathname: '/tenant/users'
          })
        })
        .catch(err => {
          if (err.message === '409') {
            alert.show(t('Input already exists'), { type: 'error' })
          } else {
            alert.show(t('Error inesperado'), { type: 'error' })
          }
        })
    }
    setButtonState(BUTTON_STATES.active)
  }

  return (
    <Box>
      <TabsTenant highlight='users' />
       <Button onClick={e => handleGoBackClick()} color='light' size='medium'>
        <Icon>
          <FontAwesomeIcon size='lg' icon={['fas', 'chevron-circle-left']} />
        </Icon>
       </Button>
      <Title>
        {userId
          ? `${t('edit')} ${userTenant && userTenant.useremail}`
          : `${t('add')} ${t('user')}`}
      </Title>
      <form onSubmit={handleSubmit(onSubmitData)}>
      <Field>
                <Control iconLeft>
                  <Input
                    name='email'
                    ref={register({
                      required: !userId
                    })}
                    color='light'
                    type='email'
                    placeholder={(userTenant && userTenant.useremail) || t('email')}
                    autoFocus=''
                    disabled={!!userId}
                  />
                  {errors.email && errors.email.type === 'required' && (
                    <FormError
                      showMessage
                      type='danger'
                      message='Required'
                      closeButton={false}
                    />
                  )}
                  {errors.email && errors.email.type === 'validate' && (
                    <FormError message={t('email already taken')} />
                  )}
                  <Icon align='left'>
                    <FontAwesomeIcon icon={['fas', 'envelope']} />
                  </Icon>
                </Control>
      </Field>
        <Field kind='group'>
          <Control>
            {userId ? (
              <Button state={buttonState} color='link'>{t('update')}</Button>
            ) : (
              <Button state={buttonState} color='link'>{t('add')}</Button>
            )}
          </Control>
          <Control>
            <Button as={Link} to='/tenant/users' type='button' text>
              {t('cancel')}
            </Button>
          </Control>
          {confirmAction.showMessage && <FormError {...confirmAction} />}
        </Field>
      </form>
    </Box>
  )
}
