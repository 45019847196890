import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { logic } from '../../logic'
import { Select, Label, Card, Content, Level, Field } from 'rbx'
import { useForm, Controller } from 'react-hook-form'
import { LocaleUtils } from 'react-day-picker'
import ReactSelect from 'react-select'

export const DistributorSelector = props => {
  const [distributors, setDistributors] = useState()

  const [options, setOptions] = useState()
  const { t } = useTranslation()

  useEffect(() => {
    logic
      .getAllDistributors()
      .then(tenants => {
        setDistributors(tenants && tenants.data)
        setOptions(tenants && tenants.data && tenants.data.map(distributor => {
            const {distributorid: distributorTenantId, distributorname: distributorName, distributoridentity: distributorIdentity} = distributor

            return {
                    label: `${distributorIdentity} ·${distributorName}`,
                    value: distributorTenantId
                }        
        }))
      })
      .catch(err => {
        console.error(err.message)
      })
  }, [])

  const handleDistributorChange = distributor => {
    props.onDistributorSelect(distributor)
  }

  return (
    <>
      <Card>
        <Card.Content>
          
              <Content>
                <Label>{t('Seleccionar distribuidor')}:</Label>
                <Field>
                 <ReactSelect isClearable isSearchable options={options}  onChange={value=> handleDistributorChange(value)}/>
                </Field>
              </Content>
           
        </Card.Content>
      </Card>
    </>
  )
}