var municipesData = require('../data/municipios')
var provincesData = require('../data/provincias')

function RemoveAccents (str) {
  var accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž'
  var accentsOut = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz'
  str = str.split('')
  var strLen = str.length
  var i, x
  for (i = 0; i < strLen; i++) {
    if ((x = accents.indexOf(str[i])) != -1) {
      str[i] = accentsOut[x]
    }
  }
  return str.join('')
}
// Expose data
Pselect.municipesData = municipesData
Pselect.provincesData = provincesData

function Pselect (options) {
  options = options || {}
  this.provinceDefaultText = options.provText || 'Provincia'
  this.municipeDefaultText = options.munText || 'Municipio'
}

Pselect.prototype.create = function (provincesElement, municipesElement, defaultProvince = -1, defaultCity = -1) {
  this._provElement = provincesElement
  this._munElement = municipesElement
  this._defaultProvince = defaultProvince
  this._defaultCity = defaultCity

  // Add default options to each select
  _addOption(provincesElement, this.provinceDefaultText, defaultProvince, true)
  _addOption(municipesElement, this.municipeDefaultText, defaultCity, true)
  /// alfabetize the provinces
  const provinces = []
  for (var i = 0; i < provincesData.length; i++) {
    var province = provincesData[i]
    provinces.push(province)
  }
  const sortedProvinces =
    provinces &&
    provinces.sort((a, b) => {
      const formatedA = RemoveAccents(a.nm)
      const formatedB = RemoveAccents(b.nm)
      if (formatedA < formatedB) {
        return -1
      }
      if (formatedA > formatedB) {
        return 1
      }
      return 0
    })
  for (var j = 0; j < sortedProvinces.length; j++) {
    var sortProvince = sortedProvinces[j]
    _addOption(provincesElement, sortProvince.nm, sortProvince.id)
  }
  // Callback when the selected province is changed
  provincesElement.addEventListener('change', this.__onProvinceSelected.bind(this))
}

Pselect.prototype.__onProvinceSelected = function (event) {
  var province = event.detail ? event.detail : event.target.value
  // Remove current municipe elements
  this._munElement.innerHTML = ''
   _addOption(this._munElement, this.municipeDefaultText, this.defaultCity, true)
  // Append new municipes list filtered by selected province
  for (var i = 0; i < municipesData.length; i++) {
    var municipe = municipesData[i]
    if (municipe.id.indexOf(province) === 0) {
      _addOption(this._munElement, municipe.nm, municipe.id)
    }
  }
}

function _addOption (parentElement, text, value, disabled) {
  var optionElement = document.createElement('option')
  optionElement.value = value
  optionElement.innerHTML = text
  if (disabled) {
    optionElement.setAttribute('selected', '')
    optionElement.setAttribute('disabled', '')
  }
  parentElement.appendChild(optionElement)
}

export default Pselect
// module.exports = Pselect
