/* eslint-disable react/jsx-indent */
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { logic } from '../../logic'
import { Role } from '../../config/role'

export const PrivateRoute = ({ role, component: Component, ...props }) => {
  //export const PrivateRoute = ({ role, component: Component, token, setToken, ...props }) => {
  const { loggedIn, isTenant, isDistributor, isAdmin } = logic
  let isValidRole = false

  if (role === Role.Tenant) {
    isValidRole = isTenant
  }
  if (role === Role.Distributor) {
    isValidRole = isTenant || isDistributor || isAdmin
  }
  if (role === Role.Admin) {
    isValidRole = isAdmin
  }
 /*  console.log("in private route with setToken: ", setToken)
  console.log("in private route with token: ", token) */
  return (
    <Route
      {...props} render={innerProps =>
        (
          (loggedIn && isValidRole) ? (
            // <Component {...innerProps} token={token} setToken={setToken}/>
            <Component {...innerProps} />
          )
            : (
              <Redirect to='/login' />
            )
        )}
    />
  )
}
