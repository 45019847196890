// This function is used and can be used to return whether an element exists within the table of tables
// The function expects:
// - whichTable = name of the table of tables
// - elementToFind = what needs to be found within the table of Tables
// - tableOfTables = all the table of tables registries (are kept in the adsContext store)
// - active = by default true but can be true, false or null (null means all registries are taken into account actives or not)
export const TableOfTables = (whichTable, elementToFind, tableOfTables, active=true) => {
  let tenantWithPvls = []
  if (tableOfTables.filter( tableoftable => tableoftable.tableoftablesname === whichTable && 
                            (active !== null ? tableoftable.active === active : tableoftable.active !== active)
                          ).length > 0) {
    tableOfTables.filter(tableoftable => {
      if (tableoftable.tableoftablesname === whichTable && 
          tableoftable.tableoftablesdata.split('###')[0] === 'DATA' &&
          (active !== null ? tableoftable.active === active : tableoftable.active !== active)) {
        tenantWithPvls.push(tableoftable.tableoftablesdata.split('###')[1])
      }
    })
  }
  return tenantWithPvls.includes(elementToFind)
}
