/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useState, useEffect, useRef } from 'react'
import { useAlert } from 'react-alert'
import { useTranslation } from 'react-i18next'
import { logic } from '../../../logic'
import { TabsProduct } from '../tabsProduct'
import { Level, Box, Title, Field, Icon, Button, Table, Select } from 'rbx'
import { Modal } from '../../modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Pagination } from '../../pagination'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useToggle } from '../../../logic/hooks'
import { Search } from '../../filter/Search'

export const ProductBrand = () => {
  const alert = useAlert()
  // Hooks for toggling on and off the modal
  const [open, toggle] = useToggle(false)
  const [openInactive, toggleInactive] = useToggle(false)
  
  const [activateInactivateItem, setActivateInactivateItem] = useState()

  // state inside the modal
  const [related, setRelated] = useState([])

  // main state, what the component will recieve
  const [brands, setBrands] = useState([])

  // brands that are searched for. what will be actually seen in the table
  const [searchBrands, setSearchBrands] = useState([])

  const { t } = useTranslation()

  // Routing variables
  const history = useHistory()
  const { url } = useRouteMatch()

  // State refering to the pagination attributes comming from the pagination component
  const defaultPaginationStartPosition = 0
  const defaultPaginationEndPosition = 10
  const [startPosition, setStartPosition] = useState(
    defaultPaginationStartPosition
  )
  const [endPosition, setEndPosition] = useState(defaultPaginationEndPosition)
  // React active or inactive
  const [dropdownState, setDropdownState] = useState('active')

  // Fetch products when component mounts
  useEffect(() => {
    async function fetchData() {
      await getProductBrands()    
    }
    fetchData()
  }, [])

  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const paginationRef = useRef()

  // handles the pagination requests
  const handleRequestPagination = requestPaginationObject => {
    let newStartPosition = defaultPaginationStartPosition
    let newEndPosition = defaultPaginationEndPosition
    if (requestPaginationObject) {
      const { currentPage, itemsPerPage } = requestPaginationObject
      newStartPosition = currentPage * itemsPerPage - itemsPerPage
      newEndPosition = newStartPosition + itemsPerPage
    }
    setStartPosition(newStartPosition)
    setEndPosition(newEndPosition)
  }

  // handles routing to add/edit
  const handleAddEditClick = (event, element) => {
    if (element) {
      history.push({
        pathname: `${url}/edit/${element && element.productbrandid}`,
        state: element
      })
    } else {
      history.push({
        pathname: `${url}/add`
      })
    }
  }

  // deletes the item
  const handleDeleteClick = (event, element) => {
    const id = `${element.productbrandid}`
    logic
      .deleteProductBrand({ productBrandId: id })
      .then(brandsWoDeletedOne => {
        if (brandsWoDeletedOne.status !== 200) {
          throw Error(brandsWoDeletedOne.error)
        }
        const newBrands = brands.filter(brand => brand.productbrandid !== brandsWoDeletedOne.data[0].productbrandid)
        alert.show(t('Marca eliminada'), { type: 'success' })
        setBrands(newBrands)
        setSearchBrands(newBrands)
        toggle()
      })
      .catch(err => {
        alert.show(t('errorinactivating')+`. ${err}`, { type: 'error' })
        console.error(err.message)
        toggle()        
      })
  }

  const handleActivateItem = (event, element) => {
    const id = `${element.productbrandid}`
    logic.activateProductBrand({ productBrandId: id })
      .then(brandsWoActivatedOne => {
        if (brandsWoActivatedOne.status !== 200) {
          throw Error(brandsWoActivatedOne.error)
        }
        const newBrands = brands.filter(brand => brand.productbrandid !== brandsWoActivatedOne.data[0].productbrandid)
        alert.show(t('Marca activada'), { type: 'success' })
        setBrands(newBrands)
        setSearchBrands(newBrands)
        toggleInactive()
      })
      .catch(err => {
        alert.show(t('erroractivating')+`. ${err}`, { type: 'error' })
        console.error(err.message)
        toggleInactive()
      })
  }

  const showRelatedItems = (event, item) => {
    setActivateInactivateItem({ ...item })
    const productBrandId = item.productbrandid.toString()
    const userId = logic.sessionId.toString()
    logic
      .getRelatedProductBrands({ productBrandId, userId })
      .then(async items => {
        if (items.status !== 200) {
          throw Error(`${items.status}`)
        }
        setRelated(items.data)
      })
      .then(() => {
        toggle()
      })
      .catch(err => {
        if (err.message === '409') {
           alert.show('Input already exists', {
            type: 'error'
          })
        } else {
           alert.show('Unexpected Error', {
            type: 'error'
          })
        }
      })
      .catch(() => {
        toggle()
      })
  }

  const showRelatedItemsInactive = (event, item) => {
    setActivateInactivateItem({ ...item })
    
    // TODO set correct logic for related
    setRelated([])
    toggleInactive()
  }

  const handleDoSearch = value => {
    const result = brands.filter(brand => {
      const values = Object.values(brand)

      const isIncluded = values
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(value.toLowerCase().replace(/\s+/g, ''))
      // pagination Reset vallues
      paginationRef.current.resetValues()

      return isIncluded
    })
    const sortedResult = result.sort((a, b) => {
      if (a.productbrandname.toLowerCase() < b.productbrandname.toLowerCase()) {
        return -1
      }
      if (a.productbrandname.toLowerCase() > b.productbrandname.toLowerCase()) {
        return 1
      }
      return 0
    })
    setSearchBrands(sortedResult)
    return sortedResult
  }

  const handleDropdownStateChange = async (event) => {
    setDropdownState(event.target.value)
    const activeInactive = event.target.value
    await getProductBrands(activeInactive)
  }

  
  async function getProductBrands(activeInactive = 'active') {
    try {
      const sessionId = logic.sessionId.toString()
      const brands = await logic.getProductBrands(sessionId)
      let data = brands && brands.data
      data = data.filter(item => {
        return activeInactive === 'active' ? item.active : !item.active
      }) 
      const sortedBrands =
        data.sort((a, b) => {
          if (a.productbrandname.toLowerCase() < b.productbrandname.toLowerCase()) {
            return -1
          }
          if (a.productbrandname.toLowerCase() > b.productbrandname.toLowerCase()) {
            return 1
          }
          return 0
        })
      setBrands(sortedBrands)
      setSearchBrands(sortedBrands)
    } catch (error) {
        alert.show('getbrandserror', {
          type: 'error'
        })
    }
  }

  return (
    <Box>
      {open && (
        <Modal toggle={toggle} open={open}>
          <Title>{t('confirmDelete')}</Title>
          {related && related.length !== 0 && (
            <Title subtitle size={6}>
              {t('cannotDeleteRelated')}
            </Title>
          )}
          {related &&
            related.length !== 0 &&
            related.length > 7 &&
            related.slice(0, 7).map((item, index) => {
              return (
                <>
                  <Field key={index}>{item.productname}</Field>
                </>
              )
            })}
          {related && related.length !== 0 && related.length > 7 && (
            <Field>
              ...{t('and')} {related.length - 7} {t('others')}
            </Field>
          )}
          {related &&
            related.length !== 0 &&
            related.length <= 7 &&
            related.map((item, index) => {
              return (
                <>
                  <Field key={index}>{item.productname}</Field>
                </>
              )
            })}
          {related && related.length === 0 && (
            <>
              <Title subtitle>{t('deleteSure')}</Title>
              <Button
                onClick={e => handleDeleteClick(e, activateInactivateItem)}
                color='danger'
              >
                {t('delete')}
              </Button>
            </>
          )}
        </Modal>
      )}
      {openInactive && (
        <Modal toggle={toggleInactive} open={openInactive}>
          <Title>{t('confirmActivate')}</Title>
          {related && related.length !== 0 && (
            <Title subtitle size={6}>
              {t('cannotActivateRelated')}
            </Title>
          )}
          {related && related.length !== 0 && related.length > 7 && related.slice(0, 7).map((item, index) => {
            return (<>
              <Field key={index}>
                {item.productname}
              </Field>
                    </>)
          })}
          {related && related.length !== 0 && related.length <= 7 && related.map((item, index) => {
            return (<>
              <Field key={index}>
                {item.productname}
              </Field>
                    </>)
          })}
          {related && related.length !== 0 && related.length > 7 && (
            <Field>
              ...{t('and')} {related.length - 7} {t('others')}
            </Field>
          )}
          {related && related.length === 0 && (
            <>
              <Title subtitle>{t('activateSure')}</Title>
              <Button
                onClick={e => handleActivateItem(e, activateInactivateItem)}
                color='success'
              >
                {t('Activate')}
              </Button>
            </>
          )}
        </Modal>
      )}
      <TabsProduct highlight='brands' />
      <Level>
        <Select.Container>
          <Select value={dropdownState} onChange={handleDropdownStateChange} >
            <Select.Option value='active'>
              {t('Actives')}
            </Select.Option>
            <Select.Option value='inactive'>
              {t('Inactives')}
            </Select.Option>
          </Select>
        </Select.Container>
        <Level.Item>
          <Search doSearch={handleDoSearch} />
        </Level.Item>
        <Level.Item align='right'>
          <Button onClick={e => handleAddEditClick()} color='info'>
            <Icon>
              <FontAwesomeIcon size='lg' icon={['fas', 'plus']} />
            </Icon>
          </Button>
        </Level.Item>
      </Level>
      <Table fullwidth narrow hoverable bordered striped>
        <Table.Head>
          <Table.Row>
            <Table.Heading>{t('name')}</Table.Heading>
            <Table.Heading>{t('action')}</Table.Heading>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {searchBrands &&
            searchBrands.slice(startPosition, endPosition).map(item => {
              return (
                <Table.Row
                  onDoubleClick={e => handleAddEditClick(e, item)}
                  key={item.productBrandId}
                >
                  <Table.Cell>{item.productbrandname}</Table.Cell>
                  <Table.Cell>
                    {item.productbrandname !== 'DESCONOCIDO' && 
                      <button onClick={e => handleAddEditClick(e, item)} className='button is-primary is-outlined is-small'>
                        <Icon>
                          <FontAwesomeIcon size='lg' icon={['fas', 'edit']} />
                        </Icon>
                      </button>
                    }
                    {dropdownState === 'active' && item.productbrandname !== 'DESCONOCIDO' && 
                        <button
                          onClick={e => showRelatedItems(e, item)}
                          className='button is-danger is-outlined is-small'
                        >
                          <Icon>
                            <FontAwesomeIcon size='lg' icon={['fas', 'trash']} />
                          </Icon>
                        </button>
                    }
                    {dropdownState === 'inactive' && item.productbrandname !== 'DESCONOCIDO' && 
                      <button
                        onClick={e => showRelatedItemsInactive(e, item)}
                        className='button is-success is-outlined is-small'
                      >
                        <Icon>
                          <FontAwesomeIcon size='lg' icon={['fas', 'check-circle']} />
                        </Icon>
                      </button>
                    }
                  </Table.Cell>
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table>
      <Pagination
        totalItems={brands && brands.length}
        ref={paginationRef}
        requestPagination={handleRequestPagination}
      />
    </Box>
  )
}
