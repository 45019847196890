/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import {
  Select,
  Textarea,
  Box,
  Title,
  Field,
  Input,
  Control,
  Label,
  Button,
  Icon,
  Checkbox,
  Level
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FormError, HelpError } from '../../error'
import { logic } from '../../../logic'
import { TabsDistributor } from '../tabsDistributor'
import { useConfirmActionMessage } from '../../../logic/hooks'
import Pselect from '../../../logic/pselect.js/src'
import { validateSpanishId } from 'spain-id'
import { MAXMONTHTOBLOCK } from '../../../config/config'

function findProvinceName (provinceId) {
  if (!provinceId) return undefined
  const result = Pselect.provincesData.filter(province => {
    return province.id.toString() === provinceId.toString()
  })
  if (result && result[0] && result[0].id) {
    return result[0].nm
  } else return undefined
}

function findCityName (cityId) {
  if (!cityId) return undefined
  const result = Pselect.municipesData.filter(city => {
    return city.id.toString() === cityId.toString()
  })
  if (result && result[0] && result[0].id) {
    return result[0].nm
  } else return undefined
}

function findProvinceId (provinceName) {
  if (!provinceName) return undefined
  const result = Pselect.provincesData.filter(province => {
    return province.nm.toString() === provinceName.toString()
  })
  if (result && result[0] && result[0].nm) {
    return result[0].id
  } else return undefined
}

function findCityId (cityName) {
  if (!cityName) return undefined
  const result = Pselect.provincesData.filter(city => {
    return city.nm.toString() === cityName.toString()
  })
  if (result && result[0] && result[0].nm) {
    return result[0].id
  } else return undefined
}
export const BUTTON_STATES = { hovered: 'hovered', focused: 'focused', active: 'active', loading: 'loading' }

export const AddEditDistributor = () => {
  const alert = useAlert()
  // productTenants
  const [distributors, setDistributors] = useState()
  // productGroups
  const [primaryContacts, setPrimaryContacts] = useState()
  const [isUploadedBlockedClicked, setIsUploadedBlockedClicked] = useState(false)
  // Error handling
  const confirmAction = useConfirmActionMessage()
  // Button request loading
  const [buttonState, setButtonState] = useState(BUTTON_STATES.active)
  const { t } = useTranslation()
  const history = useHistory()
  const { id } = useParams()
  const { register, handleSubmit, errors, watch } = useForm()

  // componentDidMount
  useEffect(() => {
    function loadCitiesAndProvinces ({ defaultProvinceName, defaultCityName, defaultProvinceId = -1, defaultCityId = -1 }) {
      const prov = document.querySelector("select[pselect='prov']")
      const mun = document.querySelector("select[pselect='mun']")

      new Pselect({ provText: defaultProvinceName, munText: defaultCityName }).create(prov, mun, defaultProvinceId, defaultCityId)
    }

    function getDistributorInfo () {
      if (id) {
        logic
          .getOneDistributorTenant({ distributorTenantId: id.toString() })
          .then(distributorTenant => {
            // set the provinces and city
            setDistributors(distributorTenant && distributorTenant.data && distributorTenant.data[0])
            setIsUploadedBlockedClicked(distributorTenant && distributorTenant.data && distributorTenant.data[0].isuploadblocked)
            loadCitiesAndProvinces({
              defaultProvinceName: distributorTenant && distributorTenant.data && distributorTenant.data[0].province,
              defaultCityName: distributorTenant && distributorTenant.data && distributorTenant.data[0].city,
              defaultProvinceId: findProvinceName(distributorTenant && distributorTenant.data && distributorTenant.data[0].province),
              defaultCityId: findCityName(distributorTenant && distributorTenant.data && distributorTenant.data[0].city)
            })
            const e = new CustomEvent('change', { detail: distributorTenant && distributorTenant.data && distributorTenant.data[0].province })
            const element = document.querySelector("select[pselect='prov']")
            element.dispatchEvent(e)
          })
          .catch(err => {
            alert.show(err.message, {
              type: 'error'
            })
          })
      } else {
        loadCitiesAndProvinces({})
      }
    }
    getDistributorInfo()
  }, [])

  useEffect(() => {
    function getPrimaryContacts () {
        if (id) {
          logic
            .getUserDistributors({ distributorId: id })
            .then(users => {
              const sortedUsers =
                users &&
                users.data &&
                users.data.sort((a, b) => {
                  if (a.username < b.username) {
                    return -1
                  }
                  if (a.username > b.username) {
                    return 1
                  }
                  return 0
                })
                setPrimaryContacts(sortedUsers)
            })
        }
    }
    getPrimaryContacts()
  }, [])

  const handleGoBackClick = () => {
    history.push('/distributor')
  }

  const onSubmitData = data => {
    setButtonState(BUTTON_STATES.loading)
    const { distributorCode, distributorIdentity, distributorName, postalCode, phone, alternatePhone, address, primaryContactId, salesStock, isUploadBlocked, dateBlocked, description } = data
    // we destructure as let because we wil be passing the city and province name, not id
    let { city, province } = data
    province = findProvinceName(province)
    city = findCityName(city)

    const notifySalesStock = salesStock
    confirmAction.closeMessage()
    if (id) {
      logic
        .updateDistributorTenant({
          distributorTenantId: id.toString(),
          distributorCode,
          distributorIdentity,
          distributorName,
          postalCode,
          phone,
          alternatePhone,
          province,
          city,
          address,
          primaryContactId,
          salesStock: notifySalesStock,
          isUploadBlocked,
          toMonthBlocked: new Date(dateBlocked),
          description
         })
        .then(product => {
          if (product.status !== 200) {
            throw Error(`${product.status}`)
          }
          alert.show(t('updateSuccesful'), {
            type: 'success'
          })
        })
        .catch(err => {
          if (err.message === '409') {
            alert.show(t('Code/NIF already exists'), {
              type: 'error'
            })
          } else {
            alert.show(t('Unexpected Error'), {
              type: 'error'
            })
          }
        })
    } else {
      logic
        .insertOneDistributorTenant({
          distributorCode,
          distributorIdentity,
          distributorName,
          postalCode,
          phone,
          alternatePhone,
          province,
          city,
          address,
          primaryContactId,
          salesStock: notifySalesStock,
          isUploadBlocked,
          toMonthBlocked: new Date(dateBlocked),
          description
        })
        .then(product => {
          if (product.status !== 200) {
            throw Error(product.status)
          }
          alert.show(t('distributorCreated'), {
            type: 'success'
          })
          history.push({
            pathname: '/distributor'
          })
        })
        .catch(err => {
          if (err.message === '409') {
            alert.show('Input already exists', {
              type: 'error'
            })
          } else {
            alert.show('Unexpected Error', {
              type: 'error'
            })
          }
        })
    }
    setButtonState(BUTTON_STATES.active)
  }

  return (
    <Box>
      <TabsDistributor highlight='distributor' />
       <Button onClick={e => handleGoBackClick()} color='light' size='medium'>
        <Icon>
          <FontAwesomeIcon size='lg' icon={['fas', 'chevron-circle-left']} />
        </Icon>
       </Button>
      <Title>
        {id
          ? `${t('edit')} ${distributors && distributors.distributorname}`
          : `${t('add')} ${t('distributor')}`}
      </Title>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <Field>
            <Label>{t('CIF/NIF')}</Label>
            <Control iconLeft>
              <Input
                defaultValue={distributors && distributors.distributoridentity}
                type='text'
                color='light'
                name='distributorIdentity'
                ref={register({
                  required: true,
                  validate:  value => validateSpanishId(value),
                  maxLength: 9
                })}
              />
              <Icon size='small' align='left'>
                <FontAwesomeIcon icon={['fas', 'archive']} />
              </Icon>
            </Control>
            {errors.distributorIdentity &&
              errors.distributorIdentity.type === 'required' && (
                <HelpError message={t('required')} />
              )}
            {errors.distributorIdentity &&
              errors.distributorIdentity.type === 'maxLength' && (
                <HelpError message={t('tooManyCharacters')} />
              )}
              {errors.distributorIdentity &&
              errors.distributorIdentity.type === 'validate' && (
                <HelpError message={t('validSpanishIdPlease')} />
              )}
        </Field>
        <Field>
          <Label>{t('code')}</Label>
          <Control iconLeft>
            <Input
              defaultValue={distributors && distributors.distributorcode}
              type='text'
              color='light'
              name='distributorCode'
              ref={register({
                required: true,
                maxLength: 50,
                type: 'number'
              })}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'archive']} />
            </Icon>
          </Control>
          {errors.code &&
            errors.code.type === 'required' && (
              <HelpError message={t('required')} />
            )}
          {errors.code &&
            errors.code.type === 'maxLength' && (
              <HelpError message={t('tooManyCharacters')} />
            )}
        </Field>

        <Field>
          <Label>{t('name')}</Label>

          <Control iconLeft iconRight>
            <Input
              defaultValue={distributors && distributors.distributorname}
              type='type'
              color='light'
              name='distributorName'
              ref={register({
                maxLength: 1000,
                required: true
              })}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {errors.productName && errors.productName.type === 'maxLength' && (
            <HelpError message={t('tooManyCharacters')} />
          )}
          {errors.productName &&
            errors.productName.type === 'required' && (
              <HelpError message={t('required')} />
            )}
        </Field>
        <Field>
          <Label>{t('Province')}</Label>
          <Control expanded>
            <Select.Container color='light' fullwidth>
              <Select
                pselect='prov'
                name='province'
                ref={register({
                  required: true
                })}
              />

            </Select.Container>
            {errors.brand &&
            errors.brand.type === 'required' && (
              <HelpError message={t('required')} />
            )}
          </Control>
        </Field>

        <Field>
          <Label>{t('City')}</Label>
          <Control expanded>
            <Select.Container color='light' fullwidth>
              <Select
                pselect='mun'
                name='city'
                ref={register({ required: true })}
              >
              </Select>
            </Select.Container>
            {errors.businessUnit &&
            errors.businessUnit.type === 'required' && (
              <HelpError message={t('required')} />
            )}
          </Control>
        </Field>

        <Field>
          <Label>{t('postalCode')}</Label>

          <Control iconLeft iconRight>
            <Input
              defaultValue={distributors && distributors.postalcode}
              type='type'
              color='light'
              name='postalCode'
              ref={register({
                maxLength: 5,
                required: true
              })}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {errors.postalCode && errors.postalCode.type === 'maxLength' && (
            <HelpError message={t('tooManyCharacters')} />
          )}
          {errors.postalCode &&
            errors.postalCode.type === 'required' && (
              <HelpError message={t('required')} />
            )}
        </Field>
        <Field>
          <Label>{t('address')}</Label>

          <Control iconLeft iconRight>
            <Input
              defaultValue={distributors && distributors.address}
              type='type'
              color='light'
              name='address'
              ref={register({
                maxLength: 100
              })}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {errors.address && errors.address.type === 'maxLength' && (
            <HelpError message={t('tooManyCharacters')} />
          )}
          {errors.address &&
            errors.address.type === 'required' && (
              <HelpError message={t('required')} />
            )}
        </Field>

        <Field>
          <Label>{t('phone')}</Label>

          <Control iconLeft iconRight>
            <Input
              defaultValue={distributors && distributors.phone}
              type='type'
              color='light'
              name='phone'
              ref={register({
                maxLength: 100
              })}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {errors.phone && errors.phone.type === 'maxLength' && (
            <HelpError message={t('tooManyCharacters')} />
          )}
          {errors.phone &&
            errors.phone.type === 'required' && (
              <HelpError message={t('required')} />
            )}
        </Field>

        <Field>
          <Label>{t('alternatePhone')}</Label>

          <Control iconLeft iconRight>
            <Input
              defaultValue={distributors && distributors.alternativephone}
              type='type'
              color='light'
              name='alternatePhone'
              ref={register({
                maxLength: 100
              })}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {errors.alternatePhone && errors.alternatePhone.type === 'maxLength' && (
            <HelpError message={t('tooManyCharacters')} />
          )}
          {errors.alternatePhone &&
            errors.alternatePhone.type === 'required' && (
              <HelpError message={t('required')} />
            )}
        </Field>
        {id && <Field>
          <Label>{t('primaryContact')}</Label>
          <Control expanded>
            <Select.Container color='light' fullwidth>
              <Select
                name='primaryContact'
                value={watch('primaryContact') || (distributors && distributors.primarycontactid)}
                ref={register({
                })}
              >
                <Select.Option value=''>{t('select')}</Select.Option>
                {primaryContacts &&
                  primaryContacts.map((contact, index) => (
                    <Select.Option
                      key={index}
                      value={contact && contact.userid}
                    >
                      {contact && contact.username} ({contact && contact.useremail})
                    </Select.Option>
                  ))}
              </Select>
            </Select.Container>
            {errors.primaryContact &&
            errors.primaryContact.type === 'required' && (
              <HelpError message={t('required')} />
            )}
          </Control>
        </Field>}
        <Level>
          <Level.Item align="left">
            <Field>
              <Label>
                <Checkbox
                  type='checkbox'
                  color='light'
                  name='isUploadBlocked'
                  ref={register}
                  onClick={(event) => setIsUploadedBlockedClicked(!isUploadedBlockedClicked)}
                  defaultChecked={distributors && distributors.isuploadblocked}
                />
                    <strong style={{ margin: '5px' }}>
                    {t('isUploadBlocked')}
                    </strong>
              </Label>
            </Field>
          </Level.Item>
        </Level>
        {isUploadedBlockedClicked ?
          <Level>
            <Level.Item align="left">
              <Field>
                <Label>{t('BlockTo')}</Label>
                <Control  >
                  <Input
                    defaultValue={distributors && distributors.tomonthblocked !== null ? `${new Date(distributors.tomonthblocked).getFullYear()}-${(new Date(distributors.tomonthblocked).getMonth()+1).toString().padStart(2, '0')}` : null}
                    max={MAXMONTHTOBLOCK[new Date().getMonth()]}
                    type='month'
                    color='light'
                    name='dateBlocked'
                    tooltip={t('infoBlocked')}
                    tooltipMultiline
                    tooltipPosition="right"
                    tooltipColor="info"
                    ref={register}
                  />
                </Control>
              </Field>
            </Level.Item>
          </Level>
        :
          null
        }
        <Field>
          <Label>
            <Checkbox
              type='checkbox'
              color='light'
              name='salesStock'
              ref={register}
              defaultChecked={watch('salesStock') || (distributors && distributors.salesstock)}
            />
                <strong style={{ margin: '5px' }}>
                {t('notifyIfRegistryNotSent')}

                </strong>
          </Label>
        </Field>

        <Field>
          <Label>{t('description')}</Label>
          <Control>
            <Textarea
              color='light'
              name='description'
              defaultValue={distributors && distributors.description}
              ref={register({
                maxLength: 1000
                         })}
              placeholder={t('description') || (distributors && distributors.description)}
            />
          </Control>
          {errors.description && errors.description.type === 'maxLength' && (
            <HelpError message={t('tooManyCharacters')} />
          )}
        </Field>

        <Field kind='group'>
          <Control>
            {id ? (
              <Button state={buttonState} color='link'>{t('update')}</Button>
            ) : (
              <Button state={buttonState} color='link'>{t('add')}</Button>
            )}
          </Control>
          <Control>
            <Button as={Link} to='/distributor' type='button' text>
              {t('cancel')}
            </Button>
          </Control>
          {confirmAction.showMessage && <FormError {...confirmAction} />}
        </Field>
      </form>
    </Box>
  )
}
