import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { logic } from '../../../logic'
import { Select, Label, Card, Content, Level } from 'rbx'
import { LocaleUtils } from 'react-day-picker'

const defaultDay = 1
const defaultHour = 12
const currentYear = new Date().getFullYear()
const fromMonth = new Date(2020, 0)
const toMonth = new Date(currentYear, 11)

function YearMonthForm ({ date, localeUtils, onChange }) {
  const { t } = useTranslation()

  const [dateState, setDateState] = useState(new Date().getMonth() > 0 ? 
                                             new Date(new Date().getFullYear(), new Date().getMonth() - 1, defaultDay, defaultHour) : 
                                             new Date(new Date().getFullYear() - 1, 11, defaultDay, defaultHour))

  const months = localeUtils.getMonths()

  const years = []
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i)
  }

  const handleChange = function handleChange (e) {
    const { year, month } = e.target.form
    if ((year && year.value) && (month && month.value)) {
      setDateState(new Date(year.value, month.value, defaultDay, defaultHour))
      onChange(new Date(year.value, month.value, defaultDay, defaultHour))
    }
  }
  return (
    <form className='DayPicker-Caption'>
      <Select.Container>
        <Select name='month' onChange={handleChange} defaultValue='' value={dateState && dateState.getMonth()}>
          <Select.Option value=''>{t('Select month')}</Select.Option>
          {months.map((month, index) => {
            return (
              <Select.Option key={month} value={index}>
                {t(month)}
              </Select.Option>
            )
          })}
        </Select>
      </Select.Container>
      <Select.Container>
        <Select name='year' onChange={handleChange} defaultValue='' value={dateState && dateState.getFullYear()}>
          <Select.Option value=''>
            {t('select')} {t('year')}
          </Select.Option>
          {years.map((year, index) => {
            return (
              <Select.Option key={year} value={year}>
                {year}
              </Select.Option>
            )
          })}
        </Select>
      </Select.Container>
    </form>
  )
}

export const MonthPicker = props => {
  const [tenantsFromDistributor, setTenantsFromDistributor] = useState()

  const [monthYear, setYearMonth] = useState(new Date().getMonth() > 0 ? 
                                             new Date(`${new Date().getFullYear()}-${(new Date().getMonth()).toString().padStart(2, '0')}-01`) : 
                                             new Date(`${new Date().getFullYear() - 1}-12-01`))

  const [currentTenant, setCurrentTenant] = useState()

  const { t } = useTranslation()

  useEffect(() => {
    logic
      .getTenantsFromDistributorAdmin(props && props.distributorId)
      .then(tenants => {
        setTenantsFromDistributor(tenants && tenants.data)
        if (tenants && tenants.data.length === 1) setCurrentTenant(tenants.data[0].tenantid.toString())
      })
      .catch(err => {
        console.error(err.message)
      })
  }, [props.distributorId])

  // If both date and tenant are value, send the data do parent component
  useEffect(() => {
    // Look for currentTenant within 
    const tenantSelected = tenantsFromDistributor && tenantsFromDistributor.find( tenant => tenant.tenantid == currentTenant)
    props.doYearMonthPick({ date: monthYear, tenantId: currentTenant, tenantName: tenantSelected && tenantSelected.tenantname, tenantIdentity: tenantSelected && tenantSelected.tenantidentity, isUploadBlocked: tenantSelected && tenantSelected.isuploadblocked, toMonthBlocked: tenantSelected && tenantSelected.tomonthblocked })
  }, [currentTenant, monthYear])

  const handleYearMonthChange = value => {
    setYearMonth(value)
    if (currentTenant) {
      props.doYearMonthPick({ date: value, tenantId: currentTenant })
    }
  }

  const handleTenantChange = event => {
    setCurrentTenant(event.target.value)
    if (monthYear) {
      props.doYearMonthPick({ date: monthYear, tenantId: event.target.value })
    }
  }

  return (
    <>
      <Card>
        <Card.Content>
          <Level>
            <Level.Item>
              <Content>
                <Label>{t('provider')}:</Label>
                <Select.Container>
                  <Select
                    name='tenants'
                    onChange={handleTenantChange}
                    value={currentTenant}
                  >
                    <Select.Option value=''>
                      {t('choose')} {t('provider')}
                    </Select.Option>
                    {tenantsFromDistributor &&
                      tenantsFromDistributor.map((tenant, index) => {
                        return (
                          <Select.Option
                            key={index}
                            value={tenant && tenant.tenantid}
                          >
                            {tenant && tenant.tenantname}
                          </Select.Option>
                        )
                      })}
                  </Select>
                </Select.Container>
              </Content>
            </Level.Item>
            <Level.Item>
              <Content>
                <Label>{t('uploadDate')}:</Label>
                <YearMonthForm
                  date={monthYear}
                  localeUtils={LocaleUtils}
                  onChange={handleYearMonthChange}
                />
              </Content>
            </Level.Item>
          </Level>
        </Card.Content>
      </Card>
    </>
  )
}