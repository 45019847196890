/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState, useContext } from 'react'
import { useAlert } from 'react-alert'
import {
  Select,
  Textarea,
  Box,
  Title,
  Field,
  Input,
  Control,
  Label,
  Button,
  Icon
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FormError, HelpError } from '../../error'
import { logic } from '../../../logic'
import { useConfirmActionMessage } from '../../../logic/hooks'
import { AdsContext } from "../../../contexts/adsContext"

export const BUTTON_STATES = { hovered: 'hovered', focused: 'focused', active: 'active', loading: 'loading' }
export const AddEditDashboardsAdmin = () => {
  // productTenant
  const alert = useAlert()
  const [options, setOptions] = useState()
  const [dashboardDistributor, setDashboardDistributor] = useState()
  // Error handling
  const confirmAction = useConfirmActionMessage()
  // Button request loading
  const [buttonState, setButtonState] = useState(BUTTON_STATES.active)

  const { t } = useTranslation()
  const history = useHistory()
  const { dashboardId } = useParams()
  const { register, handleSubmit, errors, watch } = useForm()
  const { allAdminDashboards } = useContext(AdsContext)
  const [originalName, setOriginalName] = useState()

  // componentDidMount
  useEffect(() => {
    function getDashboardInfo () {
      logic
        .getAllDistributors()
        .then(distributors => {
          setOptions(distributors && distributors.data && distributors.data.map(distributor => {
              const {distributorid: distributorid, distributorname: distributorname, distributoridentity: distributoridentity} = distributor

              return {
                      label: `${distributoridentity} ·${distributorname}`,
                      value: distributorid
                  }        
          }))
        })
        .catch(err => {
          console.error(err.message)
        })
      if (dashboardId) {
        logic
          .getOneDashboardAdmin({ dashboardId: dashboardId.toString() })
          .then(dashboardDistributor => {
            setDashboardDistributor(dashboardDistributor && dashboardDistributor.data && {...dashboardDistributor.data[0]})
            if (dashboardDistributor && dashboardDistributor.data) {
              setOriginalName(dashboardDistributor.data[0].dashboardname)
            }
  
          })
          .catch(err => {
            alert.show(err.message, {
              type: 'error'
            })
          })
      } 
    }
    getDashboardInfo()
  }, [])

  const handleGoBackClick = () => {
    history.push('/settings/dashboards')
  }

  const onSubmitData = data => {
    setButtonState(BUTTON_STATES.loading)

    const { distributorId, dashboardName, dashboardUrl, dashboardDescription } = data
    const existsName = allAdminDashboards.filter(dashboard => { if (dashboard.dashboardname === dashboardName) return dashboard })
    if (existsName && existsName.length > 0 && dashboardName !== originalName) {
      alert.show(t(`The dashboard's name already exists`), {
        type: 'error'
      })
      return
    }
    confirmAction.closeMessage()
    if (dashboardId) {
      logic
        .updateDashboardAdmin({
          dashboardId: dashboardId,
          distributorId,
          dashboardName,
          dashboardUrl,
          dashboardDescription
        })
        .then(dashboard => {
          if (dashboard.status !== 200) {
            alert.show(dashboard.error, {
              type: 'error'
            })
          } else {
            alert.show(t('dashboard_update'), {
              type: 'success'
            })
            history.push({
              pathname: '/settings/dashboards'
            })
          }
        })
        .catch(err => {
          if (err.message === '409') {
            alert.show(t('Input already exists'), {
              type: 'error'
            })
          } else {
            alert.show(t('Unexpected Error'), {
              type: 'error'
            })
          }
        })
    } else {
      logic
        .addDashboardAdmin({
          distributorId: distributorId,
          dashboardName,
          dashboardUrl,
          dashboardDescription
        })
        .then(dashboard => {
          if (dashboard.status !== 201) {
            alert.show(dashboard.error, {
              type: 'error'
            })
          } else {
            alert.show(t('dashboard_added'), {
              type: 'success'
            })
            history.push({
              pathname: '/settings/dashboards'
            })  
          }
        })
        .catch(err => {
          console.log("err: ", err)
          if (err.message === '409') {
            alert.show(t('Input already exists'), {
              type: 'error'
            })
          } else {
            alert.show(t('Unexpected error'), {
              type: 'error'
            })
          }
        })
    }
    setButtonState(BUTTON_STATES.active)
  }

  return (
    <Box>
      <Button onClick={e => handleGoBackClick()} color='light' size='medium'>
        <Icon>
          <FontAwesomeIcon size='lg' icon={['fas', 'chevron-circle-left']} />
        </Icon>
      </Button>
      <Title>
        {dashboardId
          ? `${t('edit')} ${dashboardDistributor && dashboardDistributor.dashboardname}`
          : `${t('add')} ${t('dashboard')}`}
      </Title>
      <form onSubmit={handleSubmit(onSubmitData)}>
        
        <Field>
          <Label>{t('selectDistributors')}</Label>
          <Control expanded>
            <Select.Container color='light' fullwidth>
              <Select
                name='distributorId'
                value={watch('distributor_name') || undefined}
                ref={register({
                  required: true
                })}
              >
                <Select.Option value=''>{t('select')}</Select.Option>
                  {options &&
                    options.map((option, index) => {
                      let parsedDistributorId = 0
                      parsedDistributorId = dashboardDistributor && parseInt(dashboardDistributor.distributorid)
                      
                      return (
                        <Select.Option
                          key={index}
                          value={option && `${option.value}`}
                          selected={ option && option.value === parsedDistributorId ? 'selected' : null}
                        >
                          {option && option.label}
                        </Select.Option>
                      )
                    }
                  )}
              </Select>
            </Select.Container>
            {errors.distributorId &&
              errors.distributorId.type === 'required' && (
                <HelpError message={t('required')} />
              )}
          </Control>
        </Field>

        <Field>
          <Label>{t('dashboard name')}</Label>
          <Control iconLeft>
            <Input
              defaultValue={dashboardDistributor && dashboardDistributor.dashboardname}
              type='text'
              color='light'
              name='dashboardName'
              ref={register({
                maxLength: 15
              })}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {errors.dashboardName &&
            errors.dashboardName.type === 'maxLength' && (
              <HelpError message={t('tooManyCharacters')} />
            )}
        </Field>

        <Field>
          <Label>{t('dashboard url')}</Label>
          <Control iconLeft>
            <Input
              defaultValue={dashboardDistributor && dashboardDistributor.dashboardurl}
              type='text'
              color='light'
              name='dashboardUrl'
              ref={register({
                maxLength: 2048,
                required: true
              })}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={faLink} />
            </Icon>
          </Control>
          {errors.dashboardUrl &&
            errors.dashboardUrl.type === 'required' && (
              <HelpError message={t('required')} />
            )}
          {errors.dashboardUrl &&
            errors.dashboardUrl.type === 'maxLength' && (
              <HelpError message={t('tooManyCharacters')} />
            )}
        </Field>

        <Field>
          <Label>{t('dashboard description')}</Label>
          <Control iconLeft>
            <Textarea
              defaultValue={dashboardDistributor && dashboardDistributor.description}
              type='text'
              color='light'
              name='dashboardDescription'
              ref={register({
                maxLength: 1000
              })}
            />
          </Control>
          {errors.dashboardDescription &&
            errors.dashboardDescription.type === 'maxLength' && (
              <HelpError message={t('tooManyCharacters')} />
            )}
        </Field>
        <Field kind='group'>
          <Control>
            {dashboardId ? (
              <Button color='link'>{t('update')}</Button>
            ) : (
                <Button color='link'>{t('add')}</Button>
              )}
          </Control>
          <Control>
            <Button as={Link} to='/settings/dashboards' type='button' text>
              {t('cancel')}
            </Button>
          </Control>
          {confirmAction.showMessage && <FormError {...confirmAction} />}
        </Field>
      </form>
    </Box>
  )
}
