/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import {
  Select,
  Textarea,
  Box,
  Title,
  Field,
  Input,
  Control,
  Label,
  Button,
  Icon,
  Checkbox
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FormError, HelpError } from '../../error'
import { logic } from '../../../logic'
import { useConfirmActionMessage } from '../../../logic/hooks'
import Pselect from '../../../logic/pselect.js/src'
import { validateSpanishId } from 'spain-id'

function getProvinceIdByName(provinceName){
  
  const result = Pselect.provincesData.filter(province => {
    return province.nm.toString() === provinceName && provinceName.toString()
  })
  if (result && result[0] && result[0].nm) {
    return result[0].id
  } else return undefined
}

function getCityIdByName(cityName){
  
  const result = Pselect.municipesData.filter(city => {
    return city.nm.toString() === cityName && cityName.toString()
  })
  if (result && result[0] && result[0].nm) {
    return result[0].id
  } else return undefined
}
function findProvinceNameByName (provinceName) {
  const result = Pselect.provincesData.filter(province => {
    return province.nm.toString() === provinceName && provinceName.toString()
  })
  if (result && result[0] && result[0].nm) {
    return result[0].nm
  } else return undefined
}


function findProvinceName (provinceId) {
  const result = Pselect.provincesData.filter(province => {
    return province.id.toString() === provinceId && provinceId.toString()
  })
  if (result && result[0] && result[0].nm) {
    return result[0].nm
  } else return undefined
}

function findCityName (cityId) {
  const result = Pselect.municipesData.filter(city => {
    return city.id.toString() === cityId && cityId.toString()
  })
  if (result && result[0] && result[0].nm) {
    return result[0].nm
  } else return undefined
}

function findCityNameByName (cityName) {
  const result = Pselect.municipesData.filter(city => {
    return city.nm.toString() === cityName && cityName.toString()
  })
  if (result && result[0] && result[0].nm) {
    return result[0].nm
  } else return undefined
}
export const BUTTON_STATES = { hovered: 'hovered', focused: 'focused', active: 'active', loading: 'loading' }
export const AddEditTenantAdmin = () => {
  // productTenant
  const alert = useAlert()
  const [tenant, setTenant] = useState()
  const [distributor, setDistributor] = useState()
  // Error handling
  const confirmAction = useConfirmActionMessage()
  // Button request loading
  const [buttonState, setButtonState] = useState(BUTTON_STATES.active)

  const { t } = useTranslation()
  const history = useHistory()
  const { tenantId, tenantIdentity } = useParams()
  const { register, handleSubmit, errors, watch } = useForm()
  const { url } = useRouteMatch()

  // componentDidMount
  useEffect(() => {
    function loadCitiesAndProvinces ({ defaultProvinceName, defaultCityName, defaultProvinceId = -1, defaultCityId = -1 }) {
      const prov = document.querySelector("select[pselect='prov']")
      const mun = document.querySelector("select[pselect='mun']")

      new Pselect({ provText: defaultProvinceName, munText: defaultCityName }).create(prov, mun, defaultProvinceId, defaultCityId)
    }

    function getTenantInfo () {
      
      if (tenantId) {
        logic
          .getOneDistributorTenantAdmin({ tenantIdentity: tenantIdentity.toString(), tenantId: tenantId.toString() })
          .then(distributorTenant => {
            
            // set the provinces and city
            loadCitiesAndProvinces({
              defaultProvinceName: findProvinceNameByName(distributorTenant && distributorTenant.data && distributorTenant.data[0].province),
              defaultCityName: findCityNameByName(distributorTenant && distributorTenant.data && distributorTenant.data[0].city),
              defaultProvinceId: getProvinceIdByName(distributorTenant && distributorTenant.data && distributorTenant.data[0].province),
              defaultCityId: getCityIdByName(distributorTenant && distributorTenant.data && distributorTenant.data[0].city)
            })
            
            const detailProvince = getProvinceIdByName(distributorTenant && distributorTenant.data && distributorTenant.data[0].province)
            const e = new CustomEvent('change', { detail: detailProvince })
            const element = document.querySelector("select[pselect='prov']")
            element.dispatchEvent(e)
            const detailCity = getCityIdByName(distributorTenant && distributorTenant.data && distributorTenant.data[0].city)
            const ee = new CustomEvent('change', { detail: detailCity })
            const eelement = document.querySelector("select[pselect='mun']")
            eelement.dispatchEvent(ee)
            console.log(getCityIdByName(distributorTenant && distributorTenant.data && distributorTenant.data[0].city))
            setDistributor(distributorTenant && distributorTenant.data && {...distributorTenant.data[0], city: getCityIdByName(distributorTenant && distributorTenant.data && distributorTenant.data[0].city)})
          })
          .catch(err => {
            alert.show(err.message, {
              type: 'error'
            })
          })
      } else {
        loadCitiesAndProvinces({})
      }
    }
    getTenantInfo()
  }, [])

  const handleGoBackClick = () => {
    history.push('/tenants')
  }

  const onSubmitData = data => {
    setButtonState(BUTTON_STATES.loading)

    const { distributorCode, distributorIdentity, distributorName, postalCode, phone, alternatePhone, address, primaryContactId, salesStock, description } = data
    // we destructure as let because we wil be passing the city and province name, not id
    let { city, province } = data
  
    if (!city || city === 'undefined') city = distributor && distributor.city
    province = findProvinceName(province)
    city = findCityName(city)
    confirmAction.closeMessage()
    if (tenantId) {
      logic
        .updateTenantAdmin({
          tenantId: tenantId.toString(),
          distributorIdentity: distributorIdentity && distributorIdentity.toString(),
          distributorName: distributorName && distributorName.toString(),
          distributorCode: distributorCode,
          province: province && province.toString(),
          city: city && city.toString(),
          postalCode: postalCode && postalCode.toString(),
          address: address && address.toString(),
          phone: phone && phone.toString(),
          alternatePhone: alternatePhone && alternatePhone.toString(),
          description: description && description.toString()
        })
        .then(tenant => {
          if (tenant.status !== 200) {
            alert.show(tenant.error, {
              type: 'error'
            })
          } else {
            alert.show('proveedor actualizado correctamente', {
              type: 'success'
            })
            history.push({
              pathname: '/tenants'
            })
          }
        })
        .catch(err => {
          if (err.message === '409') {
            alert.show(t('Input already exists'), {
              type: 'error'
            })
          } else {
            alert.show(t('Unexpected Error'), {
              type: 'error'
            })
          }
        })
    } else {
      logic
        .addTenantAdmin({
          distributorIdentity: distributorIdentity && distributorIdentity.toString(),
          distributorName: distributorName && distributorName.toString(),
          distributorCode: distributorCode,
          province: province && province.toString(),
          city: city && city.toString(),
          postalCode: postalCode && postalCode.toString(),
          address: address && address.toString(),
          phone: phone && phone.toString(),
          alternatePhone: alternatePhone && alternatePhone.toString(),
          description: description && description.toString()
        })
        .then(tenant => {
          if (tenant.status !== 201) {
            console.log("tenant: ", tenant)
            alert.show(tenant.error, {
              type: 'error'
            })
          } else {
            alert.show('proveedor añadido correctamente', {
              type: 'success'
            })
            history.push({
              pathname: '/tenants'
            })  
          }
        })
        .catch(err => {
          console.log("err: ", err)
          if (err.message === '409') {
            alert.show(t('Input already exists'), {
              type: 'error'
            })
          } else {
            alert.show(t('Unexpected error'), {
              type: 'error'
            })
          }
        })
    }
    setButtonState(BUTTON_STATES.active)
  }

  return (
    <Box>
      <Button onClick={e => handleGoBackClick()} color='light' size='medium'>
        <Icon>
          <FontAwesomeIcon size='lg' icon={['fas', 'chevron-circle-left']} />
        </Icon>
      </Button>
      <Title>
        {tenantId
          ? `${t('edit')} ${distributor && distributor.distributorname}`
          : `${t('add')} ${t('provider')}`}
      </Title>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <Field>
          <Label>{t('CIF/NIF')}</Label>
          <Control iconLeft>
            <Input
              defaultValue={distributor && distributor.distributoridentity}
              type='text'
              color='light'
              name='distributorIdentity'
              ref={register({
                required: true,
                maxLength: 9,
                validate: value => validateSpanishId(value)
              })}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'archive']} />
            </Icon>
          </Control>
          {errors.distributorIdentity &&
            errors.distributorIdentity.type === 'required' && (
              <HelpError message={t('required')} />
            )}
          {errors.distributorIdentity &&
            errors.distributorIdentity.type === 'maxLength' && (
              <HelpError message={t('tooManyCharacters')} />
            )}
            {errors.distributorIdentity &&
            errors.distributorIdentity.type === 'validate' && (
              <HelpError message={t('validSpanishIdPlease')} />
            )}
        </Field>
        <Field>
          <Label>{t('name')}</Label>
          <Control iconLeft iconRight>
            <Input
              defaultValue={distributor && distributor.distributorname}
              type='type'
              color='light'
              name='distributorName'
              ref={register({
                maxLength: 1000,
                required: true
              })}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {errors.distributorName && errors.distributorName.type === 'maxLength' && (
            <HelpError message={t('tooManyCharacters')} />
          )}
          {errors.distributorName &&
            errors.distributorName.type === 'required' && (
              <HelpError message={t('required')} />
            )}
        </Field>
        <Field>
          <Label>{t('code')}</Label>
          <Control iconLeft>
            <Input
              defaultValue={distributor && distributor.distributorcode}
              type='text'
              color='light'
              name='distributorCode'
              ref={register({
                required: true,
                maxLength: 50,
                type: 'number'
              })}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'archive']} />
            </Icon>
          </Control>
          {errors.code &&
            errors.code.type === 'required' && (
              <HelpError message={t('required')} />
            )}
          {errors.code &&
            errors.code.type === 'maxLength' && (
              <HelpError message={t('tooManyCharacters')} />
            )}
        </Field>

        <Field>
          <Label>{t('province')}</Label>
          <Control expanded>
            <Select.Container color='light' fullwidth>
              <Select
                pselect='prov'
                name='province'
                value={watch('province') || (distributor && distributor.province)}
                ref={register({
                  required: true
                })}
              />

            </Select.Container>
            {errors.brand &&
              errors.brand.type === 'required' && (
                <HelpError message={t('required')} />
              )}
          </Control>
        </Field>

        <Field>
          <Label>{t('city')}</Label>
          <Control expanded>
            <Select.Container color='light' fullwidth>
              <Select
                pselect='mun'
                name='city'
                defaultValue={distributor && distributor.city}
                ref={register({ required: true })}
              />
            </Select.Container>
            {errors.businessUnit &&
              errors.businessUnit.type === 'required' && (
                <HelpError message={t('required')} />
              )}
          </Control>
        </Field>

        <Field>
          <Label>{t('postalCode')}</Label>

          <Control iconLeft iconRight>
            <Input
              defaultValue={distributor && distributor.postalcode}
              type='type'
              color='light'
              name='postalCode'
              ref={register({
                maxLength: 5,
                required: true
              })}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {errors.postalCode && errors.postalCode.type === 'maxLength' && (
            <HelpError message={t('tooManyCharacters')} />
          )}
          {errors.postalCode &&
            errors.postalCode.type === 'required' && (
              <HelpError message={t('required')} />
            )}
        </Field>
        <Field>
          <Label>{t('address')}</Label>

          <Control iconLeft iconRight>
            <Input
              defaultValue={distributor && distributor.address}
              type='type'
              color='light'
              name='address'
              ref={register({
                maxLength: 100,
                required: true
              })}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {errors.address && errors.address.type === 'maxLength' && (
            <HelpError message={t('tooManyCharacters')} />
          )}
          {errors.address &&
            errors.address.type === 'required' && (
              <HelpError message={t('required')} />
            )}
        </Field>

        <Field>
          <Label>{t('phone')}</Label>

          <Control iconLeft iconRight>
            <Input
              defaultValue={distributor && distributor.phone}
              type='type'
              color='light'
              name='phone'
              ref={register({
                maxLength: 100,
                required: true
              })}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {errors.phone && errors.phone.type === 'maxLength' && (
            <HelpError message={t('tooManyCharacters')} />
          )}
          {errors.phone &&
            errors.phone.type === 'required' && (
              <HelpError message={t('required')} />
            )}
        </Field>

        <Field>
          <Label>{t('alternatePhone')}</Label>

          <Control iconLeft iconRight>
            <Input
              defaultValue={distributor && distributor.alternativephone}
              type='type'
              color='light'
              name='alternatePhone'
              ref={register({
                maxLength: 100,
                required: true
              })}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {errors.alternatePhone && errors.alternatePhone.type === 'maxLength' && (
            <HelpError message={t('tooManyCharacters')} />
          )}
          {errors.alternatePhone &&
            errors.alternatePhone.type === 'required' && (
              <HelpError message={t('required')} />
            )}
        </Field>
        <Field>
          <Label>{t('description')}</Label>
          <Control>
            <Textarea
              color='light'
              name='description'
              ref={register({
                maxLength: 100,
                required: true
              })}
              defaultValue={distributor && distributor.description}
            />
          </Control>
          {errors.description && errors.description.type === 'maxLength' && (
            <HelpError message={t('tooManyCharacters')} />
          )}
          {errors.description && errors.description.type === 'required' && (
            <HelpError message={t('required')} />
          )}
        </Field>

        <Field kind='group'>
          <Control>
            {tenantId ? (
              <Button state={buttonState} color='link'>{t('update')}</Button>
            ) : (
                <Button state={buttonState} color='link'>{t('add')}</Button>
              )}
          </Control>
          <Control>
            <Button as={Link} to='/distributor' type='button' text>
              {t('cancel')}
            </Button>
          </Control>
          {confirmAction.showMessage && <FormError {...confirmAction} />}
        </Field>
      </form>
    </Box>
  )
}
