import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { logic } from '../../../logic'
import { useForm } from 'react-hook-form'
import { SelectContainer, Select } from 'rbx/elements/form/select'

export const SelectTenants = (props) => {
  const { t } = useTranslation()
  const { watch } = useForm()

  const [tenants, setTenants] = useState()

  const [selectedTenant, setSelectedTenant] = useState(props.tenantIdOriginal)

  useEffect(() => {
    logic
      .getTenantsAdmin()
      .then(tenants => {
        const data = tenants && tenants.data
        setTenants(data)
      })
      .catch(err => {
        window.alert(err.message)
      })
  }, [])

  const handleTenantSelect = event => {
    const { value } = event.target
    //const { key: value } = event.target
    setSelectedTenant(value)
    props.doSelectTenant(value)
   /*  logic.getOneDistributor({distributorIdentity: value})
      .then((distributor) => {
        setSelectedTenant(value)
        props.doSelectTenant({ tenantid: value, distributorid: distributor && distributor.id })
      })
 */  }

  return (
    <SelectContainer>
      <Select
        name='tenants'
        onChange={handleTenantSelect}
        value={selectedTenant || props.tenantIdOriginal || ''}
      >
        <Select.Option value=''>{t('select')} {t('tenant')}</Select.Option>
        {tenants && tenants.map(tenant => {
          return (
            <Select.Option
              key={tenant && tenant.tenantidentity}
              value={tenant && `${tenant.tenantid}&${tenant.tenantname}`}
            >{tenant && tenant.tenantname}
            </Select.Option>
          )
        })}
      </Select>
    </SelectContainer>
  )
}
