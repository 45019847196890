/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, useRef, useContext } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Multiselect } from 'multiselect-react-dropdown'
import { useTranslation } from 'react-i18next'
import { useAlert } from 'react-alert'
import { logic } from '../../../logic'
import { TableOfTables } from '../../../logic/tableOfTables'
import { AdsContext } from "../../../contexts/adsContext"
import { Title, Level, Icon, Button, Table, Box, Select, Progress } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { Pagination } from '../../pagination'
import { TabsSalesStock } from '../TabsSalesStock'
import { arrayFilters } from '../../../utils/arrayFilters'
import { MONTHS_OFFSET } from '../../../config/config'
import { filtersMgmt } from '../filtersMgmt/filtersMgmt'
import './ClientsCategoriesReport.scss'
import { Switch } from 'antd'

export const ClientsCategoriesReport = () => {
  const styleSelectorDistributors = {
    chips: { background: '#6699cc', 'word-wrap': 'break-word ' },
    searchBox: { 'border-radius': '2%', marginTop: '1px', marginBottom: '1px', maxHeight: '100px', overflow: 'auto' },
    multiselectContainer: { width: '60vh', marginRight: '-10px', '-webkit-appearance': 'none', minHeight: '400px', overflow: 'auto' },
    inputField: {
      autocomplete: "off"
    },
    option: { // To change css for dropdown options
      'background-color': 'white',
      color: 'black'
    },
    groupHeading: {
      display: 'none'
    }
  }
  const styleSelectorFilter = {
    chips: { background: '#6699cc', width: 'auto' },
    searchBox: { width: '35vh', marginTop: '1px', marginBottom: '1px', marginRight: '6px', maxHeight: '100px', overflow: 'auto' },
    inputField: {
      autocomplete: "off"
    },
    multiselectContainer: { width: 'auto', 'max-width': '40%', marginRight: '-10px' },
    optionContainer: { // To change css for option container
      border: '2px solid',
      width: '35vh'
    },
    optionListContainer: { // To change css for option container
      border: '2px solid',
      width: '30vh',
      'z-index': '10'
    },
    option: {
      'background-color': 'white',
      color: 'black'
    }
  }
  // i18n hook
  const { t } = useTranslation()
  const { allTableOfTables } = useContext(AdsContext)

  // The table of table CATCLI has the unique id for those PETs business units 
  const petsBusinessUnits = allTableOfTables.filter(table => table.tableoftablesname === 'CATCLI' && 
                                                    table.tableoftablesdata.split('###')[0] === 'DATA' && 
                                                    table.tableoftablesdata.split('###')[1] === logic.tenantId.toString() &&
                                                    table.active)
                                            .map(catcliTable => catcliTable.tableoftablesdata.split('###')[2])
  const alert = useAlert()

  // flag to indicate that the chech button is validating (loading)
  const [isLoadingValidation, setIsLoadingValidation] = useState('active')
  const [isLoadingValidationDownload, setIsLoadingValidationDownload] = useState('active')

  // main state
  const [data, setData] = useState()

  // date selector start
  const [dateStart, setDateStart] = useState(new Date())

  // date selector end
  const [dateEnd, setDateEnd] = useState(new Date())

  // distributors that are searched for
  const [searchSales, setSearchSales] = useState(undefined)
  // list of options that can be selected
  const [arrayPopulation, setArrayPopulation] = useState()
  const [arrayProvince, setArrayProvince] = useState()
  const [arrayPostalCode, setArrayPostalCode] = useState()
  const [arrayCIF, setArrayCIF] = useState()
  const [arrayGroup, setArrayGroup] = useState()
  const [arrayClientCategory, setArrayClientCategory] = useState()
  
  // list of selected options
  const [selectedProvince, setSelectedProvince] = useState([])
  const [selectedPostalCode, setSelectedPostalCode] = useState([])
  const [selectedCIF, setSelectedCIF] = useState([])
  const [selectedGroup, setSelectedGroup] = useState([])
  const [selectedClientCategory, setSelectedClientCategory] = useState([])
  const [years, setYears] = useState()
  const [yearPrice, setYearPrice] = useState()

  // below constants keep all the maximum possible original values of each filter
  // we use them when we want to restore the filters to its original value because
  // useState doesn't always updates 100% of times the values and this provokes slight wrong behaviours
  //const regionRef = useRef()
  const provinceRef = useRef()
  const postalCodeRef = useRef()
  const groupRef = useRef()
  const clientCategoryRef = useRef()
  const CIFRef = useRef()

  // State refering to the pagination attributes comming from the pagination component
  const defaultPaginationStartPosition = 0
  const defaultPaginationEndPosition = 10
  const [startPosition, setStartPosition] = useState(
    defaultPaginationStartPosition
  )
  const [endPosition, setEndPosition] = useState(defaultPaginationEndPosition)

  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const paginationRef = useRef()

  const [theProgress, setTheProgress] = useState()
  const [progressSeconds, setProgressSeconds] = useState()

  useEffect(() => {              
    if (progressSeconds !== 0) {
      const progress = setTimeout(() => { setTheProgress(theProgress + 1) }, 1000)
      return () => clearTimeout(progress)
    } else {
      setTheProgress(0)
    }
  }, [theProgress])

  // Fetch products when component mounts
  useEffect(() => {
    logic.getProductPrices().then(prices => {
      const data = prices && prices.data
      const pricesYears = [...new Set(data.map(item => item.productpriceyear))]
      const pricesYearsFiltered = pricesYears.filter(item => {
        if (item !== null) return item
      }).sort()
      // The customer wants only the last prices available so we select the nearest year. 
      // In case in the future they wanted to value the sales with prices from other years you can change the next instruction by 
      // setYears(pricesYearsFiltered)
      // In the back-end the query has been adapted as well to work with any year calculating the values with the prices for the 
      // selected year or previous nearest years in case the product didn't have price for the selected year.
      setYears([pricesYearsFiltered[pricesYearsFiltered.length - 1]])
      if (pricesYearsFiltered) setYearPrice(pricesYearsFiltered[pricesYearsFiltered.length - 1])
    })
  }, [])

  // set date to one month before 
  useEffect(() => {
    const currentDate = new Date()
    currentDate.setMonth(currentDate.getMonth() - MONTHS_OFFSET)
    setDateStart(currentDate)
    setDateEnd(currentDate)
  }, [])

  const handleChangeDateStart = date => {
    setDateStart(date)
  }
  const handleChangeDateEnd = date => {
    setDateEnd(date)
  }
  // handles the pagination requests
  const handleRequestPagination = requestPaginationObject => {
    let newStartPosition = defaultPaginationStartPosition
    let newEndPosition = defaultPaginationEndPosition
    if (requestPaginationObject) {
      const { currentPage, itemsPerPage } = requestPaginationObject
      newStartPosition = currentPage * itemsPerPage - itemsPerPage
      newEndPosition = newStartPosition + itemsPerPage
    }
    setStartPosition(newStartPosition)
    setEndPosition(newEndPosition)
  }

  const calculateDiffTime = () => {
    let diffTime = 0
      if (dateEnd.getFullYear() > dateStart.getFullYear()) {
        if (dateEnd.getFullYear() > dateStart.getFullYear() + 1) {
          diffTime = 9999
        } else {
          diffTime = (dateEnd.getMonth() + 12) - dateStart.getMonth()
        }
      } else {
          diffTime = dateEnd.getMonth() - dateStart.getMonth()
      }
      return diffTime
  }

  const isDateRangeValid = () => {
    const pureDateEnd = `${dateEnd.getFullYear()}`+`${dateEnd.getMonth().toString().padStart(2, '0')}`
    const pureDateStart = `${dateStart.getFullYear()}`+`${dateStart.getMonth().toString().padStart(2, '0')}`
    if (pureDateEnd < pureDateStart) {
      return false
    }
    return true
  }

  const handleRetrieveSalesData = () => {
    if (!yearPrice) {
      return alert.show(t('Debe tener precios para generar este reporte'), {
        type:
          'error'
      })
    }
    if (!petsBusinessUnits || petsBusinessUnits.length === 0) {
      return alert.show(t('No hay unidades de negocio de mascotas definidas'), {
        type:
          'error'
      })
    }
    if (dateEnd && dateStart) {
      setIsLoadingValidation('loading')
      
      if (!isDateRangeValid()) {
        setIsLoadingValidation('active')
        return alert.show(t('fecha inicial debe ser anterior a fecha final'), {
          type:
            'error'
        })
      }
      
      if (calculateDiffTime() > 2) {
        setIsLoadingValidation('active')
        return alert.show(t('Franja de tiempo máxima de 3 meses excedida'), {
          type:
            'error'
        })
      }
      let totalSales = 0
      
      setTheProgress(0)
      setProgressSeconds(1000)
      logic
        .retrieveCategoryClientsSalesReportAllDistributors({ dateStart, dateEnd, yearPrice, petsBusinessUnits, timeout: 600000 })
        .then(result => {
          let data = result && result.data
          data = groupAndSumRows(data)
          let arrayProvince = data.map(({ province }) => province)
          arrayProvince = arrayFilters.arrayFilters(arrayProvince)
          let arrayPopulation = data.map(({ population }) => population)
          arrayPopulation = arrayFilters.arrayFilters(arrayPopulation)
          let arrayPostalCode = data.map(({ postalcode }) => postalcode)
          arrayPostalCode = arrayFilters.arrayFilters(arrayPostalCode)
          let arrayCIF = data.map(({ clientidentity }) => clientidentity)
          arrayCIF = arrayFilters.arrayFilters(arrayCIF)
          let arrayGroup = data.map(({ group }) => group)
          arrayGroup = arrayFilters.arrayFilters(arrayGroup)
          let arrayClientCategory = data.map(({ clientcategory }) => clientcategory)
          arrayClientCategory = arrayFilters.arrayFilters(arrayClientCategory)
          setArrayProvince(arrayProvince)
          setArrayPopulation(arrayPopulation)
          setArrayPostalCode(arrayPostalCode)
          setArrayCIF(arrayCIF)
          setArrayGroup(arrayGroup)
          setArrayClientCategory(arrayClientCategory)
          setSearchSales(data)
          setData(data)
          setProgressSeconds()
          setTheProgress()
          if (data.length === 0 && result && result.message && !result.message.includes('Timeout')) {
            setSearchSales(undefined)
            alert.show(t('Datos no existentes'), { type: 'error' })
            setIsLoadingValidation('active')
          }
          if(result && result.message && result.message.includes('Timeout')) {
            alert.show(t('Timeout'), { type: 'info' })
            setIsLoadingValidation('active')
          }
          return data
        })
        .then(data => {
          if (data.length !== 0) {
            alert.show(t('report succesfully retrieved'), { type: 'success' })
            setIsLoadingValidation('active')
          }
        }) 
    }
  }

  function groupAndSumRows(inputArray) {
    const groupedRows = {}
  
    inputArray.forEach((row) => {
      const key = `${row.date}-${row.distributorcode}-${row.group}-${row.businessunit}-${row.clientcategory}-${row.clientidentity}-${row.province}-${row.postalcode}`
  
      if (!groupedRows[key]) {
        groupedRows[key] = {
          ...row,
          value: row.value !== null ? parseFloat(row.value.replace(',', '.')) : 0,
        }
      } else {
        groupedRows[key].value += row.value !== null ? parseFloat(row.value.replace(',', '.')) : 0
      }
  
      // Round the value to two decimals
      groupedRows[key].value = Math.round(groupedRows[key].value * 100) / 100
    })
  
    return Object.values(groupedRows)
  }
  
  const handleDownloadData = () => {
    if (!yearPrice) {
      return alert.show(t('Debe tener precios para generar este reporte'), {
        type:
          'error'
      })
    }
    if (!petsBusinessUnits || petsBusinessUnits.length === 0) {
      return alert.show(t('No hay unidades de negocio de mascotas definidas'), {
        type:
          'error'
      })
    }
    if (!isDateRangeValid()) {
      setIsLoadingValidationDownload('active')
      return alert.show(t('fecha inicial debe ser anterior a fecha final'), {
        type:
          'error'
      })
    }
    if (calculateDiffTime() > 2) {
      setIsLoadingValidation('active')
      return alert.show(t('Franja de tiempo máxima de 3 meses excedida'), {
        type:
          'error'
      })
    }
    if (dateEnd && dateStart) {
      setIsLoadingValidationDownload('loading')
      let totalSales = 0
      
      setIsLoadingValidationDownload('loading')
      setTheProgress(0)
      setProgressSeconds(1000)
      logic
        .downloadCategoryClientsReportAllDistributors({ dateStart, dateEnd, yearPrice, petsBusinessUnits, timeout: 600000 })
        .then(result => {
          const data = result && result.data
          let a = document.createElement('a')
          a.href = `${logic.url}/${data.path}`
          // Give filename you wish to download
          a.download = data.name
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          setProgressSeconds()
          setTheProgress()
          if(result && result.message && result.message.includes('Timeout')) {
            alert.show(t('Timeout'), { type: 'info' })
            setIsLoadingValidation('active')
          }
        })
        .then( () => {
          alert.show(t('report succesfully retrieved'), { type: 'success' })
          setIsLoadingValidationDownload('active')
        }) 
    }
  }
  const handleDownloadSalesData = async () => {
    setIsLoadingValidationDownload('loading')
    if (searchSales) {
      const sheet1 = searchSales.map((item) => {
        const { distributorcode: distributorCode, distributorcif: DistributorCIF, distributorname: distributorName, postalcode: postalCode, province, clientidentity: clientIdentity, clientcategory: clientCategory, clientname: clientNamme, businessunit: BusinessUnit, group: Group, value: Value, date } = item
        return {
          DistributorCode: distributorCode, DistributorCIF: DistributorCIF, distributorName: distributorName, postalCode: "=\""+postalCode + "\"", province: province, clientCif: clientIdentity, clientCategory: clientCategory, clientName: clientNamme, BusinessUnit: BusinessUnit, Group: Group, Value: Value.toFixed(2).toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, "."), Saledate: date
        }
      })
      setIsLoadingValidationDownload('loading')

      if (calculateDiffTime() > 2) {
        setIsLoadingValidation('active')
        return alert.show(t('Franja de tiempo máxima de 3 meses excedida'), {
          type:
            'error'
        })
      }
      const name = `distributorCategoryClientsSalesReport`
      logic.downloadFilteredCategoryClientsSalesReport(sheet1)
      .then(result => {
        setIsLoadingValidationDownload('loading')
        const data = result && result.data
        let a = document.createElement('a')
        a.href = `${logic.url}/${data.path}`
        // Give filename you wish to download
        a.download = data.name
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      })
      .then(() => {
        setIsLoadingValidationDownload('active')
        alert.show(t('report succesfully retrieved'), { type: 'success' })
      })
    }
  }
  // Filter selectors - the working of these filters is alike the Excel ones
  const filterSelectors = (selected = [], typeOfSelection = 'Empty', selectedItem = 'removed', doesItActsAsResetFilter = false) => {
    let newFiltersOptionsAndSelection = {
      input: {
        typeOfSelection: typeOfSelection,
        selectedItem: selectedItem,
        selected: selected,
        allSelected: ['n/a', selectedProvince, 'n/a' , selectedPostalCode, 'n/a', selectedGroup, 'n/a', 'n/a', selectedCIF, selectedClientCategory],
        data: data && [...data]  
      },
      output: {
        newSelections: [],
        newSearchSales: data && [...data],
        newArrays: []
      }
    }
    filtersMgmt(newFiltersOptionsAndSelection, doesItActsAsResetFilter)
    let newSelectedProvinces = [...newFiltersOptionsAndSelection.output.newSelections[1]]
    let newSelectedPostalCodes = [...newFiltersOptionsAndSelection.output.newSelections[3]]
    let newSelectedGroups = [...newFiltersOptionsAndSelection.output.newSelections[5]]
    let newSelectedCIFs = [...newFiltersOptionsAndSelection.output.newSelections[8]]
    let newSelectedClientCategories = [...newFiltersOptionsAndSelection.output.newSelections[9]]

    let newSearchSales = [...newFiltersOptionsAndSelection.output.newSearchSales]

    let arrayProvince = data && [...data]
    let arrayPostalCode = [...newFiltersOptionsAndSelection.output.newArrays[2]]
    let arrayGroup = [...newFiltersOptionsAndSelection.output.newArrays[4]]
    let arrayCIF = [...newFiltersOptionsAndSelection.output.newArrays[7]]
    let arrayClientCategory = [...newFiltersOptionsAndSelection.output.newArrays[8]]

    // When there are NO selections the options are all the possible ones
    if (!newSelectedProvinces.length && !newSelectedPostalCodes.length && !newSelectedGroups.length  && !newSelectedCIFs.length && 
        !newSelectedClientCategories.length && newSearchSales) {
          if (typeOfSelection !== 'Provinces') {
            setArrayProvince(arrayFilters.arrayFilters(newSearchSales.map(({ province }) => province)))
            setSelectedProvince([])
            provinceRef.current.resetSelectedValues()
          }
          if (typeOfSelection !== 'PostalCodes') {
            setArrayPostalCode(arrayFilters.arrayFilters(newSearchSales.map(({ postalcode }) => postalcode)))
            setSelectedPostalCode([])
            postalCodeRef.current.resetSelectedValues()
          } 
          if (typeOfSelection !== 'Groups') {
            setArrayGroup(arrayFilters.arrayFilters(newSearchSales.map(({ group }) => group)))
            setSelectedGroup([])
            groupRef.current.resetSelectedValues()
          }
          if (typeOfSelection !== 'CIFs') {
            setArrayCIF(arrayFilters.arrayFilters(newSearchSales.map(({ clientidentity }) => clientidentity)))
            setSelectedCIF([])
            CIFRef.current.resetSelectedValues()
          }
          if (typeOfSelection !== 'ClientCategories') {
            setArrayClientCategory(arrayFilters.arrayFilters(newSearchSales.map(({ clientcategory }) => clientcategory)))
            setSelectedClientCategory([])
            clientCategoryRef.current.resetSelectedValues()
          }
    } else { // When there are selected items we do two things: 
             // a) We create the new list of all possible selections
             // b) We update the list of selected items that could have changed depending
             //    on the new items selected
             // The provinces can always be all selected no matter which other options
             // have been selected
          if (newSearchSales && arrayProvince && typeOfSelection !== 'Provinces') {
            setArrayProvince(arrayFilters.arrayFilters(data.map(({ province }) => province)))
            setSelectedProvince(newSelectedProvinces.filter(province => arrayFilters.arrayFilters(arrayProvince.map(({ province }) => province)).includes(province)))
          } 
          if (newSearchSales && arrayPostalCode && typeOfSelection !== 'PostalCodes') {
            setArrayPostalCode(arrayFilters.arrayFilters(arrayPostalCode.map(({ postalcode }) => postalcode)))
            setSelectedPostalCode(newSelectedPostalCodes.filter(postalcode => arrayFilters.arrayFilters(arrayPostalCode.map(({ postalcode }) => postalcode)).includes(postalcode)))
          }
          if (newSearchSales && arrayGroup && typeOfSelection !== 'Groups') {
            setArrayGroup(arrayFilters.arrayFilters(arrayGroup.map(({ group }) => group)))
            setSelectedGroup(newSelectedGroups.filter(group => arrayFilters.arrayFilters(arrayGroup.map(({ group }) => group)).includes(group)))
          }
          if (newSearchSales && arrayCIF && typeOfSelection !== 'CIFs') {
            setArrayCIF(arrayFilters.arrayFilters(arrayCIF.map(({ clientidentity }) => clientidentity)))
            setSelectedCIF(newSelectedCIFs.filter(clientidentity => arrayFilters.arrayFilters(arrayCIF.map(({ clientidentity }) => clientidentity)).includes(clientidentity)))
          }
          if (newSearchSales && arrayClientCategory && typeOfSelection !== 'ClientCategories') {
            setArrayClientCategory(arrayFilters.arrayFilters(arrayClientCategory.map(({ clientcategory }) => clientcategory)))
            setSelectedClientCategory(newSelectedClientCategories.filter(clientcategory => arrayFilters.arrayFilters(arrayClientCategory.map(({ clientcategory }) => clientcategory)).includes(clientcategory)))
          }
    }
    setSearchSales(newSearchSales)
    if (newSearchSales && newSearchSales.length === 0) {
      alert.show(t('wrong filter combination'), { type: 'error' })
    }
  }

  // reset report
  const handleReset = () => {
    setSelectedProvince([])
    setSelectedPostalCode([])
    setSelectedGroup([])
    setSelectedCIF([])
    setSelectedClientCategory([])
    setSearchSales(undefined)
  }
  // reset filters
  const handleResetFilters = () => {
    setSelectedProvince([])
    setSelectedPostalCode([])
    setSelectedGroup([])
    setSelectedCIF([])
    setSelectedClientCategory([])
    filterSelectors()
  }
  // province
  const handleSelectProvince = (newSelectedProvinces, selectedItem) => {
    setSelectedProvince(newSelectedProvinces)
    filterSelectors(newSelectedProvinces, 'Provinces', selectedItem)
  }
  const handleRemoveProvince = (newSelectedProvinces, selectedItem) => {
    setSelectedProvince(newSelectedProvinces)
    if (!newSelectedProvinces.length) {
      setSelectedProvince([])
    }
    filterSelectors(newSelectedProvinces, 'Provinces', 'removed', true) // true means that this filter is the highest hierarchical and when is empty resets all
  }
  // postal code
  const handleSelectPostalCode = (newSelectedPostalCodes, selectedItem) => {
    setSelectedPostalCode(newSelectedPostalCodes)
    filterSelectors(newSelectedPostalCodes, 'PostalCodes', selectedItem)
  }
  const handleRemovePostalCode = (newSelectedPostalCodes, selectedItem) => {
    setSelectedPostalCode(newSelectedPostalCodes)
    if (!newSelectedPostalCodes.length) {
      setSelectedPostalCode([])
    }
    filterSelectors(newSelectedPostalCodes, 'PostalCodes')
  }
  // group
  const handleSelectGroup = (newSelectedGroups, selectedItem) => {
    setSelectedGroup(newSelectedGroups)
    filterSelectors(newSelectedGroups, 'Groups', selectedItem)
  }
  const handleRemoveGroup = (newSelectedGroups, selectedItem) => {
    setSelectedGroup(newSelectedGroups)
    if (!newSelectedGroups.length) {
      setSelectedGroup([])
    }
    filterSelectors(newSelectedGroups, 'Groups')
  }
   // ClientCIF
   const handleSelectCIF = (newSelectedCIFs, selectedItem) => {
    setSelectedCIF(newSelectedCIFs)
    filterSelectors(newSelectedCIFs, 'CIFs', selectedItem)
  }
  const handleRemoveCIF = (newSelectedCIFs, selectedItem) => {
    setSelectedCIF(newSelectedCIFs)
    if (!newSelectedCIFs.length) {
      setSelectedCIF([])
    }
    filterSelectors(newSelectedCIFs, 'CIFs')
  }
  // client category
  const handleSelectClientCategory = (newSelectedClientCategories, selectedItem) => {
    setSelectedClientCategory(newSelectedClientCategories)
    filterSelectors(newSelectedClientCategories, 'ClientCategories', selectedItem)
  }
  const handleRemoveClientCategory = (newSelectedClientCategories, selectedItem) => {
    setSelectedClientCategory(newSelectedClientCategories)
    if (!newSelectedClientCategories.length) {
      setSelectedClientCategory([])
    }
    filterSelectors(newSelectedClientCategories, 'ClientCategories')
  }

  const handleYearChange = (e) => {
    const { year } = e.target.form
    if (year.value === '') return
    setYearPrice(year.value)
  }

  return (
    <Box id='clientsCategoriesReports'>
      <TabsSalesStock highlight="clients' categories" />
      {!searchSales && <Level>
        <Level.Item>
          <form>
            <Title size={5}>{t('year prices')}:</Title>
            <Select.Container>
              <Select name='year' onChange={handleYearChange} defaultValue='' value={yearPrice && yearPrice}>
                <Select.Option value=''>
                  {t('select')} {t('year')}
                </Select.Option>
                {years && years.map((year, index) => {
                  return (
                    <Select.Option key={year} value={year}>
                      {year}
                    </Select.Option>
                  )
                })}
              </Select>
            </Select.Container>
          </form>
        </Level.Item>
        <Level.Item>
          <div>
            <Title size={5}>{t('Desde')}:</Title>
            <DatePicker
              showPopperArrow={false}
              dateFormat='MM/yyyy'
              showMonthYearPicker
              selected={dateStart}
              onChange={handleChangeDateStart}
            />
          </div>
        </Level.Item>
        <Level.Item>
          <div>
            <Title size={5}>{t('Hasta')}:</Title>
            <DatePicker
              dateFormat='MM/yyyy'
              showMonthYearPicker
              selected={dateEnd}
              onChange={handleChangeDateEnd}
            />
          </div>
        </Level.Item>
      </Level>}
      {!searchSales && <Level>
        <Level.Item>
          <Button.Group>
            <Button onClick={handleRetrieveSalesData} color='primary' state={isLoadingValidation}>
              {t('Generar Informe')}
            </Button>
            <Button onClick={handleDownloadData} color='warning' state={isLoadingValidationDownload}>
              {t('Descargar Informe')}
            </Button> 
          </Button.Group>
        </Level.Item>
      </Level>}
      {searchSales && 
        <Level>
          <Level.Item>
            <Title size={5}>{t('Desde')}:</Title>&nbsp;
            <DatePicker
              showPopperArrow={false}
              dateFormat='MM/yyyy'
              selected={dateStart}
              disabled
            />
          </Level.Item>
          <Level.Item>
            <Title size={5}>{t('Hasta')}:</Title>&nbsp;
            <DatePicker
              dateFormat='MM/yyyy'
              selected={dateEnd}
              disabled
            />
            <br></br>
          </Level.Item>
        </Level>
      }
      <Level>
        {searchSales && <Level.Item>
          <Button.Group>
            <Button onClick={handleReset} color='primary'>
              {t('Generar Informe Nuevo')}
            </Button>
            <Button onClick={handleDownloadSalesData} color='success' state={isLoadingValidationDownload}>
              <span>
                {t('Descargar Informe')}
              </span>
              <Icon>
                <FontAwesomeIcon icon={['fas', 'cloud-download-alt']} />
              </Icon>
            </Button>
            <Button onClick={handleResetFilters} color='warning'>
              <span>
                {t('Restablecer filtros')}
              </span>
              <Icon>
                <FontAwesomeIcon icon={faFilter} />
              </Icon>
            </Button>
          </Button.Group>
        </Level.Item>}
      </Level>
      {theProgress > 0 &&
        <div>
          <Level>
            <Level.Item>
              <div style={{ width: 325 }}>
                <Progress size="small" color="primary"/>
              </div>
              <p>&nbsp;<strong>{t('In progress')} {`${Math.trunc(theProgress / 60).toString().padStart(2, '0')}:${Math.trunc(theProgress % 60).toString().padStart(2, '0')}`} min</strong></p>
            </Level.Item>  
          </Level>
        </div>
      }
      <form id="Form1" runat="server" autoComplete="off">
        <Level>
          {searchSales && <Level.Item>
            <Select.Container color='dark'>
              <Multiselect closeOnSelect={false} placeholder={t('Province')} ref={provinceRef} selectedValues={selectedProvince} options={arrayProvince} isObject={false} className='multiselectcontainerFilter' style={styleSelectorFilter} onSelect={handleSelectProvince} onRemove={handleRemoveProvince} avoidHighlightFirstOption />
            </Select.Container>
            <Select.Container color='dark'>
              <Multiselect closeOnSelect={false} placeholder={t('codigo postal')} ref={postalCodeRef} selectedValues={selectedPostalCode} options={arrayPostalCode} isObject={false} className='multiselectcontainerFilter' style={styleSelectorFilter} onSelect={handleSelectPostalCode} onRemove={handleRemovePostalCode} avoidHighlightFirstOption />
            </Select.Container>
            <Select.Container color='dark'>
              <Multiselect closeOnSelect={false} placeholder={t('Client CIF')} ref={CIFRef} selectedValues={selectedCIF} options={arrayCIF} isObject={false} className='multiselectcontainerFilter' style={styleSelectorFilter} onSelect={handleSelectCIF} onRemove={handleRemoveCIF} avoidHighlightFirstOption />
            </Select.Container>
          </Level.Item>}
        </Level>
        <Level>
          {searchSales && <Level.Item>
            <Select.Container color='dark'>
              <Multiselect closeOnSelect={false} placeholder={t('clientCategory')} ref={clientCategoryRef} selectedValues={selectedClientCategory} options={arrayClientCategory} isObject={false} className='multiselectcontainerFilter' style={styleSelectorFilter} onSelect={handleSelectClientCategory} onRemove={handleRemoveClientCategory} avoidHighlightFirstOption />
            </Select.Container>
            <Select.Container color='dark'>
              <Multiselect closeOnSelect={false} placeholder={t('Grupos')} ref={groupRef} selectedValues={selectedGroup} options={arrayGroup} isObject={false} className='multiselectcontainerFilter' style={styleSelectorFilter} onSelect={handleSelectGroup} onRemove={handleRemoveGroup} avoidHighlightFirstOption />
            </Select.Container>
          </Level.Item>}
        </Level>
      </form>
      <br />
      {searchSales && searchSales.length > 0 && logic.notCrossSales && <Switch checkedChildren={t('Sin vtas cruzadas')} disabled checked />} 
      {searchSales && searchSales.length > 0 && !logic.notCrossSales && <Switch unCheckedChildren={t('Con vtas cruzadas')} disabled />} 
      <br /> 
      <br />
      {searchSales && <Table narrow hoverable bordered striped>
        <Table.Head>
          <Table.Row>
            <Table.Heading>{t('Distributor code')}</Table.Heading>
            <Table.Heading>{t('CIF')}</Table.Heading>
            <Table.Heading>{t('Distributor')}</Table.Heading>
            <Table.Heading>{t('Postal Code')}</Table.Heading>
            <Table.Heading>{t('Province')}</Table.Heading>
            <Table.Heading>{t('Client CIF')}</Table.Heading>
            <Table.Heading>{t('clientCategory')}</Table.Heading>
            <Table.Heading>{t('clientName')}</Table.Heading>
            <Table.Heading>{t('Bussiness Unit')}</Table.Heading>
            <Table.Heading>{t('Group')}</Table.Heading>
            <Table.Heading>{t('Value')}</Table.Heading>
            <Table.Heading>{t('Sale date')}</Table.Heading>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {searchSales &&
            searchSales.slice(startPosition, endPosition).map((item, index) => {
              return (
                <Table.Row key={index}>
                  <Table.Cell>{item.distributorcode}</Table.Cell>
                  <Table.Cell>{item.distributorcif}</Table.Cell>
                  <Table.Cell>{item.distributorname}</Table.Cell>
                  <Table.Cell>{item.postalcode}</Table.Cell>
                  <Table.Cell>{item.province}</Table.Cell>
                  <Table.Cell>{item.clientidentity}</Table.Cell>
                  <Table.Cell>{item.clientcategory}</Table.Cell>
                  <Table.Cell>{item.clientname}</Table.Cell>
                  <Table.Cell>{item.businessunit}</Table.Cell>
                  <Table.Cell>{item.group}</Table.Cell>
                  <Table.Cell className="right">{item.value !== null ? item.value.toFixed(2).toString().replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") : item.value}</Table.Cell>
                  <Table.Cell>{item.date}</Table.Cell>
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table>}
      {searchSales && searchSales.length > 0 && <Pagination
        totalItems={searchSales && searchSales.length}
        ref={paginationRef}
        requestPagination={handleRequestPagination}
      />}
    </Box>
  )
}