import React, { useState, useEffect, useRef, useContext } from 'react'
import { useAlert } from 'react-alert'
import { useTranslation } from 'react-i18next'
import { TabsObjectives } from '../tabsObjectives'
import { Level, Box, Title, Field, Icon, Button, Table, Select } from 'rbx'
import { Modal } from '../../modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Pagination } from '../../pagination'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useToggle } from '../../../logic/hooks'
import { Search } from '../../filter/Search'
import { AdsContext } from "../../../contexts/adsContext"

export const ObjectivesTypes = () => {
  const alert = useAlert()
  // Hooks for toggling on and off the modal
  const [open, toggle] = useToggle(false)
  const [openInactive, toggleInactive] = useToggle(false)
   // React active or inactive
  const [dropdownState, setDropdownState] = useState('active')
  // state inside the modal
  const [related, setRelated] = useState([])

  const [searchTypes, setSearchTypes] = useState([])

  const { t } = useTranslation()

  // Routing variables
  const history = useHistory()
  const { url } = useRouteMatch()

  // State refering to the pagination attributes comming from the pagination component
  const defaultPaginationStartPosition = 0
  const defaultPaginationEndPosition = 10
  const [startPosition, setStartPosition] = useState(
    defaultPaginationStartPosition
  )
  const [endPosition, setEndPosition] = useState(defaultPaginationEndPosition)

  const [deleteItem, setDeleteItem] = useState()
  const { fetchAllTenantAgreementsTypes, 
              fetchAllTenantObjectives,
              inactivateTenantAgreementType,
              activateTenantAgreementType,
              allTenantAgreementsTypes } = useContext(AdsContext)
  // Fetch products when component mounts
  useEffect(() => {
    (async function () {
      const agreementsTypes = await fetchAllTenantAgreementsTypes()
      const sortedTypes =
        agreementsTypes &&
        agreementsTypes
        .filter(agreementType => { if(agreementType.active) return agreementType})
        .sort((a, b) => {
            if (a.agreementtypename.toLowerCase() < b.agreementtypename.toLowerCase()) {
                return -1
            }
            if (a.agreementtypename.toLowerCase() > b.agreementtypename.toLowerCase()) {
              return 1
            }
            return 0
        })
        //setTypes(sortedTypes)
        setSearchTypes(sortedTypes)
    })()
  }, [])

  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const paginationRef = useRef()

  // handles the pagination requests
  const handleRequestPagination = requestPaginationObject => {
    let newStartPosition = defaultPaginationStartPosition
    let newEndPosition = defaultPaginationEndPosition
    if (requestPaginationObject) {
      const { currentPage, itemsPerPage } = requestPaginationObject
      newStartPosition = currentPage * itemsPerPage - itemsPerPage
      newEndPosition = newStartPosition + itemsPerPage
    }
    setStartPosition(newStartPosition)
    setEndPosition(newEndPosition)
  }

  // handles routing to add/edit
  const handleAddEditClick = (event, element) => {
    if (element) {
      history.push({
        pathname: `${url}/edit/${element && element.agreementtypeid}`,
        state: element
      })
    } else {
      history.push({
        pathname: `${url}/add`
      })
    }
  }

  //handles activation of tenantclientgroup
  const handleActivateItem = async (event, element) => {
    const id = `${element.agreementtypeid}`
    try {
      const updatedAgreementType = await activateTenantAgreementType(id)
      const active = false
      newTypesSorted(updatedAgreementType, active)
      toggleInactive()
    }
    catch (error) {
      throw Error(error)
    }
  }

  // deletes the item
  const handleDeleteClick = async (event, element) => {
    const id = `${element.agreementtypeid}`
    try {
      const updatedAgreementType = await inactivateTenantAgreementType(id)
      const active = true
      newTypesSorted(updatedAgreementType, active)
      toggle()
    } catch (error) {
      throw Error(error)
    }
  }

  const newTypesSorted = (updatedAgreementType, active) => {
    const newTypes = allTenantAgreementsTypes.filter(
      agreementType => agreementType.agreementtypeid !== updatedAgreementType.agreementtypeid && agreementType.active === active
    )
    const sortedNewTypes =
      newTypes &&
      newTypes.sort((a, b) => {
          if (a.agreementtypename.toLowerCase() < b.agreementtypename.toLowerCase()) {
            return -1
          }
          if (a.agreementtypename.toLowerCase() > b.agreementtypename.toLowerCase()) {
            return 1
          }
          return 0
      })
      setSearchTypes(sortedNewTypes)
  }

  const showRelatedItems = async (event, item) => {
    try {
      setDeleteItem({ ...item })
      const allTenantObjectives = await fetchAllTenantObjectives()
      if (allTenantObjectives.filter(tenantObjective => 
          tenantObjective.agreementtypeid === item.agreementtypeid && tenantObjective.active === true).length) {
          setRelated(allTenantObjectives.filter(tenantObjective => 
            tenantObjective.agreementtypeid === item.agreementtypeid && tenantObjective.active === true))
      } else {
          setRelated([])
      }
      toggle()
    } catch (error) {
      throw Error("Error while getting the tenant's objectives")
    }
  }

  const showRelatedItemsInactive = (event, item) => {
    setDeleteItem({ ...item })

    // TODO set correct logic for related
    setRelated([])
    toggleInactive()
  }

  const handleDoSearch = value => {
    let active = dropdownState === 'active' ? true : false
    const result = allTenantAgreementsTypes.filter(product => {
      const values = Object.values(product)

      const isIncluded = values
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(value.toLowerCase().replace(/\s+/g, ''))
      // pagination Reset vallues
      paginationRef.current.resetValues()
      return isIncluded && product.active === active
    })
    const sortedResult = result.sort((a, b) => {
      if (a.agreementtypename.toLowerCase() < b.agreementtypename.toLowerCase()) {
        return -1
      }
      if (a.agreementtypename.toLowerCase() > b.agreementtypename.toLowerCase()) {
        return 1
      }
      return 0
    })
    setSearchTypes(sortedResult)
    return sortedResult
  }

  const handleDropdownStateChange = (event) => {
    setDropdownState(event.target.value)
    let active
    if(event.target.value === 'active') {
      active = true
    } else {
      active = false
    }
    const sortedActiveAgreementsTypes = allTenantAgreementsTypes
      .filter(agreementType => { if(agreementType.active == active) return agreementType})
      .sort((a, b) => {
        if (a.agreementtypename.toLowerCase() < b.agreementtypename.toLowerCase()) {
          return -1
        }
        if (a.agreementtypename.toLowerCase() > b.agreementtypename.toLowerCase()) {
          return 1
        }
        return 0
      })
    setSearchTypes(sortedActiveAgreementsTypes)
    paginationRef.current.resetValues()
  }

  return (
    <Box>
      {open && (
        <Modal toggle={toggle} open={open}>
          <Title>{t('confirmDelete')}</Title>
          {related && related.length !== 0 && (
            <Title subtitle size={6}>
              {t('cannotDeleteRelated')}
            </Title>
          )}
          {related &&
            related.length !== 0 &&
            related.length > 7 &&
            related.slice(0, 7).map((item, index) => {
              return (
                <>
                  <Field key={index}>{`${item.objectivecode}: ${item.objectivename}`}</Field>
                </>
              )
            })}
          {related && related.length !== 0 && related.length > 7 && (
            <Field>
              ...{t('and')} {related.length - 7} {t('others')}
            </Field>
          )}
          {related &&
            related.length !== 0 &&
            related.length <= 7 &&
            related.map((item, index) => {
              return (
                <>
                  <Field key={index}>{`${item.objectivecode}: ${item.objectivename}`}</Field>
                </>
              )
            })}
          {related && related.length === 0 && (
            <>
              <Title subtitle>{t('deleteSure')}</Title>
              <Button
                onClick={e => handleDeleteClick(e, deleteItem)}
                color='danger'
              >
                {t('delete')}
              </Button>
            </>
          )}
        </Modal>
      )}
      {openInactive && (
        <Modal toggle={toggleInactive} open={openInactive}>
          <Title>{t('confirmActivate')}</Title>
            <>
              <Title subtitle>{t('activateSure')}</Title>
              <Button
                onClick={e => handleActivateItem(e, deleteItem)}
                color='success'
              >
                {t('Activate')}
              </Button>
            </>
        </Modal>
      )}
      <TabsObjectives highlight='types' isInactive="is-inactive"/>
      <Level>
      <Select.Container>
          <Select value={dropdownState} onChange={handleDropdownStateChange} >
            <Select.Option value='active'>
              {t('Actives')}
            </Select.Option>
            <Select.Option value='inactive'>
              {t('Inactives')}
            </Select.Option>
          </Select>
        </Select.Container>
        <Level.Item>
          <Search doSearch={handleDoSearch} />
        </Level.Item>
        <Level.Item align='right'>
          <Button onClick={e => handleAddEditClick()} color='info'>
            <Icon>
              <FontAwesomeIcon size='lg' icon={['fas', 'plus']} />
            </Icon>
          </Button>
        </Level.Item>
      </Level>
      <Table fullwidth narrow hoverable bordered striped>
        <Table.Head>
          <Table.Row>
            <Table.Heading>{t('name')}</Table.Heading>
            <Table.Heading>{t('action')}</Table.Heading>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {searchTypes &&
            searchTypes.slice(startPosition, endPosition).map(item => {
              return (
                <Table.Row
                  onDoubleClick={e => handleAddEditClick(e, item)}
                  key={item.agreementtypeid}
                >
                  <Table.Cell>{item.agreementtypename}</Table.Cell>
                  <Table.Cell>
                    <button
                        onClick={e => handleAddEditClick(e, item)}
                        className='button is-primary is-outlined is-small'
                      >
                        <Icon>
                          <FontAwesomeIcon size='lg' icon={['fas', 'edit']} />
                        </Icon>
                      </button>
                      {dropdownState === 'active' && 
                      <button
                        onClick={e => showRelatedItems(e, item)}
                        className='button is-danger is-outlined is-small'
                      >
                        <Icon>
                          <FontAwesomeIcon size='lg' icon={['fas', 'trash']} />
                        </Icon>
                      </button>}
                      {dropdownState === 'inactive' && <button
                        onClick={e => showRelatedItemsInactive(e, item)}
                        className='button is-success is-outlined is-small'
                      >
                        <Icon>
                          <FontAwesomeIcon size='lg' icon={['fas', 'check-circle']} />
                        </Icon>
                      </button>}
                  </Table.Cell>
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table>
      <Pagination
        totalItems={searchTypes && searchTypes.length}
        ref={paginationRef}
        requestPagination={handleRequestPagination}
      />
    </Box>
  )
}
