import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Tab, Icon, Generic } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

export const TabsClients = ({ highlight }) => {
  const { t } = useTranslation()

  return (
    <Tab.Group align='centered' kind='boxed'>
      <Tab as={Link} to='/reports/new' active={highlight === 'new'}>
        <Icon size='small'>
          <FontAwesomeIcon icon={['fas', 'address-book']} />
        </Icon>
        <span>{t('Seguimiento')}</span>
      </Tab>
      <Tab as={Link} to='/reports/newPerDistributor' active={highlight === 'newPerDistributor'}>
        <Icon size='small'>
          <FontAwesomeIcon icon={['fas', 'address-book']} />
        </Icon>
        <span>{t('Nuevos por distribuidor')}</span>
      </Tab>
      <Tab as={Link} to='/reports/totalPerDistributor' active={highlight === 'totalPerDistributor'}>
        <Icon size='small'>
          <FontAwesomeIcon icon={['fas', 'address-book']} />
        </Icon>
        <span>{t('Únicos por distribuidor')}</span>
      </Tab>
    </Tab.Group>
  )
}
