/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { Box, Title, Container, Field, Input, Control, Label, Button, Icon } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FormError } from '../../../error'
import { logic } from '../../../../logic'
import { TabsProduct } from '../../tabsProduct'
import { useConfirmActionMessage } from '../../../../logic/hooks'

export const AddEditProductBusinessUnit = () => {
  const alert = useAlert()
  const [product, setProduct] = useState()
  // Error handling
  const errorAction = useConfirmActionMessage()

  const { t } = useTranslation()
  const history = useHistory()
  const { id } = useParams()
  const { register, handleSubmit, errors } = useForm()

  useEffect(() => {
    if (id) {
      const userId = logic.sessionId.toString()
      logic.getOneProductBusinessUnit({ userId, productBusinessUnitId: id })
        .then(product => {
          setProduct(product && product.data && product.data[0])
        })
        .catch(err => {
          alert.show(err.message, {
            type: 'error'
          })
        })
    }
  }, [])

  const handleGoBackClick = () => {
    history.push('/product/businessUnit')
  }

  const onSubmitData = ({ name }) => {
    errorAction.closeMessage()
    if (id) {
      logic.updateProductBusinessUnit({ productBusinessUnitId: id, name })
      .then(product => {
        if (product.status !== 200) {
          throw Error(`${product.status}`)
        }
        alert.show(t('productBusinessUnitSuccessfullyUpdated'), {
          type: 'success'
        })
        history.push({
            pathname: '/product/businessUnit',
            state: '200'
          })
      })
      .catch(err => {
        if (err.message === '409') {
          alert.show('Input already exists', {
            type: 'error'
          })
        } else {
          alert.show('Unexpected Error', {
            type: 'error'
          })
        }
      })
    } else {
      logic.insertProductBusinessUnit(name)
        .then(product => {
          if (product.status !== 200) {
            throw Error(product.status)
          }
          alert.show(t('productBusinessUnitSuccessfullyAdded'), {
            type: 'success'
          })
          history.push({
            pathname: '/product/businessUnit',
            state: '200'
          })
        })
        .catch(err => {
          if (err.message === '409') {
            alert.show('Input already exists', {
              type: 'error'
            })
          } else {
            alert.show('Unexpected Error', {
              type: 'error'
            })
          }
        })
    }
  }

  return (
    <Box>
      <TabsProduct highlight='businessUnits' />
      <Button onClick={e => handleGoBackClick()} color='light' size='medium'>
        <Icon>
          <FontAwesomeIcon size='lg' icon={['fas', 'chevron-circle-left']} />
        </Icon>
      </Button>
      <Title>
        {id ? `${t('edit')} ${product && product.productbusinessunitname}` : `${t('add')} ${t('businessUnit')}`}
      </Title>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <Field horizontal>
          <Field.Label size='normal'>
            <Label>{t('name')}</Label>
          </Field.Label>
          <Field.Body>
            <Control>
              <Input type='text' name='name' ref={register({ required: true, maxlength: 128 })} color='light' placeholder={product && product.productbusinessunitname} autoFocus='' />
              {errors.name && errors.name.type === 'required' && (
        <FormError showMessage type='danger' message='Required' />)}
        {errorAction.showMessage && <FormError {...errorAction} />}
            </Control>
          </Field.Body>
        </Field>

        {id
          ? <Button color='info'>
            <Container>
              {t('update')}
            </Container>
            <Icon>
              <FontAwesomeIcon size='lg' icon={['fas', 'sign-in-alt']} />
            </Icon>
            </Button>
          : <Button color='success'>
            <Container>
              {t('add')}
            </Container>
            <Icon>
              <FontAwesomeIcon size='lg' icon={['fas', 'sign-in-alt']} />
            </Icon>
            </Button>}
      </form>
    </Box>
  )
}
