/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, useRef,useContext } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Multiselect } from 'multiselect-react-dropdown'
import { useTranslation } from 'react-i18next'
import { useAlert } from 'react-alert'
import { logic } from '../../../logic'
import { Title, Level, Icon, Button, Table, Box, Select, Progress } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { Pagination } from '../../pagination'
import { TabsSalesStock } from '../TabsSalesStock'
import { arrayFilters } from '../../../utils/arrayFilters'
import { MONTHS_OFFSET } from '../../../config/config'
import { filtersMgmt } from '../filtersMgmt/filtersMgmt'
import './StockDistributorsReport.scss'
import { AdsContext } from "../../../contexts/adsContext"
import { TableOfTables } from '../../../logic/tableOfTables'

export const StockDistributorsReport = () => {
  const styleSelectorDistributors = {
    chips: { background: '#6699cc', 'word-wrap': 'break-word ' },
    searchBox: { 'border-radius': '2%', marginTop: '1px', marginBottom: '1px', maxHeight: '100px', overflow: 'auto' },
    multiselectContainer: { width: '60vh', marginRight: '-10px', '-webkit-appearance': 'none', minHeight: '400px', overflow: 'auto' },
    inputField: {
      autocomplete: "off"
    },
    option: { // To change css for dropdown options
      'background-color': 'white',
      color: 'black'
    },
    groupHeading: {
      display: 'none'
    }
  }
  const styleSelectorFilter = {
    chips: { background: '#6699cc', width: 'auto' },
    searchBox: { width: '35vh', marginTop: '1px', marginBottom: '1px', marginRight: '6px', maxHeight: '100px', overflow: 'auto' },
    inputField: {
      autocomplete: "off"
    },
    multiselectContainer: { width: 'auto', 'max-width': '40%', marginRight: '-10px' },
    optionContainer: { // To change css for option container
      border: '2px solid',
      width: '35vh'
    },
    optionListContainer: { // To change css for option container
      border: '2px solid',
      width: '30vh',
      'z-index': '10'
    },
    option: {
      'background-color': 'white',
      color: 'black'
    }
  }
  const alert = useAlert()

  // flag to indicate that the chech button is validating (loading)
  const [isLoadingValidation, setIsLoadingValidation] = useState('active')
  const [isLoadingValidationDownload, setIsLoadingValidationDownload] = useState('active')

  // i18n hook
  const { t } = useTranslation()

  // main state
  const [distributors, setDistributors] = useState([])

  // main state
  const [data, setData] = useState()

  // main state
  const [selectedDistributors, setSelectedDistributors] = useState([{distributorname:  t('Todos los distribuidores')}])

  // date selector start
  const [dateStart, setDateStart] = useState(new Date())

  // date selector end
  const [dateEnd, setDateEnd] = useState(new Date())

  // distributors that are searched for
  const [searchSales, setSearchSales] = useState(undefined)

  // filter fields

  const [arrayBrand, setArrayBrand] = useState()
  const [arrayGroup, setArrayGroup] = useState()
  const [arrayBusinessUnit, setArrayBusinessUnit] = useState()
  const [arraySet, setArraySet] = useState()

  const [selectedBrand, setSelectedBrand] = useState([])
  const [selectedGroup, setSelectedGroup] = useState([])
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState([])
  const [selectedSet, setSelectedSet] = useState([])

  // State refering to the pagination attributes comming from the pagination component
  const defaultPaginationStartPosition = 0
  const defaultPaginationEndPosition = 10
  const [startPosition, setStartPosition] = useState(
    defaultPaginationStartPosition
  )
  const [endPosition, setEndPosition] = useState(defaultPaginationEndPosition)

  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const paginationRef = useRef()

  // below constants keep all the maximum possible original values of each filter
  // we use them when we want to restore the filters to its original value because
  // useState doesn't always updates 100% of times the values and this provokes slight wrong behaviours
  const groupRef = useRef()
  const businessUnitRef = useRef()
  const setRef = useRef()
  const brandRef = useRef()

  const [theProgress, setTheProgress] = useState()
  const [progressSeconds, setProgressSeconds] = useState()

  // Add year prices vars
  const [years, setYears] = useState()
  const [yearPrice, setYearPrice] = useState()
  const { allTableOfTables } = useContext(AdsContext)

  const isPVLContracted = TableOfTables('PVL', logic.tenantId.toString(), allTableOfTables, true)

  useEffect(() => {              
    if (progressSeconds !== 0) {
      const progress = setTimeout(() => { setTheProgress(theProgress + 1) }, 1000)
      return () => clearTimeout(progress)
    } else {
      setTheProgress(0)
    }
  }, [theProgress])

  // Fetch products when component mounts
  useEffect(() => {
    logic.getDistributors().then(distributors => {
      
      const data = distributors && distributors.data
      const sortedData = data.sort((a,b) => {
        if (a.distributorname < b.distributorname) {
          return -1
        }
        if (a.distributorname > b.distributorname) {
          return 1
        }
        return 0
      })

      sortedData.unshift({ distributorname: t('Todos los distribuidores') })
      setDistributors(sortedData)
    })
  }, [])

  // set date to one month before 
  useEffect(() => {
    const currentDate = new Date()
    currentDate.setMonth(currentDate.getMonth() - MONTHS_OFFSET)
    setDateStart(currentDate)
    setDateEnd(currentDate)
  }, [])

  // Fetch products prices when component mounts
  useEffect(() => {
    if (isPVLContracted) {
      logic.getProductPrices().then(prices => {
        const data = prices && prices.data
        const pricesYears = [...new Set(data.map(item => item.productpriceyear))]
        const pricesYearsFiltered = pricesYears.filter(item => {
          if (item !== null) return item
        }).sort()
        // The customer wants only the last prices available so we select the nearest year. 
        // In case in the future they wanted to value the sales with prices from other years you can change the next instruction by 
        // setYears(pricesYearsFiltered)
        // In the back-end the query has been adapted as well to work with any year calculating the values with the prices for the 
        // selected year or previous nearest years in case the product didn't have price for the selected year.
        setYears([pricesYearsFiltered[pricesYearsFiltered.length - 1]])
        if (pricesYearsFiltered) setYearPrice(pricesYearsFiltered[pricesYearsFiltered.length - 1])
      })
    }
  }, [])


  const handleChangeDateStart = date => {
    setDateStart(date)
  }
  const handleChangeDateEnd = date => {
    setDateEnd(date)
  }
  const handleSelectDistributor = (selectedDistributors, selectedItem) => {
    setSelectedDistributors(selectedDistributors)
  }
  const handleRemoveDistributor = (selectedDistributors, selectedItem) => {
    setSelectedDistributors(selectedDistributors)
  }
  // handles the pagination requests
  const handleRequestPagination = requestPaginationObject => {
    let newStartPosition = defaultPaginationStartPosition
    let newEndPosition = defaultPaginationEndPosition
    if (requestPaginationObject) {
      const { currentPage, itemsPerPage } = requestPaginationObject
      newStartPosition = currentPage * itemsPerPage - itemsPerPage
      newEndPosition = newStartPosition + itemsPerPage
    }
    setStartPosition(newStartPosition)
    setEndPosition(newEndPosition)
  }
  
  const calculateDiffTime = () => {
    let diffTime = 0
      if (dateEnd.getFullYear() > dateStart.getFullYear()) {
        if (dateEnd.getFullYear() > dateStart.getFullYear() + 1) {
          diffTime = 9999
        } else {
          diffTime = (dateEnd.getMonth() + 12) - dateStart.getMonth() + 1
        }
      } else {
         diffTime = -1
      }
      return diffTime
  }

  const isDateRangeValid = () => {
    const pureDateEnd = `${dateEnd.getFullYear()}`+`${dateEnd.getMonth().toString().padStart(2, '0')}`
    const pureDateStart = `${dateStart.getFullYear()}`+`${dateStart.getMonth().toString().padStart(2, '0')}`
    if (pureDateEnd < pureDateStart) {
      return false
    }
    return true
  }

  const handleRetrieveStockData = () => {
    if (!yearPrice && isPVLContracted) {
      return alert.show(t('Debe tener precios para generar este reporte'), {
        type:
          'error'
      })
    }
    if(!selectedDistributors || (selectedDistributors && !selectedDistributors.length)){
      return alert.show('Por favor seleccione al menos un distribuidor', {
        type:
          'error'
      })
    }
    if (dateEnd && dateStart && selectedDistributors) {
      setIsLoadingValidation('loading')
      let found = false
      for (let i = 0; i < selectedDistributors.length; i++) {
        if (selectedDistributors[i].distributorname === t('Todos los distribuidores')) {
          found = true
          break
        }
      }
      if (!isDateRangeValid()) {
        setIsLoadingValidation('active')
        return alert.show(t('fecha inicial debe ser anterior a fecha final'), {
          type:
            'error'
        })
      }
      if (calculateDiffTime() > 12) {
        setIsLoadingValidation('active')
        return alert.show(t('Franja de tiempo máxima de 12 meses excedida'), {
          type:
            'error'
        })
      }
      setTheProgress(0)
      setProgressSeconds(1000)
      if (found) {
          logic
            .retrieveStockReportAllDistributors({ dateStart, dateEnd, yearPrice, timeout: 600000 })
            .then(result => {
              const data = result && result.data
              let arrayBrand = data.map(({ brand }) => brand)
              arrayBrand = arrayFilters.arrayFilters(arrayBrand)
              let arrayGroup = data.map(({ group }) => group)
              arrayGroup = arrayFilters.arrayFilters(arrayGroup)
              let arrayBusinessUnit = data.map(({ businessunit }) => businessunit)
              arrayBusinessUnit = arrayFilters.arrayFilters(arrayBusinessUnit)
              let arraySet = data.map(({ set }) => set)
              arraySet = arrayFilters.arrayFilters(arraySet)
              setArrayBrand(arrayBrand)
              setArrayGroup(arrayGroup)
              setArrayBusinessUnit(arrayBusinessUnit)
              setArraySet(arraySet)
              setSearchSales(data)
              setData(data)
              setProgressSeconds()
              setTheProgress()
              if (data.length === 0 && result && result.message && !result.message.includes('Timeout')) {
                setSearchSales(undefined)
                alert.show(t('Datos no existentes'), { type: 'error' })
                setIsLoadingValidation('active')
              }
              if(result && result.message && result.message.includes('Timeout')) {
                alert.show(t('Timeout'), { type: 'info' })
                setIsLoadingValidation('active')
              }
              return data
            })
            .then(data => {
              if (data.length !== 0) {
                alert.show(t('report succesfully retrieved'), { type: 'success' })
                setIsLoadingValidation('active')
              }
            })
      } else {
          logic
            .retrieveStockReport({ selectedDistributors, dateStart, dateEnd, yearPrice, timeout: 600000 })
            .then(result => {
              const data = result && result.data
              let arrayBrand = data.map(({ brand }) => brand)
              arrayBrand = arrayFilters.arrayFilters(arrayBrand)
              let arrayGroup = data.map(({ group }) => group)
              arrayGroup = arrayFilters.arrayFilters(arrayGroup)
              let arrayBusinessUnit = data.map(({ businessunit }) => businessunit)
              arrayBusinessUnit = arrayFilters.arrayFilters(arrayBusinessUnit)
              let arraySet = data.map(({ set }) => set)
              arraySet = arrayFilters.arrayFilters(arraySet)
              setArrayBrand(arrayBrand)
              setArrayGroup(arrayGroup)
              setArrayBusinessUnit(arrayBusinessUnit)
              setArraySet(arraySet)
              setSearchSales(data)
              setData(data)
              setProgressSeconds()
              setTheProgress()
              if (data.length === 0 && result && result.message && !result.message.includes('Timeout')) {
                setSearchSales(undefined)
                alert.show(t('Datos no existentes'), { type: 'error' })
                setIsLoadingValidation('active')
              }
              if(result && result.message && result.message.includes('Timeout')) {
                alert.show(t('Timeout'), { type: 'info' })
                setIsLoadingValidation('active')
              }
              return data
            })
            .then(data => {
              if (data.length !== 0) {
                alert.show(t('report succesfully retrieved'), { type: 'success' })
                setIsLoadingValidation('active')
              }
            })
      }
    }
  }
  const handleDownloadData = () => {
    if (!yearPrice && isPVLContracted) {
      return alert.show(t('Debe tener precios para generar este reporte'), {
        type:
          'error'
      })
    }
    if(!selectedDistributors || (selectedDistributors && !selectedDistributors.length)){
      return alert.show('Por favor seleccione al menos un distribuidor', {
        type:
          'error'
      })
    } 
    if (!isDateRangeValid()) {
      setIsLoadingValidationDownload('active')
      return alert.show(t('fecha inicial debe ser anterior a fecha final'), {
        type:
          'error'
      })
    }
    if (calculateDiffTime() > 12) {
      setIsLoadingValidation('active')
      return alert.show(t('Franja de tiempo máxima de 12 meses excedida'), {
        type:
          'error'
      })
    }
    if (dateEnd && dateStart && selectedDistributors) {
      setIsLoadingValidationDownload('loading')
      setTheProgress(0)
      setProgressSeconds(1000)
      let found = false
      for (let i = 0; i < selectedDistributors.length; i++) {
        if (selectedDistributors[i].distributorname === t('Todos los distribuidores')) {
          found = true
          break
        }
      }
      if (found) {
        logic
          .downloadStockReportAllDistributors({ dateStart, dateEnd, yearPrice, isPVLContracted, timeout: 600000 })
          .then(result => {
            const data = result && result.data
            let a = document.createElement('a')
            a.href = `${logic.url}/${data.path}`
            // Give filename you wish to download
            a.download = data.name
            a.style.display = 'none'
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            setProgressSeconds()
            setTheProgress()
            if(result && result.message && result.message.includes('Timeout')) {
              alert.show(t('Timeout'), { type: 'info' })
              setIsLoadingValidation('active')
            }
          })
          .then(() => {
            alert.show(t('report succesfully retrieved'), { type: 'success' })
            setIsLoadingValidationDownload('active')
          })
      } else {
          logic
            .downloadStockReport({ selectedDistributors, dateStart, dateEnd, yearPrice, isPVLContracted,timeout: 600000 })
            .then(result => {
              const data = result && result.data
              let a = document.createElement('a')
              a.href = `${logic.url}/${data.path}`
              // Give filename you wish to download
              a.download = data.name
              a.style.display = 'none'
              document.body.appendChild(a)
              a.click()
              document.body.removeChild(a)
              setProgressSeconds()
              setTheProgress()
              if(result && result.message && result.message.includes('Timeout')) {
                alert.show(t('Timeout'), { type: 'info' })
                setIsLoadingValidation('active')
              }
            })
            .then(() => {
              alert.show(t('report succesfully retrieved'), { type: 'success' })
              setIsLoadingValidationDownload('active')
            })
      }
    }
  }

  const handleDownloadStockData = () => {
    setIsLoadingValidationDownload('loading')
    if(!selectedDistributors || (selectedDistributors && !selectedDistributors.length)){
      return alert.show('Por favor seleccione al menos un distribuidor', {
        type:
          'error'
      })
    }
    if (searchSales) {
      const sheet1 = searchSales.map((item) => {
        console.log(item)
        const { distributorcode: distributorCode, distributorcif: DistributorCIF, distributorname: distributorName, distributorproductname: DistributorProductName, providercode: Providercode, providerproductname: ProviderProductName, brand: Brand, businessunit: BusinessUnit, batchnumber: BatchNumber, group: Group, set: Set, coeficient: Coeficient, units, rawdata: rawData,  value: Value, date } = item

        if (isPVLContracted) {
          return {
            DistributorCode: distributorCode, DistributorCIF: DistributorCIF, distributorName: distributorName, DistributorProductName: DistributorProductName, ProviderProductName: ProviderProductName, Providercode: Providercode, Brand: Brand, BusinessUnit: BusinessUnit, Group: Group, Set: Set, Coeficient: Coeficient, units: units, RawData: rawData,  Value:  Value, Stockdate: date, BatchNumber: BatchNumber
          }
        } else {
          return {
            DistributorCode: distributorCode, DistributorCIF: DistributorCIF, distributorName: distributorName, DistributorProductName: DistributorProductName, ProviderProductName: ProviderProductName, Providercode: Providercode, Brand: Brand, BusinessUnit: BusinessUnit, Group: Group, Set: Set, Coeficient: Coeficient, units: units, RawData: rawData,  Stockdate: date, BatchNumber: BatchNumber
          }
        }
      })
      setIsLoadingValidationDownload('loading')

      if (calculateDiffTime() > 12) {
        setIsLoadingValidation('active')
        return alert.show(t('Franja de tiempo máxima de 12 meses excedida'), {
          type:
            'error'
        })
      }
      const name = 'distributorStockReport'
      logic.downloadFilteredStockReport(sheet1)
      .then(result => {
        const data = result && result.data
        let a = document.createElement('a')
        a.href = `${logic.url}/${data.path}`
        // Give filename you wish to download
        a.download = data.name
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        setIsLoadingValidationDownload('active')
      })
      .then(() => {
        setIsLoadingValidationDownload('active')
        alert.show(t('report succesfully retrieved'), { type: 'success' })
        //setIsLoadingValidationDownload('active')
      })
      .catch((error) =>{ 
        setIsLoadingValidationDownload('active')
        alert.show(t('Error no esperado'), {type : 'error'})
      })

    }
  }
  // filter selectors
  const filterSelectors = (selected = [], typeOfSelection = 'Empty', selectedItem = 'removed') => {
    let newFiltersOptionsAndSelection = {
      input: {
        typeOfSelection: typeOfSelection,
        selectedItem: selectedItem,
        selected: selected,
        allSelected: ['n/a', 'n/a', 'n/a', 'n/a', 
          selectedBrand, selectedGroup, selectedBusinessUnit, selectedSet, 'n/a', 'n/a'],
        data: data && [...data]  
      },
      output: {
        newSelections: [],
        newSearchSales: data && [...data],
        newArrays: []
      }
    }
    filtersMgmt(newFiltersOptionsAndSelection)
    
    let newSelectedBrands = [...newFiltersOptionsAndSelection.output.newSelections[4]]
    let newSelectedGroups = [...newFiltersOptionsAndSelection.output.newSelections[5]]
    let newSelectedBusinessUnits = [...newFiltersOptionsAndSelection.output.newSelections[6]]
    let newSelectedSets = [...newFiltersOptionsAndSelection.output.newSelections[7]]

    let newSearchSales = [...newFiltersOptionsAndSelection.output.newSearchSales]

    let arrayBrand = [...newFiltersOptionsAndSelection.output.newArrays[3]]
    let arrayGroup = [...newFiltersOptionsAndSelection.output.newArrays[4]]
    let arrayBusinessUnit = [...newFiltersOptionsAndSelection.output.newArrays[5]]
    let arraySet = [...newFiltersOptionsAndSelection.output.newArrays[6]]

    // When there are NO selections the options are all the possible ones
    if (!newSelectedBrands.length && !newSelectedGroups.length && 
        !newSelectedBusinessUnits.length && !newSelectedSets.length &&
        newSearchSales) {
          if (typeOfSelection !== 'Brands') {
            setArrayBrand(arrayFilters.arrayFilters(newSearchSales.map(({ brand }) => brand)))
            setSelectedBrand([])
            brandRef.current.resetSelectedValues()
          }
          if (typeOfSelection !== 'Groups') {
            setArrayGroup(arrayFilters.arrayFilters(newSearchSales.map(({ group }) => group)))
            setSelectedGroup([])
            groupRef.current.resetSelectedValues()
          }
          if (typeOfSelection !== 'BusinessUnits') {
            setArrayBusinessUnit(arrayFilters.arrayFilters(newSearchSales.map(({ businessunit }) => businessunit)))
            setSelectedBusinessUnit([])
            businessUnitRef.current.resetSelectedValues()
          }
          if (typeOfSelection !== 'Sets') {
            setArraySet(arrayFilters.arrayFilters(newSearchSales.map(({ set }) => set)))
            setSelectedSet([])
            setRef.current.resetSelectedValues()
          }
    } else { // When there are selected items we do two things: 
            // a) We create the new list of all possible selections
            // b) We update the list of selected items that could have changed depending
            //    on the new items selected
          if (newSearchSales && arrayBrand && typeOfSelection !== 'Brands') {
            setArrayBrand(arrayFilters.arrayFilters(arrayBrand.map(({ brand }) => brand)))
            setSelectedBrand(newSelectedBrands.filter(brand => arrayFilters.arrayFilters(arrayBrand.map(({ brand }) => brand)).includes(brand)))
          }
          if (newSearchSales && arrayGroup && typeOfSelection !== 'Groups') {
            setArrayGroup(arrayFilters.arrayFilters(arrayGroup.map(({ group }) => group)))
            setSelectedGroup(newSelectedGroups.filter(group => arrayFilters.arrayFilters(arrayGroup.map(({ group }) => group)).includes(group)))
          }
          if (newSearchSales && arrayBusinessUnit && typeOfSelection !== 'BusinessUnits') {
            setArrayBusinessUnit(arrayFilters.arrayFilters(arrayBusinessUnit.map(({ businessunit }) => businessunit)))
            setSelectedBusinessUnit(newSelectedBusinessUnits.filter(businessunit => arrayFilters.arrayFilters(arrayBusinessUnit.map(({ businessunit }) => businessunit)).includes(businessunit)))
          }
          if (newSearchSales && arraySet && typeOfSelection !== 'Sets') {
            setArraySet(arrayFilters.arrayFilters(arraySet.map(({ set }) => set)))
            setSelectedSet(newSelectedSets.filter(set => arrayFilters.arrayFilters(arraySet.map(({ set }) => set)).includes(set)))
          }
    }

    setSearchSales(newSearchSales)
    if (newSearchSales && newSearchSales.length === 0) {
      alert.show(t('wrong filter combination'), { type: 'error' })
    }
  }

  // reset report
  const handleReset = () => {
    setSelectedBrand([])
    setSelectedGroup([])
    setSelectedBusinessUnit([])
    setSelectedSet([])
    setSearchSales(undefined)
  }
   // reset filters
   const handleResetFilters = () => {
    setSelectedBrand([])
    setSelectedGroup([])
    setSelectedBusinessUnit([])
    setSelectedSet([])
    filterSelectors()
  }

  // brand
  const handleSelectBrand = (newSelectedBrands, selectedItem) => {
    setSelectedBrand(newSelectedBrands)
    filterSelectors(newSelectedBrands, 'Brands', selectedItem)
  }
  const handleRemoveBrand = (newSelectedBrands, selectedItem) => {
    setSelectedBrand(newSelectedBrands)
    if (!newSelectedBrands.length) {
      setSelectedBrand([])
    }
    filterSelectors(newSelectedBrands, 'Brands')
  }
  // group
  const handleSelectGroup = (newSelectedGroups, selectedItem) => {
    setSelectedGroup(newSelectedGroups)
    filterSelectors(newSelectedGroups, 'Groups', selectedItem)
  }
  const handleRemoveGroup = (newSelectedGroups, selectedItem) => {
    setSelectedGroup(newSelectedGroups)
    if (!newSelectedGroups.length) {
      setSelectedGroup([])
    }
    filterSelectors(newSelectedGroups, 'Groups')
  }
  // Business Unit
  const handleSelectBusinessUnit = (newSelectedBusinessUnits, selectedItem) => {
    setSelectedBusinessUnit(newSelectedBusinessUnits)
    filterSelectors(newSelectedBusinessUnits, 'BusinessUnits', selectedItem)
  }
  const handleRemoveBusinessUnit = (newSelectedBusinessUnits, selectedItem) => {
    setSelectedBusinessUnit(newSelectedBusinessUnits)
    if (!newSelectedBusinessUnits.length) {
      setSelectedBusinessUnit([])
    }
    filterSelectors(newSelectedBusinessUnits, 'BusinessUnits')
  }
  // set
  const handleSelectSet = (newSelectedSets, selectedItem) => {
    setSelectedSet(newSelectedSets)
    filterSelectors(newSelectedSets, 'Sets', selectedItem)
  }
  const handleRemoveSet = (newSelectedSets, selectedItem) => {
    setSelectedSet(newSelectedSets)
    if (!newSelectedSets.length) {
      setSelectedSet([])
    }
    filterSelectors(newSelectedSets, 'Sets')
  }
  
  const handleYearChange = (e) => {
    const { year } = e.target.form
    if (year.value === '') return
    setYearPrice(year.value)
  }

  function transformString(stringNumber) {
    // Split the string into integer and fractional parts
    let parts = stringNumber.split(',');

    // Separate the integer part into groups of three digits and join with points
    let integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // If there's a fractional part, concatenate it with the decimal point
    let fractionalPart = parts.length > 1 ? ',' + parts[1] : '';

    // Concatenate integer and fractional parts
    return integerPart + fractionalPart;
  }

  return (
    <Box id='salesReports'>
      <TabsSalesStock highlight='distributor stock' />
      {!searchSales && <Level>
        <Level.Item align='left'>
          <Select.Container color='dark'>
            <form id="Form1" runat="server" autocomplete="off">
              <Multiselect selectedValues={selectedDistributors} placeholder={t('Seleccionar Distribuidores')} options={distributors} displayValue='distributorname' onSelect={handleSelectDistributor} onRemove={handleRemoveDistributor} className='multiselectcontainer' style={styleSelectorDistributors} avoidHighlightFirstOption />
            </form>
          </Select.Container>
        </Level.Item>
        {isPVLContracted &&
          <Level.Item>
            <form>
              <Title size={5}>{t('year prices')}:</Title>
              <Select.Container>
                <Select name='year' onChange={handleYearChange} defaultValue='' value={yearPrice && yearPrice}>
                  <Select.Option value=''>
                    {t('select')} {t('year')}
                  </Select.Option>
                  {years && years.map((year, index) => {
                    return (
                      <Select.Option key={year} value={year}>
                        {year}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Select.Container>
            </form>
          </Level.Item>
         }
        <Level.Item>
          <div>
            <Title size={5}>{t('Desde')}:</Title>
            <DatePicker
              showPopperArrow={false}
              dateFormat='MM/yyyy'
              showMonthYearPicker
              selected={dateStart}
              onChange={handleChangeDateStart}
            />
          </div>
        </Level.Item>
        <Level.Item>
          <div>
            <Title size={5}>{t('Hasta')}:</Title>
            <DatePicker
              dateFormat='MM/yyyy'
              showMonthYearPicker
              selected={dateEnd}
              onChange={handleChangeDateEnd}
            />
          </div>
        </Level.Item>
      </Level>}
      {!searchSales && <Level>
        <Level.Item>
          <Button.Group>
            <Button onClick={handleRetrieveStockData} color='primary' state={isLoadingValidation}>
              {t('Generar Informe')}
            </Button>
              <Button onClick={handleDownloadData} color='warning' state={isLoadingValidationDownload}>
              {t('Descargar Informe')}
            </Button> 
          </Button.Group>
        </Level.Item>
      </Level>}
      {searchSales && 
        <Level>
          <Level.Item>
              <Title size={5}>{t('Desde')}:</Title>&nbsp;
              <DatePicker
                showPopperArrow={false}
                dateFormat='MM/yyyy'
                selected={dateStart}
                disabled
              />
          </Level.Item>
          <Level.Item>
              <Title size={5}>{t('Hasta')}:</Title>&nbsp;
              <DatePicker
                dateFormat='MM/yyyy'
                selected={dateEnd}
                disabled
              />
            <br></br>
          </Level.Item>
        </Level>
      }
      <Level>
        {searchSales && searchSales.length > 0 && <Level.Item>
          <Button.Group>
            <Button onClick={handleReset} color='primary'>
              {t('Generar Informe Nuevo')}
            </Button>
            <Button onClick={handleDownloadStockData} color='success' state={isLoadingValidationDownload}>
              <span>
                {t('Descargar Informe')}
              </span>
              <Icon>
                <FontAwesomeIcon icon={['fas', 'cloud-download-alt']} />
              </Icon>
            </Button>
            <Button onClick={handleResetFilters} color='warning'>
              <span>
                {t('Restablecer filtros')}
              </span>
              <Icon>
                <FontAwesomeIcon icon={faFilter} />
              </Icon>
            </Button>
          </Button.Group>
        </Level.Item>}
      </Level>
      {theProgress > 0 &&
        <div>
          <Level>
            <Level.Item>
              <div style={{ width: 325 }}>
               <Progress size="small" color="primary"/>
              </div>
              <p>&nbsp;<strong>{t('In progress')} {`${Math.trunc(theProgress / 60).toString().padStart(2, '0')}:${Math.trunc(theProgress % 60).toString().padStart(2, '0')}`} min</strong></p>
            </Level.Item>  
          </Level>
        </div>
      }
      <form id="Form1" runat="server" autocomplete="off">
        <Level>
          {searchSales && searchSales.length > 0 && <Level.Item>
            <Select.Container color='dark'>
              <Multiselect closeOnSelect={false} placeholder={t('Marcas')} ref={brandRef} selectedValues={selectedBrand} options={arrayBrand} isObject={false} className='multiselectcontainerFilter' style={styleSelectorFilter} onSelect={handleSelectBrand} onRemove={handleRemoveBrand} avoidHighlightFirstOption />
            </Select.Container>
            <Select.Container color='dark'>
              <Multiselect closeOnSelect={false} placeholder={t('Grupos')} ref={groupRef} selectedValues={selectedGroup} options={arrayGroup} isObject={false} className='multiselectcontainerFilter' style={styleSelectorFilter} onSelect={handleSelectGroup} onRemove={handleRemoveGroup} avoidHighlightFirstOption />
            </Select.Container>
            <Select.Container color='dark'>
              <Multiselect closeOnSelect={false} placeholder={t('Business Unit')} ref={businessUnitRef} selectedValues={selectedBusinessUnit} options={arrayBusinessUnit} isObject={false} className='multiselectcontainerFilter' style={styleSelectorFilter} onSelect={handleSelectBusinessUnit} onRemove={handleRemoveBusinessUnit} avoidHighlightFirstOption />
            </Select.Container>
            <Select.Container color='dark'>
              <Multiselect closeOnSelect={false} placeholder={t('Conjuntos')} ref={setRef} selectedValues={selectedSet} options={arraySet} isObject={false} className='multiselectcontainerFilter' style={styleSelectorFilter} onSelect={handleSelectSet} onRemove={handleRemoveSet} avoidHighlightFirstOption />
            </Select.Container>
          </Level.Item>}
        </Level>
      </form>
      <br />
      <br />
      {searchSales && searchSales.length > 0 && <Table fullwidth narrow hoverable bordered striped>
        <Table.Head>
          <Table.Row>
            <Table.Heading>{t('Distributor Code')}</Table.Heading>
            <Table.Heading>{t('CIF')}</Table.Heading>
            <Table.Heading>{t('Distributor')}</Table.Heading>
            <Table.Heading>{t('Provider code')}</Table.Heading>
            <Table.Heading>{t('Provider product name')}</Table.Heading>
            <Table.Heading>{t('Brand')}</Table.Heading>
            <Table.Heading>{t('Bussiness Unit')}</Table.Heading>
            <Table.Heading>{t('lotCode')}</Table.Heading>
            <Table.Heading>{t('Group')}</Table.Heading>
            <Table.Heading>{t('Set')}</Table.Heading>
            <Table.Heading>{t('Raw Data')}</Table.Heading>
            {isPVLContracted && <Table.Heading>{t('Value')}</Table.Heading>}
            <Table.Heading>{t('Stock date')}</Table.Heading>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {searchSales &&
            searchSales.slice(startPosition, endPosition).map((item, index) => {
              return (
                <Table.Row key={index}>
                  <Table.Cell>{item.distributorcode}</Table.Cell>
                  <Table.Cell>{item.distributorcif}</Table.Cell>
                  <Table.Cell>{item.distributorname}</Table.Cell>
                  <Table.Cell>{item.providercode}</Table.Cell>
                  <Table.Cell>{item.providerproductname}</Table.Cell>
                  <Table.Cell>{item.brand}</Table.Cell>
                  <Table.Cell>{item.businessunit}</Table.Cell>
                  <Table.Cell>{item.lotcode}</Table.Cell>
                  <Table.Cell>{item.group}</Table.Cell>
                  <Table.Cell>{item.set}</Table.Cell>
                  <Table.Cell>{item.rawdata}</Table.Cell>
                  {isPVLContracted && <Table.Cell className="right">{item.value !== null ? transformString(item.value) : item.value}</Table.Cell>}
                  <Table.Cell>{item.date}</Table.Cell>
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table>}
      {searchSales && searchSales.length > 0 && <Pagination
        totalItems={searchSales && searchSales.length}
        ref={paginationRef}
        requestPagination={handleRequestPagination}
      />}
    </Box>
  )
}

