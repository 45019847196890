import React from 'react'
import './Legal.scss'
import {
  Image
} from 'rbx'
import AnimalDataSourceLogo from '../../config/images/ADS_fondoblanco.png'

export const TerminosCondiciones = () => (
  <>
  <div id="history" className="scroll-area">
    <Image.Container>
        <Image className='logo' src={AnimalDataSourceLogo} />
    </Image.Container>
    <p>
    <br></br>  
      <strong>TÉRMINOS Y CONDICIONES</strong>
    </p>
    <p>
    Estos Términos y Condiciones (en adelante, T&C) establecen los términos y 
    condiciones conforme a los cuales Ambit Building Solutions Together S.A. 
    (en adelante, AMBIT BST) presta los servicios del software Animal Data Source 
    a los usuarios y regula la forma en la que los usuarios pueden acceder y 
    utilizar el servicio.
    </p>
    <p>
      <strong><em>1.	Definiciones: </em></strong>
      1.1.	Software o plataforma. Conjunto de servicios prestados a través de Animal Data Source, accessible a través de la URL www.animaldatasource.com 
      <br/>
      1.2.	Cliente. Se entiende por Cliente la persona o sociedad que ha celebrado un contrato o acuerdo con AMBIT BST para la prestación de servicios del software Animal Data Source.
      <br/>
      1.3.	Distribuidor. La persona o sociedad que distribuye los productos del Cliente.
      <br/>
      1.4.	Usuario Autorizado. Cualquier persona a la que el Cliente concede autorización de acceso para usar el software y que es un empleado, agente, contratista, distribuidor o representante del Cliente.
      <br/>
      1.5.	Usuario Distribuidor. Usuario Autorizado que pertenece al Distribuidor.
      <br/>
      1.6.	Contrato. La totalidad del acuerdo celebrado entre el Cliente y AMBIT BST.
      <br/>
      1.7.	Datos del Cliente. Son Datos del Cliente los datos contenidos en los informes que recibe a través del software Animal Data Source. 
      <br/>
      1.8.	Datos del Distribuidor. La información que los Usuarios Distribuidores introducen en el software Animal Data Source, esto incluye los datos que se detallan en la cláusula 6.
    </p>
    <p>
      <strong><em>2.	Aceptación de los Términos y Condiciones:</em></strong>
      <em />
    </p>
    <p>
      <em>
      2.1.  Acceso. El acceso y uso de Animal Data Source está sujeto a los términos y condiciones de uso, incluyendo otros avisos legales, políticas de privacidad o términos que sean de aplicación al utilizar el software y que estén disponibles en la página web de acceso.
      <br/>
      2.2. Consentimiento. Al aceptar los términos y condiciones de uso, el usuario está demostrando su consentimiento. En caso de no estar de acuerdo con los mismos deberá abstenerse de utilizar el software.
      <br/>
      2.3. Modificaciones de los Términos y Condiciones. Las modificaciones de los presentes términos y condiciones serán notificadas al usuario antes de producir efectos. Las modificaciones no podrán tener carácter retroactivo. En caso de estar en desacuerdo con las modificaciones en los términos y condiciones, los usuarios deberán abstenerse de utilizar el software.
      <br/>
      2.4. Prevalencia de acuerdos negociados individualmente. Si existe conflicto entre estos términos y condiciones de uso y cualesquiera otros términos o acuerdos negociados individualmente, prevalecerán los términos o acuerdos negociados individualmente.
      </em>
    </p>
    <p>
      <strong><em>3.	Derechos de uso y limitaciones:</em></strong>
      <em />
    </p>
    <p>
      <em>
      3.1.	Concesión de derechos. AMBIT BST garantiza al Cliente un derecho no exclusivo e intransferible para utilizar el Software. 
      <br/>
      3.2.	Usuarios autorizados. El Cliente puede permitir usar el software a los Usuarios Autorizados, incluyendo dentro de estos a los Usuarios Distribuidores. Este uso se limitará al número de usuarios especificados en el contrato, acuerdo o encargo celebrado entre el Cliente y AMBIT BST.
      <br/>
      3.3.	Titularidad del Software. Ambit BST conserva todos sus derechos, títulos e intereses en el Software. El Cliente no posee ni ha adquirido ningún derecho de propiedad sobre el Software o ninguna patente, copyright, marca u otra forma de propiedad intelectual e industrial relacionada. 
      </em>
    </p>
    <p>
      <strong><em>4.	Uso permitido del software :</em></strong>
      <em />
    </p>
    <p>
      <em>
      4.1.	Registro previo. El acceso al software no es libre, requiere del registro previo del usuario.
      <br/>
      4.2.	Buena fe. El uso del software deberá hacerse de forma responsable y de conformidad a la legalidad vigente, la buena fe, los presentes términos y condiciones y respetando los derechos de propiedad intelectual e industrial de Ambit BST o de cualquier tercero.
      <br/>
      4.3.	Prohibiciones. El usuario no deberá:
      i.	copiar, modificar, adaptar, traducir a ningún idioma, distribuir o crear trabajos derivados basados en el Software o cualquiera de los Servicios; 
      <br/>
      ii.	dañar, deshabilitar, sobrecargar o perjudicar ningún servidor de Ambit BST, o las redes conectadas a cualquier servidor de Ambit BST
      <br/>
      iii.	obtener acceso no autorizado a sitios web de Ambit BST, a otras cuentas, a sistemas informáticos o a redes conectadas a servidores de Ambit BST
      <br/>
      iv.	sublicenciar, subarrendar, arrendar, prestar, asignar, vender, licenciar, distribuir, alquilar, exportar, reexportar u otorgar otros derechos sobre el Software o Servicio y cualquier intento por su parte de tomar tal acción será nulo. Todo ello sin perjuicio del uso que puedan hacer los Usuarios Autorizados; 
      <br/>
      v.	descompilar, desensamblar, realizar ingeniería inversa o intentar reconstruir, identificar o descubrir cualquier código fuente, ideas subyacentes, técnicas de interfaz de usuario subyacentes o algoritmos del Software o de cualquiera de los Servicios por cualquier medio; 
      <br/>
      vi.	eliminar, ocultar o alterar los derechos de autor y / u otros avisos de propiedad contenidos o relacionados con el Software o cualquiera de los Servicios; 
      <br/>
      vii.	utilizar el Software o cualquiera de los Servicios para crear o propagar un virus o para eludir cualquier protección de derechos de autor u otro mecanismo de gestión de derechos digitales;
      <br/>
      viii.	utilizar el Software o cualquiera de los Servicios para ningún propósito ilegal o no autorizado;
      <br/>
      ix.	autorizar pruebas de penetración o cualquier otro tipo de pruebas de vulnerabilidad del software Animal Data Source sin la autorización previa de AMBIT BST.
      <br/>
      4.4.	Uso del software por Usuarios Distribuidores. En el caso de los Usuarios Distribuidores que forman parte de empresas distribuidoras de productos del Cliente, se informa expresamente que el acceso al software no supone, en modo alguno, el inicio de una relación comercial con Ambit BST. El acceso de estos Usuarios Distribuidores queda supeditado a la relación comercial entre el Cliente y Ambit BST.
      </em>
    </p>
    <p>
      <strong><em>5.	Responsabilidades de Ambit BST:</em></strong>
      <em />
    </p>
    <p>
      <em>
      5.1.	Suministro. Ambit BST proporciona acceso a Animal Data Source de conformidad con lo descrito en el Contrato.
      <br/>
      5.2.	Soporte. Ambit BST presta soporte para los Servicios a través de AMBIT OS.
      <br/>
      5.3.	Seguridad. Ambit BST implementará y aplicará las medidas técnicas y organizativas adecuadas para proteger los datos tratados.
      <br/>
      5.4.	Modificaciones y actualizaciones. Las modificaciones del Software se notificarán al Cliente con una antelación adecuada. Las modificaciones y actualizaciones pueden incluir la eliminación de funcionalidades del Software cuando se proporcione un equivalente funcional o no reduzca sustancialmente la funcionalidad clave del Software. Las nuevas funcionalidades que vayan más allá del alcance inicial del Software puede estar sujeta a términos y condiciones adicionales, incluyendo el pago de cantidades adicionales.
      </em>
    </p>
    <p>
      <strong><em>6.	Datos de Distribuidores:</em></strong>
      <em />
    </p>
    <p>
      <em>
      6.1.	Origen de los Datos. El Distribuidor tiene, en los casos que sea necesario, la autorización de sus clientes finales para ceder la información a Ambit BST y al Cliente. La obtención de consentimiento de los clientes finales es necesaria únicamente si se trata de datos de carácter personal, por tanto, solo será necesario en caso de que se trate de una persona física. En este caso, será necesario obtener el consentimiento explícito del cliente final mediante firma de una carta o acuerdo. 
      <br/>
      6.2.	Información compartida. El Usuario Distribuidor solo proveerá la información depurada y únicamente:
      <br/>
      Ventas de Distribuidor (en unidades):
      <br/>
      •	Código de Distribuidor
      <br/>
      •	CIF del Distribuidor
      <br/>
      •	Nombre del Distribuidor
      <br/>
      •	Código portal de la venta
      <br/>
      •	País
      <br/>
      •	Comunidad
      <br/>
      •	Provincia
      <br/>
      •	Población
      <br/>
      •	Nombre del producto del Distribuidor
      <br/>
      •	Código del producto del Fabricante
      <br/>
      •	Nombre del producto del Fabricante
      <br/>
      •	Marca del producto del Fabricante
      <br/>
      •	Unidad de negocio del producto del Fabricante
      <br/>
      •	Grupo del producto del fabricante
      <br/>
      •	Conjunto del producto del fabricante
      <br/>
      •	Factor de correspondencia del producto del Distribuidor con el producto del Fabricante
      <br/>
      •	Unidades vendidas con coeficiente de correspondencia aplicado
      <br/>
      •	Unidades vendidas sin coeficiente de correspondencia aplicado
      <br/>
      •	Fecha
      <br/>
      Stocks en unidades (Opcional):
      <br/>
      •	Código de Distribuidor
      <br/>
      •	CIF del Distribuidor
      <br/>
      •	Nombre del Distribuidor
      <br/>
      •	Nombre del producto del Distribuidor
      <br/>
      •	Código del producto del Fabricante
      <br/>
      •	Nombre del producto del Fabricante
      <br/>
      •	Marca del producto del Fabricante
      <br/>
      •	Unidad de negocio del producto del Fabricante
      <br/>
      •	Grupo del producto del fabricante
      <br/>•	Conjunto del producto del fabricante
      •	Factor de correspondencia del producto del Distribuidor con el producto del Fabricante
      <br/>
      •	Unidades con coeficiente de correspondencia aplicado
      <br/>
      •	Unidades sin coeficiente de correspondencia aplicado
      <br/>
      •	Fecha
      <br/>
      •	Número de lote
      <br/>
      Ventas de Cliente final (en unidades):
      <br/>
      •	CIF del cliente (puede ser anónimo)
      <br/>
      •	Código portal de la venta
      <br/>
      •	País
      <br/>
      •	Comunidad
      <br/>
      •	Provincia
      <br/>
      •	Población
      <br/>
      •	Código Postal
      <br/>
      •	Código del producto del Fabricante
      <br/>
      •	Nombre del producto del Fabricante
      <br/>
      •	Marca del producto del Fabricante
      <br/>
      •	Unidad de negocio del producto del Fabricante
      <br/>
      •	Grupo del producto del fabricante
      <br/>
      •	Conjunto del producto del fabricante
      <br/>
      •	Factor de correspondencia del producto del Distribuidor con el producto del Fabricante
      <br/>
      •	Unidades vendidas con coeficiente de correspondencia aplicada
      <br/>
      6.3.	Procesamiento de Información. El Usuario Distribuidor tendrá acceso autónomo a Animal Data Source para cargar la información acordada durante los días 1 y 10 de cada mes. Una vez subida la información, la aplicación realizará una comprobación de errores automática para verificar los datos y realizar correcciones. Una vez finalizada la comprobación de errores, la información queda disponible para la explotación por parte de AMBIT BST y el Cliente. 
      <br/>
      6.4.	Problemas en la carga de la información. En caso de que hubiera algún problema con la carga de información, el Usuario Distribuidor contactará exclusivamente con el servicio de soporte de AMBIT OS a fin de cargar de forma satisfactoria los datos, absteniéndose de enviar la información al Cliente por otras vías.
      <br/>
      6.5.	Información Real y Veraz. El Usuario Distribuidor garantiza que no será facilitada la información al Cliente, excepto lo detallado en este acuerdo y a través de la plataforma Animal Data Source. Así también el Usuario Distribuidor se compromete, únicamente a proveer información real y veraz. El Usuario Distribuidor exime de cualquier responsabilidad, directa o indirecta, que pueda tener causa o se derive de cualquier incumplimiento descrito en el presente párrafo.
      <br/>
      6.6.	Cesión de Datos. El Usuario final cede la información compartida en la aplicación Animal Data Source a Ambit BST y al Cliente para su uso de conformidad a lo establecido en estos Términos y Condiciones.
      <br/>
      6.7.	Responsabilidad. El contenido transmitido mediante el uso del Software es responsabilidad exclusiva de la persona que originó dicho contenido. 
      </em>
    </p>
    <p>
      <strong><em>7.	Datos de Clientes:</em></strong>
      <em />
    </p>
    <p>
      <em>
      7.1.	Propiedad del Cliente. El Cliente conserva todos los derechos de y en relación con los Datos de Cliente.
      <br/>
      7.2.	Acceso a los Datos de Cliente. Durante el plazo de prestación de servicios, el Cliente puede acceder a sus Datos de Cliente en cualquier momento. El Cliente puede exportar sus Datos de Cliente en un formato estándar. Antes de que venza el Plazo de Prestación de Servicios, el Cliente puede exportar los Datos de Cliente, desde el Software.
      <br/>
      7.3.	Eliminación de los Datos de Cliente. Cuando termine el Contrato, Ambit BST eliminará los Datos de Cliente que permanezcan el Software, salvo que la ley aplicable exija la conservación de dichos datos. Los datos que se conserven estarán sujetos a las disposiciones de confidencialidad estipuladas en el Contrato.
      <br/>
      7.4.	Responsabilidad de los Datos de Cliente. El contenido transmitido mediante el uso del Software es responsabilidad exclusiva de la persona que originó dicho contenido. 
      </em>
    </p>
    <p>
      <strong><em>8.	Uso de la información por parte de Ambit BST:</em></strong>
      <em />
    </p>
    <p>
      <em>
      8.1.	Procesamiento de la información. Ambit BST procesa los Datos de Distribuidor que carga a la plataforma a fin de prestar el servicio al Cliente. Los Datos de Cliente son los informes obtenidos del procesamiento de la información por parte de Ambit BST. El Cliente recibe información de distintos distribuidores por lo que puede en todo caso disponer de información de unidades compradas agregadas de los diferentes Distribuidores mostradas como unidades compradas por cliente final. En este caso, Ambit BST garantiza que no es posible trazar la información de referencia de origen de los distintos Distribuidores con las compras realizadas por el cliente final. Se garantiza el anonimato del origen de compra del cliente final.
      <br/>
      8.2.	Información. Ambit BST garantiza que no facilitará ninguna otra información al Cliente que la provista por los Usuarios Distribuidores a través de la aplicación de Animal Data Source.
      <br/>
      8.3.	Análisis. Ambit BST puede utilizar información anónima relacionada con el uso del Software para preparar análisis. Los análisis no contienen Información Confidencial del Cliente. Algunos ejemplos de análisis son: optimización de recursos y soporte, investigación y desarrollo, verificación de la seguridad y la integridad de los datos, planificación de la demanda interna, desarrollos sectoriales y comparaciones anónimas con otros Clientes. 
      <br/>
      8.4.	Estudios de mercado. Ambit BST puede utilizar la información cedida por los Usuarios Distribuidores de forma agregada y anonimizada para realizar estudios de mercado susceptibles de ser comercializados.
      </em>
    </p>
    <p>
      <strong><em>9.	Confidencialidad:</em></strong>
      <em />
    </p>
    <p>
      <em>
      9.1. Definición de Información Confidencial. De modo enunciativo y no limitativo, se entiende toda aquella información, oral o escrita, comunicada por la Parte reveladora a la Parte Receptora con objeto del uso de Animal Data Source, incluyendo entre otras, información de carácter técnico, ventas, financiero, industrial, legal, fiscal y comercial. No se incluye en la Información Confidencial los Datos de Distribuidores descritos en el apartado 6 de acuerdo al procedimiento expresado en el apartado 8.
      <br/>
      9.2.	Uso de la Información Confidencial. La parte receptora protegerá toda la Información Confidencial de la parte reveladora como estrictamente confidencial, en la misma medida en que protegería su propia Información Confidencial y, como mínimo, en base a un estándar de protección razonable. La parte receptora no divulgará ninguna Información Confidencial de la parte reveladora a nadie que no sea su personal, los representantes o los Usuarios Autorizados cuyo acceso sea necesario para permitir ejercer sus derechos o ejecutar sus obligaciones de conformidad con el Contrato. El Cliente no revelará a terceros este Contrato ni los precios. En caso de procedimientos legales relacionados con la Información Confidencial, la parte receptora cooperará con la parte reveladora y cumplirá la ley aplicable (a cargo de la parte reveladora) en relación a la gestión de la Información Confidencial.
      <br/>
      9.3.	Excepciones. Las restricciones sobre el uso o la divulgación de la Información Confidencial no se aplicarán a ninguna Información Confidencial que: 
      <br/>
      i.	la parte receptora haya desarrollado de forma independiente sin referencia a la Información Confidencial de la parte reveladora, 
      <br/>
      ii.	esté a disposición general del público sin que la parte receptora incurra en un incumplimiento del Contrato, 
      <br/>
      iii.	en el momento de la divulgación, fuese conocida para la parte receptora sin restricciones de confidencialidad, o
      <br/>
      iv.	la parte reveladora haya aceptado por escrito como libre de restricciones de confidencialidad.
      </em>
    </p>
    <p>
      <strong><em>10.	Protección de datos personales:</em></strong>
      <em />
    </p>
    <p>
      <em>
      10.1.	Política de privacidad. Los datos personales de los usuarios serán tratados de conformidad con la política de privacidad de Animal Data Source, que puede consultarse a través de este enlace https://www.animaldatasource.com/privacity 
      <br/>
      10.2.	Encargo del tratamiento. El Cliente y AMBIT BST establecerán las condiciones para el tratamiento de datos conforme al encargo realizado. En caso de no haber formalizado el correspondiente contrato, puede solicitarlo a la dirección dpo@ambit-bst.com 
      </em>
    </p>
    <p>
      <strong><em>11.	Derechos de Propiedad Intelectual:</em></strong>
      <em />
    </p>
    <p>
      <em>
      11.1.	Propiedad de AMBIT BST. Todos los derechos de propiedad intelectual sobre el Software y los Servicios, incluida la documentación relacionada y las nuevas versiones, modificaciones y mejoras correspondientes, pertenecen exclusivamente a AMBIT BST. 
      <br/>
      11.2.	Licenciamiento. El Software se distribuye bajo licencia, por lo que no hay transmisión de la propiedad. No se otorga ninguna licencia, derecho o interés implícito sobre ningún derecho de autor, patente, secreto comercial, marca registrada, invención u otro derecho de propiedad intelectual. Por la presente, AMBIT BST se reserva expresamente todos los derechos sobre el Software y todos los Servicios que no se le otorguen expresamente al Cliente.
      </em>
    </p>
    <p>
      <strong><em>12.	 Disposiciones generales:</em></strong>
      <em />
    </p>
    <p>
      <em>
      12.1.	Limitación de responsabilidad. Salvo que alguna disposición del presente indique lo contrario — a excepción (i) de daños derivados del uso no autorizado o de la divulgación de la Información Confidencial (incluidos los Datos del Cliente), o (ii) de daños resultantes de la muerte o graves lesiones corporales debidos a imprudencia grave o dolo por cualquiera de las partes, o (iii) del derecho de AMBIT BST a cobrar las cuotas / el precio — bajo ninguna circunstancia e independientemente de la naturaleza de la reclamación, ninguna de las partes  serán responsables entre ellas, o ante otra persona o entidad: por una cantidad de daños que supere las cuotas pagadas por los Servicios en causa, de conformidad con el respectivo contrato, acuerdo o encargo; o en el caso de Servicios basados en suscripción o de Servicios con tarifas recurrentes de forma mensual, las cuotas pagadas en el período de doce (12) meses previo a la fecha del incidente que dé lugar a la responsabilidad; o por cualquier daño especial, fortuito, consecuente o indirecto, pérdida de honorabilidad, interrupción del trabajo, o cualquier tipo de lucro cesante, o por daños ejemplares o punitivos
      <br/>
      12.2.	Integridad. Si cualquier disposición de los Términos y Condiciones se considera inválida o no aplicable, la invalidez o no aplicabilidad no afectará al resto de disposiciones de los Términos y Condiciones.
      <br/>
      12.3.	Irrenunciabilidad. La renuncia o la no ejecución de cualquier derecho a ser resarcido por un incumplimiento del Contrato u obligación conforme al mismo no se considera una renuncia con respecto a cualquier otro incumplimiento u obligación.
      <br/>
      12.4.	Fuerza mayor. Ningún retraso en la prestación (salvo en relación con el pago de importes debidos), provocado por condiciones que vayan más allá del control razonable de la parte ejecutante, se considerará un incumplimiento del presente Contrato. La duración de la prestación se ampliará por un período de tiempo equivalente a la duración de las condiciones que impidan la prestación.
      <br/>
      12.5.	Legislación aplicable. Los presentes términos y condiciones se interpretan de acuerdo con las leyes de España.
      <br/>
      12.6.	Jurisdicción competente. Las Partes acuerdan someterse expresamente a los Juzgados y Tribunales de la ciudad de Barcelona para la resolución de cualquier disputa y / o controversia que pueda surgir en relación con la relación entre las partes.
      </em>
    </p>

  </div>
  </>
)
