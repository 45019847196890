import React from 'react'
import {Form, InputNumber, Input} from 'antd'
import { CURRENCY_FORMAT } from '../../../../config/config'

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

export const EditableFormRow = Form.create()(EditableRow);

export class EditableCell extends React.Component {
  state = {
    editing: false
  }

  toggleEdit = () => {
    const { isTotal, isCurrentMonthProtected } = this.props.record
    const dataIndex = this.props.dataIndex
    if (isTotal || isCurrentMonthProtected) return // guard clause: when you clicked on a editable column that is a total can't be edited
    const editing = !this.state.editing
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus()
      }
    })
  }

  save = e => {
    const { record, handleSave, dataIndex } = this.props
    if (dataIndex === 'distributor_agreement_code') {
      this.form.validateFields((error, values) => {
         this.toggleEdit()
         handleSave({ ...record, ...values })
       })
       return
    }
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return
      } 
      let pureValue = [...values[Object.keys(values)[0]]]
      pureValue = [...values[Object.keys(values)[0]]].map(character => {
        if (character !== ' ') return character
      }).join('').trim()
      this.toggleEdit()
      const formattedValues = {...values}
      formattedValues[Object.keys(values)[0]] = CURRENCY_FORMAT.format(pureValue.replace(',','.'))
      handleSave({ ...record, ...formattedValues })
    })
  }

  validImport = (rule, value, callback) => {
    const checkValue = value.replace('.',',').trim()
    if (RegExp(/^(-?\d+(\,\d{0,2})?|\,?\d{1,2})$/).test(checkValue) && 
         (parseFloat(checkValue) > 9999999 ||
          parseFloat(checkValue) < -9999999)) {
          this.props.record.areThereErrors = true
          callback('Value out of range / Valor fuera de rango')
    }
    if (value && this.props.record.objData.valuetype === 'euros' && !RegExp(/^(-?\d+(\,\d{0,2})?|\,?\d{1,2})$/).test(checkValue)) {
      this.props.record.areThereErrors = true
        callback('Euro format not valid / Formato euro no válido')
    } else if (value && this.props.record.objData.valuetype !== 'euros' && !RegExp(/^(-?\d+|-?\d+(\,[0][0])|-?\d+(\,[0]))$/).test(checkValue)) { 
        this.props.record.areThereErrors = true
        callback('Format not valid / Formato no válido')
    } else {
        this.props.record.areThereErrors = false
        callback()
    }
  }

  validCode = (rule, value, callback) => {
    if (value.length > 15) {
      this.props.record.areThereErrors = true
      callback('Max length = 15 / Máxima longitud = 15')
    } else {
      this.props.record.areThereErrors = false
      callback()
    }
  }

  renderCell = form => {
    this.form = form
    const { children, dataIndex, record, title } = this.props
   
    const { editing } = this.state
    if (dataIndex === 'distributor_agreement_code') {
      return editing ? (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: false,
                type: 'string',
                max: 15
              },
              {
                validator: this.validCode
              }
            ],
            initialValue: record[dataIndex],
          })(<Input style={{ textAlign: 'left' }} ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
        </Form.Item>
      ) : (
        <div
          className='editable-cell-value-wrap'
          style={{ textAlign: 'left', minHeight: 32 }}
          onClick={this.toggleEdit}
        >
          {children}      
        </div>
      )
    }
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: "Please, enter a valid value / Por favor, entre un valor válido.",
              type: 'string',
            },
            {
              validator: this.validImport
            }
          ],
          initialValue: record[dataIndex].replace('.','').replace('.','').replace('.','').replace('.',''),
        })(<Input style={{ textAlign: 'right' }} ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
      </Form.Item>
    )
    : (
      <div
        className='editable-cell-value-wrap'
        style={{ textAlign: 'right', minHeight: 32 }}
        onClick={this.toggleEdit}
      >
        {children}      
      </div>
    )
  }

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}