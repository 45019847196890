/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState, useContext } from 'react'
import { Box, Title, Field, Label, Level, Icon, Column, Table } from 'rbx'
import { Modal, Icon as IconAntd } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11'
import { Controller } from 'react-hook-form'
import { TabsObjectives} from '../../tabsObjectives'
import ReactSelect from 'react-select'
import { Pagination } from '../../../pagination'
import { AdsContext } from "../../../../contexts/adsContext";

export const AddEditObjProducts = () => {

  const { t } = useTranslation()
  const { id } = useParams()
  const { control } = useForm()
  const [productsOptions, setProductsOptions] = useState([])
  const [busUnitsOptions, setBusUnitsOptions] = useState([])
  const [brandsOptions, setBrandsOptions] = useState([])
  const [groupsOptions, setGroupsOptions] = useState([])
  const [setsOptions, setSetsOptions] = useState([])
  // State refering to the pagination attributes comming from the pagination component
  const defaultPaginationStartPosition = 0
  const defaultPaginationEndPosition = 10
  const [startPositionBusUnits, setStartPositionBusUnits] = useState(defaultPaginationStartPosition)
  const [endPositionBusUnits, setEndPositionBusUnits] = useState(defaultPaginationEndPosition)
  const [startPositionBrands, setStartPositionBrands] = useState(defaultPaginationStartPosition)
  const [endPositionBrands, setEndPositionBrands] = useState(defaultPaginationEndPosition)
  const [startPositionGroups, setStartPositionGroups] = useState(defaultPaginationStartPosition)
  const [endPositionGroups, setEndPositionGroups] = useState(defaultPaginationEndPosition)
  const [startPositionSets, setStartPositionSets] = useState(defaultPaginationStartPosition)
  const [endPositionSets, setEndPositionSets] = useState(defaultPaginationEndPosition)
  const [startPositionProducts, setStartPositionProducts] = useState(defaultPaginationStartPosition)
  const [endPositionProducts, setEndPositionProducts] = useState(defaultPaginationEndPosition)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [selectedBusUnits, setSelectedBusUnits] = useState([])
  const [selectedBrands, setSelectedBrands] = useState([])
  const [selectedGroups, setSelectedGroups] = useState([])
  const [selectedSets, setSelectedSets] = useState([])
  const { fetchAllTenantProductsIds,
              sharedObjective, setSharedObjective,
              sharedObjDisabled,
              productsCheckedBoxes, setProductsCheckedBoxes,
              boldProducts, setBoldProducts,
              boldBusUnits, setBoldBusUnits,
              boldBrands, setBoldBrands,
              boldGroups, setBoldGroups,
              boldSets, setBoldSets } = useContext(AdsContext)
  const [onScreenCheckedBoxes, setOnScreenCheckedBoxes] = useState()
  const [businessUnits, setBusinessUnits] = useState()
  const [brands, setBrands] = useState()
  const [groups, setGroups] = useState()
  const [sets, setSets] = useState()
  const [products, setProducts] = useState()
  const [totalProds, setTotalProds] = useState(0)
  const [totalSelProds, setTotalSelProds] = useState(0)
  
  useEffect(() => {
    (async function () {

      setOnScreenCheckedBoxes({...productsCheckedBoxes})
      // 1st) We load all active tenant's products, business units, brands, groups and sets.
      const allProducts = await fetchAllTenantProductsIds()
      setTotalProds(allProducts.length)
      const [allBusinessUnits, allBrands, allGroups, allSets] =  createDistinctArrays(allProducts)
      setBusinessUnits([...allBusinessUnits].sort((a, b) => {
        if (a.businessunit.toLowerCase() < b.businessunit.toLowerCase()) {
          return -1
        }
        if (a.businessunit.toLowerCase() > b.businessunit.toLowerCase()) {
         return 1
        }
        return 0
      }))
      setBrands([...allBrands].sort((a, b) => {
        if (a.brand.toLowerCase() < b.brand.toLowerCase()) {
          return -1
        }
        if (a.brand.toLowerCase() > b.brand.toLowerCase()) {
         return 1
        }
        return 0
      }))
      setGroups([...allGroups].sort((a, b) => {
        if (a.group.toLowerCase() < b.group.toLowerCase()) {
          return -1
        }
        if (a.group.toLowerCase() > b.group.toLowerCase()) {
         return 1
        }
        return 0
      }))
      setSets([...allSets].sort((a, b) => {
        if (a.set.toLowerCase() < b.set.toLowerCase()) {
          return -1
        }
        if (a.set.toLowerCase() > b.set.toLowerCase()) {
         return 1
        }
        return 0
      }))
      setProducts([...allProducts])

      // 2nd) We prepare all the tenant's products, business units, brands, groups and sets selected on the screen
      // Business units
      let onScreenObjBusUnits = sharedObjective[0].businessunit.map(objBusUnit => {
        const busUnitFound = allBusinessUnits.find(busUnit =>{
          if (busUnit.businessunitid === objBusUnit) {return busUnit}
        })
        return busUnitFound && {
         label: busUnitFound.businessunit,
         value: busUnitFound.businessunitid
         } || 
         { 
           label: '', 
           value: null
         }
      })
      if (boldBusUnits.length === 0) {
         let onScreenObjBusUnitsSorted = onScreenObjBusUnits.sort((a, b) => {
           if (a.label.toLowerCase() < b.label.toLowerCase()) {
             return -1
           }
           if (a.label.toLowerCase() > b.label.toLowerCase()) {
            return 1
           }
           return 0
         })
         setSelectedBusUnits([...onScreenObjBusUnitsSorted])
      } else {
         setSelectedBusUnits([...onScreenObjBusUnits])
      }

      // Brands
      let onScreenObjBrands = sharedObjective[0].brand.map(objBrand => {
        const brandFound = allBrands.find(brand =>{
          if (brand.brandid === objBrand) {return brand}
        })
        return brandFound && {
         label: brandFound.brand,
         value: brandFound.brandid,
         businessunit: brandFound.businessunit
         } || 
         { 
           label: '', 
           value: null
         }
      })
      if (boldBrands.length === 0) {
        let onScreenObjBrandsSorted = onScreenObjBrands.sort((a, b) => {
          if (a.label.toLowerCase() < b.label.toLowerCase()) {
            return -1
          }
          if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1
          }
          return 0
        })
        setSelectedBrands([...onScreenObjBrandsSorted])
      } else {
        setSelectedBrands([...onScreenObjBrands])
      }

      // Groups
      let onScreenObjGroups = sharedObjective[0].group.map(objGroup => {
        const groupFound = allGroups.find(group =>{
          if (group.groupid === objGroup) {return group}
        })
        return groupFound && {
         label: groupFound.group,
         value: groupFound.groupid,
         businessunit: groupFound.businessunit,
         brand: groupFound.brand
         } || 
         { 
           label: '', 
           value: null
         }
      })
      if (boldGroups.length === 0) {
        let onScreenObjGroupsSorted = onScreenObjGroups.sort((a, b) => {
          if (a.label.toLowerCase() < b.label.toLowerCase()) {
            return -1
          }
          if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1
          }
          return 0
        })
        setSelectedGroups([...onScreenObjGroupsSorted])
      } else {
        setSelectedGroups([...onScreenObjGroups])
      }

      // Sets
      let onScreenObjSets = sharedObjective[0].set.map(objSet => {
        const setFound = allSets.find(set =>{
          if (set.setid === objSet) {return set}
        })
        return setFound && {
         label: setFound.set,
         value: setFound.setid,
         businessunit: setFound.businessunit,
         brand: setFound.brand,
         group: setFound.group
         } || 
         { 
           label: '', 
           value: null
         }
      })
      if (boldSets.length === 0) {
        let onScreenObjSetsSorted = onScreenObjSets.sort((a, b) => {
          if (a.label.toLowerCase() < b.label.toLowerCase()) {
            return -1
          }
          if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1
          }
          return 0
        })
        setSelectedSets([...onScreenObjSetsSorted])
      } else {
        setSelectedSets([...onScreenObjSets])
      }

      // Products
      let onScreenObjProducts = sharedObjective[0].product.map(objProduct => {
        const productFound = allProducts.find(product =>{
          if (product.producttenantid === objProduct) {return product}
        })
        return productFound && {
         label: `${productFound.producttenantcode} - ${productFound.productname} - ${productFound.productbusinessunitname} - ${productFound.productbrandname} - ${productFound.productgroupname} - ${productFound.productsetname}`,
         value: productFound.producttenantid,
         businessunit: productFound.productbusinessunitname,
         brand: productFound.productbrandname,
         group: productFound.productgroupname,
         set: productFound.productsetname,
         forsort: productFound.productname
         } || 
         { 
           label: '', 
           value: null
         }
      })
      if (boldProducts.length === 0) {
        let onScreenObjProductsSorted = onScreenObjProducts.sort((a, b) => {
          if (a.label.toLowerCase() < b.label.toLowerCase()) {
            return -1
          }
          if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1
          }
          return 0
        })
        setSelectedProducts([...onScreenObjProductsSorted])
      } else {
        setSelectedProducts([...onScreenObjProducts])
      } 
      setTotalSelProds(countSelectedProducts(allProducts, sharedObjective[0].businessunit, sharedObjective[0].brand, sharedObjective[0].group, sharedObjective[0].set, sharedObjective[0].product))
    
      // 3rd) We prepare all the tenant's products, business units, brands, groups and sets not selected on the screen       
      selectableBusUnits(allBusinessUnits, onScreenObjBusUnits)
      selectableBrands(allBrands, onScreenObjBrands)
      selectableGroups(allGroups, onScreenObjGroups)
      selectableSets(allSets, onScreenObjSets)
      selectableProducts(allProducts, onScreenObjProducts, onScreenObjSets, onScreenObjGroups, onScreenObjBrands, onScreenObjBusUnits)
   })();
  }, [])

  const createDistinctArrays = (theArray) => {
    const distinctArrays = [[], [], [], []]
    const mapBusUnits = new Map()
    const mapBrands = new Map()
    const mapGroups = new Map()
    const mapSets = new Map()
    for (const item of theArray) {
      if(!mapBusUnits.has(item.productbusinessunitid) && item.productbusinessunitid !== null){
          mapBusUnits.set(item.productbusinessunitid, true)
          distinctArrays[0].push({
            businessunitid: item.productbusinessunitid,
            businessunit: item.productbusinessunitname
          })
      }
      if(!mapBrands.has(item.productbrandid) && item.productbrandid !== null){
        mapBrands.set(item.productbrandid, true)
        distinctArrays[1].push({
          brandid: item.productbrandid,
          brand: item.productbrandname,
          businessunit: item.productbusinessunitname
        })
      }
      if(!mapGroups.has(item.productgroupid) && item.productgroupid !== null){
        mapGroups.set(item.productgroupid, true)
        distinctArrays[2].push({
          groupid: item.productgroupid,
          group: item.productgroupname,
          businessunit: item.productbusinessunitname,
          brand: item.productbrandname
        })
      }
      if(!mapSets.has(item.productsetid) && item.productsetid !== null){
        mapSets.set(item.productsetid, true)
        distinctArrays[3].push({
          setid: item.productsetid,
          set: item.productsetname,
          businessunit: item.productbusinessunitname,
          brand: item.productbrandname,
          group: item.productgroupname
        })
      }
    }
    return distinctArrays
  }

  const selectableBusUnits = (busUnits, onScreenBusUnits) => {
    const selectableBusUnits = busUnits && busUnits.filter(busUnit => {
      let isSelectedOnScreen = onScreenBusUnits && onScreenBusUnits.some(tmpBusUnit => busUnit.businessunit === tmpBusUnit.label)
      if (!isSelectedOnScreen) return busUnit 
    }).sort((a, b) => {
      if (a.businessunit.toLowerCase() < b.businessunit.toLowerCase()) {
        return -1
      }
      if (a.businessunit.toLowerCase() > b.businessunit.toLowerCase()) {
      return 1
      }
      return 0
    })
    setBusUnitsOptions(
      selectableBusUnits.map(busUnit => {
       return {
         label: busUnit.businessunit,
         value: busUnit.businessunitid
       }
     })
    )
    return selectableBusUnits
  }

  const selectableBrands = (brands, onScreenBrands) => {
    const selectableBrands = brands && brands.filter(brand => {
      let isSelectedOnScreen = onScreenBrands && onScreenBrands.some(tmpBrand => brand.brand === tmpBrand.label)
      if (!isSelectedOnScreen) return brand 
    }).sort((a, b) => {
      if (a.brand.toLowerCase() < b.brand.toLowerCase()) {
        return -1
      }
      if (a.brand.toLowerCase() > b.brand.toLowerCase()) {
      return 1
      }
      return 0
    })
    setBrandsOptions(
      selectableBrands.map(brand => {
       return {
         label: brand.brand,
         value: brand.brandid,
         businessunit: brand.businessunit
       }
     })
    )
    return selectableBrands
  }

  const selectableGroups = (groups, onScreenGroups) => {
    const selectableGroups = groups && groups.filter(group => {
      let isSelectedOnScreen = onScreenGroups && onScreenGroups.some(tmpGroup => group.group === tmpGroup.label)
      if (!isSelectedOnScreen) return group 
    }).sort((a, b) => {
      if (a.group.toLowerCase() < b.group.toLowerCase()) {
        return -1
      }
      if (a.group.toLowerCase() > b.group.toLowerCase()) {
      return 1
      }
      return 0
    })
    setGroupsOptions(
      selectableGroups.map(group => {
       return {
         label: group.group,
         value: group.groupid,
         businessunit: group.businessunit,
         brand: group.brand
       }
     })
    )
    return selectableGroups
  }

  const selectableSets = (sets, onScreenSets) => {
    const selectableSets = sets && sets.filter(set => {
      let isSelectedOnScreen = onScreenSets && onScreenSets.some(tmpSet => set.set === tmpSet.label)
      if (!isSelectedOnScreen) return set 
    }).sort((a, b) => {
      if (a.set.toLowerCase() < b.set.toLowerCase()) {
        return -1
      }
      if (a.set.toLowerCase() > b.set.toLowerCase()) {
      return 1
      }
      return 0
    })
    setSetsOptions(
      selectableSets.map(set => {
       return {
         label: set.set,
         value: set.setid,
         businessunit: set.businessunit,
         brand: set.brand,
         group: set.group
       }
     })
    )
    return selectableSets
  }

  const selectableProducts = (products, onScreenProducts, onScreenSets, onScreenGroups, onScreenBrands, onScreenBusUnits) => {  
    const selectableProducts = products && products.filter(product => {
      let isSelectedOnScreen = onScreenProducts && onScreenProducts.some(tmpProduct => product.productname === tmpProduct.forsort)
      let isSelectedOnSet = onScreenSets && onScreenSets.some(tmpSet => product.productsetname === tmpSet.label)
      let isSelectedOnGroup = onScreenGroups && onScreenGroups.some(tmpGroup => product.productgroupname === tmpGroup.label)
      let isSelectedOnBrand = onScreenBrands && onScreenBrands.some(tmpBrand => product.productbrandname === tmpBrand.label)
      let isSelectedOnBusUnit = onScreenBusUnits && onScreenBusUnits.some(tmpBusUnit => product.productbusinessunitname === tmpBusUnit.label)
      if (!isSelectedOnScreen && !isSelectedOnSet && !isSelectedOnGroup && !isSelectedOnBrand && !isSelectedOnBusUnit) return product 
    }).sort((a, b) => {
      if (a.productname.toLowerCase() < b.productname.toLowerCase()) {
        return -1
      }
      if (a.productname.toLowerCase() > b.productname.toLowerCase()) {
      return 1
      }
      return 0
    })
    setProductsOptions(
      selectableProducts.map(product => {
       return {
         label: `${product.producttenantcode} - ${product.productname} - ${product.productbusinessunitname} - ${product.productbrandname} - ${product.productgroupname} - ${product.productsetname}`,
         value: product.producttenantid,
         businessunit: product.productbusinessunitname,
         brand: product.productbrandname,
         group: product.productgroupname,
         set: product.productsetname,
         forsort: product.productname
       }
     })
    )
    return selectableProducts
  }

  const showConfirmArea = (message, areaSelected, area) => {
    Modal.confirm({
      title: t('Confirm selection'),
      icon: <IconAntd type='exclamation-circle' />,
      content: t(message),
      onOk () {
        newSelectionsAndOptions(areaSelected, area)
      }
    })
  }

  const showConfirmDeleteSelection = (message, value) => {
    Modal.confirm({
      title: t('Confirm selection'),
      icon: <IconAntd type='exclamation-circle' />,
      content: t(message),
      onOk () {
        newOptions(value)
        let updatingCheckedBoxes = {...onScreenCheckedBoxes}
        updatingCheckedBoxes[value] = !updatingCheckedBoxes[value]
        setOnScreenCheckedBoxes({...updatingCheckedBoxes})
        setProductsCheckedBoxes({...updatingCheckedBoxes})
      },
    })
  }

  const addSubAreas = (newShrObj) => {
    const newShrObjNames = fromIdToName(newShrObj)
    selectableProducts(products, newShrObjNames.product, newShrObjNames.set,  newShrObjNames.group,  newShrObjNames.brand, newShrObjNames.businessunit)
    setTotalSelProds(countSelectedProducts(products, newShrObj[0].businessunit, newShrObj[0].brand, newShrObj[0].group, newShrObj[0].set, newShrObj[0].product))
  }

  const fromIdToName = (newShrObj) => {
    let newShrObjNames = {
      businessunit: [],
      brand: [],
      group: [],
      set: [],
      product: []
    }
    newShrObjNames.businessunit = newShrObj[0].businessunit.map(businessUnitId => {
      const busUnitName = businessUnits.find(busUnit => { return busUnit.businessunitid === businessUnitId})
      return {label: busUnitName.businessunit}
    })
    newShrObjNames.brand = newShrObj[0].brand.map(brandId => {
      const brandName = brands.find(brand => { return brand.brandid === brandId})
      return {label: brandName.brand}
    })
    newShrObjNames.group = newShrObj[0].group.map(groupId => {
      const groupName = groups.find(group => { return group.groupid === groupId})
      return {label: groupName.group}
    })
    newShrObjNames.set = newShrObj[0].set.map(setId => {
      const setName = sets.find(set => { return set.setid === setId})
      return {label: setName.set}
    })
    newShrObjNames.product = newShrObj[0].product.map(productId => {
      const productName = products.find(product => { return product.producttenantid === productId})
      return {label: productName.productname}
    })
    return newShrObjNames
  }

  const removeProducts = (newShrObj, areaSelected, area) => {
    const newSelectedProducts = selectedProducts.filter(product => { return product[area] !== areaSelected.label })
    newShrObj[0].product = newSelectedProducts.map(productSelected => {
      return productSelected.value
    })
    setSelectedProducts([...newSelectedProducts])
    const newNotSelectedProducts = productsOptions
      .filter(product => { return product[area] !== areaSelected.label })
      .sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1
        }
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1
        }
        return 0
      })
    setProductsOptions(
      newNotSelectedProducts.map(product => {
        return {
          label: product.label,
          value: product.value,
          businessunit: product.businessunit,
          brand: product.brand,
          group: product.group,
          set: product.set,
          forsort: product.forsort
        }
      })
      )
  }

  const newSelectionsAndOptions = (areaSelected, area ) => {
    let newShrObj = [...sharedObjective]
    let newSelectedAreas
    switch (area){
      case 'businessunit':
        newSelectedAreas = [areaSelected].concat(selectedBusUnits).map(area => {return area.value})
        setSelectedBusUnits([...[areaSelected].concat(selectedBusUnits)])
        setBoldBusUnits([areaSelected.value].concat(boldBusUnits))
        setBusUnitsOptions(busUnitsOptions
          .filter((busUnit, index) => {
            if(areaSelected.value !== busUnit.value) return busUnit
          })
        )
        setTotalSelProds(countSelectedProducts(products, newSelectedAreas, newShrObj[0].brand, newShrObj[0].group, newShrObj[0].set, newShrObj[0].product))
        break
      case 'brand':
        newSelectedAreas = [areaSelected].concat(selectedBrands).map(area => {return area.value})
        setSelectedBrands([...[areaSelected].concat(selectedBrands)])
        setBoldBrands([areaSelected.value].concat(boldBrands))
        setBrandsOptions(brandsOptions
          .filter((brand, index) => {
            if(areaSelected.value !== brand.value) return brand
          })
        )
        setTotalSelProds(countSelectedProducts(products, newShrObj[0].businessunit, newSelectedAreas, newShrObj[0].group, newShrObj[0].set, newShrObj[0].product))
        break
      case 'group':
        newSelectedAreas = [areaSelected].concat(selectedGroups).map(area => {return area.value})
        setSelectedGroups([...[areaSelected].concat(selectedGroups)])
        setBoldGroups([areaSelected.value].concat(boldGroups))
        setGroupsOptions(groupsOptions
          .filter((group, index) => {
            if(areaSelected.value !== group.value) return group
          })
        )
        setTotalSelProds(countSelectedProducts(products, newShrObj[0].businessunit, newShrObj[0].brand, newSelectedAreas, newShrObj[0].set, newShrObj[0].product))
        break
      case 'set':
        newSelectedAreas = [areaSelected].concat(selectedSets).map(area => {return area.value})
        setSelectedSets([...[areaSelected].concat(selectedSets)])
        setBoldSets([areaSelected.value].concat(boldSets))
        setSetsOptions(setsOptions
          .filter((set, index) => {
            if(areaSelected.value !== set.value) return set
          })
        )
        setTotalSelProds(countSelectedProducts(products, newShrObj[0].businessunit, newShrObj[0].brand, newShrObj[0].group, newSelectedAreas, newShrObj[0].product))
        break
      case 'product':
          newSelectedAreas = [areaSelected].concat(selectedProducts).map(area => {return area.value})
          setSelectedProducts([...[areaSelected].concat(selectedProducts)])
          setBoldProducts([areaSelected.value].concat(boldProducts))
          setProductsOptions(productsOptions
            .filter((product, index) => {
              if(areaSelected.value !== product.value) return product
            })
          )
          setTotalSelProds(countSelectedProducts(products, newShrObj[0].businessunit, newShrObj[0].brand, newShrObj[0].group, newShrObj[0].set, newSelectedAreas))
          break
      default:
        break
    }
    if (area !=='product') removeProducts(newShrObj, areaSelected, area) // The products don't have subareas
    newShrObj[0][area] = [...newSelectedAreas]
    setSharedObjective([...newShrObj])
  }

  // The total products selected are the products selected plus all the products selected under the scope of the 
  // business units, brands, groups and sets
  const countSelectedProducts = (allProducts, busUnits, brands, groups, sets,  prods) => {
    let productsSelected = []
    const prodsBusUnits = busUnits.map(busUnit => {
      const prodsSelOnBusUnits = allProducts.filter(product => {
        if (busUnit === product.productbusinessunitid) {
          return product.producttenantid
        }
      })
      return prodsSelOnBusUnits
    })
    productsSelected = [...productsSelected].concat(prodsBusUnits).flat()
    const prodsBrands = brands.map(brand => {
      const prodsSelOnBrands = allProducts.filter(product => {
        if (brand === product.productbrandid) {
          return product.producttenantid
        }
      })
      return prodsSelOnBrands
    })
    productsSelected = [...productsSelected].concat(prodsBrands).flat()
    const prodsGroups = groups.map(group => {
      const prodsSelOnGroups = allProducts.filter(product => {
        if (group === product.productgroupid) {
          return product.producttenantid
        }
      })
      return prodsSelOnGroups
    })
    productsSelected = [...productsSelected].concat(prodsGroups).flat()
    const prodsSets = sets.map(set => {
      const prodsSelOnSets = allProducts.filter(product => {
        if (set === product.productsetid) {
          return product.producttenantid
        }
      })
      return prodsSelOnSets
    })
    productsSelected = [...productsSelected].concat(prodsSets).flat()
    const prodsProds = prods.map(prod => {
      const prodsSelOnProds = allProducts.filter(product => {
        if (prod === product.producttenantid) {
          return product.producttenantid
        }
      })
      return prodsSelOnProds
    })
    productsSelected = [...productsSelected].concat(prodsProds).flat()
    return [...new Set(productsSelected.map(product => product.producttenantid))].length
  }

  const newOptions = (area) => {
    let newShrObj = [...sharedObjective]
    switch (area) {
      case 'businessunit':
        setSelectedBusUnits([])
        setBoldBusUnits([])
        setBusUnitsOptions(businessUnits.map(businessUnit => {
          return {
            label: businessUnit.businessunit,
            value: businessUnit.businessunitid
          }
        }))
        selectableProducts(products, newShrObj[0].product, newShrObj[0].set, newShrObj[0].group, newShrObj[0].brand, [])
        break
      case 'brand':
        setSelectedBrands([])
        setBoldBrands([])
        setBrandsOptions(brands.map(brand => {
           return {
             label: brand.brand,
             value: brand.brandid,
             businessunit: brand.businessunit
           }
         })
        )
        selectableProducts(products, newShrObj[0].product, newShrObj[0].set, newShrObj[0].group, [], newShrObj[0].businessunit)
        break
      case 'group':
        setSelectedGroups([])
        setBoldGroups([])
        setGroupsOptions(groups.map(group => {
           return {
             label: group.group,
             value: group.groupid,
             businessunit: group.businessunit,
             brand: group.brand
           }
         })
        )
        selectableProducts(products, newShrObj[0].product, newShrObj[0].set, [], newShrObj[0].brand, [], newShrObj[0].businessunit)
        break
      case 'set':
        setSelectedSets([])
        setBoldSets([])
        setSetsOptions(sets.map(set => {
           return {
             label: set.set,
             value: set.setid,
             businessunit: set.businessunit,
             brand: set.brand,
             group: set.group
           }
         })
        )
        selectableProducts(products, newShrObj[0].product, [], newShrObj[0].group, newShrObj[0].brand, newShrObj[0].businessunit)
        break
      case 'product':
        setSelectedProducts([])
        setBoldProducts([])
        selectableProducts(products, [],  newShrObj[0].set, newShrObj[0].group, newShrObj[0].brand, newShrObj[0].bussinessunit)
        break
    }
    newShrObj[0][area] = []
    setSharedObjective([...newShrObj])
    let newCheckedBoxes = {...onScreenCheckedBoxes}
    newCheckedBoxes[area] = !newCheckedBoxes[area]
    setOnScreenCheckedBoxes({...newCheckedBoxes})
    setProductsCheckedBoxes({...newCheckedBoxes})
    setTotalSelProds(countSelectedProducts(products, newShrObj[0].businessunit, newShrObj[0].brand, newShrObj[0].group, newShrObj[0].set, newShrObj[0].product))
  }

  const handleBusUnitSelected = (busUnitSelected) => {
    if (busUnitSelected === null) return // By clicking the delete key you are selecting a null country and we have to do nothing
    const areThereProductsSelected = selectedProducts.some(tmpProduct => busUnitSelected.label === tmpProduct.businessunit)
    if(areThereProductsSelected) {  
      showConfirmArea('There are products under the scope of the chosen business unit that will be deselected', busUnitSelected, 'businessunit')
      return
    }
    newSelectionsAndOptions(busUnitSelected, 'businessunit')
  }

  const handleBrandSelected = (brandSelected) => {
    if (brandSelected === null) return // By clicking the delete key you are selecting a null country and we have to do nothing
    const areThereProductsSelected = selectedProducts.some(tmpProduct => brandSelected.label === tmpProduct.brand)
    if(areThereProductsSelected) {
      showConfirmArea('There are products under the scope of the chosen brand that will be deselected', brandSelected, 'brand')
      return
    }
    newSelectionsAndOptions(brandSelected, 'brand')
  }

  const handleGroupSelected = (groupSelected) => {
    if (groupSelected === null) return // By clicking the delete key you are selecting a null country and we have to do nothing
    const areThereProductsSelected = selectedProducts.some(tmpProduct => groupSelected.label === tmpProduct.group)
    if(areThereProductsSelected) {
      showConfirmArea('There are products under the scope of the chosen group that will be deselected', groupSelected, 'group')
      return
    }
    newSelectionsAndOptions(groupSelected, 'group')
  }

  const handleSetSelected = (setSelected) => {
    if (setSelected === null) return // By clicking the delete key you are selecting a null country and we have to do nothing
    const areThereProductsSelected = selectedProducts.some(tmpProduct => setSelected.label === tmpProduct.set)
    if(areThereProductsSelected) {
      showConfirmArea('There are products under the scope of the chosen set that will be deselected', setSelected, 'set')
      return
    }
    newSelectionsAndOptions(setSelected, 'set')
  }

  const handleProductSelected = (productSelected) => {
    if (productSelected === null) return // By clicking the delete key you are selecting a null country and we have to do nothing
    newSelectionsAndOptions(productSelected, 'product')
  }

  const handleBusUnitDeselected = (e, busUnitDeselected) => {
    let newSelectedBusUnits = selectedBusUnits.filter(busUnit => { 
      if (busUnit.value !== busUnitDeselected.value) return busUnit
     })
    setSelectedBusUnits([].concat(newSelectedBusUnits))

    let newSortedOptions = [...busUnitsOptions]
    newSortedOptions.push({
      label: busUnitDeselected.label,
      value: busUnitDeselected.value
    })
    newSortedOptions.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1
      }
      return 0
    })
    setBusUnitsOptions([...newSortedOptions])

    let newShrObj = [...sharedObjective]
    newShrObj[0].businessunit = newSelectedBusUnits.map(busUnitSelected => {
      return busUnitSelected.value
    })
    addSubAreas(newShrObj)
    setSharedObjective([...newShrObj])
  }

  const handleBrandDeselected = (e, brandDeselected) => {
    let newSelectedBrands = selectedBrands.filter( brand => { 
      if ( brand.value !== brandDeselected.value) return brand
     })
    setSelectedBrands([].concat(newSelectedBrands))

    let newSortedOptions = [...brandsOptions]
    newSortedOptions.push({
      label: brandDeselected.label,
      value: brandDeselected.value,
      businessunit: brandDeselected.businessunit
    })
    newSortedOptions.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1
      }
      return 0
    })
    setBrandsOptions([...newSortedOptions])

    let newShrObj = [...sharedObjective]
    newShrObj[0].brand = newSelectedBrands.map(brandSelected => {
      return brandSelected.value
    })
    addSubAreas(newShrObj)
    setSharedObjective([...newShrObj])
  }

  const handleGroupDeselected = (e, groupDeselected) => {
    let newSelectedGroups = selectedGroups.filter( group => { 
      if ( group.value !== groupDeselected.value) return group
     })
    setSelectedGroups([].concat(newSelectedGroups))

    let newSortedOptions = [...groupsOptions]
    newSortedOptions.push({
      label: groupDeselected.label,
      value: groupDeselected.value,
      businessunit: groupDeselected.businessunit,
      brand: groupDeselected.brand
    })
    newSortedOptions.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1
      }
      return 0
    })
    setGroupsOptions([...newSortedOptions])

    let newShrObj = [...sharedObjective]
    newShrObj[0].group = newSelectedGroups.map(groupSelected => {
      return groupSelected.value
    })
    addSubAreas(newShrObj)
    setSharedObjective([...newShrObj])
  }

  const handleSetDeselected = (e, setDeselected) => {
    let newSelectedSets = selectedSets.filter( set => { 
      if (set.value !== setDeselected.value) return set
     })
    setSelectedSets([].concat(newSelectedSets))

    let newSortedOptions = [...setsOptions]
    newSortedOptions.push({
      label: setDeselected.label,
      value: setDeselected.value,
      businessunit: setDeselected.businessunit,
      brand: setDeselected.brand,
      group: setDeselected.group
    })
    newSortedOptions.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1
      }
      return 0
    })
    setSetsOptions([...newSortedOptions])

    let newShrObj = [...sharedObjective]
    newShrObj[0].set = newSelectedSets.map(setSelected => {
      return setSelected.value
    })
    addSubAreas(newShrObj)
    setSharedObjective([...newShrObj])
  }

  const handleProductDeselected = (e, productDeselected) => {
    let newSelectedProducts = selectedProducts.filter( product => { 
      if ( product.value !== productDeselected.value) return product
     })
    setSelectedProducts([].concat(newSelectedProducts))

    let newSortedOptions = [...productsOptions]
    newSortedOptions.push({
      label: productDeselected.label,
      value: productDeselected.value,
      businessunit: productDeselected.businessunit,
      brand: productDeselected.brand,
      group: productDeselected.group,
      set: productDeselected.set,
      forsort: productDeselected.forsort
    })
    newSortedOptions.sort((a, b) => {
      if (a.forsort.toLowerCase() < b.forsort.toLowerCase()) {
        return -1
      }
      if (a.forsort.toLowerCase() > b.forsort.toLowerCase()) {
        return 1
      }
      return 0
    })
    setProductsOptions([...newSortedOptions])

    let newShrObj = [...sharedObjective]
    newShrObj[0].product = newSelectedProducts.map(productSelected => {
      return productSelected.value
    })
    setSharedObjective([...newShrObj])
    setTotalSelProds(countSelectedProducts(products, newShrObj[0].businessunit, newShrObj[0].brand, newShrObj[0].group, newShrObj[0].set, newShrObj[0].product))
  }

  const handleCheckboxClicked = (event) => {
    const { name, value } = event.target 

    let area
    switch (value) {
      case 'businessunit': 
        area = 'business units'
        break
      case 'brand':
        area = 'brands'
        break
      case 'group':
        area = 'groups'
        break
      case 'set':
        area = 'sets'
        break
      case 'product':
        area='products'
        break
    }

    if (sharedObjective[0][value].length > 0 && onScreenCheckedBoxes[value]) {
      showConfirmDeleteSelection(`Deselecting ${area} implies removing all the ones previously selected`, value)
      return
    }

    let newCheckedBoxes = {...onScreenCheckedBoxes}
    newCheckedBoxes[value] = !newCheckedBoxes[value]
    setOnScreenCheckedBoxes({...newCheckedBoxes})
    setProductsCheckedBoxes({...newCheckedBoxes})
  }

    // handles the pagination requests
  const handleRequestPagination = requestPaginationObject => {
    let newStartPosition = defaultPaginationStartPosition
    let newEndPosition = defaultPaginationEndPosition
    if (requestPaginationObject) {
      const { currentPage, itemsPerPage } = requestPaginationObject
      newStartPosition = currentPage * itemsPerPage - itemsPerPage
      newEndPosition = newStartPosition + itemsPerPage
    }
    return [newStartPosition, newEndPosition]
  }

  const handleRequestPaginationBusUnits = requestPaginationObject => {
    const newPositions = handleRequestPagination(requestPaginationObject)
    setStartPositionBusUnits(newPositions[0])
    setEndPositionBusUnits(newPositions[1])
  }

  const handleRequestPaginationBrands = requestPaginationObject => {
    const newPositions = handleRequestPagination(requestPaginationObject)
    setStartPositionBrands(newPositions[0])
    setEndPositionBrands(newPositions[1])
  }

  const handleRequestPaginationGroups = requestPaginationObject => {
    const newPositions = handleRequestPagination(requestPaginationObject)
    setStartPositionGroups(newPositions[0])
    setEndPositionGroups(newPositions[1])
  }

  const handleRequestPaginationSets = requestPaginationObject => {
    const newPositions = handleRequestPagination(requestPaginationObject)
    setStartPositionSets(newPositions[0])
    setEndPositionSets(newPositions[1])
  }

  const handleRequestPaginationProducts = requestPaginationObject => {
    const newPositions = handleRequestPagination(requestPaginationObject)
    setStartPositionProducts(newPositions[0])
    setEndPositionProducts(newPositions[1])
  }

  return (
    <Box>
      <TabsObjectives highlight='products' isInactive='is-active' id={id && parseInt(id) > -1 ? id : -1} />
      <Title>
        {id
          ? `${t('edit')} ${sharedObjective && sharedObjective[0].objectivename}`
          : `${t('add')} ${t('objective_products')}`}
      </Title>
      
      <Level>
        <Level.Item>
          <Label>
            <input type="checkbox" disabled={sharedObjDisabled} checked={onScreenCheckedBoxes && onScreenCheckedBoxes.businessunit ? "checked" : null} name="businessunit" value="businessunit" onChange={(event) => handleCheckboxClicked(event)} />
            {t(' Business Units')}
          </Label>
        </Level.Item>
        <Level.Item>
          <Label>
            <input type="checkbox" disabled={sharedObjDisabled} checked={onScreenCheckedBoxes && onScreenCheckedBoxes.brand ? "checked" : null} name="checkBrands" value="brand" onChange={(event) => handleCheckboxClicked(event)} />
            {t(' Brands')}
          </Label>
        </Level.Item>
        <Level.Item>
          <Label>
            <input type="checkbox" disabled={sharedObjDisabled} checked={onScreenCheckedBoxes && onScreenCheckedBoxes.group ? "checked" : null} name="checkGroups" value="group" onChange={(event) => handleCheckboxClicked(event)} />
            {t(' Groups')}
          </Label>
        </Level.Item>
        <Level.Item>
          <Label>
            <input type="checkbox" disabled={sharedObjDisabled} checked={onScreenCheckedBoxes && onScreenCheckedBoxes.set ? "checked" : null} name="checkSets" value="set" onChange={(event) => handleCheckboxClicked(event)} />
            {t(' Sets')}
          </Label>
        </Level.Item>
        <Level.Item>
          <Label>
            <input type="checkbox"  disabled={sharedObjDisabled} checked={onScreenCheckedBoxes && onScreenCheckedBoxes.product ? "checked" : null} name="checkProducts" value="product" onChange={(event) => handleCheckboxClicked(event)} />
            {t(' Products')}
          </Label>
        </Level.Item>
      </Level>
      <Level>
        <Level.Item>
          <Label>
            {`PRODUCTOS SELECCIONADOS: ${totalSelProds} DE ${totalProds}`}
          </Label>
        </Level.Item>
      </Level>

      {onScreenCheckedBoxes && onScreenCheckedBoxes.businessunit ?  (
        <>
          <p className="productline">
            . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          </p>
          <br></br>
        
          <Column size={8}>
              <Column.Group>
                <Column narrow>
                  <Label>{t('selectBusinessUnits')}: </Label>
                </Column>
                <Column>
                  <Field>
                    <Controller
                      as={
                        <ReactSelect isClearable isSearchable options={busUnitsOptions} isDisabled={sharedObjDisabled} />
                      }
                      control={control}
                      onChange={([selected]) => {  // React Select return object instead of value for selection
                        handleBusUnitSelected(selected)
                      }}
                      name='objectivesTenant'
                    />
                  </Field>
                </Column>
              </Column.Group>
          </Column>

          <Table hoverable bordered striped narrow fullwidth >
            <Table.Head>
              <Table.Row>
                <Table.Heading>{t('selectedBusinessUnit')}</Table.Heading>
                <Table.Heading>{t('action')}</Table.Heading>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {selectedBusUnits &&
                selectedBusUnits.slice(startPositionBusUnits, endPositionBusUnits).map((busUnit, index) => {
                  return (
                    <Table.Row key={index}>
                      <Table.Cell className={boldBusUnits.includes(busUnit.value) ? "new-identity" : ""} key={index+1*10000}>{busUnit.label}</Table.Cell>
                      <Table.Cell key={index+1*20000}>
                          
                              <button
                                  type="button"
                                  onClick={e => handleBusUnitDeselected(e, busUnit)}
                                  className='button is-danger is-outlined is-small'
                                  disabled={sharedObjDisabled}
                                >
                                <Icon>
                                  <FontAwesomeIcon size='lg' icon={['fas', 'trash']} />
                                </Icon>
                              </button>

                      </Table.Cell>
                    </Table.Row>
                  )
                })}
            </Table.Body>
          </Table>
          <Pagination
            totalItems={selectedBusUnits && selectedBusUnits.length}
            requestPagination={handleRequestPaginationBusUnits}
          />
        </>
          ) : null
      } 
        
      {onScreenCheckedBoxes && onScreenCheckedBoxes.brand ?  (
        <>
          <p className="productline">
            . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          </p>
          <br></br>
        
          <Column size={8}>
              <Column.Group>
                <Column narrow>
                  <Label>{t('selectBrands')}: </Label>
                </Column>
                <Column>
                  <Field>
                    <Controller
                      as={
                        <ReactSelect isClearable isSearchable options={brandsOptions} isDisabled={sharedObjDisabled} />
                      }
                      control={control}
                      onChange={([selected]) => { // React Select return object instead of value for selection
                        handleBrandSelected(selected)
                      }}
                      name='objectivesTenant'
                    />
                  </Field>
                </Column>
              </Column.Group>
          </Column>

          <Table hoverable bordered striped narrow fullwidth >
            <Table.Head>
              <Table.Row>
                <Table.Heading>{t('selectedBrand')}</Table.Heading>
                <Table.Heading>{t('action')}</Table.Heading>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {selectedBrands &&
                selectedBrands.slice(startPositionBrands, endPositionBrands).map((brand, index) => {
                  return (
                    <Table.Row key={index}>
                      <Table.Cell className={boldBrands.includes(brand.value) ? "new-identity" : ""} key={index+1*10000}>{brand.label}</Table.Cell>
                      <Table.Cell key={index+1*20000}>
                          
                              <button
                                  type="button"
                                  onClick={e => handleBrandDeselected(e, brand)}
                                  className='button is-danger is-outlined is-small'
                                  disabled={sharedObjDisabled}
                                >
                                <Icon>
                                  <FontAwesomeIcon size='lg' icon={['fas', 'trash']} />
                                </Icon>
                              </button>

                      </Table.Cell>
                    </Table.Row>
                  )
                })}
            </Table.Body>
          </Table>
          <Pagination
            totalItems={selectedBrands && selectedBrands.length}
            requestPagination={handleRequestPaginationBrands}
          />
        </>
          ) : null
      } 

      {onScreenCheckedBoxes && onScreenCheckedBoxes.group ?  (
        <>
          <p className="productline">
            . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          </p>
          <br></br>
        
          <Column size={8}>
              <Column.Group>
                <Column narrow>
                  <Label>{t('selectGroups')}: </Label>
                </Column>
                <Column>
                  <Field>
                    <Controller
                      as={
                        <ReactSelect isClearable isSearchable options={groupsOptions} isDisabled={sharedObjDisabled} />
                      }
                      control={control}
                      onChange={([selected]) => {
                        handleGroupSelected(selected)
                      }}
                      name='objectivesTenant'
                    />
                  </Field>
                </Column>
              </Column.Group>
          </Column>

          <Table hoverable bordered striped narrow fullwidth >
            <Table.Head>
              <Table.Row>
                <Table.Heading>{t('selectedGroup')}</Table.Heading>
                <Table.Heading>{t('action')}</Table.Heading>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {selectedGroups &&
                selectedGroups.slice(startPositionGroups, endPositionGroups).map((group, index) => {
                  return (
                    <Table.Row key={index}>
                      <Table.Cell className={boldGroups.includes(group.value) ? "new-identity" : ""} key={index+1*10000}>{group.label}</Table.Cell>
                      <Table.Cell key={index+1*20000}>
                          
                              <button
                                  type="button"
                                  onClick={e => handleGroupDeselected(e, group)}
                                  className='button is-danger is-outlined is-small'
                                  disabled={sharedObjDisabled}
                                >
                                <Icon>
                                  <FontAwesomeIcon size='lg' icon={['fas', 'trash']} />
                                </Icon>
                              </button>

                      </Table.Cell>
                    </Table.Row>
                  )
                })}
            </Table.Body>
          </Table>
          <Pagination
            totalItems={selectedGroups && selectedGroups.length}
            requestPagination={handleRequestPaginationGroups}
          />
        </>
          ) : null
      } 

      {onScreenCheckedBoxes && onScreenCheckedBoxes.set ?  (
        <>
          <p className="productline">
            . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          </p>
          <br></br>
        
          <Column size={8}>
              <Column.Group>
                <Column narrow>
                  <Label>{t('selectSets')}: </Label>
                </Column>
                <Column>
                  <Field>
                    <Controller
                      as={
                        <ReactSelect isClearable isSearchable options={setsOptions} isDisabled={sharedObjDisabled} />
                      }
                      control={control}
                      onChange={([selected]) => { // React Select return object instead of value for selection
                        handleSetSelected(selected)
                      }}
                      name='objectivesTenant'
                    />
                  </Field>
                </Column>
              </Column.Group>
          </Column>

          <Table hoverable bordered striped narrow fullwidth >
            <Table.Head>
              <Table.Row>
                <Table.Heading>{t('selectedSet')}</Table.Heading>
                <Table.Heading>{t('action')}</Table.Heading>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {selectedSets &&
                selectedSets.slice(startPositionSets, endPositionSets).map((set, index) => {
                  return (
                    <Table.Row key={index}>
                      <Table.Cell className={boldSets.includes(set.value) ? "new-identity" : ""} key={index+1*10000}>{set.label}</Table.Cell>
                      <Table.Cell key={index+1*20000}>
                          
                              <button
                                  type="button"
                                  onClick={e => handleSetDeselected(e, set)}
                                  className='button is-danger is-outlined is-small'
                                  disabled={sharedObjDisabled}
                                >
                                <Icon>
                                  <FontAwesomeIcon size='lg' icon={['fas', 'trash']} />
                                </Icon>
                              </button>

                      </Table.Cell>
                    </Table.Row>
                  )
                })}
            </Table.Body>
          </Table>
          <Pagination
            totalItems={selectedSets && selectedSets.length}
            requestPagination={handleRequestPaginationSets}
          />
        </>
          ) : null
      } 

      {onScreenCheckedBoxes && onScreenCheckedBoxes.product ?  (
        <>
          <p className="productline">
            . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          </p>
          <br></br>
        
          <Column size={8}>
              <Column.Group>
                <Column narrow>
                  <Label>{t('selectProducts')}: </Label>
                </Column>
                <Column>
                  <Field>
                    <Controller
                      as={
                        <ReactSelect isClearable isSearchable options={productsOptions} isDisabled={sharedObjDisabled} />
                      }
                      control={control}
                      onChange={([selected]) => { // React Select return object instead of value for selection
                        handleProductSelected(selected)
                      }}
                      name='objectivesTenant'
                    />
                  </Field>
                </Column>
              </Column.Group>
          </Column>

          <Table hoverable bordered striped narrow fullwidth >
            <Table.Head>
              <Table.Row>
                <Table.Heading>{t('selectedProduct')}</Table.Heading>
                <Table.Heading>{t('action')}</Table.Heading>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {selectedProducts &&
                selectedProducts.slice(startPositionProducts, endPositionProducts).map((product, index) => {
                  return (
                    <Table.Row key={index}>
                      <Table.Cell className={boldProducts.includes(product.value) ? "new-identity" : ""} key={index+1*10000}>{product.label}</Table.Cell>
                      <Table.Cell key={index+1*20000}>
                          
                              <button
                                  type="button"
                                  onClick={e => handleProductDeselected(e, product)}
                                  className='button is-danger is-outlined is-small'
                                  disabled={sharedObjDisabled}
                                >
                                <Icon>
                                  <FontAwesomeIcon size='lg' icon={['fas', 'trash']} />
                                </Icon>
                              </button>

                      </Table.Cell>
                    </Table.Row>
                  )
                })}
            </Table.Body>
          </Table>
          <Pagination
            totalItems={selectedProducts && selectedProducts.length}
            requestPagination={handleRequestPaginationProducts}
          />
        </>
          ) : null
      } 
    </Box>
  )
}