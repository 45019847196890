/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react'
import { useAlert } from 'react-alert'
import {
  Container,
  Column,
  Box,
  Image,
  Field,
  Input,
  Control,
  Button,
  Icon,
  Checkbox,
  Select,
  Label,
  Level,
  Notification
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import AnimalDataSourceLogo from '../../config/images/ADS_fondoblanco.png'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FormError } from '../error'
import { logic } from '../../logic/'
import { useConfirmActionMessage } from '../../logic/hooks'
import { NOTIFICATIONS } from '../../notifications'
import { AvisoLegal } from '../../config/legal/AvisoLegal'
import './login.scss'

function checkValidLogin ({ roleName, distributorId, tenantId, active }) {
  if(!active){
    return false
  } 
  if (roleName === 'Tenant') {
    return !!tenantId
  }
  if (roleName === 'Distributor') {
    return !!distributorId
  }
  if (roleName === 'AmbitAdm') {
    return true
  }
  return false
}

export const Login = () => {
  const alert = useAlert()
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const [hasResetPassword, setHasResetPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const { register, handleSubmit, errors } = useForm()
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

  const errorAction = useConfirmActionMessage()
  const validateUserName = async value => {
    await sleep(0)
    if (value) {
      return true
    }
    return false
  }

  // ComponentDidMount Check if password has been changed correctly
  useEffect(() => {
    function checkIfPasswordHasBeenReset () {
      const hasLocation = location
      if (hasLocation && hasLocation.state && hasLocation.state.message === t(NOTIFICATIONS.resetPassword)) {
        setHasResetPassword(true)
      }
    }
    checkIfPasswordHasBeenReset()
  }, [])

  const onSubmitData = ({ email, password }) => {
    logic
      .login({ email, password })
      .then(res => {
        if (res.status !== 200) {
          throw Error(res.status)
        }
        const roleName = res && res.data && res.data.userdata && res.data.userdata.rolename
        const distributorId = res && res.data && res.data.userdata && res.data.userdata.distributorid
        const tenantId = res && res.data && res.data.userdata && res.data.userdata.tenantid
        const active = res && res.data && res.data.userdata && res.data.userdata.active
        const isValidToLogin = checkValidLogin({ roleName, distributorId, tenantId, active })
        if (isValidToLogin && logic && logic.isTenant){
           return history.push('/dashboard')
        } else if (isValidToLogin && logic && logic.isDistributor) {
          return history.push('/stats')
        } else if (isValidToLogin && logic && !logic.isTenant && !logic.isDistributor){
          return history.push('/home')
        } else {
          return alert.show('Contacte con su proveedor')
        }
      })
      .catch(err => {
        console.error('error', err.message)
        if (err.message === '429') {
          alert.show(t('too Many Requests'), {
            type: 'error'
          })
        } else {
          alert.show(t('invalid Credentials'), {
            type: 'error'
          })
        }
      })
  }

  const togglePassVisibility = (e) => {
    setShowPassword(e.target.value === 'true' ? true : false)
  }

  return (
    <Container id='login'>
      <Column.Group>
        <Column />
        <Column size='one-third'>
          {hasResetPassword && <Notification color='success'>
            Password successfully change
                               </Notification>}
        </Column>
        <Column />
      </Column.Group>
      <Column.Group>
        <Column size={4} offset={4}>
          <Box>
            <Image.Container>
              <Image className='logo' src={AnimalDataSourceLogo} />
            </Image.Container>
            <form onSubmit={handleSubmit(onSubmitData)}>
              <Field>
                <Control iconLeft>
                  <Input
                    name='email'
                    ref={register({
                      required: true,
                      validate: validateUserName
                    })}
                    color='light'
                    type='email'
                    placeholder={t('email')}
                    autoFocus=''
                  />
                  {errors.email && errors.email.type === 'required' && (
                    <FormError
                      showMessage
                      type='danger'
                      message='Required'
                      closeButton={false}
                    />
                  )}
                  {errors.email && errors.email.type === 'validate' && (
                    <FormError message={t('mustUse bill@gmail.com')} />
                  )}
                  <Icon align='left'>
                    <FontAwesomeIcon icon={['fas', 'envelope']} />
                  </Icon>
                </Control>
              </Field>
              <Level>
                <Level.Item align="left">     
                  <Level.Item>
                    <Field>
                      <Control iconLeft>
                        <Input
                          name='password'
                          ref={register({ required: true, minLength: 2 })}
                          color='light'
                          type={showPassword ? 'text' : 'password'}
                          placeholder={t('password')}
                        />
                        {errors.password && errors.password.type === 'required' && (
                          <FormError
                            showMessage
                            type='danger'
                            message='Required'
                            closeButton={false}
                          />
                        )}
                        <Icon align='left'>
                          <FontAwesomeIcon icon={['fas', 'lock']} />
                        </Icon>
                      </Control>
                    </Field>
                  </Level.Item>
                  <Level.Item align="right">
                    <Control iconLeft>
                      <Select.Container>
                        <Select value={showPassword} onChange={togglePassVisibility}>
                          <Select.Option value='true'> 
                            Mostrar
                          </Select.Option>
                          <Select.Option value='false'> 
                            Ocultar
                          </Select.Option>
                        </Select>
                      </Select.Container>
                      <Icon
                        align='left'
                      >
                        <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                      </Icon>
                    </Control>
                  </Level.Item>
                </Level.Item>
              </Level>
              {errorAction.showMessage && <FormError {...errorAction} />}
              <Button color='info' fullwidth>
                <Container>{t('login')}</Container>
                <Icon>
                  <FontAwesomeIcon size='lg' icon={['fas', 'sign-in-alt']} />
                </Icon>
              </Button>
            </form>
          <Container style={{ paddingTop: '10px' }} textAlign='centered' textColor='grey'>
            &nbsp;·&nbsp;<Link to='/forgotPassword'>{t('forgotPassword')}</Link>
            &nbsp;·&nbsp;
          </Container>
          </Box>
        </Column>
      </Column.Group>
    </Container>
  )
}
