/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import {
  Select,
  Textarea,
  Box,
  Title,
  Field,
  Input,
  Control,
  Label,
  Button,
  Icon
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11'
import { FormError, HelpError } from '../../../error'
import { logic } from '../../../../logic'
import { TabsProduct } from '../../tabsProduct'
import { useConfirmActionMessage } from '../../../../logic/hooks'

// import { FormError, HelpError } from '../error'

export const AddEditProductTenant = () => {
  const alert = useAlert()
  // productTenants
  const [product, setProduct] = useState()
  // productGroups
  const [groups, setGroups] = useState()
  // productBrands
  const [brands, setBrands] = useState()
  // productBusinessUnits
  const [businessUnits, setBusinessUnits] = useState()
  // productSets
  const [sets, setSets] = useState()
  // Error handling
  const confirmAction = useConfirmActionMessage()

  const { t } = useTranslation()
  const history = useHistory()
  const { id } = useParams()
  const { register, handleSubmit, errors, watch } = useForm()

  // componentDidMount ---> get productBrands, the productTenant, productBusinessUnits, productGroups
  useEffect(() => {
    const sessionId = logic.sessionId.toString()
    if (id) {
      logic
        .getOneProductTenant({ productTenantId: id })
        .then(product => {
          setProduct(product && product.data && product.data[0])
        })
        .catch(err => {
          alert.show(err.message, {
            type: 'error'
          })
        })
    }
    logic
      .getProductBrands(sessionId)
      .then(brands => {
        const sortedBrands =
          brands && brands.data &&
          brands.data.sort((a, b) => {
            if (a.productbrandname.toLowerCase() < b.productbrandname.toLowerCase()) {
              return -1
            }
            if (a.productbrandname.toLowerCase() > b.productbrandname.toLowerCase()) {
              return 1
            }
            return 0
          })
        setBrands(sortedBrands)
      })
      .catch(err => {
        alert.show(err.message, {
          type: 'error'
        })
        alert.show(err.message, {
          type: 'error'
        })
      })

    logic
      .getProductBusinessUnits(sessionId)
      .then(businessUnits => {
        const sortedBusinessUnits =
          businessUnits && businessUnits.data &&
          businessUnits.data.sort((a, b) => {
            if (a.productbusinessunitname.toLowerCase() < b.productbusinessunitname.toLowerCase()) {
              return -1
            }
            if (a.productbusinessunitname.toLowerCase() > b.productbusinessunitname.toLowerCase()) {
              return 1
            }
            return 0
          })
        setBusinessUnits(sortedBusinessUnits)
      })
      .catch(err => {
        alert.show(err.message, {
          type: 'error'
        })
      })

    logic
      .getProductGroups(sessionId)
      .then(groups => {
        const sortedGroups =
          groups && groups.data &&
          groups.data.sort((a, b) => {
            if (a.productgroupname.toLowerCase() < b.productgroupname.toLowerCase()) {
              return -1
            }
            if (a.productgroupname.toLowerCase() > b.productgroupname.toLowerCase()) {
              return 1
            }
            return 0
          })

        setGroups(sortedGroups)
      })
      .catch(err => {
        alert.show(err.message, { type: 'error' })
      })

      logic
      .getProductSets()
      .then(sets => {
        const sortedSets =
        sets && sets.data &&
        sets.data.sort((a, b) => {
          if (a.productsetname.toLowerCase() < b.productsetname.toLowerCase()) {
            return -1
          }
          if (a.productsetname.toLowerCase() > b.productsetname.toLowerCase()) {
            return 1
          }
          return 0
        })

        setSets(sortedSets)
      })
      .catch(err => {
        alert.show(err.message, { type: 'error' })
      })
  }, [])

  const handleGoBackClick = () => {
    history.push('/product/tenant')
  }

  const onSubmitData = data => {
    const { code, productName, brand, group, businessUnit, description, set } = data
    confirmAction.closeMessage()
    if (id) {
      logic
        .updateProductTenant({
          productTenantId: id.toString(),
          productTenantCode: code,
          productName: productName,
          productBrandId: brand.toString(),
          productBusinessUnitId: businessUnit.toString(),
          productGroupId: group.toString(),
          productSetId: set.toString(),
          productDescription: description
        })
        .then(product => {
          if (product.status !== 200) {
            throw Error(`${product.status}`)
          }
          alert.show(t('updateSudccesful'), {
            type: 'success'
          })
        })
        .catch(err => {
          if (err.message === '409') {
            alert.show('Input already exists', {
              type: 'error'
            })
          } else {
            alert.show('Unexpected Error', {
              type: 'error'
            })
          }
        })
    } else {
      logic
        .insertProductTenant({
          productTenantCode: code,
          productName: productName,
          productBrandId: brand.toString(),
          productBusinessUnitId: businessUnit.toString(),
          productGroupId: group.toString(),
          productSetId: set.toString(),
          productDescription: description
        })
        .then(product => {
          if (product.status !== 200) {
            console.log(product)
            throw Error(product.error)
          }
          alert.show(t('productTenantSuccessfullyAdded'), {
            type: 'success'
          })
          history.push({
            pathname: '/product/tenant',
            state: '200'
          })
        })
        .catch(err => {
            alert.show(t(err.message), {
              type: 'error'
            })
        })
    }
  }

  return (
    <Box>
      <TabsProduct highlight='products' />
      <Button onClick={e => handleGoBackClick()} color='light' size='medium'>
        <Icon>
          <FontAwesomeIcon size='lg' icon={['fas', 'chevron-circle-left']} />
        </Icon>
      </Button>
      <Title>
        {id
          ? `${t('edit')} ${product && product.productname}`
          : `${t('add')} ${t('product')}`}
      </Title>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <Field>
          <Label>{t('code')}</Label>
          <Control iconLeft>
            <Input
              defaultValue={product && product.producttenantcode}
              type='text'
              color='light'
              name='code'
              ref={register({
                required: true,
                maxLength: 15
              })}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'archive']} />
            </Icon>
          </Control>
          {errors.code &&
            errors.code.type === 'required' && (
              <HelpError message={t('required')} />
            )}
          {errors.code &&
            errors.code.type === 'maxLength' && (
              <HelpError message={t('tooManyCharacters')} />
            )}
        </Field>

        <Field>
          <Label>{t('name')}</Label>

          <Control iconLeft iconRight>
            <Input
              defaultValue={product && product.productname}
              type='type'
              color='light'
              name='productName'
              ref={register({
                maxLength: 1000,
                required: true
              })}
            />
            <Icon size='small' align='left'>
              <FontAwesomeIcon icon={['fas', 'file-signature']} />
            </Icon>
          </Control>
          {errors.productName && errors.productName.type === 'maxLength' && (
            <HelpError message={t('tooManyCharacters')} />
          )}
          {errors.productName &&
            errors.productName.type === 'required' && (
              <HelpError message={t('required')} />
            )}
        </Field>

        <Field>
          <Label>{t('brand')}</Label>
          <Control expanded>
            <Select.Container color='light' fullwidth>
              <Select
                name='brand'
                ref={register({
                  required: true
                })}
                value={watch('brand') || undefined}
              >
                <Select.Option value=''>{t('select')}</Select.Option>

                {brands &&
                  brands.map((brand, index) => {
                    return (
                      <Select.Option
                        key={index}
                        value={brand && `${brand.productbrandid}`}
                        selected={brand && product && brand.productbrandid === product.productbrandid ? 'selected' : null}
                      >
                        {brand && brand.productbrandname}
                      </Select.Option>
                    )
                  }
                  )}

              </Select>

            </Select.Container>
            {errors.brand &&
              errors.brand.type === 'required' && (
                <HelpError message={t('required')} />
              )}
          </Control>
        </Field>

        <Field>
          <Label>{t('businessUnit')}</Label>
          <Control expanded>
            <Select.Container color='light' fullwidth>
              <Select
                name='businessUnit'
                value={watch('businessUnit') || undefined}
                ref={register({ required: true })}
              >
                <Select.Option value=''>{t('select')}</Select.Option>
                {businessUnits &&
                  businessUnits.map((businessUnit, index) => (
                    <Select.Option
                      key={index}
                      value={businessUnit && businessUnit.productbusinessunitid}
                      selected={businessUnit && product && businessUnit.productbusinessunitid === product.productbusinessunitid ? 'selected' : null}
                    >
                      {businessUnit && businessUnit.productbusinessunitname}
                    </Select.Option>
                  ))}
              </Select>
            </Select.Container>
            {errors.businessUnit &&
              errors.businessUnit.type === 'required' && (
                <HelpError message={t('required')} />
              )}
          </Control>
        </Field>

        <Field>
          <Label>{t('group')}</Label>
          <Control expanded>
            <Select.Container color='light' fullwidth>
              <Select
                name='group'
                value={watch('group') || undefined}
                ref={register({
                  required: true
                })}
              >
                <Select.Option value=''>{t('select')}</Select.Option>
                {groups &&
                  groups.map((group, index) => (
                    <Select.Option
                      key={index}
                      value={group && group.productgroupid}
                      selected={group && product && group.productgroupid === product.productgroupid ? 'selected' : null}
                    >
                      {group && group.productgroupname}
                    </Select.Option>
                  ))}
              </Select>
            </Select.Container>
            {errors.group &&
              errors.group.type === 'required' && (
                <HelpError message={t('required')} />
              )}
          </Control>
        </Field>

        <Field>
          <Label>{t('Conjunto')}</Label>
          <Control expanded>
            <Select.Container color='light' fullwidth>
              <Select
                name='set'
                value={watch('set') || undefined}
                ref={register({
                  required: true
                })}
              >
                <Select.Option value=''>{t('select')}</Select.Option>
                {sets &&
                  sets.map((set, index) => (
                    <Select.Option
                      key={index}
                      value={set && set.productsetid}
                      selected={set && product && set.productsetid === product.productsetid ? 'selected' : null}
                    >
                      {set && set.productsetname}
                    </Select.Option>
                  ))}
              </Select>
            </Select.Container>
            {errors.set &&
            errors.set.type === 'required' && (
              <HelpError message={t('required')} />
            )}
          </Control>
        </Field>

        <Field>
          <Label>{t('description')}</Label>
          <Control>
            <Textarea
              defaultValue={product && product.productdescription}
              color='light'
              name='description'
              ref={register({
                maxLength: 1000
              })}
              placeholder={t('description')}
            />
          </Control>
          {errors.description && errors.description.type === 'maxLength' && (
            <HelpError message={t('tooManyCharacters')} />
          )}
        </Field>

        <Field kind='group'>
          <Control>
            {id ? (
              <Button color='link'>{t('update')}</Button>
            ) : (
                <Button color='link'>{t('add')}</Button>
              )}
          </Control>
          <Control>
            <Button as={Link} to='/product/tenant' type='button' text>
              {t('cancel')}
            </Button>
          </Control>
          {confirmAction.showMessage && <FormError {...confirmAction} />}
        </Field>
      </form>
    </Box>
  )
}
