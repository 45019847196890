// AMN - this is a function used by four components:
// a) SalesDistributorsReport (report for sales)
// b) StockDistributorsReport (report for stocks)
// c) ClientsReport (report for clients)
// d) ClientsCategoriesReport (report for client's categories and client's groups sales)
import { FROMTYPEOFSELECTIONTOARRAYPOSITION, FROMARRAYPOSITIONTOTYPEOFSELECTION, FROMPLURALTYPEOFSELECTIONTOSINGULAR } from '../../../config/config'

export const filtersMgmt = (newFiltersOptionsAndSelection, doesItActsAsResetFilter = false) => {
    const { typeOfSelection, selectedItem, selected, allSelected } = newFiltersOptionsAndSelection.input
    const data = newFiltersOptionsAndSelection.input.data && [...newFiltersOptionsAndSelection.input.data]
    let newSelectedRegions = []
    let newSelectedProvinces = []
    let newSelectedPopulations = []
    let newSelectedPostalCodes = []
    let newSelectedBrands = []
    let newSelectedGroups = []
    let newSelectedBusinessUnits = []
    let newSelectedSets = []
    let newSelectedCIFs = []
    let newSelectedClientCategories = []

    const prepareNewSelections = (allSelections, typeOfSelection, selectedItem, selected, allSelected) => {
        // there is a relation between regions, provinces, populations and postal codes therefore
        // when we empty (for example) the regions all the provinces, populations and postalcode must 
        // be emptied
        let higherElementToEmpty
        if (FROMTYPEOFSELECTIONTOARRAYPOSITION[typeOfSelection] < 3 && 
            FROMTYPEOFSELECTIONTOARRAYPOSITION[typeOfSelection] > 0 &&
            !doesItActsAsResetFilter) { 
              higherElementToEmpty = 3
        } else {
              higherElementToEmpty = 999
        }
        let lowerElementToEmpty = FROMTYPEOFSELECTIONTOARRAYPOSITION[typeOfSelection] < 3 ? FROMTYPEOFSELECTIONTOARRAYPOSITION[typeOfSelection] + 1 : 999
        for (let i = 0; i < 10; i++) {
          if (i === FROMTYPEOFSELECTIONTOARRAYPOSITION[typeOfSelection]) {
              allSelections[FROMTYPEOFSELECTIONTOARRAYPOSITION[typeOfSelection]] = [...selected]
          } else {
              if (allSelected[i] === 'n/a') continue // if a filter is not used we do nothing
              selectedItem !== 'removed' // if not removed is added
                ? allSelections[i] = [...new Set([...allSelected[i]]
                    .concat(data.filter(element => { return element[FROMPLURALTYPEOFSELECTIONTOSINGULAR[typeOfSelection]] === selectedItem})
                    .map(element => { return element[FROMARRAYPOSITIONTOTYPEOFSELECTION[i]] })))]
                    .sort()
                : selected.length ? allSelections[i] = [...allSelected[i]]
                                  : i >= lowerElementToEmpty && i <= higherElementToEmpty ? allSelections[i] = []
                                                                                          : allSelections[i] = [...allSelected[i]]  
          }
        }
        return allSelections
    }
    const prepareNewSearchSales = (newSelectedFilters, newSearchSales) => {
        for (let i = 0; i < 10; i++) {
            if (newSelectedFilters[i] && newSelectedFilters[i].length) {
            newSearchSales = newSearchSales.filter(function (el) {
                return newSelectedFilters[i].includes(el[FROMARRAYPOSITIONTOTYPEOFSELECTION[i]])
            }) 
            }
        }
        return newSearchSales
    }
    const prepareArrays = (newSelectedFilters, newOptionsArrays) => {
        for (let i = 0; i < 10; i++) {
            if (newSelectedFilters[i] && newSelectedFilters[i].length) {
                for (let y = 0; y < 9; y++) {
                    if (i - 1 === y ) continue
                    newOptionsArrays[y] = newOptionsArrays[y].filter(function (el) {
                    return newSelectedFilters[i].includes(el[FROMARRAYPOSITIONTOTYPEOFSELECTION[i]])
                    })
                }
            }
        }
        return newOptionsArrays
    }

    // There is a hierarchical order between regions, provinces, populations and postal codes
    // The regions contain provinces, these populations and these postal codes
    // We make sure than when we select a province, population or a postal code their 
    // related higher hierarchical geographical areas are selected as well 
    if (typeOfSelection !== 'Empty') {
        [
            newSelectedRegions, newSelectedProvinces, newSelectedPopulations, 
            newSelectedPostalCodes, newSelectedBrands, newSelectedGroups, 
            newSelectedBusinessUnits, newSelectedSets, newSelectedCIFs, newSelectedClientCategories
        ] 
        = 
        prepareNewSelections(
            [newSelectedRegions, newSelectedProvinces, 
            newSelectedPopulations, newSelectedPostalCodes, newSelectedBrands, 
            newSelectedGroups, newSelectedBusinessUnits, newSelectedSets, newSelectedCIFs, newSelectedClientCategories], 
            typeOfSelection, selectedItem, selected,
            allSelected
        )
    }
    newFiltersOptionsAndSelection.output.newSelections 
    = 
    [newSelectedRegions, newSelectedProvinces, 
        newSelectedPopulations, newSelectedPostalCodes, newSelectedBrands, 
        newSelectedGroups, newSelectedBusinessUnits, newSelectedSets, newSelectedCIFs, newSelectedClientCategories
    ]

    let arrayProvince = data && [...data]
    let arrayPopulation = data && [...data]
    let arrayPostalCode = data && [...data]
    let arrayBrand = data && [...data]
    let arrayGroup = data && [...data]
    let arrayBusinessUnit = data && [...data]
    let arraySet = data && [...data]
    let arrayCIFs = data && [...data]
    let arrayClientCategory = data && [...data]

    // The list of sales shown on the screen depends on the different filters selected
    newFiltersOptionsAndSelection.output.newSearchSales 
    = 
    prepareNewSearchSales([newSelectedRegions, newSelectedProvinces, newSelectedPopulations,
        newSelectedPostalCodes, newSelectedBrands, newSelectedGroups,
        newSelectedBusinessUnits, newSelectedSets, newSelectedCIFs, newSelectedClientCategories], 
        newFiltersOptionsAndSelection.output.newSearchSales
    )

    // The array of options depends on the selections done except
    // for the regions that always can be selected or deselected no 
    // matter which other selections have been done
    const newArrayOptions 
    =
    prepareArrays([newSelectedRegions, newSelectedProvinces, newSelectedPopulations,
                   newSelectedPostalCodes, newSelectedBrands, newSelectedGroups,
                   newSelectedBusinessUnits, newSelectedSets, newSelectedCIFs, newSelectedClientCategories],
                   [[...arrayProvince], [...arrayPopulation], [...arrayPostalCode], 
                    [...arrayBrand], [...arrayGroup], [...arrayBusinessUnit], [...arraySet], [...arrayCIFs], [...arrayClientCategory]])

    newFiltersOptionsAndSelection.output.newArrays 
    = 
    [[...newArrayOptions[0]], [...newArrayOptions[1]], 
    [...newArrayOptions[2]], [...newArrayOptions[3]], [...newArrayOptions[4]], 
    [...newArrayOptions[5]], [...newArrayOptions[6]], [...newArrayOptions[7]], [...newArrayOptions[8]]
    ]    
}