import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Icon } from 'rbx'

export const ActionButtons = (props) => {
  let editButtonDisabled = false
  let deleteButtonDisabled = false
  if (props.hasOwnProperty('role')) {
    editButtonDisabled = props.role === true ? true : false 
    deleteButtonDisabled = props.role === true ? false : true 
  } 
  return (
    <>
      <button onClick={props.onEditClick} className='button is-primary is-outlined is-small' disabled={editButtonDisabled}>
        <Icon>
          <FontAwesomeIcon size='lg' icon={['fas', 'edit']} />
        </Icon>
      </button>
      <button onClick={props.onDeleteClick} className='button is-danger is-outlined is-small' disabled={deleteButtonDisabled}>
        <Icon>
          <FontAwesomeIcon size='lg' icon={['fas', 'trash']} />
        </Icon>
      </button>
    </>
  )
}
