import React from 'react'
import './Legal.scss'
import {
  Image
} from 'rbx'
import AnimalDataSourceLogo from '../../config/images/ADS_fondoblanco.png'

export const PoliticaCookies = () => (
  <>
    <div id="history" className="scroll-area">
      <Image.Container>
           <Image className='logo' src={AnimalDataSourceLogo} />
      </Image.Container>
      <br></br>  
      <p>
        <strong>Política de Cookies de Animal Data Source</strong>
        <br/>
        Una cookie es un fichero de pequeño tamaño que los sitios web envían al navegador y descargan en su ordenador. Las cookies permiten que la página web almacene y recupere la información sobre su visita, como el idioma y otras opciones, con el fin de mejorar los servicios que se ofrecen y contribuir a tener una mejor experiencia en la navegación para el usuario. 
      </p>
      <p>
        <strong>1.	Uso de cookies</strong>
      </p>
      <p>
        Animal Data Source App emplea únicamente cookies técnicas de sesión.
        <br/>
        Son cookies temporales que permanecen en el archivo de cookies de su navegador mientras está en nuestra página web. Estas cookies son necesarias y permiten que este Sitio funcione adecuadamente.
      </p>
      <p>
        <strong>2.	Cookies de terceros</strong>
      </p>
      <p>
        No utilizamos cookies de terceros.
      </p>
      <p>
        <strong>3.	Web beacons o pixel tags  </strong>
      </p>
      <p>
        Está aplicación no utiliza web beacons o pixel tags. 
      </p>
      <p>
        <strong>4.	Cambios en la Política de Cookies</strong>
      </p>
      <p>
        Ambit BST podrá modificar en cualquier momento las condiciones aquí determinadas, siendo debidamente publicadas como aquí aparecen. Las presentes condiciones estarán vigentes hasta que sean modificadas por otras debidamente publicadas
      </p>
    </div>
  </>
)
