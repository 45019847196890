import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { logic } from '../../../logic'
import { TabsProduct } from '../tabsProduct'
import { useAlert } from 'react-alert'
import {
  Title,
  Level,
  Field,
  Icon,
  Button,
  Table,
  Box
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Pagination } from '../../pagination'
import { useHistory, useRouteMatch, useParams } from 'react-router-dom'
import { useToggle } from '../../../logic/hooks'
import { Search } from '../../filter/Search'
import { Modal } from '../../modal'

export const ProductTenantCorrespondance = () => {
  const alert = useAlert()
  // Hooks for toggling on and off the modal
  const [open, toggle] = useToggle(false)

  const [deleteItem, setDeleteItem] = useState()

  // state inside the modal
  const [related, setRelated] = useState([])

  // main state
  const [products, setProducts] = useState()

  const [product, setProduct] = useState()

  // products that are searched for
  const [searchProducts, setSearchProducts] = useState([])

  // i18n hook
  const { t } = useTranslation()

  // Routing Variables
  const { url } = useRouteMatch()
  const history = useHistory()

  // State refering to the pagination attributes comming from the pagination component
  const defaultPaginationStartPosition = 0
  const defaultPaginationEndPosition = 10
  const [startPosition, setStartPosition] = useState(
    defaultPaginationStartPosition
  )
  const [endPosition, setEndPosition] = useState(defaultPaginationEndPosition)

  const { id } = useParams()

  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const paginationRef = useRef()

  // Fetch products when component mounts
  useEffect(() => {
    if (id) {
      logic.getProductTenantCorrespondance({ productTenantId: id.toString() }).then(products => {
        const sortedProducts =
        products && products.data &&
        products.data.sort((a, b) => {
          if (a.productname.toLowerCase() < b.productname.toLowerCase()) {
            return -1
          }
          if (a.productname.toLowerCase() > b.productname.toLowerCase()) {
            return 1
          }
          return 0
        })
        setProducts(sortedProducts)
        setSearchProducts(sortedProducts)
      })
      logic.getOneProductTenant({ productTenantId: id }).then(product => {
        setProduct(product && product.data && product.data[0])
      })
    }
  }, [])

  // handles the pagination requests
  const handleRequestPagination = requestPaginationObject => {
    let newStartPosition = defaultPaginationStartPosition
    let newEndPosition = defaultPaginationEndPosition
    if (requestPaginationObject) {
      const { currentPage, itemsPerPage } = requestPaginationObject
      newStartPosition = currentPage * itemsPerPage - itemsPerPage
      newEndPosition = newStartPosition + itemsPerPage
    }
    setStartPosition(newStartPosition)
    setEndPosition(newEndPosition)
  }

  // handles routing to add/edit
  const handleAddEditClick = (event, element) => {
    if (element) {
      history.push({
        pathname: `/correspondance/edit/${element && element.productdistributorid}/${id}`,
        state: element
      })
    }
  }

  // deletes the item
  const handleDeleteClick = (event, element) => {
    const id = `${element.productdistributorid}`
    const pro = product
    logic
      .deleteProductCorrespondance({ productTenantId: product && product.producttenantid.toString(), productDistributorId: id.toString() })
      .then(item => {
        if (item.status !== 200) {
          alert.show(t('Correspondence not deleted').concat(`. Error: ${item.error}`), {
            type: 'error'
          })
          toggle()
          return
        }
        const newProducts = products.filter(product => {
          return product.producttenantid !== item.data[0].producttenantid && product.productdistributorid !== item.data[0].productdistributorid
        })

        const sortedNewProducts =
        newProducts &&
        newProducts.sort((a, b) => {
          if (a.tenantproductname < b.tenantproductname) {
            return -1
          }
          if (a.tenantproductname > b.tenantproductname) {
            return 1
          }
        })
        setProducts(sortedNewProducts)
        setSearchProducts(sortedNewProducts)
        toggle()
        alert.show(t('Correspondence deleted'), {
          type: 'success'
        })
      })
      .catch(err => {
        alert.show(t('Unexpected error').concat(`. Error: ${err.message}`), {
          type: 'error'
        })
        toggle()
      })
  }

  const showRelatedItems = (event, item) => {
    setDeleteItem({ ...item })

    // TODO set correct logic for related
    setRelated([])
    toggle()
  }

  const handleDoSearch = value => {
    const result = products.filter(product => {
      const values = Object.values(product)

      const isIncluded = values
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(value.toLowerCase().replace(/\s+/g, ''))
      // pagination Reset vallues
      paginationRef.current.resetValues()

      return isIncluded
    })
    const sortedResult = result.sort((a, b) => {
      if (a.productname.toLowerCase() < b.productname.toLowerCase()) {
        return -1
      }
      if (a.productname.toLowerCase() > b.productname.toLowerCase()) {
        return 1
      }
      return 0
    })
    setSearchProducts(sortedResult)
    return sortedResult
  }

  return (
    <Box>
      {open && (
        <Modal toggle={toggle} open={open}>
          <Title>{t('confirmDelete')}</Title>
          {related && related.length !== 0 && (
            <Title subtitle size={6}>
              {t('cannotDeleteRelated')}
            </Title>
          )}
          {related &&
            related.length !== 0 &&
            related.length > 7 &&
            related.slice(0, 7).map((item, index) => {
              return (
                <>
                  <Field key={index}>{item.productname}</Field>
                </>
              )
            })}
          {related &&
            related.length !== 0 &&
            related.length <= 7 &&
            related.map((item, index) => {
              return (
                <>
                  <Field key={index}>{item.productname}</Field>
                </>
              )
            })}
          {related && related.length !== 0 && related.length > 7 && (
            <Field>
              ...{t('and')} {related.length - 7} {t('others')}
            </Field>
          )}
          {related && related.length === 0 && (
            <>
              <Title subtitle>{t('deleteSure')}</Title>
              <Button
                onClick={e => handleDeleteClick(e, deleteItem)}
                color='danger'
              >
                {t('delete')}
              </Button>
            </>
          )}
        </Modal>
      )}
      <TabsProduct highlight='products' />
      <Title>{t('Correspondencia de')} {product && product.productname}</Title>
      <Title subtitle>{product && product.producttenantcode}</Title>
      <Level>
        <Level.Item>
          <Search doSearch={handleDoSearch} />
        </Level.Item>
      </Level>
      <Table hoverable bordered striped narrow fullwidth>
        <Table.Head>
          <Table.Row>
            <Table.Heading>{t('distributorName')}</Table.Heading>
            <Table.Heading>{t('productName')}</Table.Heading>
            <Table.Heading>{t('productDistributorCode')}</Table.Heading>
            <Table.Heading>{t('correspondance')}</Table.Heading>
            <Table.Heading>{t('action')}</Table.Heading>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {searchProducts &&
            searchProducts.slice(startPosition, endPosition).map(item => {
              return (
                <Table.Row key={item.productdistributorid}>
                  <Table.Heading>{item.distributorname}</Table.Heading>
                  <Table.Cell>{item.productname}</Table.Cell>
                  <Table.Cell>{item.productdistributorcode}</Table.Cell>
                  <Table.Cell>{item.correspondancefactor}</Table.Cell>
                  <Table.Cell>
                    <button
                      onClick={e => handleAddEditClick(e, item)}
                      className='button is-primary is-outlined is-small' disabled={parseInt(logic.distributorId) === item.distributortenantid}
                    >
                      <Icon>
                        <FontAwesomeIcon size='lg' icon={['fas', 'edit']} />
                      </Icon>
                    </button>
                    <button
                      onClick={e => showRelatedItems(e, item)}
                      className='button is-danger is-outlined is-small' disabled={parseInt(logic.distributorId) === item.distributortenantid}
                    >
                      <Icon>
                        <FontAwesomeIcon size='lg' icon={['fas', 'trash']} />
                      </Icon>
                    </button>
                  </Table.Cell>
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table>
      <Pagination
        totalItems={searchProducts && searchProducts.length}
        ref={paginationRef}
        requestPagination={handleRequestPagination}
      />
    </Box>
  )
}
