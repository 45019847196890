/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Table, Button, Row, Col, Icon, Upload, Modal, Input } from 'antd'
import { ExcelRenderer } from '../../logic/excelRenderer'
import { EditableFormRow, EditableCell } from './editable'
import { useTranslation } from 'react-i18next'
import * as dayjs from 'dayjs'
import { logic } from '../../logic'
import { useConfirmActionMessage } from '../../logic/hooks'
import { useAlert } from 'react-alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import Highlighter from 'react-highlight-words'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { Title, Level } from 'rbx'
import { locale } from 'core-js'
import { Select, Card, Content } from 'rbx'
import moment from 'moment'

dayjs.extend(customParseFormat)

const DATE_FORMAT = 'DD/MM/YYYY'

export const PriceExcelPage = (props) => {
  // Error handling
  const alert = useAlert()
  const confirmAction = useConfirmActionMessage()
  const confirmAction2 = useConfirmActionMessage()
  const confirmAction3 = useConfirmActionMessage()
  const confirmAction4 = useConfirmActionMessage()
  const confirmAction5 = useConfirmActionMessage()
  const confirmAction6 = useConfirmActionMessage()
  const confirmAction7 = useConfirmActionMessage()
  const confirmAction8 = useConfirmActionMessage()
  // Routing Variables
  const { url } = useRouteMatch()
  const history = useHistory()

  const { t, i18n } = useTranslation()

  // Quick and dirty flag to hide contents based on whether a provider and a date has been selected
  //const [isHidden, setIsHidden] = useState(true)

  // flag to indicate whether there has been a satisfactory validation check
  const [hasValidDataToUpload, setHasValidDataToUpload] = useState(false)

  // flag to indicate that the chech button is validating (loading)
  const [isLoadingValidation, setIsLoadingValidation] = useState(false)

  // flag to indicate that the delete button is validating
  const [isLoadingPage, setIsLoadingPage] = useState(false)

  // flag to indicate that the delete button is validating
  const [isLoadingUpload, setIsLoadingUpload] = useState(false)

  // flag to indicate whether there are already registries for the selected date and tenant
  const [hasRegistries, setHasRegistries] = useState(false)

  const [selectedTenant, setSelectedTenant] = useState()
  const [selectedTenantName, setSelectedTenantName] = useState()

  // state that controls the files that are being uploaded
  const [fileList, setFileList] = useState([])

  // state that controles whether the table is loading
  const [tableLoading, setTableLoading] = useState(false)

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [searchInput, setSearchInput] = useState()
  const [theProgress, setTheProgress] = useState()
  const [progressSeconds, setProgressSeconds] = useState()
  const [infoState, setInfoState] = useState(false)
  const [warningPrice, setWarningPrice] = useState(false);

  const [years, setYears] = useState()
  const [yearPrice, setYearPrice] = useState()

  const [priceList, setPriceList] = useState([]);

  const [successLoad, setSuccessLoad] = useState(false);

  const calculateTheProgress = () => {
    const theNewProgress = theProgress + 1
    return theNewProgress
  }

  useEffect(() => {
    if (progressSeconds !== 0) {
      const progress = setTimeout(() => { setTheProgress(calculateTheProgress()) }, progressSeconds)
      return () => clearTimeout(progress)
    } else {
      setTheProgress()
    }
  }, [theProgress])


  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            setSearchInput(node)
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon='search'
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type='search' style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record = '') =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.select())
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  })

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }

  const showConfirmSubmit = (data) => {
    let isOkClicked = false
    Modal.confirm({
      title: t('Confirm submit'),
      icon: <Icon type='exclamation-circle' />,
      content: t(
        'Please confirm that you want to upload the prices of the products', { selectedTenantName: selectedTenantName }
      ),
      onOk() {
        if (!isOkClicked) {
          isOkClicked = true
          handleSubmit(data)
        }
      },
      onCancel() {
        setHasValidDataToUpload(false)
      }
    })
  }

  const showModal = () => {
    setInfoState(true)
  }

  const handleOk = e => {
    setInfoState(false)
  }

  const handleCancel = e => {
    setInfoState(false)
  }

  const handleYearChange = (e) => {
    const { year } = e.target.form
    setHasValidDataToUpload(false);
    if (year.value === '') return
    setYearPrice(year.value)
  }

  useEffect(() => {
    let years = [];
    let start = 2020;
    let end = new Date().getFullYear() + 1;
    while (end >= start) {
      years.push(start);
      start++;
    }
    setYears(years);
    setYearPrice(years[years.length - 1]);
  }, [])

  useEffect(() => {
    logic
      .getProductPrices()
      .then((data) => {

        if (data.status !== 200) {
          throw Error(data.error)
        } else {
          console.log(data);
          setPriceList(data);
        }
      });
    const newRows1 = []
    setIsLoadingPage(false)
    setFirstPage((prevState) => {
      return {
        ...prevState,
        columns: columnsPrices.columns,
        rows: newRows1,
        errorMessage: null
      }
    })
      setHasRegistries(false)
  }, [])

  const renderErrorCell = (text, record) => {
    // it will check if the row has an hasError property and if it is the case it will render one or another.
    const {hasDuplicateError, hasCurrencyError, hasProductError, hasRangeError, hasPriceError, hasPriceWarning, hasYearStartDateError, hasYearEndDateError,hasStartDateFormated,hasEndDateFormated,hasEndMinorStartDate } = record
    let hasError = hasCurrencyError || hasProductError || hasRangeError || hasPriceError || hasCurrencyError || hasYearStartDateError || hasYearEndDateError || hasDuplicateError || hasStartDateFormated || hasEndDateFormated || hasEndMinorStartDate
    if (hasError) {
      return (
        <Icon style={{ color: '#f5222d' }}>
          <FontAwesomeIcon size='lg' icon={['fas', 'exclamation-triangle']} />
        </Icon>
      )
    }
    if (hasPriceWarning) {
      return (
        <Icon style={{ color: '#ffcc00' }}>
          <FontAwesomeIcon size='lg' icon={['fas', 'exclamation-triangle']} />
        </Icon>
      )
    }


  }

  const [firstPage, setFirstPage] = useState({
    name: '',
    cols: [],
    rows: [],
    errorMessage: null
  })

  const columnsPrices = {
    name: '',
    cols: [],
    rows: [],
    errorMessage: null,
    columns: [
      {
        title: t('product'),
        dataIndex: 'product',
        editable: true,
        sorter: (a, b) => {
            if (!a.product || !b.product) {
              return -1
            }
            return ('' + a.product).localeCompare(b.product)
          },
        ...getColumnSearchProps('product')
      },
      {
        title: t('productName'),
        dataIndex: 'productName',
        editable: false,
        sorter: (a, b) => {
          if (!a.productName || !b.productName) {
            return -1
          }
          return ('' + a.productName).localeCompare(b.productName)
        },
        ...getColumnSearchProps('productName')
      },
      {
        title: t('grossPrice'),
        dataIndex: 'grossPrice',
        editable: true,
        sorter: (a, b) => {
          if (!a.grossPrice || !b.grossPrice) {
            return -1
          }
          return ('' + a.grossPrice).localeCompare(
            b.grossPrice
          )
        },
        ...getColumnSearchProps('grossPrice')
      },
      {
        title: t('currency'),
        dataIndex: 'currency',
        editable: true,
        sorter: (a, b) => {
          if (!a.currency || !b.currency) {
            return -1
          }
          return ('' + a.currency).localeCompare(
            b.currency
          )
        },
        ...getColumnSearchProps('currency')
      },
      {
        title: t('startDate'),
        dataIndex: 'startDate',
        editable: true,
        sorter: (a, b) => {
          if (!a.startDate || !b.startDate) {
            return -1
          }
          a = a.startDate.split('/').reverse().join('/')
          b = b.startDate.split('/').reverse().join('/')
          if (a < b) {
            return -1
          } else if (a > b) {
            return 1
          } else {
            return 0
          }
        },
        ...getColumnSearchProps('startDate')
      },
      {
        title: t('endDate'),
        dataIndex: 'endDate',
        editable: true,
        sorter: (a, b) => {
          if (!a.endDate || !b.endDate) {
            return -1
          }
          a = a.endDate.split('/').reverse().join('/')
          b = b.endDate.split('/').reverse().join('/')
          if (a < b) {
            return -1
          } else if (a > b) {
            return 1
          } else {
            return 0
          }
        },
        ...getColumnSearchProps('endDate')
      },
      {
        title: t('Errores'),
        dataIndex: 'errors',
        editable: false,
        sorter: (a, b) => {
          // if a has error and b doesn't
          if ((a.hasError)) {
            return -1
          }
          // if a doesn't have error and b does
          if ((!a.hasError)) {
            return 1
          }

          // if they both have an error
          if ((a.hasError)) {
            return 0
          }
          return 0
        },
        render: renderErrorCell
      }
    ]
  }

  const handleSave = (row) => {
    setHasValidDataToUpload(false);

    const newData = [...firstPage.rows]
    const index = newData.findIndex((item) => row.key === item.key)
    const item = newData[index]
    // if findIndex fails, don't splice
    if (index !== -1) {
      newData.splice(index, 1, {
        ...item,
        ...row
      })
    }

    setFirstPage((prevState) => {
      return { ...prevState, rows: newData }
    })
  }

  const handleFileListChange = (info) => {
    setHasValidDataToUpload(false);
    let fileList = [...info.fileList]
    // 1. Limit the number of uploaded files to 1
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(-1)

    setFileList([...fileList])
  }

  const fileHandler = (fileList) => {
    setIsLoadingUpload(true)
    // 1. Limit the number of uploaded files to 1
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    if (fileList.length) {
      fileList = fileList.slice(-1)
    }
    const fileObj = fileList
    if (!fileObj) {
      alert.show(t('Ningun archivo cargado'), {
        type: 'error'
      })
      setFirstPage((prevState) => {
        return { ...prevState, errorMessage: 'No file uploaded!' }
      })
      return false
    }
    if (!(fileObj.type === 'application/vnd.ms-excel' ||
      fileObj.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
      alert.show(t('Formato incorrecto, solo se acepta format Excel'), {
        type: 'error'
      })
      setFirstPage((prevState) => {
        return {
          ...prevState,
          errorMessage: 'Unknown file format. Only Excel files are uploaded!'
        }
      })
      return false
    }

    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        if (err === 'incorrectTemplate') {
          alert.show(t('Por favor utiliza la plantilla correcta'), {
            type: 'error'
          })
        }
      } else {
        const newRows1 = []
        if (resp[0].rows.length > 0) {
          setFirstPage((prevState) => {
            return { ...prevState, name: resp[0].wsname }
          })
        }
        resp.map((sheet, sheetIndex) => {
          sheet.rows.slice(1).map((row, index) => {
            if (row && row !== 'undefined') {
              // Trim all the sensitive data to make sure we have correct information
              row[1] = row[1] && row[1].toString().trim()
              row[2] = row[2] && row[2].toString().trim()
              row[3] = row[3] && row[3].toString().trim()
              row[4] = row[4] && row[4].toString().trim()
              row[5] = row[5] && row[5].toString().trim()
              const formatStartDate = dayjs(row[4]).format(
                DATE_FORMAT
              )
              const formatEndDate = dayjs(row[5]).format(
                DATE_FORMAT
              )
              const startYear = dayjs(row[4]).format("YYYY");
              const endYear = dayjs(row[5]).format("YYYY");

              let productName = "";
              let f = priceList.data.filter((element) => element.producttenantcode == row[0]);
              if (f.length > 0) {
                productName = f[0].productname;
              }

              let price = '';
              price = Number(row[1]);

              if ((Number.isNaN(price))) {
                price = '';
              }
              price = (price + "").replace(".", ",");

              return newRows1.push({
                key: `newSale-${index}`,
                product: row[0],
                productName: productName,
                grossPrice: price,
                currency: row[3],
                startYear,
                endYear,
                startDate: formatStartDate,
                endDate: formatEndDate,
              })
            }
            return null
          })
          if (newRows1.length === 0) {
            if (resp[0].rows.length > 0) {
              setFirstPage((prevState) => {
                return { ...prevState, errorMessage: 'No data found in file!' }
              })
            }
          }
        })
        if (newRows1.length !== 0) {
          setHasRegistries((prevState) => false)
          setFirstPage((prevState) => {
            return {
              ...prevState,
              cols: resp.cols,
              rows: newRows1,
              errorMessage: null
            }
          })
        }
      }
      setIsLoadingUpload(false)
    })
    setTableLoading(false)
    return false
  }

  const handleSubmit = async (dataSend) => {
    setTheProgress(0)

    let source = dataSend ? dataSend.rows : firstPage.rows;
    let sendToServer = [];
    source.forEach(element => {
      sendToServer.push({
        productId: element.productId,
        productName: element.productName,
        grossPrice: element.grossPrice,
        startDate: element.startDate,
        endDate: element.endDate,
        currency: element.currency
      })
    });

    setProgressSeconds(1000)
    const data = {
      prices: {
        name: dataSend ? dataSend.name : firstPage.name,
        data: sendToServer
      },
      tenantId:  logic.tenantId.toString(),
      year: yearPrice
    }
    confirmAction.closeMessage()
    confirmAction2.closeMessage()
    confirmAction3.closeMessage()
    confirmAction4.closeMessage()
    confirmAction5.closeMessage()
    confirmAction6.closeMessage()
    confirmAction7.closeMessage()
    console.log(data);
    setIsLoadingValidation(true)
    logic
      .insertPriceExcelPage(data)
      .then((data) => {
        if (data.status !== 200) {
          throw Error(data.error)
        }
        if (data.data && data.data.prices[0] === 'BLOCKED') {
          alert.show(t('Blocked'), {
            type: 'info',
            timeout: 5000
          })  
          history.push("/dashboard") 
        } else {
          setSuccessLoad(true);
          alert.show(t('Subida de datos satisfactoria'), {
            type: 'success',
          })  
        }
        setIsLoadingValidation(false)
        setHasValidDataToUpload(false)
        setHasRegistries(true)
        setFileList([])
        setProgressSeconds(0)
      })
      .catch((err) => {
        const { message } = err
        console.error(message)
        console.error("error")

        setIsLoadingValidation(false)
        setHasValidDataToUpload(false)
        setProgressSeconds(0)
        alert.show((message), {
          type: 'error',
          timeout: 0
        })
        setFirstPage((prevState) => {
          return { ...prevState, rows: [] }
        })
      })
  }

  // components for the editable rows
  const components1 = {
    body: {
      row: EditableFormRow,
      cell: EditableCell
    }
  }

  const newColumns1 =
    firstPage &&
    firstPage.columns &&
    firstPage.columns.map((col) => {
      if (!col.editable) { // if originally the distributor had data on the DDBB we don't allow changing it
        return col
      }
      return {
        ...col,
        onCell: (record) => {
          return {
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: handleSave
          }
        }
      }
    })

  const handleValidateDataToUpload = async () => {
    // validatemlg
    setIsLoadingValidation(true)
    setHasValidDataToUpload(false)
    try {
      confirmAction.closeMessage()
      confirmAction2.closeMessage()
      confirmAction3.closeMessage()
      confirmAction4.closeMessage()
      confirmAction5.closeMessage()
      confirmAction6.closeMessage()
      confirmAction7.closeMessage()
      let firstPageState = { ...firstPage }

      setFirstPage((prevState) => {
        firstPageState = prevState
        return { ...prevState }
      })
      // verify the dates
      console.log(priceList.data);
      if (priceList && priceList.data) {
        const checkExistProduct =
          firstPageState &&
          firstPageState.rows &&
          firstPageState.rows.map((row, index) => {
            let f = priceList.data.filter((element) => element.producttenantcode == row.product);
            return {
              ...row,
              productId: f.length == 0 ? null : f[0].producttenantid,
              hasProductError: f.length == 0,
            }
          });
        firstPageState = {
          ...firstPageState,
          rows: [...checkExistProduct]
        }

        const checkCurrency =
          firstPageState &&
          firstPageState.rows &&
          firstPageState.rows.map((row, index) => {
            return {
              ...row,
              hasCurrencyError: row.currency == undefined || row.currency == "" || row.currency != 'EUR',
            }

          });

        firstPageState = {
          ...firstPageState,
          rows: [...checkCurrency]
        }


        const checkPrice =
          firstPageState &&
          firstPageState.rows &&
          firstPageState.rows.map((row, index) => {
            let hasError = false;
            console.log(Number.isNaN(row.grossPrice));
            console.log(row.grossPrice);
            if (row.grossPrice == '' || Number.isNaN(row.grossPrice) || row.grossPrice == null || row.grossPrice < 0) {
              hasError = true;
            }

            return {
              ...row,
              hasPriceError: hasError,
            }

          });

        firstPageState = {
          ...firstPageState,
          rows: [...checkPrice]
        }

        const checkDuplicateError =
          firstPageState &&
          firstPageState.rows &&
          firstPageState.rows.map((row, index) => {
            let hasDuplicate = false;
            let duplicates = firstPageState.rows.filter((element) => element.product == row.product);
            if (duplicates.length > 1) {
              hasDuplicate = true;
            }
            return {
              ...row,
              hasDuplicateError: hasDuplicate,
            }

        });

        firstPageState = {
          ...firstPageState,
          rows: [...checkDuplicateError]
        }

        const checkPriceWarning =
        firstPageState &&
        firstPageState.rows &&
        firstPageState.rows.map((row, index) => {
          let warning = false;
          let year = 0;
          let priceYear = 0;
          let f = priceList.data.filter((element) => element.producttenantcode == row.product && element.productpriceyear != null && element.productpriceyear < yearPrice );
          if (f.length > 0) {
            var res = f.sort((a, b) => b.productpriceyear - a.productpriceyear);
            year = res[0].productpriceyear;
            priceYear = res[0].productprice;
            let compare = row.grossPrice.replace(",",".");
            warning = compare >= Number(res[0].productprice) * 2 || compare < Number(res[0].productprice)
          }
          return {
            ...row,
            year,
            priceYear,
            hasPriceWarning: warning,
          }

        });
        firstPageState = {
          ...firstPageState,
          rows: [...checkPriceWarning]
        }


        const checkDatesYear =
          firstPageState &&
          firstPageState.rows &&
          firstPageState.rows.map((row, index) => {

            let startDate = dayjs(row.startDate, 'DD/MM/YYYY');
            let endDate = dayjs(row.endDate, 'DD/MM/YYYY');
            row.startYear = dayjs(startDate).format("YYYY");
            row.endYear = dayjs(endDate).format("YYYY");
            let hasYearStartDateError = row.startYear != yearPrice;
            let hasYearEndDateError = row.endYear != yearPrice;


            let rangeError = false;
            let hasStartDateFormated = false;
            let hasEndDateFormated = false;
            let hasEndMinorStartDate = false;
            let startValid = moment(row.startDate, 'DD/MMM/YYYY').isValid() && evalDate(row.startDate);
            let endValid = moment(row.endDate, 'DD/MMM/YYYY').isValid() && evalDate(row.endDate);
            console.log(row.startDate + " " +startValid);
            if (startValid && endValid)  {
              if (endDate.isBefore(startDate)) {
                hasEndMinorStartDate = true;
              } else {
                let dates = priceList.data.filter((element) => element.producttenantcode == row.product);

                let f = dates.filter((element) => {
                  let found = false;
                  let localStartDate = dayjs(element.productpricefromdate);
                  let localEndDate = dayjs(element.productpricetodate);
                  if (!found) {
                    found = (startDate.isAfter(localStartDate) || startDate.isSame(localStartDate)) && (startDate.isBefore(localEndDate) || startDate.isSame(localEndDate));
                  }
                  if (!found) {
                    found = endDate.isAfter(localStartDate) && endDate.isBefore(localEndDate);
                  }
                  return found;
                });
                rangeError = f.length > 0;
              }
            } else {
              hasStartDateFormated = !startValid ? true : null;
              hasEndDateFormated = !endValid ? true : null;
            }
            return {
              ...row,
              hasEndMinorStartDate,
              hasStartDateFormated,
              hasEndDateFormated,
              hasRangeError: rangeError,
              hasYearStartDateError,
              hasYearEndDateError
            }

          });

        firstPageState = {
          ...firstPageState,
          rows: [...checkDatesYear]
        }
      }

      const setSorted =
        firstPageState &&
        firstPageState.rows &&
        firstPageState.rows.map((row, index) => {
          let sorted = 0;
          console.log(row.hasYearStartDateError);
          if (row.hasPriceWarning) {
            sorted = 99
          } else {
            if (
              row.hasPriceError || 
              row.hasRangeError || 
              row.hasCurrencyError ||
              row.hasProductError || 
              row.hasDateError || 
              row.hasDuplicateError || 
              row.hasStartDateFormated || 
              row.hasEndDateFormated ||
              row.hasEndMinorStartDate || 
              row.hasYearEndDateError || 
              row.hasYearStartDateError
            ) {
                  sorted = 1;
                }
          }
          return {
            ...row,
            sorted : sorted
          }

      });

      firstPageState = {
        ...firstPageState,
        rows: [...setSorted]
      }

      firstPageState.rows = firstPageState.rows.sort(({sorted:a}, {sorted:b}) => b-a);

      let filterp = firstPageState.rows.filter(((element) => 
        element.hasPriceError || 
        element.hasRangeError || 
        element.hasCurrencyError ||
        element.hasProductError || 
        element.hasDateError || 
        element.hasDuplicateError || 
        element.hasStartDateFormated || 
        element.hasEndDateFormated ||
        element.hasEndMinorStartDate || 
        element.hasYearEndDateError || 
        element.hasYearStartDateError));

      
      setFirstPage(firstPageState);
      setIsLoadingValidation(false)
      setHasValidDataToUpload(filterp.length == 0)

      let filterWarning = firstPageState.rows.filter(((element) => 
      element.hasPriceWarning));

      if (filterWarning.length > 0 && filterp.length == 0) {
        setWarningPrice(true);
      } else {
        setWarningPrice(false);
      }
      if (filterp.length == 0 && filterWarning.length == 0) {
        showConfirmSubmit(firstPageState);
      }


    } catch (ex) {
      console.log(ex);
      setIsLoadingValidation(false);
    }
  }

  const evalDate = (value) => { 
    if (value.length != 10) return false;
    const regexDate = new RegExp('[0-9]{2}[-|\/]{1}[0-9]{2}[-|\/]{1}[0-9]{4}');
    return regexDate.test(value);

  }

  const handleReloadPage = () => {
    window.location.reload();

  }
  const handleGoToProductPrices = () => {
    history.push('/product/value')
  }

  return (
    <>
      <div>
        <Card style={{ borderRadius: '6px' }}>
          <Card.Content>
            <Level.Item>
              <form>
                <Title size={6}>{t('year prices')}</Title>
                <Select.Container style={{ marginBottom: 30, marginTop: 10 }}>



                  <Select name='year' onChange={handleYearChange} defaultValue='' value={yearPrice && yearPrice}>
                    {years && years.map((year, index) => {
                      return (
                        <Select.Option key={year} value={year}>
                          {year}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Select.Container>

              </form>
            </Level.Item>
          </Card.Content>
        </Card>
        <br></br>
        <Row gutter={16}>
          <Col
              span={8}
              align='right'
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <a
                href={`${logic.url}/template/excel_2/prices`} 
                target='_blank'
                rel='noopener noreferrer'
                download
              >
                <Icon type='download' /> {t('Sample excel sheet')}
              </a>
              {firstPage.rows.length > 0 && (
                <>
                </>
              )}
          </Col>
          <Button onClick={e => handleGoToProductPrices()} color='light' size='medium'>
            <Icon>
              <FontAwesomeIcon icon={faLink} />
            </Icon>
            {t('productPrices')}
          </Button>
        </Row>
        <br></br>
        <Row>
          <Col span={8}>
            {!successLoad /* && originalFirstPage === 0 && originalSecondPage === 0 */ ? (
              <Upload
                name='file'
                onChange={handleFileListChange}
                fileList={fileList}
                beforeUpload={fileHandler}
                onRemove={() => {
                  setFirstPage((prevState) => {
                    return { ...prevState, rows: [] }
                  })
                  /* setSecondPage((prevState) => {
                    return { ...prevState, rows: [] }
                  }) */
                }}
                multiple={false}
                loading={isLoadingPage || isLoadingUpload}
              >
                <Button loading={isLoadingPage || isLoadingUpload} disabled={isLoadingValidation}>
                  {!isLoadingPage && !isLoadingUpload ? <Icon type='upload' /> : null}
                  {t('Click to Upload Excel File')}
                </Button>
              </Upload>
            ) : null}
           {successLoad && 
            <Button
                onClick={handleReloadPage}
                style={{background: 'rgb(255,199,45)', color: 'rgb(112,88,20)'}}
                color='warning'
                loading={isLoadingValidation || isLoadingPage}
              >
                {' '}
                {t('Reset')}
              </Button>
           }
           
           
          </Col>
          <Col span={4}>
            {(firstPage.rows.length /* || secondPage.rows.length */) &&
              !hasRegistries &&
              !hasValidDataToUpload ? (
              <Button
                onClick={handleValidateDataToUpload}
                type='primary'
                style={{ marginBottom: 16, marginLeft: 10 }}
                loading={isLoadingValidation || isLoadingPage}
              >
                {' '}
                {t('Check Data')}
              </Button>
            ) : null}
            {(firstPage.rows.length /* || secondPage.rows.length */) &&
              !hasRegistries &&
              hasValidDataToUpload ? (
              <Button
                onClick={() => showConfirmSubmit(firstPage)}
                type='primary'
                style={{backgroundColor: warningPrice ? '#FDE705' : null, color: warningPrice ? 'black': null, marginBottom: 16, marginLeft: 10 }}
                loading={isLoadingValidation}
              >
                {!isLoadingValidation ? <Icon type='cloud-upload' /> : null}
                {isLoadingValidation ? `${t('Enviando datos...')} ${Math.trunc(theProgress / 60).toString().padStart(2, '0')}:${Math.trunc(theProgress % 60).toString().padStart(2, '0')} min` : t('Submit Data')}
              </Button>
            ) : null}
          </Col>
          <Col span={4}>
            {(firstPage.rows.length /* || secondPage.rows.length */) &&
              !hasRegistries &&
              !hasValidDataToUpload ? (
              <>
                <Button type='link' onClick={showModal}>
                  <Icon type="info-circle" style={{ fontSize: '32px' }} theme="twoTone" />
                </Button>


                <Modal
                  title={t('pricesValidations')}
                  visible={infoState}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  width={1000}
                >
                  {i18n.language === 'es' ?
                    <>
                      <p>-	Código de producto:</p>
                      <p>&nbsp;&nbsp;&nbsp;.	El código es <strong>único</strong> (no se puede repetir).</p>
                      <p>&nbsp;&nbsp;&nbsp;.	El código de producto <strong>debe existir.</strong></p>
                      <p>-	Precio:</p>
                      <p>&nbsp;&nbsp;&nbsp;.	El precio debe ser <strong>mayor o igual que cero</strong> con como máximo <strong>dos decimales</strong>.</p>
                      <p>-	Divisa:</p>
                      <p>&nbsp;&nbsp;&nbsp;.	La divisa será <strong>siempre</strong> EUR.</p>

                      <p>-	Fecha de inicio:</p>
                      <p>&nbsp;&nbsp;&nbsp;.	Formato: DD/MM/YYYY</p>
                      <p>&nbsp;&nbsp;&nbsp;.	Debe ser <strong>inferior o igual</strong> a la fecha fin.</p>
                      <p>&nbsp;&nbsp;&nbsp;.	Fecha de inicio de vigencia del precio.</p>

                      <p>-	Fecha fin:</p>
                      <p>&nbsp;&nbsp;&nbsp;.	Formato: DD/MM/YYYY</p>
                      <p>&nbsp;&nbsp;&nbsp;.	Debe ser <strong>superior o igual</strong> a la fecha de inicio.</p>
                      <p>&nbsp;&nbsp;&nbsp;.	Fecha fin de vigencia del precio.</p>
                    </>
                    :
                    <>
                      <p>-	Product code:</p>
                      <p>&nbsp;&nbsp;&nbsp;.	The code is <strong>unique</strong> (must not be repeated).</p>
                      <p>&nbsp;&nbsp;&nbsp;.	The product code <strong>must exists.</strong></p>
                      <p>-	Price:</p>
                      <p>&nbsp;&nbsp;&nbsp;.	The price must be <strong>greater or equal than zero</strong> with a maximum of <strong>two decimals</strong>.</p>
                      <p>-	Currency:</p>
                      <p>&nbsp;&nbsp;&nbsp;.	The currency will be <strong>always</strong> EUR.</p>

                      <p>-	Start date:</p>
                      <p>&nbsp;&nbsp;&nbsp;.	Format: DD/MM/YYYY</p>
                      <p>&nbsp;&nbsp;&nbsp;.	Must be <strong>inferior or equal</strong> to the end date.</p>
                      <p>&nbsp;&nbsp;&nbsp;.	Start date of price validity.</p>


                      <p>-	Fecha fin:</p>
                      <p>&nbsp;&nbsp;&nbsp;.	Format: DD/MM/YYYY</p>
                      <p>&nbsp;&nbsp;&nbsp;.	Must be <strong>superior or equal</strong> to the start date.</p>
                      <p>&nbsp;&nbsp;&nbsp;.	End date of price validity.</p>
                    </>
                  }
                </Modal>

              </>
            ) : null}
          </Col>
        </Row>
      </div>
      <div style={{ marginTop: 20 }}>
        <Table
          components={components1}
          rowClassName={() => 'editable-row'}
          dataSource={firstPage.rows}
          columns={hasRegistries ? firstPage.columns : newColumns1}
          loading={tableLoading}
        />

      </div>
    </>
  )
}
