/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState, useContext } from 'react'
import { Box, Label, Checkbox, Field, Control, Button, Divider } from 'rbx'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FormError } from '../../error'
import { logic } from '../../../logic'
import { TabsTenant } from '../tabsTenant'
import { useConfirmActionMessage } from '../../../logic/hooks'
import { useAlert } from 'react-alert'
import { Switch, Popover } from 'antd'
import { AdsContext } from "../../../contexts/adsContext"
import { TableOfTables } from '../../../logic/tableOfTables'

export const BUTTON_STATES = { hovered: 'hovered', focused: 'focused', active: 'active', loading: 'loading' }
export const MyTenant = () => {
  // userTenant
  const [tenant, setTenant] = useState()

  const confirmAction = useConfirmActionMessage()
  const alert = useAlert()

  const { t } = useTranslation()
  const history = useHistory()
  const { userId } = useParams()
  const { register, handleSubmit } = useForm()
  const { allTableOfTables } = useContext(AdsContext)
  const isAGRContracted = TableOfTables('AGR', logic.tenantId.toString(), allTableOfTables, true)
  const isCLIGROUPContracted = TableOfTables('CLIGROUP', logic.tenantId.toString(), allTableOfTables, true)
  const isPVLContracted = TableOfTables('PVL', logic.tenantId.toString(), allTableOfTables, true)
  const isCATCLIContracted = TableOfTables('CATCLI', logic.tenantId.toString(), allTableOfTables, true)
  const [hasAnalytics, setHasAnalytics] = useState(false)

  const [hovered, setHovered] = useState(false)
  
  const handleHoverChange = visible => {
    setHovered(visible)
  }

  const hoverContent = (
    <div>
      Puede contratar y/o gestionar sus funcionalidades contactando con AMBIT BST al teléfono{' '}
      <a href="tel:0034935478899">+34935478899</a> o a{' '}
      <a href="mailto:servicedesk@ambit-bst.com">servicedesk@ambit-bst.com</a>
    </div>
  )

  // load
  useEffect(() => {
    logic.getTenant(userId)
    .then(tenant => {
      if (tenant.status !== 200) {
        alert.show('Error de red', {
          type: 'error'
        })
      }
      setTenant(tenant && tenant.data && tenant.data[0])
    })
    const distributorId = logic.distributorId
    logic.getDashboardsDistributor({ distributorId }).then((response) => {
      if (response && response.data && response.data[0]) {
        setHasAnalytics(true)
      } 
    })
  }, [])

  const onSubmitData = data => {

    const { notCrossSales } = data
    // we destructure as let because we wil be passing the city and province name, not id
    confirmAction.closeMessage()
    logic.
      updateTenant({notCrossSales})
      .then(tenant => {
        if (tenant.status !== 200) {
          throw Error(`${tenant.status}`)
        }
        alert.show(t('updateSuccesful'), { type: 'success' })
      })
      .catch(err => {
        if (err.message === '409') {
          alert.show(t('Input already exists'), { type: 'error' })
        } else {
          alert.show(t('Error inesperado'), { type: 'error' })
        }
      })
    logic.logout()
    .then(message => {
      history.push('/login')
    })
    .catch(error => {
      console.log(error.message)
    })
  }

  return (
    <Box>
      <TabsTenant highlight='mytenant' />
      <form onSubmit={handleSubmit(onSubmitData)}>
        <Label>{t('Contracted funcionalities')}:</Label>
        <Popover content={hoverContent} 
                 title="AMBIT BST contact details"
                 trigger="hover"
                 visible={hovered}
                 onVisibleChange={handleHoverChange}
                 placement="right">
            <Switch checkedChildren={t('ADS basic package')} disabled checked />
            <br/>
            {isAGRContracted ? <Switch checkedChildren={t('Agreements')} disabled checked /> : <Switch unCheckedChildren={t('Agreements')} disabled />}
            <br/>
            {isCLIGROUPContracted ? <Switch checkedChildren={t(`Clients' groups`)} disabled checked /> : <Switch unCheckedChildren={t(`Clients' groups`)} disabled />}
            <br/>
            {isPVLContracted ? <Switch checkedChildren={t('PVLs (list sales price)')} disabled checked /> : <Switch unCheckedChildren={t('PVLs (list sales price)')} disabled />}
            <br/>
            {isCATCLIContracted ? <Switch checkedChildren={t(`Clients' categories`)} disabled checked/> : <Switch unCheckedChildren={t(`Clients' categories`)} disabled />}
            <br/>
            {hasAnalytics ? <Switch checkedChildren={t(`Dashboard`)} disabled checked /> : <Switch unCheckedChildren={t(`Dashboard`)} disabled />}
        </Popover>
        <br/>
        <br/>
        <br/>
        <Divider color="black">***</Divider>
        <Label>{t('Settings')}:</Label>
        <Field>
          <Label>
            <Checkbox
              type='checkbox'
              color='light'
              name='notCrossSales'
              ref={register}
              tooltip={t('crosssales')}
              tooltipMultiline
              tooltipPosition="right"
              tooltipColor="warning"
              defaultChecked={
                  (tenant && tenant.notcrosssales)
              }
            /> {t('Ventas sin referencias cruzadas')} 
          </Label>
        </Field>
        <br/>
        <Field kind='group'>
          <Control>
            <Button color='link'>{t('update')}</Button>
          </Control>
          <Control>
            <Button as={Link} to='/tenant/users' type='button' text>
              {t('cancel')}
            </Button>
          </Control>
          {confirmAction.showMessage && <FormError {...confirmAction} />}
        </Field>
      </form>
    </Box>
  )
}
