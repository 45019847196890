import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Tab, Icon } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { logic } from '../../../logic'
import { TableOfTables } from '../../../logic/tableOfTables'
import { AdsContext } from "../../../contexts/adsContext"

export const TabsSalesStock = ({ highlight }) => {
  const { t } = useTranslation()
  const { allTableOfTables } = useContext(AdsContext)

  const isPVLContracted = TableOfTables('PVL', logic.tenantId.toString(), allTableOfTables, true)

  return (
    <Tab.Group align='centered' kind='boxed'>
      <Tab as={Link} to='/reports/Sales' active={highlight === 'distributor sales'}>
        <Icon size='small'>
          <FontAwesomeIcon icon={['fas', 'shopping-basket']} />
        </Icon>
        <span>{t('Distributor sales')}</span>
      </Tab>
      <Tab as={Link} to='/reports/Stock' active={highlight === 'distributor stock'}>
        <Icon size='small'>
          <FontAwesomeIcon icon={['fas', 'copyright']} />
        </Icon>
        <span>{t('Distributors stock')}</span>
      </Tab>
      <Tab as={Link} to='/reports/clients' active={highlight === 'clients'}>
        <Icon size='small'>
          <FontAwesomeIcon icon={['fas', 'briefcase']} />
        </Icon>
        <span>{t('Clients')}</span>
      </Tab>
      {isPVLContracted ? 
        <Tab as={Link} to='/reports/categories' active={highlight === `clients' categories`}>
          <Icon size='small'>
            <FontAwesomeIcon icon={['fas', 'layer-group']} />
          </Icon>
          <span>{t(`Clients' categories`)}</span>
        </Tab>
        :
        null
      }
    </Tab.Group>
  )
}
