/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, useRef, useContext } from 'react'
import { useAlert } from 'react-alert'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import {
  Title,
  Level,
  Icon,
  Button,
  Table,
  Field,
  Input,
  Control,
  Box,
  Select
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { Pagination } from '../pagination'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useToggle } from '../../logic/hooks'
import { Search } from '../filter/Search'
import { Modal } from '../modal'
import { AdsContext } from "../../contexts/adsContext"
import { TabsTablesAdmin } from './tabsTablesAdmin'
import { FormError } from '../error'
import { useForm } from 'react-hook-form'
import { TableOfTables } from '../../logic/tableOfTables'
let isValidPass = false

export const TablesAdmin = () => {
  const alert = useAlert()
  // Hooks for toggling on and off the modal
  const [open, toggle] = useToggle(false)
  const [openInactive, toggleInactive] = useToggle(false)

  const [deleteItem, setDeleteItem] = useState()

  // state inside the modal
  const [related, setRelated] = useState([])
  
  // tenants that are searched for
  const [searchTables, setSearchTables] = useState()
  const [dropdownState, setDropdownState] = useState('active')

  // i18n hook
  const { t } = useTranslation()
  const { register, handleSubmit, errors } = useForm()

  // Routing Variables
  const { url } = useRouteMatch()
  const history = useHistory()

  // State refering to the pagination attributes comming from the pagination component
  const defaultPaginationStartPosition = 0
  const defaultPaginationEndPosition = 10
  const [startPosition, setStartPosition] = useState(
    defaultPaginationStartPosition
  )
  const [endPosition, setEndPosition] = useState(defaultPaginationEndPosition)

  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const paginationRef = useRef()
  const { allTableOfTables, fetchAllAdminTables, activateAdminTable, inactivateAdminTable } = useContext(AdsContext)

  const location = useLocation()
  const calledFromChild = location.state && location.state.fromChild || false

  // Fetch tenants when component mounts
  useEffect(() => {
    (async function () {
      let activeTables
      activeTables = await fetchAllAdminTables()
      setSearchTables(activeTables.filter(
        table => {
          return table.active === true
        }
      ))
    })()
  }, [])
  
  // handles the pagination requests
  const handleRequestPagination = requestPaginationObject => {
    let newStartPosition = defaultPaginationStartPosition
    let newEndPosition = defaultPaginationEndPosition
    if (requestPaginationObject) {
      const { currentPage, itemsPerPage } = requestPaginationObject
      newStartPosition = currentPage * itemsPerPage - itemsPerPage
      newEndPosition = newStartPosition + itemsPerPage
    }
    setStartPosition(newStartPosition)
    setEndPosition(newEndPosition)
  }

  // handles routing to add/edit
  const handleAddEditClick = (event, element) => {
    if (element) {
      history.push({
        pathname: `${url}/edit/${element && element.tableoftablesid}`
      })
    } else {
      history.push({
        pathname: `${url}/add`
      })
    }
  }
  const handleActivateItem = async (event, element) => {
    const id = `${element.tableoftablesid}`
    try {
      const updatedTable = await activateAdminTable(id)
      const active = false
      newTablesSorted(updatedTable, active)
      toggleInactive()
    }
    catch (error) {
      throw Error(error)
    }
  }

  const handleDeleteClick = async (event, element) => {
    const id = `${element.tableoftablesid}`
    try {
      const updatedTable = await inactivateAdminTable(id)
      const active = true
      newTablesSorted(updatedTable, active)
      toggle()
    } catch (error) {
      throw Error(`Error while inactivating the table of tables id: ${id} for the tenant: ${element.distributorname} with table of tables name: ${element.tableoftablesname}`)
    }
  }

  const newTablesSorted = (updatedTable, active) => {
    const newTables = allTableOfTables.filter(
      table => {
        return table.tableoftablesid !== updatedTable.tableoftablesid && table.active === active
      }
    )
    const sortedNewTables =
    newTables &&
    newTables.sort((a, b) => {
      if (a.tableoftablesname.toLowerCase() < b.tableoftablesname.toLowerCase()) {
        return -1
      }
      if (a.tableoftablesname.toLowerCase() > b.tableoftablesname.toLowerCase()) {
        return 1
      }
      return 0
    })
    setSearchTables(sortedNewTables)
  }

  const showRelatedItems = (event, item) => {
    setDeleteItem({ ...item })
    // TODO set correct logic for related
    setRelated([])
    toggle()
  }

  const showRelatedItemsInactive = (event, item) => {
    setDeleteItem({ ...item })
    setRelated([])
    toggleInactive()
  }

  const handleDoSearch = value => {
    let active
    if (dropdownState === 'active') {
      active = true
    } else {
      active = false
    }
    const result = allTableOfTables.filter(table => {
      const values = Object.values(table)

      const isIncluded = values
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
      // pagination Reset vallues
      paginationRef.current.resetValues()

      return isIncluded && table.active === active
    })
    setSearchTables(result)
    return result
  }

  const handleDropdownStateChange = (event) => {
    setDropdownState(event.target.value)
    let active
    if(event.target.value === 'active') {
      active = true
    } else {
      active = false
    }

    const selectedTables = allTableOfTables.filter(table => { if(table.active == active) return table})
    setSearchTables(selectedTables)
    paginationRef.current.resetValues()
  }

  const onSubmitData = ({ password }) => {
    isValidPass = TableOfTables('SUPADMIN', password, allTableOfTables, null)
  }

  return isValidPass ? (
    <Box id='tables'>
      {open && (
        <Modal toggle={toggle} open={open}>
          <Title>{t('confirmDelete')}</Title>
            <>
              <Title subtitle>{t('deleteSure')}</Title>
                <Button
                  onClick={e => handleDeleteClick(e, deleteItem)}
                  color='danger'
                >
                  {t('delete')}
                </Button>
            </>
        </Modal>
      )}
      {openInactive && (
        <Modal toggle={toggleInactive} open={openInactive}>
          <Title>{t('confirmActivate')}</Title>
            <>
              <Title subtitle>{t('activateSure')}</Title>
              <Button
                onClick={e => handleActivateItem(e, deleteItem)}
                color='success'
              >
                {t('Activate')}
              </Button>
            </>
        </Modal>
      )}
      <TabsTablesAdmin highlight='tables'/>
      <Level>
        <Select.Container>
          <Select value={dropdownState} onChange={handleDropdownStateChange} >
            <Select.Option value='active'>
              {t('Actives')}
            </Select.Option>
            <Select.Option value='inactive'>
              {t('Inactives')}
            </Select.Option>
          </Select>
        </Select.Container>
        <Level.Item>
          <Search doSearch={handleDoSearch} />
        </Level.Item>
        <Level.Item align='right'>
          <Button onClick={e => handleAddEditClick()} color='info'>
            <Icon>
              <FontAwesomeIcon size='lg' icon={['fas', 'plus']} />
            </Icon>
          </Button>
        </Level.Item>
      </Level>
      <Table hoverable bordered striped narrow fullwidth>
        <Table.Head>
          <Table.Row>
            <Table.Heading className='dashboard' colSpan='1'>
              {t('tableoftables name')}
            </Table.Heading>
            <Table.Heading className='dashboard' colSpan='1'>
              {t('tableoftables data')}
            </Table.Heading>
            <Table.Heading className='dashboard' colSpan='1'>
              {t('status')}
            </Table.Heading>
            <Table.Heading colSpan='3'>
              {t('action')}
            </Table.Heading>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {searchTables &&
            searchTables
              .slice(startPosition, endPosition)
              .map((item, index) => {
                return (
                  <Table.Row key={item.tableoftablesid}>
                    <Table.Cell>{item.tableoftablesname}</Table.Cell>
                    <Table.Cell>{item.tableoftablesdata}</Table.Cell>
                    <Table.Cell>{item.active ? 'active' : 'inactive'}</Table.Cell>
                    <Table.Cell>
                      {allTableOfTables.filter(table => { return table.tableoftablesname === item.tableoftablesname }) && 
                        item.tableoftablesdata.slice(0,6) !== 'HEADER' && 
                        <button
                          onClick={e => handleAddEditClick(e, item)}
                          className='button is-primary is-outlined is-small'
                        >
                          <Icon>
                            <FontAwesomeIcon size='lg' icon={['fas', 'edit']} />
                          </Icon>
                        </button>
                      }
                      {allTableOfTables.filter(table => { return table.tableoftablesname === item.tableoftablesname }) && 
                        item.tableoftablesdata.slice(0,6) === 'HEADER' && 
                        <button
                          onClick={e => handleAddEditClick(e, item)}
                          className='button is-primary is-outlined is-small'
                        >
                          <Icon>
                            <FontAwesomeIcon size='lg' icon={faEye} />
                          </Icon>
                        </button>
                      } 
                      {dropdownState === 'active' && 
                        allTableOfTables.filter(table => { return table.tableoftablesname === item.tableoftablesname && table.active }).length > 1 && 
                        item.tableoftablesdata.slice(0,6) !== 'HEADER' && 
                        <button
                          onClick={e => showRelatedItems(e, item)}
                          className='button is-danger is-outlined is-small'
                        >
                          <Icon>
                            <FontAwesomeIcon size='lg' icon={['fas', 'trash']} />
                          </Icon>
                        </button>}
                      {dropdownState === 'active' && 
                        allTableOfTables.filter(table => { return table.tableoftablesname === item.tableoftablesname && table.active }).length === 1 && 
                        item.tableoftablesdata.slice(0,6) === 'HEADER' && 
                        <button
                          onClick={e => showRelatedItems(e, item)}
                          className='button is-danger is-outlined is-small'
                        >
                          <Icon>
                            <FontAwesomeIcon size='lg' icon={['fas', 'trash']} />
                          </Icon>
                        </button>}
                      {dropdownState === 'inactive' && 
                        allTableOfTables.filter(table => { return table.tableoftablesname === item.tableoftablesname && !table.active  && table.tableoftablesdata.slice(0,6) === 'HEADER' }).length === 0 && 
                        <button
                          onClick={e => showRelatedItemsInactive(e, item)}
                          className='button is-success is-outlined is-small'
                        >
                          <Icon>
                            <FontAwesomeIcon size='lg' icon={['fas', 'check-circle']} />
                          </Icon>
                        </button>}
                      {dropdownState === 'inactive' && 
                        allTableOfTables.filter(table => { return table.tableoftablesname === item.tableoftablesname && !table.active && table.tableoftablesdata.slice(0,6) === 'HEADER' }).length === 1 &&
                        item.tableoftablesdata.slice(0,6) === 'HEADER' &&
                        <button
                          onClick={e => showRelatedItemsInactive(e, item)}
                          className='button is-success is-outlined is-small'
                        >
                          <Icon>
                            <FontAwesomeIcon size='lg' icon={['fas', 'check-circle']} />
                          </Icon>
                        </button>}
                    </Table.Cell>
                  </Table.Row>
                )
              })}
          {!searchTables && (
            <Table.Row>
              <Table.Heading colSpan='4'>{t('noDataAvailable')}</Table.Heading>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <Pagination
        totalItems={searchTables && searchTables.length}
        ref={paginationRef}
        requestPagination={handleRequestPagination}
      />
    </Box>
  )
  :
  (
    <Box>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <Field>
          <Control iconLeft>
            <Input
              name='password'
              ref={register({ required: true, minLength: 2 })}
              color='light'
              type='password'
              placeholder={t('password')}
            />
            {errors.password && errors.password.type === 'required' && (
              <FormError
                showMessage
                type='danger'
                message='Required'
                closeButton={false}
              />
            )}
            <Icon align='left'>
              <FontAwesomeIcon icon={['fas', 'lock']} />
            </Icon>
          </Control>
        </Field>
      </form>
    </Box>
  )
}
