import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Field, Input, Control, Icon } from 'rbx'

export const Search = ({ doSearch }) => {
  // Init a timeout variable to be used below
  let timeout = null
  // time to wait, in ms
  const sleepTime = 1000
  const { t } = useTranslation()

  const handleOnChange = event => {
    const value = event.target.value
    // Clear the timeout if it has already been set.
    // This will prevent the previous task from executing
    // if it has been less than <MILLISECONDS>
    clearTimeout(timeout)

    // Make a new timeout set to go off in 1000ms (1 second)
    timeout = setTimeout(() => {
      doSearch(value)
    }, sleepTime)
  }

  return (
    <Field horizontal>
      <Field.Body>
        <Control iconLeft>
          <Icon align='left'>
            <FontAwesomeIcon icon={['fas', 'search']} />
          </Icon>
          <Input placeholder={t('search')} color='dark' type='text' onChange={handleOnChange} />
        </Control>
      </Field.Body>
    </Field>
  )
}
