/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import {
  Textarea,
  Box,
  Title,
  Field,
  Input,
  Control,
  Label,
  Button,
  Icon,
  Column,
  Table
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { FormError, HelpError } from '../../error'
import { logic } from '../../../logic'
import { useConfirmActionMessage } from '../../../logic/hooks'
import ReactSelect from 'react-select'
// import ReactSelect from 'react-virtualized-select'

// import { FormError, HelpError } from '../error'

export const AddEditCorrespondance = () => {
  const alert = useAlert()
  // productCorrespndance
  const [product, setProduct] = useState()
  // Error handling
  const confirmAction = useConfirmActionMessage()

  const { t } = useTranslation()
  const history = useHistory()
  const { productTenantId, productDistributorId } = useParams()
  const { register, handleSubmit, errors, control } = useForm()
  const isEdit = !!(productTenantId && productDistributorId)
  // react-select options
  const [options, setOptions] = useState([])
  // componentDidMount ---> get productBrands, the productTenant, productBusinessUnits, productGroups
  useEffect(() => {
    if (isEdit) {
      logic
        .getOneProductCorrespondance({ productTenantId, productDistributorId })
        .then(product => {
          setProduct(product && product.data && product.data[0])
        })
        .catch(err => {
          alert.show(err.message, {
            type: 'error'
          })
        })
    } else {
      logic.getOneProductDistributorAsTenant({ productDistributorId })
        .then(product => {
          setProduct(product && product.data && product.data[0])
        })
    }
    logic
      .getProductTenants()
      .then(productTenants => {
        const sortedProductTenants =
          productTenants &&
          productTenants.data &&
          productTenants.data.sort((a, b) => {
            if (a.productname.toLowerCase() < b.productname.toLowerCase()) {
              return -1
            }
            if (a.productname.toLowerCase() > b.productname.toLowerCase()) {
              return 1
            }
            return 0
          })
        setOptions(
          sortedProductTenants.map(product => {
            const { producttenantcode: productTenantCode, productname: productName, producttenantid: productTenantId } = product
            return {
              label: `${productTenantCode} ${productName}`,
              value: productTenantId
            }
          })
        )
      })
      .catch(err => {
        alert.show(err.message, {
          type: 'error'
        })
      })
  }, [])

  const handleGoBackClick = () => {
    if (!isEdit) {
      history.push('/correspondance/not')
    }
    history.push('/correspondance')
  }

  const onSubmitData = data => {
    const {
      productTenant: {
        value
      },
      correspondanceFactor,
      description
    } = data
    confirmAction.closeMessage()
    if (isEdit) {
      logic
        .updateProductCorrespondance({
          productTenantId: product && product.producttenantid.toString(),
          productDistributorId: product && product.productdistributorid.toString(),
          newProductTenantId: value.toString(),
          description,
          correspondanceFactor
        })
        .then(productCorrespondance => {
          if (productCorrespondance.status !== 200) {
            throw Error(`${productCorrespondance.status}`)
          }
          setProduct(productCorrespondance && productCorrespondance.data && productCorrespondance.data[0])
          alert.show(t('updateSuccesful'), {
            type: 'success'
          })
        })
        .catch(err => {
          if (err.message === '409') {
            alert.show('Input already exists', {
              type: 'error'
            })
          } else {
            alert.show('Unexpected Error', {
              type: 'error'
            })
          }
        })
    } else {
      logic
        .insertProductCorrespondance({
          distributorId: product && product.distributortenantid.toString(),
          productTenantId: value.toString(),
          description,
          correspondanceFactor,
          productDistributorId: product && product.productdistributorid.toString()
        })
        .then(product => {
          if (product.status !== 200) {
            throw Error(product.status)
          }
          history.push({
            pathname: '/correspondance/not',
            state: '200'
          })
        })
        .catch(err => {
          if (err.message === '409') {
            alert.show('Input already exists', {
              type: 'error'
            })
          } else {
            alert.show('Unexpected Error', {
              type: 'error'
            })
          }
        })
    }
  }

  return (
    <Box>
      <Button onClick={e => handleGoBackClick()} color='light' size='medium'>
        <Icon>
          <FontAwesomeIcon size='lg' icon={['fas', 'chevron-circle-left']} />
        </Icon>
      </Button>
      <Title>
        {isEdit
          ? `${t('edit')} ${product && product.distributorproductname}`
          : `${t('add')} ${t('correspondance')}`}
      </Title>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <Column.Group>
          <Column size={5}>
            <Table fullwidth narrow hoverable bordered striped>
              <Table.Head>
                <Table.Row colSpan={2}>
                  <Table.Heading>{t('tenant')}</Table.Heading>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                <Table.Row>
                  <Table.Heading>{`${(product && product.producttenantcode) ||
                    ''} ${(product && product.tenantproductname) ||
                    ''}`}
                  </Table.Heading>
                </Table.Row>
              </Table.Body>
            </Table>
          </Column>

          <Column narrow>
            <Button static onClick={e => { }} color='primary'>
              <Icon>
                <FontAwesomeIcon size='lg' icon={['fas', 'exchange-alt']} />
              </Icon>
            </Button>
          </Column>
          <Column size={5}>
            <Table fullwidth narrow hoverable bordered striped>
              <Table.Head>
                <Table.Row colSpan={2}>
                  <Table.Heading>{t('distributor')}</Table.Heading>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                <Table.Row>
                  <Table.Heading>{`${(product &&
                    product.productdistributorcode) ||
                    ''} ${(product && product.distributorproductname) ||
                    ''}`}
                  </Table.Heading>
                </Table.Row>
              </Table.Body>
            </Table>
          </Column>
          <Column narrow>
            <Table fullwidth narrow hoverable bordered striped>
              <Table.Head>
                <Table.Row colSpan={2}>
                  <Table.Heading>{t('correspondanceFactor')}</Table.Heading>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                <Table.Row>
                  <Table.Heading>{`${(product &&
                    product.correspondancefactor) ||
                    ''}`}
                  </Table.Heading>
                </Table.Row>
              </Table.Body>
            </Table>
          </Column>
        </Column.Group>
        <Column.Group>
          <Column size={8}>
            <Column.Group>
              <Column narrow>
                <Label>{t('selectProduct')}: </Label>
              </Column>
              <Column>
                <Field>
                  <Controller
                    as={
                      <ReactSelect isClearable isSearchable options={options} />
                    }
                    control={control}
                    rules={{ required: true }}
                    onChange={([selected]) => {
                      // React Select return object instead of value for selection
                      return selected
                    }}
                    name='productTenant'
                  />
                  {errors.productTenant &&
                    errors.productTenant.type === 'required' && (
                      <HelpError message={t('required')} />
                    )}
                </Field>
              </Column>
            </Column.Group>
          </Column>
          <Column>
            <Column.Group>
              <Column narrow>

                <Label>{t('conversionFactor')}:</Label>

              </Column>
              <Column narrow>
                <Field>

                  <Control>
                    <Input
                      name='correspondanceFactor'
                      type='text'
                      defaultValue={(product && product.correspondancefactor) || ''}
                      color='light'
                      placeholder={t('factor')}
                      ref={register({ required: true, pattern: /\d+/i })}
                    />

                  </Control>
                  {errors.correspondanceFactor && errors.correspondanceFactor.type === 'required' && (
                    <HelpError message={t('required')} />
                  )}
                  {errors.correspondanceFactor && errors.correspondanceFactor.type === 'pattern' && (
                    <HelpError message={t('mustBeBetween0And1')} />
                  )}
                </Field>
              </Column>
            </Column.Group>
          </Column>
        </Column.Group>

        <Field>
          <Label>{t('observations')}</Label>
          <Control>
            <Textarea
              color='light'
              name='description'
              ref={register({
                maxLength: 1000
              })}
              placeholder={t('observations')}
              defaultValue={product && product.description}
            />
          </Control>
          {errors.description && errors.description.type === 'maxLength' && (
            <HelpError message={t('tooManyCharacters')} />
          )}
        </Field>

        <Field kind='group'>
          <Control>
            {isEdit ? (
              <Button color='link'>{t('update')}</Button>
            ) : (
                <Button color='link'>{t('add')}</Button>
              )}
          </Control>
          <Control>
            <Button as={Link} to='/correspondance' type='button' text>
              {t('cancel')}
            </Button>
          </Control>
          {confirmAction.showMessage && <FormError {...confirmAction} />}
        </Field>
      </form>
    </Box>
  )
}
