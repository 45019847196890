import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { logic } from '../../../logic'
import { SelectContainer, Select } from 'rbx/elements/form/select'
import { OmitProps } from 'antd/lib/transfer/renderListBody'

export const SelectRole = (props) => {
  const { t } = useTranslation()

  const [selectedRole, setSelectedRole] = useState(props.role)

  const handleDistributorSelect = event => {
    const { value } = event.target

    setSelectedRole(value)

    props.doSelectRole(value)
  }

  return (
    <SelectContainer>
      {props.role &&
        <Select
          name='distributors'
          onChange={handleDistributorSelect}
          value={selectedRole || ''}
        >
          <Select.Option value={props.role}>{props.role}</Select.Option>
          <Select.Option value={props.antiRole}>{props.antiRole}</Select.Option>
        </Select>}
      {!props.role &&
        <Select
          name='distributors'
          onChange={handleDistributorSelect}
          value={selectedRole || ''}
        >
          <Select.Option value=''>{t('select')} {t('role')}</Select.Option>
          <Select.Option value='Tenant'>Tenant</Select.Option>
          <Select.Option value='Distributor'>Distributor</Select.Option>
        </Select>}
    </SelectContainer>
  )
}
