import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { logic } from '../../../logic'
import { TabsDistributor } from './tabsDistributor'
import {
  Title,
  Level,
  Field,
  Icon,
  Select,
  Button,
  Table,
  Box
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Pagination } from '../../pagination'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useToggle } from '../../../logic/hooks'
import { Search } from '../../filter/Search'
import { Modal } from '../../modal'
import { SelectTenant } from './selectTenant'
import { useAlert } from 'react-alert'

export const ProductDistributor = () => {

  // Alert hook
  const alert = useAlert()

  // Hooks for toggling on and off the modal
  const [open, toggle] = useToggle(false)
  const [openInactive, toggleInactive] = useToggle(false)

  const [activateInactivateItem, setActivateInactivateItem] = useState()

  const [deleteItem, setDeleteItem] = useState()

  // state inside the modal
  const [related, setRelated] = useState([])

  // main state
  const [products, setProducts] = useState()

  // products that are searched for
  const [searchProducts, setSearchProducts] = useState([])

  // tenant that is selected to work with
  const [selectedTenant, setSelectedTenant] = useState()

  // i18n hook
  const { t } = useTranslation()

  // Routing Variables
  const { url } = useRouteMatch()
  const history = useHistory()

  // State refering to the pagination attributes comming from the pagination component
  const defaultPaginationStartPosition = 0
  const defaultPaginationEndPosition = 10
  const [startPosition, setStartPosition] = useState(
    defaultPaginationStartPosition
  )
  const [endPosition, setEndPosition] = useState(defaultPaginationEndPosition)
  // React active or inactive
  const [dropdownState, setDropdownState] = useState('active')


  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const paginationRef = useRef()

  // handles the pagination requests
  const handleRequestPagination = requestPaginationObject => {
    let newStartPosition = defaultPaginationStartPosition
    let newEndPosition = defaultPaginationEndPosition
    if (requestPaginationObject) {
      const { currentPage, itemsPerPage } = requestPaginationObject
      newStartPosition = currentPage * itemsPerPage - itemsPerPage
      newEndPosition = newStartPosition + itemsPerPage
    }
    setStartPosition(newStartPosition)
    setEndPosition(newEndPosition)
  }

  // handles routing to add/edit
  const handleAddEditClick = (event, element) => {
    if (element) {
      history.push({
        pathname: `${url}/edit/${element && element.productdistributorid}/${selectedTenant}`,
        state: element
      })
    } else {
      history.push({
        pathname: `${url}/add/${selectedTenant}`
      })
    }
  }

  // deletes the item
  const handleDeleteClick = (event, element) => {
    const id = `${element.productdistributorid}`
    logic
      .deleteProductDistributor({ productDistributorId: id, tenantId: selectedTenant })
      .then(item => {
        if (item.status !== 200) {
          throw Error(item.error)
        }
        const newProducts = products.filter(product => {
          return product.productdistributorid !== item.data[0].productdistributorid
        })

        const sortedNewProducts =
          newProducts &&
          newProducts.sort((a, b) => {
            if (a.distributorproductname.toLowerCase() < b.distributorproductname.toLowerCase()) {
              return -1
            }
            if (a.distributorproductname.toLowerCase() > b.distributorproductname.toLowerCase()) {
              return 1
            }
          })
        setProducts(sortedNewProducts)
        setSearchProducts(sortedNewProducts)
        alert.show(t('Producto borrado'), {
          type: 'success'

        })
        toggle()
      })
      .catch(err => {
        alert.show(t('errorinactivating')+`. ${err}`, { type: 'error' })
        console.error(err.message)
        toggle()
      })
  }

  const handleActivateItem = (event, element) => {
    const id = `${element.productdistributorid}`
    logic
      .activateProductDistributor({ productDistributorId: id, tenantId: selectedTenant })
      .then(item => {
        if (item.status !== 200) {
          throw Error(item.error)
        }
        const newProducts = products.filter(product => {
          return product.productdistributorid !== item.data[0].productdistributorid
        })

        const sortedNewProducts =
          newProducts &&
          newProducts.sort((a, b) => {
            if (a.distributorproductname.toLowerCase() < b.distributorproductname.toLowerCase()) {
              return -1
            }
            if (a.distributorproductname.toLowerCase() > b.distributorproductname.toLowerCase()) {
              return 1
            }
          })
        setProducts(sortedNewProducts)
        setSearchProducts(sortedNewProducts)
        alert.show(t('Producto activado'), {
          type: 'success'

        })
        toggleInactive()
      })
      .catch(err => {
        alert.show(t('erroractivating')+`. ${err}`, { type: 'error' })
        console.error(err.message)
        toggleInactive()
      })
  }

  const showRelatedItems = (event, item) => {
    setActivateInactivateItem({ ...item })

    // TODO set correct logic for related
    setRelated([])
    toggle()
  }

  const showRelatedItemsInactive = (event, item) => {
    setActivateInactivateItem({ ...item })
    
    // TODO set correct logic for related
    setRelated([])
    toggleInactive()
  }

  const handleDoSearch = value => {
    const result = products.filter(product => {
      const values = Object.values(product)

      const isIncluded = values
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(value.toLowerCase().replace(/\s+/g, ''))
      // pagination Reset vallues
      paginationRef.current.resetValues()

      return isIncluded
    })
    const sortedResult = result.sort((a, b) => {
      if (a.distributorproductname.toLowerCase() < b.distributorproductname.toLowerCase()) {
        return -1
      }
      if (a.distributorproductname.toLowerCase() > b.distributorproductname.toLowerCase()) {
        return 1
      }
      return 0
    })
    setSearchProducts(sortedResult)
    return sortedResult
  }

  const handleDropdownStateChange = async (event) => {
    setDropdownState(event.target.value)
    const activeInactive = event.target.value
    if (!selectedTenant) {

    } else {
      await getDistributorProducts(activeInactive, selectedTenant) 
    }
  }

  async function getDistributorProducts(activeInactive = dropdownState, tenantId = selectedTenant) {
    try {
      const productsDistributor = await logic.getProductsDistributor({ tenantId })
      let data = productsDistributor && productsDistributor.data
      data = data.filter(item => {
        return activeInactive === 'active' ? item.active : !item.active
      }) 
      const sortedProductsDistributor =
        data.sort((a, b) => {
          if (a.distributorproductname.toLowerCase() < b.distributorproductname.toLowerCase()) {
            return -1
          }
          if (a.distributorproductname.toLowerCase() > b.distributorproductname.toLowerCase()) {
            return 1
          }
          return 0
      })
      setProducts(sortedProductsDistributor)
      setSearchProducts(sortedProductsDistributor)
    } catch (error) {
        console.log("error: ", error)
        alert.show(t('getproductsdistributorerror'), {
          type: 'error'
        })
    }
  }

  const handleSelectTenant = async (value) => {
    if (value && value !== '0') {
      await getDistributorProducts(dropdownState, value)
      setSelectedTenant(value)
    }
  }

  return (
    <Box>
      {open && (
        <Modal toggle={toggle} open={open}>
          <Title>{t('confirmDelete')}</Title>
          {related && related.length !== 0 && (
            <Title subtitle size={6}>
              {t('cannotDeleteRelated')}
            </Title>
          )}
          {related &&
            related.length !== 0 &&
            related.length > 7 &&
            related.slice(0, 7).map((item, index) => {
              return (
                <>
                  <Field key={index}>{item.distributorproductname}</Field>
                </>
              )
            })}
          {related &&
            related.length !== 0 &&
            related.length <= 7 &&
            related.map((item, index) => {
              return (
                <>
                  <Field key={index}>{item.distributorproductname}</Field>
                </>
              )
            })}
          {related && related.length !== 0 && related.length > 7 && (
            <Field>
              ...{t('and')} {related.length - 7} {t('others')}
            </Field>
          )}
          {related && related.length === 0 && (
            <>
              <Title subtitle>{t('deleteSure')}</Title>
              <Button
                onClick={e => handleDeleteClick(e, activateInactivateItem)}
                color='danger'
              >
                {t('delete')}
              </Button>
            </>
          )}
        </Modal>
      )}
      {openInactive && (
        <Modal toggle={toggleInactive} open={openInactive}>
          <Title>{t('confirmActivate')}</Title>
          {related && related.length !== 0 && (
            <Title subtitle size={6}>
              {t('cannotActivateRelated')}
            </Title>
          )}
          {related && 
           related.length !== 0 && 
           related.length > 7 && 
           related.slice(0, 7).map((item, index) => {
            return (
              <>
                <Field key={index}>{item.distributorproductname}</Field>
              </>
            )
          })}
          {related && 
           related.length !== 0 && 
           related.length <= 7 && 
           related.map((item, index) => {
            return (
              <>
                <Field key={index}>{item.distributorproductname}</Field>
              </>
            )
          })}
          {related && related.length !== 0 && related.length > 7 && (
            <Field>
              ...{t('and')} {related.length - 7} {t('others')}
            </Field>
          )}
          {related && related.length === 0 && (
            <>
              <Title subtitle>{t('activateSure')}</Title>
              <Button
                onClick={e => handleActivateItem(e, activateInactivateItem)}
                color='success'
              >
                {t('Activate')}
              </Button>
            </>
          )}
        </Modal>
      )}
      <TabsDistributor highlight='products' />
      <Level>
        <Level.Item align='left'>
          <Select.Container color='dark'>
            <SelectTenant doSelectTenant={handleSelectTenant} />
          </Select.Container>
        </Level.Item>
        <Select.Container style={{ marginLeft: '20px' }}>
          <Select value={dropdownState} onChange={handleDropdownStateChange} >
            <Select.Option value='active'>
              {t('Actives')}
            </Select.Option>
            <Select.Option value='inactive'>
              {t('Inactives')}
            </Select.Option>
          </Select>
        </Select.Container>
        <Level.Item>
          <Search doSearch={handleDoSearch} />
        </Level.Item>
        <Level.Item align='right'>
          {selectedTenant && selectedTenant.toString() === '0' ? 
              <Button onClick={e => handleAddEditClick()} color='info' disabled>
                <Icon>
                  <FontAwesomeIcon size='lg' icon={['fas', 'plus']} />
                </Icon>
              </Button>
            :
              <Button onClick={e => handleAddEditClick()} color='info'>
                <Icon>
                  <FontAwesomeIcon size='lg' icon={['fas', 'plus']} />
                </Icon>
              </Button>
          }
        </Level.Item>
      </Level>
      <Table hoverable bordered striped narrow fullwidth>
        <Table.Head>
          <Table.Row>
            <Table.Heading>{t('code')}</Table.Heading>
            <Table.Heading>{t('name')}</Table.Heading>
            <Table.Heading>{t('action')}</Table.Heading>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {searchProducts &&
            searchProducts.slice(startPosition, endPosition).map(item => {
              return (
                <Table.Row key={item.productdistributorid}>
                  <Table.Heading>{item.productdistributorcode}</Table.Heading>
                  <Table.Cell>{item.distributorproductname}</Table.Cell>
                  <Table.Cell>
                    <button
                      onClick={e => handleAddEditClick(e, item)}
                      className='button is-primary is-outlined is-small'
                    >
                      <Icon>
                        <FontAwesomeIcon size='lg' icon={['fas', 'edit']} />
                      </Icon>
                    </button>

                    {dropdownState === 'active' && 
                      <button
                        onClick={e => showRelatedItems(e, item)}
                        className='button is-danger is-outlined is-small'
                      >
                        <Icon>
                          <FontAwesomeIcon size='lg' icon={['fas', 'trash']} />
                        </Icon>
                      </button>
                    }
                    {dropdownState === 'inactive' && 
                      <button
                        onClick={e => showRelatedItemsInactive(e, item)}
                        className='button is-success is-outlined is-small'
                      >
                        <Icon>
                          <FontAwesomeIcon size='lg' icon={['fas', 'check-circle']} />
                        </Icon>
                      </button>
                    }
                  </Table.Cell>
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table>
      <Pagination
        totalItems={searchProducts && searchProducts.length}
        ref={paginationRef}
        requestPagination={handleRequestPagination}
      />
    </Box>
  )
}
