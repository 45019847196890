/* eslint-disable react/jsx-indent */
import React from 'react'
import { Help, Icon } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const HelpError = ({ message }) =>
  (<Help color='danger'>
    <Icon color='danger' align='left'>
      <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
    </Icon>
    {message}
   </Help>)
