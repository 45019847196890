import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tab, Icon, Generic } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

export const TabsCorrespondance = ({ highlight, isPending }) => {
  const { t } = useTranslation()

  return (
    <Tab.Group align='centered'>
      <Tab as={Link} to='/correspondance' active={highlight === 'correspondance'}>
        <Icon size='small'>
          <FontAwesomeIcon icon={['fas', 'exchange-alt']} />
        </Icon>
        <Generic>{t('correspondance')}</Generic>
      </Tab>
      <Tab as={Link} to='/correspondance/not' active={highlight === 'correspondanceNot'}>
        <Icon size='small'>
          <FontAwesomeIcon icon={['fas', 'not-equal']} />
        </Icon>
        <Generic badge={isPending ? t('pending') : null} badgeColor={isPending ? 'warning' : null}>
          {t('nonCorrespondance')}
        </Generic>
      </Tab>
    </Tab.Group>
  )
}
