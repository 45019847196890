/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import {
  Box,
  Title,
  Field,
  Input,
  Control,
  Label,
  Button,
  Icon,
  Checkbox
} from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FormError } from '../../../error'
import { logic } from '../../../../logic'
import { TabsDistributor } from '../../tabsDistributor'
import { useConfirmActionMessage } from '../../../../logic/hooks'
import { NOTIFICATIONS } from '../../../../notifications'

export const BUTTON_STATES = { hovered: 'hovered', focused: 'focused', active: 'active', loading: 'loading' }
export const AddEditUserDistributor = () => {
  const alert = useAlert()
  // productTenants
  const [userDistributor, setUserDistributor] = useState()
  const [distributorName, setDistributorName] = useState()

  const confirmAction = useConfirmActionMessage()
  // Button request loading
  const [buttonState, setButtonState] = useState(BUTTON_STATES.active)

  const { t } = useTranslation()
  const history = useHistory()
  const { userId, distributorId } = useParams()
  const { register, handleSubmit, errors, watch } = useForm()

  // load
  useEffect(() => {
    function loadUserId () {
        logic.getOneUserDistributor({ distributorUserId: userId, distributorId })
        .then(user => {
          if (user.status !== 200) {
            alert.show('network error', { type: 'error' })
          }
          setUserDistributor(user && user.data && user.data[0])
        })
    }
    if (userId) {
      loadUserId()
    }
    logic.getOneDistributorTenant({ distributorTenantId: distributorId })
    .then(distributorTenant => {
      if (distributorTenant.status !== 200) {
        alert.show('Error retrieving the distributor name', { type: 'error' })
      }
      setDistributorName(distributorTenant.data[0].distributorname)
    })
  }, [])

  const handleGoBackClick = () => {
    history.push('/distributor/users')
  }

  const onSubmitData = data => {
    setButtonState(BUTTON_STATES.loading)

    const { email, primaryContact, notify } = data
    // we destructure as let because we wil be passing the city and province name, not id
    confirmAction.closeMessage()
    if (userId) {
      logic
        .updateUserDistributor({
          userDistributorId: userId.toString(),
          primaryContact,
          distributorId: distributorId && distributorId.toString(),
          notify
         })
        .then(userDistributor => {
          if (userDistributor.status !== 200) {
            throw Error(`${userDistributor.status}`)
          }
          alert.show(t('updateSuccesful'), {
            type: 'success'
          })
          history.push({
            pathname: '/distributor/users'
          })
        })
        .catch(err => {
          if (err.message === '409') {
            alert.show('Input already exists', {
              type: 'error'
            })
          } else {
            alert.show('Unexpected Error', {
              type: 'error'
            })
          }
        })
    } else {
      logic
        .addUserDistributor({
          userEmail: email,
          primaryContact,
          distributorId,
          notify
        })
        .then(userDistributor => {
          if (userDistributor.status !== 200) {
            throw Error(userDistributor.status)
          }
          alert.show(t(NOTIFICATIONS.userDistributorCreate), {
            type: 'success'
          })
          history.push({
            pathname: '/distributor/users'
          })
        })
        .catch(err => {
          if (err.message === '409') {
            alert.show('Input already exists', {
              type: 'error'
            })
          } else {
            alert.show('Unexpected Error', {
              type: 'error'
            })
          }
        })
    }
    setButtonState(BUTTON_STATES.active)
  }

  return (
    <Box>
      <TabsDistributor highlight='users' />
       <Button onClick={e => handleGoBackClick()} color='light' size='medium'>
        <Icon>
          <FontAwesomeIcon size='lg' icon={['fas', 'chevron-circle-left']} />
        </Icon>
       </Button>
      <Title>
        {userId
          ? `${t('edit')} ${userDistributor && userDistributor.useremail} (${distributorName})`
          : `${t('add')} ${t('user')} (${distributorName})`}
      </Title>
      <form onSubmit={handleSubmit(onSubmitData)}>
      <Field>
                <Control iconLeft>
                  <Input
                    name='email'
                    ref={register({
                      required: !userId
                    })}
                    color='light'
                    type='email'
                    placeholder={(userDistributor && userDistributor.useremail) || t('email')}
                    autoFocus=''
                    disabled={!!userId}
                  />
                  {errors.email && errors.email.type === 'required' && (
                    <FormError
                      showMessage
                      type='danger'
                      message='Required'
                      closeButton={false}
                    />
                  )}
                  {errors.email && errors.email.type === 'validate' && (
                    <FormError message={t('email already taken')} />
                  )}
                  <Icon align='left'>
                    <FontAwesomeIcon icon={['fas', 'envelope']} />
                  </Icon>
                </Control>
      </Field>
        <Field>
          <Label>
            <Checkbox
              type='checkbox'
              color='light'
              name='primaryContact'
              ref={register}
              defaultChecked={watch('primaryContact') || (userDistributor && userDistributor.primarycontactid === userDistributor.userid)}
            />
                <strong style={{ margin: '5px' }}>
                {t('primaryContact')}

                </strong>
          </Label>
        </Field>
        <Field>
          <Label>
            <Checkbox
              type='checkbox'
              color='light'
              name='notify'
              ref={register}
              defaultChecked={userId ? (watch('notify') || (userDistributor && userDistributor.notify)) : true}
            />
                <strong style={{ margin: '5px' }}>
                {t('Enviar notificaciones')}

                </strong>
          </Label>
        </Field>
        <Field kind='group'>
          <Control>
            {userId ? (
              <Button state={buttonState} color='link'>{t('update')}</Button>
            ) : (
              <Button state={buttonState} color='link'>{t('add')}</Button>
            )}
          </Control>
          <Control>
            <Button as={Link} to='/distributor/users' type='button' text>
              {t('cancel')}
            </Button>
          </Control>
          {confirmAction.showMessage && <FormError {...confirmAction} />}
        </Field>
      </form>
    </Box>
  )
}
