/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState, useContext } from 'react'
import { Checkbox, Box,Title, Field, Input, Control, Label, Level, Button, Icon, Column, Table, Container } from 'rbx'
import { Modal, Icon as IconAntd } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11'
import { Controller } from 'react-hook-form'
import { TabsObjectives} from '../../tabsObjectives'

import ReactSelect from 'react-select'
import { Pagination } from '../../../pagination'
import { AdsContext } from '../../../../contexts/adsContext'
import { FormError } from '../../../error'
import { useConfirmActionMessage } from '../../../../logic/hooks'
import { useAlert } from 'react-alert'

export const AddEditObjPostalCodes = () => {

  const { t } = useTranslation()
  const alert = useAlert()
  const { id } = useParams()
  const { control, register, handleSubmit, errors } = useForm()
  const errorAction = useConfirmActionMessage()
  const [countriesOptions, setCountriesOptions] = useState([])
  const [regionsOptions, setRegionsOptions] = useState([])
  const [provincesOptions, setProvincesOptions] = useState([])
  // State refering to the pagination attributes comming from the pagination component
  const defaultPaginationStartPosition = 0
  const defaultPaginationEndPosition = 10
  const [startPositionCountries, setStartPositionCountries] = useState(defaultPaginationStartPosition)
  const [endPositionCountries, setEndPositionCountries] = useState(defaultPaginationEndPosition)
  const [startPositionRegions, setStartPositionRegions] = useState(defaultPaginationStartPosition)
  const [endPositionRegions, setEndPositionRegions] = useState(defaultPaginationEndPosition)
  const [startPositionProvinces, setStartPositionProvinces] = useState(defaultPaginationStartPosition)
  const [endPositionProvinces, setEndPositionProvinces] = useState(defaultPaginationEndPosition)
  const [startPositionPostalCodes, setStartPositionPostalCodes] = useState(defaultPaginationStartPosition)
  const [endPositionPostalCodes, setEndPositionPostalCodes] = useState(defaultPaginationEndPosition)
  const [selectedPostalCodes, setSelectedPostalCodes] = useState([]) 
  const [selectedCountries, setSelectedCountries] = useState([]) 
  const [selectedRegions, setSelectedRegions] = useState([])
  const [selectedProvinces, setSelectedProvinces] = useState([])
  const [onScreenCheckedBoxes, setOnScreenCheckedBoxes] = useState()
  const { fetchOnePostalCode, fetchOnePostalCodeById, fetchAllCountries, fetchAllRegions, fetchAllProvinces,
              sharedObjective, setSharedObjective, sharedObjDisabled,
              boldPostalCodes, setBoldPostalCodes,
              boldCountries, setBoldCountries,
              boldRegions, setBoldRegions,
              boldProvinces, setBoldProvinces,
              infZonesCheckedBoxes, setInfZonesCheckedBoxes} = useContext(AdsContext)
  const [countries, setCountries] = useState()
  const [regions, setRegions] = useState()
  const [provinces, setProvinces] = useState()
  
  useEffect(() => {
    (async function () {
      setOnScreenCheckedBoxes({...infZonesCheckedBoxes})
      // 1st) We load all active postal codes, countries, regions and provinces
      const allCountries = await fetchAllCountries()
      const allRegions = await fetchAllRegions()
      const allProvinces = await fetchAllProvinces()
      setCountries([...allCountries])
      setRegions([...allRegions])
      setProvinces([...allProvinces])
      
      // 2nd) We prepare all the postal codes, countries, regions and provinces  selected on the screen
      // Postal codes 
      let onScreenObjPostalCodes = []
      for (let index=0; index < sharedObjective[0].postalcode.length; index++) {
        const postalCodeFound =  await fetchOnePostalCodeById(sharedObjective[0].postalcode[index])
        if (postalCodeFound) {
          onScreenObjPostalCodes.push({
            label: `${postalCodeFound[0].postalcode} - ${postalCodeFound[0].country} - ${postalCodeFound[0].region} - ${postalCodeFound[0].province} - ${postalCodeFound[0].population}`,
            value: postalCodeFound[0].postalcodeid,
            country: postalCodeFound[0].country,
            region: postalCodeFound[0].region,
            province: postalCodeFound[0].province,
            postalcode: postalCodeFound[0].postalcode
          })  
        } else {
          onScreenObjPostalCodes.push({ 
            label: '', 
            value: null
          })
        }
      }
      if (boldPostalCodes.length === 0) {
        let onScreenObjPostalCodesSorted = onScreenObjPostalCodes.sort((a, b) => {
          return a.label.toLowerCase().localeCompare(b.label.toLowerCase())
        })
        setSelectedPostalCodes([...onScreenObjPostalCodesSorted])
      } else {
        setSelectedPostalCodes([...onScreenObjPostalCodes])
      }

      // Countries
      let onScreenObjCountries = sharedObjective[0].country.map(objCountry => {
        const countryFound = allCountries.find(country =>{
          if (country.country === objCountry) return country
        })
        return countryFound && {
         label: countryFound.country,
         value: countryFound.country
         } || 
         { 
           label: '', 
           value: null
         }
      })
      if (boldCountries.length === 0) {
        let onScreenObjCountriesSorted = onScreenObjCountries.sort((a, b) => {
          return a.label.toLowerCase().localeCompare(b.label.toLowerCase())
        })
        setSelectedCountries([...onScreenObjCountriesSorted])
      } else {
        setSelectedCountries([...onScreenObjCountries])
      }
      // Regions
      let onScreenObjRegions = sharedObjective[0].region.map(objRegion => {
        const regionFound = allRegions.find(region =>{
          if (region.region === objRegion) return region
        })
        return regionFound && {
        label: regionFound.region,
        value: regionFound.region,
        country: regionFound.country
        } || 
        { 
          label: '', 
          value: null
        }
      })
      if (boldRegions.length === 0) {
        let onScreenObjRegionsSorted = onScreenObjRegions.sort((a, b) => {
          return a.label.toLowerCase().localeCompare(b.label.toLowerCase())
        })
        setSelectedRegions([...onScreenObjRegionsSorted])
      } else {
        setSelectedRegions([...onScreenObjRegions])
      }

      // Provinces
      let onScreenObjProvinces = sharedObjective[0].province.map(objProvince => {
        const provinceFound = allProvinces.find(province =>{
          if (province.province === objProvince) return province
        })
        return provinceFound && {
        label: provinceFound.province,
        value: provinceFound.province,
        country: provinceFound.country,
        region: provinceFound.region
        } || 
        { 
          label: '', 
          value: null
        }
      })
      if (boldProvinces.length === 0) {
        let onScreenObjProvincesSorted = onScreenObjProvinces.sort((a, b) => {
          return a.label.toLowerCase().localeCompare( b.label.toLowerCase())
        })
        setSelectedProvinces([...onScreenObjProvincesSorted])
      } else {
        setSelectedProvinces([...onScreenObjProvinces])
      }
    
      // 3rd) We prepare all the countries, regions and provinces  not selected on the screen       
      selectableCountries(allCountries, onScreenObjCountries)
      selectableRegions(allRegions, onScreenObjRegions, onScreenObjCountries)
      selectableProvinces(allProvinces, onScreenObjProvinces, onScreenObjRegions, onScreenObjCountries)
   })();
  }, [])

  const selectableCountries = (countries, onScreenCountries) => {
    const selectableCountries = countries && countries.filter(country => {
      let isSelectedOnScreen = onScreenCountries && onScreenCountries.some(tmpCountry => country.country === tmpCountry.value || country.country === tmpCountry)
      if (!isSelectedOnScreen) return country 
    })
    selectableCountries.sort()
    setCountriesOptions(
      selectableCountries.map(country => {
       return {
         label: country.country,
         value: country.country
       }
     })
    )
    return selectableCountries
  }

  const selectableRegions = (regions, onScreenRegions, onScreenCountries) => {
    const selectableRegions = regions && regions.filter(region => {
      let isSelectedOnScreen = onScreenRegions && onScreenRegions.some(tmpRegion => region.region === tmpRegion.value || region.region === tmpRegion)
      let isSelectedOnCountry = onScreenCountries && onScreenCountries.some(tmpCountry => region.country === tmpCountry.value || region.country === tmpCountry)
      if(!isSelectedOnScreen && !isSelectedOnCountry) return region
     })
     selectableRegions.sort()
     setRegionsOptions(
      selectableRegions.map(region => {
        return {
          label: region.region,
          value: region.region,
          country: region.country
        }
      })
     )
     return selectableRegions
  }

  const selectableProvinces = (provinces, onScreenProvinces, onScreenRegions, onScreenCountries) => {
    const selectableProvinces = provinces && provinces.filter(province => {
      let isSelectedOnScreen = onScreenProvinces && onScreenProvinces.some(tmpProvince => province.province === tmpProvince.value || province.province === tmpProvince)
      let isSelectedOnRegion = onScreenRegions && onScreenRegions.some(tmpRegion => province.region === tmpRegion.value || province.region === tmpRegion)
      let isSelectedOnCountry = onScreenCountries && onScreenCountries.some(tmpCountry => province.country === tmpCountry.value || province.country === tmpCountry)
      if(!isSelectedOnScreen && !isSelectedOnRegion && !isSelectedOnCountry) return province
     })
     selectableProvinces.sort()
     setProvincesOptions(
      selectableProvinces.map(province => {
        return {
          label: province.province,
          value: province.province,
          country: province.country,
          region: province.region
        }
      })
     )
     return selectableProvinces
  }

  const showConfirmGeoArea = (message, areaSelected, area) => {
    Modal.confirm({
      title: t('Confirm selection'),
      icon: <IconAntd type='exclamation-circle' />,
      content: t(message),
      onOk () {
        newSelectionsAndOptions(areaSelected, area)
      }
    })
  }

  const showConfirmDeleteSelection = (message, value) => {
    Modal.confirm({
      title: t('Confirm selection'),
      icon: <IconAntd type='exclamation-circle' />,
      content: t(message),
      onOk () {
        newOptions(value)
        let updatingCheckedBoxes = {...onScreenCheckedBoxes}
        updatingCheckedBoxes[value] = !updatingCheckedBoxes[value]
        setOnScreenCheckedBoxes({...updatingCheckedBoxes})
        setInfZonesCheckedBoxes({...updatingCheckedBoxes})
      },
    })
  }

  const addSubGeoAreas = (newShrObj, area) => {
    if (area === 'country') {
      selectableRegions(regions, newShrObj[0].region, newShrObj[0].country)
    }
    if (area === 'country' || area === 'region') {
      selectableProvinces(provinces, newShrObj[0].province,  newShrObj[0].region, newShrObj[0].country)
    }
  }

  const removeSubGeoAreas = (newShrObj, areaSelected, area) => {
    if (area === 'country') {
      const newSelectedRegions = selectedRegions.filter(region => { return region[area] !== areaSelected.value })
      newShrObj[0].region = newSelectedRegions.map(regionSelected => {
        return regionSelected.value
      })
      setSelectedRegions([...newSelectedRegions])
      const newNotSelectedRegions = regionsOptions.filter(region => { return region[area] !== areaSelected.value })
      newNotSelectedRegions.sort()
      setRegionsOptions(
        newNotSelectedRegions.map(region => {
          return {
            label: region.label,
            value: region.value,
            country: region.country
          }
        })
       )
    }
    if (area === 'country' || area === 'region') {
      const newSelectedProvinces = selectedProvinces.filter(province => { return province[area] !== areaSelected.value })
      newShrObj[0].province = newSelectedProvinces.map(provinceSelected => {
        return provinceSelected.value
      })
      setSelectedProvinces([...newSelectedProvinces])
      const newNotSelectedProvinces = provincesOptions.filter(province => { return province[area] !== areaSelected.value })
      newNotSelectedProvinces.sort()
      setProvincesOptions(
        newNotSelectedProvinces.map(province => {
          return {
            label: province.label,
            value: province.value,
            country: province.country,
            region: province.region
          }
        })
       )
    }
    const newSelectedPostalCodes = selectedPostalCodes.filter(postalCode => { return postalCode[area] !== areaSelected.value })
    newShrObj[0].postalcode = newSelectedPostalCodes.map(postalCodeSelected => {
      return postalCodeSelected.value
    })
    setSelectedPostalCodes([...newSelectedPostalCodes])
  }

  const newSelectionsAndOptions = (areaSelected, area ) => {
    let newShrObj = [...sharedObjective]
    let newSelectedAreas
    switch (area){
      case 'country':
        newSelectedAreas = [areaSelected].concat(selectedCountries)
        setSelectedCountries([...newSelectedAreas])
        setBoldCountries([areaSelected.value].concat(boldCountries))
        setCountriesOptions(countriesOptions
          .filter((country, index) => {
            if(areaSelected.value !== country.value) return country
          })
        )
        break
      case 'region':
        newSelectedAreas = [areaSelected].concat(selectedRegions)
        setSelectedRegions([...newSelectedAreas])
        setBoldRegions([areaSelected.value].concat(boldRegions))
        setRegionsOptions(regionsOptions
          .filter((region, index) => {
            if(areaSelected.value !== region.value) return region
          })
        )
        break
      case 'province':
        newSelectedAreas = [areaSelected].concat(selectedProvinces)
        setSelectedProvinces([...newSelectedAreas])
        setBoldProvinces([areaSelected.value].concat(boldProvinces))
        setProvincesOptions(provincesOptions
          .filter((province, index) => {
            if(areaSelected.value !== province.value) return province
          })
        )
        break
      default:
        break
    }
    removeSubGeoAreas(newShrObj, areaSelected, area)
    newShrObj[0][area] = newSelectedAreas.map(areaSelected => {
      return areaSelected.value
    })
    setSharedObjective([...newShrObj])
  }

  const newOptions = (area) => {
    let newShrObj = [...sharedObjective]
    switch (area) {
      case 'country':
        setSelectedCountries([])
        setBoldCountries([])
        setCountriesOptions(countries.map(country => {
          return {
            label: country.country,
            value: country.country
          }
        }))
        selectableRegions(regions, newShrObj[0].region, [])
        selectableProvinces(provinces, newShrObj[0].province,  newShrObj[0].region, [])
        break
      case 'region':
        setSelectedRegions([])
        setBoldRegions([])
        selectableRegions(regions, [], newShrObj[0].country)
        selectableProvinces(provinces, newShrObj[0].province,  [], newShrObj[0].country)
        break
      case 'province':
        setSelectedProvinces([])
        setBoldProvinces([])
        selectableProvinces(provinces, [],  newShrObj[0].region, newShrObj[0].country)
        break
      case 'postalcode':
        setSelectedPostalCodes([])
        setBoldPostalCodes([])
        break
    }
    newShrObj[0][area] = []
    setSharedObjective([...newShrObj])
    let newCheckedBoxes = {...onScreenCheckedBoxes}
    newCheckedBoxes[area] = !newCheckedBoxes[area]
    setOnScreenCheckedBoxes({...newCheckedBoxes})
    setInfZonesCheckedBoxes({...newCheckedBoxes})
  }

  const handlePostalCodeSelected = (postalCodeSelected) => {
    const newSelectedPostalCodes = [postalCodeSelected].concat(selectedPostalCodes)
    setSelectedPostalCodes([...newSelectedPostalCodes])
    setBoldPostalCodes([postalCodeSelected.value].concat(boldPostalCodes))
    let newShrObj = [...sharedObjective]
    newShrObj[0].postalcode = newSelectedPostalCodes.map(postalCodeSelected => {
      return postalCodeSelected.value
    })
    setSharedObjective([...newShrObj])
  }

  const handleCountrySelected = (countrySelected) => {
    if (countrySelected === null) return // By clicking the delete key you are selecting a null country and we have to do nothing
    const areThereRegionsSelected = selectedRegions.some(tmpRegion => countrySelected.value === tmpRegion.country)
    const areThereProvincesSelected = selectedProvinces.some(tmpProvince => countrySelected.value === tmpProvince.country)
    const areTherePostalCodesSelected = selectedPostalCodes.some(tmpPostalCode => countrySelected.value === tmpPostalCode.country)
    if(areThereRegionsSelected || areThereProvincesSelected || areTherePostalCodesSelected) {
      showConfirmGeoArea('There are other selected areas under the geographical scope of the chosen country that will be deselected', countrySelected, 'country')
      return
    }
    newSelectionsAndOptions(countrySelected, 'country')
  }

  const handleRegionSelected = (regionSelected) => {
    if (regionSelected === null) return // By clicking the delete key you are selecting a null region and we have to do nothing
    const areThereProvincesSelected = selectedProvinces.some(tmpProvince => regionSelected.value === tmpProvince.region)
    const areTherePostalCodesSelected = selectedPostalCodes.some(tmpPostalCode => regionSelected.value === tmpPostalCode.region)
    if(areThereProvincesSelected || areTherePostalCodesSelected) {
      showConfirmGeoArea('There are other selected areas under the geographical scope of the chosen region that will be deselected', regionSelected, 'region')
      return
    }
    newSelectionsAndOptions(regionSelected, 'region')
  }

  const handleProvinceSelected = (provinceSelected) => {
    if (provinceSelected === null) return // By clicking the delete key you are selecting a null country and we have to do nothing
    const areTherePostalCodesSelected = selectedPostalCodes.some(tmpPostalCode => provinceSelected.value === tmpPostalCode.province)
    if(areTherePostalCodesSelected) {
      showConfirmGeoArea('There are other selected areas under the geographical scope of the chosen province that will be deselected', provinceSelected, 'province')
      return
    }
    newSelectionsAndOptions(provinceSelected, 'province')
  }
  
  const handlePostalCodeDeselected = (e, postalCodeDeselected) => {
    let newSelectedPostalCodes = selectedPostalCodes.filter( postalCode => { 
      if ( postalCode.value !== postalCodeDeselected.value) return postalCode
     })
    setSelectedPostalCodes([].concat(newSelectedPostalCodes))

    let newShrObj = [...sharedObjective]
    newShrObj[0].postalcode = newSelectedPostalCodes.map(postalCodeSelected => {
      return postalCodeSelected.value
    })
    setSharedObjective([...newShrObj])
  }

  const handleCountryDeselected = (e, countryDeselected) => {
    let newSelectedCountries = selectedCountries.filter(country => { 
      if (country.value !== countryDeselected.value) return country
     })
    setSelectedCountries([].concat(newSelectedCountries))

    let newSortedOptions = [...countriesOptions]
    newSortedOptions.push({
      label: countryDeselected.label,
      value: countryDeselected.value
    })
    newSortedOptions.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1
      }
      return 0
    })
    setCountriesOptions([...newSortedOptions])

    let newShrObj = [...sharedObjective]
    newShrObj[0].country = newSelectedCountries.map(countrySelected => {
      return countrySelected.value
    })
    addSubGeoAreas(newShrObj, 'country')
    setSharedObjective([...newShrObj])
  }

  const handleRegionDeselected = (e, regionDeselected) => {
    let newSelectedRegions = selectedRegions.filter( region => { 
      if ( region.value !== regionDeselected.value) return region
     })
    setSelectedRegions([].concat(newSelectedRegions))

    let newSortedOptions = [...regionsOptions]
    newSortedOptions.push({
      label: regionDeselected.label,
      value: regionDeselected.value,
      country: regionDeselected.country
    })
    newSortedOptions.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1
      }
      return 0
    })
    setRegionsOptions([...newSortedOptions])

    let newShrObj = [...sharedObjective]
    newShrObj[0].region = newSelectedRegions.map(regionSelected => {
      return regionSelected.value
    })
    addSubGeoAreas(newShrObj, 'region')
    setSharedObjective([...newShrObj])
  }

  const handleProvinceDeselected = (e, provinceDeselected) => {
    let newSelectedProvinces = selectedProvinces.filter( province => { 
      if (province.value !== provinceDeselected.value) return province
     })
    setSelectedProvinces([].concat(newSelectedProvinces))

    let newSortedOptions = [...provincesOptions]
    newSortedOptions.push({
      label: provinceDeselected.label,
      value: provinceDeselected.value,
      country: provinceDeselected.country,
      region: provinceDeselected.region
    })
    newSortedOptions.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1
      }
      return 0
    })
    setProvincesOptions([...newSortedOptions])

    let newShrObj = [...sharedObjective]
    newShrObj[0].province = newSelectedProvinces.map(provinceSelected => {
      return provinceSelected.value
    })
    setSharedObjective([...newShrObj])
  }

  const handleCheckboxClicked = (event) => {
    const { name, value } = event.target 

    let area
    switch (value) {
      case 'country': 
        area = 'countries'
        break
      case 'region':
        area = 'regions'
        break
      case 'province':
        area = 'provinces'
        break
      case 'postalcode':
        area = 'postal codes'
        break
    }

    if (sharedObjective[0][value].length > 0 && onScreenCheckedBoxes[value]) {
      showConfirmDeleteSelection(`Deselecting ${area} implies removing all the ones previously selected`, value)
      return
    }

    let newCheckedBoxes = {...onScreenCheckedBoxes}
    newCheckedBoxes[value] = !newCheckedBoxes[value]
    setOnScreenCheckedBoxes({...newCheckedBoxes})
    setInfZonesCheckedBoxes({...newCheckedBoxes})
  }

    // handles the pagination requests
  const handleRequestPagination = (requestPaginationObject) => {
    let newStartPosition = defaultPaginationStartPosition
    let newEndPosition = defaultPaginationEndPosition
    if (requestPaginationObject) {
      const { currentPage, itemsPerPage } = requestPaginationObject
      newStartPosition = currentPage * itemsPerPage - itemsPerPage
      newEndPosition = newStartPosition + itemsPerPage
    }
    return [newStartPosition, newEndPosition]
  }

  const handleRequestPaginationCountries = requestPaginationObject => {
    const newPositions = handleRequestPagination(requestPaginationObject)
    setStartPositionCountries(newPositions[0])
    setEndPositionCountries(newPositions[1])
  }

  const handleRequestPaginationRegions = requestPaginationObject => {
    const newPositions = handleRequestPagination(requestPaginationObject)
    setStartPositionRegions(newPositions[0])
    setEndPositionRegions(newPositions[1])
  }

  const handleRequestPaginationProvinces = requestPaginationObject => {
    const newPositions = handleRequestPagination(requestPaginationObject)
    setStartPositionProvinces(newPositions[0])
    setEndPositionProvinces(newPositions[1])
  }

  const handleRequestPaginationPostalCodes = requestPaginationObject => {
    const newPositions = handleRequestPagination(requestPaginationObject)
    setStartPositionPostalCodes(newPositions[0])
    setEndPositionPostalCodes(newPositions[1])
  }
  
  const onSubmitData = async ({ postalCode }) => {
    try {
      errorAction.closeMessage()
      const thePostalCode = selectedPostalCodes.find(postalDatum => { return postalDatum.postalcode === postalCode})
      if (thePostalCode) {
        alert.show(t(`Postal code already selected`), {type: 'info'})
        return
      }
      const postalCodeData = await fetchOnePostalCode(postalCode)
      if (postalCodeData.status !== 200) {
        throw Error(postalCodeData.status)
      }
      if (!postalCodeData.data || (postalCodeData.data && postalCodeData.data.length === 0)) {
        alert.show(t(`Postal code doesn't exist`), {
          type: 'error'
        })
        return
      } 
      const theCountry = selectedCountries.find(country => { return country.value === postalCodeData.data[0].country})
      const theRegion = selectedRegions.find(region => { return region.value === postalCodeData.data[0].region})
      const theProvince = selectedProvinces.find(province => { return province.value === postalCodeData.data[0].province})
      if (!theCountry && !theRegion && !theProvince) {
        handlePostalCodeSelected( {label: `${postalCodeData.data[0].postalcode} - ${postalCodeData.data[0].country} - ${postalCodeData.data[0].region} - ${postalCodeData.data[0].province} - ${postalCodeData.data[0].population}`,
        value: postalCodeData.data[0].postalcodeid,
        country: postalCodeData.data[0].country,
        region: postalCodeData.data[0].region,
        province: postalCodeData.data[0].province,
        postalcode: postalCodeData.data[0].postalcode})
        return 
      }
      if (theCountry) {
        alert.show(t(`This postal code is within the scope of one of the counties selected already`), {type: 'info'})
        return
      }
      if (theRegion) {
        alert.show(t(`This postal code is within the scope of one of the regions selected already`), {type: 'info'})
        return
      }
      if (theProvince) {
        alert.show(t(`This postal code is within the scope of one of the provinces selected already`), {type: 'info'})
        return
      }
    } catch (error) {
            throw Error("Error while getting the postal codes")       
    }
  }

  return (
    <Box>
      <TabsObjectives highlight='postalCodes' isInactive='is-active' id={id && parseInt(id) > -1 ? id : -1} />
      <Title>
        {id
          ? `${t('edit')} ${sharedObjective && sharedObjective[0].objectivename}`
          : `${t('add')} ${t('objective_postalCodes')}`}
      </Title>
      
      <Level>
        <Level.Item>
          <Label>
            <Checkbox disabled={sharedObjDisabled} checked={onScreenCheckedBoxes && onScreenCheckedBoxes.country ? "checked" : null} name="checkCountries" value="country" onChange={(event) => handleCheckboxClicked(event)}/>
            {t(' Countries')}
          </Label>
        </Level.Item>
        <Level.Item>
          <Label>
            <Checkbox disabled={sharedObjDisabled} checked={onScreenCheckedBoxes && onScreenCheckedBoxes.region ? "checked" : null} name="checkRegions" value="region" onChange={(event) => handleCheckboxClicked(event)} />
            {t(' Regions')}
          </Label>
        </Level.Item>
        <Level.Item>
          <Label>
            <Checkbox  disabled={sharedObjDisabled} checked={onScreenCheckedBoxes && onScreenCheckedBoxes.province ? "checked" : null} name="checkProvinces" value="province" onChange={(event) => handleCheckboxClicked(event)} />
            {t(' Provinces')}
          </Label>
        </Level.Item>
        <Level.Item>
          <Label>
            <Checkbox  disabled={sharedObjDisabled} checked={onScreenCheckedBoxes && onScreenCheckedBoxes.postalcode ? "checked" : null} name="checkPostalCodes" value="postalcode" onChange={(event) => handleCheckboxClicked(event)} />
            {t(' Postal codes')}
          </Label>
        </Level.Item>
      </Level>  
      
      {onScreenCheckedBoxes && onScreenCheckedBoxes.country ?  (
        <>
          <p className="postalcodeline">
            . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          </p>
          <br></br>
          <Column size={8}>
            <Column.Group>
              <Column narrow>
                <Label>{t('selectCountry')}: </Label>
              </Column>
              <Column>
                <Field>
                  <Controller
                    as={
                      <ReactSelect isClearable isSearchable options={countriesOptions} isDisabled={sharedObjDisabled} />
                    }
                    control={control}
                    onChange={([selected]) => {
                      handleCountrySelected(selected)
                    }}
                    name='objectivesTenant'
                  />
                </Field>
              </Column>
            </Column.Group>
          </Column>

          <Table hoverable bordered striped narrow fullwidth >
            <Table.Head>
              <Table.Row>
                <Table.Heading>{t('selectedCountry')}</Table.Heading>
                <Table.Heading>{t('action')}</Table.Heading>
              </Table.Row>
            </Table.Head>
            <Table.Body>
            {selectedCountries &&
              selectedCountries.slice(startPositionCountries, endPositionCountries).map((country, index) => {
                return (
                  <Table.Row key={index}>
                    <Table.Cell className={boldCountries.includes(country.value) ? "new-identity" : ""} key={index+1*10000}>{country.label}</Table.Cell>
                    <Table.Cell key={index+1*20000}>
                        
                            <button
                                type="button"
                                onClick={e => handleCountryDeselected(e, country)}
                                className='button is-danger is-outlined is-small'
                                disabled={sharedObjDisabled}
                              >
                              <Icon>
                                <FontAwesomeIcon size='lg' icon={['fas', 'trash']} />
                              </Icon>
                            </button>

                    </Table.Cell>
                  </Table.Row>
                )
            })}
            </Table.Body>
          </Table>
          <Pagination
            totalItems={selectedCountries && selectedCountries.length}
            requestPagination={handleRequestPaginationCountries}
          />
        </>
        ) : null
      }

      {onScreenCheckedBoxes && onScreenCheckedBoxes.region ?  (
        <>
          <p className="postalcodeline">
            . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          </p>
          <br></br>
         <Column size={8}>
            <Column.Group>
              <Column narrow>
                <Label>{t('selectRegion')}: </Label>
              </Column>
              <Column>
                <Field>
                  <Controller
                    as={
                      <ReactSelect isClearable isSearchable options={regionsOptions} isDisabled={sharedObjDisabled} />
                    }
                    control={control}
                    onChange={([selected]) => {
                      handleRegionSelected(selected)
                    }}
                    name='objectivesTenant'
                  />
                </Field>
              </Column>
            </Column.Group>
          </Column>

          <Table hoverable bordered striped narrow fullwidth >
            <Table.Head>
              <Table.Row>
                <Table.Heading>{t('selectedRegion')}</Table.Heading>
                <Table.Heading>{t('action')}</Table.Heading>
              </Table.Row>
            </Table.Head>
            <Table.Body>
            {selectedRegions &&
              selectedRegions.slice(startPositionRegions, endPositionRegions).map((region, index) => {
                return (
                  <Table.Row key={index}>
                    <Table.Cell className={boldRegions.includes(region.value) ? "new-identity" : ""} key={index+1*10000}>{region.label}</Table.Cell>
                    <Table.Cell key={index+1*20000}>
                        
                            <button
                                type="button"
                                onClick={e => handleRegionDeselected(e, region)}
                                className='button is-danger is-outlined is-small'
                                disabled={sharedObjDisabled}
                              >
                              <Icon>
                                <FontAwesomeIcon size='lg' icon={['fas', 'trash']} />
                              </Icon>
                            </button>

                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
          <Pagination
            totalItems={selectedRegions && selectedRegions.length}
            requestPagination={handleRequestPaginationRegions}
          /> 
        </>
        ) : null
      }

      {onScreenCheckedBoxes && onScreenCheckedBoxes.province ?  (
        <>
          <p className="postalcodeline">
            . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          </p>
          <br></br>
          <Column size={8}>
            <Column.Group>
              <Column narrow>
                <Label>{t('selectProvince')}: </Label>
              </Column>
              <Column>
                <Field>
                  <Controller
                    as={
                      <ReactSelect isClearable isSearchable options={provincesOptions} isDisabled={sharedObjDisabled} />
                    }
                    control={control}
                    onChange={([selected]) => {
                      handleProvinceSelected(selected)
                    }}
                    name='objectivesTenant'
                  />
                </Field>
              </Column>
            </Column.Group>
          </Column>

          <Table hoverable bordered striped narrow fullwidth >
            <Table.Head>
              <Table.Row>
                <Table.Heading>{t('selectedProvince')}</Table.Heading>
                <Table.Heading>{t('action')}</Table.Heading>
              </Table.Row>
            </Table.Head>
            <Table.Body>
            {selectedProvinces &&
              selectedProvinces.slice(startPositionProvinces, endPositionProvinces).map((province, index) => {
                return (
                  <Table.Row key={index}>
                    <Table.Cell className={boldProvinces.includes(province.value) ? "new-identity" : ""} key={index+1*10000}>{province.label}</Table.Cell>
                    <Table.Cell key={index+1*20000}>
                        
                            <button
                                type="button"
                                onClick={e => handleProvinceDeselected(e, province)}
                                className='button is-danger is-outlined is-small'
                                disabled={sharedObjDisabled}
                              >
                              <Icon>
                                <FontAwesomeIcon size='lg' icon={['fas', 'trash']} />
                              </Icon>
                            </button>

                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
          <Pagination
            totalItems={selectedProvinces && selectedProvinces.length}
            requestPagination={handleRequestPaginationProvinces}
          />
        </>
        ) : null
      } 

      {onScreenCheckedBoxes && onScreenCheckedBoxes.postalcode ?  (
        <>
          <p className="postalcodeline">
            . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
          </p>
          <br></br>
          <br></br>

          <form onSubmit={handleSubmit(onSubmitData)}>
            <Field horizontal>
              <Field.Label size='normal'>
                <Label>{t('Add Postal Code')}</Label>
              </Field.Label>
              <Field.Body>
                <Control>
                  <Input 
                    type='text' 
                    name='postalCode' 
                    ref={register({ 
                      required: true, 
                      maxLength: 12 
                    })} 
                    color='light' 
                    autoFocus='' 
                    disabled={sharedObjDisabled}
                  />
                  {errors.postalCode && errors.postalCode.type === 'required' && (
                  <FormError showMessage type='danger' message={t('required')} />)}
                  {errorAction.showMessage && <FormError {...errorAction} />}
                  {errors.postalCode && errors.postalCode.type === 'maxLength' && (
                  <FormError showMessage type='danger' message={t('tooManyCharacters')} />)}
                  {errorAction.showMessage && <FormError {...errorAction} />}
                </Control>
              </Field.Body>
            </Field>

            <Button color='success'>
              <Container>
                {t('add')}
              </Container>
              <Icon>
                <FontAwesomeIcon size='lg' icon={['fas', 'sign-in-alt']} />
              </Icon>
            </Button>
          </form>
          
          <br></br>

          <Table hoverable bordered striped narrow fullwidth >
            <Table.Head>
              <Table.Row>
                <Table.Heading>{t('selectedPostalCode')}</Table.Heading>
                <Table.Heading>{t('action')}</Table.Heading>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {selectedPostalCodes &&
                selectedPostalCodes.slice(startPositionPostalCodes, endPositionPostalCodes).map((postalCode, index) => {
                  return (
                    <Table.Row key={index}>
                      <Table.Cell className={boldPostalCodes.includes(postalCode.value) ? "new-identity" : ""} key={index+1*10000}>{postalCode.label}</Table.Cell>
                      <Table.Cell key={index+1*20000}>
                          
                              <button
                                  type="button"
                                  onClick={e => handlePostalCodeDeselected(e, postalCode)}
                                  className='button is-danger is-outlined is-small'
                                  disabled={sharedObjDisabled}
                                >
                                <Icon>
                                  <FontAwesomeIcon size='lg' icon={['fas', 'trash']} />
                                </Icon>
                              </button>

                      </Table.Cell>
                    </Table.Row>
                  )
                })}
            </Table.Body>
          </Table>
          <Pagination
            totalItems={selectedPostalCodes && selectedPostalCodes.length}
            requestPagination={handleRequestPaginationPostalCodes}
          />
    
        </>
          ) : null
      } 
    </Box>
  )
}