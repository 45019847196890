import React from 'react'
import './Legal.scss'
import {
  Image
} from 'rbx'
import AnimalDataSourceLogo from '../../config/images/ADS_fondoblanco.png'

export const PoliticaPrivacidad = () => (
  <>
  <div id="history" className="scroll-area">
    <Image.Container>
        <Image className='logo' src={AnimalDataSourceLogo} />
    </Image.Container>
    <p>
    <br></br>  
      <strong>Política de Privacidad de Animal Data Source</strong>
    </p>
    <p>
    Ambit Building Solutions Together, S.A (en adelante Ambit BST) tiene como valores fundamentales la seguridad y la privacidad en el tratamiento de datos. En consecuencia, asumimos el compromiso de garantizar el derecho a la privacidad del Usuario cuando tratamos sus datos y entendemos que dicho compromiso parte de la transparencia.
    <br/>
    Esta Política de Privacidad contiene la información sobre el tratamiento de los datos personales de los usuarios de Animal Data Source (www.animaldatasource.com ):
    <br/>
    •	¿Quién es el responsable del tratamiento de sus datos personales?
    <br/>
    •	¿Qué datos personales tratamos?
    <br/>
    •	¿Con qué finalidades tratamos sus datos?
    <br/>
    •	¿Por qué podemos tratar sus datos?
    <br/>
    •	¿Durante cuánto tiempo se conservan sus datos?
    <br/>
    •	¿Compartimos sus datos con terceros?
    <br/>
    •	¿Qué derechos le asisten?
    <br/>
    •	¿Qué medidas de seguridad se aplican?
    <br/>
    Si quiere acceder a la información de privacidad de otros tratamientos de datos llevados a cabo por Ambit BST, diríjase a nuestra política de privacidad: https://www.ambit-bst.com/politica-de-privacidad?hsLang=es.
    </p>
    <p>
      <strong><em>1.	¿Quién es el responsable de los datos personales?: </em></strong>
      <br/>
      El responsable del tratamiento de los datos será, en todo caso, la empresa a la que pertenece el usuario.
      <br/>
      En este sentido, Ambit actúa como encargado del tratamiento de los datos personales:
      <br/>
      Encargado del tratamiento:	Ambit Building Solutions Together S.A. (Ambit BST)
      <br/>
      Dirección:	Calle Rosselló I Porcel nº21, 8ª planta, 08016, Barcelona
      <br/>
      Teléfono de contacto:	932 768 990
      <br/>
      Contacto del DPO:	dpo@ambit-bst.com 
    </p>
    <p>
      <strong><em>2.	¿Qué datos personales tratamos?:</em></strong>
      <em />
    </p>
    <p>
      <em>
      Tratamos los siguientes datos de los usuarios de www.animaldatasource.com :
      <br/>
      -	Nombre de usuario
      <br/>
      -	Email
      <br/>
      -	Teléfono
      <br/>
      -	Datos de sesión
      <br/>
      -	Empresa a la que pertenece
      <br/>
      -	IP
      </em>
    </p>
    <p>
      <strong><em>3.	¿Con qué finalidades tratamos sus datos?:</em></strong>
      <br/>
      Tratamos los datos con las siguientes finalidades:
      <br/>
      -	Gestión de los datos y demás información facilitada por el Responsable con el fin exclusivo de mantener el servicio que ofrece, en condiciones óptimas, y garantizar así la integridad, confidencialidad y disponibilidad de los datos.
      <br/>
      -	Facilitarle un medio para que pueda ponerse en contacto con nosotros y contestar a sus solicitudes de información.
      <em />
    </p>
    <p>
      <strong><em>5.	¿Durante cuánto tiempo se conservan sus datos?:</em></strong>
      <br/>
      Sus datos se conservarán mientras se mantenga la relación contractual con el responsable del tratamiento. Una vez la relación contractual finalice los datos serán eliminados o bien devueltos al responsable del tratamiento.
      <em />
    </p>
    <p>
      <strong><em>6.	¿Compartimos sus datos con terceros?:</em></strong>
      <br/>
      Por lo general, sus datos no se cederán a terceros. Los datos se encuentran alojados en los servidores cloud de Ambit situados en España, por lo que no se realizarán transferencias internacionales de datos.
      <br/>
      Ambit puede subcontratar proveedores externos para la prestación de servicios auxiliares. En todo caso, cuando la subcontratación implique un tratamiento de datos por parte de terceros se comunicará adecuadamente.
      <em />
    </p>
    <p>
      <strong><em>7.	¿Qué derechos le asisten?:</em></strong>
      <br/>
      Puede dirigir sus comunicaciones y ejercer sus derechos ante el responsable del tratamiento. En su caso, también puede dirigirlas a Ambit a través del correo electrónico dpo@ambit-bst.com. 
      <br/>
      Los derechos que le asisten en materia de protección de datos son los siguientes:
      <br/>
      •	Derecho de acceso: puede pedir información de aquellos datos personales que se tratan acerca de usted. 
      <br/>
      •	Derecho de rectificación: puede comunicar cualquier cambio en sus datos personales. 
      <br/>
      •	Derecho de supresión y al olvido: puede solicitar la eliminación previo bloqueo de los datos personales. 
      <br/>
      •	Derecho de limitación al tratamiento: supone la restricción del tratamiento de los datos personales. 
      <br/>
      •	Derecho de oposición: puede retirar el consentimiento del tratamiento de los datos, oponiéndose a que se sigan tratando. 
      <br/>
      •	A solicitar la portabilidad de los datos en los casos previstos en la normativa.
      <br/>
      •	Cualesquiera otros derechos reconocidos en las normativas aplicables.
      <br/>
      Asimismo, puede presentar una reclamación ante la autoridad de protección de datos competente.
    </p>
    <p>
      <strong><em>8.	¿Qué medidas de seguridad se aplican?:</em></strong>
      <br/>
      Con el objetivo de salvaguardar la seguridad de sus datos personales, le informamos que hemos adoptado todas las medidas de índole técnica y organizativa necesarias para garantizar la seguridad de los datos personales suministrados y evitar su alteración, pérdida y tratamientos o accesos no autorizados.
      <br/>
      Además, Ambit BST cuenta con la certificación ISO27001 de Seguridad de la Información.
      <br/>
      A continuación, se muestra un listado de las medidas de seguridad implementadas:
      <br/>
      •	Certificado https.
      <br/>
      •	Existencia de copias de seguridad frecuentes.
      <br/>
      •	Implementación de política de contraseñas seguras.
      <br/>
      •	Cifrado de contraseñas.
      <br/>•	Bloqueo de usuario ante intentos de acceso no autorizados.
      •	Uso de firewall.
      <em />
    </p>
    <p>
      <strong><em>9.	Modificaciones en la política de privacidad:</em></strong>
      <br/>
      Esta política de privacidad ha sido actualizada en fecha 25/07/2022.
      <br/>
      La presente Política de Privacidad puede ser modificada para adaptarse a los cambios en el tratamiento o a los cambios en la normativa. 
      <em />
    </p>
    </div>
  </>
)
